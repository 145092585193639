import { DateTime } from 'luxon';
import { useListLoader } from '../base/useListLoader';
import { useOptimisticUpdates } from '../base/useOptimisticUpdates';
import { useRealTimeUpdates } from '../base/useRealTimeUpdates';
import { useCurrentUser } from '../user/useCurrentUser';

export function useSavedfiltersV3Loader({ count, params: _params, pageSize } = {}) {
  const user = useCurrentUser();
  const params = _params || {};

  function computeDisplayOrder(savedfilter, savedfilters) {
    if (savedfilter.positionAfterFilterId !== 0) {
      const previousTask = savedfilters.find(({ id }) => id === savedfilter.positionAfterFilterId);
      if (previousTask) {
        return previousTask.displayOrder - 0.5;
      }
      return Number.MIN_SAFE_INTEGER;
    }
    return Number.MAX_SAFE_INTEGER;
  }

  function responseToItems({ data: { savedfilters, included = {} } }) {
    return savedfilters.map((savedfilter) => {
      return {
        ...savedfilter,
        createdAt: savedfilter.createdAt ? DateTime.fromISO(savedfilter.createdAt) : null,
        updatedAt: savedfilter.updatedAt ? DateTime.fromISO(savedfilter.updatedAt) : null,
        // The standard approach is to use the `included` data to resolve IDs
        // within the main data (`savedfilter` in this case), however, in this particular case
        // it makes more sense to simply expose `included` instead because:
        // - Keeping `savedfilter.parameters` in the format expected by the API is nice and clean.
        //   I don't want to lose this advantage just because some components need the included data.
        // - The set of parameters containing IDs is open-ended in this case.
        //   I don't want to pollute the loader with all of them.
        included,
      };
    });
  }

  function createOrderByDisplayOrder(asc) {
    return (a, b) => {
      const order = asc ? 1 : -1;
      return (
        (a.projectSpecific - b.projectSpecific) * order ||
        (a.displayOrder - b.displayOrder) * order ||
        (a.id - b.id) * order
      );
    };
  }

  const orderByDisplayOrderAsc = createOrderByDisplayOrder(true);
  const orderByDisplayOrderDesc = createOrderByDisplayOrder(false);

  function createOrderByDateUpdated(asc) {
    return (a, b) => {
      const order = asc ? 1 : -1;
      return (a.default - b.default) * order || (a.updatedAt - b.updatedAt) * order || (a.id - b.id) * order;
    };
  }

  const orderByDateUpdatedAsc = createOrderByDateUpdated(true);
  const orderByDateUpdatedDesc = createOrderByDateUpdated(false);

  const order = computed(() => {
    const { orderBy, orderMode } = params.value || {};
    const asc = orderMode !== 'desc';

    switch (orderBy.toLowerCase()) {
      case 'dateupdated':
        return asc ? orderByDateUpdatedAsc : orderByDateUpdatedDesc;
      case 'displayorder':
      default:
        return asc ? orderByDisplayOrderAsc : orderByDisplayOrderDesc;
    }
  });

  const { state, update, refresh } = useListLoader({
    url: '/projects/api/v3/me/savedfilters.json',
    count,
    order,
    params,
    pageSize,
    responseToItems,
    responseToMeta({ data }) {
      return data?.meta;
    },
  });

  useOptimisticUpdates((event) => {
    if (event.type !== 'savedfilter') {
      return;
    }
    update((savedfilters) => {
      if (event.action === 'create') {
        return savedfilters.concat(event.savedfilter);
      }
      if (event.action === 'update') {
        if (event.savedfilter.positionAfterFilterId != null) {
          return savedfilters.map((savedfilter) => {
            if (savedfilter.id === event.savedfilter.id) {
              const newSavedfilter = { ...savedfilter, ...event.savedfilter };
              newSavedfilter.displayOrder = computeDisplayOrder(newSavedfilter, savedfilters);
              return newSavedfilter;
            }
            return savedfilter;
          });
        }
        return savedfilters.map((savedfilter) =>
          savedfilter.id === event.savedfilter.id ? { ...savedfilter, ...event.savedfilter } : savedfilter,
        );
      }
      if (event.action === 'delete') {
        return savedfilters.filter((savedfilter) => savedfilter.id !== event.savedfilter.id);
      }
      return savedfilters;
    }, event.promise);
  });

  useRealTimeUpdates((event) => {
    if (event.type === 'savedfilter') {
      refresh();
    }

    if (event.type === 'savedFilterMigration' && event.userId === user.value.id) {
      refresh();
    }
  });

  return state;
}
