<script setup>
import { useI18n } from '@/util';
import StarIcon from './StarIcon.svg';

const { t } = useI18n();
</script>
<template>
  <LscEmptyState
    size="sm"
    class="h-full"
    :title="t('No starred pages')"
    :message="t('Star your favorite spaces so you can return to them quickly')"
  >
    <template #image>
      <StarIcon class="block h-auto w-12" />
    </template>
    <template #action>
      <LscLink to="/spaces/home/all" variant="standalone" prependIcon="lsi-arrow-right">
        {{ t('{productName} home', { productName: 'Spaces' }) }}
      </LscLink>
    </template>
  </LscEmptyState>
</template>
