/**
 * @typedef {object} Breadcrumb
 * @property {string} id - unique id for the breadcrumb
 * @property {string} label - the text to display
 * @property {string} path - the path to navigate to when clicked
 * @property {string=} icon - the icon to display (optional)
 * @property {string=} iconColor - the color of the icon (optional)
 * @property {string=} tooltip - the tooltip to display (optional)
 * @property {string=} dataIdentifier - the data identifier to use for the breadcrumb (optional)
 * @property {number=} truncationWidth - the width at which to truncate the breadcrumb (optional)
 */

export const LscBreadcrumbSizes = /** @type {const} */ (['sm', 'md']);
