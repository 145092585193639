import { useFeatures } from '../features/useFeatures';
import { usePendo } from '../features/usePendo';

/**
 * Constants, computed properties and functions related to Experiment A-23-18
 * https://app.launchdarkly.com/default/production/features/projects-exp-a-23-18-optimise-free-forever-to-paid-upgrade-ux/targeting
 * https://digitalcrew.teamwork.com/spaces/growth/page/44099-a-23-18-optimise-free-forever-to-paid-upgrade-ux
 */
export function useExperimentA18() {
  const { trackExperimentInPendo } = usePendo();
  const { optimiseFreeForeverToPaidUpgradeUXEnabled } = useFeatures();
  const { launchDarklyFlagKey, defaultValue } = optimiseFreeForeverToPaidUpgradeUXEnabled;
  const ignoreInExperimentOnRuleMatch = true;

  const isExpA18Variation = computed(() => optimiseFreeForeverToPaidUpgradeUXEnabled.value);

  function trackExperimentA18() {
    trackExperimentInPendo({
      launchDarklyFlagKey,
      defaultValue,
      ignoreInExperimentOnRuleMatch,
    });
  }

  return {
    isExpA18Variation,
    trackExperimentA18,
  };
}
