import { DateTime } from 'luxon';

export function stringToDateTime(string) {
  if (typeof string === 'string') {
    const dateTime = DateTime.fromFormat(string.slice(0, 10), 'yyyy-MM-dd');
    if (dateTime.isValid) {
      return dateTime;
    }
  }
  return undefined;
}

export function dateTimeToString(dateTime) {
  if (DateTime.isDateTime(dateTime) && dateTime.isValid) {
    return dateTime.toFormat('yyyy-MM-dd');
  }
  return undefined;
}

/*
 * Get number of days between two dates
 * @param {string} startDate - Start date
 * @param {string} endDate - End date
 * @returns {number} - Number of days between two dates
 */
export function getDaysDiff(startDate, endDate) {
  const start = DateTime.fromISO(startDate);
  const end = DateTime.fromISO(endDate);

  if (!start.isValid || !end.isValid) {
    return 0;
  }

  return end.diff(start, 'days').days;
}

/*
 * Get date value for pre-defined relative date options
 * @param {string} operator - Pre-defined relative date option
 * @param {object} interval - Interval object for 'withinprev' operator
 * @returns {string|string[]} - Date value or array of date values
 */
export function getRelativeDates(operator, interval = { days: 1 }) {
  const now = DateTime.now();
  switch (operator) {
    case 'yesterday':
      return [now.minus({ days: 1 }).startOf('day'), now.minus({ days: 1 }).endOf('day')];
    case 'today':
      return [now.startOf('day'), now.endOf('day')];
    case 'thisweek':
      return [now.startOf('week', { useLocaleWeeks: true }), now.endOf('week', { useLocaleWeeks: true })];
    case 'lastweek':
      return [
        now.minus({ weeks: 1 }).startOf('week', { useLocaleWeeks: true }),
        now.minus({ weeks: 1 }).endOf('week', { useLocaleWeeks: true }),
      ];
    case 'thismonth':
      return [now.startOf('month'), now.endOf('month')];
    case 'lastmonth':
      return [now.minus({ months: 1 }).startOf('month'), now.minus({ months: 1 }).endOf('month')];
    case 'last3months':
      return [now.minus({ months: 3 }).startOf('month'), now.endOf('month')];
    case 'last6months':
      return [now.minus({ months: 6 }).startOf('month'), now.endOf('month')];
    case 'withinprev':
      return [now.minus(interval), now];
    case 'within':
      return [now, now.plus(interval)];
    default:
      return [];
  }
}

/**
 * Returns an array representation of the specified customFields.
 * @param {string|object[]} customFields Custom fields as an Array or a JSON string
 * @returns {object[]} An array representation of the customFields
 */
export function toCustomFieldsArray(customFields) {
  let customFieldsArray = customFields;

  if (typeof customFields === 'string') {
    try {
      customFieldsArray = JSON.parse(customFields);
    } catch (e) {
      customFieldsArray = [];
    }
  }

  if (!Array.isArray(customFieldsArray)) {
    customFieldsArray = [];
  }

  return customFieldsArray
    .map((filterCustomField) => {
      if (filterCustomField == null) {
        return null;
      }

      const fieldId = Number(filterCustomField.fieldId);
      const { operator, value } = filterCustomField;

      if (!Number.isInteger(fieldId) || typeof operator !== 'string' || value === undefined) {
        return null;
      }

      return { fieldId, operator, value };
    })
    .filter(Boolean);
}

/**
 * Returns a string representation of the specified customFields.
 * @param {string|object[]} customFields Custom fields as an Array or a JSON string
 * @returns {string} A string representation of the customFields
 */
export function toCustomFieldsString(customFields) {
  return JSON.stringify(toCustomFieldsArray(customFields));
}

/**
 * Converts parameters from the Saved Filters format to the API format.
 */
export function filterParamsToApiParams(filterParams) {
  const { customFields, ...apiParams } = filterParams;

  toCustomFieldsArray(customFields).forEach((customField) => {
    if (customField.operator === 'range') {
      // Number range
      apiParams[`customField[${customField.fieldId}][gte]`] = customField.value?.[0];
      apiParams[`customField[${customField.fieldId}][lte]`] = customField.value?.[1];
    } else if (customField.operator === 'custom') {
      // Date range
      // Legacy saved filters have value as datetime so it needs to be converted to date
      apiParams[`customField[${customField.fieldId}][gte]`] = customField.value?.[0]?.slice(0, 10);
      apiParams[`customField[${customField.fieldId}][lte]`] = customField.value?.[1]?.slice(0, 10);
    } else if (customField.operator === 'withinprev') {
      // Date within previous n days
      const interval = getDaysDiff(customField.value?.[0], customField.value?.[1]);
      const [start, end] = getRelativeDates('withinprev', { days: interval });
      apiParams[`customField[${customField.fieldId}][gte]`] = start?.toFormat('yyyy-MM-dd');
      apiParams[`customField[${customField.fieldId}][lte]`] = end?.toFormat('yyyy-MM-dd');
    } else if (
      ['thisweek', 'lastweek', 'thismonth', 'lastmonth', 'last3months', 'last6months'].includes(customField.operator)
    ) {
      // Pre-defined relative date range options
      const [start, end] = getRelativeDates(customField.operator);
      apiParams[`customField[${customField.fieldId}][gte]`] = start?.toFormat('yyyy-MM-dd');
      apiParams[`customField[${customField.fieldId}][lte]`] = end?.toFormat('yyyy-MM-dd');
    } else if (['yesterday', 'today'].includes(customField.operator)) {
      // Pre-defined relative date options
      const [start] = getRelativeDates(customField.operator);
      apiParams[`customField[${customField.fieldId}][eq]`] = start?.toFormat('yyyy-MM-dd');
    } else {
      // All other operators
      apiParams[`customField[${customField.fieldId}][${customField.operator}]`] = customField.value;
    }
  });

  return apiParams;
}

/**
 * Converts parameters from the API format to the Saved Filters format.
 */
export function apiParamsToFilterParams(apiParams) {
  const filterParams = { ...apiParams };
  const customFields = [];

  Object.keys(filterParams).forEach((key) => {
    const match = key.match(/^customField\[(\d+)\]\[(\w+)\]$/);
    if (!match) {
      return;
    }

    customFields.push({
      fieldId: Number(match[1]),
      operator: match[2],
      value: filterParams[key] ?? '',
    });

    delete filterParams[key];
  });

  filterParams.customFields = JSON.stringify(customFields);

  return filterParams;
}
