<script setup>
import { useCurrentProject, useCurrentProjectNavigation } from '@/route';
import { useElementSize, useI18n } from '@/util';
import ProjectJumpToDrawerProjectsListProjectNavList from './ProjectJumpToDrawerProjectsListProjectNavList.vue';

const { t } = useI18n();
const project = useCurrentProject();
const { enabledNavItems, maxVisibleItems } = useCurrentProjectNavigation();
const enabledNavItemsToShow = computed(() => enabledNavItems.value.slice(0, maxVisibleItems.value));

const el = shallowRef(null);
const { height } = useElementSize(el);
const minHeight = 44; // Minimum height needed to show spinner
const currentHeight = computed(() => `${Math.max(height.value, minHeight)}px`);
const currentClass = computed(() => (enabledNavItemsToShow.value.length > 0 ? `opacity-100` : 'opacity-0'));
</script>
<template>
  <VList density="compact" class="m-0 p-0 pl-9">
    <Transition
      v-if="enabledNavItemsToShow.length === 0"
      enterFromClass="opacity-0"
      enterActiveClass="transition-opacity duration-500 delay-200"
      appear
    >
      <VListItem density="compact" v-bind="VListItemSidebarProjectsDrawer" tabindex="-1" class="absolute left-9 top-0">
        <template #prepend>
          <LscIcon size="md" icon="lsi-loading" class="mr-2 animate-spin" />
        </template>
        <VListItemTitle class="text-body-1 text-subtle">{{ t('Loading') }}</VListItemTitle>
      </VListItem>
    </Transition>

    <div class="duration-200 overflow-hidden transition-all" :class="currentClass" :style="{ height: currentHeight }">
      <div ref="el">
        <template v-if="enabledNavItemsToShow.length">
          <ProjectJumpToDrawerProjectsListProjectNavList :project="project" :navItems="enabledNavItemsToShow" />
        </template>
      </div>
    </div>
  </VList>
</template>
