<script setup>
import { useSpacesV1CategoriesLoader } from '@/api';
import { useI18n } from '@/util';
import SpacesDrawerSpaceVListItem from './SpacesDrawerSpaceVListItem.vue';
import { useSpacesDrawer } from './useSpacesDrawer';

const { t } = useI18n();
const { lastOpenCategories: open } = useSpacesDrawer();

// Load categories
const pageSize = 20;
const count = shallowRef(pageSize);
const state = useSpacesV1CategoriesLoader({
  count: 100,
  pageSize,
  params: computed(() => ({
    include: 'spaces',
  })),
});

const categories = computed(() => unref(state.items));

function generateTreeItem(item) {
  const newItem = {
    id: item.id,
    title: item.name || item.title,
    count: item.meta?.spaceCount || 0,
  };

  // Set color - note api color doesn't have the hash in front
  let { color } = item;
  if (color !== null && color !== '') {
    color = `#${color}`;
  } else {
    color = null;
  }
  newItem.iconColor = color;

  newItem.to = `/spaces/home/categories/${item.id}`;

  // Add on the children spaces
  newItem.spaces = [];
  if (item.spaces && item.spaces.length) {
    newItem.spaces = item.spaces;
  }

  return newItem;
}

const treeFriendlyItems = computed(() => {
  // Get the top level categories...
  const topLevelItems = categories.value;
  // ...and convert them to tree items
  const treeItems = topLevelItems.map((item) => generateTreeItem(item));
  return treeItems;
});
</script>

<template>
  <LswLoaderState :state="state">
    <template #default>
      <LscTreeview v-model="open" :items="treeFriendlyItems">
        <!-- Show projects in category, if open -->
        <template #appendCategoryList="slotProps">
          <template v-if="slotProps.parentItem && open.includes(slotProps.parentItem.id)">
            <SpacesDrawerSpaceVListItem v-for="space in slotProps.parentItem.spaces" :key="space.id" :space="space" />
          </template>
        </template>
      </LscTreeview>
      <LswLoaderTrigger v-model:count="count" :step="pageSize" :state="state" />
    </template>
    <template #loading>
      <LscLoadingProgress :message="t('Loading categories')" />
    </template>
    <template #blank>
      <slot name="blank" />
    </template>
  </LswLoaderState>
</template>
