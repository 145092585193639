<script setup>
import { useI18n } from '@/util';

const { t } = useI18n();
</script>
<template>
  <div class="text-body-1">
    <p>
      {{
        t(
          "The Schedule allows you to map-out what future, unplanned work could look like. Always know how much client work you can take on and how much capacity you'll have (or need) in the future.",
        )
      }}
    </p>
  </div>
</template>
