import { createSharedComposable, useNow } from '@vueuse/core';
import { DateTime } from 'luxon';

/**
 * Returns a shared `computed` which resolves to a luxon `DateTime` object
 * containing the current time which is updated every minute.
 */
export const useNowByMinute = createSharedComposable(() => {
  const now = useNow({ interval: 60000 });
  return computed(() => DateTime.fromJSDate(now.value));
});
