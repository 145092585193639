<script setup>
import { useI18n } from '@/util';

const { t } = useI18n();
</script>
<template>
  <div class="text-body-1">
    <p>
      {{
        t(
          'The Project Health Report allows you to spot unhealthy projects, identify overdue tasks, and take action to keep your team on track.',
        )
      }}
    </p>
    <p class="pt-6">
      {{
        t(
          'This single report becomes your mission control - where you’ll know all of your project statuses at a single glance.',
        )
      }}
    </p>
  </div>
</template>
