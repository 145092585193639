import { useCurrentAccount } from '../account/useCurrentAccount';
import { useCohort } from '../cohort/useCohort';
import { useFeatures } from '../features/useFeatures';
import { usePendo } from '../features/usePendo';

/**
 * Constants, computed properties and functions related to Experiment R-2410
 * https://app.launchdarkly.com/projects/default/flags/projects-exp-r-24-10-capture-it-industry-data-on-login/targeting
 * https://digitalcrew.teamwork.com/spaces/growth/page/60340-r-24-10-capture-it-industry-data-on-login
 */

export function useExperimentR2410() {
  const { projectsIndustrySubcategoryCaptureEnabled } = useFeatures();
  const { trackExperimentInPendo } = usePendo();
  const { isITServicesAccount, isOwnerAdmin } = useCohort();
  const account = useCurrentAccount();

  const { launchDarklyFlagKey, defaultValue } = projectsIndustrySubcategoryCaptureEnabled;
  const ignoreInExperimentOnRuleMatch = true;

  const expR2410AppLevelTargeting = computed(
    () => isITServicesAccount.value && isOwnerAdmin.value && !account.value?.subIndustryCategoryId,
  );

  const isExpR2410Variation = computed(
    () => projectsIndustrySubcategoryCaptureEnabled.value && expR2410AppLevelTargeting.value,
  );

  function trackExperimentR2410() {
    trackExperimentInPendo({
      launchDarklyFlagKey,
      defaultValue,
      ignoreInExperimentOnRuleMatch,
      appLevelTargeting: expR2410AppLevelTargeting.value,
    });
  }

  return {
    trackExperimentR2410,
    isExpR2410Variation,
  };
}
