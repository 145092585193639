<script setup>
import { useI18n } from '@/util';

const { t } = useI18n();
</script>
<template>
  <div class="text-body-1">
    <p>
      {{
        t(
          'Set up a Retainer Budget for when you have a project that involves ongoing work or support for the same client over a period of time.',
        )
      }}
    </p>
    <p class="pt-6">
      {{
        t(
          'Not only do retainers help you manage long-term client engagements, they also eliminate budgeting surprises, are flexible to each project, and help things end on budget regardless of any spending fluctuations along the way.',
        )
      }}
    </p>
  </div>
</template>
