import { until } from '@vueuse/core';
import { useRouter } from 'vue-router';
import { useHubspotAuthUrlV1Loader, usePreferences } from '@/api';
import { useI18n } from '@/util';
import LogoChrome from '../assets/logo-chrome.svg';
import LogoGoogleDocs from '../assets/logo-gdocs.svg';
import LogoGoogleDrive from '../assets/logo-gdrive.svg';
import LogoGmail from '../assets/logo-gmail.svg';
// import LogoHarvest from '../assets/logo-harvest.svg';
import LogoHubspot from '../assets/logo-hubspot.svg';
import LogoMSOutlook from '../assets/logo-msoutlook.svg';
import LogoQuickbooks from '../assets/logo-quickbooks.svg';
import LogoSharepoint from '../assets/logo-sharepoint.svg';
import LogoSlack from '../assets/logo-slack.svg';
// import LogoMSOffice from '../assets/logo-msoffice.svg';
// import LogoMSProjects from '../assets/logo-msprojects.svg';
// import LogoMSTeams from '../assets/logo-msteams.svg';
// import LogoZapier from '../assets/logo-zapier.svg';

export function useOnboardingWizardIntegrations() {
  const { onboardingSelectedIntegrations } = usePreferences();

  const { t } = useI18n();
  const router = useRouter();
  const toast = useLsToast();
  const { item: hubspotAuthURL, inSync: hubspotAuthURLInSync } = useHubspotAuthUrlV1Loader();

  function toggleIntegration(integration) {
    if (onboardingSelectedIntegrations.value.includes(integration.id)) {
      onboardingSelectedIntegrations.value = onboardingSelectedIntegrations.value.filter((id) => id !== integration.id);
    } else {
      onboardingSelectedIntegrations.value = [...onboardingSelectedIntegrations.value, integration.id];
    }
  }

  function clearSelectedIntegrations() {
    onboardingSelectedIntegrations.value = [];
  }

  const availableIntegrations = [
    {
      id: 'slack',
      icon: LogoSlack,
      label: 'Slack',
      description: t(
        'Create tasks in {teamwork} from messages in {slack} and receive real-time notifications on your tasks in a dedicated {slack} channel.',
        {
          teamwork: 'Teamwork.com',
          slack: 'Slack',
        },
      ),
      url: '/synthesis/oauth/slack/start',
    },
    {
      id: 'hubspot',
      icon: LogoHubspot,
      label: 'Hubspot',
      description: t(
        'Create projects, tasks, messages, milestones, events, and more in {teamwork} directly from {hubspot}.',
        {
          teamwork: 'Teamwork.com',
          hubspot: 'HubSpot',
        },
      ),
      action: async () => {
        await until(hubspotAuthURLInSync).toBe(true, { timeout: 5000 });

        if (hubspotAuthURL.value?.length) {
          window.top.location.href = hubspotAuthURL.value;
        } else {
          toast.critical(
            t('Could not connect to {hubspot}. Redirecting you to the settings page.', { hubspot: 'Hubspot' }),
            { timeout: 2000 },
          );
          setTimeout(() => {
            router.push({ path: '/settings/integrations/all' });
          }, 2000);
        }
      },
    },
    {
      id: 'quickbooks',
      icon: LogoQuickbooks,
      label: 'QuickBooks',
      description: t('Export invoices directly from {teamwork} to your {quickbooks} account.', {
        teamwork: 'Teamwork.com',
        quickbooks: 'QuickBooks',
      }),
      url: '/settings/integrations/all?i=quickbooks',
    },
    {
      id: 'gmail',
      icon: LogoGmail,
      label: 'Gmail',
      url: 'https://workspace.google.com/marketplace/app/teamwork/275857615071',
    },
    {
      id: 'googledrive',
      icon: LogoGoogleDrive,
      label: 'Drive',
      description: t('Keep the files you need at your fingertips by connecting {teamwork} with your {googleDrive}.', {
        teamwork: 'Teamwork.com',
        googleDrive: 'Google Drive',
      }),
      url: '/settings/integrations/all?i=googledrive',
    },
    {
      id: 'sharepoint',
      icon: LogoSharepoint,
      label: 'SharePoint',
      url: '/settings/integrations/all?i=mssharepoint',
    },
    {
      id: 'chrome',
      icon: LogoChrome,
      label: 'Chrome',
      url: 'https://chrome.google.com/webstore/detail/teamwork-for-chrome/okekkfbjgdmfmogicbhdechdmkfhfphc',
    },
    {
      id: 'googledocs',
      icon: LogoGoogleDocs,
      label: 'Google Docs',
      url: 'https://workspace.google.com/marketplace/app/teamwork_for_google_docs/585067086184',
    },
    // Harvest integration too complex for Trial accounts
    // {
    //   id: 'harvest',
    //   icon: LogoHarvest,
    //   label: 'Harvest',
    //   action: () => {
    //     api.put('account.json', { harvestEnabled: true }).then(() => {
    //       window.open('/settings/integrations/all?i=harvest', '_blank');
    //     });
    //   },
    // },
    {
      id: 'msoutlook',
      icon: LogoMSOutlook,
      label: 'MS Outlook',
      url: 'https://appsource.microsoft.com/en/product/office/WA200003957?tab=Overview',
    },
    // not in scope as of yet, as per https://digitalcrew.teamwork.com/spaces/growth/page/49403-a-23-41-integrations-details
    // {
    //   id: 'msteams',
    //   icon: LogoMSTeams,
    //   label: 'MS Teams',
    // },
    // {
    //   id: 'msprojects',
    //   icon: LogoMSProjects,
    //   label: 'MS Project',
    // },
    // {
    //   id: 'msoffice',
    //   icon: LogoMSOffice,
    //   label: 'MS Office',
    // },
    // {
    //   id: 'zapier',
    //   icon: LogoZapier,
    //   label: 'Zapier',
    // },
  ];

  return {
    availableIntegrations,
    toggleIntegration,
    clearSelectedIntegrations,
    onboardingSelectedIntegrations,
  };
}
