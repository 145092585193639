<script setup>
import { useI18n } from '@/util';

const props = defineProps({
  items: {
    type: Array,
    required: false,
    default: () => [
      //
    ],
  },

  loading: {
    type: Boolean,
    required: false,
    default: false,
  },
});

const modelValue = defineModel({
  type: [Object, String],
  default: null,
});

// Bind the selected value to modelValue
const selectedValue = shallowRef(modelValue);

const { t } = useI18n();

const focused = shallowRef(false);
const searchTerm = shallowRef('');

const filtered = computed(() => {
  const { items } = props;

  const filteredItems = [];

  const search = searchTerm.value.trim().toLowerCase();
  const groupTitles = new Set();

  for (const item of items) {
    if (!search || item.props.searchText.includes(search)) {
      if (!item.props.groupTitle || groupTitles.has(item.props.groupTitle)) {
        filteredItems.push(item);
      } else {
        groupTitles.add(item.props.groupTitle);
        filteredItems.push({
          ...item,
          props: {
            ...item.props,
            showGroupTitle: true,
          },
        });
      }
    }
  }

  return filteredItems;
});

function handleClick() {
  // Update modelValue only if there is a change to the selected option
  if (selectedValue.value !== modelValue.value) {
    modelValue.value = selectedValue.value;
  }
}

// Watch for changes to selectedValue
watch(selectedValue, (newValue, oldValue) => {
  // Trigger handleClick only if the value has changed
  if (newValue !== oldValue) {
    handleClick();
  }
});

function handleUpdateModelValue(value) {
  searchTerm.value = '';
  modelValue.value = value;
}
</script>

<template>
  <VAutocomplete
    v-model:focused="focused"
    v-model:search="searchTerm"
    required
    hideSelected
    returnObject
    :modelValue="modelValue"
    noFilter
    :items="filtered"
    :loading="loading && focused"
    :label="t('Select a field')"
    :menuProps="{ width: 'auto' }"
    class="h-10 w-5/12 shrink-0 grow-0 [&_.v-field\_\_input]:max-h-10 [&_input]:!min-w-0"
    singleLine
    @update:modelValue="handleUpdateModelValue"
    @click="handleClick"
  >
    <template #item="{ props: { onClick }, item }">
      <div
        v-if="item.props.showGroupTitle"
        class="sticky -top-1 z-10 bg-default px-3 py-2 text-body-2 font-semibold text-default"
      >
        {{ item.props.groupTitle }}
      </div>

      <div v-bind="{ onClick }" class="flex max-w-60 flex-1 shrink-0 px-1 py-0.5 text-body-2">
        <div
          class="flex h-10 w-full items-center gap-x-2 overflow-hidden rounded-md p-2 hover:cursor-pointer hover:bg-hover"
        >
          <LscIcon v-if="item.props.icon" :icon="item.props.icon" size="md" class="text-icon-subtle" />

          <LscOverflowEllipsis class="text-body-1">{{ item.title }}</LscOverflowEllipsis>
        </div>
      </div>
    </template>
    <template #selection="{ item }">
      <div class="flex h-10 w-full items-center gap-x-2 overflow-hidden rounded-md">
        <LscIcon v-if="item.props.icon" :icon="item.props.icon" size="md" class="text-icon-subtle" />

        <LscOverflowEllipsis class="text-body-1">{{ item.title }}</LscOverflowEllipsis>
      </div>
    </template>
  </VAutocomplete>
</template>
