<script setup>
import { useI18n } from '@/util';
import ApiSetupEmbed1 from './ApiSetupEmbed1.vue';

const { languageCode, timeZoneName, dateFormat, timeFormat, weekStartsOnSunday } = useI18n();
</script>

<template>
  <!-- The `key` recreates the child component when the basic localization data changes. -->
  <ApiSetupEmbed1 :key="`${languageCode}/${timeZoneName}/${dateFormat}/${timeFormat}/${weekStartsOnSunday}`">
    <slot />
  </ApiSetupEmbed1>
</template>
