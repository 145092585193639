<script setup>
import { useExperimentA18 } from '@/api';
import { useI18n } from '@/util';

const { t } = useI18n();
const { isExpA18Variation } = useExperimentA18();
</script>
<template>
  <div class="text-body-1">
    <p>
      {{ t('Centralize your comments and files in one place and simplify your content review workflow.') }}
    </p>
    <p class="mt-4">
      {{ t('Over the next 2 minutes, discover how you can:') }}
    </p>
    <ul class="ml-4 list-disc">
      <li>{{ t('Collaborate with your team') }}</li>
      <li>
        {{ t('Add files to your project items') }}
      </li>
      <li v-if="!isExpA18Variation">
        {{ t('Create and manage proofs') }}
      </li>
    </ul>
  </div>
</template>
