import { DateTime } from 'luxon';
import { useCurrentAccount } from '../account/useCurrentAccount';
import { usePendo } from '../features/usePendo';

/**
 * functions related to Experiment on calendar release
 */
export function useCalenderExperiment() {
  const { trackExperimentInPendo } = usePendo();
  const account = useCurrentAccount();
  const ignoreInExperimentOnRuleMatch = true;
  const comparisonDate = DateTime.fromISO('2024-02-01T14:00:00Z');

  const expCalenderAppLevelTargeting = computed(() => account.value?.dateSignedUp >= comparisonDate);

  function trackCalendarExperiment() {
    trackExperimentInPendo({
      launchDarklyFlagKey: 'projects-my-calendar-master',
      commonMetrics: ['user_role', 'plan_name', 'page', 'account'],
      appLevelTargeting: expCalenderAppLevelTargeting,
      ignoreInExperimentOnRuleMatch,
    });
  }

  return {
    trackCalendarExperiment,
  };
}
