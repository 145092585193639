export const redirectRoutes = [
  {
    path: '/redirect',
    component: () => import('./RouteRedirect.vue'),
    children: [
      {
        path: 'checkout',
        component: () => import('./RouteRedirectCheckout.vue'),
        props: (route) => ({
          cancel: route.query.cancel,
          skipPlans: route.query.skipPlans,
          recommendedPlan: route.query.recommendedPlan,
          discountCode: route.query.discountCode,
        }),
      },
      {
        path: 'trial-bundle/:plan',
        component: () => import('./RouteRedirectTrialBundle.vue'),
        props: true,
        beforeEnter: (to, from, next) => {
          // eslint-disable-next-line no-param-reassign
          to.params.fromPath = from.path;
          next();
        },
      },
    ],
  },
];
