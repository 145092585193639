import { unrefElement } from '@vueuse/core';
import { type MaybeComputedElementRef } from '@vueuse/core';

export function useLsDraggable() {
  function draggable(
    _element: MaybeComputedElementRef,
    {
      dragHandle: _dragHandle,
      restrictToViewport = true,
    }: {
      dragHandle?: MaybeComputedElementRef;
      restrictToViewport?: boolean;
    } = {},
  ) {
    const element = unrefElement(_element) as HTMLElement;
    const dragHandle = unrefElement(_dragHandle ?? _element) as HTMLElement;

    dragHandle.classList.add('cursor-move', 'select-none');

    let initialPointerX = 0;
    let initialPointerY = 0;
    let pointerX = 0;
    let pointerY = 0;
    let initialElementX = 0;
    let initialElementY = 0;
    let elementX = 0;
    let elementY = 0;

    function handlePointerMove(event: PointerEvent) {
      if (!event.isPrimary) {
        return;
      }

      let minElementX = -Infinity;
      let maxElementX = Infinity;
      let minElementY = -Infinity;
      let maxElementY = Infinity;

      if (restrictToViewport) {
        const { left, right, top, bottom } = element.getBoundingClientRect();
        minElementX = Math.min(0, elementX - left);
        maxElementX = Math.max(0, elementX + window.innerWidth - right);
        minElementY = Math.min(0, elementY - top);
        maxElementY = Math.max(0, elementY + window.innerHeight - bottom);
      }

      pointerX = event.clientX;
      pointerY = event.clientY;
      elementX = Math.max(minElementX, Math.min(maxElementX, initialElementX + pointerX - initialPointerX));
      elementY = Math.max(minElementY, Math.min(maxElementY, initialElementY + pointerY - initialPointerY));

      element.style.transform = `translate3d(${elementX}px, ${elementY}px, 0px)`;
    }

    function handlePointerUp(event: PointerEvent) {
      if (!event.isPrimary || !(event.target instanceof HTMLElement)) {
        return;
      }

      event.target.releasePointerCapture(event.pointerId);
      event.target.removeEventListener('pointermove', handlePointerMove);
      event.target.removeEventListener('pointerup', handlePointerUp);
    }

    function handlePointerDown(event: PointerEvent) {
      if (!event.isPrimary || !(event.target instanceof HTMLElement)) {
        return;
      }

      event.target.setPointerCapture(event.pointerId);
      event.target.addEventListener('pointermove', handlePointerMove);
      event.target.addEventListener('pointerup', handlePointerUp);

      initialPointerX = event.clientX;
      initialPointerY = event.clientY;
      initialElementX = elementX;
      initialElementY = elementY;
    }

    dragHandle.addEventListener('pointerdown', handlePointerDown);
  }

  function resetPosition(_element: MaybeComputedElementRef) {
    const element = unrefElement(_element);
    if (!element || element.style.transform === '') {
      return;
    }
    element.style.transform = '';
  }

  return {
    draggable,
    resetPosition,
  };
}
