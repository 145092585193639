const symbol = Symbol('useLaunchpadInfo');

export function provideLaunchpadInfoState(infoState) {
  provide(symbol, infoState);
  return infoState;
}

export function useLaunchpadInfoState() {
  return inject(symbol);
}

export function useLaunchpadInfo() {
  return useLaunchpadInfoState().item;
}
