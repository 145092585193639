import { getLastChildPath } from '../lastChildPath';

/** @param {import('vue-router').RouteLocationNormalized} route */
function userIdFromRoute(route) {
  return {
    userId: Number(route.params.userId),
  };
}

/** @type {import("vue-router").RouteRecordRaw[]} */
export const personRoutes = [
  {
    path: '/people/:userId',
    component: () => import('./RoutePerson.vue'),
    props: userIdFromRoute,
    children: [
      {
        path: '',
        redirect(to) {
          return `/people/${to.params.userId}/${getLastChildPath('person', 'details')}`;
        },
      },
      {
        path: 'details',
        component: () => import('./RoutePersonDetails.vue'),
      },
      {
        path: 'projects',
        component: () => import('./RoutePersonProjects.vue'),
      },
      {
        path: 'tasks',
        component: () => import('./RoutePersonTasks.vue'),
      },
      {
        path: 'events',
        component: () => import('./RoutePersonEvents.vue'),
      },
      {
        path: 'teams',
        component: () => import('./RoutePersonTeams.vue'),
      },
      {
        path: 'boards',
        component: () => import('./RoutePersonBoards.vue'),
      },
      {
        path: 'boards/:boardId',
        component: () => import('./RoutePersonBoard.vue'),
      },
      {
        path: 'completed',
        component: () => import('./RoutePersonCompleted.vue'),
      },
      {
        path: 'milestones',
        component: () => import('./RoutePersonMilestones.vue'),
      },
      {
        path: 'time',
        component: () => import('./RoutePersonTime.vue'),
      },
      {
        path: 'integrations',
        component: () => import('./RoutePersonIntegrations.vue'),
        children: [
          {
            path: '',
            redirect(to) {
              return `/people/${to.params.userId}/integrations/${getLastChildPath(
                `people/${to.params.userId}/integrations/`,
                'all',
              )}`;
            },
          },
          {
            path: ':integrationsTab(all|productivity|apps|accounting|connectors|partner|cloud|webhooks|teamwork|other)',
            component: () => import('./RoutePersonIntegrationsView.vue'),
            props: true,
          },
        ],
      },
      {
        path: 'activity',
        component: () => import('./RoutePersonActivity.vue'),
      },
      {
        path: 'badges',
        component: () => import('./RoutePersonBadges.vue'),
        props: userIdFromRoute,
      },
    ],
  },
];
