<script setup>
import { provideCurrentAccountState } from '../account/useCurrentAccount';
import { useNoopItemLoader } from '../base/useNoopItemLoader';
import { useNoopListLoader } from '../base/useNoopListLoader';
import { provideTeamworkFeaturesState } from '../features/useTeamworkFeatures';
import { provideLaunchpadInfoState } from '../launchpad/useLaunchpadInfo';
import { useLaunchpadInfoV1Loader } from '../launchpad/useLaunchpadInfoV1Loader';
import { provideCurrentUserState } from '../user/useCurrentUser';
import ApiSetupPublic1 from './ApiSetupPublic1.vue';
import ApiSetupPublic2 from './ApiSetupPublic2.vue';
import ApiSetupPublic3 from './ApiSetupPublic3.vue';
import ApiSetupPublic4 from './ApiSetupPublic4.vue';

const launchpadInfoState = useLaunchpadInfoV1Loader({
  params: {
    suppressLoginModal: true,
    noErrorHandling: true,
  },
});
provideLaunchpadInfoState(launchpadInfoState);

provideCurrentAccountState({
  ...launchpadInfoState,
  item: computed(() => {
    if (launchpadInfoState.item.value == null) {
      return launchpadInfoState.item.value;
    }
    const { id, name, twAuthBaseURL } = launchpadInfoState.item.value;
    return {
      id,
      name,
      isStaging: twAuthBaseURL === 'https://tw-auth.staging.teamwork.com',
      installationTrackingEnabled: true,
    };
  }),
});

// Gets a random persistent ID for an anonymous user, so that we could return consistent feature flags.
function getUserId() {
  let userId = Number(localStorage.getItem('teamwork/ApiSetupPublic/userId'));

  if (!(Number.isSafeInteger(userId) && userId < 0)) {
    // Math.random() may return 0, so we subtract 1 to ensure that the generated number is negative.
    userId = -Math.floor(Math.random() * 0xffffffff) - 1;
    try {
      localStorage.setItem('teamwork/ApiSetupPublic/userId', userId);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('ApiSetupPublic: Failed to store the userId.');
    }
  }

  return userId;
}

provideCurrentUserState(useNoopItemLoader({ id: getUserId() }));

provideTeamworkFeaturesState(useNoopListLoader());
</script>

<template>
  <ApiSetupPublic1>
    <ApiSetupPublic2>
      <ApiSetupPublic3>
        <ApiSetupPublic4>
          <slot />
        </ApiSetupPublic4>
      </ApiSetupPublic3>
    </ApiSetupPublic2>
  </ApiSetupPublic1>
</template>
