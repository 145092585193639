<script setup>
import { formatKeyboardShortcut } from '@/util';
import { useThemeColor } from '@/module/theme';
import { useAppShellSidebar } from './useAppShellSidebar';

defineOptions({
  inheritAttrs: false,
});

defineProps({
  icon: {
    type: String,
    required: true,
  },
  primaryLabel: {
    type: String,
    required: true,
  },
  secondaryLabel: {
    type: String,
    required: true,
  },
});

const emit = defineEmits(['leftButtonClick', 'rightButtonClick']);

const { isCustomTheme } = useThemeColor();
const { isSidebarExpanded, isQuickAddOpen } = useAppShellSidebar();

const rightButtonRef = shallowRef(null);

function performRightButtonClick() {
  if (!rightButtonRef.value || rightButtonRef.value.tabIndex === -1) {
    return;
  }

  rightButtonRef.value.click?.();
  rightButtonRef.value.focus?.();
}

defineExpose({
  performRightButtonClick,
});
</script>
<template>
  <div
    :class="[
      'flex w-full min-w-9 justify-start rounded-full',
      'text-[--lsds-c-sidebar-drawer-quick-add-color-on-surface]',
      'bg-[--lsds-c-sidebar-drawer-quick-add-color-surface]',
    ]"
  >
    <template v-if="isSidebarExpanded">
      <button
        type="button"
        :class="[
          'flex h-9 min-w-9 flex-1 items-center gap-1 rounded-l-full',
          'outline-none ring-0 ring-[color:--lsds-c-sidebar-drawer-list-item-background-color-on-focus]',
          'text-body-1 font-medium',
          'bg-[--lsds-c-sidebar-drawer-quick-add-color-surface] transition-colors',
          'hover:bg-[--lsds-c-sidebar-drawer-quick-add-color-surface-hover]',
          'focus-visible:z-10 focus-visible:bg-[--lsds-c-sidebar-drawer-quick-add-color-surface-hover] focus-visible:ring-2',
        ]"
        @click="emit('leftButtonClick')"
      >
        <div class="flex size-9 shrink-0 items-center justify-center">
          <LscIcon :icon="icon" />
        </div>
        <LscOverflowEllipsis>{{ primaryLabel }}</LscOverflowEllipsis>
      </button>
      <button
        ref="rightButtonRef"
        v-LsdTooltip:right="
          isQuickAddOpen
            ? undefined
            : {
                text: secondaryLabel,
                subtext: formatKeyboardShortcut('Q'),
              }
        "
        v-bind="$attrs"
        type="button"
        :class="[
          'before:absolute before:left-0 before:h-4 before:w-px',
          'relative flex size-9 items-center justify-center rounded-r-full',
          'outline-none ring-0 ring-[color:--lsds-c-sidebar-drawer-list-item-background-color-on-focus]',
          'bg-[--lsds-c-sidebar-drawer-quick-add-color-surface] transition-colors',
          'hover:bg-[--lsds-c-sidebar-drawer-quick-add-color-surface-hover]',
          'aria-expanded:ring-0',
          'focus-visible:bg-[--lsds-c-sidebar-drawer-quick-add-color-surface-hover] focus-visible:ring-2',
          {
            'before:bg-[--lsds-a-color-border-bold]': isCustomTheme,
            'before:bg-[--lsds-c-button-color-divider-primary]': !isCustomTheme,
          },
        ]"
        @click="emit('rightButtonClick')"
      >
        <LscIcon icon="lsi-dropdown" size="sm" />
      </button>
    </template>
    <button
      v-else
      ref="rightButtonRef"
      v-LsdTooltip:right="
        isQuickAddOpen
          ? undefined
          : {
              text: secondaryLabel,
              subtext: formatKeyboardShortcut('Q'),
            }
      "
      v-bind="$attrs"
      type="button"
      :class="[
        'flex size-9 items-center justify-center rounded-full',
        'outline-none ring-0 ring-[color:--lsds-c-sidebar-drawer-list-item-background-color-on-focus]',
        'transition-colors',
        'hover:bg-[--lsds-c-sidebar-drawer-quick-add-color-surface-hover]',
        'aria-expanded:ring-0',
        'focus-visible:bg-[--lsds-c-sidebar-drawer-quick-add-color-surface-hover] focus-visible:ring-2',
      ]"
      @click="emit('rightButtonClick')"
    >
      <LscIcon icon="lsi-sidenav-quick-add" />
    </button>
  </div>
</template>
