<script setup>
import { useBudgetsCountsV3Loader, useExperimentA2301, useExperimentA2350, useFeatures } from '@/api';
import { useI18n } from '@/util';
import OnboardingWizardCommonStep from '../common/OnboardingWizardCommonStep.vue';
import OnboardingWizardCommonPreview from '../common/preview/OnboardingWizardCommonPreview.vue';
import {
  BILLING_TYPE_FIXED_FEE,
  BILLING_TYPE_NO_BUDGET,
  BILLING_TYPE_RETAINER,
  BILLING_TYPE_STANDARD,
  ONBOARDING_GOAL_GETTING_STARTED,
  PREVIEW_TAB_BUDGETS,
  STEP_BILLING_TYPE,
  STEP_CLIENT_PROJECT,
  STEP_SELECT_VIEW,
} from '../constants';
import { useOnboardingWizard } from '../useOnboardingWizard';

const props = defineProps({
  state: {
    type: Object,
    required: true,
  },
  nextButtonText: {
    type: String,
    required: true,
  },
  showSkipButton: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['nextStep', 'previousStep', 'close']);

const {
  [STEP_BILLING_TYPE]: stateData,
  [STEP_SELECT_VIEW]: selectViewState,
  [STEP_CLIENT_PROJECT]: clientProjectStateData,
} = props.state;

const { t } = useI18n();
const { currentGoal, currentProject } = useOnboardingWizard();
const { trackBillingTypeClicked: trackBillingTypeClickedA2301, trackIDontKnowYet } = useExperimentA2301();
const { trackBillingTypeClicked: trackBillingTypeClickedA2350 } = useExperimentA2350();
const { financialBudgetLimit, timeBudgetLimit, retainerBudgetsLimit, fixedFeeBudgetsLimit } = useFeatures();

const { item: budgetCounts, inSync, loaded } = useBudgetsCountsV3Loader({ params: { skipCounts: false } });

const loading = computed(() => !loaded.value || !inSync.value);

const isOverFeatureLimit = computed(() => {
  if (loading.value) {
    return undefined;
  }

  return {
    financial:
      financialBudgetLimit.value !== -1 &&
      (budgetCounts?.value?.financial?.active ?? 0) + (budgetCounts?.value?.financial?.upcoming ?? 0) >=
        financialBudgetLimit.value,
    time:
      timeBudgetLimit.value !== -1 &&
      (budgetCounts?.value?.time?.active ?? 0) + (budgetCounts?.value?.time?.upcoming ?? 0) >= timeBudgetLimit.value,
    retainer:
      retainerBudgetsLimit.value !== -1 &&
      (budgetCounts?.value?.retainer?.active ?? 0) + (budgetCounts?.value?.retainer?.upcoming ?? 0) >=
        retainerBudgetsLimit.value,
    fixedFee:
      fixedFeeBudgetsLimit.value !== -1 &&
      (budgetCounts?.value?.fixedFee?.active ?? 0) + (budgetCounts?.value?.fixedFee?.upcoming ?? 0) >=
        fixedFeeBudgetsLimit.value,
  };
});

const anyBudget = computed(() => {
  if (loading.value) {
    return undefined;
  }

  let trueEntries = Object.keys(isOverFeatureLimit.value).filter((key) => isOverFeatureLimit.value[key]);

  if (trueEntries.length === 0) {
    return { isOverAnyBudgetLimit: false, message: undefined };
  }

  trueEntries = trueEntries.map((entry) => (entry === 'fixedFee' ? 'fixed fee' : entry));

  let message = '';

  if (trueEntries.length <= 2) {
    message = t(
      'No limit reached | You have reached the maximum available {type} budgets on your Grow plan trial. | You have reached the maximum available {type} and {type1} budgets on your Grow plan trial.',
      {
        n: trueEntries.length,
        type: trueEntries[0],
        type1: trueEntries[1],
      },
    );
  } else {
    const lastEntry = trueEntries.pop();
    message = t('You have reached the maximum available {types} and {lastType} budgets on your Grow plan trial.', {
      types: trueEntries.join(', '),
      lastType: lastEntry,
    });
  }

  return {
    isOverAnyBudgetLimit: Boolean(trueEntries.length),
    message,
  };
});

const typeOptions = [
  {
    label: t('Fixed fee'),
    description: t('Clients pay for time and expenses spent on the project'),
    icon: 'lsi-project',
    id: BILLING_TYPE_FIXED_FEE,
  },
  {
    label: t('Time & materials'),
    description: t('Bill for time and materials, with billable rates'),
    icon: 'lsi-billable',
    id: BILLING_TYPE_STANDARD,
  },
  {
    label: t('Retainer'),
    description: t('A monthly or quarterly recurring budget'),
    icon: 'lsi-retainer',
    id: BILLING_TYPE_RETAINER,
  },
];

const type = shallowRef(stateData?.type);

const clientName = computed(() => {
  if (clientProjectStateData?.name) {
    return clientProjectStateData.name;
  }

  if (currentProject?.value && currentProject?.value?.company) {
    const { company } = currentProject.value;
    return company.isOwner ? t('clients') : company.name;
  }

  return t('clients');
});

const projectName = computed(() => selectViewState?.name ?? currentProject?.value?.name ?? t('your project'));

function trackBillingTypeClicked(billingType) {
  if (currentGoal.value === ONBOARDING_GOAL_GETTING_STARTED) {
    trackBillingTypeClickedA2301(billingType);
  } else {
    const billingTypes = {
      [BILLING_TYPE_STANDARD]: 'time_and_materials',
      [BILLING_TYPE_RETAINER]: 'retainer',
      [BILLING_TYPE_FIXED_FEE]: 'fixed_fee',
    };
    trackBillingTypeClickedA2350(billingTypes[billingType]);
  }
}

function isOverBudgetLimit(budget) {
  if (loading.value) {
    return undefined;
  }

  switch (budget) {
    case BILLING_TYPE_STANDARD:
      return isOverFeatureLimit.value.financial || isOverFeatureLimit.value.time;
    case BILLING_TYPE_FIXED_FEE:
      return isOverFeatureLimit.value.fixedFee;
    case BILLING_TYPE_RETAINER:
      return isOverFeatureLimit.value.retainer;
    default:
      return false;
  }
}

function hasDataChanged() {
  return !stateData || stateData?.type !== type.value;
}

function nextStep() {
  emit('nextStep', hasDataChanged(), { type: type.value });
}

function skipStep() {
  if (type.value) {
    type.value = BILLING_TYPE_NO_BUDGET;

    trackIDontKnowYet();
    nextStep();

    return;
  }

  emit('nextStep', !stateData || !stateData?.skipped, { skipped: true });
}
</script>
<template>
  <OnboardingWizardCommonStep
    :title="
      t('How do you bill {clientCompanyName} for {projectName}?', {
        clientCompanyName: clientName,
        projectName: projectName,
      })
    "
    :description="t('We\'ll customize your project based on your choice. You can change this later.')"
  >
    <LscChoiceList
      v-model="type"
      class="flex flex-col gap-4"
      horizontal
      :multiple="false"
      @update:modelValue="(type) => trackBillingTypeClicked(type)"
    >
      <LscChoice
        v-for="option in typeOptions"
        :key="option.id"
        :label="option.label"
        :description="option.description"
        :icon="option.icon"
        :value="option.id"
        :disabled="isOverBudgetLimit(option.id)"
        hasCheck
        class="w-full"
        :class="{
          '!border-critical-default': isOverBudgetLimit(option.id),
        }"
      />
    </LscChoiceList>
    <span v-if="anyBudget?.isOverAnyBudgetLimit" class="text-body-2 text-critical">
      {{ anyBudget?.message }}
    </span>
    <slot
      name="underFieldsButtons"
      :nextButtonText="nextButtonText"
      :nextStep="nextStep"
      :showSkipButton="showSkipButton"
      :skipButtonText="type ? t('I don\'t know yet') : t('Skip for now')"
      :skipStep="skipStep"
    />
    <template #right>
      <OnboardingWizardCommonPreview :projectName="t('Budgets')" :preselectedTab="PREVIEW_TAB_BUDGETS" />
    </template>
  </OnboardingWizardCommonStep>
  <slot
    name="footerButtons"
    :nextButtonText="nextButtonText"
    :nextStep="nextStep"
    :isNextStepButtonDisabled="!Boolean(type)"
    :skipButtonText="t('Skip')"
    :showSkipButton="showSkipButton"
    :skipStep="skipStep"
  />
</template>
