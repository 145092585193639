import { usePendo } from '@/api';
import { usePlanTrial } from '@/module/planTrial';

const name = shallowRef('');
const limit = shallowRef(0);
const reasonForAccess = shallowRef('unknown');

export function useFeatureAccessEvent() {
  const { trackPendoEvent } = usePendo();
  const { maxpGrowTrialAvailable } = usePlanTrial();

  function setFeatureName(featureName) {
    name.value = featureName;
  }

  function setFeatureLimit(featureLimit) {
    limit.value = featureLimit;
  }

  function setReasonForFeatureAccess(reasonForFeatureAccess) {
    reasonForAccess.value = reasonForFeatureAccess;
  }

  /** @param {'contact_us_modal_displayed' | 'ask_admin_modal_displayed' | 'contact_us_form_submitted' | 'ask_admin_form_submitted' | 'contact_us_to_upgrade_clicked' | 'contact_us_to_upgrade_submitted' } eventAction */
  function trackFeatureAccessEvent(eventAction) {
    if (name.value === '') {
      return;
    }

    trackPendoEvent({
      eventName: 'FEATURE_ACCESS_EVENT',
      commonMetrics: ['plan_name', 'user_role', 'page'],
      metadata: {
        feature: name.value,
        feature_limit: limit.value,
        reason_for_feature_access: reasonForAccess.value,
        event_action: eventAction,
        page_type: `${window.location.pathname.split('/')?.at(-1).toLowerCase()}_page`,
        grow_trial_available: maxpGrowTrialAvailable.value,
      },
    });
  }

  return {
    setFeatureName,
    setFeatureLimit,
    setReasonForFeatureAccess,
    trackFeatureAccessEvent,
  };
}
