import { DateTime } from 'luxon';
import { useListLoader } from '../base/useListLoader';
import { useOptimisticUpdates } from '../base/useOptimisticUpdates';
import { useRealTimeUpdates } from '../base/useRealTimeUpdates';

function responseToItems(response) {
  const projects = {};
  const {
    activities = {},
    companies = {},
    customfieldProjects: customFieldProjects = {},
    customfields: customFields = {},
    portfolioBoards = {},
    portfolioCards = {},
    portfolioColumns = {},
    projectBudgets = {},
    projectCategories = {},
    projectTaskStats = {},
    projectUpdates = {},
    tags = {},
    users = {},
    workflows = {},
    stages = {},
  } = response.data.included;
  const permissions = response.data.meta.permissions || {};
  const projectsList = response.data.projects || [];
  const projectProfitability = response.data.meta.projectProfitability || {};

  /* eslint-disable no-param-reassign */
  projectsList.forEach((project) => {
    projects[project.id] = project;

    project.startDate = project.startDate ? DateTime.fromISO(project.startDate, { setZone: true }) : null;
    project.endDate = project.endDate ? DateTime.fromISO(project.endDate, { setZone: true }) : null;
    project.createdAt = project.createdAt ? DateTime.fromISO(project.createdAt, { setZone: true }) : null;
    project.updatedAt = project.updatedAt ? DateTime.fromISO(project.updatedAt, { setZone: true }) : null;
    project.lastWorkedOn = project.lastWorkedOn ? DateTime.fromISO(project.lastWorkedOn, { setZone: true }) : null;

    const categoryId = project.category?.id ?? project.categoryId;

    if (categoryId && projectCategories[categoryId]) {
      project.category = projectCategories[categoryId];
    }

    if (project.companyId && companies[project.companyId]) {
      project.company = companies[project.companyId];
    }

    if (project.customFieldValues) {
      project.customFieldValues.forEach((customFieldValue, index) => {
        if (customFieldProjects[customFieldValue.id]) {
          project.customFieldValues[index] = customFieldProjects[customFieldValue.id];
        }
      });
    }

    if (project.latestActivity && activities[project.latestActivity.id]) {
      project.latestActivity = activities[project.latestActivity.id];
    }

    if (project.latestUpdate && projectUpdates[project.latestUpdate.id]) {
      project.latestUpdate = projectUpdates[project.latestUpdate.id];
    }

    if (project.minMaxAvailableDates) {
      const { minStartDate, maxEndDate, suggestedStartDate, suggestedEndDate } = project.minMaxAvailableDates;
      project.minMaxAvailableDates.maxEndDate = maxEndDate ? DateTime.fromISO(maxEndDate) : null;
      project.minMaxAvailableDates.minStartDate = minStartDate ? DateTime.fromISO(minStartDate) : null;
      project.minMaxAvailableDates.suggestedEndDate = suggestedStartDate ? DateTime.fromISO(suggestedStartDate) : null;
      project.minMaxAvailableDates.suggestedStartDate = suggestedEndDate ? DateTime.fromISO(suggestedEndDate) : null;
    }

    if (project.ownerId && users[project.ownerId]) {
      project.owner = { ...users[project.ownerId], entityType: 'user' };
    }

    if (project.updatedBy && users[project.updatedBy]) {
      project.updatedBy = { ...users[project.updatedBy], entityType: 'user' };
    }

    if (project.createdBy && users[project.createdBy]) {
      project.createdBy = { ...users[project.createdBy], entityType: 'user' };
    }

    if (permissions[project.id]) {
      project.permissions = permissions[project.id];
    }

    project.workflows = (project.workflows || []).reduce((acc, _workflow) => {
      const workflow = workflows[_workflow.id];
      if (!workflow) {
        acc.push(_workflow);
        return acc;
      }

      // Workaround for API incorrectly surfacing deleted/archived workflows
      // https://digitalcrew.teamwork.com/#/tasks/23329762
      if (['archived', 'deleted'].includes(workflow.status)) {
        return acc;
      }

      acc.push({
        ...workflow,
        stages: (workflow.stages || []).map((s) => stages[s.id] || s).sort((a, b) => a.displayOrder - b.displayOrder),
      });

      return acc;
    }, []);

    if (project.users) {
      project.users.forEach((user, index) => {
        if (users[user.id]) {
          project.users[index] = users[user.id];
        }
      });
    }

    if (project.latestActivity && activities[project.latestActivity.id]) {
      const activityData = activities[project.latestActivity.id];
      // rename to work with useActivityListString
      project.latestActivity = {
        ...activityData,
        activityType: activityData.latestType,
        link: activityData.link,
        type: activityData.itemType,
        user: users[activityData.user?.id],
        datetime: activityData.datetime ? DateTime.fromISO(activityData.datetime) : null,
      };
    }

    if (project.portfolioCards) {
      project.portfolioCards.forEach((portfolioCard, index) => {
        if (portfolioCards[portfolioCard.id]) {
          project.portfolioCards[index] = portfolioCards[portfolioCard.id];
        }
      });
    }

    if (project.tags?.length) {
      project.tags.forEach((tag, index) => {
        if (tags[tag.id]) {
          project.tags[index] = tags[tag.id];
        }
      });
    }

    if (projectTaskStats[project.id]) {
      project.taskStats = projectTaskStats[project.id];
    }

    if (projectProfitability && projectProfitability[project.id]) {
      project.profitabilityStats = projectProfitability[project.id];
    }

    const budgetId = project.timeBudgetId || project.financialBudgetId;
    if (projectBudgets[budgetId]) {
      project.budget = projectBudgets[budgetId];
      project.budget.repeatUnit = project.budget.repeatUnit.toLowerCase();
      project.budget.status = project.budget.status.toLowerCase();
      project.budget.timelogType = project.budget.timelogType.toLowerCase();
      project.budget.type = project.budget.type.toLowerCase();
      project.budget.startDateTime = project.budget.startDateTime
        ? DateTime.fromISO(project.budget.startDateTime)
        : null;
      project.budget.endDateTime = project.budget.endDateTime ? DateTime.fromISO(project.budget.endDateTime) : null;
    }

    if (project.updateId && projectUpdates[project.updateId]) {
      project.update = projectUpdates[project.updateId];
    }
  });

  Object.values(activities).forEach((activity) => {
    activity.datetime = activity.datetime ? DateTime.fromISO(activity.datetime) : null;

    if (activity.user && users[activity.user.id]) {
      activity.user = users[activity.user.id];
    }
  });

  Object.values(customFieldProjects).forEach((customFieldProject) => {
    if (customFieldProject.customfield) {
      customFieldProject.customField = customFieldProject.customfield;
      delete customFieldProject.customfield;
    }
    if (customFieldProject.customfieldId) {
      customFieldProject.customFieldId = customFieldProject.customfieldId;
      delete customFieldProject.customfieldId;
    }

    if (customFieldProject.customField && customFields[customFieldProject.customField.id]) {
      customFieldProject.customField = customFields[customFieldProject.customField.id];
      switch (customFieldProject.customField.type) {
        case 'status':
        case 'dropdown': {
          const option = customFieldProject.customField.options.choices.find(
            (el) => el.value === customFieldProject.value,
          );
          if (option) {
            customFieldProject.color = option.color;
          }
          break;
        }
        default:
          break;
      }
    }

    if (customFieldProject.project && projects[customFieldProject.project.id]) {
      customFieldProject.project = projects[customFieldProject.project.id];
    }
  });

  Object.values(portfolioCards).forEach((portfolioCard) => {
    if (portfolioCard.project && projects[portfolioCard.project.id]) {
      portfolioCard.project = projects[portfolioCard.project.id];
    }

    if (portfolioCard.column && portfolioColumns[portfolioCard.column.id]) {
      portfolioCard.column = portfolioColumns[portfolioCard.column.id];
    }

    if (portfolioCard.board && portfolioBoards[portfolioCard.board.id]) {
      portfolioCard.board = portfolioBoards[portfolioCard.board.id];
    }
  });

  Object.values(projectCategories).forEach((projectCategory) => {
    if (projectCategory.parent && projectCategories[projectCategory.parent.id]) {
      projectCategory.parent = projectCategories[projectCategory.parent.id];
    }
  });

  Object.values(projectUpdates).forEach((projectUpdate) => {
    projectUpdate.createdAt = projectUpdate.createdAt ? DateTime.fromISO(projectUpdate.createdAt) : null;
    projectUpdate.updatedAt = projectUpdate.updatedAt ? DateTime.fromISO(projectUpdate.updatedAt) : null;

    if (users[projectUpdate.createdBy]) {
      projectUpdate.creator = users[projectUpdate.createdBy];
    }

    if (projectUpdate.project && projects[projectUpdate.project.id]) {
      projectUpdate.project = projects[projectUpdate.project.id];
    }
  });
  /* eslint-enable no-param-reassign */

  return projectsList;
}

// Gets projects metadata from an axios response.
function responseToMeta({ data: { meta } }) {
  const { projects, user, page } = meta;
  return {
    projects,
    user,
    totalCount: page?.count,
  };
}

/**
 * Loads a list of projects from the Teamwork v3 API.
 */
export function useProjectsV3Loader({ params, count, pageSize, cache = true } = {}) {
  const url = computed(() => '/projects/api/v3/projects.json');

  const { state, refresh, update } = useListLoader({
    url,
    params,
    count,
    pageSize,
    responseToItems,
    responseToMeta,
    cache,
  });

  useOptimisticUpdates((event) => {
    if (event.type === 'project') {
      if (event.action === 'update') {
        update(
          (projects) =>
            projects.map((project) => (project.id === event.project.id ? { ...project, ...event.project } : project)),
          event.promise,
        );
      }
    }
  });

  useRealTimeUpdates((event) => {
    switch (event.type) {
      case 'project':
        if (event.action === 'permissions') {
          // Do nothing - existing pwa behaviour.
        } else if (event.action === 'progressNotice') {
          // Do nothing because project objects do not change on project progressNotice events.
        } else if (event.action === 'starred' || event.action === 'unstarred') {
          // The server does not mark starred and unstarred projects as modified,
          // so we must trigger a full refresh to avoid projects disappearing from the list.
          // See https://digitalcrew.teamwork.com/#tasks/18655536?c=9133523
          refresh();
        } else {
          // Refresh only the modified projects.
          refresh(event.projectId);
        }
        break;
      case 'budget':
        // Update project budgets.
        if (
          params.value &&
          typeof params.value.include === 'string' &&
          /(^|,)projectBudgets($|,)/.test(params.value.include)
        ) {
          refresh(event.projectId);
        }
        break;
      case 'time':
        // Update project budgets.
        if (
          params.value &&
          typeof params.value.include === 'string' &&
          /(^|,)projectBudgets($|,)/.test(params.value.include)
        ) {
          refresh();
        }
        break;
      case 'task':
        // Update task stats.
        if (
          params.value &&
          typeof params.value.include === 'string' &&
          /(^|,)projectTaskStats($|,)/.test(params.value.include) &&
          (event.action === 'completed' ||
            event.action === 'reopened' ||
            event.action === 'deleted' ||
            event.action === 'new')
        ) {
          refresh();
        }
        break;
      default:
        break;
    }
  });

  return state;
}
