<script setup>
import { refDebounced } from '@vueuse/core';
import { useFormsV3Loader } from '@/api';
import FormDrawerAllBlankSlate from './FormDrawerAllBlankSlate.vue';
import FormDrawerFormsList from './FormDrawerFormsList.vue';

const props = defineProps({
  searchTerm: {
    type: String,
    default: '',
  },
});
const emit = defineEmits(['hasSomeForms']);
const { searchTerm } = toRefs(props);
const debouncedSearchTerm = refDebounced(searchTerm, 300);

const step = 30;
const count = shallowRef(step);
const state = useFormsV3Loader({
  count,
  pageSize: step,
  params: computed(() => ({
    'searchTerm': debouncedSearchTerm.value,
    'orderBy': 'name',
    'onlyProjectsWithExplicitMembership': true,
    'include': 'projects,projects.permissions',
    'content.state': 'latest',
    'fields[projects]': 'id,name',
    'fields[projectPermissions]': 'addForms',
  })),
});

// Let the parent know if we have forms or not
watch(
  () => state.items.value.length > 0,
  (hasSomeForms) => {
    emit('hasSomeForms', hasSomeForms);
  },
  { immediate: true },
);
</script>

<template>
  <FormDrawerFormsList v-model:count="count" :step="step" :state="state">
    <template #blank>
      <FormDrawerAllBlankSlate :searchTerm="searchTerm" />
    </template>
  </FormDrawerFormsList>
</template>
