export const cancellationRoutes = [
  {
    path: '/checkout/cancellation',
    component: () => import('./RouteCancellation.vue'),
    children: [
      {
        path: '',
        component: () => import('./RouteCancellationHome.vue'),
      },
      {
        path: ':pathMatch(.*)*',
        redirect: '/checkout/cancellation',
      },
    ],
  },
];
