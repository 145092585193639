<script setup>
import { useI18n } from '@/util';
import SpacesDrawerPageVListItem from './SpacesDrawerPageVListItem.vue';

const props = defineProps({
  state: {
    type: Object,
    required: true,
  },
  step: {
    type: Number,
    default: 10,
  },
  resultsTitle: {
    type: [String, undefined],
    default: undefined,
  },
});

const count = defineModel('count', { type: Number, required: true });

const { t } = useI18n();

const pages = computed(() => unref(props.state.items));
</script>

<template>
  <LswLoaderState :state="state">
    <template #default>
      <VList density="compact" class="m-0 !px-4 !pb-0 !pt-0">
        <VListSubheader v-if="resultsTitle">{{ resultsTitle }}</VListSubheader>
        <SpacesDrawerPageVListItem v-for="page in pages" :key="page.id" :page="page" />
        <LscLoadingProgress v-if="!state.inSync.value" />
        <LswLoaderTrigger v-model:count="count" :step="step" :state="state" />
      </VList>
    </template>
    <template #loading>
      <LscLoadingProgress :message="t('Loading pages')" />
    </template>
    <template #blank>
      <slot name="blank" />
    </template>
  </LswLoaderState>
</template>
