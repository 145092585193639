import { DateTime } from 'luxon';
import { useRouter } from 'vue-router';
import {
  PROFITABILITY_FEATURE_KEY,
  PROFITABILITY_FEATURE_TRIAL_LENGTH,
  RETAINER_BUDGETS_FEATURE_KEY,
  RETAINER_BUDGETS_FEATURE_TRIAL_LENGTH,
  SCHEDULED_REPORTS_FEATURE_KEY,
  SCHEDULED_REPORTS_FEATURE_TRIAL_LENGTH,
  TASKLIST_BUDGETS_FEATURE_KEY,
  TASKLIST_BUDGETS_FEATURE_TRIAL_LENGTH,
  useCurrentAccount,
  useCurrentUser,
  useFeedbackActions,
  usePermissions,
  usePreferences,
  usePricePlan,
} from '@/api';
import { useI18n } from '@/util';
import { useCheckoutData } from '@/module/checkout';
import { useFeaturesLimit } from '@/module/featureLimit';
import { useFeedback } from '@/module/feedback';
import { useLegacyBridge } from '../legacy/useLegacyBridge.js';

/**
 * @param {string} featureKey
 */
export function useFeatureTrial(featureKey) {
  const account = useCurrentAccount();
  const toast = useLsToast();
  const { postFeedback } = useFeedbackActions();
  const user = useCurrentUser();
  const { t } = useI18n();
  const { featureLimit, featureHasLimits, isOverFeatureLimit, isFeatureEnabled } = useFeaturesLimit({
    feature: featureKey,
  });
  const { isOwnerAdmin } = usePermissions();
  const { featureTrialsBannersVisibility } = usePreferences();
  const { canAccessCheckout, isCancellationScheduled } = isOwnerAdmin.value
    ? useCheckoutData()
    : { canAccessCheckout: ref(false), isCancellationScheduled: ref(false) };
  const router = useRouter();
  const { isPaymentMethodInvoice } = usePricePlan();
  const { showContactForm } = useFeedback();
  const { isDialogOpen, dialogName, dialogProps } = useLegacyBridge();

  const accountTrial = computed(() => account.value.pricePlanData?.trials?.[featureKey] || undefined);

  const trialDaysRemaining = computed(() =>
    Math.floor(DateTime.fromISO(accountTrial.value?.endDate).diff(DateTime.now(), 'days').toObject().days),
  );

  const exactTrialTimeRemaining = computed(() =>
    DateTime.fromISO(accountTrial.value?.endDate).diff(DateTime.now(), 'millisecond').toMillis(),
  );

  const isTrialActive = computed(() => Boolean(accountTrial.value && exactTrialTimeRemaining.value >= 0));
  const isTrialExpired = computed(() => Boolean(accountTrial.value && !isTrialActive.value));

  const hasTrialEverStarted = computed(() => Boolean(accountTrial.value));

  const totalTrialDays = computed(() => {
    switch (featureKey) {
      case SCHEDULED_REPORTS_FEATURE_KEY:
        return SCHEDULED_REPORTS_FEATURE_TRIAL_LENGTH;
      case RETAINER_BUDGETS_FEATURE_KEY:
        return RETAINER_BUDGETS_FEATURE_TRIAL_LENGTH;
      case TASKLIST_BUDGETS_FEATURE_KEY:
        return TASKLIST_BUDGETS_FEATURE_TRIAL_LENGTH;
      case PROFITABILITY_FEATURE_KEY:
        return PROFITABILITY_FEATURE_TRIAL_LENGTH;
      default:
        return 7;
    }
  });

  const hasTrialExpiryLapsed = computed(() => Math.abs(trialDaysRemaining.value) >= 30);

  const showBanner = computed(
    () =>
      isTrialActive.value ||
      (isTrialExpired.value &&
        !hasTrialExpiryLapsed.value &&
        featureTrialsBannersVisibility.value[featureKey.toLowerCase()]),
  );

  const reasonForFeatureAccess = computed(() => {
    let reason = 'unknown';
    if (!isFeatureEnabled.value) {
      reason = 'not_part_of_plan';
    } else if (isOverFeatureLimit.value) {
      reason = 'limit_reached';
    }
    return reason;
  });

  function contactSales() {
    const salesExpansionInbox = 2749;

    const body = {
      feedback: {
        source: 'Teamwork Projects',
        type: 'Feature trial - Scale plan upgrade',
        summary: 'This account has expressed an interest in upgrade to Scale plan',
        installationId: account.value.id,
        email: user.value.emailAddress,
        inboxId: salesExpansionInbox,
        sendToHubspot: true,
        message: `
            Name: ${user.value.firstName} ${user.value.lastName} \n
            Installation: ${account.value.id} \n
            Email: ${user.value.emailAddress} \n
            Feature trial ID: ${accountTrial.value?.featureId} \n
            Message Content: This account has expressed an interest in upgrade to Scale plan after trialling feature: ${featureKey} \n
          `,
      },
    };

    return postFeedback(body).then(() => {
      toast.success(t('A member of our team will be in touch shortly.'));
    });
  }

  async function goToCheckoutOrContactCS() {
    if (isCancellationScheduled.value) {
      dialogName.value = 'ReactivateSubscriptionModal';
      dialogProps.value = { needConfirmation: true };
      isDialogOpen.value = true;
      return;
    }

    if (isPaymentMethodInvoice.value || !canAccessCheckout.value) {
      dialogName.value = 'ContactFormDialog';
      isDialogOpen.value = true;
      dialogProps.value = {
        formType: 'scaleUpgradeRequest',
        title: t('Contact Us'),
        subtitle: t(
          'Simply fill out your details here to make changes to your subscription, and your Customer Success representative will contact you to figure out the best solution for your needs.',
        ),
        entryPoint: '',
      };
      showContactForm(dialogProps.value);
      return;
    }

    router.push({ path: '/redirect/checkout' });
  }

  const fteSubtitleText = computed(() => {
    if (isTrialActive.value) {
      return t('Your trial ends today | Your trial ends tomorrow | Your trial ends in {n} days', {
        n: Math.abs(trialDaysRemaining.value),
      });
    }
    if (isTrialExpired.value) {
      return t('Your trial ended today | Your trial ended yesterday | Your trial ended {n} days ago', {
        n: Math.abs(trialDaysRemaining.value),
      });
    }
    return t('Available on the {grow} & {scale} plans', { grow: 'Grow', scale: 'Scale' });
  });

  return {
    accountTrial,
    isTrialActive,
    isTrialExpired,
    trialDaysRemaining,
    hasTrialEverStarted,
    totalTrialDays,
    hasTrialExpiryLapsed,
    showBanner,
    contactSales,
    featureLimit,
    featureHasLimits,
    reasonForFeatureAccess,
    goToCheckoutOrContactCS,
    fteSubtitleText,
  };
}
