<script setup>
import { useI18n } from '@/util';

const { t } = useI18n();
</script>
<template>
  <div class="text-body-1">
    <p>
      {{
        t(
          'You can manage and utilize task templates directly in the Table or List area of a project. Hover over the relevant task list and click the three dots on the right. Click the Advanced option in the dropdown menu, hover over Templates and select Manage Task Templates.',
        )
      }}
    </p>
    <p class="pt-6">
      {{
        t(
          'Note: Standard users with permission to manage task templates will also need to have project-level permission to add tasks enabled to see the Templates menu items at task list level.',
        )
      }}
    </p>
  </div>
</template>
