<script setup>
import { useI18n } from '@/util';

const { t } = useI18n();
</script>
<template>
  <div class="text-body-1">
    <p>
      {{
        t(
          'Quickly and easily build your own project templates from scratch or turn an existing project into a template. Choose who has access to each template and find them with ease by adding a category and tags.',
        )
      }}
    </p>
    <p class="pt-6">
      {{
        t(
          'You can (and should) customize projects even as you launch them from a template. Only include template items that are needed in the new project.',
        )
      }}
    </p>
  </div>
</template>
