import * as TOKENS from '@teamwork/lightspeed-design-tokens';

const defaultColors = [
  TOKENS.LsdsCColorPickerColor1,
  TOKENS.LsdsCColorPickerColor2,
  TOKENS.LsdsCColorPickerColor3,
  TOKENS.LsdsCColorPickerColor4,
  TOKENS.LsdsCColorPickerColor5,
  TOKENS.LsdsCColorPickerColor6,
  TOKENS.LsdsCColorPickerColor7,
  TOKENS.LsdsCColorPickerColor8,
  TOKENS.LsdsCColorPickerColor9,
  TOKENS.LsdsCColorPickerColor10,
  TOKENS.LsdsCColorPickerColor11,
  TOKENS.LsdsCColorPickerColor12,
];

let colorsToPick = [];

export function getRandomPickerColor() {
  if (!colorsToPick.length) {
    colorsToPick = [...defaultColors];
  }

  const pickedColor = colorsToPick[Math.floor(Math.random() * colorsToPick.length)];
  colorsToPick = colorsToPick.filter((color) => color !== pickedColor);

  return pickedColor;
}
