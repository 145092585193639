<script setup>
import { useI18n } from '@/util';

const { t } = useI18n();
</script>
<template>
  <div class="text-body-1">
    <p>
      {{
        t(
          'To start tracking your time, hover over the clock icon on your task and hit Start Timer. This sets the timer into action on the bottom left-hand corner of your screen. Need to take a break? Simply hit the pause button and resume the timer when you’re ready to dive back into the task.',
        )
      }}
    </p>
    <p class="pt-6">
      {{
        t(
          'Finished for the day but not finished with the task? Hit Pause and log to mark your time against the task, check the billable box or add a quick description if needed, and hit Save Time Log.',
        )
      }}
    </p>
    <p class="pt-6">
      {{
        t(
          'To manually log time, select Log More Time from the clock icon. Click into the task for an overview of the time you’ve logged.',
        )
      }}
    </p>
  </div>
</template>
