<script setup lang="ts">
const props = defineProps({
  /** The text to be parsed. This text can contain slots in the format of {slotName}. */
  text: {
    type: String,
    required: true,
  },
});

const parts = computed(() =>
  Array.from(props.text.matchAll(/(\{[^}]*\})|([^][^{]*)/g), (match) =>
    match[1]
      ? {
          slotName: match[1].slice(1, -1),
          string: match[1],
        }
      : {
          slotName: null,
          string: match[2],
        },
  ),
);
</script>

<template>
  <template v-for="{ slotName, string } in parts">
    <slot v-if="slotName" :name="slotName">{{ string }}</slot>
    <template v-else>{{ string }}</template>
  </template>
</template>
