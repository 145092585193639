<script setup>
const props = defineProps({
  repeatPeriod: {
    type: Number,
    default: 1,
  },
  repeatUnit: {
    type: String,
    default: 'month',
  },
});

const emit = defineEmits(['set']);

const OnboardingWizardBudgetCustomRepeatIntervalDialogPanel = defineAsyncComponent(
  () => import('./OnboardingWizardBudgetCustomRepeatIntervalDialogPanel.vue'),
);

function handleRepeatIntervalSet(period, unit) {
  emit('set', period, unit);
}
</script>

<template>
  <LscDialog width="360px">
    <template #default="{ close }">
      <OnboardingWizardBudgetCustomRepeatIntervalDialogPanel
        v-bind="props"
        @set="handleRepeatIntervalSet"
        @close="close"
      />
    </template>
  </LscDialog>
</template>
