<script setup>
import { useI18n } from '@/util';
import LscTableHeaderCellOrder from './LscTableHeaderCellOrder.vue';
import { useLscTable } from './useLscTable';

const props = defineProps({
  column: {
    type: Object,
    required: true,
  },
});

const { t } = useI18n();
const {
  toggleOrder,
  clearOrder,
  isOrderedBy: _isOrderedBy,
  moveColumn,
  canMoveColumnLeft: _canMoveColumnLeft,
  canMoveColumnRight: _canMoveColumnRight,
} = useLscTable();

const canMoveColumnLeft = computed(() => _canMoveColumnLeft(props.column));
const canMoveColumnRight = computed(() => _canMoveColumnRight(props.column));
const isOrderedBy = computed(() => _isOrderedBy(props.column));

function getAriaLabel(column) {
  if (!column.name || !column.orderBy) {
    return undefined;
  }
  return t('Sort column by "{columnName}"', { columnName: column.name });
}
</script>

<template>
  <Component
    :is="column.orderBy ? 'button' : 'span'"
    v-bind="$attrs"
    :class="[
      'relative inline-flex min-w-0 items-center gap-1 text-subtle text-overline aria-pressed:text-primary',
      'group-data-[align=left]/LscTableHeaderCell:justify-start',
      'group-data-[align=center]/LscTableHeaderCell:justify-center',
      'group-data-[align=right]/LscTableHeaderCell:justify-end',
      { 'cursor-pointer': column.orderBy },
    ]"
    :aria-label="getAriaLabel(column)"
    :aria-pressed="isOrderedBy"
    data-identifier="table-header"
    @click.stop="toggleOrder(column)"
  >
    <LscOverflowEllipsis>{{ column.name }}</LscOverflowEllipsis>
    <slot name="append">
      <LscIcon
        v-if="column.tooltip"
        v-LsdTooltip="column.tooltip"
        size="sm"
        class="shrink-0 text-icon-subtle outline-none"
        icon="lsi-tooltip"
      />
    </slot>

    <LscTableHeaderCellOrder
      v-if="column.orderBy"
      class="shrink-0"
      :class="isOrderedBy ? 'text-icon-primary-default' : 'text-icon-subtle'"
    />
  </Component>
  <WidgetOptionsMenu
    v-if="canMoveColumnLeft || canMoveColumnRight || isOrderedBy"
    transition="none"
    location="bottom end"
  >
    <template #activator="activator">
      <LscIconButton
        icon="lsi-dropdown"
        v-bind="activator.props"
        size="xs"
        class="hidden shrink-0 group-hover/LscTableHeaderCell:inline-flex aria-expanded:inline-flex group-data-[align=left]/LscTableHeaderCell:ml-auto"
        :ariaLabel="t('Options')"
        variant="primary"
        data-identifier="table-header-options"
      />
    </template>
    <template v-if="isOrderedBy">
      <WidgetOptionsMenuItem :prepend="{ icon: 'lsi-clear', size: 'md' }" @click="clearOrder">
        {{ t('Clear sorting') }}
      </WidgetOptionsMenuItem>
      <WidgetOptionsMenuItem :prepend="{ icon: 'lsi-return', size: 'md' }" @click="toggleOrder(column)">
        {{ t('Reverse sorting order') }}
      </WidgetOptionsMenuItem>
      <div v-if="canMoveColumnLeft || canMoveColumnRight" class="border-t border-separator" />
    </template>
    <WidgetOptionsMenuItem
      v-if="canMoveColumnLeft"
      :prepend="{ icon: 'lsi-arrow-left', size: 'md' }"
      @click="moveColumn(column, -1)"
    >
      {{ t('Move column left') }}
    </WidgetOptionsMenuItem>
    <WidgetOptionsMenuItem
      v-if="canMoveColumnRight"
      :prepend="{ icon: 'lsi-arrow-right', size: 'md' }"
      @click="moveColumn(column, 1)"
    >
      {{ t('Move column right') }}
    </WidgetOptionsMenuItem>
  </WidgetOptionsMenu>
</template>
