/**
 * Generates a report in a new tab
 * @param {string} url
 * @param {Object} params
 * @param {{ method: 'GET' | 'POST'}} options
 */
export function generateExport(url, params, options = { method: 'POST' }) {
  // create a form element and set its attributes
  const form = document.createElement('form');
  form.style.display = 'none';
  form.id = 'export-form';
  form.method = options.method;
  form.action = url;
  form.target = '_blank';

  // create input elements for each key in the params object
  Object.entries(params).forEach(([key, value]) => {
    if (value == null) {
      return;
    }
    const input = document.createElement('input');
    input.type = 'hidden';
    input.name = key;
    input.value = typeof value === 'object' ? JSON.stringify(value) : value;
    form.appendChild(input);
  });

  document.body.appendChild(form);
  form.submit();
  document.body.removeChild(form);
}
