<script setup>
import { debouncedRef } from '@vueuse/core';
import { useLoadingCount, usePermissions, useTagsV3Loader } from '@/api';
import { useI18n } from '@/util';
import LscButton from '../../../components/action/button/LscButton.vue';
import LscEmptyState from '../../../components/infodisplay/empty/LscEmptyState.vue';
import LscOverflowEllipsis from '../../../components/infodisplay/overflow/LscOverflowEllipsis.vue';
import LscSkeleton from '../../../components/infodisplay/skeleton/LscSkeleton.vue';
import LscSearchBar from '../../../components/input/searchbar/LscSearchBar.vue';
import LswLoaderState from '../../loader/LswLoaderState.vue';
import LswLoaderTrigger from '../../loader/LswLoaderTrigger.vue';
import LswTagPickerForm from './LswTagPickerForm.vue';
import { useLswTagPicker } from './useLswTagPicker';

const emit = defineEmits(['close']);

const { selectedTags, projectId, includeProjectTags } = useLswTagPicker();
const { t } = useI18n();
const { canManageTags } = usePermissions();

const searchTerm = shallowRef('');
const debouncedSearchTerm = debouncedRef(searchTerm, 300);
const pageSize = shallowRef(200);
const count = shallowRef(200);
const shouldShowTagForm = shallowRef(false);
const initialTagFormValue = shallowRef('');

const state = useTagsV3Loader({
  params: computed(() => {
    // Default: include all tags (global and project tags)
    let projectIds = [];
    if (projectId.value) {
      // Include global (0) and specific project tags
      projectIds = [0, projectId.value];
    } else if (!includeProjectTags.value) {
      // Include only global tags (0)
      projectIds = [0];
    }
    return {
      orderMode: 'desc',
      orderBy: 'projectdatelastused',
      projectIds: projectIds.join(','),
      searchTerm: debouncedSearchTerm.value,
      include: 'projects',
    };
  }),
  pageSize,
  count,
});

const { items: tags, inSync, retry } = state;

const loadingCount = useLoadingCount({ count, state, maxLoadingCount: 30 });

function isSelected(tag) {
  return selectedTags.value.some(({ id }) => id === tag.id);
}

const tagsGroupedByProject = computed(() => {
  const unselectedTags = tags.value.filter((tag) => !isSelected(tag));
  return [...Map.groupBy(unselectedTags, (tag) => tag.projectId).values()];
});

function selectTag(tag) {
  selectedTags.value = [...selectedTags.value, tag];
  emit('close');
}

function removeTag(tag) {
  selectedTags.value = selectedTags.value.filter(({ id }) => id !== tag.id);
}

function handleTagCreation(newTag) {
  selectedTags.value = [...selectedTags.value, newTag];
  shouldShowTagForm.value = false;
  emit('close');
}

function showTagForm() {
  if (!canManageTags.value) {
    return;
  }
  initialTagFormValue.value = searchTerm.value;
  searchTerm.value = '';
  shouldShowTagForm.value = true;
}

function getRandomInt(min, max) {
  const minCeiled = Math.ceil(min);
  const maxFloored = Math.floor(max);
  return Math.floor(Math.random() * (maxFloored - minCeiled) + minCeiled);
}

const skeletonItems = computed(() => {
  return Array(loadingCount.value)
    .fill(null)
    .map((_, id) => ({
      id,
      width: `${getRandomInt(50, 80)}px`,
    }));
});

watch(inSync, () => {
  if (!inSync.value || shouldShowTagForm.value || tags.value.length > 0) {
    return;
  }
  showTagForm(); // Show tag form if no tags are available
});
</script>

<template>
  <LscSheet v-if="shouldShowTagForm" class="flex max-h-96 w-72 flex-col">
    <LswTagPickerForm
      :tag="{ name: initialTagFormValue }"
      @saved="handleTagCreation"
      @clear="shouldShowTagForm = false"
    />
  </LscSheet>
  <LscSheet v-else class="flex max-h-96 w-72 flex-col !p-0">
    <LscSearchBar
      v-model="searchTerm"
      :placeholder="t('Search tags')"
      data-test-id="tag-picker-input"
      autofocus
      clearable
      class="m-4 mb-2"
      @keyup.enter="showTagForm"
    />
    <div class="flex size-full flex-col items-stretch overflow-hidden" role="listbox">
      <LswLoaderState :state="state" :blankTitle="t('No tags found')">
        <template #defaultOrLoading>
          <div class="flex flex-col gap-3 overflow-y-scroll px-4">
            <div v-if="selectedTags.length > 0">
              <div class="mb-3 flex items-center gap-2 text-body-1">
                <span>{{ t('1 selected tag | {n} selected tags', { n: selectedTags.length }) }}</span>
                <LscButton variant="plain-primary" @click.stop="selectedTags = []">
                  {{ t('Clear') }}
                </LscButton>
              </div>
              <div class="flex flex-wrap gap-x-2 gap-y-3">
                <LscChip
                  is="button"
                  v-for="tag in selectedTags"
                  :key="tag.id"
                  :color="tag.color"
                  variant="tag"
                  class="max-w-full"
                  size="sm"
                  :clearable="true"
                  @clear="removeTag(tag)"
                >
                  {{ tag.name }}
                </LscChip>
              </div>
            </div>
            <div v-for="(groupTags, index) of tagsGroupedByProject" :key="index" class="flex flex-col gap-3">
              <LscOverflowEllipsis class="text-body-1 font-semibold">
                {{ groupTags[0].projectId === 0 ? t('Global') : groupTags[0].project.name }}
              </LscOverflowEllipsis>
              <div class="flex flex-wrap gap-x-2 gap-y-3">
                <LscChip
                  is="button"
                  v-for="tag of groupTags"
                  :key="tag.id"
                  class="max-w-full"
                  :color="tag.color"
                  variant="tag"
                  size="sm"
                  @click="selectTag(tag)"
                >
                  {{ tag.name }}
                </LscChip>
              </div>
            </div>
            <div v-if="skeletonItems.length > 0" class="flex flex-wrap gap-x-2 gap-y-3">
              <LscSkeleton
                v-for="item in skeletonItems"
                :key="item.id"
                class="h-6 w-[--width] rounded-full"
                :style="{ '--width': item.width }"
              />
            </div>
            <LswLoaderTrigger v-model:count="count" :state="state" :step="pageSize" />
          </div>
          <div v-if="$slots.footer" class="mx-4 my-3 flex items-center gap-2">
            <slot name="footer" />
          </div>
          <div class="h-4 w-full flex-none" />
        </template>
        <template #error>
          <LscEmptyState :title="t('Error loading tags')">
            <template #actions>
              <LscButton variant="primary" @click="retry">{{ t('Retry') }}</LscButton>
            </template>
          </LscEmptyState>
        </template>
      </LswLoaderState>
    </div>
  </LscSheet>
</template>
