<script setup>
const props = defineProps({
  chip: {
    type: Object,
    required: true,
  },
});

const prepends = computed(() => {
  switch (props.chip.type) {
    case 'assignee':
      return props.chip.id > 0 ? 'assignees' : 'assign';
    case 'assignee-desk':
      return 'assignee-desk';
    case 'teams':
      return 'teams';
    default:
      return props.chip.icon ? 'icon' : null;
  }
});
</script>

<template>
  <LscChip
    size="sm"
    :color="chip.type === 'tag' ? chip.color : undefined"
    :variant="chip.type === 'tag' ? 'tag' : 'on-surface'"
    :class="{ '!text-subtle': chip.type === 'assignee-desk' && chip.agent?.state === 'archived' }"
    clearable
    alwaysShowClearButton
    @clear="chip.delete"
  >
    <template v-if="prepends" #prepend>
      <LscAvatar v-if="prepends === 'assignees'" size="xs" :src="chip.icon" :name="chip.name" />
      <LscAvatar v-else-if="prepends === 'assign'" size="xs" />
      <LscAvatar
        v-else-if="prepends === 'assignee-desk'"
        size="xs"
        v-bind="getLsAvatarProps({ deskAgent: chip.agent })"
      />
      <LscAvatar v-else-if="prepends === 'teams'" v-bind="getLsAvatarProps({ team: chip.team })" size="xs" />
      <LscIcon v-else-if="prepends === 'icon'" :icon="chip.icon" :color="chip.iconColor" size="sm" />
    </template>
    {{ chip.name }}
  </LscChip>
</template>
