<script setup>
const props = defineProps({
  role: {
    type: Object,
    default: () => ({}),
  },
  canAssignUsers: {
    type: Boolean,
    default: true,
  },
  canSaveAndAddAnother: {
    type: Boolean,
    default: true,
  },
  people: {
    type: Array,
    default: () => [],
  },
});

const emit = defineEmits(['trackRolesEvent']);

const JobRolesAddDialogPanel = defineAsyncComponent(() => import('./JobRolesAddDialogPanel.vue'));

function handleTrack(pageType, eventAction, eventCategory, ...args) {
  emit('trackRolesEvent', pageType, eventAction, eventCategory, ...args);
}
</script>

<template>
  <LscDialog>
    <template #default="{ close }">
      <JobRolesAddDialogPanel
        v-bind="props"
        :canSaveAndAddAnother="canSaveAndAddAnother"
        @close="close"
        @trackRolesEvent="handleTrack"
      />
    </template>
  </LscDialog>
</template>
