import { useIntervalFn } from '@vueuse/core';

/**
 * Elects a single leader from all invokations of `useLeader` with the given `groupName`
 * across all tabs, windows, frames and iframes.
 *
 * @param {string} [groupName] The name of the group which needs a leader. Defaults to an empty string.
 * @returns {ComputedRef<boolean>} `true` if this instance of `useLeader` is the leader, otherwise `false`.
 */
export function useLeader(groupName = '') {
  // `crypto.randomUUID` is supported only since Safari 15.4
  // We can remove the fallback to `Math.random` once we stop supporting the older Safari versions.
  const id = crypto?.randomUUID?.() || String(Math.random());
  // We use `localStorage` directly rather than through `useLocalStorage` to avoid any chance of race conditions.
  const idKey = `teamwork/useLeader/${groupName}/id`;
  const timestampKey = `teamwork/useLeader/${groupName}/timestamp`;
  const isLeader = shallowRef(false);

  useIntervalFn(
    () => {
      const currentLeaderId = localStorage.getItem(idKey);
      const currentLeaderTimestamp = Number(localStorage.getItem(timestampKey));
      const now = Date.now();

      if (currentLeaderId === id) {
        // Remain a leader.
        localStorage.setItem(timestampKey, String(now));
        isLeader.value = true;
      } else if (now - 3000 <= currentLeaderTimestamp && currentLeaderTimestamp <= now) {
        // Remain a follower.
        isLeader.value = false;
      } else {
        // Become a leader.
        localStorage.setItem(idKey, id);
        localStorage.setItem(timestampKey, String(now));
        isLeader.value = true;
      }
    },
    1000,
    { immediateCallback: true },
  );

  return isLeader;
}
