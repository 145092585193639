import { useItemLoader } from '../base/useItemLoader';
import { useRealTimeUpdates } from '../base/useRealTimeUpdates';

function responseToItem(response) {
  return response.data?.integrations ?? [];
}

export function useIntegrationsV2Loader() {
  const { state, refresh } = useItemLoader({
    url: '/synthesis/api/v2/integrations.json',
    responseToItem,
  });

  useRealTimeUpdates((event) => {
    if (event.type === 'integration' && ['update', 'deleted', 'enabled'].includes(event.action)) {
      refresh();
    }
    if (event.type === 'calendar' && event.action === 'sync-enabled') {
      refresh();
    }
  });

  return state;
}
