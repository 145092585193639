<script setup lang="ts">
import { isMacOS } from '@/util';

const props = defineProps({
  /** The ID of the form. */
  id: {
    type: String,
    default: undefined,
  },
});

const emit = defineEmits<{
  submit: [event: Event];
}>();

const formRef = useTemplateRef('form');
const id = computed(() => props.id ?? `lsc-form-${useId()}`);

function submit() {
  formRef.value?.requestSubmit();
}

function handleKeyboardSubmit(event: KeyboardEvent) {
  // Submit the form on Ctrl/Meta+Enter.
  if (
    event.key === 'Enter' &&
    event.metaKey === isMacOS &&
    event.ctrlKey !== isMacOS &&
    !event.altKey &&
    !event.shiftKey
  ) {
    submit();
  }
}
</script>

<template>
  <form :id="id" ref="form" @keydown="handleKeyboardSubmit" @submit.prevent="emit('submit', $event)">
    <slot :id="id" :submit="submit" />
  </form>
</template>
