const symbol = Symbol('LscTabs');

/**
 * @param {object} options
 * @param {typeof import('./constants').LscTabVariants[number]} options.variant - the variant of the tabs
 */
function LscTabs({ variant, justified }) {
  return {
    variant,
    justified,
  };
}

export function provideLscTabs(options) {
  provide(symbol, LscTabs(options));
}

/**
 *
 * @returns {LscTabs}
 */
export function useLscTabs() {
  const context = inject(symbol);

  if (!context) {
    throw new Error('<LscTab> must be used within <LscTabs>');
  }

  return context;
}
