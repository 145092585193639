import { DateTime } from 'luxon';
import { useListLoader } from '../base/useListLoader';
import { useOptimisticUpdates } from '../base/useOptimisticUpdates';
import { useRealTimeUpdates } from '../base/useRealTimeUpdates';
import { useCurrentUser } from '../user/useCurrentUser';

// TODO types
function responseToItems(response) {
  const { levels } = response.data.included;
  const badges = response.data.badges || [];

  return badges.map((badge) =>
    Object.assign(
      badge,
      badge.achievedAt && { achievedAt: DateTime.fromISO(badge.achievedAt) },
      levels && { level: levels[badge.level.id] },
    ),
  );
}

/**
 * Loads a list of badges from the Teamwork v3 API.
 */
export function useBadgesV3Loader({ userId: _userId, params, count, pageSize = 50 }) {
  const user = useCurrentUser();
  const userId = computed(() => unref(_userId) ?? user.value.id);
  const url = computed(() => `/projects/api/v3/user/${userId.value}/badges.json`);

  const { state, refresh, update } = useListLoader({
    url,
    params,
    count,
    responseToItems,
    pageSize,
  });

  useOptimisticUpdates(({ promise, type, action, badge: badgeUpdates }) => {
    if (type === 'badge' && action === 'update') {
      update((badges) => {
        return badges.map((badge) => {
          return badge.id === badgeUpdates.id ? { ...badge, ...badgeUpdates } : badge;
        });
      }, promise);
    }
  });

  useRealTimeUpdates((event) => {
    if (event.type === 'badge' && event.userId === userId.value) {
      // TODO Check if the server can return only the recently modified badges,
      // and if so, pass the modified `badgeId` to `refresh` to avoid reloading all badges.
      // refresh(event.badgeId);
      refresh();
    }
  });

  return state;
}
