<script setup>
import { useI18n } from '@/util';

defineProps({
  /**
   * The data identifier of the button.
   */
  dataIdentifier: {
    type: String,
    required: true,
  },
  /**
   * The size of the button.
   * @type {PropType<'sm' | 'md' >}
   */
  size: {
    type: String,
    default: 'md',
    validator: (value) => ['sm', 'md'].includes(value),
  },
});

const { t } = useI18n();
</script>
<template>
  <div class="mr-2 flex">
    <LscButton variant="plain-primary" :size="size" :data-identifier="dataIdentifier" prependIcon="lsi-feedback">
      {{ t('Send feedback') }}
    </LscButton>
  </div>
</template>
