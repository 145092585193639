function at(n) {
  // eslint-disable-next-line no-param-reassign
  n = Math.trunc(n) || 0;
  if (n < 0) {
    // eslint-disable-next-line no-param-reassign
    n += this.length;
  }
  // OOB access is guaranteed to return undefined
  if (n < 0 || n >= this.length) {
    return undefined;
  }
  // Otherwise, this is just normal property access
  return this[n];
}

const TypedArray = Reflect.getPrototypeOf(Int8Array);
for (const C of [Array, String, TypedArray]) {
  if (!C.prototype.at) {
    Object.defineProperty(C.prototype, 'at', { value: at, writable: true, enumerable: false, configurable: true });
  }
}
