<script setup>
import useVuelidate from '@vuelidate/core';
import { useSavedfilterActions } from '@/api';
import { useI18n, useValidators } from '@/util';

const props = defineProps({
  savedFilter: {
    type: Object,
    default: null,
  },
  /**
   * The ID of the project the filter is being created for (when within the project context)
   */
  projectId: {
    type: Number,
    default: 0,
  },
});

const emit = defineEmits(['save', 'cancel']);
const { t } = useI18n();
const toast = useLsToast();
const { maxLength, helpers, required } = useValidators();
const savedFilterActions = useSavedfilterActions();

const isEditing = computed(() => Boolean(props.savedFilter?.id));
const isSaving = shallowRef(false);

// Deep reactivity is required here
// eslint-disable-next-line lightspeed/prefer-shallow-ref
const localSavedFilter = ref({
  ...(props.savedFilter || {}),
});

const rules = {
  title: {
    required: helpers.withMessage(t('Please enter a name for this filter'), required),
    maxLength: maxLength(255),
  },
  description: {
    maxLength: maxLength(255),
  },
};

const v$ = useVuelidate(rules, localSavedFilter, { $autoDirty: false });

async function save() {
  v$.value.$touch();

  if (v$.value.$invalid) {
    return;
  }

  isSaving.value = true;

  const {
    description,
    includesSort,
    projectId: filterProjectId,
    section,
    title,
    parameters,
    projectSpecific,
  } = localSavedFilter.value;

  // Note: due to differences between the payload and response objects we need to map the properties before submission
  const payload = {
    // Note: API doesn't like `id` being passed on creation, even if it's 0
    id: isEditing.value ? localSavedFilter.value.id : undefined,
    description,
    includesSort,
    projectId: (() => {
      // Note: when a saved filter is not project-specific, the `projectId` simply indicates the
      // project it was created on. When editing a filter to be project-specific, the `projectId`
      // must be set to the current project
      if (isEditing.value) {
        return projectSpecific ? props.projectId : filterProjectId;
      }

      return props.projectId;
    })(),
    section,
    title,
    parameters,
    isProjectSpecific: projectSpecific,
  };

  const newFilter = await (async () => {
    if (isEditing.value) {
      return savedFilterActions.updateSavedfilter(payload);
    }

    return savedFilterActions.createSavedfilter(payload);
  })();

  isSaving.value = false;

  toast.success(t('Filter saved'));

  emit('save', newFilter);
}

watch(
  () => props.savedFilter,
  (newVal = {}) => {
    const defaults = {
      title: '',
      description: '',
      includesSort: false,
      projectSpecific: false,
      default: false,
      temporary: false,
    };

    localSavedFilter.value = { ...defaults, ...newVal };
  },
);

const showProjectSpecificOption = computed(() => Boolean(localSavedFilter.value.projectId));
const showIncludesSortOption = computed(() => false);
</script>

<template>
  <div class="flex flex-col gap-4">
    <div class="flex flex-col gap-2 pt-4">
      <VTextField
        v-model.trim="localSavedFilter.title"
        :disabled="isSaving"
        :label="t('Name')"
        autofocus
        :errorMessages="v$?.title?.$errors.map(({ $message }) => $message)"
      />

      <VTextarea
        v-model="localSavedFilter.description"
        :disabled="isSaving"
        :rows="3"
        :maxRows="9"
        :autoGrow="false"
        :label="`${t('Description')} (${t('optional')})`"
        :errorMessages="v$?.description?.$errors.map(({ $message }) => $message)"
      />

      <LscCheckbox v-if="showProjectSpecificOption" v-model="localSavedFilter.projectSpecific" :disabled="isSaving">
        <template #label>
          <div class="flex items-center gap-1">
            {{ t('Applies to this project only') }}
            <LscIcon
              v-LsdTooltip="
                t(
                  'If checked, then this filter will only show on this project. If not checked, this filter will be available on all projects.',
                )
              "
              class="text-icon-subtle"
              icon="lsi-tooltip"
              size="sm"
            />
          </div>
        </template>
      </LscCheckbox>

      <LscCheckbox v-if="showIncludesSortOption" v-model="localSavedFilter.includesSort" :disabled="isSaving">
        <template #label>
          <div class="flex items-center gap-1">
            {{ t('Include sort options') }}
            <LscIcon
              v-LsdTooltip="
                t(
                  'If checked, this saved filter will include your current sort settings. Otherwise your most recent sort preference will be used.',
                )
              "
              class="text-icon-subtle"
              icon="lsi-tooltip"
              size="sm"
            />
          </div>
        </template>
      </LscCheckbox>
    </div>

    <div class="flex flex-wrap justify-between gap-2">
      <LscButton variant="tertiary" size="md" :disabled="isSaving" @click="emit('cancel')">
        {{ t('Cancel') }}
      </LscButton>
      <LscButton size="md" variant="primary" :disabled="isSaving" :loading="isSaving" @click="save">
        {{ t('Save filter') }}
      </LscButton>
    </div>
  </div>
</template>
