import { useCurrentAccount, useCurrentAccountState } from '../account/useCurrentAccount';
import { useCurrentUser, useCurrentUserState } from '../user/useCurrentUser';
import { usePreferenceActions } from './usePreferenceActions';

/**
 * @template Type
 * @typedef {Object} PreferenceType
 * @property {function(string|Type): Type} parse
 * @property {function(Type): string} serialize
 */

/**
 * @param {boolean} defaultValue
 * @returns {PreferenceType<boolean>}
 */
export function useBooleanType(defaultValue = false) {
  return {
    parse(value) {
      switch (typeof value) {
        case 'boolean': {
          return value;
        }
        case 'string': {
          switch (value.toLowerCase()) {
            case 'true':
            case 'yes': {
              return true;
            }
            case 'false':
            case 'no': {
              return false;
            }
            default: {
              return unref(defaultValue);
            }
          }
        }
        default: {
          return unref(defaultValue);
        }
      }
    },
    serialize(value) {
      return JSON.stringify(value);
    },
  };
}

/**
 * @param {string} defaultValue
 * @returns {PreferenceType<string>}
 */
export function useStringType(defaultValue = '') {
  return {
    parse(value) {
      switch (typeof value) {
        case 'string': {
          return value;
        }
        default: {
          return unref(defaultValue);
        }
      }
    },
    serialize(value) {
      return value.toString();
    },
  };
}

/**
 * @param {Object} defaultValue
 * @returns {PreferenceType<Object>}
 */
export function useObjectType(defaultValue = {}) {
  return {
    parse(value) {
      switch (typeof value) {
        case 'object': {
          if (value !== null && !Array.isArray(value)) {
            return value;
          }
          return unref(defaultValue);
        }
        case 'string': {
          try {
            const parsedValue = JSON.parse(value);
            if (typeof parsedValue === 'object' && parsedValue !== null && !Array.isArray(parsedValue)) {
              return { ...unref(defaultValue), ...parsedValue };
            }
            return unref(defaultValue);
          } catch (error) {
            return unref(defaultValue);
          }
        }
        default: {
          return unref(defaultValue);
        }
      }
    },
    serialize(value) {
      return JSON.stringify(value);
    },
  };
}

/**
 * @param {*[]} defaultValue
 * @returns {PreferenceType<*[]>}
 */
export function useArrayType(defaultValue = []) {
  return {
    parse(value) {
      if (Array.isArray(value)) {
        return value;
      }

      if (typeof value !== 'string' || !value) {
        return unref(defaultValue);
      }
      try {
        const parsedValue = JSON.parse(value);

        if (Array.isArray(parsedValue)) {
          return parsedValue;
        }
      } catch (error) {
        //
      }

      return unref(defaultValue);
    },
    serialize(value) {
      return JSON.stringify(value);
    },
  };
}

/**
 * Returns a writable computed which is automatically synced with the given account preference.
 * @template Type
 * @param {string} name
 * @param {PreferenceType<Type>} type
 * @returns {WritableComputedRef<Type>}
 */
export function useAccountPreference(name, type) {
  const account = useCurrentAccount();
  const { saveAccountPreferences } = usePreferenceActions();
  return computed({
    get() {
      return type.parse(account.value?.preferences?.[name]);
    },
    set(value) {
      saveAccountPreferences({ [name]: type.serialize(value) });
    },
  });
}

/**
 * Returns a writable computed which is automatically synced with the given account preference.
 * @template Type
 * @param {string} name
 * @param {PreferenceType<Type>} type
 * @returns {WritableComputedRef<Type>}
 */
export function useUserPreference(name, type) {
  const user = useCurrentUser();
  const { saveUserPreferences } = usePreferenceActions();
  return computed({
    get() {
      return type.parse(user.value?.preferences?.[name]);
    },
    set(value) {
      saveUserPreferences({ [name]: type.serialize(value) });
    },
  });
}

/**
 * Indicates if all preferences have been already initialized with the values from the backend API.
 * @returns {ComputedRef<boolean>}
 */
export function useInitialized() {
  const initialized = shallowRef(false);
  const { inSync: accountInSync } = useCurrentAccountState();
  const { inSync: userInSync } = useCurrentUserState();

  const unwatch = watch(
    [accountInSync, userInSync],
    () => {
      if (accountInSync.value && userInSync.value) {
        initialized.value = true;
        // prevent potential undefined value on first call
        queueMicrotask(() => {
          unwatch();
        });
      }
    },
    { immediate: true },
  );

  return initialized;
}
