<script setup>
const props = defineProps({
  project: {
    type: Object,
    required: true,
  },
});

const ProjectAddPeopleDialogPanel = defineAsyncComponent(() => import('./ProjectAddPeopleDialogPanel.vue'));
</script>

<template>
  <LscDialog>
    <template #activator="activator"><slot name="activator" v-bind="activator" /></template>
    <template #default="{ close }"><ProjectAddPeopleDialogPanel v-bind="props" @close="close" /></template>
  </LscDialog>
</template>
