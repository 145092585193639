<script setup>
import { useI18n } from '@/util';

const { t } = useI18n();
</script>
<template>
  <div class="text-body-1">
    <p>
      {{
        t(
          "The Project Health report is your mission control - where you'll know all of your project statuses at a single glance.",
        )
      }}
    </p>
    <p class="pt-6">
      {{
        t(
          'The interactive table view format with customizable columns and column sorting enables you to hide irrelevant information while highlighting the key information critical for your project completion.',
        )
      }}
    </p>
    <p class="pt-6">
      {{
        t(
          "Subsequently, you'll have both the time and information you need to address any potential risks as your team's work progresses.",
        )
      }}
    </p>
  </div>
</template>
