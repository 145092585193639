import * as TOKENS from '@teamwork/lightspeed-design-tokens';

export function useWorkflowStageAvailableColor({ usedColors: _usedColors = [] }) {
  const stageColors = [
    TOKENS.LsdsCColorPickerColor1,
    TOKENS.LsdsCColorPickerColor2,
    TOKENS.LsdsCColorPickerColor3,
    TOKENS.LsdsCColorPickerColor4,
    TOKENS.LsdsCColorPickerColor6,
    TOKENS.LsdsCColorPickerColor7,
    TOKENS.LsdsCColorPickerColor8,
    TOKENS.LsdsCColorPickerColor9,
    TOKENS.LsdsCColorPickerColor10,
    TOKENS.LsdsCColorPickerColor11,
    TOKENS.LsdsCColorPickerColor12,
  ];

  const usedColors = ref(_usedColors);

  const nextAvailableColor = computed(() => {
    const used = new Set(usedColors.value);
    for (const color of stageColors) {
      if (!used.has(color)) {
        return color;
      }
    }
    return TOKENS.LsdsCColorPickerColor12; // default color
  });

  return {
    nextAvailableColor,
  };
}
