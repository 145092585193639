<script setup>
import { useImporterStatsV3Loader } from '@/api';
import { useI18n } from '@/util';

const { t } = useI18n();
const { item } = useImporterStatsV3Loader();

const isImporting = computed(() => item.value?.isImporting);
</script>

<template>
  <LscAlert
    v-if="isImporting"
    layout="banner"
    icon="lsi-notifications"
    :message="t('Importing your projects. Please avoid archiving or deleting those projects for now.')"
  />
</template>
