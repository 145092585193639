export const LscLinearChartVariants = /** @type {const} */ ([
  'neutral',
  'warning',
  'critical',
  'success',
  'empty',
  'disabled',
  'unavailable',
]);

export const LscLinearChartDirections = /** @type {const} */ (['horizontal', 'vertical']);

export const LscLinearChartIs = /** @type {const} */ (['div', 'button']);
