import { useI18n } from '@/util';
import { useAxios } from '../base/useAxios';
import { useOptimisticUpdates } from '../base/useOptimisticUpdates';
import { useRealTimeUpdates } from '../base/useRealTimeUpdates';

let nextCustomfieldId = -1;

function toId(value) {
  return Number(value) || null;
}

export function useCustomfieldActions() {
  const api = useAxios();
  const { t } = useI18n();
  const { socketId, emit: _emitRealTimeUpdate } = useRealTimeUpdates();
  const { emit: _emitOptimisticUpdate } = useOptimisticUpdates();

  /**
   *
   * @param {Promise} promise
   * @param {'create'|'update'|'delete'} action
   * @param {object} customfield
   */
  function emitOptimisticUpdate(promise, action, customfield) {
    _emitOptimisticUpdate({
      promise,
      type: 'customfield',
      action,
      customfield,
    });
  }

  /**
   *
   * @param {string} action
   * @param {object} customfield
   */
  function emitRealTimeUpdate(action, customfield) {
    _emitRealTimeUpdate({
      type: 'customfield',
      action,
      customfieldId: toId(customfield.id),
      projectId: toId(customfield.projectId),
    });
  }

  return {
    createCustomfield(customfield) {
      const newCustomfieldId = nextCustomfieldId--;
      const promise = api
        .post(
          '/projects/api/v3/customfields.json',
          { customfield },
          {
            headers: {
              'Socket-ID': socketId,
            },
            errorMessage(error) {
              const errorMessageDetail = error?.response?.data?.errors?.reduce(
                (errorDetails, errorMsg) => `${errorDetails} ${errorMsg.detail}`,
                '',
              );

              if (errorMessageDetail?.includes('already exists')) {
                return t('Custom field with same name and type already exists');
              }
              if (errorMessageDetail?.includes('repeated values in the dropdown value')) {
                return t('Options cannot have duplicate names.');
              }
              return t('Error creating custom field');
            },
          },
        )
        .then(({ data }) => {
          const created = { ...data.customfield, tempId: newCustomfieldId };
          emitOptimisticUpdate(promise, 'update', created);
          emitRealTimeUpdate('new', data.customfield);
          return created;
        });
      emitOptimisticUpdate(promise, 'create', { ...customfield, id: newCustomfieldId });
      return promise;
    },

    updateCustomfield(customfield) {
      const promise = api
        .put(
          `/projects/api/v3/customfields/${customfield.id}.json`,
          { customfield },
          {
            headers: {
              'Socket-ID': socketId,
            },
            errorMessage(error) {
              const errorMessageDetail = error?.response?.data?.errors?.reduce(
                (errorDetails, errorMsg) => `${errorDetails} ${errorMsg.detail}`,
                '',
              );

              if (errorMessageDetail?.includes('already exists')) {
                return t('Custom field with same name and type already exists');
              }
              if (errorMessageDetail?.includes('repeated values in the dropdown value')) {
                return t('Options cannot have duplicate names.');
              }
              return t('Error updating custom field');
            },
          },
        )
        .then(({ data }) => {
          emitRealTimeUpdate('edited', data.customfield);
          return data.customfield;
        });
      emitOptimisticUpdate(promise, 'update', customfield);
      return promise;
    },

    deleteCustomfield(customfield) {
      const promise = api
        .delete(`/projects/api/v3/customfields/${customfield.id}.json`, {
          headers: {
            'Socket-ID': socketId,
          },
          errorMessage: t('Error deleting custom field'),
        })
        .then(() => {
          emitRealTimeUpdate('deleted', customfield);
        });
      emitOptimisticUpdate(promise, 'delete', customfield);
      return promise;
    },
  };
}
