export function normalizeFilterBoolean(value, defaultValue = false) {
  if (typeof value === 'boolean') {
    return value;
  }
  if (value === 'YES' || value === '1') {
    return true;
  }
  if (value === 'NO' || value === '0') {
    return false;
  }
  return defaultValue;
}

export function normalizeFilterIds(value, defaultValue = '') {
  if (typeof value !== 'string') {
    return defaultValue;
  }
  if (/^$|^\d+(,\d+)*$/.test(value)) {
    return value;
  }
  return defaultValue;
}
