<script setup lang="ts">
import type { LscAvatarStackAvatarProps } from './LscAvatarStack.types.js';
import { LscAvatarSizes } from './LscAvatar.types.js';
import LscAvatar from './LscAvatar.vue';

const props = defineProps({
  /** The avatars to display.*/
  avatars: {
    type: Array as PropType<LscAvatarStackAvatarProps[]>,
    default: () => [],
  },
  /** The size of the avatars in the stack.*/
  size: {
    type: String as PropType<NonNullable<LscAvatarSize>>,
    default: 'sm',
    validator: (value: LscAvatarSize) => LscAvatarSizes.includes(value),
  },
  /** The maximum number of avatars to display.*/
  max: {
    type: Number,
    default: 3,
  },
  /** Whether the avatar is clearable.*/
  clearable: {
    type: Boolean,
    default: false,
  },
  /** Whether to show tooltips on the avatars.*/
  showTooltips: {
    type: Boolean,
    default: true,
  },
  /** A function to generate the tooltip text for the removing the avatar.*/
  removeTooltipFunction: {
    type: Function as PropType<(name?: string) => string | undefined>,
    default: undefined,
  },
});

const emit = defineEmits(['remove']);

const displayedAvatars = computed(() => props.avatars.slice(0, props.max));
const remainingAvatars = computed(() => props.avatars.slice(props.max, props.avatars.length));

const remainingCount = computed(() =>
  remainingAvatars.value.length > 99 ? '99+' : `+${remainingAvatars.value.length}`,
);

const remainingAvatarTooltip = computed(() => {
  if (!props.showTooltips) {
    return undefined;
  }
  return remainingAvatars.value
    .map((avatar) => avatar?.name)
    .filter(Boolean)
    .join('\n');
});

const avatarStackVariantStyleConfig = tv({
  base: 'relative flex',
  slots: {
    avatar: 'hover:z-10',
    showMore: 'cursor-pointer',
  },
  variants: {
    size: {
      xxs: { base: '-space-x-1' },
      xs: { base: '-space-x-1' },
      sm: { base: '-space-x-1' },
      md: { base: '-space-x-2' },
      lg: { base: '-space-x-2' },
      xl: { base: '-space-x-3' },
      xxl: { base: '-space-x-4' },
    },
    hasRing: {
      true: {
        avatar: 'ring-1 ring-reversed',
      },
    },
  },
});

const classes = computed(() =>
  avatarStackVariantStyleConfig({
    size: props.size,
    hasRing: props.avatars.length > 1,
  }),
);
</script>

<template>
  <div v-if="displayedAvatars.length" :class="classes.base()">
    <LscAvatar
      v-for="(avatar, index) in displayedAvatars"
      :key="index"
      :class="classes.avatar()"
      :size="size"
      :removeTooltipFunction="removeTooltipFunction"
      :showTooltip="showTooltips"
      :clearable="clearable"
      v-bind="avatar"
      @remove="emit('remove', avatar)"
    />
    <LscAvatar
      v-if="remainingAvatars.length"
      v-LsdTooltip="remainingAvatarTooltip"
      :class="[classes.avatar(), classes.showMore()]"
      bgColor="var(--lsds-a-color-surface-dark)"
      iconColor="var(--lsds-a-color-text-on-dark)"
      :size="size"
      :removeTooltipFunction="removeTooltipFunction"
      :showTooltip="false"
    >
      {{ remainingCount }}
    </LscAvatar>
  </div>
</template>
