export function createFormatNumber({ languageCode }) {
  /**
   * Returns a formatted number as a string.
   * @param {Parameters<Intl.NumberFormat['format']>[0]} value
   * @param {Intl.NumberFormatOptions} [options] Optional object with options on how to format the number.
   */
  function formatNumber(value, options) {
    return Intl.NumberFormat(languageCode.value, options).format(value);
  }

  /**
   * Returns a formatted number as a string.
   * @param {Parameters<Intl.NumberFormat['format']>[0]} value
   * @param {Intl.NumberFormatOptions} [options] Optional object with options on how to format the number.
   */
  function formatNumberToParts(value, options) {
    return Intl.NumberFormat(languageCode.value, options).formatToParts(value);
  }

  /**
   * Converts a decimal number to a percentage string.
   * @param {Parameters<formatNumber>[0]} value
   * @param {Intl.NumberFormatOptions} [options] Optional object with options on how to format the percentage.
   * @returns {String} Formatted percentage string.
   */
  function formatPercentage(value, options = {}) {
    return formatNumber(value, {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
      style: 'percent',
      ...options,
    });
  }

  /**
   * Converts a decimal number between 0 and 1 to a percentage string without the percent sign.
   * @note If you need the percent sign, use `formatPercentage` instead.
   * @param {Parameters<formatNumberToParts>[0]} value
   * @param {Intl.NumberFormatOptions} [options] Optional object with options on how to format the percentage.
   * @returns {String} Formatted percentage string.
   */
  function convertToPercentage(value, options = {}) {
    const parts = formatNumberToParts(value, {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
      style: 'percent',
      ...options,
    });

    const numericString = parts
      .filter((part) => {
        return ['plusSign', 'minusSign', 'integer', 'decimal', 'fraction'].includes(part.type);
      })
      .map((part) => part.value)
      .join('');

    return Number(numericString);
  }

  /**
   * This function converts a percentage to a decimal number,
   * This is the reverse of `convertToPercentage`.
   * @param {number} number
   * @returns {number}
   */
  function convertFromPercentage(number) {
    return number / 100;
  }

  /**
   * Formats a file size in bytes into a human readable string.
   * @param {Parameters<formatNumber>[0]} value
   * @param {Intl.NumberFormatOptions} [options] Optional object with options on how to format the percentage.
   * @returns {String} Formatted percentage string.
   */
  function formatFileSize(value = 0, options = {}) {
    return formatNumber(value, {
      notation: 'compact',
      style: 'unit',
      unit: 'byte',
      unitDisplay: 'narrow',
      ...options,
    });
  }

  return {
    convertToPercentage,
    convertFromPercentage,
    formatNumber,
    formatPercentage,
    formatFileSize,
  };
}
