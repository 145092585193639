import { Cancel, isCancel } from 'axios';
import { DateTime } from 'luxon';
import { deepEqual, exponentialBackOff, useBackOff } from '@/util';
import { canRetry as defaultCanRetry } from './canRetry';
import { useAxios } from './useAxios';
import * as config from './useListLoaderConfig';
import { useLoaders } from './useLoaders';
import { useLoadersInSyncSource } from './useLoadersInSync';
import { useRealTimeUpdates } from './useRealTimeUpdates';

/**
 * Loads a list of items from the Teamwork API.
 * Supports pagination, retrying with customizable delay, optimistic updates and real-time updates.
 * @template Item
 * @template Meta
 *
 * @param {Object} options
 * @param {MaybeRef<string | null | undefined>} options.url The request url. If it is not a string, then nothing will be loaded.
 * @param {MaybeRef<object | null | undefined>} [options.params={}] The request params. If it is not an object, nothing will be loaded.
 * @param {MaybeRef<number>} options.count The number of items to load. If it is less then 0, then nothing will be loaded.
 * @param {(response: unknown[]) => Item[]} options.responseToItems Gets a list of items from the server response.
 * @param {(response: unknown[]) => Meta[]} [options.responseToMeta] Gets metadata from the server response. It's used only when a page of items is loaded and not when only recently modified items are refreshed.
 * @param {Parameters<Array<Item>['sort']>['0']} [options.order] A function for sorting items using `Array#sort`.
 * @param {number} [options.pageSize] The number of items to load per request when loading new items.
 * @param {string} [options.type] The type name of the loaded items.
 * @param {(retryAttempt: unknown) => number} [options.retryDelay] The retry delay strategy.
 * @param {MaybeRef<number>} [options.maxRetries] The max number of retries on server errors.
 * @param {MaybeRef<boolean>} [options.cache] Should the first request be sent directly to the browser cache.
 * @param {MaybeRef<boolean>} [options.canRefresh] Determines if the loader can process real-time updates.
 * @param {(error: import('axios').AxiosError) => boolean} [options.canRetry] Determines if the loader can retry on the given error.
 */
export function useListLoader({
  url: _url,
  params: _params = {},
  count: _count = -1,
  responseToItems: _responseToItems = () => [],
  responseToMeta: _responseToMeta = () => null,
  order: _order,
  pageSize: _pageSize = 10,
  type: _type = undefined,
  retryDelay = exponentialBackOff({ minDelay: 4000 }),
  maxRetries = Infinity,
  cache: _cache = true,
  canRefresh: _canRefresh = true,
  canRetry = defaultCanRetry,
  config: { getHasMore, getNextCursor, getLoadedCount, prepareParams } = config,
}) {
  const axios = useAxios();
  const { registerItems } = useLoaders();
  const { handlingEventFromSocket } = useRealTimeUpdates();
  const url = shallowRef(_url);
  const params = shallowRef(_params);
  const count = shallowRef(_count);
  const responseToItems = shallowRef(_responseToItems);
  const responseToMeta = shallowRef(_responseToMeta);
  const order = shallowRef(_order);
  const pageSize = shallowRef(_pageSize);
  const type = shallowRef(_type);
  const cursor = shallowRef(undefined);
  // The number of consecutive items which are guaranteed to be in sync with the server
  // counting from the beginning of the list. The actual number of loaded items might
  // be more than `loadedCount`. The guarantee is based on correctness and reliability
  // of real-time updates, which is sufficient in practice but not 100% reliable.
  const loadedCount = shallowRef(0);
  const serverHasMore = shallowRef(true);
  const lastUpdated = shallowRef(undefined);
  /** @type {ShallowRef<Map<Item['id'], Item>>} */
  const itemsMap = shallowRef(new Map());
  const updatedItemIds = shallowRef(new Set());
  const optimisticUpdates = shallowRef(new Set());
  const cancel = shallowRef(undefined);
  const backOff = useBackOff({ retryDelay, maxRetries });
  const cache = shallowRef(_cache);
  const canRefresh = shallowRef(_canRefresh);

  /** The loaded metadata. @type {ShallowRef<undefined | Meta>} */
  const meta = shallowRef(undefined);
  /** The error produced by the last axios request. @type {ShallowRef<import("axios").AxiosError|undefined>} */
  const error = shallowRef(undefined);
  /** Indicates if the loader has completed an initial load. @type {ShallowRef<boolean>} */
  const loaded = shallowRef(false);
  /** @type {ShallowRef<Item[]>} */
  const outdatedItems = shallowRef(undefined);
  const needsBuffer = shallowRef(false);
  const bufferSize = computed(() => (needsBuffer.value ? Math.ceil(pageSize.value * 0.2) : 0));
  const urlAndParamsValid = computed(() =>
    Boolean(url.value && typeof url.value === 'string' && params.value && typeof params.value === 'object'),
  );
  const needsRefresh = computed(() => updatedItemIds.value.size > 0 || Boolean(outdatedItems.value));
  /** Indicates if increasing `count` is likely to increase the number of items in `items` @type {ComputedRef<boolean>} */
  const hasMore = computed(() => {
    if (count.value < 0) {
      return true;
    }
    if (pageSize.value <= 0) {
      return false;
    }
    if (count.value < loadedCount.value) {
      return true;
    }
    return serverHasMore.value;
  });
  /**  Indicates if the loader is in sync with the server. @type {ComputedRef<boolean>} */
  const inSync = computed(() => {
    if (!urlAndParamsValid.value) {
      // invalid url or params
      return true;
    }
    return (
      // no real-time updates
      !needsRefresh.value &&
      // no optimistic updates
      optimisticUpdates.value.size === 0 &&
      // something loaded or nothing requested
      (lastUpdated.value !== undefined || count.value < 0) &&
      // loaded as much as possible, needed or available
      (pageSize.value === 0 || loadedCount.value >= count.value || !serverHasMore.value)
    );
  });
  useLoadersInSyncSource(inSync);
  let isInitialState = true;

  /** @type {Item[]} */
  let cachedItems = [];

  /** Used to prevent unwanted retries. */
  let blockRequest = false;

  /**
   * @param {Item[]} loadedItems
   */
  function applyOptimisticUpdates(loadedItems) {
    let processedItems = loadedItems;
    optimisticUpdates.value.forEach((optimisticUpdate) => {
      processedItems = optimisticUpdate.apply(processedItems);
    });
    return processedItems;
  }

  /** @type {ComputedRef<Item[]>} */
  const allItems = computed(() => {
    const newItems = applyOptimisticUpdates(outdatedItems.value || Array.from(itemsMap.value.values()));
    if (typeof order.value === 'function') {
      newItems.sort(order.value);
    }
    return newItems;
  });

  /** The loaded items @type {ComputedRef<Item[]>} */
  const items = computed(() => {
    const newItems = allItems.value.slice(0, Math.max(0, count.value));
    // If `newItems` contains the same items as `cachedItems`,
    // then we return `cachedItems` to avoid unnecessary change notifications and processing,
    // which affects performance especially when the items are rendered as Vue components.
    if (newItems.length !== cachedItems.length || newItems.some((newItem, index) => newItem !== cachedItems[index])) {
      cachedItems = newItems;
    }
    return cachedItems;
  });

  /** @type {'user'|'event/ws'|'event/local'} */
  let triggeredBy = 'user';

  const forceRefresh = Symbol('useListLoader/forceRefresh');
  const refreshLater = new Set();
  watch(canRefresh, () => {
    if (canRefresh.value && refreshLater.size > 0) {
      refreshLater.forEach(refresh);
      refreshLater.clear();
    }
  });

  function reset() {
    isInitialState = true;
    blockRequest = false;
    cursor.value = undefined;
    needsBuffer.value = false;
    loadedCount.value = 0;
    serverHasMore.value = true;
    lastUpdated.value = undefined;
    error.value = undefined;
    meta.value = undefined;
    loaded.value = false;
    itemsMap.value.clear();
    triggerRef(itemsMap);
    updatedItemIds.value.clear();
    triggerRef(updatedItemIds);
    outdatedItems.value = undefined;
    if (cancel.value) {
      cancel.value();
    }
    backOff.reset();
    triggeredBy = 'user';
    refreshLater.clear();
  }

  function resetOnChange(newValue, oldValue) {
    if (deepEqual(newValue, oldValue)) {
      return;
    }
    reset();
  }

  /**
   * Refreshes the specific item or all items by reloading them from the server.
   * @param {undefined|number|string} itemId If an id is specified, only one item is refreshed, otherwise all items are refreshed.
   */
  function refresh(itemId, force) {
    if (force !== forceRefresh && !canRefresh.value) {
      refreshLater.add(itemId);
      return;
    }

    blockRequest = false;
    if (typeof itemId === 'undefined') {
      // reload all
      outdatedItems.value ??= Array.from(itemsMap.value.values()); // temporarily return old items
      cursor.value = undefined;
      loadedCount.value = 0;
      lastUpdated.value = undefined;
      itemsMap.value.clear();
      triggerRef(itemsMap);
      updatedItemIds.value.clear();
      triggerRef(updatedItemIds);
    } else {
      // reload one
      needsBuffer.value = true;
      updatedItemIds.value.add(itemId);
      triggerRef(updatedItemIds);
    }
    if (cancel.value) {
      cancel.value();
    }
    backOff.reset();
    triggeredBy = handlingEventFromSocket.value ? 'event/ws' : 'event/local';
  }

  function getLastUpdated({ headers }) {
    // Subtract 10s from the response date for extra safety.
    // Round to the start of the minute to make backend cache hits more likely.
    return DateTime.fromHTTP(headers.date, { zone: 'utc' }).minus(10000).startOf('minute');
  }

  async function loadUpdates() {
    // no items loaded yet, so discard updatedItemIds
    if (lastUpdated.value === undefined) {
      updatedItemIds.value.clear();
      triggerRef(updatedItemIds);
      return;
    }

    // refresh all if too many items changed
    if (updatedItemIds.value.size > pageSize.value) {
      refresh();
      return;
    }

    try {
      const response = await new Promise((resolve, reject) => {
        // Marks the request as canceled but allows it to complete,
        // so that it could be cached by the browser.
        cancel.value = () => reject(new Cancel());
        axios
          .get(url.value, {
            params: prepareParams(params.value, {
              url: url.value,
              pageSize: pageSize.value,
              lastUpdated: lastUpdated.value,
              forceModernApi: import.meta.env.MODE === 'test',
            }),
            headers: {
              'Triggered-By': triggeredBy,
              'Retry-Attempt': backOff.retryAttempt.value + 1,
            },
          })
          .then(resolve, reject);
      });
      error.value = undefined;

      // refresh all if too many items changed
      if (getHasMore(response)) {
        refresh();
        return;
      }

      // When items are removed or reordered, the offsets of all subsequent items
      // are affected, which can lead to skipping some results when loading more pages.
      // We avoid that situation by adjusting `loadedCount`, which may force reloading of
      // some pages to ensure that no items are omitted in the result. In order to
      // minimize the frequency of such reloads, `loadMore` maintains a buffer of extra items
      // at the end of the list. Those items are normally hidden from the client code, so we can
      // simply reveal them instead of forcing an immediate reload. The last page(s) needs to be
      // reloaded only when we run out of the buffered items.
      // An added benefit of maintaining the buffered items is that we can make them available to
      // the client code immediately when `count` is increased, and then load more items in the background.
      let newLoadedCount = loadedCount.value;
      const loadedItemsSet = new Set();
      const loadedItems = responseToItems.value(response);
      loadedItems.forEach((item) => {
        const oldItem = itemsMap.value.get(item.id);
        if (oldItem && (typeof order.value !== 'function' || order.value(item, oldItem) !== 0)) {
          newLoadedCount -= 1; // item reordered
        }
        itemsMap.value.set(item.id, item);
        loadedItemsSet.add(item.id);
      });
      updatedItemIds.value.forEach((id) => {
        if (itemsMap.value.has(id) && !loadedItemsSet.has(id)) {
          itemsMap.value.delete(id);
          newLoadedCount -= 1; // item removed
        }
      });
      updatedItemIds.value.clear();
      triggerRef(itemsMap);
      triggerRef(updatedItemIds);

      if (serverHasMore.value) {
        loadedCount.value = Math.max(newLoadedCount, 0);
      } else {
        loadedCount.value = itemsMap.value.size;
      }

      lastUpdated.value = getLastUpdated(response);

      backOff.reset();
    } catch (axiosError) {
      if (isCancel(axiosError)) {
        return;
      }

      if (import.meta.env.MODE !== 'test') {
        // eslint-disable-next-line no-console
        console.error('Error in useListLoader:', axiosError);
      }

      error.value = axiosError;

      if (canRetry(axiosError)) {
        backOff.start();
      } else {
        blockRequest = true;
        backOff.reset();
      }
    } finally {
      cancel.value = undefined;
    }
  }

  async function loadMore() {
    if (count.value < 0) {
      outdatedItems.value = undefined;
      return; // no items are needed
    }

    // If we have something already loaded.
    if (lastUpdated.value !== undefined) {
      if (pageSize.value <= 0) {
        outdatedItems.value = undefined;
        return; // cannot load anything more
      }

      if (!serverHasMore.value) {
        outdatedItems.value = undefined;
        return; // loaded as much as available
      }

      // If after calling `refresh` we have already loaded at least as many items as
      // we have temporarily preserved as `outdatedItems`, then discard `outdatedItems`.
      if (outdatedItems.value && loadedCount.value >= outdatedItems.value.length) {
        outdatedItems.value = undefined;
      }

      if (loadedCount.value >= count.value) {
        outdatedItems.value = undefined;

        // With cursor-based pagination we can always request the next batch of items
        // without worrying about item offsets, so buffering is not needed.
        if (cursor.value !== undefined) {
          return; // loaded as much as needed
        }

        // We enable buffering only when items are refreshed for the first time
        // to minimize unnecessary server requests.
        if (bufferSize.value <= 0) {
          return; // loaded as much as needed
        }

        // We use `loadedCount` to track how many items are in sync with the server.
        // When items are removed or reordered, the offsets of all following items are changed,
        // so we decrement `loadedCount` to ensure that no items are missed when paginating.
        // When `loadedCount` falls below `count`, we must load a full page of data.
        // In order to avoid loading a full page of data every time we decrement `loadedCount`,
        // we maintain a buffer of extra items.
        if (loadedCount.value > Math.floor((count.value + bufferSize.value - 1) / pageSize.value) * pageSize.value) {
          return; // loaded as much as needed including a buffer
        }
      }
    }

    // We force using the browser cache for the initial request in order to show the cached data immediately.
    // After that request completes, we immediately call `refresh` to load fresh data from the server.
    // Note also that direct requests to the browser cache are possible only for same-origin requests.
    // See https://developer.mozilla.org/en-US/docs/Web/API/Request/cache
    const shouldUseCache =
      isInitialState && cache.value && (/^\/\w/.test(url.value) || import.meta.env.MODE === 'test');

    try {
      const response = await new Promise((resolve, reject) => {
        // Marks the request as canceled but allows it to complete,
        // so that it could be cached by the browser.
        cancel.value = () => reject(new Cancel());
        axios
          .get(url.value, {
            params: prepareParams(params.value, {
              url: url.value,
              pageSize: pageSize.value,
              loadedCount: loadedCount.value,
              cursor: cursor.value,
              forceModernApi: import.meta.env.MODE === 'test',
            }),
            headers: {
              'Triggered-By': triggeredBy,
              'Retry-Attempt': backOff.retryAttempt.value + 1,
            },
            fetchOptions: {
              // See https://developer.mozilla.org/en-US/docs/Web/API/Request/cache
              cache: shouldUseCache ? 'only-if-cached' : undefined,
              mode: shouldUseCache ? 'same-origin' : undefined,
            },
          })
          .then(resolve, reject);
      });

      const loadedItems = responseToItems.value(response);

      if (shouldUseCache && loadedItems.length === 0) {
        return;
      }

      error.value = undefined;

      serverHasMore.value = getHasMore(response);
      cursor.value = getNextCursor(response);
      meta.value = responseToMeta.value(response);
      loadedItems.forEach((item) => itemsMap.value.set(item.id, item));
      triggerRef(itemsMap);
      loadedCount.value = getLoadedCount(response, {
        hasMore: serverHasMore.value,
        loadedCountMax: itemsMap.value.size,
        loadedCountOld: loadedCount.value,
      });

      if (lastUpdated.value === undefined) {
        lastUpdated.value = getLastUpdated(response);
      }

      loaded.value = true;
      backOff.reset();
    } catch (axiosError) {
      if (shouldUseCache || isCancel(axiosError)) {
        return;
      }

      if (import.meta.env.MODE !== 'test') {
        // eslint-disable-next-line no-console
        console.error('Error in useListLoader:', axiosError);
      }

      error.value = axiosError;

      if (canRetry(axiosError)) {
        backOff.start();
      } else {
        blockRequest = true;
        backOff.reset();
      }
    } finally {
      isInitialState = false;
      cancel.value = undefined;
      if (shouldUseCache) {
        refresh(undefined, forceRefresh);
        triggeredBy = 'user';
      }
    }
  }

  function load() {
    if (blockRequest) {
      return; // got a 4xx client error on the previous request
    }
    if (cancel.value) {
      return; // loading in progress
    }
    if (backOff.active.value) {
      return; // wait for the back-off to complete
    }
    if (!urlAndParamsValid.value) {
      outdatedItems.value = undefined;
      return; // invalid url or params
    }
    if (updatedItemIds.value.size > 0) {
      loadUpdates();
    } else {
      loadMore();
    }
  }

  /**
   * Determines if the given item is in sync with the server.
   * @param {Item} itemToCheck An item to check.
   * @returns {boolean} If the `item` is in sync with the server
   */
  function itemInSync(itemToCheck) {
    return (
      Boolean(itemToCheck) &&
      itemsMap.value.get(itemToCheck.id) === itemToCheck &&
      !updatedItemIds.value.has(itemToCheck.id)
    );
  }

  /**
   * Updates the item locally, while waiting for the same change to be saved on the server.
   * @param {(item: Item | null | undefined) => Item | null | undefined} apply Gets an item and returns its new version with modifications. It MUST NOT modify the original item.
   * @param {Promise} promise A Promise tracking the request which makes the corresponding change on the server.
   */
  function update(apply, promise) {
    const optimisticUpdate = { apply, promise };
    promise.then(
      () => {
        if (needsRefresh.value) {
          // Keep the update until the data is refreshed.
          optimisticUpdate.promise = undefined;
        } else {
          // Discard the update, as it did not affect this loader.
          optimisticUpdates.value.delete(optimisticUpdate);
          triggerRef(optimisticUpdates);
        }
      },
      () => {
        // Discard the update, as it failed.
        optimisticUpdates.value.delete(optimisticUpdate);
        triggerRef(optimisticUpdates);
      },
    );
    // Apply the update optimistically.
    optimisticUpdates.value.add(optimisticUpdate);
    triggerRef(optimisticUpdates);
  }

  /**
   * Triggers an immediate retry.
   */
  function retry() {
    backOff.reset();
  }

  watch(needsRefresh, () => {
    if (!needsRefresh.value) {
      // Prune the optimistic updates which have been saved and read back from the server.
      optimisticUpdates.value.forEach((optimisticUpdate) => {
        if (!optimisticUpdate.promise) {
          optimisticUpdates.value.delete(optimisticUpdate);
          triggerRef(optimisticUpdates);
        }
      });
    }
  });

  watch(url, resetOnChange);
  watch(params, resetOnChange);
  watch(responseToItems, resetOnChange);
  watch(responseToMeta, resetOnChange);
  watch(order, resetOnChange);

  watch(cancel, load);
  watch(backOff.active, load);
  watch(updatedItemIds, load);
  watch(pageSize, load);
  watch(count, load);
  watch(cursor, load);
  watch(loadedCount, load);

  onScopeDispose(reset);
  load();
  registerItems(type, items);

  return {
    state: { items, hasMore, inSync, itemInSync, loaded, meta, error, retry },
    refresh,
    update,
  };
}
