import { useFeatures } from '../features/useFeatures';
import { usePendo } from '../features/usePendo';
import { usePermissions } from '../permissions/usePermissions';

/**
 * Constants, computed properties and functions related to Experiment R-2401
 * https://app.launchdarkly.com/default/production/features/projects-exp-r-24-01-reps-contact
 * https://digitalcrew.teamwork.com/spaces/growth/page/52724-r-24-01-self-serve-onboarding-account-manager-display
 */

export function useExperimentR2401() {
  const { projectsRepsContactEnabled } = useFeatures();
  const { trackExperimentInPendo } = usePendo();
  const { isOwnerAdmin } = usePermissions();
  const { launchDarklyFlagKey, defaultValue } = projectsRepsContactEnabled;
  const ignoreInExperimentOnRuleMatch = true;

  const expER2401AppLevelTargeting = computed(() => isOwnerAdmin?.value);

  const isExpR2401Variation = computed(() => expER2401AppLevelTargeting.value && projectsRepsContactEnabled.value);

  function trackExperimentR2401() {
    trackExperimentInPendo({
      launchDarklyFlagKey,
      defaultValue,
      ignoreInExperimentOnRuleMatch,
      appLevelTargeting: expER2401AppLevelTargeting.value,
    });
  }

  return {
    launchDarklyFlagKey,
    isExpR2401Variation,
    trackExperimentR2401,
  };
}
