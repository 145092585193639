<script setup>
import { useI18n } from '@/util';

const { t } = useI18n();
</script>
<template>
  <div class="text-body-1">
    <p>
      {{ t('Say goodbye to the hassle of managing your content review process through emails and folders.') }}
    </p>
    <p class="mt-4">
      {{
        t(
          'Create a proof for your client work and keep all feedback and versions in one place. Easily interact with clients and team members throughout the approval process, making your workflow faster and more efficient.',
        )
      }}
    </p>
  </div>
</template>
