<script setup>
import { useI18n } from '@/util';

const { t } = useI18n();
</script>
<template>
  <div class="text-body-1">
    <p>
      {{ t("Do you know you can have a bird's eye view of all your task statuses without going into each project?") }}
    </p>
    <p class="pt-6">
      {{ t('You can - you just need to turn on board columns in My Work.') }}
    </p>
  </div>
</template>
