<script setup>
import { useI18n } from '@/util';

const { t } = useI18n();
</script>
<template>
  <div class="text-body-1">
    <p>
      {{
        t(
          "Don't let the approval process slow you down by going back and forth with versions across different platforms. With the dedicated Proofs section within {teamwork}, you now have the ability to manage a proof through its entire lifecycle—from creation to revision to final client approval.",
          { teamwork: 'Teamwork.com' },
        )
      }}
    </p>
    <p class="pt-6">
      {{
        t(
          "Create proofs in {teamwork}, then invite key stakeholders (including your clients) to review and approve the proof. Your clients don't need to be added to your {teamwork} site in order to access the proofing tool and contribute to the proofing process.",
          { teamwork: 'Teamwork.com' },
        )
      }}
    </p>
  </div>
</template>
