<script setup>
import useVuelidate from '@vuelidate/core';
import { useFeatures, useTagActions } from '@/api';
import { useI18n, useValidators } from '@/util';
import LscButton from '../../../components/action/button/LscButton.vue';
import LscMessage from '../../../components/infodisplay/message/LscMessage.vue';
import LscCheckbox from '../../../components/input/checkbox/LscCheckbox.vue';
import LscIcon from '../../../components/media/icon/LscIcon.vue';
import LscColorPicker from '../../../components/picker/color/LscColorPicker.vue';
import LscForm from '../../../components/utility/form/LscForm.vue';
import LswProjectPicker from '../project/LswProjectPicker.vue';
import { useLswTagPicker } from './useLswTagPicker';

/**
 * @typedef {import('./useLswTagPicker').Tag} Tag
 */

const props = defineProps({
  /**
   * The tag to edit. If the object or the ID is not provided, a new tag will be created.
   * @type {PropType<Tag>}
   */
  tag: {
    type: Object,
    default: null,
  },
});

const emit = defineEmits(['saved', 'clear']);
const { projectId, includeProjectTags } = useLswTagPicker();
const { t } = useI18n();
const { required, requiredIf, helpers } = useValidators();
const tagActions = useTagActions();
const { projectTagsEnabled } = useFeatures();

const name = shallowRef(props.tag?.name ?? '');
const selectedColor = shallowRef(props.tag?.color ?? '');
const isProjectSpecific = shallowRef(false);
const selectedProject = shallowRef(null);
const isSubmitting = shallowRef(false);
const colors = [
  {
    label: t('Red'),
    color: '#d84640',
  },
  {
    label: t('Orange'),
    color: '#f78233',
  },
  {
    label: t('Yellow'),
    color: '#f3bd38',
  },
  {
    label: t('Lime'),
    color: '#b1da33',
  },
  {
    label: t('Green'),
    color: '#53c843',
  },
  {
    label: t('Cyan'),
    color: '#37cecf',
  },
  {
    label: t('Blue'),
    color: '#2e8de3',
  },
  {
    label: t('Purple'),
    color: '#9b7cdb',
  },
  {
    label: t('Pink'),
    color: '#f47fbd',
  },
  {
    label: t('Grey'),
    color: '#a6a6a6',
  },
  {
    label: t('Black'),
    color: '#000000',
  },
  {
    label: t('Brown'),
    color: '#9d6857',
  },
];

const isUpdatingTag = computed(() => Boolean(props.tag?.id));

const projectOptionsEnabled = computed(() =>
  Boolean(projectTagsEnabled.value && !isUpdatingTag.value && (projectId.value || includeProjectTags.value)),
);

const shouldShowProjectPicker = computed(
  () => projectOptionsEnabled.value && isProjectSpecific.value && !projectId.value,
);

const payload = computed(() => {
  if (isUpdatingTag.value) {
    return {
      id: props.tag.id,
      name: name.value,
      color: selectedColor.value,
      projectId: props.tag.projectId,
    };
  }
  return {
    name: name.value,
    color: selectedColor.value,
    projectId: isProjectSpecific.value ? (selectedProject.value?.id ?? projectId.value) : 0,
  };
});

const v$ = useVuelidate(
  {
    name: {
      required: helpers.withMessage(t('You must enter a tag name'), required),
    },
    selectedColor: {
      required: helpers.withMessage(t('You must select a tag color'), required),
    },
    selectedProject: {
      requiredIf: helpers.withMessage(
        t('You must select a project'),
        requiredIf(() => shouldShowProjectPicker.value),
      ),
    },
  },
  { name, selectedColor, selectedProject },
);

async function submit() {
  v$.value.$touch();
  if (v$.value.$error) {
    return;
  }
  try {
    isSubmitting.value = true;
    const tag = isUpdatingTag.value
      ? await tagActions.updateTag(payload.value)
      : await tagActions.createTag(payload.value);
    emit('saved', tag);
  } finally {
    isSubmitting.value = false;
  }
}
</script>
<template>
  <LscForm class="flex flex-col gap-4" @submit="submit">
    <VTextField
      v-model.trim="name"
      :autofocus="true"
      class="w-full"
      :label="t('Tag name')"
      :errorMessages="v$.name.$errors.map(({ $message }) => $message)"
    >
      <template #append-inner>
        <LscIcon
          v-if="name"
          icon="lsi-close"
          size="sm"
          class="cursor-pointer text-icon-subtle"
          @click="$emit('clear')"
        />
      </template>
    </VTextField>
    <div class="flex w-full flex-col gap-3">
      <div class="flex items-center gap-2">
        <h4 class="text-body-1 font-semibold">
          {{ t('Tag color') }}
        </h4>
        <LscIcon class="text-icon-subtle" icon="lsi-tooltip" size="md" />
      </div>
      <LscColorPicker v-model="selectedColor" :colors="colors" :required="true" :cols="6" />
      <LscMessage
        v-if="v$.selectedColor.$errors.length > 0"
        :message="v$.selectedColor.$errors[0].$message"
        variant="critical"
      />
    </div>
    <template v-if="projectOptionsEnabled">
      <LscCheckbox v-model="isProjectSpecific" :label="t('Project specific')" />
      <LswProjectPicker
        v-if="shouldShowProjectPicker"
        v-model="selectedProject"
        :label="t('Select a project')"
        :errorMessages="v$.selectedProject.$errors.map(({ $message }) => $message)"
      />
    </template>
    <LscButton variant="primary" :loading="isSubmitting" type="submit" class="mx-auto">
      {{ isUpdatingTag ? t('Save changes') : t('Create new tag') }}
    </LscButton>
  </LscForm>
</template>
