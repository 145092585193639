<script setup>
import { useTasklistsV3Loader } from '@/api';
import { useElementSize, useI18n } from '@/util';
import ProjectJumpToDrawerProjectsListTasklistsList from './ProjectJumpToDrawerProjectsListTasklistsList.vue';

const props = defineProps({
  navItem: {
    type: String,
    required: true,
  },
  project: {
    type: Object,
    required: true,
  },
});

const { t } = useI18n();

const state = useTasklistsV3Loader({
  count: Infinity,
  pageSize: 500,
  projectId: computed(() => props.project.id),
  params: {
    status: 'all',
    sort: 'displayOrder',
  },
});
const { items: tasklists, loaded } = state;

const el = shallowRef(null);
const { height } = useElementSize(el);
const minHeight = 44; // Minimum height needed to show spinner
const currentHeight = computed(() => `${Math.max(height.value, minHeight)}px`);
const currentClass = computed(() => (tasklists.value.length > 0 ? `opacity-100` : 'opacity-0'));
</script>
<template>
  <VList density="compact" class="m-0 p-0 pl-9">
    <Transition
      v-if="!loaded"
      enterFromClass="opacity-0"
      enterActiveClass="transition-opacity duration-500 delay-200"
      appear
    >
      <VListItem density="compact" v-bind="VListItemSidebarProjectsDrawer" tabindex="-1" class="absolute left-9 top-0">
        <template #prepend>
          <LscIcon size="md" icon="lsi-loading" class="mr-2 animate-spin" />
        </template>
        <VListItemTitle class="text-body-1 text-subtle">{{ t('Loading') }}</VListItemTitle>
      </VListItem>
    </Transition>

    <div class="duration-200 overflow-hidden transition-all" :class="currentClass" :style="{ height: currentHeight }">
      <div ref="el">
        <template v-if="tasklists.length">
          <ProjectJumpToDrawerProjectsListTasklistsList :navItem="navItem" :tasklists="tasklists" />
        </template>
      </div>
    </div>
  </VList>
</template>
