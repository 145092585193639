const symbol = Symbol('useTeamworkFeatures');

export function provideTeamworkFeaturesState(teamworkFeaturesState) {
  provide(symbol, teamworkFeaturesState);
  return teamworkFeaturesState;
}

export function useTeamworkFeaturesState() {
  return inject(symbol);
}

export function useTeamworkFeatures() {
  return useTeamworkFeaturesState().items;
}
