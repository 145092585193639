<script setup>
import { useFeatures } from '@/api';
import { useI18n } from '@/util';
import { customfieldTypeIcons, customfieldTypes } from '../constants';
import CustomfieldAddOrEditDialogFieldTypeItem from './CustomfieldAddOrEditDialogFieldTypeItem.vue';

const props = defineProps({
  disabled: {
    type: Boolean,
    default: false,
  },

  canAddFormula: {
    type: Boolean,
    default: false,
  },
});

const modelValue = defineModel({
  type: [String, null],
  default: null,
});

const { t } = useI18n();
const { formulaFieldsEnabled } = useFeatures();

const fieldTypes = computed(() => {
  const types = [
    { value: customfieldTypes.TEXT, title: t('Text') },
    { value: customfieldTypes.NUMBER_INTEGER, title: t('Number') },
    { value: customfieldTypes.DROPDOWN, title: t('Dropdown') },
    { value: customfieldTypes.STATUS, title: t('Status') },
    { value: customfieldTypes.DATE, title: t('Date') },
    { value: customfieldTypes.URL, title: t('URL') },
    { value: customfieldTypes.CHECKBOX, title: t('Checkbox') },
  ];

  if (props.canAddFormula && formulaFieldsEnabled.value) {
    types.splice(1, 0, { value: customfieldTypes.FORMULA, title: t('Formula') });
  }

  return types.map((fieldType) => ({ ...fieldType, icon: customfieldTypeIcons[fieldType.value] }));
});
</script>

<template>
  <div class="flex w-4/12 items-center gap-x-3 gap-y-1">
    <VSelect
      v-model="modelValue"
      :label="t('Field type')"
      :items="fieldTypes"
      :disabled="disabled"
      density="compact"
      :menuProps="{ width: 'auto', maxHeight: 'auto' }"
    >
      <template #selection="{ item }">
        <CustomfieldAddOrEditDialogFieldTypeItem :title="item.raw.title" :icon="item.raw.icon" />
      </template>

      <template #item="{ item, props: { onClick } }">
        <VListItem
          density="compact"
          class="-ml-1"
          :class="{ 'bg-surface-selected': modelValue === item.value }"
          v-bind="{ onClick }"
        >
          <VListItemTitle class="items-center">
            <CustomfieldAddOrEditDialogFieldTypeItem
              :title="item.raw.title"
              :icon="item.raw.icon"
              :selected="modelValue === item.value"
            />
          </VListItemTitle>
        </VListItem>
      </template>
    </VSelect>
    <LscIcon v-if="disabled" v-LsdTooltip="t('You can\'t change field type')" icon="lsi-tooltip" size="sm" />
  </div>
</template>
