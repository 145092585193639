<script setup>
import { useI18n } from '@/util';

const { t } = useI18n();
</script>
<template>
  <div class="text-body-1">
    <p>
      {{
        t(
          "Tip 1: Be consistent. When possible, use the same column names across projects - that'll make it easy to understand how things are going without having to translate statuses.",
        )
      }}
    </p>
    <p class="pt-6">
      {{
        t(
          'Tip 2: Use phrasing that your team is familiar with. The more comfortable people are with terms, the easier it will be to apply them.',
        )
      }}
    </p>
  </div>
</template>
