<script setup>
import { useI18n } from '@/util';

const { t } = useI18n();
</script>
<template>
  <div class="text-body-1">
    <p>
      {{ t('In task list templates, some task options behave differently:') }}
    </p>
    <ul class="my-4 ml-4 list-disc">
      <li>
        <strong>{{ t('Start date/due date:') }}</strong>
        {{
          t(
            'You cannot set a specific date for the task start and due date. Instead, choose a day number. When the template is used, the dates will be set counting from the day you use the template.',
          )
        }}
      </li>
      <li>
        <strong>{{ t('Who should do this:') }}</strong>
        <ul class="ml-4 list-disc">
          <li>{{ t('Leave the selection as Anyone to show the task unassigned when the template is used.') }}</li>
          <li>
            {{
              t(
                'Use the Choose Later option to add a note of who should do the task. The actual assignee(s) can be chosen when the template is used on a project.',
              )
            }}
          </li>
        </ul>
      </li>
    </ul>
  </div>
</template>
