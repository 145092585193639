<script setup>
import { useI18n } from '@/util';

const { t } = useI18n();
</script>
<template>
  <div class="text-body-1">
    <p>
      {{
        t(
          'User rates are crucial for billing, invoicing, and tracking profitability. There are two types of user rates:',
        )
      }}
    </p>
    <p class="pt-6">
      {{
        t(
          'Billable rates: A person’s hourly rate i.e. the hourly rates you charge for someone’s work. Your client is invoiced based on this rate and the number of hours logged.  Think of it as income.',
        )
      }}
    </p>
    <p class="pt-6">
      {{
        t(
          'Cost rate: The total hourly cost to your company to employ someone (i.e. the hourly cost of someone’s salary, benefits, etc.)',
        )
      }}
    </p>
  </div>
</template>
