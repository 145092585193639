import { useFeatures } from '../features/useFeatures';
import { usePendo } from '../features/usePendo';
import { usePermissions } from '../permissions/usePermissions';

/**
 * Constants, computed properties and functions related to Experiment E21a
 * https://app.launchdarkly.com/default/production/features/projects-exp-e-23-21a-feature-trials-scale/targeting
 */
export function useExperimentE21a() {
  const { isOwnerAdmin } = usePermissions();
  const { projectsFeatureTrialScale } = useFeatures();
  const { trackExperimentInPendo, trackPendoEvent } = usePendo();

  const EXP_E21A_FLAG_KEY = 'projects-exp-e-23-21a-feature-trials-scale';

  const EXP_E21A_COMMON_METRICS = ['plan_name', 'page', 'user_role'];
  const EXP_E21A_EVENT_NAME = 'FEATURE_TRIAL_EVENT';
  const FEATURE_ACCESS_EVENT_NAME = 'FEATURE_ACCESS_EVENT';

  const defaultFlagValue = false;
  const ignoreInExperimentOnRuleMatch = true;

  const isExp21aVariation = computed(() => projectsFeatureTrialScale.value && isOwnerAdmin.value);
  const isExp21aControl = computed(() => !isExp21aVariation.value);

  function trackExperimentE21a() {
    trackExperimentInPendo({
      launchDarklyFlagKey: EXP_E21A_FLAG_KEY,
      defaultValue: defaultFlagValue,
      ignoreInExperimentOnRuleMatch,
      appLevelTargeting: isOwnerAdmin.value,
    });
  }

  function trackEventE21a({ eventAction, meta = {} }) {
    trackPendoEvent({
      eventName: EXP_E21A_EVENT_NAME,
      commonMetrics: EXP_E21A_COMMON_METRICS,
      metadata: {
        event_action: eventAction,
        ...meta,
      },
    });
  }

  function trackFeatureAccessEvent({ eventAction, meta = {} }) {
    trackPendoEvent({
      eventName: FEATURE_ACCESS_EVENT_NAME,
      commonMetrics: EXP_E21A_COMMON_METRICS,
      metadata: {
        event_action: eventAction,
        ...meta,
      },
    });
  }

  return {
    isExp21aVariation,
    isExp21aControl,
    // EXP_E21A_COMMON_METRICS,
    // EXP_E21A_EVENT_NAME,
    EXP_E21a_FLAG_KEY: EXP_E21A_FLAG_KEY,
    trackExperimentE21a,
    trackEventE21a,
    trackFeatureAccessEvent,
  };
}
