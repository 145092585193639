import { useCheckoutV1Loader, useCurrentUser } from '@/api';

const defaults = {
  isNewInvoiceActive: shallowRef(undefined),
  latestInvoiceHostedPage: shallowRef(null),
  accountIsInvoiceBased: shallowRef(false),
  isInvoicePaymentRequired: shallowRef(false),
};

export function useInvoice() {
  const user = useCurrentUser();
  const { item: checkoutData } = useCheckoutV1Loader();

  if (!user.value.inOwnerCompany || !user.value.administrator) {
    return defaults;
  }

  const projects = computed(() => checkoutData.value?.installation.apps.projects);

  const isInvoicePaymentRequired = computed(() => {
    return (
      projects.value &&
      projects.value?.paymentMethod === 'stripe' &&
      projects.value?.paymentCollectionMethod === 'send_invoice'
    );
  });

  const latestInvoiceHostedPage = computed(() => checkoutData.value?.latestInvoiceHostedPage);

  const isNewInvoiceActive = computed(() => {
    return isInvoicePaymentRequired.value && projects.value.inGracePeriod;
  });

  // Account is Paid offline
  const accountIsInvoiceBased = computed(() => {
    return projects.value?.paymentMethod === 'invoice';
  });

  return { isNewInvoiceActive, latestInvoiceHostedPage, accountIsInvoiceBased, isInvoicePaymentRequired };
}
