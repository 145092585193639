<script setup>
import { useElementSize, useI18n } from '@/util';
import { LscBreadcrumbSizes } from './constants';
import LscBreadcrumbsItem from './LscBreadcrumbsItem.vue';

/**
 * @typedef {import('./constants').Breadcrumb} Breadcrumb
 */

const props = defineProps({
  /**
   * The size of the breadcrumbs.
   * @type {PropType<typeof LscBreadcrumbSizes[number]>}
   */
  size: {
    type: String,
    default: 'md',
    validator: (value) => LscBreadcrumbSizes.includes(value),
  },
  /**
   * The items to display in the breadcrumbs.
   * @type {PropType<Breadcrumb[]>}
   */
  items: {
    type: Array,
    required: true,
  },
  /**
   * The number of items to show before the ellipsis item.
   * @type {PropType<number>}
   */
  itemsBeforeCollapse: {
    type: Number,
    default: 1,
    validator: (value) => value >= 0 && Number.isInteger(value),
  },
  /**
   * The number of items to show after the ellipsis item.
   * @type {PropType<number>}
   */
  itemsAfterCollapse: {
    type: Number,
    default: 1,
    validator: (value) => value >= 0 && Number.isInteger(value),
  },
  /**
   * Whether or not to wrap the breadcrumbs when they overflow.
   */
  expandedOverflow: {
    type: Boolean,
    default: true,
  },
});

const { t } = useI18n();

const containerRef = shallowRef(null);

const { width: containerWidth } = useElementSize(containerRef);

/**
 * @type {ShallowRef<'init' | 'expanded' | 'collapsed'>}
 */
const visibilityState = shallowRef('init');

function toggleExpansion() {
  visibilityState.value = 'expanded';
}

const ellipsisItem = /** @type {Breadcrumb & {isEllipses: boolean}} */ ({
  label: '…',
  tooltip: t('Expand'),
  isEllipses: true,
  onClick: toggleExpansion,
});

const visibleItems = computed(() => {
  if (visibilityState.value === 'expanded' || visibilityState.value === 'init') {
    return props.items;
  }

  if (props.items.length <= 2) {
    return props.items;
  }

  const firstItems = props.itemsBeforeCollapse === 0 ? [] : props.items.slice(0, props.itemsBeforeCollapse);
  const lastItems = props.itemsAfterCollapse === 0 ? [] : props.items.slice(-props.itemsAfterCollapse);

  return [...firstItems, ellipsisItem, ...lastItems];
});

async function recalculateWidth() {
  if (visibilityState.value === 'expanded') {
    return;
  }
  visibilityState.value = 'init';
  await nextTick();
  if (containerRef.value?.scrollWidth > containerRef.value?.clientWidth) {
    visibilityState.value = 'collapsed';
  } else {
    visibilityState.value = 'init';
  }
}

onMounted(recalculateWidth);
watch([containerWidth, () => props.items], recalculateWidth);

const breadcrumbVariantStyleConfig = tv({
  base: 'flex min-w-0 items-center gap-1',
  slots: {
    separator: 'shrink-0 text-icon-subtle',
    item: '',
  },
  variants: {
    visibilityState: {
      init: {
        base: 'flex-nowrap',
        item: 'shrink-0',
      },
      expanded: {
        item: 'shrink-0',
      },
      collapsed: {
        base: 'flex-nowrap',
        item: 'shrink',
      },
    },
    size: {
      sm: {
        separator: 'text-body-2',
      },
      md: {
        separator: 'text-body-1',
      },
    },
  },
  compoundVariants: [
    {
      visibilityState: 'expanded',
      expandedOverflow: true,
      class: {
        base: 'flex-wrap',
      },
    },
    {
      visibilityState: 'expanded',
      expandedOverflow: false,
      class: {
        base: 'flex-nowrap',
      },
    },
  ],
});

const iconSizeMap = {
  sm: 'xs',
  md: 'sm',
};
const iconSize = computed(() => iconSizeMap[props.size]);

const classes = computed(() =>
  breadcrumbVariantStyleConfig({
    visibilityState: visibilityState.value,
    expandedOverflow: props.expandedOverflow,
    size: props.size,
  }),
);
</script>

<template>
  <nav ref="containerRef" :aria-label="t('Breadcrumbs')" :class="classes.base()">
    <template v-for="(item, index) in visibleItems" :key="index">
      <LscBreadcrumbsItem :class="classes.item()" :item="item" :size="size" />
      <LscIcon
        v-if="index < visibleItems.length - 1"
        :size="iconSize"
        icon="lsi-forward-slash"
        :class="classes.separator()"
      />
    </template>
  </nav>
</template>
