<script setup>
defineProps({
  template: {
    type: Object,
    required: true,
  },
});

const emit = defineEmits(['useTemplate']);
const SampleTemplatePreviewPanel = defineAsyncComponent(() => import('./SampleTemplatePreviewPanel.vue'));
</script>

<template>
  <LscDialog>
    <template #activator="activator"><slot name="activator" v-bind="activator" /></template>
    <template #default="{ close }">
      <SampleTemplatePreviewPanel :template="template" @close="close" @useTemplate="emit('useTemplate', $event)" />
    </template>
  </LscDialog>
</template>
