/**
 * Transform a v3 task sequence object to a v1 task repeatOptions object
 * @param {*} sequence teamwork v3 API task sequence object
 * @returns teamwork v1 API repeatOptions object
 */
export function normalizeRepeatOptions(sequence, dateFormat) {
  if (!sequence) {
    throw new Error('Please pass a sequence');
  }

  const isFrequencyNoRepeat = sequence.frequency === 'norepeat';
  const hasEndDate = sequence.endDate != null;
  const repeatEndDate = isFrequencyNoRepeat || !hasEndDate ? 'noEndDate' : sequence.endDate.toFormat(dateFormat);
  const additionalProps = {};

  if (sequence.monthlyRepeatType) {
    additionalProps.monthlyRepeatType = sequence.monthlyRepeatType;
  }

  if (sequence.frequency === 'selecteddays' && sequence.selectedWeekDays) {
    additionalProps.selecteddays = sequence.selectedWeekDays.join(',');
  }

  if (['everyxdays', 'everyxdaysweekday'].includes(sequence.frequency) && sequence.duration > 1) {
    additionalProps.duration = sequence.duration;
  }

  return {
    repeatsFreq: sequence.frequency,
    repeatEndDate,
    ...additionalProps,
  };
}
