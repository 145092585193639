<script setup>
import { useDraggable, useElementBounding, useRafFn, watchDebounced } from '@vueuse/core';
import { useClamp } from '@vueuse/math';
import { useTimerActions } from '@/api';
import { useAppShellSidebar } from '@/appShell';
import { useI18n } from '@/util';
import { useLegacyBridge } from '@/module/legacy';
import TimeTimerItem from './TimeTimerItem.vue';
import { useTimer } from './useTimer';
import { useTimeTimerTracking } from './useTimeTimerTracking';

const props = defineProps({
  containerRef: {
    type: Object,
    required: true,
  },
});

const { t } = useI18n();
const { userHasTimers, shownTimer, timerDisplays, timers } = useTimer();
const { pauseTimer, resumeTimer } = useTimerActions();
const { disableLegacyInteraction, enableLegacyInteraction, legacyViewPosition } = useLegacyBridge();
const { isSidebarExpanded } = useAppShellSidebar();
const { trackTimerWidgetMoved } = useTimeTimerTracking();

const timersRef = shallowRef();
const sortedTimers = computed(() => {
  return timers.value.slice(0).sort((a, b) => {
    if (a.id === shownTimer.value.id) {
      return -1;
    }
    if (b.id === shownTimer.value.id) {
      return 1;
    }
    return 0;
  });
});

// Positioning and drag behaviour

const appBodyContentBounding = useElementBounding(props.containerRef);
const widgetTimersContainer = shallowRef(null);
const dragHandle = shallowRef(null);
const widgetTimersContainerBounding = useElementBounding(widgetTimersContainer);
const isOpen = shallowRef(false);
const multipleTimers = computed(() => timers.value.length > 1);

const minX = computed(() => {
  return appBodyContentBounding.left;
});

const maxX = computed(() => {
  const { right } = appBodyContentBounding;
  const { width } = widgetTimersContainerBounding;
  return right.value - width.value;
});

const minY = computed(() => {
  const { top } = appBodyContentBounding;
  return top.value + legacyViewPosition.value.top;
});

const maxY = computed(() => {
  const { bottom } = appBodyContentBounding;
  const { height } = widgetTimersContainerBounding;
  return bottom.value - height.value;
});

const initialValue = {
  x: Number(localStorage.getItem('teamwork/TimeTimer/x-position')) || minX.value,
  y: Number(localStorage.getItem('teamwork/TimeTimer/y-position')) || maxY.value,
};

const isLiftedForDrag = shallowRef(false);
const isDragging = shallowRef(false);

const finalX = shallowRef(initialValue.x);
const finalY = shallowRef(initialValue.y);

const { x, y } = useDraggable(widgetTimersContainer, {
  initialValue,
  stopPropagation: true,
  preventDefault: true,
  pointerTypes: ['mouse', 'touch'],
  handle: dragHandle,
  onStart: () => {
    disableLegacyInteraction();
    isLiftedForDrag.value = true;
  },
  // Timeouts prevent intended clicks being picked up as drags and vice versa
  onMove: () => {
    // eslint-disable-next-line no-use-before-define
    resumeRaf();
    setTimeout(() => {
      isDragging.value = true;
    }, 200);
  },
  onEnd: () => {
    // eslint-disable-next-line no-use-before-define
    pauseRaf();
    enableLegacyInteraction();
    isLiftedForDrag.value = false;
    setTimeout(() => {
      isDragging.value = false;
      trackTimerWidgetMoved();
    }, 600);
  },
});

const destinationX = computed(() => useClamp(x.value, minX.value, maxX.value).value);
const destinationY = computed(() => useClamp(y.value, minY.value, maxY.value).value);

function updatePosition() {
  finalX.value = destinationX.value;
  finalY.value = destinationY.value;
}

const { pause: pauseRaf, resume: resumeRaf } = useRafFn(
  () => {
    updatePosition();
  },
  {
    immediate: false,
  },
);

// Transition takes 200ms so we have to wait before repositioning
// The additional delay ensures that it is fully resolved
const NAV_TRANSITION_DURATION_DELAY = 500;

watch(isSidebarExpanded, () => {
  setTimeout(() => {
    updatePosition();
  }, NAV_TRANSITION_DURATION_DELAY);
});

onMounted(() => {
  updatePosition();
});

watchDebounced(
  [finalX, finalY],
  () => {
    localStorage.setItem('teamwork/TimeTimer/x-position', finalX.value);
    localStorage.setItem('teamwork/TimeTimer/y-position', finalY.value);
  },
  { debounce: 250 },
);

watch(maxX, () => updatePosition(), { immediate: true });
// update the position when the timers widget is opened/closed
watch(isOpen, () => {
  setTimeout(() => updatePosition(), NAV_TRANSITION_DURATION_DELAY);
  if (!isOpen.value) {
    const timersWrapper = timersRef.value?.[0]?.parentElement;
    if (timersWrapper) {
      timersWrapper.scrollTop = 0;
    }
  }
});
// update the position when timers are added/removed
watch(timers, (currentTimers, oldTimers) => {
  updatePosition();
  if (currentTimers.length > oldTimers.length) {
    isOpen.value = true;
  }
});
</script>

<template>
  <div
    v-if="userHasTimers"
    ref="widgetTimersContainer"
    class="TimeTimer group fixed z-[--timer-z-index]"
    :class="{ 'transition-all': !isLiftedForDrag }"
    :style="{ left: `${finalX}px`, top: `${finalY}px` }"
  >
    <div
      class="my-4 ml-5 mr-4 flex flex-col overflow-hidden rounded-sm bg-[color:--lsds-c-timer-color-background-default]"
      :class="{
        'shadow-1': !isLiftedForDrag,
        'shadow-4': isLiftedForDrag,
      }"
    >
      <div
        v-if="multipleTimers"
        ref="dragHandle"
        class="flex h-10 items-center justify-between border-b border-subtle px-2"
        :class="{ 'cursor-grab': !isLiftedForDrag, 'cursor-grabbing': isLiftedForDrag }"
      >
        <div class="text-body-2 font-bold">
          {{ t('No timers | One timer | {n} timers', { n: timers.length }) }}
        </div>
        <div class="flex items-center">
          <LscIcon icon="lsi-drag" size="sm" class="invisible text-icon-subtle group-hover:visible" />
          <LscIconButton
            variant="plain-secondary"
            :icon="isOpen ? 'lsi-sort-table' : 'lsi-dropdown'"
            :ariaLabel="isOpen ? t('Collapse') : t('Expand')"
            @click="isOpen = !isOpen"
          />
        </div>
      </div>
      <div :class="{ 'h-16 overflow-hidden': !isOpen, 'max-h-48 overflow-auto': isOpen }" data-identifier="time-timer">
        <div
          v-for="(timer, i) of sortedTimers"
          ref="timersRef"
          :key="timer.id"
          class="flex h-16 w-96 grow items-center justify-between bg-[color:--lsds-c-timer-color-background-default] py-2 pl-2 pr-3 hover:bg-[color:--lsds-c-timer-color-background-hover]"
          :class="{
            'border-t border-subtle': multipleTimers && i > 0,
            'pl-4': multipleTimers,
          }"
        >
          <!-- Padding provides extra area so the cursor doesn't flicker back to pointer while dragging -->
          <!-- @TODO: Replace with classes - not currently available -->
          <div
            v-if="timers.length === 1"
            :ref="(el) => (dragHandle = el)"
            :style="{ cursor: isLiftedForDrag ? 'grabbing' : 'grab' }"
            class="invisible flex items-center group-hover:visible"
          >
            <LscIcon icon="lsi-drag" size="sm" class="text-icon-subtle" />
          </div>
          <TimeTimerItem
            :timer="timer"
            :timeToDisplay="timerDisplays[timer.id]"
            :isBeingDragged="isDragging"
            class="grow"
            @pauseTimer="pauseTimer"
            @resumeTimer="resumeTimer"
          />
        </div>
      </div>
    </div>
  </div>
</template>
