import { useAxios } from '../base/useAxios';
import { useRealTimeUpdates } from '../base/useRealTimeUpdates';

export function useUserProductOutcomesActions() {
  const api = useAxios();
  const { emit: emitRealTimeUpdate, socketId } = useRealTimeUpdates();

  function config() {
    return {
      headers: {
        'Socket-ID': socketId,
      },
    };
  }

  return {
    saveSelectedProductOutcomes(userId, productOutcomeIds) {
      const promise = api
        .post(`/projects/api/v3/users/${userId}/productoutcomes.json`, { productOutcomeIds }, config())
        .then((result) => {
          emitRealTimeUpdate({ type: 'userproductoutcome', action: 'updated' });
          return result.data;
        });

      return promise;
    },
  };
}
