import { useKnowledgeBaseCategoriesLoader, useKnowledgeBaseCategoryArticlesBySlugLoader } from '@/api';
import { getSubcategoriesAndArticlesBySlug } from './helpers';

/**
 * This composite loader is merging states from two Loaders into a single state to make sure it can be used in LswLoaderState
 */
export function useCompositeSubcategoriesAndArticlesLoader({ categorySlug, count: _count = -1 }) {
  const count = shallowRef(_count);
  const articlesState = useKnowledgeBaseCategoryArticlesBySlugLoader({ count: Infinity, categorySlug });
  const categoriesState = useKnowledgeBaseCategoriesLoader({ count: Infinity });

  const allItems = computed(() =>
    categoriesState.loaded.value && articlesState.loaded.value
      ? getSubcategoriesAndArticlesBySlug(categorySlug, categoriesState.items.value, articlesState.items.value)
      : [],
  );

  return {
    items: computed(() => {
      if (count.value < 0) {
        return [];
      }
      if (count.value < allItems.value.length) {
        return allItems.value.slice(0, count.value);
      }
      return allItems.value;
    }),
    hasMore: computed(() => count.value < allItems.value.length),
    itemInSync: () => articlesState.inSync.value && categoriesState.inSync.value,
    inSync: computed(() => articlesState.inSync.value && categoriesState.inSync.value),
    loaded: computed(() => articlesState.loaded.value || categoriesState.loaded.value),
    meta: computed(() => null),
    error: computed(() => articlesState.error.value || categoriesState.error.value),
    retry() {
      // `retry` does nothing if there's no error, so it can be called on all states
      articlesState.retry();
      categoriesState.retry();
    },
  };
}
