import { useProjectsV3Loader } from './useProjectsV3Loader';

const symbol = Symbol('useHasActiveProjects');

export function provideHasActiveProjects() {
  const count = shallowRef(-1);
  const state = useProjectsV3Loader({
    count,
    pageSize: 1,
    params: {
      'projectStatuses': 'active',
      'fields[projects]': 'id',
    },
  });

  const hasActiveProjects = computed(() => {
    // Load the projects if they haven't been loaded yet.
    // Using a microtask to avoid updating a ref directly within a computed property.
    queueMicrotask(() => {
      if (count.value !== 1) {
        count.value = 1;
      }
    });
    return state.items.value.length > 0;
  });

  provide(symbol, hasActiveProjects);
  return hasActiveProjects;
}

export function useHasActiveProjects() {
  return inject(symbol);
}
