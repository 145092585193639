import { useEventListener } from '@vueuse/core';
import { useRouter } from 'vue-router';

const symbol = Symbol('UnsavedChanges');

function UnsavedChanges() {
  const unsavedRefs = shallowRef(new Set());
  const isNavigating = shallowRef(false);
  const hasAnyUnsavedChanges = computed(() => Array.from(unsavedRefs.value).some((ref) => ref.value));
  const isNavigatingWithUnsavedChanges = computed(() => hasAnyUnsavedChanges.value && isNavigating.value);

  let navigationPromiseResolve;
  let navigationPromiseReject;

  useEventListener('beforeunload', (event) => {
    if (hasAnyUnsavedChanges.value) {
      event.preventDefault();

      // Included for legacy support, e.g. Chrome/Edge < 119
      // eslint-disable-next-line no-param-reassign
      event.returnValue = true;
    }
  });

  const router = useRouter();
  router?.beforeResolve(async () => {
    if (!hasAnyUnsavedChanges.value || isNavigating.value) {
      return true;
    }

    isNavigating.value = true;
    const promise = new Promise((resolve, reject) => {
      navigationPromiseResolve = resolve;
      navigationPromiseReject = reject;
    });

    try {
      // Wait for the decision to either allow or block navigation
      await promise;
      return true;
    } catch (e) {
      return false;
    } finally {
      navigationPromiseResolve = undefined;
      navigationPromiseReject = undefined;
    }
  });

  function resolveNavigation() {
    isNavigating.value = false;
    if (navigationPromiseResolve) {
      navigationPromiseResolve();
    }
  }

  function rejectNavigation() {
    isNavigating.value = false;
    if (navigationPromiseReject) {
      navigationPromiseReject();
    }
  }

  function registerUnsavedChanges(ref) {
    unsavedRefs.value.add(ref);
    triggerRef(unsavedRefs);
    onScopeDispose(() => {
      unsavedRefs.value.delete(ref);
      triggerRef(unsavedRefs);
    });
  }

  return {
    registerUnsavedChanges,
    hasAnyUnsavedChanges,
    isNavigatingWithUnsavedChanges,
    resolveNavigation,
    rejectNavigation,
  };
}

export const unsavedChangesPlugin = {
  install(app) {
    app.provide(symbol, UnsavedChanges());
  },
};

/**
 *
 * @returns {ReturnType<UnsavedChanges>}
 */
export function useUnsavedChanges() {
  const unsavedChanges = inject(symbol);
  if (!unsavedChanges) {
    throw new Error('useUnsavedChanges() must be used within a Vue component where unsavedChangesPlugin is installed.');
  }
  return unsavedChanges;
}
