/**
 * @typedef {number|string} Id
 */

/**
 * @typedef {Object} Item
 * @property {Id} id
 */

/**
 * Creates a local item cache.
 * @param {...MaybeRef<Item|Array<Item>>} itemArgs The sources of items for the cache.
 *   Any number of params may be provided. Each param must be an Item or an Array of Item, possibly wrapped in a Ref.
 *   All items are cached once initially, and also every time their wrapper ref is triggered.
 */
export function useItemCache(...itemArgs) {
  const cachedItems = shallowRef(new Map());

  function addItems(itemOrItems) {
    if (Array.isArray(itemOrItems)) {
      for (const item of itemOrItems) {
        cachedItems.value.set(item.id, item);
      }
    } else {
      const item = itemOrItems;
      cachedItems.value.set(item.id, item);
    }
    triggerRef(cachedItems);
  }

  for (const itemArg of itemArgs) {
    if (isRef(itemArg)) {
      watch(itemArg, addItems, { immediate: true });
    } else {
      addItems(itemArg);
    }
  }

  /**
   * Gets an item from the cache by ID.
   * This function triggers reactivity and may return a different item after cached items change.
   *
   * @param {Id} id The item ID.
   * @param {(id:Id)=>Item} [getDefault] An optional function which generates a default item given an ID.
   * @returns {Item|undefined} Returns a cached item, if it exists, otherwise `undefined`.
   */
  function getOne(id, getDefault) {
    return cachedItems.value.get(id) ?? (typeof getDefault === 'function' ? getDefault(id) : undefined);
  }

  /**
   * Gets an item from the cache by ID, wrapped in a computed.
   *
   * @param {MaybeRef<Id>} id The item ID.
   * @param {(id:Id)=>Item} [getDefault] An optional function which generates a default item given an ID.
   * @returns {ComputedRef<Item|undefined>} The cached item or `undefined`, wrapped in a computed.
   */
  function computeOne(id, getDefault) {
    return computed(() => getOne(unref(id), getDefault));
  }

  /**
   * Gets a items from the cache by ID.
   * This function triggers reactivity and may return different items after cached items change.
   *
   * @param {Array<Id>} id The item IDs.
   * @param {(id:Id)=>Item} [getDefault] An optional function which generates a default item given an ID.
   * @returns {Array<Item|undefined>} An Array containing cached items or `undefined`.
   */
  function getAll(ids, getDefault) {
    return ids.map((id) => getOne(id, getDefault));
  }

  /**
   * Gets items from the cache by ID, wrapped in a computed.
   *
   * @param {MaybeRef<Array<Id>>} id The item IDs.
   * @param {(id:Id)=>Item} [getDefault] An optional function which generates a default item given an ID.
   * @returns {ComputedRef<Array<Item|undefined>>} An Array containing cached items or `undefined`, wrapped in a computed.
   */
  function computeAll(ids, getDefault) {
    return computed(() => getAll(unref(ids), getDefault));
  }

  /**
   * Given an Array of IDs, gets an Array containing only the items which are not in the cache.
   *
   * @param {Array<Id>} ids The item IDs.
   * @returns {Array<Id>} An array containing only the IDs missing from the cache.
   */
  function getMissing(ids) {
    return ids.filter((id) => !cachedItems.value.has(id));
  }

  /**
   * Given an Array of IDs, optionally wrapped in a ref,
   * gets an Array containing only the items which are not in the cache, wrapped in a computed.
   *
   * @param {MaybeRef<Array<Id>>} ids The item IDs.
   * @returns {ComputedRef<Array<Id>>} An array containing only the IDs missing from the cache, wrapped in a computed.
   */
  function computeMissing(ids) {
    return computed(() => getMissing(unref(ids)));
  }

  return { getOne, computeOne, getAll, computeAll, getMissing, computeMissing };
}
