import { useListLoader } from '../base/useListLoader';
import { useOptimisticUpdates } from '../base/useOptimisticUpdates';
import { useRealTimeUpdates } from '../base/useRealTimeUpdates';
import { computeDisplayOrder, normalizeNewTask, normalizeTaskParams, normalizeTasks } from './normalizers';
import {
  orderAscByCompletedOn,
  orderAscByCreatedBy,
  orderAscByCreatedDate,
  orderAscByDueDate,
  orderAscByEstimate,
  orderAscByManual,
  orderAscByName,
  orderAscByPriority,
  orderAscByProject,
  orderAscByStartDate,
  orderAscByTasklistDisplayOrder,
  orderAscByTaskListName,
  orderAscDateUpdated,
  orderDescByCompletedOn,
  orderDescByCreatedBy,
  orderDescByCreatedDate,
  orderDescByDueDate,
  orderDescByEstimate,
  orderDescByManual,
  orderDescByName,
  orderDescByPriority,
  orderDescByProject,
  orderDescByStartDate,
  orderDescByTaskListName,
  orderDescDateUpdated,
} from './sortingHelpers';

// gets a list of tasks from an axios response
function responseToItems({ data }) {
  const { tasks, included } = data;
  return normalizeTasks(tasks, included);
}

function responseToMeta({ data: { meta } }) {
  return { totalCount: meta.page?.count };
}

function orderByDefault() {
  return 0;
}

/**
 * Loads a list of tasks from the Teamwork v3 API.
 */
export function useTasksV3Loader({
  /**
   * The task from which to load subtasks.
   */
  taskId: _taskId,
  /**
   * The task list from which to load tasks.
   */
  tasklistId: _tasklistId,
  /**
   * The project from which to load tasks.
   */
  projectId: _projectId,
  /**
   * Filtering and sorting params for the request.
   */
  params: _params,
  /**
   * The number of tasks to load.
   */
  count,
  /**
   * The number of tasks to load per request.
   */
  pageSize,
}) {
  const taskId = shallowRef(_taskId);
  const tasklistId = shallowRef(_tasklistId);
  const projectId = shallowRef(_projectId);

  const params = computed(() => {
    const normalizedParams = normalizeTaskParams(_params);
    const { orderBy, orderMode } = normalizedParams;

    if (!orderBy) {
      normalizedParams.orderBy = 'manual';
    }

    if (!orderMode) {
      normalizedParams.orderMode = 'asc';
    } else if (orderMode !== 'asc' && orderMode !== 'desc') {
      // eslint-disable-next-line no-console
      console.warn(`useTasksV3Loader: unknown params.orderMode: ${orderMode}`);
      normalizedParams.orderMode = 'asc';
    }

    return normalizedParams;
  });

  const url = computed(() => {
    if (taskId.value) {
      return `/projects/api/v3/tasks/${taskId.value}/subtasks.json`;
    }
    if (tasklistId.value) {
      return `/projects/api/v3/tasklists/${tasklistId.value}/tasks.json`;
    }
    if (projectId.value) {
      return `/projects/api/v3/projects/${projectId.value}/tasks.json`;
    }
    return '/projects/api/v3/tasks.json';
  });

  const order = computed(() => {
    const { orderBy, orderMode } = params.value;
    const desc = orderMode === 'desc';

    switch (orderBy.toLowerCase()) {
      case 'manual':
        return orderAscByManual;
      case 'projectmanual':
        return desc ? orderDescByManual : orderAscByManual;
      case 'taskname':
        return desc ? orderDescByName : orderAscByName;
      case 'duedate':
        return desc ? orderDescByDueDate : orderAscByDueDate;
      case 'startdate':
        return desc ? orderDescByStartDate : orderAscByStartDate;
      case 'priority':
        return desc ? orderDescByPriority : orderAscByPriority;
      case 'tasklistname':
        return desc ? orderDescByTaskListName : orderAscByTaskListName;
      case 'tasklistdisplayorder':
        return orderAscByTasklistDisplayOrder;
      case 'createdby':
        return desc ? orderDescByCreatedBy : orderAscByCreatedBy;
      case 'project':
        return desc ? orderDescByProject : orderAscByProject;
      case 'dateadded':
        return desc ? orderDescByCreatedDate : orderAscByCreatedDate;
      case 'active':
        return desc ? orderDescByCompletedOn : orderAscByCompletedOn;
      case 'estimatedtime':
        return desc ? orderDescByEstimate : orderAscByEstimate;
      case 'dateupdated':
        return desc ? orderDescDateUpdated : orderAscDateUpdated;
      default:
        // eslint-disable-next-line no-console
        console.warn(`useTasksV3Loader: unknown params.orderBy: ${orderBy}`);
        return orderByDefault;
    }
  });

  const { state, refresh, update } = useListLoader({
    url,
    params,
    count,
    responseToItems,
    responseToMeta,
    order,
    pageSize,
    type: 'task',
  });

  /**
   * Determines if the specified optimistic-updates `task` matches the filtering criteria of this `useTasksV3Loader`.
   * It may need to be extended in the future to cover more cases as we continue building more task features.
   */
  function matchesFilter(task) {
    if (!params.value?.getSubTasks && !taskId.value && Object.hasOwn(task, 'parentTaskId') && task.parentTaskId) {
      // We're loading only top-level tasks but the task is a subtask.
      return false;
    }

    if (taskId.value && Object.hasOwn(task, 'parentTaskId') && taskId.value !== task.parentTaskId) {
      // We're loading subtasks of a specific task but the task is not a subtask of that task.
      return false;
    }

    if (tasklistId.value && Object.hasOwn(task, 'tasklistId') && tasklistId.value !== task.tasklistId) {
      // We're loading tasks from a specific tasklist but the task is not from that tasklist.
      return false;
    }

    if (projectId.value && Object.hasOwn(task, 'projectId') && projectId.value !== task.projectId) {
      // We're loading tasks from a specific project but the task is not from that project.
      return false;
    }

    return true;
  }

  useOptimisticUpdates((event) => {
    if (event.type === 'task') {
      update((tasks) => {
        if (event.action === 'create') {
          if (matchesFilter(event.task)) {
            return tasks.concat([normalizeNewTask(event.task, tasks)]);
          }
          return tasks;
        }

        if (event.action === 'update') {
          const hasTask = tasks.some((task) => task.id === event.task.id);
          const shouldHaveTask = matchesFilter(event.task);

          if (hasTask && !shouldHaveTask) {
            return tasks.filter((task) => task.id !== event.task.id);
          }

          if (
            !hasTask &&
            shouldHaveTask &&
            Object.hasOwn(event.task, 'parentTaskId') &&
            Object.hasOwn(event.task, 'tasklistId') &&
            Object.hasOwn(event.task, 'projectId')
          ) {
            return tasks.concat([normalizeNewTask(event.task, tasks)]);
          }

          if (hasTask) {
            return tasks.map((task) => {
              if (task.id === event.task.id) {
                const newTask = { ...task, ...event.task };
                newTask.displayOrder = computeDisplayOrder(newTask, tasks);
                return newTask;
              }
              return task;
            });
          }

          return tasks;
        }

        if (event.action === 'delete') {
          return tasks.filter((task) => task.id !== event.task.id);
        }
        return tasks;
      }, event.promise);
    }

    // Respond to tag updates.
    if (event.type === 'tag' && event.action === 'update') {
      update((tasks) => {
        return tasks.map((task) => ({
          ...task,
          tags: task.tags?.map((tag) => (tag.id === event.tag.id ? { ...tag, ...event.tag } : tag)),
        }));
      }, event.promise);
    }
  });

  useRealTimeUpdates((event) => {
    // If many tasks changed, reload all tasks.
    // The event name is misleading, as task modifications are not limited to a single project.
    if (event.type === 'projectTasks') {
      refresh();
      return;
    }

    // If many tasks changed, reload all tasks.
    if (event.type === 'task' && event.action === 'bulkEditTasks') {
      refresh();
      return;
    }

    // If a tag attached to some tasks changed, reload all tasks.
    if (event.type === 'tag' && event.action === 'edited') {
      if (state.items.value.some((task) => task.tagIds?.includes(event.tagId))) {
        refresh();
        return;
      }
    }

    // When a file is deleted from task, reload the task if affected.
    if (event.type === 'file' && event.action === 'edited' && event.affectedTaskIds?.length) {
      event.affectedTaskIds.forEach((affectedTaskId) => {
        refresh(affectedTaskId);
      });
    }

    // If filtering by projectId, skip changes in other projects.
    if (projectId.value && projectId.value !== event.projectId && projectId.value !== event.oldProjectId) {
      return;
    }

    // If filtering by tasklistId, skip changes in other tasklists.
    if (tasklistId.value && tasklistId.value !== event.tasklistId && tasklistId.value !== event.oldTasklistId) {
      return;
    }

    // If many tasks in a task list changed, reload all.
    if (event.type === 'tasklistTasks') {
      refresh();
      return;
    }

    if (event.type === 'task') {
      // If a subtask is added, deleted, completed or reopened,
      // then reload the parent task as its number of subtasks changed.
      if (
        event.parentTaskId &&
        (event.action === 'reopened' ||
          event.action === 'completed' ||
          event.action === 'new' ||
          event.action === 'deleted')
      ) {
        // The event does not contain the grandparentTaskId,
        // so we need to loop through the tasks to know if we need to reload.
        if (state.items.value.some((task) => task.id === event.parentTaskId)) {
          refresh(event.parentTaskId);
        }
      }

      // If a task is moved out from a parent task,
      // then reload the parent task as its number of subtasks changed.
      if (event.oldParentTaskId) {
        // The event does not contain the grandparentTaskId,
        // so we need to loop through the tasks to know if we need to reload.
        if (state.items.value.some((task) => task.id === event.oldParentTaskId)) {
          refresh(event.oldParentTaskId);
        }
      }

      // If a task is moved into a parent task,
      // then reload the parent task as its number of subtasks changed.
      if (event.parentTaskId) {
        // The event does not contain the grandparentTaskId,
        // so we need to loop through the tasks to know if we need to reload.
        if (state.items.value.some((task) => task.id === event.parentTaskId)) {
          refresh(event.parentTaskId);
        }
      }
    }

    // If filtering by taskId, skip changes in other parent tasks.
    if (taskId.value && taskId.value !== event.parentTaskId && taskId.value !== event.oldParentTaskId) {
      return;
    }

    // If a task list changed.
    if (event.type === 'tasklist') {
      // If a side-loaded task list name might have changed, then reload all tasks.
      if (event.action === 'edited') {
        refresh();
      }
      return;
    }

    // Only events of these types can affect task properties.
    if (event.type !== 'task' && event.type !== 'comment' && event.type !== 'time' && event.type !== 'reminder') {
      return;
    }

    // Only added and deleted comments can affect task properties.
    if (event.type === 'comment' && event.action === 'edited') {
      return;
    }

    // If loading only one level from the tasks hierarchy...
    if (!params.value.getSubTasks) {
      // If loading subtasks, skip tasks having a different parent.
      if (taskId.value && taskId.value !== event.parentTaskId && taskId.value !== event.oldParentTaskId) {
        return;
      }

      // If not loading subtasks, skip subtask changes.
      if (!taskId.value && event.parentTaskId && event.oldParentTaskId) {
        return;
      }
    }

    // Reload the task.
    if (event.taskId) {
      refresh(event.taskId);
    }
  });

  return state;
}
