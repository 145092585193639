<script setup>
import { useI18n } from '@/util';

const { t } = useI18n();
</script>
<template>
  <div class="text-body-1">
    <p>
      {{
        t(
          "The Utilization Report shows you how to measure your team's available time, so you can feel certain that your projects are efficiently resourced.",
        )
      }}
    </p>
    <p class="pt-6">{{ t('Learn from past projects or forecast utilization for upcoming projects.') }}</p>
  </div>
</template>
