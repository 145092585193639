<script setup>
import { useI18n } from '@/util';

const { t } = useI18n();
</script>
<template>
  <div class="text-body-1">
    <p>
      {{
        t(
          'People are the foundation of effective communication. You can only send comments and messages if your teammates are in {teamwork}.',
          { teamwork: 'Teamwork.com' },
        )
      }}
    </p>
    <p class="pt-6">
      {{
        t(
          'Standard users are usually your teammates or employees in your company whom you work with everyday. They can have full access to your site and they occupy a paid seat.',
        )
      }}
    </p>
  </div>
</template>
