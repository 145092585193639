<script setup>
const props = defineProps({
  autofocus: {
    type: Boolean,
    default: false,
  },
});

const modelValue = defineModel({ type: String, default: undefined });

const inputRef = shallowRef(null);

onMounted(async () => {
  await nextTick();
  if (props.autofocus) {
    inputRef.value.focus();
  }
});
</script>

<template>
  <input
    ref="inputRef"
    v-model="modelValue"
    type="text"
    class="h-7 w-full rounded-md bg-transparent p-2 ring-1 ring-transparent hover:ring-form-default focus-visible:ring-form-active disabled:text-disabled disabled:ring-transparent"
  />
</template>
