const symbol = Symbol('LscCard');

export function provideLscCard({
  layout: _layout,
  inset: _inset,
  elevated: _elevated,
  interactive: _interactive,
} = {}) {
  const layout = shallowRef(_layout);
  const inset = shallowRef(_inset);
  const elevated = shallowRef(_elevated);
  const interactive = shallowRef(_interactive);
  provide(symbol, { layout, inset, elevated, interactive });
}

export function useLscCard() {
  const context = inject(symbol);
  if (!context) {
    throw new Error('useLscCard must be used within a LscCardProvider');
  }
  return context;
}
