import { useFeatures, useHasActiveProjects, usePermissions } from '@/api';
import { useCurrentProject, useCurrentProjectPermissions } from '@/route';

/**
 * Returns a set of computed properties that determine whether the current user can quick add specific items.
 */
export function useQuickAddPermissions() {
  const hasActiveProjects = useHasActiveProjects();
  const project = useCurrentProject();
  const { projectUpdatesEnabled } = useFeatures();
  const { isOwnerAccount, isCollaborator, isClientUser, canAddProjects, isSiteAdmin } = usePermissions();
  const { canAddTasks, canAddMessages, canAddUpdate, canAddMilestones, canLogTime, canInviteUser } =
    useCurrentProjectPermissions();

  const isUserAllowed = computed(() => isSiteAdmin.value || isClientUser.value || hasActiveProjects.value);

  const quickAddTask = computed(
    () => Boolean(project.value ? canAddTasks.value : isUserAllowed.value) && !isCollaborator.value,
  );
  const addOrEditTimer = computed(
    () => Boolean(project.value ? canLogTime.value : isUserAllowed.value) && !isCollaborator.value,
  );
  const addMessage = computed(() => Boolean(project.value ? canAddMessages.value : isUserAllowed.value));
  const addOrEditEvent = computed(() =>
    Boolean(project.value ? !project.value.isTemplate && isOwnerAccount.value : !isCollaborator.value),
  );
  const addOrEditProjectUpdate = computed(
    () => project.value && !project.value.isTemplate && projectUpdatesEnabled.value && canAddUpdate.value,
  );
  const addOrEditMilestone = computed(() => Boolean(project.value ? canAddMilestones.value : canAddProjects.value));
  const addOrEditProjectWizard = canAddProjects;

  return {
    quickAddTask,
    addOrEditTimer,
    canInviteUser,
    addMessage,
    addOrEditEvent,
    addOrEditProjectUpdate,
    addOrEditProjectWizard,
    addOrEditMilestone,
  };
}
