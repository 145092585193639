<script setup>
import { useI18n } from '@/util';

const { t } = useI18n();
</script>
<template>
  <div class="text-body-1">
    <p>
      {{ t("There are tons of use cases with automations, here's a few to get you started:") }}
    </p>
    <ul class="my-4 ml-4">
      <li>{{ t('Auto-complete a task moved to the completed column.') }}</li>
      <li>{{ t("Create a sub-task, like 'Review Content'.") }}</li>
      <li>{{ t('Re-assign the task to yourself for review.') }}</li>
      <li>{{ t("Update the task's due date to next week if it's waiting for feedback.") }}</li>
      <li>{{ t('Send a notification to draw attention to blocked tasks.') }}</li>
    </ul>
  </div>
</template>
