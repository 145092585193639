<script setup>
const props = defineProps({
  text: {
    type: String,
    default: undefined,
  },
  tooltip: {
    type: String,
    default: null,
  },
  /**
   * The icon to prepend to the text.
   * @type {PropType<{ icon: LscIconName, size: string }>}
   */
  prepend: {
    type: Object,
    default: null,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  critical: {
    type: Boolean,
    default: false,
  },
  hasChildren: {
    type: Boolean,
    default: false,
  },
  active: {
    type: Boolean,
    default: false,
  },
  /**
   * The corresponding keyboard shortcut.
   */
  shortcut: {
    type: String,
    default: '',
  },
  autofocus: {
    type: Boolean,
    default: false,
  },
});

const listItemEl = shallowRef();

onMounted(() => {
  if (props.autofocus) {
    // We need to wait for 3 animation frames until Vuetify shows the menu,
    // so that the menu item could actually receive focus.
    requestAnimationFrame(() => {
      requestAnimationFrame(() => {
        requestAnimationFrame(() => {
          listItemEl.value?.$el.focus();
        });
      });
    });
  }
});

defineExpose({
  focus() {
    listItemEl.value?.$el.focus();
  },
});
</script>
<template>
  <VListItem ref="listItemEl" v-LsdTooltip="tooltip" :active="active" link :disabled="disabled">
    <template #prepend>
      <slot name="prepend">
        <LscIcon
          v-if="prepend"
          :icon="prepend.icon"
          :size="prepend.size || 'sm'"
          :class="{ '!text-icon-critical': critical }"
        />
      </slot>
    </template>
    <span class="truncate text-body-1" :class="{ '!text-critical': critical }">
      <slot>{{ text }}</slot>
    </span>
    <template #append>
      <div class="ml-2 flex items-center gap-2">
        <div
          v-if="shortcut"
          class="min-w-5 rounded-sm border border-form-default px-1 text-center text-body-2 text-subtle"
        >
          {{ shortcut }}
        </div>
        <slot name="append" />
        <LscIcon
          v-if="hasChildren"
          icon="lsi-open-panel"
          size="sm"
          :class="{ '!text-critical': critical }"
          class="!mr-0"
        />
      </div>
    </template>
  </VListItem>
</template>
