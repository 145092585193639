import { useLocalStorage } from '@/util';

const validTabs = computed(() => ['recent', 'starred', 'az', 'category'].filter(Boolean));

function validTab(tab) {
  return validTabs.value.includes(tab) ? tab : validTabs.value[0];
}

export function useSpacesDrawer() {
  const selectedTabRaw = useLocalStorage('teamwork/useSpacesDrawer/lastSelectedTab', validTab());
  const selectedTab = computed({
    get() {
      return validTab(selectedTabRaw.value);
    },
    set(tab) {
      selectedTabRaw.value = validTab(tab);
    },
  });

  const userIsGettingStarted = useLocalStorage('teamwork/useSpacesDrawer/userIsGettingStarted', true);

  const searchTermRaw = useLocalStorage('teamwork/useSpacesDrawer/searchTerm', '');
  const searchTerm = computed({
    get() {
      return searchTermRaw.value;
    },
    set(newVal) {
      // This is because clearing a value can set this to null, instead we alwasy make it an empty string
      searchTermRaw.value = newVal || '';
    },
  });

  const lastOpenCategories = useLocalStorage('teamwork/useSpacesDrawer/lastOpenCategories', []);

  return {
    selectedTab,
    userIsGettingStarted,
    searchTerm,
    lastOpenCategories,
  };
}
