import { DateTime } from 'luxon';
/**
 * TODO: This file should be split into multiple files per utility function
 */

const fileTypeGroups = {
  document: [
    '.pdf',
    '.doc',
    '.txt',
    '.docx',
    '.odt',
    '.msg',
    '.rtf',
    '.html',
    '.xml',
    '.eml',
    '.dxf',
    '.key',
    '.pages',
    '.vsdx',
    '.step',
    '.stp',
    '.dotx',
    '.sdlxliff',
    '.stl',
    '.htm',
    '.mpp',
    '.ics',
    '.oft',
    '.strings',
    '.log',
    '.pub',
    '.d3l',
    '.x_t',
    '.enriched',
    '.docx_',
    '.docm',
    '.wpd',
    '.wps',
  ],
  spreadsheet: [
    '.xlsx',
    '.xls',
    '.xlsm',
    '.csv',
    '.ods',
    '.numbers',
    '.xlsb',
    '.def',
    '.xll',
    '.xltm',
    '.sxc',
    '.nb',
    '.ots',
    '.rdf',
    '.xar',
    '.pmd',
    '.nmbtemplate',
    '.xltx',
    '.gsheet',
    '.uos',
  ],
  image: [
    '.jpg',
    '.jpeg',
    '.png',
    '.svg',
    '.gif',
    '.tiff',
    '.ai',
    '.psd',
    '.dwg',
    '.eps',
    '.ttf',
    '.otf',
    '.bmp',
    '.webp',
    '.indd',
    '.vsd',
    '.heic',
    '.xd',
    '.plt',
    '.sldprt',
    '.sketch',
    '.cdr',
    '.spl7',
    '.jfif',
    '.easm',
    '.swf',
    '.3dm',
    '.tif',
    '.dds',
    '.tga',
    '.thm',
  ],
  displayableImage: ['.jpg', '.jpeg', '.png', '.apng', '.gif', '.webp', '.svg', '.avif'],
  audio: ['.mp3', '.wav', '.m4a', '.opus', '.flac', '.wma', '.aac', '.aif', '.iff', '.m3u', '.mid', '.mpa', '.ogg'],
  video: [
    '.mp4',
    '.mov',
    '.webm',
    '.m4v',
    '.wmv',
    '.avi',
    '.f4v',
    '.qt',
    '.3gp',
    '.asf',
    '.flv',
    '.mpg',
    '.srt',
    '.vob',
  ],
  presentation: ['.ppt', '.pptx'],
  data: ['.json', '.mdb', '.apk', '.dll', '.db', '.exe', '.hex', '.sdltm'],
  code: ['.html', '.xml', '.sql', '.json', '.scss', '.css', '.php', '.js'],
  compressed: ['.zip', '.7z', '.rar', '.gz', '.xmind', '.kmz'],
};

// Used for FilePreview component
const FILE_PREVIEW_GROUPS = {
  MICROSOFTOFFICEFILES: [
    'xlsx',
    'xls',
    'xlsb',
    'xlsm',
    'docx',
    'doc',
    'dotx',
    'pptx',
    'ppt',
    'ppsx',
    'pps',
    'potx',
    'ppsm',
    'one',
  ],
  // TODO: google viewer https://www.gtricks.com/docs/read-embed-documents-online-google-docs-viewer/
  GOOGLEFILES: ['csv', 'cpp', 'c', 'css', 'txt', 'php'],
  FORBIDDENFILETYPES: ['zip', 'exe', 'com', 'jar', 'webm', 'tar', 'pkg'],
  VIEWABLEFILETYPES: [
    'txt',
    'doc',
    'docx',
    'xls',
    'xlsx',
    'ppt',
    'pptx',
    'pdf',
    'ai',
    'tiff',
    'svg',
    'ttf',
    'xps',
    'eps',
    'ps',
    'dxf',
    'csv',
  ],
  PLAYABLEVIDEO: ['mp4', 'webm', 'ogv', 'mov'],
  BLOCKEDVIDEOS: ['avc', 'asf', 'avi', 'mpg', 'mpeg', 'flv', 'divx', 'wmv'],
  DISPLAYABLEIMAGE: ['png', 'jpg', 'jpeg', 'gif', 'webp', 'svg'],
  VIEWABLEDROPBOXFILETYPES: ['pdf'],
  ODB_BLOCKED_PREVIEW: ['pdf', 'mp4', 'xlsx', 'docx', 'pptx', 'mdbx', 'doc', 'xls', 'one', 'txt'],
  SAMPLE_DIR: 'contentfiles/samples/',
};

const FILEPREVIEWSIZELIMITS = {
  GOOGLE: 25 * 1024 * 1024,
  MICROSOFT: 5 * 1024 * 1024,
};

export function getExtension(fileName) {
  return fileName?.includes('.') ? fileName.split('.').pop().toLowerCase() : '';
}

export function getFileName(fileName) {
  return fileName.substring(0, fileName.lastIndexOf('.')) || fileName;
}

export function isImage(fileName) {
  // Displayable image formats for web, exclude 'svg'
  return ['jpg', 'jpeg', 'png', 'gif', 'webp'].includes(getExtension(fileName));
}

export function isValidOfficeFile(file) {
  const fileExt = getExtension(file.originalName ?? file.displayName);
  return FILE_PREVIEW_GROUPS.MICROSOFTOFFICEFILES.includes(fileExt);
}

export function isPreviewableWithGoogleViewer(file) {
  const fileExt = getExtension(file.originalName ?? file.displayName);
  return FILE_PREVIEW_GROUPS.GOOGLEFILES.includes(fileExt);
}

export function isDisplayableImage(file) {
  const fileExt = getExtension(file.originalName ?? file.displayName);
  return FILE_PREVIEW_GROUPS.DISPLAYABLEIMAGE.includes(fileExt);
}

export function isViewableFile(file) {
  const fileExt = getExtension(file.originalName ?? file.displayName);
  return FILE_PREVIEW_GROUPS.VIEWABLEFILETYPES.includes(fileExt);
}

export function isPlayableVideo(file) {
  const fileExt = getExtension(file.originalName ?? file.displayName);
  return FILE_PREVIEW_GROUPS.PLAYABLEVIDEO.includes(fileExt);
}

export function isViewableDropboxFile(file) {
  const fileExt = getExtension(file.originalName ?? file.displayName);
  return FILE_PREVIEW_GROUPS.VIEWABLEDROPBOXFILETYPES.includes(fileExt);
}

function fileTooBig(file) {
  return parseInt(file.size, 10) > FILEPREVIEWSIZELIMITS.GOOGLE;
}

export function isPreviewableFile(file, canPreviewFiles) {
  // If the account allows file previews OR if the file is a teamwork file and an image/video
  if (!canPreviewFiles) {
    return file.fileSource === 'teamworkpm' && (isDisplayableImage(file) || isPlayableVideo(file));
  }

  if (file.fileSource === 'teamworkpm') {
    return (
      (isValidOfficeFile(file) ||
        isPreviewableWithGoogleViewer(file) ||
        isDisplayableImage(file) ||
        isViewableFile(file) ||
        isPlayableVideo(file)) &&
      !fileTooBig(file)
    );
  }

  // const fileExt = getExtension(file.originalName ?? file.displayName);

  // Some services can only display images and not content
  // if (file.fileSource === 'onedrive') {
  //   return isDisplayableImage(file);
  // }

  // if (file.fileSource === 'onedrivebusiness' || file.fileSource === 'sharepoint') {
  //   return isDisplayableImage(file) && !FILE_PREVIEW_GROUPS.ODB_BLOCKED_PREVIEW.includes(fileExt);
  // }

  // if (file.fileSource === 'dropbox') {
  //   return (isViewableDropboxFile(file) || isDisplayableImage(file)) && !fileTooBig(file);
  // }

  return false;
}

export function fileFormat(fileName) {
  const fileExt = `.${getExtension(fileName)}`.toLowerCase();
  if (fileExt === '.pdf') {
    return 'pdf';
  }
  if (fileTypeGroups.video.includes(fileExt)) {
    return 'video';
  }
  if (fileTypeGroups.document.includes(fileExt)) {
    return 'document';
  }
  if (fileTypeGroups.image.includes(fileExt)) {
    return 'image';
  }
  if (fileTypeGroups.audio.includes(fileExt)) {
    return 'audio';
  }
  if (fileTypeGroups.compressed.includes(fileExt)) {
    return 'zip';
  }
  if (fileTypeGroups.data.includes(fileExt)) {
    return 'data';
  }
  if (fileTypeGroups.spreadsheet.includes(fileExt)) {
    return 'xlsx';
  }
  if (fileTypeGroups.code.includes(fileExt)) {
    return 'code';
  }
  if (fileTypeGroups.presentation.includes(fileExt)) {
    return 'presentation';
  }
  return undefined;
}

export function getFileIcon(fileName) {
  const extensionMap = {
    pdf: { icon: 'lsi-file-pdf', color: 'var(--lsds-c-file-color-pdf)' },
    video: { icon: 'lsi-play', color: 'var(--lsds-a-color-text-subtle)' },
    document: { icon: 'lsi-file-document', color: 'var(--lsds-c-file-color-document)' },
    image: { icon: 'lsi-file-image', color: 'var(--lsds-a-color-text-subtle)' },
    audio: { icon: 'lsi-file-audio', color: 'var(--lsds-a-color-text-subtle)' },
    zip: { icon: 'lsi-file-zip', color: 'var(--lsds-a-color-text-subtle)' },
    data: { icon: 'lsi-file-document', color: 'var(--lsds-a-color-text-subtle)' },
    xlsx: { icon: 'lsi-file-xls', color: 'var(--lsds-c-file-color-spreadsheet)' },
    code: { icon: 'lsi-file-document', color: 'var(--lsds-a-color-text-subtle)' },
    presentation: { icon: 'lsi-file-presentation', color: 'var(--lsds-c-file-color-powerpoint)' },
  };

  const extension = fileFormat(fileName);

  return extensionMap[extension] || extensionMap.data;
}

export function getFileTypeGroupExtensions(type) {
  switch (type) {
    case 'pdf':
      return '.pdf';
    case 'document':
      return [...fileTypeGroups.document, ...fileTypeGroups.presentation];
    default:
      return fileTypeGroups[type];
  }
}

export function getFileDownloadUrl(id, versionId = 0) {
  let url = `/?action=viewFile&fileId=${id}&display=false`;
  if (versionId > 0) {
    url += `&versionId=${versionId}`;
  }

  return url;
}

// TODO Remove this function and use the one from i18n instead
// once we can get rid of normalizeFile
function formatFileSize(size) {
  return Intl.NumberFormat('en', { notation: 'compact', style: 'unit', unit: 'byte', unitDisplay: 'narrow' }).format(
    size,
  );
}

export function normalizeFile(file) {
  const id = file.fileId || file.id || 0;
  const mode = file.mode ?? 'server';
  const name = file.fileName || file.fileNameOnDisk || file.originalName || file.displayName || file.name || '-';
  const src = file.src || file.id;

  return {
    ...file,
    mode,
    id,
    fileRef: file.fileRef ?? '',
    versionId: file.versionId ?? 0,
    name,
    displayName: file.displayName || file.fileNameOnDisk || '-',
    src,
    uploadDate: DateTime.fromISO(file.uploadDate || file.uploadedDate || undefined),
    isUploading: file.isUploading ?? false,
    uploadProgress: file.uploadProgress ?? 0,
    size: file.fileSize || (file.size && formatFileSize(file.size)) || '0KB',
    sizeRaw: parseFloat(file.fileSize) || file.size || 0,
    categoryId: file.categoryId ?? null,
    lockdownId: file.lockdownId ?? 0,
  };
}

export function downloadFile(file, downloadURL) {
  if (file.fileSource === 'teamworkpm') {
    window.location.assign(downloadURL || file.downloadURL);
  } else {
    window.open(downloadURL || file.downloadURL, '_blank');
  }
}
