<script setup>
import { useI18n } from '@/util';

const { t } = useI18n();
</script>
<template>
  <div class="text-body-1">
    <p>
      {{
        t('Drag the 2-hour design time from Friday to Monday. Now {userName} Friday looks a little less busy!', {
          userName: "David's",
        })
      }}
    </p>
  </div>
</template>
