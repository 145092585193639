<template>
  <div class="flex justify-between">
    <div class="flex gap-1">
      <slot name="start" />
    </div>
    <div class="flex gap-1">
      <slot name="end" />
    </div>
  </div>
</template>
