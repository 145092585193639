<script setup>
import { useAppShellSidebar } from '@/appShell';
import { useWindowSize } from '@/util';
import { useLegacyBridge } from './useLegacyBridge';

const { activeDrawerPanel } = useAppShellSidebar();

const { width } = useWindowSize();

const {
  legacyBaseUrl,
  legacyIframeEl,
  iframeVisibilityMode,
  isLegacyModalOpen,
  isLegacyInteractionDisabled,
  isLegacyIframeLoaded,
  isLegacyFullscreenEnabled,
  legacyViewPosition,
} = useLegacyBridge();

const overlaySize = computed(() => {
  if (width.value < 1200 || !activeDrawerPanel.value) {
    return 'var(--sidebar-width)';
  }
  return 'calc(var(--active-drawer-panel-width) + var(--sidebar-width))';
});

function legacyIframeLoaded() {
  isLegacyIframeLoaded.value = true;
}

const initialSearchQuery = window.location.search;
const isFullScreen = computed(() => iframeVisibilityMode.value === 'modal' || isLegacyFullscreenEnabled.value);
const iframeStyle = computed(() => {
  return {
    top: isFullScreen.value ? '0' : `${legacyViewPosition.value.top}px`,
    left: isFullScreen.value ? '0' : `${legacyViewPosition.value.left}px`,
    width: isFullScreen.value ? '100%' : `${legacyViewPosition.value.width}px`,
    height: isFullScreen.value || !legacyViewPosition.value.height ? '100%' : `${legacyViewPosition.value.height}px`,
  };
});
</script>

<template>
  <!-- The legacy iframe -->
  <iframe
    ref="legacyIframeEl"
    class="fixed z-10 overflow-hidden bg-transparent"
    :allowfullscreen="true"
    :src="`${legacyBaseUrl}/#/idle${initialSearchQuery}`"
    :class="[
      iframeVisibilityMode,
      {
        'pointer-events-none': isLegacyInteractionDisabled,
        'z-50': isFullScreen,
      },
    ]"
    :style="iframeStyle"
    data-test-id="legacy-app-iframe"
    @load="legacyIframeLoaded"
  />

  <!-- Overlay matcher -->
  <template v-if="isLegacyModalOpen && iframeVisibilityMode !== 'modal'">
    <div
      class="fixed top-0 z-20 w-full"
      :style="{
        height: `${legacyViewPosition.top}px`,
        left: overlaySize,
        background: 'rgba(11, 14, 31, 0.2)',
      }"
    />
    <div
      class="fixed left-0 top-0 z-50 h-full w-[--v-layout-left]"
      :style="{
        width: overlaySize,
        background: 'rgba(11, 14, 31, 0.2)',
      }"
    />
  </template>
</template>
