/**
 * Delay promise resolution by X ms.
 *
 * @param {any} [value] resolve value.
 * @param {number} [ms] delay timeout
 * @returns {Promise<value>} delayed promise
 */
export function delayedPromise(value = undefined, ms = 1000) {
  return new Promise((resolve) => {
    setTimeout(() => resolve(value), ms);
  });
}
