<script setup>
import { useI18n } from '@/util';

const { t } = useI18n();
</script>
<template>
  <div class="text-body-1">
    <p>
      {{
        t(
          'Save time and effort when transitioning your projects into {teamwork} from your previous project management platform.',
          { teamwork: 'Teamwork ' },
        )
      }}
    </p>
    <p class="pt-6">
      {{ t('Use one of our importers to get you up and running with {teamwork}.', { teamwork: 'Teamwork ' }) }}
    </p>
  </div>
</template>
