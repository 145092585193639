import { setupDevtoolsPlugin } from '@vue/devtools-api';

const symbol = Symbol('Devtools');

function Devtools(app) {
  let devtools;
  if (import.meta.env.DEV && import.meta.env.MODE !== 'test') {
    setupDevtoolsPlugin(
      {
        id: 'lihtspeed-devtools',
        label: 'Lightspeed Devtools',
        enableEarlyProxy: true,
        app,
      },
      (api) => {
        devtools = api;
      },
    );
  }
  return devtools;
}

/**
 * @param {import('vue').App} app
 * @type {Devtools}
 */
export const devtoolsPlugin = {
  install(app) {
    app.provide(symbol, Devtools(app));
  },
};

/**
 *
 * @returns {ReturnType<Devtools>}
 */
export function useDevtools() {
  return inject(symbol);
}
