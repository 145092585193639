/**
 * Determines if more items can be loaded based on the provided axios response.
 * @param {import('axios').AxiosResponse} response
 * @returns {boolean}
 */
export function getHasMore(response) {
  // Cursor based pagination
  if (Number.isInteger(response.data?.meta?.limit)) {
    return response.data.meta.nextCursor != null;
  }

  const pageMeta = response.data?.meta?.page;

  if (pageMeta) {
    // Projects v3
    const { hasMore } = pageMeta;
    if (typeof hasMore === 'boolean') {
      return hasMore;
    }

    // Projects v3 fallback
    const { pageOffset, pageSize, count } = pageMeta;
    if (Number.isInteger(pageOffset) && Number.isInteger(pageSize) && Number.isInteger(count)) {
      return count > (pageOffset + 1) * pageSize;
    }

    // Chat
    const { offset, limit, total } = pageMeta;
    if (Number.isInteger(offset) && Number.isInteger(limit) && Number.isInteger(total)) {
      return pageMeta.total > pageMeta.offset + pageMeta.limit;
    }
  }

  // Projects v2 & v1
  const { headers } = response;
  const page = Number(headers['x-page']);
  const pages = Number(headers['x-pages']);
  if (Number.isInteger(page) && Number.isInteger(pages)) {
    return page < pages;
  }

  // Fallback
  // Note that Projects v1 does not return pagination data, if all items fit in one page.
  return false;
}

/**
 * Gets the next cursor for the cursor-based pagination from the provided axios response.
 * @param {import('axios').AxiosResponse} response
 * @returns {string | undefined}
 */
export function getNextCursor(response) {
  return response.data?.meta?.nextCursor;
}

/**
 * Gets the total number of items loaded with pagination.
 * @param {import('axios').AxiosResponse} response The axios response representing the furthest advanced page of items.
 * @param {Object} context Some extra context information.
 * @param {boolean} context.hasMore Indicates if the server has more items.
 * @param {boolean} context.loadedCountMax The total number of unique items actually loaded so far.
 * @param {boolean} context.loadedCountOld The loadedCount before the given response was received.
 * @returns {number} The number of loaded items.
 */
export function getLoadedCount(response, { hasMore, loadedCountMax, loadedCountOld }) {
  // If all items have been already loaded, then just return their actual count.
  if (!hasMore) {
    return loadedCountMax;
  }

  // Cursor-based pagination
  if (Number.isInteger(response.data?.meta?.limit)) {
    return Math.min(loadedCountMax, loadedCountOld + response.data.meta.limit);
  }

  const pageMeta = response.data?.meta?.page;

  if (pageMeta) {
    // Projects v3
    const { pageOffset, pageSize } = pageMeta;
    if (Number.isInteger(pageOffset) && Number.isInteger(pageSize)) {
      return Math.min(loadedCountMax, (pageOffset + 1) * pageSize);
    }

    // Chat
    const { offset, limit } = pageMeta;
    if (Number.isInteger(offset) && Number.isInteger(limit)) {
      return Math.min(loadedCountMax, offset + limit);
    }
  }

  // Fallback
  // Note that Projects v1 does not return pagination data, if all items fit in one page.
  const { page = 1, pageSize } = response.config.params;
  return Math.min(loadedCountMax, page * pageSize);
}

/**
 * Prepares the final request params by combining the `customParams`
 * with pagination, real-time updates and optimization params.
 * @param {Object|null|undefined} customParams The endpoint-specific params provided to the loader.
 * @param {Object} context Some extra context information.
 * @param {string} context.url The endpoint URL.
 * @param {number} context.pageSize The number of items to load.
 * @param {number} context.loadedCount The number of already loaded items.
 * @param {string|undefined} [context.cursor] The next cursor for cursor-based pagination.
 * @param {DateTime} [context.lastUpdated] If provided, only the items modified after that time will be requested.
 * @param {boolean} [context.forceModernApi=false] Force adding cursor-based pagination and skipCounts params.
 * @return {Object} The final request params.
 */
export function prepareParams(customParams, { url, pageSize, loadedCount, cursor, lastUpdated, forceModernApi }) {
  const params = { ...customParams };

  // Projects v3
  if (url.startsWith('/projects/api/v3/') || forceModernApi) {
    // Improve performance by allowing the server to skip calculation of the total number of items.
    // See https://digitalcrew.teamwork.com/app/tasks/21099246
    if (typeof params.skipCounts !== 'boolean') {
      params.skipCounts = true;
    }

    if (lastUpdated) {
      params.updatedAfter = lastUpdated.toISO();
      // Opt-in to cursor-based pagination
      params.cursor = '';
      params.limit = pageSize;
      // Fall back to offset-based pagination
      params.page = undefined;
      params.pageSize = pageSize;
    } else if (cursor != null) {
      // Cursor-based pagination
      params.cursor = cursor;
      params.limit = pageSize;
    } else if (loadedCount >= pageSize && pageSize > 0) {
      // Offset-based pagination
      params.page = Math.floor(loadedCount / pageSize) + 1;
      params.pageSize = pageSize;
    } else {
      // Opt-in to cursor-based pagination
      params.cursor = '';
      params.limit = pageSize;
      // Fall back to offset-based pagination
      params.page = undefined;
      params.pageSize = pageSize;
    }

    return params;
  }

  // Automations
  if (url.startsWith('/automations/api/v1/')) {
    params.limit = pageSize;
    params.cursor = cursor;

    if (lastUpdated) {
      // eslint-disable-next-line no-console
      console.warn(
        'useListLoaderConfig: The Automations API does not support loading of recently modified items for real-time updates.',
      );
    }

    return params;
  }

  // Chat
  if (url.startsWith('/chat/')) {
    params['page[offset]'] = loadedCount;
    params['page[limit]'] = pageSize;

    if (cursor) {
      // eslint-disable-next-line no-console
      console.warn('useListLoaderConfig: The Chat API does not support cursor-based pagination.');
    }

    if (lastUpdated) {
      // eslint-disable-next-line no-console
      console.warn(
        'useListLoaderConfig: The Chat API does not support loading of recently modified items for real-time updates.',
      );
    }

    return params;
  }

  // Projects v2 & v1
  if (lastUpdated) {
    params.updatedAfterDate = lastUpdated.toFormat('yyyyMMddHHmmss');
    params.page = undefined;
    params.pageSize = pageSize;
  } else if (loadedCount >= pageSize && pageSize > 0) {
    params.page = Math.floor(loadedCount / pageSize) + 1;
    params.pageSize = pageSize;
  } else {
    params.page = undefined;
    params.pageSize = pageSize;
  }

  return params;
}
