/**
 * Get initials from a name
 * Accepts a string or an array of strings (for first names and last names)
 * Also accepts a limit to the number of initials returned
 * @param {String|String[]} name
 * @param {Number} limit - The number of initials to return (default 2)
 * @returns {String}
 */
export function getInitials(name, limit = 2) {
  const nameArray = Array.isArray(name) ? name : name.split(' ');
  const initialArray = [];
  if (nameArray.length === 1) {
    initialArray.push(nameArray[0]);
  } else {
    nameArray.forEach((_name) => {
      if (_name) {
        initialArray.push(String.fromCodePoint(_name.codePointAt(0)).toUpperCase());
      }
    });
  }
  return initialArray.join('').slice(0, limit);
}
