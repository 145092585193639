<script setup>
import { useI18n } from '@/util';

const { t } = useI18n();
</script>
<template>
  <div class="text-body-1">
    <p>
      {{
        t(
          'When you have unread notifications for activity from any of your projects, a badge count of your unread notifications is displayed.',
        )
      }}
    </p>
    <p class="mt-4">
      {{
        t(
          'Select the bell icon on the left-hand navigation bar to open the Notifications slide out panel. Clicking a notification relating to a comment or message redirects you to the details view for that item.',
        )
      }}
    </p>
  </div>
</template>
