import { useFeatures, useProofsLoader } from '@/api';

/**
 * @returns {import('./useFeaturesLimit').FeatureLimitObject}
 */
export function useProofsLimit() {
  const { proofsLimit: featureLimit, proofsEnabled: isFeatureEnabled } = useFeatures();

  const { meta, inSync, loaded } = useProofsLoader({ count: 0, params: { skipCounts: false } });

  const loading = computed(() => !loaded.value || !inSync.value);

  const isOverFeatureLimit = computed(() => {
    if (loading.value) {
      return undefined;
    }

    if (featureLimit.value === -1) {
      return false;
    }

    if (meta.value?.totalCount >= featureLimit.value) {
      return true;
    }

    // The proof count is for proofs visible to the user. (possibly not all)
    // The limit visible is for the entire install
    // Therefore we cannot be certain when the limit will be hit
    return undefined;
  });

  return {
    featureLimit,
    isFeatureEnabled,
    isOverFeatureLimit,
    loading,
  };
}
