<script setup>
import { provideDocumentTitle, useI18n } from '@/util';
import AppShellError from './AppShellError.vue';
import AppShellLoading from './AppShellLoading.vue';
import AppShellToastContainer from './toast/AppShellToastContainer.vue';

const { ready, error } = useI18n();

provideDocumentTitle();
</script>

<template>
  <AppShellError v-if="error" />
  <AppShellLoading v-else-if="!ready" />
  <RouterView v-else />
  <AppShellToastContainer />
</template>
