export const LscButtonSizes = ['sm', 'md', 'lg'] as const;
export type LscButtonSize = (typeof LscButtonSizes)[number];

export const LscButtonTypes = ['button', 'submit', 'reset'] as const;
export type LscButtonType = (typeof LscButtonTypes)[number];

export const LscButtonVariants = [
  'primary',
  'secondary',
  'tertiary',
  'critical',
  'plain-primary',
  'plain-secondary',
  'critical-secondary',
] as const;
export type LscButtonVariant = (typeof LscButtonVariants)[number];
