<script setup>
import { useI18n } from '@/util';

const { t } = useI18n();
</script>
<template>
  <div class="text-body-1">
    <p>
      {{
        t(
          'Client View is the place to oversee your clients and their projects. This is your centralized hub for efficient client management and strategic insights. Stay on top of profitability, budget progress, billable vs. non-billable time, and more—all in one convenient location.',
        )
      }}
    </p>
    <p class="pt-6">
      {{
        t(
          'Click into widgets for detailed analysis and filter by date range for precise information. With Client View, managing clients has never been easier, empowering you to optimize client work and boost productivity like never before.',
        )
      }}
    </p>
  </div>
</template>
