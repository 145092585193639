import { markdownToHtml } from '@/util';

let nextId = 1;
const store = new WeakMap();

const visibleTooltips = new Set();

const baseClasses = [
  'bg-surface-dark',
  'text-body-2',
  'text-reversed',
  'max-w-72',
  'break-words',
  'p-2',
  'rounded-md',
  'absolute',
  'pointer-events-none',
  'transition-opacity',
  'z-[1000000]',
];

const markdownClassName = baseClasses
  .concat([
    '[&_ul]:pl-4',
    '[&_ol]:pl-4',
    '[&>ul:not(:last-child)]:mb-2',
    '[&>ol:not(:last-child)]:mb-2',
    '[&>p:not(:last-child)]:mb-2',
  ])
  .join(' ');

const plainTextClassName = baseClasses.concat(['whitespace-pre-wrap']).join(' ');

const margin = 4;

function isMarkdownEnabled(data) {
  return Boolean(data.binding.modifiers.markdown);
}

function getText(data) {
  const { value } = data.binding;

  if (typeof value === 'string') {
    return value;
  }

  if (typeof value === 'object' && value != null && typeof value.text === 'string') {
    return value.text;
  }

  return '';
}

function getClassName(data) {
  if (isMarkdownEnabled(data)) {
    return markdownClassName;
  }
  return plainTextClassName;
}

function getOffset(data) {
  const { value } = data.binding;

  if (typeof value === 'object' && value != null && typeof value.offset === 'number') {
    return value.offset;
  }

  return 8;
}

function getSubtext(data) {
  const { value } = data.binding;
  if (typeof value === 'object' && value != null && typeof value.subtext === 'string') {
    return value.subtext;
  }
  return '';
}

function getLocation(data) {
  const location = data.binding.arg;
  switch (location) {
    case 'top':
    case 'right':
    case 'bottom':
    case 'left':
      return location;
    default:
      return 'top';
  }
}

function updateTooltip(data) {
  const { element, tooltipElement } = data;

  if (!tooltipElement || !tooltipElement.parentNode) {
    return;
  }

  const text = getText(data);
  const subtext = getSubtext(data);
  const className = getClassName(data);
  const offset = getOffset(data);
  let location = getLocation(data);

  // Update the tooltip content and styling.
  if (isMarkdownEnabled(data)) {
    tooltipElement.innerHTML = markdownToHtml(text);
  } else {
    tooltipElement.textContent = text;
  }

  if (subtext) {
    if (subtext.length > 1) {
      tooltipElement.appendChild(document.createElement('DIV'));
      tooltipElement.lastChild.className = 'text-body-2 text-on-dark';
    } else {
      tooltipElement.appendChild(document.createElement('SPAN'));
      tooltipElement.lastChild.className = 'text-body-2 text-on-dark inline-block pl-2';
    }
    tooltipElement.lastChild.textContent = subtext;
  }

  tooltipElement.className = className;
  tooltipElement.style.visibility = text ? '' : 'hidden';

  // Update the tooltip position.
  requestAnimationFrame(() => {
    const elementRect = element.getBoundingClientRect();
    const tooltipElementRect = tooltipElement.getBoundingClientRect();
    const viewportWidth = document.documentElement.clientWidth;
    const viewportHeight = document.documentElement.clientHeight;
    const tooltipWidth = margin + tooltipElementRect.width + offset;
    const tooltipHeight = margin + tooltipElementRect.height + offset;
    const spaceLeft = elementRect.left;
    const spaceRight = viewportWidth - elementRect.right;
    const spaceTop = elementRect.top;
    const spaceBottom = viewportHeight - elementRect.bottom;

    // If there's too little space in the requested location, try to find a different one.
    if (
      (location === 'top' && tooltipHeight > spaceTop) ||
      (location === 'right' && tooltipWidth > spaceRight) ||
      (location === 'bottom' && tooltipHeight > spaceBottom) ||
      (location === 'left' && tooltipWidth > spaceLeft)
    ) {
      // first try the opposite direction
      if (location === 'top' && tooltipHeight <= spaceBottom) {
        location = 'bottom';
      } else if (location === 'right' && tooltipWidth <= spaceLeft) {
        location = 'left';
      } else if (location === 'bottom' && tooltipHeight <= spaceTop) {
        location = 'top';
      } else if (location === 'left' && tooltipWidth <= spaceRight) {
        location = 'right';
        // then try the other directions
      } else if (tooltipHeight <= spaceTop) {
        location = 'top';
      } else if (tooltipWidth <= spaceRight) {
        location = 'right';
      } else if (tooltipHeight <= spaceBottom) {
        location = 'bottom';
      } else if (tooltipWidth <= spaceLeft) {
        location = 'left';
      }
    }

    // Position the tooltip at the correct side of the trigger.
    if (location === 'top') {
      tooltipElement.style.top = `${elementRect.top - tooltipElementRect.height - offset}px`;
    } else if (location === 'right') {
      tooltipElement.style.left = `${elementRect.right + offset}px`;
    } else if (location === 'bottom') {
      tooltipElement.style.top = `${elementRect.bottom + offset}px`;
    } else if (location === 'left') {
      tooltipElement.style.left = `${elementRect.left - tooltipElementRect.width - offset}px`;
    }

    // Align the tooltip with the trigger and keep it in the viewport.
    if (location === 'top' || location === 'bottom') {
      let left = elementRect.left + elementRect.width / 2 - tooltipElementRect.width / 2;
      left -= Math.max(0, left + tooltipElementRect.width + margin - viewportWidth);
      left = Math.max(left, margin);
      tooltipElement.style.left = `${left}px`;
    } else if (location === 'left' || location === 'right') {
      let top = elementRect.top + elementRect.height / 2 - tooltipElementRect.height / 2;
      top -= Math.max(0, tooltipElementRect.height + margin - viewportHeight);
      top = Math.max(top, margin);
      tooltipElement.style.top = `${top}px`;
    }
  });
}

function showTooltip(data) {
  visibleTooltips.add(data);
  // eslint-disable-next-line no-param-reassign
  data.tooltipElement ??= document.createElement('div');

  const { element, tooltipElement, id } = data;

  if (tooltipElement.parentNode) {
    return;
  }

  document.body.appendChild(tooltipElement);

  element.setAttribute('aria-describedby', id);
  tooltipElement.setAttribute('id', id);
  tooltipElement.setAttribute('role', 'tooltip');

  tooltipElement.style.transitionDelay = '150ms';
  tooltipElement.style.opacity = '0';
  requestAnimationFrame(() => {
    requestAnimationFrame(() => {
      tooltipElement.style.opacity = '1';
    });
  });

  updateTooltip(data);
}

function hideTooltip(data) {
  visibleTooltips.delete(data);
  const { tooltipElement } = data;

  if (!tooltipElement || !tooltipElement.parentNode) {
    return;
  }

  tooltipElement.parentNode.removeChild(tooltipElement);
}

function handleMouseEnter(event) {
  const data = store.get(event.currentTarget);
  data.hasHover = true;
  showTooltip(data);
}

function handleMouseLeave(event) {
  const data = store.get(event.currentTarget);
  data.hasHover = false;
  if (!data.hasFocus) {
    hideTooltip(data);
  }
}

function handleFocus(event) {
  const data = store.get(event.currentTarget);
  if (event.currentTarget.matches(':focus-visible')) {
    data.hasFocus = true;
    showTooltip(data);
  }
}

function handleBlur(event) {
  const data = store.get(event.currentTarget);
  data.hasFocus = false;
  if (!data.hasHover) {
    hideTooltip(data);
  }
}

window.addEventListener(
  'keydown',
  (event) => {
    if (event.key === 'Escape') {
      visibleTooltips.forEach(hideTooltip);
    }
  },
  true,
);

window.addEventListener(
  'scroll',
  () => {
    visibleTooltips.forEach(hideTooltip);
  },
  true,
);

export const LsdTooltip = {
  created(element, binding) {
    store.set(element, {
      element,
      binding,
      tooltipElement: undefined,
      hasFocus: false,
      hasHover: false,
      id: `LsdTooltip-${nextId++}`,
    });
    element.addEventListener('mouseenter', handleMouseEnter, false);
    element.addEventListener('mouseleave', handleMouseLeave, false);
    element.addEventListener('focus', handleFocus, false);
    element.addEventListener('blur', handleBlur, false);
  },
  mounted(element, binding) {
    const data = store.get(element);
    data.binding = binding;
    updateTooltip(data);
  },
  updated(element, binding) {
    const data = store.get(element);
    data.binding = binding;
    updateTooltip(data);
  },
  unmounted(element) {
    hideTooltip(store.get(element));
  },
};
