<script setup>
import { useI18n } from '@/util';

const { t } = useI18n();
</script>
<template>
  <div class="text-body-1">
    <p>
      {{
        t(
          'Task list budgets track the smaller chunks of work on your projects and roll up to the overall project budget.',
        )
      }}
    </p>
    <p class="pt-6">
      {{
        t(
          'By breaking down your costs at the Task List level, you’ll know when and where to reallocate resources, add more support, or explore cost-saving tactics.',
        )
      }}
    </p>
  </div>
</template>
