// this is a work in progress, hidden from production, migration of onboarding to LS. Old onboarding is still in place with legacy view.
// eventually legacy /onboarding and /getting-started will be removed and there will only be one route here

export const onboardingRoutes = [
  {
    path: '/onboarding',
    component: () => import('./RouteOnboardingLS.vue'),
    meta: {
      theaterMode: 'always',
    },
    children: [
      {
        path: '',
        component: () => import('./RouteOnboardingWizard.vue'),
      },
      // once we remove legacy onboarding, we need a catchall redirect here
    ],
  },
  {
    path: '/getting-started',
    component: () => import('./RouteGettingStarted.vue'),
    meta: {
      theaterMode: 'always',
    },
  },
  {
    path: '/onboarding/step/:step?',
    component: () => import('./RouteOnboarding.vue'),
    meta: {
      theaterMode: 'always',
    },
    props: (route) => ({
      step: parseInt(route.params.step, 10),
      projectName: route.params.projectName,
    }),
  },
];
