<script setup>
defineProps({
  active: {
    type: Boolean,
    default: false,
  },
  dataTestIdPrefix: {
    type: String,
    default: undefined,
  },
});

const emit = defineEmits(['click']);
</script>

<template>
  <li class="group/LswAssigneePickerItem relative m-0 flex list-none items-center p-0">
    <button
      type="button"
      class="flex w-full items-center justify-between gap-2 rounded-md bg-default p-2 text-default ring-default transition-colors focus-visible:outline-none focus-visible:ring-2 group-hover/LswAssigneePickerItem:bg-hover aria-pressed:!bg-surface-emphasis-selected aria-pressed:font-semibold aria-pressed:text-on-emphasis"
      :aria-pressed="active"
      :data-test-id="dataTestIdPrefix ? `${dataTestIdPrefix}-assignee` : undefined"
      @click="emit('click', $event)"
    >
      <LscOverflowEllipsis>
        <slot />
      </LscOverflowEllipsis>

      <slot name="append" />
    </button>
  </li>
</template>
