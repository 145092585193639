const symbol = Symbol('ReportBuilderFilters');

function ReportCustomBuilderFilters() {
  const defaultParams = {
    categoryIds: [],
    companyIds: [],
    customFieldFilters: [],
    hideObservedProjects: false,
    includeArchivedProjects: false,
    includeCompletedMilestones: false,
    includeCompletedProjects: true,
    includeCompletedTasks: false,
    onlyProjectsWithExplicitMembership: true,
    includeDeletedUsers: false,
    onlyStarredProjects: false,
    projectsWithActivityOnly: false,
    projectIds: [],
    status: 'all',
    teamIds: [],
    tagIds: [],
    userIds: [],
    jobRoleIds: [],
  };

  const filterParams = shallowRef({
    ...defaultParams,
  });

  // TODO : set this up to have items for each base type to use in the createCustomReport function to format the selected fitlers correctly
  const filterConfig = {
    milestone: [{ name: 'includeCompletedMilestones', value: true }],
    project: [{ name: 'includeArchivedProjects', value: true }],
    task: [{ name: 'includeCompletedTasks', value: true }],
    user: [{ name: 'includeArchivedProjects', value: true }],
  };

  function clearFilters() {
    filterParams.value = {
      ...defaultParams,
    };
  }

  return {
    defaultParams,
    filterConfig,
    filterParams,
    clearFilters,
  };
}

/**
 * @returns {ReportCustomBuilderFilters}
 */
export function provideReportCustomBuilderFilters() {
  const filters = ReportCustomBuilderFilters();
  provide(symbol, filters);
  return filters;
}

/**
 * @returns {ReportCustomBuilderFilters|{}}
 */
export function useReportCustomBuilderFilters() {
  return inject(symbol, {});
}
