<script setup>
import { useCheckoutActions, useCurrentAccount, usePreferences, usePricePlan } from '@/api';
import { useI18n } from '@/util';
import { useInvoice } from './useInvoice';

const { t } = useI18n();
const { isNewInvoiceActive, latestInvoiceHostedPage } = useInvoice();
const { goToUpgrade } = useCheckoutActions();
const account = useCurrentAccount();
const { isPaymentOverdue } = usePricePlan();
const { invoiceBannerDismissed } = usePreferences();

const paidDaysRemaining = computed(() => {
  const paidUntilDateTime = account.value?.paidUntilDateTime;
  return paidUntilDateTime ? Math.ceil(paidUntilDateTime.diffNow('days').days) : 0;
});

const show = computed(() => isPaymentOverdue.value && isNewInvoiceActive.value && !invoiceBannerDismissed.value);

function payInvoice() {
  if (latestInvoiceHostedPage.value) {
    window.open(latestInvoiceHostedPage.value, '_blank');
    return;
  }
  goToUpgrade('AppShellInvoiceBanner');
}

function handleClose() {
  invoiceBannerDismissed.value = true;
}
</script>
<template>
  <LscAlert
    v-if="show"
    closable
    variant="warning"
    layout="banner"
    :message="
      t(
        'You have an outstanding invoice, please complete the payment today to continue using {teamwork}. | You have an outstanding invoice, please complete the payment in the next day to continue using {teamwork}. | You have an outstanding invoice, please complete the payment in the next {n} days to continue using {teamwork}.',
        { n: paidDaysRemaining, teamwork: 'Teamwork.com' },
      )
    "
    @close="handleClose"
  >
    <template #action>
      <LscAlertButton @click="payInvoice">
        {{ t('Pay invoice') }}
      </LscAlertButton>
    </template>
  </LscAlert>
</template>
