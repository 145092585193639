<script setup>
import { useI18n } from '@/util';

const { t } = useI18n();
</script>
<template>
  <div class="text-body-1">
    <p>
      {{ t('Templates save you time and help standardize your work. Use these for commonly repeated lists of tasks.') }}
    </p>
    <p class="pt-6">
      {{ t('Click on the 3 dots beside your task list name, then click Advanced > Templates > Save in templates.') }}
    </p>
  </div>
</template>
