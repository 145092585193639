<script setup>
import { useSpacesStarredV1Loader } from '@/api';
import SpacesDrawerStarredBlankSlate from './SpacesDrawerStarredBlankSlate.vue';
import SpacesDrawerStarredList from './SpacesDrawerStarredList.vue';

const pageSize = 50;
const count = shallowRef(pageSize);
const state = useSpacesStarredV1Loader({
  count,
  pageSize,
  params: computed(() => ({
    orderBy: 'title',
    orderMode: 'ASC',
    include: 'spaces,pages,pages.spaces',
  })),
});
</script>

<template>
  <SpacesDrawerStarredList v-model:count="count" :step="pageSize" :state="state">
    <template #blank>
      <SpacesDrawerStarredBlankSlate />
    </template>
  </SpacesDrawerStarredList>
</template>
