import { getLastChildPath } from '../lastChildPath';

export const timeRoutes = [
  {
    path: '/time',
    component: () => import('./RouteTime.vue'),
    children: [
      {
        path: '',
        redirect() {
          return `/time/${getLastChildPath('time', 'all')}`;
        },
      },
      {
        path: 'user',
        component: () => import('./RouteTimeTimesheetUser.vue'),
      },
      {
        path: 'company',
        component: () => import('./RouteTimeTimesheetCompany.vue'),
      },
      {
        path: 'all',
        component: () => import('./RouteTimeAllTime.vue'),
      },
      {
        path: 'approvals',
        component: () => import('./RouteTimeApprovals.vue'),
      },
      {
        path: ':pathMatch(.*)*',
        redirect: '/time/all',
      },
    ],
  },
];
