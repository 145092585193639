<script setup>
import RepsAccountPromptActivator from './RepsAccountPromptActivator.vue';
import { provideReps } from './useReps';

provideReps();
</script>

<template>
  <RepsAccountPromptActivator />
</template>
