import { useItemLoader } from '../base/useItemLoader';

function responseToItem({ data: { customfield } }) {
  return customfield;
}

function responseToMeta({ data: { count } }) {
  return { count };
}

export function useCustomfieldV3Loader({ customfieldId: _customfieldId, params }) {
  const customfieldId = shallowRef(_customfieldId);
  const { state } = useItemLoader({
    url: computed(() =>
      Number.isInteger(customfieldId.value) ? `/projects/api/v3/customfields/${customfieldId.value}.json` : undefined,
    ),
    params,
    responseToItem,
    responseToMeta,
  });

  return state;
}
