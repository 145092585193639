<script setup>
const ShortcutDrawerPanel = defineAsyncComponent(() => import('./ShortcutDrawerPanel.vue'));
</script>

<template>
  <LscDrawer scrim>
    <template #activator="activator"><slot name="activator" v-bind="activator" /></template>
    <template #default="{ close }"><ShortcutDrawerPanel @close="close" /></template>
  </LscDrawer>
</template>
