<script setup>
const PeopleSkillsCreateDialogPanel = defineAsyncComponent(() => import('./PeopleSkillsCreateDialogPanel.vue'));
</script>

<template>
  <LscDialog>
    <template #activator="activator"><slot name="activator" v-bind="activator" /></template>
    <template #default="{ close }">
      <PeopleSkillsCreateDialogPanel v-bind="$attrs" @close="close" />
    </template>
  </LscDialog>
</template>
