<script setup>
import useVuelidate from '@vuelidate/core';
import { debouncedRef } from '@vueuse/core';
import { useExperimentA2301 } from '@/api';
import { capitalizeFirstLetter, useI18n, useValidators } from '@/util';

const props = defineProps({
  billableRate: {
    type: Number,
    default: null,
    required: false,
  },
  costRate: {
    type: Number,
    default: null,
    required: false,
  },
  originalBillableRate: {
    type: Number,
    default: null,
    required: false,
  },
  originalCostRate: {
    type: Number,
    default: null,
    required: false,
  },
  projectUser: {
    type: Object,
    required: true,
  },
  currencySymbol: {
    type: String,
    required: true,
  },
  personIndex: {
    type: Number,
    required: true,
  },
  showAutomaticCostRatesCalculationAlert: {
    type: Function,
    default: () => {},
  },
  automaticCostRatesCalculationEnabled: {
    type: Boolean,
    default: true,
    required: false,
  },
  billingType: {
    type: String,
    required: false,
    default: '',
  },
  showValidationError: {
    type: Boolean,
    required: false,
    default: false,
  },
  isOnboardingGoalContext: {
    type: Boolean,
    required: false,
    default: false,
  },
});

const emit = defineEmits([
  'update:billableRate',
  'update:costRate',
  'update:automaticCostRatesCalculationEnabled',
  'update:costRateMessage',
]);

const { t } = useI18n();
const { minValue, maxValue, decimal } = useValidators();
const { trackBillableRateEntered, trackCostRateEntered, isExpA2301Variation } = useExperimentA2301();

const rules = {
  editedBillableRate: {
    decimal,
    minValue: minValue(0),
    maxValue: maxValue(9999999),
  },
  editedCostRate: {
    decimal,
    minValue: minValue(0),
    maxValue: maxValue(9999999),
  },
};

const editedBillableRate = shallowRef(props.billableRate ?? null);
const editedCostRate = shallowRef(props.costRate ?? null);
const debouncedBillableRate = debouncedRef(editedBillableRate, 300);
const debouncedCostRate = debouncedRef(editedCostRate, 300);

const v$ = useVuelidate(
  rules,
  {
    editedBillableRate,
    editedCostRate,
  },
  { $autoDirty: false },
);

const validationErrors = computed(() => ({
  editedBillableRate: v$.value?.editedBillableRate?.$errors?.map((error) => error.$message),
  editedCostRate: v$.value?.editedCostRate?.$errors?.map((error) => error.$message),
}));

const firstName = computed(() => {
  if (props.projectUser.user && props.projectUser.user.firstName) {
    return props.projectUser.user.firstName;
  }
  return props.projectUser.firstName || '';
});

const initial = computed(() => {
  if (props.projectUser.user && props.projectUser.user.initial) {
    return props.projectUser.user.initial;
  }
  return props.projectUser.initial || '';
});

const editableRatesDisplayed = computed(() => !props.isOnboardingGoalContext || props.projectUser.canEditRates);

function getAvatarBackgroundColorByIndex(index) {
  switch (index % 6) {
    case 1:
      return 'var(--lsds-a-color-decorative-1)';
    case 2:
      return 'var(--lsds-a-color-decorative-2)';
    case 3:
      return 'var(--lsds-a-color-decorative-6)';
    case 4:
      return 'var(--lsds-a-color-decorative-3)';
    case 5:
      return 'var(--lsds-a-color-decorative-5)';
    default:
      return 'var(--lsds-a-color-decorative-7)';
  }
}

function emitCostRateMessage() {
  if (props.originalBillableRate === null && props.originalCostRate === null) {
    emit('update:costRateMessage', true);
  } else {
    emit('update:costRateMessage', false);
  }
}

function handleRateChange(rate, type) {
  const isBillableRate = type === 'billableRate';
  const isCostRate = type === 'costRate';

  if (rate === '' && (isBillableRate || isCostRate)) {
    if (isBillableRate) {
      editedBillableRate.value = props.originalBillableRate;
    } else if (isCostRate) {
      editedCostRate.value = props.originalCostRate;
    }
  }

  if (!props.isOnboardingGoalContext && isExpA2301Variation.value) {
    if (isBillableRate && props.originalBillableRate !== rate) {
      trackBillableRateEntered();
    } else if (isCostRate && props.originalCostRate !== rate) {
      trackCostRateEntered();
    }
  }
}

watch(
  () => props.billableRate,
  (billableRate) => {
    editedBillableRate.value = billableRate;
  },
);

watch(
  () => props.costRate,
  (costRate) => {
    editedCostRate.value = costRate;
  },
);

watch(
  () => debouncedBillableRate.value,
  (newValue) => {
    const parsedValue = Number(newValue);

    if (props.originalBillableRate === null && props.originalCostRate === null) {
      editedCostRate.value = parsedValue === 0 ? null : Number((parsedValue * 0.6).toFixed(2));
    }

    if (parsedValue !== props.originalBillableRate) {
      emit('update:billableRate', newValue);
    }
  },
);

watch(
  () => debouncedCostRate.value,
  (newValue) => {
    if (newValue !== props.originalCostRate) {
      emit('update:costRate', newValue);
    }
  },
);
</script>

<template>
  <div class="flex items-start gap-2">
    <div class="mt-3 flex flex-1 items-center gap-2">
      <div
        :style="{ '--bg': getAvatarBackgroundColorByIndex(personIndex) }"
        class="flex size-8 shrink-0 items-center justify-center rounded-full bg-[--bg] text-body-1 font-semibold"
      >
        {{ capitalizeFirstLetter(initial) }}
      </div>
      <LscOverflowEllipsis class="text-body-1 text-default">
        {{ capitalizeFirstLetter(firstName) }}
      </LscOverflowEllipsis>
      <LscIcon
        v-if="!editableRatesDisplayed"
        v-LsdTooltip="t('Rates can only be set for standard and client users.')"
        class="cursor-help text-icon-default focus:outline-none"
        size="sm"
        icon="lsi-tooltip"
      />
    </div>
    <VTextField
      v-if="editableRatesDisplayed"
      v-model.number="editedBillableRate"
      variant="outlined"
      type="number"
      class="mt-3 flex-1"
      :label="t('Add rate')"
      :placeholder="t('Not set')"
      :errorMessages="showValidationError ? validationErrors.editedBillableRate : []"
      @update:modelValue="showAutomaticCostRatesCalculationAlert"
      @input="emit('update:billableRate', editedBillableRate)"
      @blur="handleRateChange(editedBillableRate, 'billableRate')"
      @keydown="emitCostRateMessage"
    >
      <template #prepend-inner>
        <div class="mr-2 text-subtle">
          {{ currencySymbol }}
        </div>
      </template>
    </VTextField>
    <VTextField
      v-if="editableRatesDisplayed"
      v-model.number="editedCostRate"
      variant="outlined"
      type="number"
      class="mt-3 flex-1"
      :label="t('Add rate')"
      :placeholder="t('Not set')"
      :errorMessages="showValidationError ? validationErrors.editedCostRate : []"
      @update:modelValue="emit('update:automaticCostRatesCalculationEnabled', false)"
      @input="emit('update:costRate', editedCostRate)"
      @blur="handleRateChange(editedCostRate, 'costRate')"
    >
      <template #prepend-inner>
        <div class="mr-2 text-subtle">
          {{ currencySymbol }}
        </div>
      </template>
    </VTextField>
  </div>
</template>
