<script setup>
import { useNotifications, useNotificationsActions } from '@/api';
import { LsaNotificationsUnread } from '@/designsystem';
import { useI18n } from '@/util';
import NotificationDrawerList from './NotificationDrawerList.vue';

const { unreadNotifications } = useNotifications();
const { markNotificationRead } = useNotificationsActions();
const { t } = useI18n();

async function toggleRead(id) {
  await markNotificationRead({
    id,
    read: true,
  });
}
unreadNotifications.loadMore();

const count = shallowRef(20);

watch(
  count,
  () => {
    unreadNotifications?.loadMore();
  },
  {
    immediate: true,
  },
);
</script>

<template>
  <NotificationDrawerList v-model:count="count" :state="unreadNotifications.state" @toggleRead="toggleRead">
    <template #blank>
      <LscEmptyState class="h-full" :title="t('Woohoo!')" :message="t('You\'re all up to date!')">
        <template #image>
          <LsaNotificationsUnread />
        </template>
      </LscEmptyState>
    </template>
  </NotificationDrawerList>
</template>
