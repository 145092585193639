<script setup lang="ts">
import {
  type LscEmptyStateSize,
  LscEmptyStateSizes,
  type LscEmptyStateVariant,
  LscEmptyStateVariants,
} from './LscEmptyState.types.js';

interface Slots {
  /** The image slot. Only use this slot if you need to add HTML, otherwise just use the prop */
  image(): unknown;
  /** The title slot. Only use this slot if you need to add HTML, otherwise just use the prop. */
  title(): unknown;
  /** The message slot. Only use this slot if you need to add HTML, otherwise just use the prop */
  message(): unknown;
  /** The actions slot. Please put the primary CTA to the right side. */
  actions(props: { size: LscEmptyStateSize }): unknown;
}

const props = defineProps({
  /**
   * The variant of the empty state.
   */
  variant: {
    type: String as PropType<LscEmptyStateVariant>,
    default: 'default',
    validator: (value: LscEmptyStateVariant) => LscEmptyStateVariants.includes(value),
  },
  /** The size of the empty state. */
  size: {
    type: String as PropType<LscEmptyStateSize>,
    default: 'md',
    validator: (value: LscEmptyStateSize) => LscEmptyStateSizes.includes(value),
  },
  /** The empty state title. */
  title: {
    type: String,
    default: undefined,
  },
  /** The empty state message. */
  message: {
    type: String,
    default: undefined,
  },
  /** Whether should show the image. */
  hideImage: {
    type: Boolean,
    default: false,
  },
});

const slots = defineSlots<Slots>();

const LscEmptyStateDefaultImage = defineAsyncComponent(() => import('./LscEmptyStateDefaultImage.svg'));
const LscEmptyStateErrorImage = defineAsyncComponent(() => import('./LscEmptyStateErrorImage.svg'));

const emptyStateVariantStyleConfig = tv({
  base: 'flex items-center justify-center',
  slots: {
    wrapper: 'flex w-full flex-col items-center',
    image: 'mb-4 flex aspect-[3/2] h-auto w-full items-center justify-center [&>*]:h-full',
    title: 'text-balance text-center font-semibold',
    message: 'mt-1 text-pretty text-center text-body-1',
    actions: 'mt-4 flex w-full justify-center gap-2',
  },
  variants: {
    size: {
      sm: {
        wrapper: 'max-w-[--lsds-c-empty-state-size-sm]',
        image: 'mb-3 max-w-[--lsds-c-empty-state-image-size-sm]',
        title: 'text-body-1',
        message: 'text-body-2',
        actions: 'mt-3',
      },
      md: {
        wrapper: 'max-w-[--lsds-c-empty-state-size-md]',
        image: 'max-w-[--lsds-c-empty-state-image-size-md]',
        title: 'text-subtitle-2',
      },
      lg: {
        base: '',
        wrapper: 'max-w-[--lsds-c-empty-state-text-box-size-lg]',
        image: 'max-w-[--lsds-c-empty-state-image-size-lg]',
        title: 'max-w-[--lsds-c-empty-state-size-lg] text-subtitle-1',
        message: 'max-w-[--lsds-c-empty-state-size-lg]',
      },
    },
  },
});

const classes = computed(() =>
  emptyStateVariantStyleConfig({
    size: props.size,
  }),
);
</script>
<template>
  <div :class="classes.base()">
    <div :class="classes.wrapper()">
      <div :class="classes.image()">
        <slot v-if="!hideImage" name="image">
          <LscSlotSwitch :name="variant" class="">
            <template #default>
              <LscEmptyStateDefaultImage />
            </template>
            <template #error>
              <LscEmptyStateErrorImage />
            </template>
          </LscSlotSwitch>
        </slot>
      </div>
      <h4 v-if="slots.title || title" :class="classes.title()">
        <slot name="title">{{ title }}</slot>
      </h4>
      <p v-if="slots.message || message" :class="classes.message()">
        <slot name="message">{{ message }}</slot>
      </p>
      <div v-if="slots.actions" :class="classes.actions()">
        <slot name="actions" :size="size" />
      </div>
    </div>
  </div>
</template>
