<script setup>
import { useI18n } from '@/util';

const { t } = useI18n();
</script>
<template>
  <div class="text-body-1">
    <p>
      {{
        t(
          'At the end of each billing cycle, simply select the specific time logs you wish to bill your client for and add them to the invoice. Once ready, export it in your preferred format and send it directly to your client for seamless billing.',
        )
      }}
    </p>
  </div>
</template>
