import { createRouter as createVueRouter, createWebHistory } from 'vue-router';
import { publicRoutes } from './public';

export function createPublicRouter() {
  const router = createVueRouter({
    history: createWebHistory('/app/public'),
    routes: publicRoutes,
  });

  return router;
}
