// eslint-disable-next-line no-restricted-imports
import { useClipboard as _useClipboard } from '@vueuse/core';
import { useI18n } from '../i18n/useI18n';

export function useClipboard() {
  const { copy } = _useClipboard({ legacy: true });
  const { t } = useI18n();
  const toast = useLsToast();

  /**
   * Copy text to the clipboard and display a toast when done.
   * @param {string} text - The text to copy to the clipboard.
   * @param {string} [successMessage] - The message to display in the toast.
   */
  async function copyToClipboard(text, successMessage) {
    await copy(text);
    toast.success(successMessage || t('Copied to clipboard!'));
  }

  return {
    copyToClipboard,
  };
}
