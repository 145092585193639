import { useItemLoader } from '../../base/useItemLoader';

function responseToItem(response) {
  return response.data.budgetCounts;
}

export function useBudgetsCountsV3Loader({ params }) {
  const { state } = useItemLoader({
    url: '/projects/api/v3/projects/budgets/counts.json',
    params,
    responseToItem,
  });

  return state;
}
