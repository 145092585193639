<script setup>
import { refDebounced, syncRef } from '@vueuse/core';
import { useJobRolesV3Loader } from '@/api';
import { useI18n, useItemCache } from '@/util';
import { normalizeFilterIds } from './normalize';
import { useFilter, useFilterChips, useFilterClear, useFilterCount, useFilterNormalize } from './useFilter';

const props = defineProps({
  name: {
    type: String,
    required: true,
  },
  title: {
    type: String,
    required: true,
  },
  defaultValue: {
    type: String,
    default: '',
  },
  disabled: {
    type: Boolean,
    default: false,
  },
});

const { t } = useI18n();
const { params, activeFilter, dataIdentifierPrefix } = useFilter();
const searchTerm = shallowRef('');
const debouncedSearchTerm = refDebounced(searchTerm, 300);
const pageSize = 20;
const count = shallowRef(-1);
const filterType = 'jobroles';

const state = useJobRolesV3Loader({
  params: computed(() => ({
    orderBy: 'name',
    searchTerm: debouncedSearchTerm.value,
  })),
  count,
  pageSize,
});

const { items } = state;

const missingJobRoleIds = shallowRef([]);
const missingJobRolesState = useJobRolesV3Loader({
  params: computed(() => ({
    jobroleIds: missingJobRoleIds.value.join(','),
  })),
  count: computed(() => missingJobRoleIds.value.length || -1),
  pageSize: 50,
});

const { computeAll, computeMissing } = useItemCache(
  computed(() => Object.values(activeFilter.value?.included?.[filterType] || {})),
  items,
  missingJobRolesState.items,
);

const jobRoleIds = computed({
  get() {
    return typeof params.value[props.name] === 'string'
      ? params.value[props.name].split(',').map(Number).filter(Boolean)
      : [];
  },
  set(value) {
    params.value = {
      ...params.value,
      [props.name]: value.join(','),
    };
  },
});

const jobRoles = computeAll(jobRoleIds, (id) => ({ id, name: t('Unknown') }));

syncRef(computeMissing(jobRoleIds), missingJobRoleIds, { direction: 'ltr' });

useFilterNormalize(toRef(props, 'name'), (value) => normalizeFilterIds(value, props.defaultValue));

useFilterClear(toRef(props, 'name'), toRef(props, 'defaultValue'));

useFilterChips(
  computed(() =>
    jobRoles.value.map((jobRole) => ({
      name: jobRole.name,
      icon: 'lsi-client',
      type: filterType,
      delete: () => {
        jobRoleIds.value = jobRoleIds.value.filter((id) => id !== jobRole.id);
      },
    })),
  ),
);

useFilterCount(computed(() => jobRoleIds.value.length));

function toggleJobRole({ id }) {
  if (jobRoleIds.value.includes(id)) {
    jobRoleIds.value = jobRoleIds.value.filter((jobRoleId) => jobRoleId !== id);
  } else {
    jobRoleIds.value = [...jobRoleIds.value, id];
  }
}

function icon(id) {
  return jobRoleIds.value.includes(id) ? 'lsi-remove' : 'lsi-add';
}

function toggleMenu(opened) {
  if (!opened || count.value >= 0) {
    return;
  }
  count.value = pageSize;
}
</script>
<template>
  <LscMenu :closeOnContentClick="false" location="bottom left" offset="12" @update:modelValue="toggleMenu">
    <template #activator="activator">
      <LswFilterOptionButton
        v-bind="activator.props"
        :ariaExpanded="activator.isActive"
        :ariaPressed="jobRoleIds.length > 0"
        icon="lsi-job-roles"
        :data-identifier="`${dataIdentifierPrefix}-jobRole-picker-button`"
        :disabled="disabled"
      >
        {{ title }}
      </LswFilterOptionButton>
    </template>
    <LscSheet class="flex w-72 flex-col px-0">
      <LscSearchBar v-model="searchTerm" :clearable="true" :placeholder="t('Search roles')" autofocus class="mx-4" />
      <LswLoaderState :state="state" :blankTitle="t('No roles found')">
        <template #default>
          <VList density="compact" maxHeight="384">
            <VListItem
              v-for="jobRole in items"
              :key="jobRole.id"
              :active="jobRoleIds.includes(jobRole.id)"
              class="my-1 rounded-sm"
              :data-identifier="`${dataIdentifierPrefix}-jobRole-picker-item`"
              @click="toggleJobRole(jobRole)"
            >
              <div class="flex items-center justify-between text-body-1">
                <LscOverflowEllipsis class="ml-2 max-w-32 text-body-1">
                  {{ jobRole.name }}
                </LscOverflowEllipsis>
                <LscIcon :icon="icon(jobRole.id)" class="text-icon-subtle" size="sm" />
              </div>
            </VListItem>
            <LswLoaderTrigger v-model:count="count" :state="state" :step="pageSize" />
          </VList>
        </template>
        <!-- Return empty blank state as per design -->
        <template #blank>
          <div class="mb-2" />
        </template>
      </LswLoaderState>
    </LscSheet>
  </LscMenu>
</template>
