import { DateTime } from 'luxon';
import { useCurrentAccountState } from '../account/useCurrentAccount';
import { useCurrentUserState } from '../user/useCurrentUser';

const COMPANY_SIZES = [
  { id: 1, label: '1-5' },
  { id: 2, label: '6-10' },
  { id: 3, label: '11-20' },
  { id: 4, label: '21-35' },
  { id: 5, label: '36-50' },
  { id: 6, label: '51-200' },
  { id: 7, label: '201-500' },
  { id: 8, label: '501+' },
];

const USER_ROLES = [
  { id: 1, label: 'C-Level, Partner, Founder, Director, VP' },
  { id: 2, label: 'Project Manager' },
  { id: 3, label: 'Operations Manager' },
  { id: 4, label: 'Team Lead/Manager' },
  { id: 5, label: 'Team Member' },
  { id: 6, label: 'Contractor' },
  { id: 7, label: 'Other' },
  { id: 8, label: 'Student' },
];

const DEFAULT_COMMON_METRICS = ['plan_name', 'user_role', 'page', 'on_trial', 'is_icp', 'is_paid', 'timestamp'];

/** @typedef {'timestamp' | 'account_id' | 'on_trial' | 'is_icp' | 'is_paid' | 'start_date' | 'plan_type' | 'currency' | 'initial_plan_id' | 'initial_plan_name' | 'initial_payment_period' | 'days_since_purchase' | 'product_code' | 'initial_number_of_seats' | 'company_size_id' | 'company_size' | 'company_user_role_id' | 'company_user_role' | 'company_sector' | 'user_role' | 'plan_name' | 'page' | 'page_tab' | 'plan_id'} CommonMetric */

/** @typedef {'total_active_forms' } IncludeMetric */

/**
 * Internal composable for `usePendo.js`, providing commonly used metrics.
 */
export function usePendoCommonMetrics() {
  const { item: currentAccount, inSync: currentAccountInSync } = useCurrentAccountState();
  const { item: currentUser, inSync: currentUserInSync } = useCurrentUserState();

  const userRole = computed(() => {
    if (!currentUser.value) {
      return undefined;
    }

    if (currentUser.value.siteOwner) {
      return 'Site Owner';
    }
    if (currentUser.value.administrator) {
      return 'Administrator';
    }
    if (currentUser.value.isClientUser) {
      return 'Client User';
    }
    if (currentUser.value.isCollaborator) {
      return 'Collaborator';
    }

    return 'Standard User';
  });

  const allCommonMetricsInSync = computed(() => currentAccountInSync.value && currentUserInSync.value);

  function getDaysSincePurchase() {
    if (!currentAccount.value?.installationDateFirstPayment?.isValid) {
      return undefined;
    }

    return DateTime.now()
      .set({ hour: 24, minute: 0, second: 0, millisecond: 0 })
      .diff(currentAccount.value?.installationDateFirstPayment, 'days')
      .toObject().days;
  }

  /**
   * @param {CommonMetric[]} selectedMetrics List with metrics to be loaded
   * @returns Object with loaded values from selected metrics
   */
  function getCommonMetrics(selectedMetrics = []) {
    const combinedMetrics = [...DEFAULT_COMMON_METRICS, ...selectedMetrics];
    return {
      ...(combinedMetrics.includes('timestamp') && {
        timestamp: DateTime.now().toUTC().toISO(),
      }),
      ...(combinedMetrics.includes('account_id') && {
        account_id: currentAccount.value?.id?.toString(),
      }),
      ...(combinedMetrics.includes('on_trial') && {
        on_trial: currentAccount.value?.trialDaysRemaining > 0,
      }),
      ...(combinedMetrics.includes('is_paid') && {
        is_paid: currentAccount.value?.isPaid,
      }),
      ...(combinedMetrics.includes('is_icp') && {
        is_icp: currentAccount.value?.isICP,
      }),
      ...(combinedMetrics.includes('start_date') && {
        start_date: currentAccount.value?.createdAt?.toISODate(),
      }),
      ...(combinedMetrics.includes('plan_type') && {
        plan_type: currentAccount.value?.pricePlanType,
      }),
      ...(combinedMetrics.includes('currency') && {
        currency: currentAccount.value?.currency?.code,
      }),
      ...(combinedMetrics.includes('initial_plan_id') && {
        initial_plan_id: currentAccount.value?.pricePlanId,
      }),
      ...(combinedMetrics.includes('initial_plan_name') && {
        initial_plan_name: currentAccount.value?.pricePlanName,
      }),
      ...(combinedMetrics.includes('initial_payment_period') && {
        initial_payment_period: currentAccount.value?.billingPeriod,
      }),
      ...(combinedMetrics.includes('days_since_purchase') && {
        days_since_purchase: getDaysSincePurchase(),
      }),
      ...(combinedMetrics.includes('product_code') && {
        product_code: 'projects',
      }),
      ...(combinedMetrics.includes('initial_number_of_seats') && {
        initial_number_of_seats: currentAccount.value?.paidForUsers,
      }),
      ...(combinedMetrics.includes('company_size_id') && {
        company_size_id: currentAccount.value?.companySizeId,
      }),
      ...(combinedMetrics.includes('company_size') && {
        company_size: COMPANY_SIZES.find((i) => i.id === currentAccount.value?.companySizeId)?.label,
      }),
      ...(combinedMetrics.includes('company_user_role_id') && {
        company_user_role_id: currentUser.value?.companyRoleId,
      }),
      ...(combinedMetrics.includes('company_user_role') && {
        company_user_role: USER_ROLES.find((i) => i.id === currentUser.value?.companyRoleId)?.label,
      }),
      ...(combinedMetrics.includes('company_sector') && {
        company_sector: currentAccount.value?.industryCategoryName,
      }),
      ...(combinedMetrics.includes('user_id') && {
        user_id: currentUser.value?.id?.toString(),
      }),
      ...(combinedMetrics.includes('user_role') && { user_role: userRole.value }),
      ...(combinedMetrics.includes('plan_name') && { plan_name: currentAccount.value?.pricePlanData?.name }),
      ...(combinedMetrics.includes('page') && { page: window.location.pathname.replace(/^\/app/, '') }),
      ...(combinedMetrics.includes('page_tab') && {
        page_tab: window.location.pathname.split('/')?.at(-1),
      }),
      ...(combinedMetrics.includes('billing_period') && {
        billing_period: currentAccount.value?.billingPeriod,
      }),
      ...(combinedMetrics.includes('payment_method') && {
        payment_method: currentAccount.value?.paymentMethod,
      }),
      ...(combinedMetrics.includes('plan_id') && {
        plan_id: currentAccount.value?.pricePlanData?.id,
      }),
    };
  }

  return {
    allCommonMetricsInSync,
    getCommonMetrics,
  };
}
