import { createRouter as createVueRouter, createWebHistory, loadRouteLocation } from 'vue-router';
import { routes } from './routes';

// The following query params should be persisted on route changes.
const queryParamsToKeep = ['pendo', 'bypassExperiments', 'logTrackEvents', 'theater'];

export function createRouter() {
  const router = createVueRouter({
    history: createWebHistory('/app/'),
    routes,
  });

  // Persist some specific query params.
  router.beforeResolve(async (to, from, next) => {
    const fromQuery = from.query;
    let toQuery = to.query;

    // check if there is a quick view background and ensure route is loaded before continuing.
    const { quickViewBackgroundPath } = window.history.state || {};
    if (quickViewBackgroundPath) {
      await loadRouteLocation(router.resolve(quickViewBackgroundPath));
    }

    for (const param of queryParamsToKeep) {
      // We compare with `undefined` to persist existing params with `null` values too.
      if (fromQuery[param] !== undefined && toQuery[param] === undefined) {
        // Keep the original query intact.
        if (toQuery === to.query) {
          toQuery = { ...to.query };
        }
        // Preserve the query param.
        toQuery[param] = fromQuery[param];
      }
    }

    if (toQuery === to.query) {
      // No params to preserve.
      next();
    } else {
      // Continue with a modified route to ensure that the specific query params are preserved.
      next({
        ...to,
        query: toQuery,
      });
    }
  });

  return router;
}
