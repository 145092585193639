import { useListLoader } from '../base/useListLoader';
import { useOptimisticUpdates } from '../base/useOptimisticUpdates.js';
import { useRealTimeUpdates } from '../base/useRealTimeUpdates.js';
import { useCurrentUser } from '../user/useCurrentUser';

function responseToItems(response) {
  const { companies = {}, teams = {}, jobRoles = {} } = response.data.included;
  const people = response.data.people || [];

  /* eslint-disable no-param-reassign */
  people.forEach((person) => {
    if (person.company && companies[person.company.id]) {
      person.company = companies[person.company.id];
    }

    person.teams?.forEach((team, index) => {
      if (teams[team.id]) {
        person.teams[index] = teams[team.id];
      }
    });

    person.jobRoles?.forEach((jobRole, index) => {
      if (jobRoles[jobRole.id]) {
        person.jobRoles[index] = jobRoles[jobRole.id];
      }
    });

    /* eslint-enable no-param-reassign */
  });
  return people;
}

function responseToMeta({ data: { meta } }) {
  return { totalCount: meta.page?.count };
}

/**
 * Loads a list of people from the Teamwork v3 API.
 * Can fetch people generally or if a project id provided can target a project
 * @param {Object} options
 * @param {Object=} options.params - Query parameters to send to the API
 * @param {Number=} options.projectId - Project ID to fetch people for
 * @param {ShallowRef<Number>} options.count - Number of people to fetch
 * @param {Number=} options.pageSize - Number of people to fetch per page
 */
export function usePeopleV3Loader({ params, projectId: _projectId, count, pageSize = 20 }) {
  const user = useCurrentUser();
  const projectId = shallowRef(_projectId);
  const url = computed(() =>
    projectId.value ? `/projects/api/v3/projects/${projectId.value}/people.json` : '/projects/api/v3/people.json',
  );

  const { state, refresh, update } = useListLoader({
    url,
    params,
    count,
    responseToItems,
    responseToMeta,
    pageSize,
  });

  useOptimisticUpdates((event) => {
    if (event.type !== 'person') {
      return;
    }
    update((people) => {
      const optimisticPerson = event.person;

      if (event.action === 'create') {
        return [...people, optimisticPerson];
      }
      if (event.action === 'update') {
        return people.map((person) =>
          person.id === optimisticPerson.id ? { ...person, ...optimisticPerson } : person,
        );
      }
      if (event.action === 'delete') {
        return people.filter((person) => person.id !== optimisticPerson.id);
      }

      return people;
    }, event.promise);
  });

  useRealTimeUpdates((event) => {
    switch (event.type) {
      case 'person':
        /*
          Decide to reload one user or a full reload.
          If the current user has been edited, then we need a full reload
          because their permissions may now allow them to see more or less (e.g. rates)
        */
        if (event.userId && event.userId !== user.value.id) {
          refresh(event.userId);
        } else {
          refresh();
        }
        break;
      case 'jobRole':
      case 'userRate':
      case 'team':
        refresh();
        break;
      case 'project':
        if (event.projectId === projectId.value) {
          refresh();
        }
        break;
      case 'invite':
        refresh();
        break;
      default:
    }
  });

  return state;
}
