import { usePendo } from '../features/usePendo';

/**
 * Functions related to Dummy Pendo Experiment to evaluate the following flags
 * https://app.launchdarkly.com/default/production/features/projects-dummy-pendo-experiment-icp-trials-pms-only/targeting
 * https://app.launchdarkly.com/default/production/features/projects-dummy-pendo-experiment-icp-trials/targeting
 * https://app.launchdarkly.com/default/production/features/projects-dummy-pendo-experiment-all-trials/targeting
 * Task details: https://digitalcrew.teamwork.com/app/tasks/21306979
 */
export function useDummyPendoExperiment() {
  const { trackExperimentInPendo } = usePendo();

  const defaultFlagValue = false;
  const ignoreInExperimentOnRuleMatch = true;
  const EXP_DUMMY_ALL_TRIALS = 'projects-dummy-pendo-experiment-all-trials';
  const EXP_DUMMY_ICP_TRIALS = 'projects-dummy-pendo-experiment-icp-trials';
  const EXP_DUMMY_ICP_PM_TRIALS = 'projects-dummy-pendo-experiment-icp-trials-pms-only';

  function trackDummyExperimentAllTrials() {
    trackExperimentInPendo({
      launchDarklyFlagKey: EXP_DUMMY_ALL_TRIALS,
      defaultValue: defaultFlagValue,
      ignoreInExperimentOnRuleMatch,
    });
  }

  function trackDummyExperimentIcpTrials() {
    trackExperimentInPendo({
      launchDarklyFlagKey: EXP_DUMMY_ICP_TRIALS,
      defaultValue: defaultFlagValue,
      ignoreInExperimentOnRuleMatch,
    });
  }

  function trackDummyExperimentIcpPmTrials() {
    trackExperimentInPendo({
      launchDarklyFlagKey: EXP_DUMMY_ICP_PM_TRIALS,
      defaultValue: defaultFlagValue,
      ignoreInExperimentOnRuleMatch,
    });
  }

  function trackDummyExperiments() {
    trackDummyExperimentAllTrials();
    trackDummyExperimentIcpTrials();
    trackDummyExperimentIcpPmTrials();
  }

  return {
    trackDummyExperiments,
  };
}
