export function getCustomfieldSlot(column) {
  switch (column.unitId || column.type) {
    // case 1:
    //   return 'customfield-currency';
    // case 2:
    //   return 'customfield-duration';
    // case 1001:
    //   return 'customfield-currency-per-duration';
    // case 1002:
    //   return 'customfield-duration-per-currency';
    case 'status':
    case 'dropdown':
      return 'customfield-dropdown';
    case 'date':
      return 'customfield-date';
    case 'checkbox':
      return 'customfield-checkbox';
    case 'url':
      return 'customfield-link';
    case 'number-decimal':
    case 'number-integer':
    case 'text-short':
    default:
      return 'customfield-text';
  }
}
