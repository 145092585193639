<script setup>
import { watchOnce } from '@vueuse/core';
import { useExperimentA2408, usePendo, usePreferences } from '@/api';
import { useQuickView, useRoute } from '@/route';
import { useI18n } from '@/util';
import { useOnboardingChecklist } from '@/module/onboarding';
import RepsAccountPromptDialog from './RepsAccountPromptDialog.vue';
import { useReps } from './useReps';

const { t } = useI18n();
const { isAssignedReps, accountAssociatedReps, isAccountRoTW } = useReps();
const { trackPendoEvent } = usePendo();
const { firstTimeRepsModalViewed } = usePreferences();
const route = useRoute();
const { isExpA2408Variation } = useExperimentA2408();
const { isChecklistFlowEnabled } = useOnboardingChecklist();
const { isQuickViewExpanded } = useQuickView();

const shouldShowActivator = shallowRef(true);
const shouldShowDismiss = shallowRef(false);
const shouldShowRepsDialog = shallowRef(false);

function showDismiss() {
  shouldShowDismiss.value = true;
}

function hideDismiss() {
  shouldShowDismiss.value = false;
}

function dismissActivator() {
  trackPendoEvent({
    eventName: 'CONTACT_PROMPT_EVENT',
    metadata: {
      event_action: 'prompt_icon_dismissed',
    },
  });
  shouldShowActivator.value = false;
}

function toggleShowRepsDialog() {
  shouldShowRepsDialog.value = !shouldShowRepsDialog.value;
}

const hasV1Reps = computed(() => {
  if (isAccountRoTW.value) {
    return accountAssociatedReps.value?.csm;
  }
  if (route.path.includes('subscription')) {
    return accountAssociatedReps.value?.accountManager;
  }
  return accountAssociatedReps.value?.csm;
});

const hasExpA2408Rep = computed(() => {
  if (isExpA2408Variation.value) {
    return Boolean(accountAssociatedReps.value?.productSpecialist);
  }

  return false;
});

const onboardingRoutePaths = ['getting-started', 'onboarding'];
const isOnboardingRoute = computed(() => onboardingRoutePaths.some((path) => route.path.includes(path)));

watchOnce(
  () => isAssignedReps.value,
  (isAssigned) => {
    if (isAssigned && shouldShowActivator.value && hasV1Reps.value) {
      trackPendoEvent({
        eventName: 'CONTACT_PROMPT_EVENT',
        metadata: {
          event_action: 'prompt_icon_displayed',
        },
      });
    }
    if (!firstTimeRepsModalViewed.value && isAssignedReps.value && hasV1Reps.value) {
      shouldShowRepsDialog.value = true;
      firstTimeRepsModalViewed.value = true;
    }
  },
  { immediate: false },
);
</script>

<template>
  <div v-if="hasV1Reps || hasExpA2408Rep">
    <RepsAccountPromptDialog
      v-if="shouldShowRepsDialog"
      class="fixed right-6 z-20 flex flex-col p-1"
      :class="{
        'bottom-32 mb-3': isChecklistFlowEnabled && !isOnboardingRoute,
        'bottom-20': !isChecklistFlowEnabled || isOnboardingRoute,
      }"
      @close="toggleShowRepsDialog"
    />
    <div
      v-if="shouldShowActivator && isAssignedReps"
      class="button-container fixed right-6 z-20 flex flex-col p-1"
      :class="{
        'bottom-20 mb-0.5': isChecklistFlowEnabled && !isOnboardingRoute,
        'bottom-6': !isChecklistFlowEnabled || isOnboardingRoute,
      }"
      @mouseover="showDismiss()"
      @mouseleave="hideDismiss()"
    >
      <div class="-mr-2 flex justify-end pb-1">
        <LscIconButton
          v-show="shouldShowDismiss && !shouldShowRepsDialog"
          v-LsdTooltip="t('Dismiss')"
          icon="lsi-close"
          variant="secondary"
          :ariaLabel="t('Dismiss')"
          size="xs"
          @click="dismissActivator()"
        />
      </div>
      <LscButton
        v-if="!isOnboardingRoute && !isQuickViewExpanded && isAccountRoTW"
        appendIcon="lsi-comment-text"
        variant="secondary"
        size="lg"
        class="bg-primary hover:bg-[--lsds-c-button-color-bg-primary-hover]"
        :ariaLabel="t('Contact your CSM')"
        @click="toggleShowRepsDialog()"
      >
        {{ t('Contact your CSM') }}
      </LscButton>
      <LscIconButton
        v-else-if="!isOnboardingRoute && !isQuickViewExpanded"
        icon="lsi-comment-text"
        variant="secondary"
        size="lg"
        class="bg-primary hover:bg-[--lsds-c-button-color-bg-primary-hover]"
        :ariaLabel="t('Book a call')"
        @click="toggleShowRepsDialog()"
      />
    </div>
  </div>
</template>
