<script setup>
import { useI18n } from '@/util';

const { t } = useI18n();
</script>
<template>
  <div class="text-body-1">
    <p>
      {{ t('Next, learn about future forecasting in the Scheduler.') }}
    </p>
  </div>
</template>
