import { usePendo } from '@/api';
import { useQuickView, useRoute } from '@/route';

/**
 * Provides functions for tracking Pendo events related to the task details page.
 * Link: https://digitalcrew.teamwork.com/spaces/product-analytics/page/54198-task-editing-task-detail-panels-phase-i
 */
export function useTaskEditTracking(context) {
  const {
    trackPendoEvent: trackEvent,
    EVENT_CATEGORIES: { ADVANCED_DISCOVERY, ACTIVATION },
  } = usePendo();
  const { isQuickViewExpanded } = useQuickView();
  const route = useRoute();

  const TASK_EDIT_EVENT_NAME = 'TASK_EDIT_EVENT';

  function track(data) {
    trackEvent({
      eventName: TASK_EDIT_EVENT_NAME,
      metadata: {
        task_details_modal_type: unref(context)?.type ?? 'side_panel',
        task_details_modal_version: unref(context)?.version ?? 'lightspeed',
        task_details_panel_size: unref(context)?.panelSize ?? (isQuickViewExpanded.value ? 'expanded' : 'minimized'),
        project_view_type: route.meta.view,
        page_type: route.path
          .split('/')
          .filter((routePart) => Number.isNaN(Number(routePart)) && !routePart.includes('?'))
          .join('_'),
        ...data,
      },
    });
  }

  function trackTaskDetailDisplayEvent(isPartialView) {
    track({
      event_action: 'task_details_panel_displayed',
      event_category: ADVANCED_DISCOVERY,
      is_partial_view: isPartialView,
    });
  }

  function trackTaskDetailDiscoveryEvent(itemType) {
    track({
      event_action: 'task_item_clicked',
      event_category: ADVANCED_DISCOVERY,
      item_type: itemType,
    });
  }

  function trackTaskEditActivationEvent(itemType) {
    track({
      event_action: 'task_item_edited',
      event_category: ACTIVATION,
      item_type: itemType,
    });
  }

  return {
    trackTaskDetailDisplayEvent,
    trackTaskDetailDiscoveryEvent,
    trackTaskEditActivationEvent,
  };
}
