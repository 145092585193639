import { useFeatures } from '../features/useFeatures';
import { usePendo } from '../features/usePendo';

/**
 * Constants, computed properties and functions related to Experiment R-2408
 * https://app.launchdarkly.com/projects/default/flags/projects-exp-r-24-08-product-emails-redesign/targeting
 * https://digitalcrew.teamwork.com/spaces/growth/page/58040-r-24-08-product-emails-redesign
 */

export function useExperimentR2408() {
  const { projectsProductEmailsRedesignEnabled } = useFeatures();
  const { trackExperimentInPendo } = usePendo();

  const { launchDarklyFlagKey, defaultValue } = projectsProductEmailsRedesignEnabled;
  const ignoreInExperimentOnRuleMatch = true;

  function trackExperimentR2408() {
    trackExperimentInPendo({
      launchDarklyFlagKey,
      defaultValue,
      ignoreInExperimentOnRuleMatch,
    });
  }

  return {
    trackExperimentR2408,
  };
}
