import type { LscDateStepperSize } from './LscDateStepper.types.js';

const symbol = Symbol('LscDateStepper');

type DateStepperParams = {
  size: ComputedRef<LscDateStepperSize>;
};

function DateStepper({ size }: DateStepperParams) {
  return {
    size,
  };
}

export function provideLscDateStepper({ size }: DateStepperParams) {
  provide(symbol, DateStepper({ size }));
}

export function useLscDateStepper() {
  const context = inject(symbol);
  if (!context) {
    throw new Error('useLscDateStepper must be used within a LscDateStepperProvider');
  }
  return context as ReturnType<typeof DateStepper>;
}
