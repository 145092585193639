import { useListLoader } from '../base/useListLoader';

function responseToItems(response) {
  const { spaces = {} } = response.data.included || {};

  const list = response.data.results || [];

  list.forEach((page) => {
    // This call returning pageId instead of id for some reason
    if (page.pageId) {
      // eslint-disable-next-line no-param-reassign
      page.id = page.pageId;
    }
    if (page.space?.id && spaces[page.space.id]) {
      Object.assign(page.space, spaces[page.space.id]);
    }
  });
  return list;
}

/**
 * Searches pages in Spaces
 * @param {Object} options
 */
export function useSpacesSearchV1Loader({ params, count, pageSize = 50 }) {
  const { state } = useListLoader({
    url: '/spaces/api/v1/search.json',
    params,
    count,
    responseToItems,
    pageSize,
  });

  return state;
}
