<script setup>
import { useI18n } from '@/util';

const { t } = useI18n();
</script>
<template>
  <div class="text-body-1">
    <p>
      {{
        t(
          'The insight donuts in this view shows that your total allocated time exceeds the total available time for your team. Has your team enough resources to tackle all the work?',
        )
      }}
    </p>
  </div>
</template>
