import { useEventListener } from '@vueuse/core';

/**
 * Simplifies implementation of drop targets.
 * @template State
 * @param {Object} options
 * @param {MaybeRef<HTMLElement>|undefined} options.element
 *   The drop target element for attaching `dragenter`, `dragleave`, `dragover` and `drop` event listeners.
 * @param {(event: DragEvent) => State|null|undefined} [options.getState]
 *   Extracts some arbitrary state from `DragEvent` on `dragenter`, `dragover` and `drop`.
 *   If it returns `null` or `undefined`, the `state` will be set to `null` and the drag event will be ignored,
 *   otherwise the `state` will be set to the returned value.
 *   Defaults to a function which returns `undefined`.
 * @param {(event: DragEvent, state: State) => void} [options.drop]
 *   Called on the `drop` event with `DragEvent` and the latest `state` but only when the `state` is not `null`.
 *   Defaults to a function which does nothing.
 * @returns {Ref<State>} Returns a ref containing the current dragging `state`, or `undefined` if nothing is being dragged.
 * @see https://html.spec.whatwg.org/multipage/dnd.html
 */
export function useDrop({ element: _element, getState = () => undefined, drop = () => undefined }) {
  const element = shallowRef(_element);

  let counter = 0;
  const state = shallowRef();

  watch(element, () => {
    counter = 0;
    state.value = undefined;
  });

  function updateState(event) {
    try {
      state.value = getState(event) ?? null;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('useDrop: `getState` failed', error);
      state.value = null;
    }
    if (state.value != null) {
      event.preventDefault();
    }
  }

  useEventListener(element, 'dragenter', (event) => {
    counter++;
    updateState(event);
  });

  useEventListener(element, 'dragleave', () => {
    counter--;
    if (counter === 0) {
      state.value = undefined;
    }
  });

  useEventListener(element, 'dragover', (event) => {
    counter = 1;
    updateState(event);
  });

  useEventListener(element, 'drop', (event) => {
    counter = 0;
    updateState(event);
    if (state.value != null) {
      try {
        drop(event, state.value);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('useDrop: `drop` failed', error);
      }
    }
    state.value = undefined;
  });

  return state;
}
