import * as Sentry from '@sentry/vue';
import { z } from 'zod';
import { useI18n } from '../i18n/useI18n';

function ZodValidation() {
  const { t } = useI18n();

  // TODO: Make validation error messages more user friendly
  // See https://digitalcrew.teamwork.com/app/tasks/23779961
  function getMessage(issue) {
    switch (issue.code) {
      case z.ZodIssueCode.invalid_type:
        return t('Required');
      case z.ZodIssueCode.invalid_literal:
        return t('Invalid value');
      case z.ZodIssueCode.unrecognized_keys:
        return t('Unrecognized key(s) in object');
      case z.ZodIssueCode.invalid_union:
        return t('Invalid value');
      case z.ZodIssueCode.invalid_union_discriminator:
        return t('Invalid value');
      case z.ZodIssueCode.invalid_enum_value:
        return t('Please select a valid option');
      case z.ZodIssueCode.invalid_arguments:
        return t('Invalid function arguments');
      case z.ZodIssueCode.invalid_return_type:
        return t('Invalid function return type');
      case z.ZodIssueCode.invalid_date:
        return t('Please enter a valid date');
      case z.ZodIssueCode.invalid_string:
        if (issue.validation === 'url') {
          return t('Please enter a valid URL');
        }
        if (issue.validation === 'email') {
          return t('Please enter a valid email address');
        }
        return t('Please enter some valid text');
      case z.ZodIssueCode.too_small:
        if (issue.minimum === 0 || issue.minimum === 1) {
          return t('Required');
        }
        if (issue.type === 'array') {
          return t('This field must contain at least {min} items', { min: issue.minimum });
        }
        if (issue.type === 'string') {
          return t('This field must be at least {min} characters long', { min: issue.minimum });
        }
        if (issue.type === 'number') {
          return t('The minimum value allowed is {min}', { min: issue.minimum });
        }
        if (issue.type === 'date') {
          return t('The minimum date allowed is {min}', { min: issue.minimum });
        }
        return t('Invalid value');
      case z.ZodIssueCode.too_big:
        if (issue.type === 'array') {
          return t('This field must contain at most {max} items', { max: issue.maximum });
        }
        if (issue.type === 'string') {
          return t('This field must be at most {max} characters long', { max: issue.maximum });
        }
        if (issue.type === 'number') {
          t('The maximum value allowed is {max}', { max: issue.maximum });
        }
        if (issue.type === 'date') {
          return t('The maximum date allowed is {max}', { min: issue.maximum });
        }
        return t('Invalid value');
      case z.ZodIssueCode.custom:
        return t('Invalid value');
      case z.ZodIssueCode.invalid_intersection_types:
        return t('Intersection results could not be merged');
      case z.ZodIssueCode.not_multiple_of:
        return t('Number must be a multiple of a specific value');
      case z.ZodIssueCode.not_finite:
        return t('Number must be finite');
      default:
        // eslint-disable-next-line no-case-declarations
        const warningMessage = `Missing Zod validation message for issue code: ${issue.code}`;

        // eslint-disable-next-line no-console
        console.warn(warningMessage);

        Sentry.captureMessage(warningMessage, {
          level: 'warning',
          extra: { issue },
        });

        return t('Invalid value');
    }
  }

  function customErrorMap(issue) {
    return { message: getMessage(issue) };
  }

  z.setErrorMap(customErrorMap);
}

/**
 * @param {import('vue').App} app
 * @type {ZodValidation}
 */
export const zodValidationPlugin = {
  install() {
    ZodValidation();
  },
};
