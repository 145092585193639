import { useCurrentAccount } from '@/api';

export function useCheckoutStandardUserDefaults() {
  const account = useCurrentAccount();

  const checkoutData = shallowRef({
    installation: {
      apps: {
        projects: {
          appCode: 'projects',
          cardId: 'unknown',
          paymentCollectionMethod: 'unknown',
          pricePlanId: account.value.pricePlanId,
          paymentMethod: account.value.paymentMethod,
          paidUsers: account.value.paidForUsers,
          isPaid: account.value.isPaid,
          addons: [],
        },
      },
      overrides: {
        projects: [],
      },
    },
    enterpriseRequests: {},
    plans: [],
    addons: [],
    usage: {},
    user: {},
    currency: {},
  });

  return {
    item: checkoutData,
  };
}
