<script setup>
import { useI18n } from '@/util';

const { t } = useI18n();
</script>
<template>
  <div class="text-body-1">
    <p>
      {{
        t(
          "Use description text or follow-up messages to share with submitters what they can expect. For example, if you're collecting requests, provide a general timeline for when someone might expect to hear back.",
        )
      }}
    </p>
  </div>
</template>
