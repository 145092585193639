import { useCohort } from '../cohort/useCohort';
import { useExperimentA2413c } from '../experiments/useExperimentA2413c';
import {
  useAccountPreference,
  useArrayType,
  useBooleanType,
  useInitialized,
  useObjectType,
  useStringType,
  useUserPreference,
} from './preferencesUtil';

const symbol = Symbol('usePreferences');

function Preferences() {
  const initialized = useInitialized();
  const { isGrowPM, isPersonaOwner } = useCohort();
  const { isExpA2413cVariation, personalisedSidebarPinnedItems } = useExperimentA2413c();

  const defaultSidebarPinnedItems = computed(() =>
    isExpA2413cVariation.value
      ? personalisedSidebarPinnedItems.value
      : {
          home: { docked: true },
          search: { docked: true },
          welcome: { docked: true },
          jumpTo: { docked: true },
          clients: { docked: isGrowPM.value },
          projects: { docked: true },
          templates: { docked: true },
          planning: { docked: isGrowPM.value || isPersonaOwner.value },
          budgets: { docked: isGrowPM.value },
          reports: { docked: isGrowPM.value },
          time: { docked: isGrowPM.value },
          proofs: { docked: false },
          forms: { docked: false },
          everything: { docked: false },
          calendar: { docked: false },
          people: { docked: false },
        },
  );

  return {
    initialized,
    notificationDrawerActiveTab: useUserPreference('notificationDrawerActiveTab', useStringType('unread')),
    switchAllUsersToRedesignedSidebar: useAccountPreference('switchAllUsersToRedesignedSidebar', useBooleanType()),
    shouldMinimizeSidebar: useUserPreference('shouldMinimizeSidebar', useBooleanType()),
    shouldShowRedesignedSidebar: useUserPreference('shouldShowRedesignedSidebar', useBooleanType()),
    projectHealthGreenLabel: useAccountPreference('projectHealthGreenLabel', useStringType()),
    projectHealthAmberLabel: useAccountPreference('projectHealthAmberLabel', useStringType()),
    projectHealthRedLabel: useAccountPreference('projectHealthRedLabel', useStringType()),
    sidebarPinnedItems: useUserPreference('sidebarPinnedItems', useObjectType(defaultSidebarPinnedItems)),
    sidebarPinnedPanel: useUserPreference('sidebarPinnedPanel', useObjectType({ name: null, isOpen: false })),
    hotspots: useUserPreference('hotspots', useObjectType()),
    areNotificationsUnmuted: useUserPreference('webBNotifsOn', useBooleanType(true)),
    itemCommentsSortBy: useUserPreference('itemCommentsSortBy', useStringType('date')),
    commentThreadSort: useUserPreference('commentThreadSort', useStringType('desc')),
    useDesktopNotifications: useUserPreference('useDesktopNotifications', useBooleanType(true)),
    vueListViewActive: useUserPreference('vueListViewActive', useBooleanType()),
    firstTimeExperienceVisibility: useUserPreference(
      'firstTimeExperienceVisibility',
      useObjectType({ proofing: true, clientEmail: true }),
    ),
    taskPushSubTasks: useUserPreference('taskPushSubTasks', useBooleanType()),
    tasklistTemplateSkipWeekends: useUserPreference('tasklistTemplateSkipWeekends', useBooleanType()),
    taskPushDependentDates: useUserPreference('taskPushDependentDates', useBooleanType()),
    onboardingChecklistDismissed: useUserPreference('onboardingChecklistDismissed', useBooleanType(false)),
    productToursCompleted: useUserPreference('productToursCompleted', useArrayType([])),
    sampleProjectsBannerDismissed: useUserPreference('sampleProjectsBannerDismissed', useBooleanType(false)),
    sampleProjectsVisible: useUserPreference('sampleProjectsVisible', useArrayType([])),
    sampleProjectIds: useUserPreference('sampleProjectIds', useArrayType([])),
    sampleProjectsRemovedDuringCheckout: useUserPreference(
      'sampleProjectsRemovedDuringCheckout',
      useBooleanType(false),
    ),
    sampleUserIds: useUserPreference('sampleUserIds', useArrayType([])),
    sampleClientIds: useUserPreference('sampleClientIds', useArrayType([])),
    trialWarningBannerDismissed: useUserPreference(
      'trialWarningBannerDismissed',
      useObjectType({
        trialEndingSoon: false,
        trialExpiry: false,
      }),
    ),
    sampleRoles: useUserPreference('sampleRoles', useArrayType([])),
    invoiceBannerDismissed: useUserPreference('invoiceBannerDismissed', useBooleanType(false)),
    financeRatesBannerDismissed: useUserPreference('financeRatesBannerDismissed', useBooleanType(false)),
    featureTrialsBannersVisibility: useUserPreference(
      'featureTrialsBannersVisibility',
      useObjectType({
        tasklistbudgets: true,
        retainerbudgets: true,
        profitability: true,
        customreporting: true,
      }),
    ),
    homeImprovementsBannersVisibility: useUserPreference(
      'homeImprovementsBannersVisibility',
      useObjectType({
        timeBlock: true,
        myWork: true,
        myCalendar: true,
        myTimesheet: true,
      }),
    ),
    showCalendarMyWorkAlert: useUserPreference('showCalendarMyWorkAlert', useBooleanType(true)),
    shouldUseDefaultHomeTabSettings: useUserPreference('shouldUseDefaultHomeTabSettings', useBooleanType(true)),
    calendarShowDescriptions: useUserPreference('calendarShowDescriptions', useBooleanType(true)),
    calendarShowSubtask: useUserPreference('calendarShowSubtask', useBooleanType(true)),
    calendarShowCompletedTask: useUserPreference('calendarShowCompletedTask', useBooleanType(true)),
    calendarShowWeekend: useUserPreference('calendarShowWeekend', useBooleanType(false)),
    calendarShowTasksOnlyOnDueDate: useUserPreference('calendarShowTasksOnlyOnDueDate', useBooleanType(false)),
    calendarShowTeamTasks: useUserPreference('calendarShowTeamTasks', useBooleanType(false)),
    onboardingSelectedIntegrations: useUserPreference('onboardingSelectedIntegrations', useArrayType([])),
    blackFridayPromotionBannersDismissed: useUserPreference(
      'blackFridayPromotionBannersDismissed',
      useObjectType(
        computed(() => ({
          confirmBanner: false,
          remindBanner: false,
          saleUpgradeBannerFinal: false,
        })),
      ),
    ),
    scaleTrialUpgradeModalDismissed: useUserPreference('scaleTrialUpgradeModalDismissed', useBooleanType(false)),
    blackFridayFeatureTrialsStarted: useAccountPreference('blackFridayFeatureTrialsStarted', useArrayType([])),
    createCalendarEventLastProjectId: useUserPreference('createCalendarEventLastProjectId', useStringType()),
    quicklyAddTasksLastProjectId: useUserPreference('quicklyAddTasksLastProjectId', useStringType()),
    quicklyAddTasksLastTasklistId: useUserPreference('quicklyAddTasksLastTasklistId', useStringType()),
    shouldHideAllTimeApprovalsAlert: useUserPreference('shouldHideAllTimeApprovalsAlert', useBooleanType(false)),
    firstTimeVisitingApprovals: useUserPreference('firstTimeVisitingApprovals', useBooleanType(true)),
    firstTimeVisitingUserTimesheetApprovals: useUserPreference(
      'firstTimeVisitingUserTimesheetApprovals',
      useBooleanType(true),
    ),
    userWasATimeApprover: useUserPreference('userWasATimeApprover', useBooleanType(false)),
    shouldShowJan2024SaleBanner: useUserPreference('shouldShowJan2024SaleBannerV2', useBooleanType(true)),
    firstTimeRepsModalViewed: useUserPreference('firstTimeRepsModalViewed', useBooleanType(false)),
    showFinancialInsightsShowSampleDataAlert: useUserPreference(
      'showFinancialInsightsShowSampleDataAlert',
      useBooleanType(true),
    ),
    logTimeModalSmartDefaults: useUserPreference(
      'logTimeModalSmartDefaults',
      useObjectType({
        date: null,
        startTime: null,
        endTime: null,
        projectId: null,
        minutes: 30,
      }),
    ),
    logTimeModalSettings: useUserPreference(
      'logTimeModalSettings',
      useObjectType({
        anchoredToEndTime: true,
        autofillDuration: true,
        autofillDescription: false,
      }),
    ),
    sidebarShowRecentProjects: useUserPreference('sidebarShowRecentProjects', useBooleanType(false)),
    sidebarShowStarredProjects: useUserPreference('sidebarShowStarredProjects', useBooleanType(false)),
    reportsInAdvancedReporting: useAccountPreference('reportsInAdvancedReporting', useObjectType({})),
    onboardingProjectId: useAccountPreference('onboardingProjectId', useStringType()),
    trainingProjectSettings: useAccountPreference(
      'trainingProjectSettings',
      useObjectType({
        trainingProjectId: null,
        trainingTasklistId: null,
      }),
    ),
    dontAskTrainingAgain: useUserPreference('dontAskTrainingAgain', useBooleanType(false)),
    selectedWorkerBeeTrainingIds: useUserPreference('selectedWorkerBeeTrainingIds', useArrayType([1, 2, 3])),
    onboardingTemplateCreatedIds: useUserPreference('onboardingTemplateCreatedIds', useArrayType([])),
    onboardingTeamAddedOrRatedProjectIds: useUserPreference('onboardingTeamAddedOrRatedProjectIds', useArrayType([])),
    workflowsStandardUserFteViewed: useUserPreference('workflowsStandardUserFteViewed', useBooleanType(false)),
    onboardingTasklistGeneration: useUserPreference(
      'onboardingTasklistGeneration',
      useObjectType({ countByProjectId: {}, completedByProjectId: {} }),
    ),
    ticketTabWording: useAccountPreference('ticketTabWording', useStringType('tickets')),
    onboardingProjectSetup: useUserPreference(
      'onboardingProjectSetup',
      useObjectType({ progressByProjectId: {}, dismissedForAllProjects: false, dismissedForProjectIds: [] }),
    ),
    firstTimeVisitingProjectInvoices: useUserPreference('firstTimeVisitingProjectInvoices', useBooleanType(true)),
    firstTimeVisitingClientQuotes: useUserPreference('firstTimeVisitingClientQuotes', useBooleanType(true)),
    onboardingSelectedGoalId: useUserPreference('onboardingSelectedGoalId', useStringType()),
    industrySubcategoryModalDismissed: useAccountPreference('industrySubcategoryModalDismissed', useBooleanType(false)),
  };
}

export function providePreferences() {
  provide(symbol, Preferences());
}

/**
 * @type {Preferences}
 */
export function usePreferences() {
  return inject(symbol);
}
