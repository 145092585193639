<script setup>
import { useI18n } from '@/util';

const { t } = useI18n();
</script>
<template>
  <div class="text-body-1">
    <p>
      {{
        t(
          'Organize and report on your projects under the clients they relate to by creating a company for each client.',
        )
      }}
    </p>
    <p class="pt-6">
      {{
        t(
          'Creating a company allows you to bring your external contractors and client stakeholders into {teamwork} for free using our Collaborator and Client user licence types.',
          { teamwork: 'Teamwork.com' },
        )
      }}
    </p>
  </div>
</template>
