import { useFeatures } from '../features/useFeatures';
import { usePendo } from '../features/usePendo';

/**
 * Constants, computed properties and functions related to Experiment Lightspeed Onboarding
 * https://app.launchdarkly.com/default/staging/features/projects-lightspeed-onboarding/targeting
 * https://digitalcrew.teamwork.com/spaces/growth-2/page/52390-onboarding-ls-migration
 */

export function useExperimentOnboarding() {
  const { lightspeedOnboardingEnabled } = useFeatures();
  const { trackExperimentInPendo } = usePendo();
  const { launchDarklyFlagKey, defaultValue } = lightspeedOnboardingEnabled;
  const ignoreInExperimentOnRuleMatch = true;

  function trackExperimentOnboarding() {
    trackExperimentInPendo({
      launchDarklyFlagKey,
      defaultValue,
      ignoreInExperimentOnRuleMatch,
    });
  }

  return {
    lightspeedOnboardingEnabled,
    trackExperimentOnboarding,
  };
}
