<script setup>
import { useRouter } from 'vue-router';
import { useCurrentAccount, useCurrentAccountActions, useCurrentUser, useFeatures } from '@/api';
import { useI18n } from '@/util';
import { useLegacyBridge } from '@/module/legacy';
import { useAppShellSidebar } from '../useAppShellSidebar';
import { useAppShellSidebarTracking } from '../useAppShellSidebarTracking';

const { t } = useI18n();
const { showLegacyModal } = useLegacyBridge();
const { accessLinkedAccount } = useCurrentAccountActions();
const { isSidebarExpanded, isUserMenuSwitchAccountsOpen } = useAppShellSidebar();
const { trackProfileMenuItemClicked } = useAppShellSidebarTracking({ isSidebarExpanded });

const router = useRouter();
const user = useCurrentUser();
const account = useCurrentAccount();
const { linkedDemosEnabled } = useFeatures();

const defaultAccountFavicon = `${import.meta.env.BASE_URL}favicon.png`;

function showLinkedAccounts() {
  trackProfileMenuItemClicked('link_teamwork_accounts');

  showLegacyModal({
    modalName: 'linkedAccounts',
    args: { userId: user.id },
  });
}

async function navigateToLinkedAccount({ metaKey, ctrlKey }, { id, userId }) {
  const url = await accessLinkedAccount({
    id,
    userId,
  });
  if (!url) {
    return;
  }
  const openNewTab = metaKey || ctrlKey;

  // Intentionally not using Vue's router here
  // External URLs otherwise wouldn't work with it
  if (openNewTab) {
    router.newTab(url);
  } else {
    window.location.href = url;
  }
}

function navigateToPartnerAccount({ metaKey, ctrlKey }, { link }) {
  const url = link;
  if (!url) {
    return;
  }
  const openNewTab = metaKey || ctrlKey;

  // Intentionally not using Vue's router here
  // External URLs otherwise wouldn't work with it
  if (openNewTab) {
    router.newTab(url);
  } else {
    window.location.href = url;
  }
}
</script>
<template>
  <WidgetOptionsMenu v-model="isUserMenuSwitchAccountsOpen" location="end" openOnHover openOnClick>
    <template #activator="activator">
      <WidgetOptionsMenuItem class="focus-visible:bg-selected" v-bind="activator.props">
        <template #prepend><LscIcon icon="lsi-website" /></template>
        <VListItemTitle>{{ t('Switch accounts') }}</VListItemTitle>
        <template #append><LscIcon icon="lsi-open-panel" /></template>
      </WidgetOptionsMenuItem>
    </template>

    <WidgetOptionsMenuItem
      v-for="accountItem in user.accounts"
      :key="accountItem.name"
      tabindex="0"
      link
      class="focus-visible:bg-selected"
      @click="navigateToLinkedAccount($event, accountItem)"
    >
      <template #prepend>
        <img
          class="mx-2 size-5"
          :src="`${accountItem.favIcon == 'favicon.ico' ? defaultAccountFavicon : accountItem.favIcon}`"
        />
      </template>
      <VListItemTitle :title="`${accountItem.name} (${accountItem.email})`">
        {{ accountItem.name }} ({{ accountItem.email }})
      </VListItemTitle>
      <VListItemSubtitle :title="accountItem.link">{{ accountItem.link }}</VListItemSubtitle>
    </WidgetOptionsMenuItem>
    <WidgetOptionsMenuItem class="focus-visible:bg-selected" @click="showLinkedAccounts">
      <template #prepend>
        <LscIcon icon="lsi-group" />
      </template>
      <VListItemTitle>
        {{ t('Link {teamwork} accounts', { teamwork: 'Teamwork.com' }) }}
      </VListItemTitle>
    </WidgetOptionsMenuItem>

    <template v-if="account.isPartner">
      <VDivider />

      <template v-if="linkedDemosEnabled">
        <WidgetOptionsMenuItem
          v-for="accountItem in user.partnerdemos"
          :key="accountItem.name"
          tabindex="0"
          link
          class="focus-visible:bg-selected"
          @click="navigateToPartnerAccount($event, accountItem)"
        >
          <template #prepend>
            <img
              class="size-5"
              :src="`${accountItem.favIcon == 'favicon.ico' ? defaultAccountFavicon : accountItem.favIcon}`"
            />
          </template>
          <div>
            <VListItemTitle :title="`${accountItem.name} (${accountItem.email})`">
              {{ accountItem.name }} ({{ accountItem.email }})
            </VListItemTitle>
            <VListItemSubtitle :title="accountItem.link">{{ accountItem.link }}</VListItemSubtitle>
          </div>
        </WidgetOptionsMenuItem>
      </template>

      <WidgetOptionsMenuItem
        link
        target="_blank"
        rel="noreferrer noopener"
        class="focus-visible:bg-selected"
        href="/?action=demoSite&demoType=partner"
        @click="trackProfileMenuItemClicked('create_partner_demo')"
      >
        <template #prepend>
          <LscIcon icon="lsi-group" />
        </template>
        <VListItemTitle>
          {{ t('Create Partner Demo') }}
        </VListItemTitle>
      </WidgetOptionsMenuItem>
    </template>
  </WidgetOptionsMenu>
</template>
