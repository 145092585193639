<script setup>
import { useLoadingCount, useProjectsV3Loader } from '@/api';
import AppShellSidebarProjectsItem from './AppShellSidebarProjectsItem.vue';

const props = defineProps({
  params: {
    type: Object,
    default: () => ({}),
  },
  noProjectsMessage: {
    type: String,
    required: true,
  },
  /**
   * The type of project list.
   * @type {PropType<'recent'|'starred'>}
   */
  projectListType: {
    type: String,
    required: true,
    validator: (value) => ['recent', 'starred'].includes(value),
  },
});

const step = 30;
const count = shallowRef(undefined);

const state = useProjectsV3Loader({
  count,
  pageSize: step,
  params: computed(() => props.params),
});

const loadingCount = useLoadingCount({ count, state, maxLoadingCount: step });

const { items: projects } = state;

watchEffect(() => {
  if (count.value >= 0) {
    return;
  }
  count.value = step;
});
</script>

<template>
  <LswLoaderState :state="state">
    <template #defaultOrLoading>
      <div class="flex flex-col items-stretch">
        <AppShellSidebarProjectsItem
          v-for="project in projects"
          :key="project.id"
          :project="project"
          :projectListType="projectListType"
        />
        <div
          v-for="i in loadingCount"
          :key="i"
          class="m-2 h-10 animate-pulse rounded-md bg-[--lsds-c-sidebar-drawer-list-item-background-color-on-selected]"
        />
        <LswLoaderTrigger v-model:count="count" :step="step" :state="state" />
      </div>
    </template>
    <template #blank>
      <div class="px-6 py-2 text-body-2 text-[--lsds-c-sidebar-drawer-list-item-color-on-surface]">
        {{ noProjectsMessage }}
      </div>
    </template>
  </LswLoaderState>
</template>
