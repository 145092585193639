import { useFeatures } from '../features/useFeatures';
import { usePendo } from '../features/usePendo';

/**
 * Constants, computed properties and functions related to Experiment R-2403 Consistent Task details rollout
 * https://app.launchdarkly.com/default/production/features/projects-lightspeed-task-details/targeting
 * https://digitalcrew.teamwork.com/spaces/growth/page/53989-r-24-03-task-details-panel
 */

export function useExperimentR2403() {
  const { projectsLightSpeedTaskDetailsUIVariation, projectsLightspeedTaskDetails, projectsReplaceTaskDetailsPage } =
    useFeatures();
  const { trackExperimentInPendo, trackPendoEvent } = usePendo();

  const { launchDarklyFlagKey, defaultValue } = projectsLightspeedTaskDetails;
  const ignoreInExperimentOnRuleMatch = true;

  const isExpR2403Variation = computed(() => projectsLightSpeedTaskDetailsUIVariation.value);
  const isCommentsSidebarVariation = computed(() => isExpR2403Variation.value && projectsReplaceTaskDetailsPage.value);

  function trackExperimentR2403() {
    trackExperimentInPendo({
      launchDarklyFlagKey,
      defaultValue,
      ignoreInExperimentOnRuleMatch,
      experimentEventSuffix: '_R-24-03',
    });
    if (isExpR2403Variation.value) {
      trackPendoEvent({ eventName: 'TASK_DETAILS_R-24-03_ENABLED' });
    }
  }

  return {
    launchDarklyFlagKey,
    isExpR2403Variation,
    trackExperimentR2403,
    isCommentsSidebarVariation,
  };
}
