<script setup>
import { usePreferences } from '@/api';
import { useI18n } from '@/util';
import AppShellSidebarProjectsList from './AppShellSidebarProjectsList.vue';

const { t } = useI18n();
const { sidebarShowRecentProjects } = usePreferences();
const params = {
  'searchTerm': null,
  'include': 'companies,projectCategories',
  'includeProjectUserInfo': true,
  'fields[projects]': 'id,name,isStarred,companyId,startPage,categoryId,logoIcon,logoColor',
  'orderBy': 'lastWorkedOn',
  'orderMode': 'desc',
  'onlyStarredProjects': false,
  'onlyProjectsWithExplicitMembership': 1,
};
</script>

<template>
  <button
    type="button"
    class="mx-6 my-1 inline-flex items-center gap-1 text-body-2 text-[color:--lsds-c-sidebar-drawer-icon-color-on-surface] hover:text-[color:--lsds-c-sidebar-drawer-list-item-color-on-hover]"
    data-identifier="ls-nav-sidebar-projects-recent"
    @click="sidebarShowRecentProjects = !sidebarShowRecentProjects"
  >
    {{ t('Recent') }}
    <LscIcon
      size="xs"
      icon="lsi-collapse"
      class="text-[color:--lsds-c-sidebar-drawer-icon-color-on-surface] transition-transform duration-300"
      :class="{ '-rotate-90': sidebarShowRecentProjects, '-rotate-180': !sidebarShowRecentProjects }"
    />
  </button>
  <KeepAlive>
    <AppShellSidebarProjectsList
      v-if="sidebarShowRecentProjects"
      :params="params"
      :noProjectsMessage="t('No recent projects')"
      projectListType="recent"
    />
  </KeepAlive>
</template>
