<script setup>
import { formatKeyboardShortcut, useI18n, useKeyboardShortcut } from '@/util';
import { useAppShellSidebar } from './useAppShellSidebar';

const { toggleSidebarExpansion, isSidebarExpanded } = useAppShellSidebar();
const { t } = useI18n();
useKeyboardShortcut('COMMAND B', toggleSidebarExpansion);
</script>

<template>
  <button
    v-LsdTooltip="{
      text: isSidebarExpanded ? t('Collapse') : t('Expand'),
      subtext: formatKeyboardShortcut('Ctrl + B'),
    }"
    type="button"
    data-identifier="app-nav__caret"
    :class="[
      'absolute bottom-7 left-full flex size-6 -translate-x-1/2 transform items-center justify-center rounded-full outline-none transition duration-500 ease-in-out',
      'bg-[color:--lsds-c-sidebar-drawer-list-item-background-color-on-selected]',
      'ring-2 ring-transparent',
      'hover:bg-[--lsds-c-sidebar-drawer-close-panel-icon-color-on-hover]',
      'focus-visible:ring-[color:--lsds-c-sidebar-drawer-list-item-background-color-on-focus]',
      'focus-visible:bg-[--lsds-c-sidebar-drawer-close-panel-icon-color-on-hover]',
      { '-rotate-180': !isSidebarExpanded },
    ]"
    @click.stop="toggleSidebarExpansion"
  >
    <LscIcon
      size="md"
      icon="lsi-collapse"
      class="text-[color:--lsds-c-sidebar-drawer-close-panel-icon-color-on-surface] transition-transform hover:scale-110"
    />
  </button>
</template>
