function hasScrollbar(el) {
  if (!el || el.nodeType !== Node.ELEMENT_NODE) {
    return false;
  }

  const style = window.getComputedStyle(el);
  return (
    style.overflowY === 'scroll' ||
    (style.overflowY === 'auto' && el.scrollHeight > el.clientHeight) ||
    style.overflowX === 'scroll' ||
    (style.overflowX === 'auto' && el.scrollWidth > el.clientWidth)
  );
}

function getScrollParents(el, stopAt) {
  const elements = [];

  if (stopAt && el && !stopAt.contains(el)) {
    return elements;
  }

  while (el) {
    if (hasScrollbar(el)) {
      elements.push(el);
    }
    if (el === stopAt) {
      break;
    }
    // eslint-disable-next-line no-param-reassign
    el = el.parentElement;
  }

  return elements;
}

/**
 * We created a custom blockScrollStrategy, the block strategy is used to prevent scrolling on containers when a menu is open.
 * The native Vuetify block strategy doesnt take the X-axis into account, so we created our own.
 * @param {Object} data
 * @param {Object} props
 */
export function blockScrollStrategy(data, props) {
  const overflowClass = '!overflow-hidden';
  const scrollbarStableClass = '[scrollbar-gutter:stable]';
  let scrollElements = [];
  let scrollableParent;

  // timeout is required to let dispose from another menu to run first
  setTimeout(() => {
    const offsetParent = data.root.value?.offsetParent;
    scrollElements = [
      ...new Set([
        ...getScrollParents(data.targetEl.value, props.contained ? offsetParent : undefined),
        ...getScrollParents(data.contentEl.value, props.contained ? offsetParent : undefined),
      ]),
    ].filter((el) => !el.classList.contains(overflowClass));
    scrollableParent = ((el) => hasScrollbar(el) && el)(offsetParent || document.documentElement);
    if (scrollableParent) {
      data.root.value.classList.add(overflowClass);
      data.root.value.classList.add(scrollbarStableClass);
    }

    scrollElements.forEach((el) => {
      el.classList.add(overflowClass);
      el.classList.add(scrollbarStableClass);
    });
  }, 0);

  onScopeDispose(() => {
    scrollElements.forEach((el) => {
      el.classList.remove(overflowClass);
      el.classList.remove(scrollbarStableClass);
    });
    if (scrollableParent) {
      data.root.value.classList.remove(overflowClass);
      data.root.value.classList.remove(scrollbarStableClass);
    }
  });
}
