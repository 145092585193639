import { DateTime } from 'luxon';
import { useListLoader } from '../base/useListLoader';
import { useRealTimeUpdates } from '../base/useRealTimeUpdates';

function responseToItems(response) {
  const { updates } = response.data;

  updates.forEach((update) => {
    // eslint-disable-next-line no-param-reassign
    update.updateTime = DateTime.fromISO(update.date);
  });
  return updates;
}

function responseToMeta(response) {
  return {
    totalCount: Number(response.headers['x-records']),
    unreadCount: Number(response.headers['x-totunread']),
  };
}

export function useProductUpdatesV1Loader({ count, pageSize = 25, params }) {
  const { state, refresh } = useListLoader({
    url: '/updates/latest.json',
    count,
    pageSize,
    params,
    responseToItems,
    responseToMeta,
  });

  useRealTimeUpdates((event) => {
    if (event.type === 'announcement') {
      if (event.action === 'updated') {
        refresh();
      }
    }
  });

  return state;
}
