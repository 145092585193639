import { useListLoader } from '../base/useListLoader';
import { useRealTimeUpdates } from '../base/useRealTimeUpdates';

function responseToItems({ data: { companies } }) {
  return companies;
}

/**
 * Loads companies from Teamwork v3 endpoints.
 * @param {Object} options
 * @param {MaybeRef<number>} options.projectId
 * @param {MaybeRef<Object>} options.params
 * @param {MaybeRef<number>} options.count
 * @param {MaybeRef<number>} options.pageSize
 */
export function useCompaniesV3Loader({ projectId: _projectId, params, count, pageSize }) {
  const projectId = shallowRef(_projectId);
  const url = computed(() => {
    if (projectId.value) {
      return `/projects/api/v3/projects/${projectId.value}/companies.json`;
    }
    return '/projects/api/v3/companies.json';
  });

  const { state, refresh } = useListLoader({
    url,
    params,
    count,
    pageSize,
    responseToItems,
  });

  useRealTimeUpdates((event) => {
    if (event.type === 'company') {
      refresh();
    }
  });
  return state;
}
