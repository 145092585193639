import { useI18n } from '@/util';
import { useAxios } from '../base/useAxios';
import { useOptimisticUpdates } from '../base/useOptimisticUpdates';
import { useRealTimeUpdates } from '../base/useRealTimeUpdates';
import { useCurrentUser } from './useCurrentUser';

export function useCurrentUserActions() {
  const api = useAxios();
  const { t } = useI18n();
  const user = useCurrentUser();
  const { emit: emitOptimisticUpdate } = useOptimisticUpdates();
  const { emit: emitRealTimeUpdate, socketId } = useRealTimeUpdates();

  async function logout() {
    if (import.meta.env.DEV && import.meta.env.TW_APP_PROXY_TARGET) {
      // eslint-disable-next-line no-console
      console.warn('Cannot log out in dev proxy mode');
    } else {
      await api.put('/v/1/logout.json');
      window.location = '/launchpad/logout';
    }
  }

  function toggleClockIn() {
    const isClockedIn = !user.value.isClockedIn;

    const url = isClockedIn ? '/me/clockin.json' : '/me/clockout.json';

    const promise = api
      .post(
        url,
        { info: '' },
        {
          headers: { 'Socket-ID': socketId },
          errorMessage: isClockedIn ? t('Failed to clock in') : t('Failed to clock out'),
        },
      )
      .then(() => {
        emitRealTimeUpdate({
          type: 'person',
          action: 'update',
          person: { id: user.value.id, isClockedIn },
          userId: user.value.id,
        });
      });

    emitOptimisticUpdate({
      promise,
      type: 'person',
      action: 'update',
      person: {
        id: user.value.id,
        isClockedIn,
      },
    });

    return promise;
  }

  function reloadApp() {
    setTimeout(() => {
      window.location.reload();
    }, 300);
  }

  async function impersonate(userId) {
    if (!userId) {
      return;
    }
    await api.put(`/people/${userId}/impersonate.json`, undefined, {
      headers: { 'Socket-ID': socketId },
      errorMessage: t('Failed to impersonate user'),
    });
    reloadApp();
  }

  async function revertImpersonate() {
    if (!user.value.impersonating) {
      window.location.reload();
      return;
    }
    const { data } = await api.put('/people/impersonate/revert.json', undefined, {
      headers: { 'Socket-ID': socketId },
      errorMessage: t('Failed to revert impersonation'),
    });
    const { previousProjectId, impersonatedUserId } = data;

    if (Number(previousProjectId)) {
      window.location.href = `/app/projects/${previousProjectId}/people`;
      reloadApp();
      return;
    }
    if (Number(impersonatedUserId)) {
      window.location.href = `/app/people/${impersonatedUserId}`;
      reloadApp();
      return;
    }

    window.location.href = '/app/people';
    reloadApp();
  }

  return {
    logout,
    toggleClockIn,
    impersonate,
    revertImpersonate,
  };
}
