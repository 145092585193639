import { useListLoader } from '../base/useListLoader';
import { useOptimisticUpdates } from '../base/useOptimisticUpdates';
import { useRealTimeUpdates } from '../base/useRealTimeUpdates';

function orderByNameAsc(customfield1, customfield2) {
  return customfield1.name.localeCompare(customfield2.name);
}

function orderByNameDesc(customfield1, customfield2) {
  return customfield2.name.localeCompare(customfield1.name);
}

function responseToItems(response) {
  return response.data.customfields;
}

function responseToMeta({ data: { meta } }) {
  return { totalCount: meta.page?.count };
}

/**
 * Loads a list of custom fields from the Teamwork v3 API.
 */
export function useCustomfieldsV3Loader({ params, count, pageSize = 50 }) {
  const order = computed(() => {
    const { orderBy, orderMode } = params.value || {};
    const asc = orderMode ? orderMode === 'asc' : true;

    if (!orderBy || orderBy === 'name') {
      return asc ? orderByNameAsc : orderByNameDesc;
    }

    return undefined;
  });

  const { state, refresh, update } = useListLoader({
    url: `/projects/api/v3/customfields.json`,
    params,
    count,
    responseToItems,
    responseToMeta,
    order,
    pageSize,
  });

  useRealTimeUpdates((event) => {
    if (event.type === 'customfield') {
      refresh(event.customfieldId);
    }
  });

  useOptimisticUpdates((event) => {
    if (event.type === 'customfield') {
      update((customfields) => {
        if (event.action === 'create' && params.value?.entities === event.customfield.entity) {
          return [...customfields, event.customfield];
        }
        if (event.action === 'update') {
          return customfields.map((customfield) =>
            customfield.id === event.customfield.id || customfield.id === event.customfield.tempId
              ? { ...customfield, ...event.customfield }
              : customfield,
          );
        }
        if (event.action === 'delete') {
          return customfields.filter((customfield) => customfield.id !== event.customfield.id);
        }
        return customfields;
      }, event.promise);
    }
  });

  return state;
}
