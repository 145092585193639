<script setup>
import { useAppShellSidebar } from '@/appShell';
import { useSpacesIcons } from './useSpacesIcons';

defineProps({
  page: {
    type: Object,
    required: true,
  },
});

const { clearActiveDrawerPanelIfNotPinned } = useAppShellSidebar();
const { getSpaceIcon, getSpaceInitials } = useSpacesIcons();
</script>

<template>
  <VListItem
    :to="`/spaces/${page.space.code}/page/${page.id}-${page.slug}`"
    v-bind="VListItemSidebarProjectsDrawer"
    @click="clearActiveDrawerPanelIfNotPinned"
  >
    <template #prepend>
      <div class="bg-primary relative mr-2 flex size-8 shrink-0 items-center justify-center rounded-full">
        <VIcon color="#fff" :size="16">lsi-guide</VIcon>

        <!-- Space icon-->
        <div class="top-50 absolute bottom-0 right-0 -mb-1 -mr-1">
          <div
            class="flex size-4 shrink-0 items-center justify-center rounded-full bg-[--color]"
            :style="{ '--color': page.space.spaceColor }"
          >
            <LscIcon
              v-if="page.space?.icon.length"
              class="text-icon-on-primary"
              :size="8"
              :icon="getSpaceIcon(page.space.icon)"
            />
            <span v-else class="text-body-3 text-on-primary">{{ getSpaceInitials(page.space.title) }}</span>
          </div>
        </div>
      </div>
    </template>
    <VListItemTitle class="text-body-1">
      {{ page.title }}
    </VListItemTitle>

    <VListItemSubtitle class="text-body-2">
      {{ page.space.title }}
    </VListItemSubtitle>
  </VListItem>
</template>
