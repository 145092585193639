<script setup>
import { useI18n } from '@/util';
import FormDrawerEmptyFormsProjects from './FormDrawerEmptyFormsProjects.svg';

const { t } = useI18n();
</script>
<template>
  <LscEmptyState size="sm" class="grow" :title="t('No project forms')" :message="t('You have no project forms yet')">
    <template #image>
      <FormDrawerEmptyFormsProjects />
    </template>
  </LscEmptyState>
</template>
