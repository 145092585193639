import {
  ArcElement,
  BarController,
  BarElement,
  CategoryScale,
  Chart,
  Decimation,
  Filler,
  Legend,
  LinearScale,
  LineController,
  LineElement,
  PointElement,
  SubTitle,
  TimeScale,
  Title,
  Tooltip,
} from 'chart.js';
import Annotations from 'chartjs-plugin-annotation';

export function chartjsPlugin() {
  Chart.register(
    Title,
    Tooltip,
    Legend,
    ArcElement,
    Annotations,
    BarController,
    BarElement,
    CategoryScale,
    Decimation,
    LinearScale,
    LineController,
    LineElement,
    PointElement,
    SubTitle,
    TimeScale,
    Filler,
  );
}
