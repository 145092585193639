import { useDeskCurrentUserLoader } from './useDeskCurrentUserLoader';

const symbol = Symbol('useDeskCurrentUser');

export function provideDeskCurrentUserState() {
  // Do not load the Desk current user until it's requested for the first time.
  const active = shallowRef(false);
  const state = useDeskCurrentUserLoader({ active });
  provide(symbol, { state, active });
}

export function useDeskCurrentUserState() {
  const { active, state } = inject(symbol);
  active.value = true;
  return state;
}

export function useDeskCurrentUser() {
  return useDeskCurrentUserState().item;
}
