export const embedRoutes = [
  {
    path: '/hubspot',
    component: () => import('./RouteEmbedHubspot.vue'),
    children: [
      {
        path: 'projects',
        children: [
          {
            path: '',
            component: () => import('./RouteEmbedHubspotProjectCard.vue'),
          },
          {
            path: 'new',
            component: () => import('./RouteEmbedHubspotProjectCreate.vue'),
          },
          {
            path: 'existing',
            component: () => import('./RouteEmbedHubspotProjectExisting.vue'),
          },
        ],
      },
      {
        path: 'tasks',
        children: [
          {
            path: '',
            component: () => import('./RouteEmbedHubspotTaskCard.vue'),
          },
          {
            path: 'new',
            component: () => import('./RouteEmbedHubspotTaskCreate.vue'),
          },
          {
            path: 'existing',
            component: () => import('./RouteEmbedHubspotTaskExisting.vue'),
          },
        ],
      },
      {
        path: 'timelogs',
        children: [
          {
            path: 'new',
            component: () => import('./RouteEmbedHubspotTimelogAdd.vue'),
          },
        ],
      },
    ],
  },
];
