import { useI18n } from '@/util';
import { useAxios } from '../base/useAxios';
import { useOptimisticUpdates } from '../base/useOptimisticUpdates';
import { useRealTimeUpdates } from '../base/useRealTimeUpdates';

function toApiPayload(formState) {
  const payload = {};

  if (Object.hasOwn(formState, 'capacity')) {
    payload.capacity = formState.capacity;
  }
  if (Object.hasOwn(formState, 'budgetCategory')) {
    payload.budgetCategory =
      formState.budgetCategory === 'retainer' ? 'STANDARD' : formState.budgetCategory.toUpperCase();
  }
  if (Object.hasOwn(formState, 'project')) {
    payload.projectId = formState.project.id;
  }
  if (Object.hasOwn(formState, 'startDateTime')) {
    payload.startDateTime = formState.startDateTime.toISODate();
  }
  if (Object.hasOwn(formState, 'type')) {
    payload.type = formState.type.toUpperCase();
  }
  if (Object.hasOwn(formState, 'timelogType')) {
    payload.timelogType = formState.timelogType.toUpperCase();
  }
  if (Object.hasOwn(formState, 'isRepeating')) {
    payload.isRepeating = formState.isRepeating;
  }
  if (Object.hasOwn(formState, 'isRetainer')) {
    payload.isRetainer = formState.isRetainer;
  }
  if (Object.hasOwn(formState, 'endDateTime') && !formState?.isRepeating) {
    payload.endDateTime = formState.endDateTime?.plus({ days: 1 }).toISODate();
  }
  if (Object.hasOwn(formState, 'completedAt') && formState?.isRepeating) {
    payload.completedAt = formState.completedAt?.plus({ days: 1 }).toISODate();
  }
  if (Object.hasOwn(formState, 'budgetProfitMargin') && formState?.budgetProfitMargin) {
    payload.budgetProfitMargin = formState.budgetProfitMargin;
  }
  if (Object.hasOwn(formState, 'shouldUpdateProjectRate') && formState?.projectRate) {
    payload.projectRate = formState.projectRate;
  }
  if (Object.hasOwn(formState, 'repeatPeriod') && formState?.isRepeating) {
    payload.repeatPeriod = formState.repeatPeriod;
  }
  if (Object.hasOwn(formState, 'repeatUnit') && formState?.isRepeating) {
    payload.repeatUnit = formState.repeatUnit;
  }
  if (Object.hasOwn(formState, 'id') && formState?.id) {
    payload.id = formState.id;
  }
  if (Object.hasOwn(formState, 'carryUnderspend') && formState?.isRetainer) {
    payload.carryUnderspend = formState.carryUnderspend;
  }
  if (Object.hasOwn(formState, 'carryOverspend') && formState?.isRetainer) {
    payload.carryOverspend = formState.carryOverspend;
  }
  if (Object.hasOwn(formState, 'notifications')) {
    payload.notifications =
      formState.notifications.map?.((notification) => {
        return {
          notificationMedium: notification.notificationMedium.toUpperCase(),
          capacityThreshold: notification.capacityThreshold,
          userIds: notification.userIds ?? notification.users?.map((user) => user.id) ?? [],
          teamIds: notification.teamIds ?? notification.teams?.map((team) => team.id) ?? [],
          companyIds: notification.companyIds ?? notification.companies?.map((company) => company.id) ?? [],
          id: notification.id,
        };
      }) ?? [];
  }

  return payload;
}

export function useProjectBudgetActions() {
  const { t } = useI18n();
  const api = useAxios();
  const { emit: emitOptimisticUpdate } = useOptimisticUpdates();
  const { emit: emitRealTimeUpdate, socketId } = useRealTimeUpdates();

  return {
    createProjectBudget(formState) {
      const budget = toApiPayload(formState);
      const promise = api
        .post(
          '/projects/api/v3/projects/budgets.json',
          { budget },
          {
            headers: {
              'Socket-ID': socketId,
            },
            errorMessage: t('Failed to create a project budget'),
          },
        )
        .then((response) => {
          const {
            data: { budget: createdBudget },
          } = response;
          emitRealTimeUpdate({
            type: 'budget',
            action: 'new',
            budgetId: createdBudget.id,
            projectId: createdBudget.projectId,
          });
          return response;
        });

      emitOptimisticUpdate({
        promise,
        type: 'budget',
        action: 'create',
        budget: {
          ...budget,
          id: -1,
          capacityUsed: 0,
        },
      });

      return promise;
    },
    updateProjectBudget(formState, isEnding = false) {
      const budget = toApiPayload(formState, isEnding);
      const promise = api
        .patch(
          `/projects/api/v3/projects/budgets/${budget.id}.json`,
          { budget },
          {
            headers: {
              'Socket-ID': socketId,
            },
            errorMessage: t('Failed to update a project budget'),
          },
        )
        .then((response) => {
          const {
            data: { budget: updatedBudget },
          } = response;
          emitRealTimeUpdate({
            type: 'budget',
            action: 'updated',
            budgetId: updatedBudget.id,
            projectId: updatedBudget.projectId,
          });
          return response;
        });

      emitOptimisticUpdate({
        promise,
        type: 'budget',
        action: 'update',
        budget,
      });

      return promise;
    },
    deleteProjectBudget(budget) {
      const promise = api
        .delete(`/projects/api/v3/projects/budgets/${budget.id}.json`, {
          headers: {
            'Socket-ID': socketId,
          },
          errorMessage: t('Failed to delete project budget'),
        })
        .then((response) => {
          emitRealTimeUpdate({
            type: 'budget',
            action: 'deleted',
            budgetId: budget.id,
            projectId: budget.projectId,
          });
          return response;
        });

      emitOptimisticUpdate(promise, 'delete', budget);

      return promise;
    },
  };
}
