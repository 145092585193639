<script setup>
import { useI18n } from '@/util';
import LswAssigneePickerItem from './LswAssigneePickerItem.vue';
import { useAssigneePicker } from './useAssigneePicker';

const { clearAssignees, modelValue, isAnyoneActive } = useAssigneePicker();
const { t } = useI18n();

function assignToAnyone() {
  clearAssignees();
  modelValue.value = false;
}
</script>
<template>
  <LswAssigneePickerItem
    :assignTooltip="t('Assign to anyone')"
    :unassignTooltip="t('Clear')"
    :active="isAnyoneActive"
    :shouldShowToggleButton="false"
    @toggle="assignToAnyone"
  >
    {{ t('Anyone') }}
  </LswAssigneePickerItem>
</template>
