<script setup lang="ts">
import { type LscDateStepperSize, LscDateStepperSizes } from './LscDateStepper.types.js';
import { provideLscDateStepper } from './useLscDateStepper';

const props = defineProps({
  /** The size of the date stepper. */
  size: {
    type: String as PropType<LscDateStepperSize>,
    default: 'lg',
    validator: (value: LscDateStepperSize) => LscDateStepperSizes.includes(value),
  },
});

provideLscDateStepper({
  size: computed(() => props.size),
});
</script>

<template>
  <div class="flex items-center gap-2">
    <slot name="prepend" />
    <slot name="nav" />
    <slot name="default" />
    <slot name="append" />
  </div>
</template>
