<script setup>
import { useCurrentUser } from '@/api';
import { useCurrentProject } from '@/route';
import { ProjectIcon } from '@/module/project';
import { useAppShellSidebar } from '../useAppShellSidebar';

defineProps({
  project: {
    type: Object,
    required: true,
  },
  projectListType: {
    type: String,
    default: undefined,
  },
});

const { clearActiveDrawerPanelIfNotPinned } = useAppShellSidebar();
const currentProject = useCurrentProject();
const currentUser = useCurrentUser();
</script>

<template>
  <RouterLink
    :to="`/projects/${project.id}`"
    :class="[
      'flex h-14 items-center gap-3 px-6 py-2 transition-colors',
      'text-[--lsds-c-sidebar-drawer-list-item-color-on-surface]',
      'hover:bg-[--lsds-c-sidebar-drawer-list-item-background-color-on-selected]',
      'focus-visible:bg-[--lsds-c-sidebar-drawer-list-item-background-color-on-selected]',
      'aria-[current=page]:bg-[--lsds-c-sidebar-drawer-list-item-background-color-on-selected]',
    ]"
    :aria-current="currentProject?.id === project.id ? 'page' : undefined"
    tabindex="0"
    :data-identifier="`side-nav-${projectListType}-project`"
    @click="clearActiveDrawerPanelIfNotPinned"
  >
    <ProjectIcon :project="project" class="shrink-0" />
    <div class="flex flex-1 flex-col items-stretch overflow-hidden">
      <LscOverflowEllipsis is="h4" location="right">
        {{ project.name }}
      </LscOverflowEllipsis>
      <div class="flex items-center gap-1 text-body-2 text-[color:--lsds-c-sidebar-drawer-icon-color-on-surface]">
        <template v-if="project.company?.name && currentUser.companyId !== project.companyId">
          <LscIcon icon="lsi-sidenav-clients" class="shrink-0" size="sm" />
          <LscOverflowEllipsis location="right">
            {{ project.company.name }}
          </LscOverflowEllipsis>
        </template>
        <LscOverflowEllipsis v-else-if="project?.category">
          {{ project?.category?.name }}
        </LscOverflowEllipsis>
      </div>
    </div>
  </RouterLink>
</template>
