import { DateTime } from 'luxon';
import { useListLoader } from '../base/useListLoader';
import { useRealTimeUpdates } from '../base/useRealTimeUpdates';

function responseToItems(response) {
  const { projects = {} } = response.data.included;
  const { tags } = response.data;

  /* eslint-disable no-param-reassign */
  tags.forEach((tag) => {
    if (projects[tag.projectId]) {
      tag.project = projects[tag.projectId];
      tag.project.createdAt = tag.project.createdAt ? DateTime.fromISO(tag.project.createdAt) : null;
      tag.project.updatedAt = tag.project.updatedAt ? DateTime.fromISO(tag.project.updatedAt) : null;
    }
  });
  /* eslint-enable no-param-reassign */

  return tags;
}

/**
 * Loads a list of all project tags.
 *
 */
export function useTagsV3Loader({ params, pageSize = 40, count = 40 }) {
  const { state, refresh } = useListLoader({
    url: '/projects/api/v3/tags.json',
    params,
    responseToItems,
    pageSize,
    count,
    type: 'tag',
  });

  useRealTimeUpdates((event) => {
    if (event.type === 'tag') {
      refresh(event.tagId);
    }
  });

  return state;
}
