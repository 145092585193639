import { useI18n } from '@/util';
import { useAxios } from '../base/useAxios';
import { useOptimisticUpdates } from '../base/useOptimisticUpdates';
import { useRealTimeUpdates } from '../base/useRealTimeUpdates';

export function useUserRateActions() {
  const api = useAxios();
  const { socketId, emit: emitRealTimeUpdate } = useRealTimeUpdates();
  const { emit: emitOptimisticUpdate } = useOptimisticUpdates();
  const { t } = useI18n();

  return {
    updateUserRate({ projectId, userId, userRate, fromDate, toDate }) {
      const promise = api
        .put(
          projectId > 0
            ? `/projects/api/v3/rates/projects/${projectId}/users/${userId}.json`
            : `/projects/api/v3/rates/installation/users/${userId}.json`,
          {
            fromDate,
            userRate,
            toDate,
          },
          {
            headers: {
              'Socket-ID': socketId,
            },
            errorMessage() {
              if (projectId > 0) {
                return t('We’re unable to update project rates right now, please try again later.');
              }
              return t('We’re unable to update installation rates right now, please try again later.');
            },
          },
        )
        .then(() => {
          emitRealTimeUpdate({
            type: 'userRate',
            action: 'edited',
            projectId,
          });
        });

      emitOptimisticUpdate({
        promise,
        type: 'userRate',
        action: 'update',
        userIds: [userId],
        userRate,
      });
      return promise;
    },
    updateUserCost({ projectId, userId, userCost, fromDate }) {
      const promise = api
        .put(
          projectId > 0
            ? `/projects/api/v3/costs/projects/${projectId}/users/${userId}.json`
            : `/projects/api/v3/costs/installation/users/${userId}.json`,
          {
            userCost,
            fromDate,
          },
          {
            headers: {
              'Socket-ID': socketId,
            },
            errorMessage: t('We’re unable to update user cost right now, please try again later.'),
          },
        )
        .then(() => {
          emitRealTimeUpdate({
            type: 'userRate',
            action: 'edited',
            projectId,
          });
        });

      emitOptimisticUpdate({
        promise,
        type: 'userCost',
        action: 'update',
        userIds: [userId],
        userCost,
      });
      return promise;
    },
    updateMultipleUserRates(projectId, userRates, userRate) {
      const promise = api
        .put(
          `/projects/api/v3/rates/projects/${projectId}/actions/update.json`,
          { userRates },
          {
            headers: {
              'Socket-ID': socketId,
            },
            errorMessage: t('We’re unable to update project rates right now, please try again later.'),
          },
        )
        .then(() => {
          emitRealTimeUpdate({
            type: 'userRate',
            action: 'edited',
            projectId,
          });
        });
      const ids = userRates.map((rate) => rate.user.id);
      emitOptimisticUpdate({
        promise,
        type: 'userRate',
        action: 'update',
        userIds: ids,
        userRate,
      });
      return promise;
    },
    bulkUpdateUserRates({ userIds, userRate }) {
      const payload = {
        userRate,
        all: false,
        ids: userIds,
      };
      return api
        .put('/projects/api/v3/rates/installation/users/bulk/update.json', payload, {
          headers: {
            'Socket-ID': socketId,
          },
          errorMessage: t('We’re unable to update user rates right now, please try again later.'),
        })
        .then(() => {
          emitRealTimeUpdate({
            type: 'userRate',
            action: 'edited',
          });
        });
    },
  };
}
