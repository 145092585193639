<script setup>
import { useI18n } from '@/util';

const { t } = useI18n();
</script>

<template>
  <div class="text-body-1">
    <p>
      {{
        t(
          'Helpful hint: Think about what your day-to-day work looks like. You can turn columns on or off to fit your working habits. Look at the column options and choose the ones that help you work more effectively.',
        )
      }}
    </p>
  </div>
</template>
