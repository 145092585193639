<script setup>
import { useWorkflowV3Loader } from '@/api';

const props = defineProps({
  automationId: {
    type: String,
    default: null,
  },
  projectId: {
    type: Number,
    default: null,
  },
  workflowId: {
    type: Number,
    default: null,
  },
  workflowStageId: {
    type: Number,
    default: null,
  },
});

const template = shallowRef(null);

if (props.workflowId && !props.automationId) {
  const { item: workflow, loaded: workflowLoaded } = useWorkflowV3Loader({
    workflowId: props.workflowId,
    count: 1,
  });

  const unwatch = watch(workflowLoaded, (loaded) => {
    if (loaded) {
      if (workflow.value?.projectSpecific) {
        template.value = {
          trigger: {
            scope: 'projects',
            entity: 'task',
            name: 'workflow_stage_changed',
            conditions: [
              {
                name: 'projects.task.workflow_stage_changed',
                params: {
                  projectId: props.projectId,
                  workflowId: props.workflowId,
                  workflowStageId: props.workflowStageId ?? null,
                },
              },
            ],
          },
          actions: [],
        };
      } else {
        template.value = {
          trigger: {
            scope: 'projects',
            entity: 'task',
            name: 'workflow_stage_changed',
            conditions: [
              {
                name: 'projects.task.workflow_stage_changed',
                params: {
                  projectId: null,
                  workflowId: props.workflowId,
                  workflowStageId: props.workflowStageId ?? null,
                },
              },
            ],
          },
          actions: [],
        };
      }
      unwatch();
    }
  });
}

const AutomationModalDialogPanel = defineAsyncComponent(() => import('./AutomationModalDialogPanel.vue'));
</script>

<template>
  <LscDialog fullscreen :retainFocus="false">
    <template #activator="activator"><slot name="activator" v-bind="activator" /></template>
    <template #default="{ close }">
      <AutomationModalDialogPanel :template="template" v-bind="props" @close="close" />
    </template>
  </LscDialog>
</template>
