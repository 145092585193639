<script setup>
import { useBranding, useExperimentA2413c, useFeatures } from '@/api';
import { ProductUpdatesDrawer } from '@/module/productUpdates';
import { ShortcutDrawer } from '@/module/shortcut';
import { SuccessDrawer } from '@/module/success';
import { useThemeColor } from '@/module/theme';
import AppShellSidebarActionsBottom from './AppShellSidebarActionsBottom.vue';
import AppShellSidebarActionsGrowth from './AppShellSidebarActionsGrowth.vue';
import AppShellSidebarExpandButton from './AppShellSidebarExpandButton.vue';
import AppShellSidebarLogo from './AppShellSidebarLogo.vue';
import AppShellSidebarNavigation from './AppShellSidebarNavigation.vue';
import AppShellSidebarUserButton from './AppShellSidebarUserButton.vue';
import AppShellSidebarProjects from './projects/AppShellSidebarProjects.vue';
import { useAppShellSidebar } from './useAppShellSidebar';
import { useAppShellSidebarTracking } from './useAppShellSidebarTracking';
import AppShellSidebarUserMenu from './userMenu/AppShellSidebarUserMenu.vue';

defineOptions({
  inheritAttrs: false,
});

const branding = useBranding();
const { previewTheme } = useThemeColor();
const { lightspeedProjectsInSidebar } = useFeatures();
const { isMyShortcutsOpen, isSidebarExpanded, isSidebarOpenOnMobile, isProductUpdatesOpen, isSuccessPlannerOpen } =
  useAppShellSidebar();
const { trackLeftNavigationClicked } = useAppShellSidebarTracking({ isSidebarExpanded });
const { isExpA2413cVariation } = useExperimentA2413c();

const currentlyActiveThemeColor = computed(() => {
  if (previewTheme.value) {
    return `is-${previewTheme.value}`;
  }
  return '';
});

const actionsGrowthClasses = computed(() => {
  if (isExpA2413cVariation.value) {
    return 'mb-3';
  }

  return isSidebarExpanded.value ? 'mb-8' : 'mb-4';
});
</script>

<template>
  <div
    id="mainNav"
    v-bind="$attrs"
    :class="[
      currentlyActiveThemeColor,
      'fixed left-0 top-0 z-50 flex h-screen flex-col',
      'bg-[--lsds-c-sidebar-drawer-color-surface]',
      '-translate-x-full sm:translate-x-0',
      'transition-[width,transform] duration-300 will-change-[width,transform]',
      'w-[--sidebar-width]',
      {
        'translate-x-0': isSidebarOpenOnMobile,
      },
    ]"
  >
    <AppShellSidebarLogo
      v-if="branding.hasTeamworkBranding && !isSidebarOpenOnMobile"
      class="mx-3 mt-5 block flex-none"
      :collapsed="isSidebarExpanded"
    />
    <AppShellSidebarNavigation class="pb-4" />

    <AppShellSidebarProjects v-if="lightspeedProjectsInSidebar && isSidebarExpanded" class="mb-4 flex-1" />
    <div v-else class="flex-1" />

    <AppShellSidebarActionsGrowth :class="actionsGrowthClasses" />
    <AppShellSidebarActionsBottom class="mx-6 shrink-0 py-1" />
    <AppShellSidebarUserMenu location="top end" origin="bottom start">
      <template #activator="activator">
        <AppShellSidebarUserButton
          v-bind="activator.props"
          class="mx-5 mb-4 shrink-0"
          @click="!activator.isActive && trackLeftNavigationClicked('user_profile')"
        />
      </template>
    </AppShellSidebarUserMenu>

    <AppShellSidebarExpandButton
      :class="{
        'visible': isSidebarOpenOnMobile,
        'invisible sm:visible': !isSidebarOpenOnMobile,
      }"
    />
  </div>
  <Transition
    appearFromClass="opacity-0"
    appearToClass="opacity-20"
    appearActiveClass="transition-opacity duration-300"
    leaveFromClass="opacity-20"
    leaveToClass="opacity-0"
    leaveActiveClass="transition-opacity duration-300"
  >
    <div
      v-if="isSidebarOpenOnMobile"
      class="fixed inset-0 z-40 bg-surface-dark opacity-20"
      @click="isSidebarOpenOnMobile = !isSidebarOpenOnMobile"
    />
  </Transition>
  <ShortcutDrawer v-model="isMyShortcutsOpen" />
  <SuccessDrawer v-model="isSuccessPlannerOpen" />
  <ProductUpdatesDrawer v-model="isProductUpdatesOpen" />
</template>
