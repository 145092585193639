<script setup>
const props = defineProps({
  statusId: { type: Number, default: 0 },
  options: { type: Object, default: () => ({}) },
  canEdit: { type: Boolean, default: false },
  rowSize: { type: String, default: 'md' },
});

const emit = defineEmits(['updateHealth', 'trackHealthClicked']);

const health = computed(() => {
  const option = props.statusId;
  return props.options[option] || props.options[0];
});

function updateHealthOption(option) {
  if (!props.canEdit) {
    return;
  }
  emit('updateHealth', option);
}
</script>

<template>
  <div
    v-if="!canEdit"
    class="flex w-full items-center justify-center rounded-md p-3"
    :class="{
      'cursor-pointer': canEdit,
      'p-2': rowSize === 'md',
      'p-3': rowSize === 'lg',
    }"
    :style="{ background: health.background, color: health.text }"
  >
    <LscOverflowEllipsis class="font-semibold">{{ health.label }}</LscOverflowEllipsis>
  </div>
  <WidgetOptionsMenu v-else location="bottom">
    <template #activator="activator">
      <div
        v-bind="activator.props"
        class="flex w-full items-center overflow-hidden rounded-md"
        :class="{
          'cursor-pointer': canEdit,
          'p-2': rowSize === 'md',
          'p-3': rowSize === 'lg',
        }"
        :style="{ background: health.background, color: health.text }"
        @click="emit('trackHealthClicked')"
      >
        <LscOverflowEllipsis class="flex-grow text-center font-semibold">{{ health.label }}</LscOverflowEllipsis>
        <LscIcon icon="lsi-accordion-collapsed" class="ml-auto shrink-0 rotate-90" />
      </div>
    </template>

    <div v-for="item in options" :key="item.key" class="max-w-72 gap-1 truncate px-2 text-body-2">
      <div
        class="flex w-full items-center justify-center rounded-md border border-transparent px-1 py-3 hover:border-bold"
        :class="{
          'border-bold': item.key === health.key,
          'cursor-pointer': canEdit,
          'border border-default': item.key === 0,
        }"
        :style="{ background: item.background, color: item.text }"
        @click="updateHealthOption(item)"
      >
        <LscOverflowEllipsis class="font-semibold">{{ item.label }}</LscOverflowEllipsis>
      </div>
    </div>
  </WidgetOptionsMenu>
</template>
