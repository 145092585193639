<script setup>
const props = defineProps({
  variant: {
    type: String,
    default: 'default',
  },
  active: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
});

const LscInputBlockVariantStyleConfig = tv({
  base: 'group/LscInputBlock flex items-center gap-2 rounded-md',
  slots: {
    dragHandle: 'cursor-grab items-center justify-center text-icon-subtle empty:hidden',
    prepend: 'flex items-center gap-2 empty:hidden',
    append: 'flex items-center gap-2 empty:hidden',
    default: 'flex flex-1 items-center gap-2',
  },
  variants: {
    variant: {
      default:
        'aria-disabled:border-disabled border border-default bg-default px-3 py-1 aria-[current=true]:border-form-active',
    },
  },
});

const classes = computed(() => LscInputBlockVariantStyleConfig({ variant: props.variant }));
</script>

<template>
  <div :class="classes.base()" :aria-disabled="disabled" :aria-current="active">
    <div :class="classes.dragHandle()"><slot name="dragHandle" /></div>
    <div :class="classes.prepend()"><slot name="prepend" /></div>
    <div :class="classes.default()"><slot /></div>
    <div :class="classes.append()"><slot name="append" /></div>
  </div>
</template>
