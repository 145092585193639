/**
 * Creates a function which implements the exponential back-off strategy with fixed configuration.
 * @param {object} options
 * @param {number} options.minDelay The min delay.
 * @param {number} options.maxDelay The max delay.
 * @param {number} options.delayFactor How much the delay should grow for each subsequentry retry attempt.
 * @returns {(retryAttempt:number) => number} A function which implements the exponential back-off strategy.
 */
export function exponentialBackOff({ minDelay = 1000, maxDelay = 5 * 60 * 1000, delayFactor = 2 } = {}) {
  return (retryAttempt) => Math.max(minDelay, Math.min(maxDelay, Math.floor(minDelay * delayFactor ** retryAttempt)));
}
