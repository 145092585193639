export const proofsRoutes = [
  {
    path: '/proofs',
    component: () => import('./RouteProofs.vue'),
    children: [
      {
        path: '',
        component: () => import('./RouteProofsHome.vue'),
      },
      {
        path: ':pathMatch(.*)*',
        redirect: '/proofs',
      },
    ],
  },
];
