export default /** @type {const} */ ({
	"warning": "lsi-vuetify-warning",
	"unfold": "lsi-vuetify-unfold",
	"success": "lsi-vuetify-success",
	"subgroup": "lsi-vuetify-subgroup",
	"sortDesc": "lsi-vuetify-sort-desc",
	"sortAsc": "lsi-vuetify-sort-asc",
	"ratingHalf": "lsi-vuetify-rating-half",
	"ratingFull": "lsi-vuetify-rating-full",
	"ratingEmpty": "lsi-vuetify-rating-empty",
	"radioOn": "lsi-vuetify-radio-on",
	"radioOff": "lsi-vuetify-radio-off",
	"prev": "lsi-vuetify-prev",
	"plus": "lsi-vuetify-plus",
	"next": "lsi-vuetify-next",
	"minus": "lsi-vuetify-minus",
	"menu": "lsi-vuetify-menu",
	"loading": "lsi-vuetify-loading",
	"last": "lsi-vuetify-last",
	"info": "lsi-vuetify-info",
	"first": "lsi-vuetify-first",
	"file": "lsi-vuetify-file",
	"expand": "lsi-vuetify-expand",
	"error": "lsi-vuetify-error",
	"edit": "lsi-vuetify-edit",
	"dropdown": "lsi-vuetify-dropdown",
	"delimiter": "lsi-vuetify-delimiter",
	"delete": "lsi-vuetify-delete",
	"complete": "lsi-vuetify-complete",
	"collapse": "lsi-vuetify-collapse",
	"close": "lsi-vuetify-close",
	"clear": "lsi-vuetify-clear",
	"checkboxOn": "lsi-vuetify-checkbox-on",
	"checkboxIndeterminate": "lsi-vuetify-checkbox-indeterminate",
	"checkboxOff": "lsi-vuetify-checkbox-off",
	"cancel": "lsi-vuetify-cancel"
});