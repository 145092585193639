<script setup>
import { useI18n } from '@/util';

const { t } = useI18n();
</script>
<template>
  <div class="text-body-1">
    <p>
      {{
        t(
          'Board automations update tasks when cards are moved to a specific column. For example, you can assign a task to a manager for review or mark completed tasks as done simply my moving your card across columns.',
        )
      }}
    </p>
    <p class="pt-6">
      {{ t('Click the three dots beside your column name and select Add automation to add a board automation.') }}
    </p>
  </div>
</template>
