<script setup>
import useVuelidate from '@vuelidate/core';
import { DateTime } from 'luxon';
import { isSameDay, useI18n, useValidators } from '@/util';
import { useDatePicker } from './useDatePicker';

defineOptions({
  inheritAttrs: false,
});

const props = defineProps({
  /**
   * Start or end date field.
   * @type {PropType<'start'|'end'>}
   */
  inputMode: {
    type: String,
    required: true,
    validator: (value) => ['start', 'end'].includes(value),
  },
});

const emit = defineEmits(['blur', 'focus']);

/**
 * The date to display as a Luxon DateTime object.
 * @type {PropType<DateTime>}
 */
const modelValue = defineModel({
  type: Object,
  default: undefined,
  validator: (value) => value === undefined || DateTime.isDateTime(value),
});

const {
  clearable,
  close,
  endTextField,
  minDate,
  maxDate,
  rangeMode,
  startTextField,
  hasDateProp,
  dates,
  toggleInputFocus,
} = useDatePicker();

const { parseDate, formatDate, t } = useI18n();
const { parsableDate, betweenDates } = useValidators();

/**
 * Get the min date if it exists, or the start date if the input mode is 'end'
 */
const normalizedMinDate = computed(() => {
  if (hasDateProp.value) {
    return minDate.value;
  }
  if (props.inputMode === 'end') {
    return DateTime.max(...[dates.value[0], minDate.value].filter(Boolean));
  }
  return minDate.value;
});

/**
 * Get the max date if it exists, or the end date if the input mode is 'start'
 */
const normalizedMaxDate = computed(() => {
  if (hasDateProp.value) {
    return maxDate.value;
  }
  if (props.inputMode === 'start') {
    return DateTime.min(...[dates.value[1], maxDate.value].filter(Boolean));
  }
  return maxDate.value;
});

const rules = computed(() => ({
  formattedDate: {
    parsableDate,
  },
  parsedDate: {
    betweenDates: betweenDates(normalizedMinDate.value, normalizedMaxDate.value),
  },
}));

const formattedDate = shallowRef(modelValue.value ? formatDate(modelValue.value) : '');
const parsedDate = computed(() => {
  const parsed = parseDate(formattedDate.value);
  return parsed.isValid ? parsed : undefined;
});

const v$ = useVuelidate(rules, { formattedDate, parsedDate });

const firstError = computed(() => v$.value.parsedDate.$errors.map(({ $message }) => $message).at(0));

function setTextFieldRef(el) {
  if (props.inputMode === 'start') {
    startTextField.value = el;
  } else if (props.inputMode === 'end') {
    endTextField.value = el;
  }
}

function saveDate() {
  v$.value.$touch();
  if (v$.value.$error) {
    return;
  }
  modelValue.value = parsedDate.value;
  // Update the input with the formatted date once the model value has been updated
  formattedDate.value = modelValue.value ? formatDate(modelValue.value) : '';
  v$.value.$reset();
}

function focus(event) {
  emit('focus', event);
}

function blur(event) {
  emit('blur', event);
}

function keypressEnter() {
  if (isSameDay(parsedDate.value, modelValue.value) && !v$.value.$dirty) {
    close();
  } else {
    saveDate();
    toggleInputFocus();
  }
}

function clearDate() {
  formattedDate.value = '';
  modelValue.value = undefined;
}

watch(modelValue, () => {
  if (parsedDate.value === modelValue.value) {
    return;
  }

  formattedDate.value = formatDate(modelValue.value);
  v$.value.$reset();
});

watch(rangeMode, async () => {
  await nextTick();
  saveDate();
});
</script>

<template>
  <VTextField
    :ref="setTextFieldRef"
    v-model="formattedDate"
    v-bind="$attrs"
    :error="v$.$error"
    @focus="focus"
    @blur="blur"
    @keydown.enter.stop="keypressEnter"
  >
    <template #append-inner>
      <LscIconButton
        v-if="formattedDate && clearable"
        size="xs"
        class="-mr-1"
        icon="lsi-close"
        :ariaLabel="t('Clear date')"
        @click="clearDate"
      />
    </template>
  </VTextField>
  <div
    v-if="firstError"
    class="absolute top-full z-20 mt-2 w-auto rounded-md bg-surface-dark p-2 text-body-2 text-on-dark"
    :class="{ 'left-0': inputMode === 'start', 'right-0': inputMode === 'end' }"
  >
    {{ firstError }}
  </div>
</template>
