import { useCurrentAccount, useIntegrationsHubspotV1Loader, useIntegrationsV2Loader, usePendo } from '@/api';

/**
 * Provides functions for tracking Pendo events related to integrations.
 */
export function useIntegrationTracking() {
  const {
    trackPendoEvent,
    EVENT_CATEGORIES: { DISCOVERY, ADVANCED_DISCOVERY, STANDARD_INTERACTION },
  } = usePendo();

  const integrationsState = useIntegrationsV2Loader();
  const hubspotState = useIntegrationsHubspotV1Loader();
  const account = useCurrentAccount();

  const COMMON_METRICS = ['plan_name', 'user_role', 'page', 'on_trial', 'is_icp', 'is_paid'];
  const INTEGRATIONS_EVENT_NAME = 'INTEGRATIONS_EVENT';

  function track(data) {
    trackPendoEvent({
      eventName: INTEGRATIONS_EVENT_NAME,
      commonMetrics: COMMON_METRICS,
      metadata: {
        ...data,
      },
    });
  }

  function trackAllIntegrations() {
    const unwatch = watch(
      [integrationsState.item, integrationsState.inSync, hubspotState.item, hubspotState.inSync],
      async ([integrations, integrationInSync, hubspot, hubspotInSync]) => {
        if (integrations && integrationInSync && hubspot && hubspotInSync) {
          const {
            integrations: accountData,
            documentEditorEnabled,
            customCSSEnabled,
            chatEnabled,
            spacesEnabled,
            deskEnabled,
          } = account.value;

          const integrationsFromAccountData = Object.entries(accountData).reduce(
            (acc, [key, { enabled }]) => {
              acc[key] = enabled;
              return acc;
            },
            {
              documentEditor: documentEditorEnabled,
              customCSS: customCSSEnabled,
              chat: chatEnabled,
              spaces: spacesEnabled,
              desk: deskEnabled,
            },
          );

          const accountIntegrations = {
            ...integrationsFromAccountData,
            hubSpot: hubspot.isAuthed && hubspot.hasRequiredScopes,
          };

          const synthesisIntegrations = integrations.reduce((acc, { code, enabled }) => {
            acc[code] = enabled;
            return acc;
          }, {});

          track({
            page_type: 'integrations',
            event_action: 'integrations_state',
            event_category: DISCOVERY,
            ...accountIntegrations,
            ...synthesisIntegrations,
          });
          unwatch();
        }
      },
      { immediate: true },
    );
  }

  function trackSettingsIntegrationPageViewed() {
    track({
      page_type: 'integrations',
      event_action: 'integrations_viewed',
      event_category: DISCOVERY,
    });
  }

  function trackParagonConnectPortal(integrationName, isOpened) {
    track({
      page_type: 'integrations',
      event_action: `${integrationName}_paragon_connect_portal_${isOpened ? 'opened' : 'closed'}`,
      event_category: ADVANCED_DISCOVERY,
      integration_name: integrationName,
    });
  }

  function trackToggleSiteIntegration(integrationName, value) {
    const isParagonIntegration = ['netsuite', 'salesforce'].includes(integrationName);
    track({
      page_type: isParagonIntegration ? `${integrationName}_app_modal` : 'integrations',
      event_action: `${integrationName}_site_integration_${value ? 'enable' : 'disable'}`,
      event_category: isParagonIntegration && value ? ADVANCED_DISCOVERY : STANDARD_INTERACTION,
      integration_name: integrationName,
    });
  }

  function trackToggleProjectIntegration(integrationName, value, source) {
    track({
      page_type: source,
      event_action: `${integrationName}_project_integration_toggled`,
      event_category: ADVANCED_DISCOVERY,
      enable: value,
      integration_name: integrationName,
    });
  }

  return {
    trackToggleProjectIntegration,
    trackParagonConnectPortal,
    trackToggleSiteIntegration,
    trackSettingsIntegrationPageViewed,
    trackAllIntegrations,
  };
}
