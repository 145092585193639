const symbol = Symbol('useCurrentProjectBudget');

export function provideCurrentProjectBudgetState(currentProjectBudgetState) {
  provide(symbol, currentProjectBudgetState);
  return currentProjectBudgetState;
}

export function useCurrentProjectBudgetState() {
  return inject(symbol);
}

export function useCurrentProjectBudget() {
  return useCurrentProjectBudgetState().item;
}
