import * as LDClient from 'launchdarkly-js-client-sdk';
import { useCurrentAccountState } from '../account/useCurrentAccount';
import { useCurrentUserState } from '../user/useCurrentUser';
import { isBypassExperimentsQueryEnabled, isLogTrackEventsQueryEnabled } from './util';

const TRACK_MAX_RETRIES = 10;
const TRACK_RETRY_TIME_IN_MILLIS = 500;

/**
 * Logs LaunchDarkly Track Event name and metadata to the console.
 *
 * @param {string} eventName Name of the LaunchDarkly event
 * @param {Object} metadata Object of event data and metric values
 */
function logTrackEvent(eventName, metadata) {
  if (metadata && Object.entries(metadata).length > 0) {
    // eslint-disable-next-line no-console
    console.info(`=== LaunchDarkly: ${eventName}`, metadata);
  } else {
    // eslint-disable-next-line no-console
    console.info(`=== LaunchDarkly: ${eventName}`);
  }
}

function createClient(account, user) {
  // We have 2 projects in LaunchDarkly:
  // - default:
  //   - https://app.launchdarkly.com/default/production/features
  //   - Used for authenticated sessions - all except for public routes.
  //   - Secure Mode enabled.
  //   - `options.hash` from `user.ldHash`.
  //   - LaunchDarkly Environment Key from `account.environment.launchDarklyClientId`.
  // - anonymous-users, for anonymous sessions
  //   - https://app.launchdarkly.com/anonymous-users/production/features
  //   - Used for anonymous sessions - only public routes.
  //   - Secure Mode disabled.
  //   - `options.hash` not required.
  //   - LaunchDarkly Environment Key hardcoded below.
  //
  // Anonymous contexts: https://docs.launchdarkly.com/home/observability/anonymous-contexts
  // Secure Mode: https://docs.launchdarkly.com/sdk/features/secure-mode
  // `options.hash`: https://launchdarkly.github.io/js-client-sdk/interfaces/LDOptions.html#hash

  const options = {
    hash: user.ldHash,
    bootstrap: 'localStorage',
    evaluationReasons: true,
    sendEventsOnlyForVariation: true,
  };

  if (!user.ldHash) {
    return LDClient.initialize(
      import.meta.env.DEV ||
        window.location.hostname.endsWith('dev.stg.teamworkops.com') ||
        /\.staging(?:\.\w+)?\.teamwork\.com$/.test(window.location.hostname)
        ? '62616ae9d8d6461604b91afb' // https://app.launchdarkly.com/anonymous-users/test/features
        : '62616ae9d8d6461604b91afc', // https://app.launchdarkly.com/anonymous-users/production/features
      {
        anonymous: true,
        custom: {
          installationId: account.id,
        },
      },
      options,
    );
  }

  return LDClient.initialize(
    account.environment.launchDarklyClientId,
    {
      key: `${account.id}-${user.id}`,
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.emailAddress,
      custom: {
        installationId: account.id,
        installationIsTestAccount: account.installationIsTestAccount,
        industryCategoryId: account.industryCategoryId,
        isICP: account.isICP,
        region: account.awsRegion,
        shardNo: account.shardNo,
        impersonating: !!user.twAdminUserId,
        projectsPlanId: account.pricePlanId,
        projectsPricePlanCode: account.pricePlanCode,
        projectsPricePlanType: account.pricePlanType,
        projectsStartDate: account.dateSignedUp?.toUTC().toISO(),
        projectsIsPaid: account.isPaid,
        projectsPaymentStatus: account.paymentStatus,
        projectsTrialDaysRemaining: Number(account.trialDaysRemaining) || undefined,
        projectsPaymentMethod: account.paymentMethod,
        projectsInOwnerCompany: user.inOwnerCompany,
        projectsSiteOwner: user.siteOwner,
        projectAdministrator: user.administrator,
        projectsIsClientUser: user.isClientUser,
        projectsIsCollaborator: user.isCollaborator,
        projectsCompanyRoleId: user.companyRoleId,
        projectsFirstLogin: user.loginCount <= 1,
      },
    },
    options,
  );
}

const symbol = Symbol('useLaunchDarkly');

function LaunchDarkly() {
  const { item: account, inSync: accountInSync } = useCurrentAccountState();
  const { item: user, inSync: userInSync } = useCurrentUserState();

  const client = shallowRef();
  const ready = shallowRef(false);
  const initialized = shallowRef(false);

  /**
   * Internal method for tracking event in LaunchDarkly, with retry mechanism.
   * @param {string} eventName Event name
   * @param {*} [data] Additional information to associate with the event.
   * @param {number} [metricValue] An optional numeric value that can be used by the LaunchDarkly experimentation feature in numeric custom metrics.
   */
  function track(eventName, data, metricValue, retryCount = 0) {
    if (ready.value) {
      if (isLogTrackEventsQueryEnabled()) {
        logTrackEvent(eventName, { data, metricValue });
        return;
      }

      client.value.track(eventName, data, metricValue);
      return;
    }

    if (retryCount >= TRACK_MAX_RETRIES) {
      return;
    }

    setTimeout(() => {
      track(eventName, data, metricValue, retryCount + 1);
    }, TRACK_RETRY_TIME_IN_MILLIS);
  }

  /**
   * Method for tracking event in LaunchDarkly.
   * @param {Object} dataObject LaunchDarkly data object
   *
   * `dataObject.eventName` Event name
   *
   * `dataObject.data` Additional information to associate with the event.
   *
   * `dataObject.metricsValue` An optional numeric value that can be used by the LaunchDarkly experimentation feature in numeric custom metrics.
   *
   * `dataObject.launchDarklyFlagKey` LaunchDarkly flag key. When the key is passed, the event track action will only be sent if current user evaluation of this flag is in experiment or if it is bypassed by the `bypassExperiments` query parameter.
   *
   * `options.appLevelTargeting` Optional boolean parameter to check if user matches app level targeting. Defaults to `true` therefore if not passed, the check will be skipped.
   *
   * @param {string} dataObject.eventName
   * @param {*} [dataObject.data]
   * @param {number} [dataObject.metricsValue]
   * @param {string} [dataObject.launchDarklyFlagKey]
   * @param {MaybeRef<boolean>} [options.appLevelTargeting]
   */
  function trackLaunchDarklyEvent({ eventName, data, metricsValue, launchDarklyFlagKey, appLevelTargeting = true }) {
    if (launchDarklyFlagKey) {
      const expVariationDetail = client.value.variationDetail(launchDarklyFlagKey);
      const matchesAppLevelTargeting = unref(appLevelTargeting);

      if (
        !matchesAppLevelTargeting &&
        !expVariationDetail?.reason?.inExperiment &&
        !isBypassExperimentsQueryEnabled()
      ) {
        return;
      }
    }

    track(eventName, data, metricsValue);
  }

  watch(
    [account, user],
    () => {
      if (!client.value && accountInSync.value && account.value && userInSync.value && user.value) {
        client.value = createClient(account.value, user.value);
        client.value.on('ready', () => {
          ready.value = true;
        });
        client.value.on('initialized', () => {
          initialized.value = true;
        });
      }
    },
    { immediate: true },
  );

  onScopeDispose(() => {
    if (client.value) {
      client.value.close();
    }
  });

  return { client, ready, initialized, trackLaunchDarklyEvent };
}

/**
 * Initializes a LaunchDarkly client once useCurrentAccount and useCurrentUser are initialized.
 * It currently does not reinitialize when the current account and current user change.
 */
export function provideLaunchDarkly() {
  const launchDarkly = LaunchDarkly();
  provide(symbol, launchDarkly);
  return launchDarkly;
}

/**
 * @type LaunchDarkly
 */
export function useLaunchDarkly() {
  return inject(symbol);
}
