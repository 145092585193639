import { useVuelidate } from '@vuelidate/core';
import { getMimeType, useDrop, useValidators } from '@/util';
import type { LscDropzoneSize } from './LscDropzone.types.js';

const symbol = Symbol('useLscDropzone');

interface LscDropzoneParams {
  disabled: MaybeRef<boolean>;
  dropzoneRef: MaybeRef<HTMLElement>;
  error: MaybeRef<boolean>;
  errorMessage: MaybeRef<string | undefined>;
  extensions: MaybeRef<string[]>;
  maxFileSize: MaybeRef<number>;
  message: MaybeRef<string | undefined>;
  modelValue: ModelRef<File[]>;
  multiple: MaybeRef<boolean>;
  showMessage: MaybeRef<boolean>;
  size: MaybeRef<LscDropzoneSize>;
}

function LscDropzone(params: LscDropzoneParams) {
  const disabled = shallowRef(params.disabled);
  const dropzoneRef = shallowRef(params.dropzoneRef) as ShallowRef<HTMLElement | undefined>;
  const error = shallowRef(params.error);
  const errorMessage = shallowRef(params.errorMessage);
  const extensions = shallowRef(params.extensions);
  const maxFileSize = shallowRef(params.maxFileSize);
  const message = shallowRef(params.message);
  const modelValue = shallowRef(params.modelValue) as unknown as ShallowRef<File[]>;
  const multiple = shallowRef(params.multiple);
  const showMessage = shallowRef(params.showMessage);
  const size = shallowRef(params.size) as ShallowRef<LscDropzoneSize>;

  const computedDropzoneRef = computed(() => (disabled.value ? null : dropzoneRef.value));
  const acceptedMimeTypes = computed(
    () => Array.from(new Set(extensions.value.map(getMimeType))).filter(Boolean) ?? ([] as string[]),
  );
  const acceptedMimeTypesString = computed(() =>
    acceptedMimeTypes.value.length ? acceptedMimeTypes.value.join(',') : '*',
  );

  // Only used for validation, once validated, the files are moved to modelValue
  const files = shallowRef<{ File: File }[]>([]);
  const validators = useValidators();
  const rules = computed(() => ({
    files: {
      $each: validators.helpers.forEach({
        file: {
          mimeType: validators.mimeType(acceptedMimeTypes.value as string[]),
          maxFileSize: validators.maxFileSize(maxFileSize.value),
        },
      }),
    },
  }));

  const v$ = useVuelidate(rules, { files });

  const vuelidateErrorMessage = computed(() => {
    if (!v$.value.$error) {
      return '';
    }
    const messages: string[] = [];

    v$.value.files.$each.$response.$errors.forEach(({ file }: { file: { $message: string }[] }) => {
      file.forEach(({ $message }) => {
        messages.push($message as string);
      });
    });
    return messages[0] ?? '';
  });

  // Either a custom error or the default error state
  const computedError = computed(() => error.value || v$.value.$error);

  // Either a custom error message or the default error message
  const computedErrorMessage = computed(() => {
    if (errorMessage.value) {
      return errorMessage.value;
    }
    return vuelidateErrorMessage.value;
  });

  async function selectFiles(newFiles: File[] | null) {
    if (!newFiles?.length) {
      return;
    }
    files.value = newFiles.map((file) => ({ File: file }));
    v$.value.$touch();
    if (v$.value.$error) {
      return;
    }
    modelValue.value = multiple.value ? [...newFiles] : [newFiles[0]];
    files.value = [];
    v$.value.$reset();
  }

  const isDraggingValidFiles = useDrop({
    element: computedDropzoneRef as Ref<HTMLElement> | undefined,
    getState(event) {
      // Check if the user is dragging any files.
      if (!event.dataTransfer?.types.includes('Files')) {
        return null;
      }

      if (!multiple.value) {
        // Check if the user is dragging exactly one file.
        let hasFile = false;
        for (const item of event.dataTransfer.items) {
          if (item.kind === 'file') {
            if (hasFile) {
              return null;
            }
            hasFile = true;
          }
        }
      }

      // eslint-disable-next-line no-param-reassign
      event.dataTransfer.dropEffect = 'copy';
      return true;
    },
    drop(event) {
      selectFiles(Array.from(event.dataTransfer?.files ?? []));
    },
  });
  const isOverDropZone = computed(() => Boolean(isDraggingValidFiles.value));

  return {
    acceptedMimeTypes,
    acceptedMimeTypesString,
    disabled,
    error: computedError,
    errorMessage: computedErrorMessage,
    extensions,
    isOverDropZone,
    maxFileSize,
    message,
    multiple,
    rules,
    selectFiles,
    showMessage,
    size,
  };
}

export function provideLscDropzone(params: LscDropzoneParams) {
  const dropzone = LscDropzone(params);
  provide(symbol, dropzone);
  return dropzone;
}

export function useLscDropzone() {
  return inject(symbol) as ReturnType<typeof LscDropzone>;
}
