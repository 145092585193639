<script setup>
const ProductUpdatesDrawerPanel = defineAsyncComponent(() => import('./ProductUpdatesDrawerPanel.vue'));
</script>

<template>
  <LscDrawer scrim>
    <template #activator="activator"><slot name="activator" v-bind="activator" /></template>
    <template #default="{ close }"><ProductUpdatesDrawerPanel @close="close" /></template>
  </LscDrawer>
</template>
