<script setup>
import LscTreeviewChildren from './LscTreeviewChildren.vue';

const props = defineProps({
  /**
   * Array of open categories - by id e.g. [283, 94784]
   */
  modelValue: {
    type: Array,
    default: () => [],
  },
  /**
   * Our density based on some style changes defined by the specs. We manage two density levels: `default` and `sm`.
   */
  density: {
    type: String,
    default: 'compact',
  },
  /**
   * Array that define the content list of the breadcrumb, each item is an object with to, title keys.
   */
  items: {
    type: Array,
    required: true,
  },
});

const emit = defineEmits(['update:modelValue']);

const open = shallowRef(props.modelValue);
watch(open, (newValue) => {
  emit('update:modelValue', newValue);
});

provide('open', open);
</script>

<template>
  <VList v-model:opened="open" :density="density" class="m-0 !pl-4 !pr-2 !pt-4" v-bind="VListGroupTree">
    <LscTreeviewChildren :items="items" value="value">
      <template #appendCategoryList="slotProps">
        <slot name="appendCategoryList" :parentItem="slotProps.parentItem" />
      </template>
    </LscTreeviewChildren>
  </VList>
</template>
