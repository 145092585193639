<script setup>
import { useI18n } from '@/util';
import PreviewAvatar from './OnboardingWizardCommonPreviewAvatar.vue';

const { t, formatCurrency } = useI18n();

const data = [
  {
    id: 1,
    name: t('Monthly campaign marketing'),
    dueDate: '06/03/2024',
    rate: formatCurrency(18),
  },
  {
    id: 2,
    name: t('Website graphics'),
    dueDate: '10/04/2024',
    rate: formatCurrency(24),
  },
  {
    id: 3,
    name: t('Write blog posts'),
    dueDate: '16/04/2024',
    rate: formatCurrency(15),
  },
  {
    id: 4,
    name: t('Monthly social content'),
    dueDate: '23/05/2024',
    rate: formatCurrency(18),
  },
  {
    id: 5,
    name: t('Monthly content site'),
    dueDate: '26/05/2024',
    rate: formatCurrency(20),
  },
];
</script>
<template>
  <table class="w-full table-fixed border-collapse">
    <thead>
      <tr class="h-10 border-b text-body-2 text-subtle">
        <th class="w-7/12 text-left font-normal">
          {{ t('Name') }}
        </th>
        <th class="w-1/6 font-normal">
          {{ t('Owner') }}
        </th>
        <th class="w-3/6 font-normal">
          {{ t('Project dates') }}
        </th>
        <th class="w-1/6 font-normal">
          {{ t('Rates') }}
        </th>
      </tr>
    </thead>
    <tbody>
      <template v-for="(project, i) in data" :key="i">
        <tr class="border-b">
          <td>
            <div class="flex h-10 items-center gap-2">
              <LscOverflowEllipsis class="text-left text-body-1 text-default">
                {{ project.name }}
              </LscOverflowEllipsis>
            </div>
          </td>
          <td class="flex h-10 items-center justify-center border-x">
            <PreviewAvatar :id="project.id" class="block h-full w-full" />
          </td>
          <td class="h-10 p-0.5 text-center font-normal text-subtle">
            <div class="flex h-full items-center justify-center rounded-sm text-default">
              <LscOverflowEllipsis class="flex-1 font-normal text-subtle">{{ project.dueDate }}</LscOverflowEllipsis>
            </div>
          </td>
          <td class="h-10 p-0.5 text-center font-normal text-subtle">
            <div class="flex h-full items-center justify-center rounded-sm text-subtle">{{ project.rate }}</div>
          </td>
        </tr>
      </template>
    </tbody>
  </table>
</template>
