<script setup>
import { provideLscAccordion } from './useLscAccordion';

const props = defineProps({
  multiple: {
    type: Boolean,
    default: true,
  },
});

provideLscAccordion({
  multiple: computed(() => props.multiple),
});
</script>

<template>
  <div class="grid grid-cols-1 items-stretch">
    <slot />
  </div>
</template>
