<script setup>
import { useNotifications, useNotificationsActions } from '@/api';
import { LsaNotificationsRead } from '@/designsystem';
import { useI18n } from '@/util';
import NotificationDrawerList from './NotificationDrawerList.vue';

const { readNotifications } = useNotifications();
const { markNotificationRead } = useNotificationsActions();
const { t } = useI18n();

async function toggleRead(id) {
  await markNotificationRead({
    id,
    read: false,
  });
}

const count = shallowRef(20);

watch(
  count,
  () => {
    readNotifications.loadMore();
  },
  {
    immediate: true,
  },
);
</script>

<template>
  <NotificationDrawerList v-model:count="count" :state="readNotifications.state" @toggleRead="toggleRead">
    <template #blank>
      <LscEmptyState
        class="h-full"
        :title="t('All quiet for now')"
        :message="t('You do not have any read notifications')"
      >
        <template #image>
          <LsaNotificationsRead />
        </template>
      </LscEmptyState>
    </template>
  </NotificationDrawerList>
</template>
