import * as defaultTheme from '@teamwork/lightspeed-design-tokens';
import * as blueTheme from '@teamwork/lightspeed-design-tokens/theme/blue';
import * as brownTheme from '@teamwork/lightspeed-design-tokens/theme/brown';
import * as greenTheme from '@teamwork/lightspeed-design-tokens/theme/green';
import * as indigoTheme from '@teamwork/lightspeed-design-tokens/theme/indigo';
import * as neutralTheme from '@teamwork/lightspeed-design-tokens/theme/neutral';
import * as orangeTheme from '@teamwork/lightspeed-design-tokens/theme/orange';
import * as pinkTheme from '@teamwork/lightspeed-design-tokens/theme/pink';
import * as redTheme from '@teamwork/lightspeed-design-tokens/theme/red';
import * as tealTheme from '@teamwork/lightspeed-design-tokens/theme/teal';
import * as violetTheme from '@teamwork/lightspeed-design-tokens/theme/violet';
import * as yellowTheme from '@teamwork/lightspeed-design-tokens/theme/yellow';
import { useBranding, useBrandingActions } from '@/api';
import { themeMap } from './themeMap';

const symbol = Symbol('useThemeColor');

const themes = {
  blue: blueTheme,
  brown: brownTheme,
  default: defaultTheme,
  green: greenTheme,
  indigo: indigoTheme,
  neutral: neutralTheme,
  orange: orangeTheme,
  pink: pinkTheme,
  red: redTheme,
  teal: tealTheme,
  violet: violetTheme,
  yellow: yellowTheme,
};

function ThemeColor() {
  // create a style element to apply the theme color to the root element
  const themeStyleEl = document.createElement('style');

  document.head.appendChild(themeStyleEl);
  const branding = useBranding();
  const { updateBrandingColor } = useBrandingActions();

  const previewThemeColor = shallowRef(branding.value?.theme.color);
  const applyBrandingToBackground = shallowRef(branding.value.useBackgroundColorOnLogin);
  const isSubmitting = shallowRef(false);

  const currentTheme = computed(() =>
    themeMap.has(branding.value?.theme.color) ? themeMap.get(branding.value.theme.color) : 'default',
  );
  const previewTheme = computed(() =>
    themeMap.has(previewThemeColor.value) ? themeMap.get(previewThemeColor.value) : currentTheme.value,
  );
  const isCustomTheme = computed(() => previewTheme.value !== 'default');
  const canSubmit = computed(
    () =>
      currentTheme.value !== previewTheme.value ||
      applyBrandingToBackground.value !== branding.value.useBackgroundColorOnLogin,
  );

  async function setTheme() {
    isSubmitting.value = true;
    try {
      await updateBrandingColor({
        color: previewThemeColor.value,
        useBackgroundColorOnLogin: applyBrandingToBackground.value,
      });
    } finally {
      isSubmitting.value = false;
    }
  }

  function setThemePreview(color) {
    previewThemeColor.value = color;
  }

  function resetTheme() {
    previewThemeColor.value = branding.value?.theme.color;
  }

  function updateThemeVariables() {
    let cssVars = '';
    const themeStyles = themes[previewTheme.value];
    // iterate, format and apply each token to its :root css variable
    // only apply tokens that are prefixed with LsdsCSidebarDrawer for now
    Object.keys(themeStyles)
      .filter((token) => token.includes('LsdsCSidebarDrawer'))
      .forEach((token) => {
        const cssKey = `--${token
          .split(/(?=[A-Z])/)
          .map((tokenName) => tokenName.toLowerCase())
          .join('-')}`;

        cssVars += `${cssKey}: ${themeStyles[token]};`;
      });

    themeStyleEl.innerHTML = `:root { ${cssVars} }`;
  }

  // watch for branding changes and set the theme color accordingly
  watchEffect(updateThemeVariables);

  return {
    currentTheme,
    previewTheme,
    isCustomTheme,
    applyBrandingToBackground,
    isSubmitting,
    canSubmit,
    setThemePreview,
    setTheme,
    resetTheme,
  };
}

/** @type {typeof ThemeColor} */
export function provideThemeColor() {
  const themeColor = ThemeColor();
  provide(symbol, themeColor);
  return themeColor;
}

/** @type {typeof ThemeColor} */
export function useThemeColor() {
  return inject(symbol);
}
