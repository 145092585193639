import { useSessionStorage } from '@vueuse/core';
import { useRouter } from 'vue-router';
import { useWindowSize } from '@/util';
import { useQuickViewRoute, useRoute } from './useRoute';

export const DEFAULT_QUICK_VIEW_WIDTH = 768;

const useQuickViewSymbol = Symbol('useQuickView');

export function provideQuickView() {
  const router = useRouter();
  const route = useRoute();
  const quickViewRoute = useQuickViewRoute();
  const { width: windowWidth } = useWindowSize();

  const quickViewItem = shallowRef();
  const quickViewBounds = {
    left: shallowRef(0),
    right: shallowRef(0),
  };
  const quickViewTransition = shallowRef('none');
  const quickViewWidth = useSessionStorage('teamwork/useQuickView/quick-view-width', DEFAULT_QUICK_VIEW_WIDTH);
  const lastQuickViewWidth = useSessionStorage('teamwork/useQuickView/last-quick-view-width');
  const isQuickViewLocked = shallowRef(false);
  const isQuickViewResizing = shallowRef(false);
  const isQuickViewExpanded = computed(
    () =>
      (Boolean(quickViewRoute.path) && isQuickViewLocked.value) ||
      (Boolean(quickViewRoute.path) &&
        Boolean(quickViewBounds.right.value && quickViewBounds.left.value) &&
        quickViewWidth.value >= quickViewBounds.right.value - quickViewBounds.left.value),
  );
  const isQuickViewOpen = computed(() => Boolean(quickViewRoute.path) || isQuickViewExpanded.value);
  const fullScreenWidth = computed(() =>
    quickViewBounds.right.value - quickViewBounds.left.value
      ? quickViewBounds.right.value - quickViewBounds.left.value
      : windowWidth.value,
  );

  watch(
    [isQuickViewLocked, fullScreenWidth, quickViewWidth],
    () => {
      if (isQuickViewLocked.value) {
        quickViewWidth.value = fullScreenWidth.value;
      } else if (quickViewWidth.value === fullScreenWidth.value) {
        isQuickViewLocked.value = true;
      }

      quickViewTransition.value = quickViewWidth.value >= fullScreenWidth.value ? 'none' : 'slide-x-reverse-transition';
    },
    { immediate: true },
  );

  /**
   *
   * @param {string} path The path to open in the Quick View.
   * @param {object} options
   * @param {object} options.item The initial quick view item, prior to loading from the API
   * @param {string} options.hash The hash to be set on the Quick View route
   * @param {string} options.query The query to be set on the Quick View route
   *
   */
  function openQuickView(path, options = {}) {
    if (!path) {
      // eslint-disable-next-line no-console
      console.warn(`openQuickView: requires 'path' param.`);
      return;
    }

    if (quickViewItem.value?.id !== options.item?.id) {
      quickViewItem.value = options.item;
    }

    // Reset quick view width to last width when reopens
    if (lastQuickViewWidth.value) {
      quickViewWidth.value = lastQuickViewWidth.value;
      lastQuickViewWidth.value = undefined;
      isQuickViewLocked.value = false;
    }

    const routerObject = {
      path,
      state: {
        quickViewBackgroundPath: route.fullPath,
      },
    };

    if (options.hash) {
      routerObject.hash = `#${options.hash}`;
    }

    if (options.query) {
      routerObject.query = options.query;
    }

    // go to quickview route
    router.push(routerObject);
  }

  function closeQuickView() {
    isQuickViewLocked.value = false;

    // Set the quick view background path to null to prevent the quick view from reopening
    router.push({
      ...route,
      state: null,
    });
  }

  function setQuickViewBackgroundPath(path, shouldFixQuickView = false) {
    if (shouldFixQuickView) {
      // We are forcing the quick view to be locked at full screen width when opening a quick view directly
      // e.g. User visting task details page from email link
      // Once the forced quick view is closed, it should return to the last width
      lastQuickViewWidth.value = quickViewWidth.value;
      isQuickViewLocked.value = true;
    }

    window.history.state.quickViewBackgroundPath = path;
    // Preserve query params and hash
    router.replace({ query: router.currentRoute.value.query, hash: router.currentRoute.value.hash, force: true });
  }

  function toggleQuickViewExpanded() {
    quickViewWidth.value = isQuickViewExpanded.value ? DEFAULT_QUICK_VIEW_WIDTH : fullScreenWidth.value;
    isQuickViewLocked.value = quickViewWidth.value === fullScreenWidth.value;
    lastQuickViewWidth.value = undefined;
  }

  // Clear the quickViewItem when the Quick View is closed
  watch(isQuickViewOpen, (value) => {
    if (!value) {
      quickViewItem.value = undefined;
    }
  });

  provide(useQuickViewSymbol, {
    quickViewItem,
    quickViewWidth,
    isQuickViewOpen,
    isQuickViewExpanded,
    isQuickViewLocked,
    isQuickViewResizing,
    quickViewBounds,
    quickViewTransition,
    openQuickView,
    closeQuickView,
    toggleQuickViewExpanded,
    setQuickViewBackgroundPath,
  });
}

export function useQuickView() {
  return inject(useQuickViewSymbol);
}
