import { useLocalStorage } from '@/util';

const validTabs = ['recent', 'starred', 'all', 'categories'];

function validTab(tab) {
  return validTabs.includes(tab) ? tab : validTabs[0];
}

export function useProjectJumpToDrawer() {
  const selectedTabRaw = useLocalStorage('teamwork/useProjectJumpToDrawer/lastSelectedTab', validTab());
  const selectedTab = computed({
    get() {
      return validTab(selectedTabRaw.value);
    },
    set(tab) {
      selectedTabRaw.value = validTab(tab);
    },
  });
  const lastOpenCategories = useLocalStorage('teamwork/useProjectJumpToDrawer/lastOpenCategories', []);

  return {
    selectedTab,
    lastOpenCategories,
  };
}
