<script setup>
import { useVersion } from '@/api';
import { useI18n } from '@/util';

const { t } = useI18n();
const { willUpgradeInSeconds, upgradeNow } = useVersion();
</script>

<template>
  <LscAlert
    v-if="willUpgradeInSeconds <= 60"
    variant="warning"
    layout="banner"
    :message="
      t(
        'A new version of the application is required. The application will refresh automatically in 1 second. | A new version of the application is required. The application will refresh automatically in {n} seconds.',
        { n: willUpgradeInSeconds },
      )
    "
  >
    <template #action>
      <LscAlertButton @click="upgradeNow">
        {{ t('Refresh now') }}
      </LscAlertButton>
    </template>
  </LscAlert>
</template>
