<script setup>
import { useProjectsV3Loader } from '@/api';
import ProjectJumpToDrawerProjectsList from './ProjectJumpToDrawerProjectsList.vue';
import ProjectJumpToDrawerRecentBlankSlate from './ProjectJumpToDrawerRecentBlankSlate.vue';

const step = 15;
const count = shallowRef(step);
const state = useProjectsV3Loader({
  count,
  pageSize: 15,
  params: {
    'searchTerm': null,
    'include': 'companies,projectCategories',
    'includeProjectUserInfo': true,
    'fields[projects]': 'id,name,isStarred,companyId,startPage,categoryId,logoIcon,logoColor',
    'orderBy': 'lastWorkedOn',
    'orderMode': 'desc',
    'onlyStarredProjects': false,
    'onlyProjectsWithExplicitMembership': 1,
  },
});
</script>

<template>
  <ProjectJumpToDrawerProjectsList :count="count" :step="step" :state="state" tab="recent">
    <template #blank>
      <ProjectJumpToDrawerRecentBlankSlate />
    </template>
  </ProjectJumpToDrawerProjectsList>
</template>
