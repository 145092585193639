<script setup lang="ts">
import { useLscDateStepper } from './useLscDateStepper';

defineProps({
  /** Whether to show the dropdown icon. */
  showDropDownIcon: {
    type: Boolean,
    default: false,
  },
  /** The data identifier prefix for the button. */
  dataIdentifierPrefix: {
    type: String,
    default: undefined,
  },
});

const { size } = useLscDateStepper();
</script>

<template>
  <LscButton
    variant="plain-secondary"
    :appendIcon="showDropDownIcon ? 'lsi-dropdown' : undefined"
    :size="size"
    :data-identifier="dataIdentifierPrefix ? `${dataIdentifierPrefix}-date-stepper-label` : undefined"
  >
    <slot />
  </LscButton>
</template>
