import { Comment, Fragment, Text } from 'vue';

function asArray(arg) {
  if (Array.isArray(arg)) {
    return arg;
  }
  return arg != null ? [arg] : [];
}

/**
 *
 * @param {import('vue').VNode} vnode
 * @returns {boolean}
 */
export function hasVNodeContent(vnode) {
  if (!vnode) {
    return false;
  }

  return asArray(vnode).some((node) => {
    if (node.type === Comment) {
      return false;
    }
    if (node.type === Text || node.type === Fragment) {
      if (typeof node.children === 'string') {
        return node.children.length > 0;
      }
      return hasVNodeContent(node.children);
    }

    return true;
  });
}

/**
 * Gets the text content of a vnode.
 * @param {import('vue').VNode} vnode
 * @returns {string}
 */
export function getVNodeTextContent(vnode) {
  return asArray(vnode)
    .map((node) => {
      if (node.type === Text) {
        return node.children;
      }
      if (node.type === Comment) {
        return '';
      }
      return getVNodeTextContent(node.children);
    })
    .join('')
    .trim();
}
