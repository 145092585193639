import { useListLoader } from '../base/useListLoader';

function responseToItems(response) {
  const { spaces = {} } = response.data.included || {};

  const list = response.data.spaces || [];

  list.forEach((space) => {
    if (spaces[space.id]) {
      Object.assign(space, spaces[space.id]);
    }
  });
  return list;
}

/**
 * Loads a list of Spaces from the Teamwork API.
 * @param {Object} options
 */
export function useSpacesV1Loader({ params, count, pageSize = 50 }) {
  const { state } = useListLoader({
    url: '/spaces/api/v1/spaces.json',
    params,
    count,
    responseToItems,
    pageSize,
  });

  return state;
}
