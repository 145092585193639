<script setup>
import { useI18n } from '@/util';
import { CURRENCY_OPTIONS } from '../../constants';
import BudgetGraph from './assets/finance-preview-budget-graph.svg?skipsvgo';
import RetainerNoOptionsSetGraph from './assets/finance-preview-no-options-set-graph.svg?skipsvgo';
import RetainerOverspentGraph from './assets/finance-preview-overspent-graph.svg?skipsvgo';
import RetainerUnspentGraph from './assets/finance-preview-unspent-graph.svg?skipsvgo';
import RetainerUnspentAndOverspentGraph from './assets/finance-preview-unspent-overspent-graph.svg?skipsvgo';
import PreviewAvatar from './OnboardingWizardCommonPreviewAvatar.vue';

const props = defineProps({
  isBillingTypeRetainer: {
    type: Boolean,
    default: false,
  },
  isBudgetTypeFinancial: {
    type: Boolean,
    default: false,
  },
  currencyId: {
    type: Number,
    default: CURRENCY_OPTIONS[0].id,
  },
  amount: {
    type: [Number, String],
    default: 0,
  },
  totalCost: {
    type: Number,
    default: 0,
  },
  billableTotal: {
    type: Number,
    default: 0,
  },
  profit: {
    type: Number,
    default: 0,
  },
  addUnspentHours: {
    type: Boolean,
    default: true,
  },
  subtractOverspentHours: {
    type: Boolean,
    default: true,
  },
  showProjectProfitabilityInformation: {
    type: Boolean,
    default: false,
  },
});

const DEFAULT_HOURS = 80;
const TASKLIST_BUDGETS_ROWS = [
  { progressValue: 60, progressColor: 'decorative-6' },
  { progressValue: 90, progressColor: 'decorative-8' },
  { progressValue: 10, progressColor: 'decorative-7' },
  { progressValue: 65, progressColor: 'decorative-6' },
  { progressValue: 40, progressColor: 'decorative-7' },
];

const { formatCurrency, t } = useI18n();

const currency = computed(() => ({
  code: CURRENCY_OPTIONS.find(({ id }) => id === props.currencyId)?.code || CURRENCY_OPTIONS[0],
  decimalPoints: 2,
}));

const formattedAmount = computed(() => {
  if (props.isBudgetTypeFinancial) {
    return formatCurrency(Number(props.amount || 0), { currency });
  }

  return props.amount || DEFAULT_HOURS;
});
</script>
<template>
  <div class="flex h-full gap-4">
    <div class="py-4">
      <div class="mb-2 flex items-center">
        <template v-if="isBillingTypeRetainer">
          <LscIcon icon="lsi-accordion-collapsed" class="-ml-1 rotate-180 text-icon-subtle" />
          <LscIcon icon="lsi-accordion-collapsed" class="-ml-2 text-icon-subtle" />
        </template>
        <span class="text-body-1 font-semibold text-default">
          {{ isBillingTypeRetainer ? t('May 2024') : t('Project budget') }}
        </span>
      </div>
      <div class="flex items-center gap-1">
        <LscOverflowEllipsis class="block max-w-40 text-subtitle-1 font-semibold text-default">
          {{ formattedAmount }}
        </LscOverflowEllipsis>
        <span class="text-body-2 text-subtle">{{ isBudgetTypeFinancial ? t('Total fee') : t('Hours') }}</span>
      </div>
      <BudgetGraph />
      <div class="mb-2 flex justify-between text-body-2 text-subtle">
        <div>{{ t('12% used') }}</div>
        <div>{{ t('88% remaining') }}</div>
      </div>
      <VProgressLinear :modelValue="12" color="decorative-7" height="12" rounded />
      <div class="mt-10 flex flex-col gap-6">
        <div v-for="i of [0, 1]" :key="i" class="flex justify-between">
          <LscSkeleton :animation="false" class="h-[--lsds-a-typography-body-2-regular-font-size] w-24" />
          <LscSkeleton :animation="false" class="h-[--lsds-a-typography-body-2-regular-font-size] w-24" />
        </div>
      </div>
      <div class="mt-5 flex">
        <PreviewAvatar v-for="id in [0, 1, 2]" :id="id" :key="id" class="size-4" />
      </div>
    </div>

    <div class="h-full border-y border-l" />

    <div class="flex flex-1 flex-col gap-5 py-4">
      <div v-if="isBillingTypeRetainer">
        <div class="mb-2 text-body-1 font-semibold text-default">{{ t('Retainer history') }}</div>
        <RetainerUnspentAndOverspentGraph v-if="addUnspentHours && subtractOverspentHours" />
        <RetainerUnspentGraph v-else-if="addUnspentHours && !subtractOverspentHours" />
        <RetainerOverspentGraph v-else-if="!addUnspentHours && subtractOverspentHours" />
        <RetainerNoOptionsSetGraph v-else />
      </div>
      <div>
        <div class="flex items-center gap-1 text-body-1 font-semibold text-default">
          {{ t('Project profitability') }}
          <LscIcon
            v-if="showProjectProfitabilityInformation"
            v-LsdTooltip="t('Profitability of current date range')"
            class="cursor-help text-icon-default focus:outline-none"
            size="sm"
            icon="lsi-tooltip"
          />
        </div>
        <table class="w-full table-fixed border-collapse">
          <thead class="text-left text-body-1 text-subtle">
            <tr class="h-10 border-b">
              <th class="font-normal">
                {{ t('Total cost') }}
              </th>
              <th class="font-normal">
                {{ t('Billable total') }}
              </th>
              <th class="font-normal">
                {{ t('Profit') }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr class="h-10 border-b text-left">
              <th>
                <LscOverflowEllipsis v-if="totalCost" class="mr-1 block text-body-1">
                  {{ formatCurrency(totalCost, { currency }) }}
                </LscOverflowEllipsis>
                <LscSkeleton v-else :animation="false" class="h-[--lsds-a-typography-body-1-regular-font-size] w-14" />
              </th>
              <th>
                <LscOverflowEllipsis v-if="totalCost" class="mr-1 block text-body-1">
                  {{ formatCurrency(billableTotal, { currency }) }}
                </LscOverflowEllipsis>
                <LscSkeleton v-else :animation="false" class="h-[--lsds-a-typography-body-1-regular-font-size] w-14" />
              </th>
              <th>
                <LscOverflowEllipsis v-if="totalCost" class="block text-body-1">
                  {{ formatCurrency(profit, { currency }) }}
                </LscOverflowEllipsis>
                <LscSkeleton v-else :animation="false" class="h-[--lsds-a-typography-body-1-regular-font-size] w-14" />
              </th>
            </tr>
          </tbody>
        </table>
      </div>
      <div v-if="!isBillingTypeRetainer">
        <div class="text-body-1 font-semibold text-default">{{ t('Task list budgets') }}</div>
        <table class="w-full table-fixed border-collapse">
          <thead class="text-left text-body-1 text-subtle">
            <tr class="h-10 border-b">
              <th class="font-normal">
                {{ t('Task list') }}
              </th>
              <th class="w-1/4 font-normal">
                {{ t('Budget') }}
              </th>
              <th class="font-normal">
                {{ t('Progress') }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(row, i) in TASKLIST_BUDGETS_ROWS" :key="i" class="h-10 border-b text-left">
              <th>
                <LscSkeleton
                  :animation="false"
                  :class="['h-[--lsds-a-typography-body-1-regular-font-size]', i % 2 === 0 ? 'w-16' : 'w-24']"
                />
              </th>
              <th>
                <LscSkeleton
                  :animation="false"
                  :class="['h-[--lsds-a-typography-body-1-regular-font-size]', i === 1 ? 'w-8' : 'w-10']"
                />
              </th>
              <th>
                <VProgressLinear :modelValue="row.progressValue" :color="row.progressColor" height="8" rounded />
              </th>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
