<script setup>
import { useI18n } from '@/util';

const props = defineProps({
  default: {
    type: String,
    default: '',
  },
});

const emit = defineEmits(['update']);
const { t } = useI18n();
const summaryOptionMode = shallowRef(props.default);

function updateSummaryOptionMode(mode) {
  summaryOptionMode.value = mode;
  emit('update', mode);
}
</script>

<template>
  <WidgetOptionsMenu location="top">
    <template #activator="activator">
      <LscIconButton
        v-LsdTooltip:left="t('Summary row options')"
        icon="lsi-options"
        v-bind="activator.props"
        :ariaLabel="t('Summary row options')"
        variant="primary"
      />
    </template>
    <WidgetOptionsMenuItem
      :text="t('Show total')"
      :active="summaryOptionMode === 'total'"
      @click="() => updateSummaryOptionMode('total')"
    />
    <WidgetOptionsMenuItem
      :text="t('Show average')"
      :active="summaryOptionMode === 'average'"
      @click="() => updateSummaryOptionMode('average')"
    />
  </WidgetOptionsMenu>
</template>
