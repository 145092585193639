import { DateTime } from 'luxon';
import { useItemLoader } from '../base/useItemLoader';

/**
 * Every article returns
 * @typedef {Object} KnowledgeBaseArticle a knowledge base article
 * @property {number} id
 * @property {string} title article Title
 * @property {string} description article description
 * @property {string} content article content as a html string
 * @property {string} articleSlug article slug
 * @property {string} categorySlug slug for containing category
 * @property {string} canonicalURL URL link to original article on support site
 * @property {string} status publish status of article (not available for article payload)
 * @property {string} createdAt article creation date
 * @property {DateTime} updatedAt article last updated date time
 */

const articleKeyMapping = new Map([
  ['Contents', 'content'],
  ['CategorySlug', 'categorySlug'],
  ['Description', 'description'],
  ['DisqusEnabled', 'disqusEnabled'],
  ['Slug', 'slug'],
  ['Title', 'title'],
]);

const relatedArticleKeyMapping = new Map([['slug', 'articleSlug']]);

/**
 * Sanitize the html string and do operations on it. Currently only replaces relative urls with
 * absolute urls using the baseUrl provided
 * @param {string} contentString string of valid html, will be converted to actual document fragment
 * @param {string} baseUrl base url to use for link rewrites
 * @returns {string} innerHTML string after conversion
 */
function sanitizeContent(contentString, baseUrl) {
  try {
    const template = document.createElement('template');
    template.innerHTML = contentString;

    const absoluteUrlRegex = /^https?/i;

    const nodesToFix = template.content.querySelectorAll(['[src]', '[href]']);
    nodesToFix.forEach((node) => {
      ['src', 'href'].forEach((attr) => {
        if (node.hasAttribute(attr)) {
          if (!absoluteUrlRegex.test(node.getAttribute(attr))) {
            node.setAttribute(attr, `${baseUrl}${node.getAttribute(attr)}`);
          }
        }
      });
    });

    return template.innerHTML;
  } catch {
    return contentString;
  }
}

function responseToItem({ data: { article: rawArticle, relatedArticles: rawRelatedArticles } }) {
  const article = {};
  const relatedArticles = [];

  Object.keys(rawArticle).forEach((key) => {
    const normalizedKey = articleKeyMapping.get(key) || key;
    article[normalizedKey] = rawArticle[key];
  });

  article.content = sanitizeContent(article.content, 'https://support.teamwork.com');
  article.createdAt = DateTime.fromISO(article.createdAt);
  article.updatedAt = DateTime.fromISO(article.updatedAt);

  for (const rawReleatedArticle of rawRelatedArticles) {
    const releatedArticle = {};

    Object.keys(rawReleatedArticle).forEach((key) => {
      const normalizedKey = relatedArticleKeyMapping.get(key) || key;
      releatedArticle[normalizedKey] = rawReleatedArticle[key];
    });

    relatedArticles.push(releatedArticle);
  }

  return { article, relatedArticles };
}

/**
 * Get a single article with the category and article slugs. Also contains related articles array
 * @param {string} categorySlug category slug string
 * @param {string} articleSlug article slug string
 * @returns {article: KnowledgeBaseArticle, relatedArticles: KnowledgeBaseListArticle[]} An object with article entity and relatedArticles array
 */
export function useKnowledgeBaseArticleByCategoryAndSlugLoader({ params: _params }) {
  const params = shallowRef(_params);
  const categorySlug = params?.value?.categorySlug;
  const articleSlug = params?.value?.articleSlug;

  if (!categorySlug || !articleSlug) {
    throw new Error('Slug not provided');
  }

  const { state } = useItemLoader({
    url: `https://support.teamwork.com/projects/${categorySlug}/${articleSlug}`,
    responseToItem,
  });

  return state;
}
