import { useItemLoader } from '../base/useItemLoader';

function responseToItem({ data }) {
  return data.hubspotToken;
}

export function useHubspotTokenV3Loader() {
  const { state } = useItemLoader({
    url: '/projects/api/v3/user/hubspot/token.json',
    responseToItem,
  });

  return state;
}
