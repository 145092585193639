<script setup>
import { useDebounceFn } from '@vueuse/core';
import { useExperimentA2413c, usePendo, usePermissions } from '@/api';
import { AppShellDrawerPanel, AppShellDrawerTitle, useAppShellSidebar } from '@/appShell';
import { useI18n } from '@/util';
import { useLegacyBridge } from '@/module/legacy';
import ProjectJumpToDrawerAll from './ProjectJumpToDrawerAll.vue';
import ProjectJumpToDrawerCategories from './ProjectJumpToDrawerCategories.vue';
import ProjectJumpToDrawerRecent from './ProjectJumpToDrawerRecent.vue';
import ProjectJumpToDrawerStarred from './ProjectJumpToDrawerStarred.vue';
import { useProjectJumpToDrawer } from './useProjectJumpToDrawer';
import { useProjectJumpToDrawerTracking } from './useProjectJumpToDrawerTracking';

const searchTerm = shallowRef('');

const { t } = useI18n();
const { showLegacyModal } = useLegacyBridge();
const { selectedTab } = useProjectJumpToDrawer();
const { canAddProjects, canViewProjectTemplates } = usePermissions();
const { activeDrawerPanel, clearActiveDrawerPanelIfNotPinned } = useAppShellSidebar();
const { trackPendoEvent } = usePendo();
const {
  trackJumpToDrawerFooterLinksClicked,
  trackJumpToDrawerSearchSumbmitted,
  trackJumpToDrawerTabClicked,
  trackJumpToDrawerAddProjectClicked,
} = useProjectJumpToDrawerTracking();
const { isExpA2413cVariation } = useExperimentA2413c();

function handleAddProject() {
  const source = 'add_project_jump_to_drawer_icon';

  trackPendoEvent({
    eventName: 'PROJECTS_EVENT',
    commonMetrics: ['plan_name', 'user_role', 'page', 'on_trial', 'is_icp', 'is_paid'],
    metadata: {
      page_type: 'projects',
      event_action: 'add_project_clicked',
      event_category: 'advanced_discovery_event',
      source,
    },
  });

  trackJumpToDrawerAddProjectClicked();

  showLegacyModal({
    modalName: 'addOrEditProjectWizard',
    params: { source },
  });
}

function handleFooterLinksClicked(actionType) {
  clearActiveDrawerPanelIfNotPinned();
  trackJumpToDrawerFooterLinksClicked(actionType);
}

watch(
  searchTerm,
  useDebounceFn(() => {
    if (searchTerm.value?.trim()) {
      trackJumpToDrawerSearchSumbmitted();
    }
  }, 600),
);
</script>

<template>
  <AppShellDrawerPanel v-if="activeDrawerPanel === 'jumpTo'" data-identifier="side-nav-jump-to-panel">
    <template #header>
      <AppShellDrawerTitle
        :title="isExpA2413cVariation ? t('Recent') : t('Jump to')"
        pinDataIdentifier="side-nav-jump-to-panel-pin-icon"
        closeDataIdentifier="side-nav-jump-to-panel-close-icon"
      >
        <template #prepend-icons="{ buttonSize }">
          <LscIconButton
            v-if="canAddProjects"
            v-LsdTooltip="t('Add project')"
            :size="buttonSize"
            :ariaLabel="t('Add project')"
            icon="lsi-add"
            data-identifier="side-nav-jump-to-drawer-add-project-icon"
            @click="handleAddProject"
          />
        </template>
      </AppShellDrawerTitle>
    </template>
    <template #content>
      <div class="m-0 flex h-full flex-col overflow-hidden p-0">
        <VTextField
          id="jumpToDrawerInput"
          :key="activeDrawerPanel"
          v-model.trim="searchTerm"
          v-bind="VTextFieldPanelSearch"
          class="mx-6 grow-0"
          :placeholder="t('Search')"
          autofocus
        />

        <template v-if="searchTerm">
          <h4 class="ml-6 mr-6 mt-6">{{ t('Search results') }}</h4>
        </template>

        <div v-else class="!mt-2 grow-0 px-6">
          <VTabs v-bind="VTabsUnderline" v-model="selectedTab">
            <VTab
              value="recent"
              data-identifier="projects-panel-tab-recent"
              tabindex="0"
              @click="trackJumpToDrawerTabClicked('recent')"
            >
              {{ t('Recent') }}
            </VTab>
            <VTab
              value="starred"
              data-identifier="projects-panel-tab-starred"
              tabindex="0"
              @click="trackJumpToDrawerTabClicked('starred')"
            >
              {{ t('Starred') }}
            </VTab>
            <VTab
              value="all"
              data-identifier="projects-panel-tab-all"
              tabindex="0"
              @click="trackJumpToDrawerTabClicked('all')"
            >
              {{ t('All') }}
            </VTab>
            <VTab
              value="categories"
              data-identifier="projects-panel-tab-categories"
              tabindex="0"
              @click="trackJumpToDrawerTabClicked('categories')"
            >
              {{ t('Categories') }}
            </VTab>
          </VTabs>
        </div>

        <KeepAlive>
          <ProjectJumpToDrawerAll v-if="searchTerm" :searchTerm="searchTerm" />
          <ProjectJumpToDrawerRecent v-else-if="selectedTab === 'recent'" />
          <ProjectJumpToDrawerStarred v-else-if="selectedTab === 'starred'" />
          <ProjectJumpToDrawerAll v-else-if="selectedTab === 'all'" />
          <ProjectJumpToDrawerCategories v-else-if="selectedTab === 'categories'" />
        </KeepAlive>
      </div>
    </template>
    <template #footer>
      <div class="my-7 flex flex-col gap-4">
        <RouterLink
          v-if="canViewProjectTemplates"
          to="/projects/list"
          class="flex w-full items-center gap-2 text-body-1 font-semibold text-primary no-underline"
          @click="handleFooterLinksClicked('see_all_projects_clicked')"
        >
          <LscIcon icon="lsi-project" />
          {{ t('See all projects') }}
          <LscIcon icon="lsi-open-panel" class="ml-auto" />
        </RouterLink>
        <div class="h-px w-full border-t border-separator-on-surface-default" />
        <RouterLink
          to="/projects/templates"
          class="flex w-full items-center gap-2 text-body-1 font-semibold text-subtle no-underline hover:text-default"
          @click="handleFooterLinksClicked('project_templates_clicked')"
        >
          <LscIcon icon="lsi-single-template" />
          {{ t('Project templates') }}
          <LscIcon icon="lsi-open-panel" class="ml-auto" />
        </RouterLink>
      </div>
    </template>
  </AppShellDrawerPanel>
</template>
