<script setup>
import { useCurrentAccount, useFeatures } from '@/api';
import { useI18n } from '@/util';
import {
  Filter,
  FilterCheckbox,
  FilterCompanyPicker,
  FilterJobRolePicker,
  FilterPeoplePicker,
  FilterProjectPicker,
  FilterTeamPicker,
} from '@/module/filter';
import { useReportCustomBuilderFilters } from '../useReportCustomBuilderFilters';

const { t } = useI18n();
const { peopleJobRolesPageEnabled } = useFeatures();
const { filterParams } = useReportCustomBuilderFilters();
const account = useCurrentAccount();
const clientOrCompanyText = computed(() => (account.value?.useClientView ? t('Client') : t('Company')));
</script>

<template>
  <Filter
    v-model="filterParams"
    dataIdentifierPrefix="report-builder-filter-user"
    section="reportBuilderFilter"
    class="flex flex-col"
    :allowSaveFilters="false"
  >
    <template #menuFilters>
      <FilterTeamPicker
        :title="t('Teams')"
        name="teamIds"
        :loaderParams="{
          includeCompanyTeams: true,
          includeSubteams: true,
          includeProjectTeams: true,
        }"
      />
      <FilterProjectPicker :title="t('Project')" name="projectIds" />
      <FilterCompanyPicker :title="clientOrCompanyText" name="companyIds" />
      <FilterPeoplePicker :title="t('User')" name="userIds" />
      <FilterJobRolePicker v-if="peopleJobRolesPageEnabled" :title="t('Role')" name="jobRoleIds" />
    </template>
    <template #menuOptions>
      <FilterCheckbox
        name="includeDeletedUsers"
        :label="t('Include deleted users')"
        data-identifier="report-builder-filter-user--deleted-user-checkbox"
      />
      <FilterCheckbox
        name="includeClientsCollaborators"
        :label="t('Include clients and collaborators')"
        data-identifier="report-builder-filter-user--client-collab-checkbox"
      />
      <FilterCheckbox
        name="includeArchivedProjects"
        :label="t('Include data from archived projects')"
        data-identifier="report-builder-filter-user--archived-projects-checkbox"
      />
    </template>
  </Filter>
</template>
