<script setup lang="ts">
import { useWindowSize } from '@/util';
import { useLsDraggable } from '../../../composables/draggable/useLsDraggable.js';
import { type LscDialogCardSize, LscDialogCardSizes } from './LscDialog.types.js';
import LscDialogCardHeader from './LscDialogCardHeader.vue';
import { useLsDialog } from './useLsDialog.js';

const props = defineProps({
  /**
   * The title to display in the dialog.
   */
  title: {
    type: String,
    default: '',
  },
  /**
   * An optional tooltip to display next to the title.
   */
  infoTooltip: {
    type: [String, Object],
    default: undefined,
  },
  /**
   * The prefix to use for data identifiers.
   */
  dataIdentifierPrefix: {
    type: String,
    default: undefined,
  },
  /**  The size of the dialog.*/
  size: {
    type: String as PropType<LscDialogCardSize>,
    default: 'sm',
    validator: (value: LscDialogCardSize) => LscDialogCardSizes.includes(value),
  },
  /**
   * Should the user be able to click outside
   */
  shouldCloseOnClickOutside: {
    type: Boolean,
    default: true,
  },
});

const emit = defineEmits(['close']);

defineSlots<{
  /** The main content of the dialog */
  default(): unknown;
  /** The dialog title, please use the prop unless you need more control */
  title?(): unknown;
  /** Before the title on the left */
  prependHeaderLeft?(): unknown;
  /** After the title on the left */
  appendHeaderLeft?(): unknown;
  /** Before the close button on the right */
  prependHeaderRight?(): unknown;
  /** After the close button on the right */
  appendHeaderRight?(): unknown;
  /** The main content of the dialog */
  content?(): unknown;
  /** The actions of the dialog */
  actions?(): unknown;
  /** The container of the dialog. Only use this when you need to override the default scroll container, its padding or it's margin */
  container?(): unknown;
}>();

const { width } = useWindowSize();
const { draggable, resetPosition } = useLsDraggable();
const card = shallowRef(null);
const dragHandle = shallowRef();
const { shouldCloseOnClickOutside: _shouldCloseOnClickOutside } = useLsDialog();

const LscDialogCardVariantStyleConfig = tv({
  base: 'pointer-events-auto flex min-h-0 w-full min-w-0 flex-auto transform-gpu flex-col items-stretch overflow-hidden rounded-md bg-[--lsds-c-dialog-color-background-default] shadow-dialog',
  slots: {
    container: 'flex h-full min-h-0 grow flex-col items-stretch',
    header: 'grow-0 p-6 pb-3',
    main: 'flex min-h-0 flex-1 flex-col items-stretch',
    content: 'overflow-y-auto px-6 py-3 text-body-1',
    actions: 'flex grow-0 items-center gap-2 p-6 pt-3',
  },
  variants: {
    size: {
      xs: 'max-w-[--lsds-c-dialog-size-xs]',
      sm: 'max-w-[--lsds-c-dialog-size-sm]',
      md: 'max-w-[--lsds-c-dialog-size-md]',
      lg: 'max-w-[--lsds-c-dialog-size-lg]',
      full: 'm-auto max-h-[96vh] w-[--lsds-c-dialog-size-full] max-w-[2560px]',
    },
  },
});

const classes = computed(() =>
  LscDialogCardVariantStyleConfig({
    size: props.size,
  }),
);

function close() {
  emit('close');
}

watch(
  () => props.shouldCloseOnClickOutside,
  (val) => {
    _shouldCloseOnClickOutside.value = val;
  },
  { immediate: true },
);

watch(width, () => {
  if (card.value) {
    resetPosition(card);
  }
});

watch(
  [card, dragHandle],
  () => {
    if (card.value && dragHandle.value) {
      draggable(card, { dragHandle });
    }
  },
  { immediate: true },
);
</script>

<template>
  <div ref="card" :class="classes.base()">
    <slot name="container" :dragHandle="dragHandle">
      <div :class="classes.container()">
        <LscDialogCardHeader
          ref="dragHandle"
          :infoTooltip="infoTooltip"
          :dataIdentifierPrefix="dataIdentifierPrefix"
          :class="classes.header()"
          @close="close"
        >
          <template #prependHeaderLeft><slot name="prependHeaderLeft" /></template>
          <template #appendHeaderLeft><slot name="appendHeaderLeft" /></template>
          <template #title>
            <slot name="title">{{ title }}</slot>
          </template>
          <template #prependHeaderRight><slot name="prependHeaderRight" /></template>
          <template #appendHeaderRight><slot name="appendHeaderRight" /></template>
        </LscDialogCardHeader>

        <div :class="classes.main()">
          <slot name="content">
            <div :class="classes.content()" tabindex="0">
              <slot name="default" />
            </div>
          </slot>
        </div>

        <div v-if="$slots.actions" :class="classes.actions()"><slot name="actions" /></div>
      </div>
    </slot>
  </div>
</template>
