<script setup>
const { toasts, removeToast, pauseToast, resumeToast } = useLsToast();
</script>
<template>
  <TransitionGroup
    enterActiveClass="transition-all duration-500 transform-gpu ease-[cubic-bezier(0,0.34,0.03,1)]"
    leaveActiveClass="transition-all duration-300 transform-gpu ease-[cubic-bezier(0,0.34,0.03,1)] absolute"
    moveClass="transition-all duration-500 ease-[cubic-bezier(0,0.34,0.03,1)] transform-gpu"
    enterFromClass="opacity-0 translate-y-full"
    enterToClass="opacity-100 translate-y-0"
    leaveFromClass="opacity-100 translate-x-0"
    leaveToClass="opacity-0 translate-x-full"
    class="fixed bottom-5 right-5 z-[99999] flex flex-col gap-2"
    tag="div"
  >
    <LscAlert
      v-for="toast in toasts"
      :key="toast.id"
      class="LscToast w-[--lsds-c-toast-max-width]"
      :variant="toast.type"
      :title="toast.title"
      :message="toast.message"
      :closable="true"
      @close="removeToast(toast)"
      @mouseover="pauseToast(toast)"
      @mouseleave="resumeToast(toast)"
    >
      <template #action>
        <LscAlertButton v-if="toast.action" @click="toast.action">
          {{ toast.actionText }}
        </LscAlertButton>
      </template>
    </LscAlert>
  </TransitionGroup>
</template>
