import { useRouter } from 'vue-router';
import {
  ACTIVE_PROJECT_LIMIT_FEATURE_KEY,
  BUDGET_EXPENSES_FEATURE_KEY,
  CUSTOM_FIELDS_FEATURE_KEY,
  CUSTOM_REPORTS_FEATURE_KEY,
  FINANCIAL_BUDGETS_FEATURE_KEY,
  FIXEDFEE_BUDGETS_FEATURE_KEY,
  INTAKE_FORMS_FEATURE_KEY,
  PROJECT_TEMPLATES_FEATURE_KEY,
  PROOFS_FEATURE_KEY,
  RETAINER_BUDGETS_FEATURE_KEY,
  TASKLIST_BUDGETS_FEATURE_KEY,
  TIME_BUDGETS_FEATURE_KEY,
  useCurrentUser,
  usePermissions,
  usePricePlan,
  WORKFLOWS_FEATURE_KEY,
} from '@/api';
import { useInvoice } from '@/appShell';
import { useI18n } from '@/util';
import { useFeedback } from '@/module/feedback';
import { useCheckoutData } from '../checkout';
import { useLegacyBridge } from '../legacy';
import { useBudgetsLimit } from './useBudgetsLimit';
import { useCustomFieldsLimit } from './useCustomFieldsLimit';
import { useFormsLimit } from './useFormsLimit';
import { useProofsLimit } from './useProofsLimit';
import { useTasklistBudgetsLimit } from './useTasklistBudgetsLimit';
import { useWorkflowsLimit } from './useWorkflowsLimit';

const featuresWithLimits = /** @type {const} */ ([
  ACTIVE_PROJECT_LIMIT_FEATURE_KEY,
  BUDGET_EXPENSES_FEATURE_KEY,
  CUSTOM_FIELDS_FEATURE_KEY,
  CUSTOM_REPORTS_FEATURE_KEY,
  FINANCIAL_BUDGETS_FEATURE_KEY,
  FIXEDFEE_BUDGETS_FEATURE_KEY,
  INTAKE_FORMS_FEATURE_KEY,
  PROJECT_TEMPLATES_FEATURE_KEY,
  PROOFS_FEATURE_KEY,
  RETAINER_BUDGETS_FEATURE_KEY,
  TASKLIST_BUDGETS_FEATURE_KEY,
  TIME_BUDGETS_FEATURE_KEY,
  WORKFLOWS_FEATURE_KEY,
]);

/** @typedef {typeof featuresWithLimits[number]} FeatureWithLimits */

/**
 * @typedef {Object} FeatureLimitObject
 * @property {ComputedRef<number>=} totalCount
 * @property {ComputedRef<number>} featureLimit
 * @property {ComputedRef<boolean>} isFeatureEnabled
 * @property {ComputedRef<boolean>} isOverFeatureLimit
 * @property {ComputedRef<boolean>} loading
 */

/**
 * Returns the limits for a given feature
 * @param {Object} options
 * @param {FeatureWithLimits} options.feature
 * @param {boolean} options.fromCreationModal
 * @returns {FeatureLimitObject}
 */
function useLimit({ feature, fromCreationModal = true } = {}) {
  if (
    [
      FINANCIAL_BUDGETS_FEATURE_KEY,
      TIME_BUDGETS_FEATURE_KEY,
      RETAINER_BUDGETS_FEATURE_KEY,
      FIXEDFEE_BUDGETS_FEATURE_KEY,
    ].includes(feature)
  ) {
    const budgetTypes = {
      [FINANCIAL_BUDGETS_FEATURE_KEY]: 'financial',
      [TIME_BUDGETS_FEATURE_KEY]: 'time',
      [RETAINER_BUDGETS_FEATURE_KEY]: 'retainer',
      [FIXEDFEE_BUDGETS_FEATURE_KEY]: 'fixedFee',
    };

    return useBudgetsLimit({ type: budgetTypes[feature], isNewBudget: fromCreationModal });
  }

  if (feature === CUSTOM_FIELDS_FEATURE_KEY) {
    return useCustomFieldsLimit();
  }

  if (feature === PROOFS_FEATURE_KEY) {
    return useProofsLimit();
  }

  if (feature === TASKLIST_BUDGETS_FEATURE_KEY) {
    return useTasklistBudgetsLimit();
  }

  if (feature === INTAKE_FORMS_FEATURE_KEY) {
    return useFormsLimit();
  }

  if (feature === WORKFLOWS_FEATURE_KEY) {
    return useWorkflowsLimit();
  }

  return {
    totalCount: computed(() => 0),
    isOverFeatureLimit: computed(() => false),
    isFeatureEnabled: computed(() => false),
    featureLimit: computed(() => 0),
    loading: computed(() => true),
  };
}

/**
 * @param {Object} options
 * @param {FeatureWithLimits} options.feature
 * @param {boolean} options.fromCreationModal
 */
export function useFeaturesLimit({ feature, fromCreationModal = true }) {
  const featureHasLimits = computed(() => featuresWithLimits.includes(feature));

  const user = useCurrentUser();
  const { isOwnerAdmin } = usePermissions();
  const { canAccessCheckout, isCancellationScheduled } = isOwnerAdmin.value
    ? useCheckoutData()
    : { canAccessCheckout: ref(false), isCancellationScheduled: ref(false) };

  const { t } = useI18n();
  const { pricePlanId, isPlanMaxProjects } = usePricePlan();
  const { showContactForm, showRequestUpgradeForm } = useFeedback();
  const { isInvoicePaymentRequired, accountIsInvoiceBased } = useInvoice();
  const router = useRouter();
  const { isDialogOpen, dialogName, dialogProps } = useLegacyBridge();

  const {
    isOverFeatureLimit,
    isFeatureEnabled,
    featureLimit,
    loading,
    totalCount: currentUsage,
  } = useLimit({
    feature,
    fromCreationModal,
  });

  const isSending = shallowRef(false);
  const wasFeatureRequestSent = shallowRef(false);

  const featureLimitsPerPlan = {
    Deliver: {
      [WORKFLOWS_FEATURE_KEY]: 1,
    },
    Grow: {
      [ACTIVE_PROJECT_LIMIT_FEATURE_KEY]: 600,
      [CUSTOM_FIELDS_FEATURE_KEY]: 'unlimited',
      [PROJECT_TEMPLATES_FEATURE_KEY]: 50,
      [FINANCIAL_BUDGETS_FEATURE_KEY]: 30,
      [TIME_BUDGETS_FEATURE_KEY]: 'unlimited',
      [PROOFS_FEATURE_KEY]: 50,
      [WORKFLOWS_FEATURE_KEY]: 3,
    },
    Scale: {
      [WORKFLOWS_FEATURE_KEY]: 'unlimited',
    },
    Enterprise: {
      [WORKFLOWS_FEATURE_KEY]: 'unlimited',
    },
  };

  const featureNamesMapping = {
    [FINANCIAL_BUDGETS_FEATURE_KEY]: t('financial budgets'),
    [TIME_BUDGETS_FEATURE_KEY]: t('time budgets'),
    [FIXEDFEE_BUDGETS_FEATURE_KEY]: t('fixed fee budgets'),
    [RETAINER_BUDGETS_FEATURE_KEY]: t('retainer budgets'),
    [CUSTOM_FIELDS_FEATURE_KEY]: t('custom fields'),
    [PROOFS_FEATURE_KEY]: t('proofs'),
    [INTAKE_FORMS_FEATURE_KEY]: t('forms'),
    [WORKFLOWS_FEATURE_KEY]: t('workflows'),
  };

  function nextPlanUpFeatureLimit(nextPlanUpName) {
    return featureLimitsPerPlan?.[nextPlanUpName]?.[feature];
  }

  function featureCallToActionUser(planId, entryPoint = '') {
    let message = `${t('Hey there,')}\n\n`;

    if (featureNamesMapping[feature]) {
      const featureName = featureNamesMapping[feature];

      message += t('I have reached the maximum number of {featureName} available on our plan.', { featureName });
      message += ' ';
      message += t(
        'To add more {featureName} we need to either delete one or upgrade to a higher plan to get more {featureName} and much more.',
        { featureName },
      );
      message += ' ';
    }

    message += t('As an admin, could you review our upgrade options.');

    message += `\n\n${t('Thanks!')}`;

    dialogName.value = 'ContactUpgradeRequestFormDialog';
    dialogProps.value = { planId, feature, message, entryPoint };
    isDialogOpen.value = true;

    showRequestUpgradeForm(dialogProps.value);
  }

  function featureCallToActionAdmin(entryPoint = '') {
    if (isCancellationScheduled.value) {
      dialogName.value = 'ReactivateSubscriptionModal';
      dialogProps.value = { needConfirmation: true };
      isDialogOpen.value = true;
      return;
    }

    if (isPlanMaxProjects.value) {
      router.push({ path: '/migrate' });
      return;
    }

    if ((!isInvoicePaymentRequired.value || canAccessCheckout.value) && !accountIsInvoiceBased.value) {
      router.push({ path: '/redirect/checkout' });
    } else {
      dialogName.value = 'ContactFormDialog';
      isDialogOpen.value = true;
      dialogProps.value = {
        formType: 'checkoutExcludedUser',
        title: t('Contact Us'),
        subtitle: t(
          'Simply fill out your details here to make changes to your subscription, and your Customer Success representative will contact you to figure out the best solution for your needs.',
        ),
        entryPoint,
      };
      showContactForm(dialogProps.value);
    }
  }

  function featureCallToAction(entryPoint = '') {
    if (user.value.administrator) {
      featureCallToActionAdmin(entryPoint);
    } else {
      featureCallToActionUser(pricePlanId.value, entryPoint);
    }
  }

  return {
    featureHasLimits,
    featureLimit,
    featureCallToAction,
    featureCallToActionUser,
    isFeatureEnabled,
    isOverFeatureLimit,
    isSending,
    nextPlanUpFeatureLimit,
    loading,
    currentUsage,
    wasFeatureRequestSent,
    featureNamesMapping,
  };
}
