<script setup>
import { useI18n } from '@/util';

const { t } = useI18n();
</script>
<template>
  <div class="text-body-1">
    <p>
      {{
        t(
          "This is your work hub within {teamwork}. You'll see all work assigned to you and you can decide what needs to get done first (or next).",
          { teamwork: 'Teamwork.com' },
        )
      }}
    </p>
    <p class="mt-4">
      {{
        t(
          'Start with any "late" tasks and then move onto tasks due "today". You\'ll also be able to see what\'s "upcoming" to make sure there are no surprises.',
        )
      }}
    </p>
  </div>
</template>
