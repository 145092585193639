import { distance } from 'fastest-levenshtein';
import { useI18n } from '@/util';

/**
 * Returns a list of job role suggestions based on the search term.
 * @param {ShallowRef<string>} searchTerm
 * @param {Number} count
 * @returns {ComputedRef<string[]>}
 */
export function getJobRoleSuggestions(searchTerm, count = 5) {
  const { t, languageCode } = useI18n();
  const roles = [
    [t('Backend Developer'), 1.2],
    [t('Content Moderator')],
    [t('Content Strategist')],
    [t('Content Writer')],
    [t('Copywriter')],
    [t('Customer Support')],
    [t('Data Analyst'), 0.8],
    [t('Data Scientist'), 0.8],
    [t('Database Administrator')],
    [t('Financial Analyst')],
    [t('Frontend Developer'), 1.2],
    [t('Graphic Designer'), 0.85],
    [t('Human Resources Manager')],
    [t('IT Consultant')],
    [t('Legal Advisor'), 0.7],
    [t('Mobile Developer'), 1.05],
    [t('Operations Manager')],
    [t('Principal Designer')],
    [t('Product Manager')],
    [t('Project Manager')],
    [t('QA Manager')],
    [t('QA Tester')],
    [t('SEO Specialist')],
    [t('Social Media Manager')],
    [t('Software Engineer')],
    [t('Systems Analyst')],
    [t('Technical Support Specialist')],
    [t('UI Designer')],
    [t('UX Designer')],
    [t('Videographer')],
  ];

  return computed(() => {
    const search = searchTerm.value?.toLocaleLowerCase(languageCode.value) ?? '';
    let filteredRoles = roles;
    if (search) {
      filteredRoles = roles.map(([string, weight = 1]) => {
        const tokens = string.split(' ');
        const weightedDistance = Math.min(...tokens.map((token) => distance(token, search) * weight));
        return [string, weightedDistance];
      });
    }
    return filteredRoles
      .sort((a, b) => a[1] - b[1])
      .map(([role]) => role)
      .slice(0, count);
  });
}
