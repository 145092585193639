<!-- eslint-disable vue/multi-word-component-names -->
<script setup>
import FilterMenu from './FilterMenu.vue';
import FilterMenuButton from './FilterMenuButton.vue';
import { provideFilter } from './useFilter';

const props = defineProps({
  allowSaveFilters: {
    type: Boolean,
    default: true,
  },
  projectId: {
    type: Number,
    default: 0,
  },
  section: {
    type: String,
    default: undefined,
  },
  dataIdentifierPrefix: {
    type: String,
    required: true,
  },
  allowMenu: {
    type: Boolean,
    default: true,
  },
  allowWrap: {
    type: Boolean,
    default: false,
  },
});

const apiParams = defineModel({
  type: Object,
  required: true,
});

const menu = defineModel('menu', {
  type: Boolean,
  default: false,
});

const slots = useSlots();
const shouldShowMenu = computed(() => props.allowMenu && Boolean(slots.menuFilters || slots.menuOptions));
const hasQuickFilters = computed(() => Boolean(slots.menuQuickFilters));

provideFilter({
  apiParams,
  section: computed(() => props.section),
  projectId: computed(() => props.projectId),
  dataIdentifierPrefix: computed(() => props.dataIdentifierPrefix),
});
</script>

<template>
  <div :class="allowWrap ? 'flex flex-wrap gap-y-4' : 'inline-flex'" class="items-center gap-2">
    <slot name="default" />
    <FilterMenu
      v-if="shouldShowMenu"
      v-model="menu"
      :allowSaveFilters="allowSaveFilters"
      :closeOnContentClick="false"
      :projectId="projectId"
      :section="section"
      :hasQuickFilters="hasQuickFilters"
      :location="hasQuickFilters ? 'bottom center' : 'bottom end'"
      offset="12"
    >
      <template #activator="activator">
        <slot name="activator" v-bind="activator">
          <FilterMenuButton v-bind="activator.props" />
        </slot>
      </template>
      <template #menuFilters><slot name="menuFilters" /></template>
      <template v-if="$slots.menuQuickFilters" #menuQuickFilters><slot name="menuQuickFilters" /></template>
      <template #menuOptions><slot name="menuOptions" /></template>
      <template #beforeSavedFiltersList="{ meta }"><slot name="beforeSavedFiltersList" :meta="meta" /></template>
    </FilterMenu>
  </div>
</template>
