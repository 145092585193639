<script setup lang="ts">
// This isnt automatically imported by Vite, so we need to import it manually
import { VDialog } from 'vuetify/components';
import { provideKeyboardShortcut } from '@/util';
import { provideLsDialog } from './useLsDialog.js';

interface ClickOutsideEvent extends Event {
  target: HTMLElement;
}

const props = defineProps({
  /**
   * Controls focus retention on dialog's first child. Set to false for compatibility with external tools like Pendo.
   * @see https://vuetifyjs.com/en/api/v-dialog/#props-retain-focus
   */
  retainFocus: {
    type: Boolean,
    default: true,
  },
  /** Whether the dialog can be closed on click outside. This will also hide the close button */
  persistent: {
    type: Boolean,
    default: false,
  },
});

const modelValue = defineModel({
  type: Boolean,
  default: false,
});

const { shouldCloseOnClickOutside } = provideLsDialog(computed(() => props.persistent));
provideKeyboardShortcut(modelValue);

function close() {
  modelValue.value = false;
}

let clickOutsideEvent: ClickOutsideEvent | undefined;

function handleClickOutside(event: ClickOutsideEvent) {
  clickOutsideEvent = event;
  nextTick(() => {
    clickOutsideEvent = undefined;
  });
}

/**
 * Do not close the dialog:
 * - when a toast was clicked.
 * - when pendo guide is clicked.
 * - when shouldCloseOnClickOutside is false.
 */
const modelValueFiltered = computed({
  get() {
    return modelValue.value;
  },
  set(value) {
    if (clickOutsideEvent?.target.closest('.LscToast, #pendo-guide-container') || !shouldCloseOnClickOutside.value) {
      return;
    }
    modelValue.value = value;
  },
});
</script>

<template>
  <!-- eslint-disable-next-line lightspeed/no-restricted-component -->
  <VDialog
    v-model="modelValueFiltered"
    class="LscDialog"
    :scrollable="true"
    :persistent="persistent"
    aria-labelledby="lscdialog-title"
    :retainFocus="retainFocus"
    @click:outside="handleClickOutside"
  >
    <template #activator="activator">
      <slot name="activator" v-bind="activator" />
    </template>
    <slot name="default" :close="close" />
  </VDialog>
</template>

<style>
.LscDialog.v-dialog > .v-overlay__content {
  @apply pointer-events-none w-full items-center;
}

.LscDialog.v-dialog.alignLeft > .v-overlay__content {
  @apply items-start;
}
</style>
