import { useItemLoader } from '../base/useItemLoader';

function responseToItem({ data }) {
  return data.authenticateURL;
}

// Experiment A41 (useExperimentA41) need this url to redirect to
export function useHubspotAuthUrlV1Loader() {
  const { state } = useItemLoader({
    url: '/integrations/hubspot.json?getAuthURL=true',
    responseToItem,
  });

  return state;
}
