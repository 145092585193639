import { getLastChildPath } from '../lastChildPath';

export const settingsRoutes = [
  {
    path: '/settings',
    component: () => import('./RouteSettings.vue'),
    children: [
      {
        path: '',
        redirect() {
          return `/settings/${getLastChildPath('settings', 'general')}`;
        },
      },
      {
        path: 'workflows',
        component: () => import('./RouteSettingsWorkflow.vue'),
      },
      {
        path: 'beta',
        component: () => import('./RouteSettingsBeta.vue'),
      },
      {
        path: 'colortheme',
        component: () => import('./RouteSettingsColorTheme.vue'),
      },
      {
        path: 'customfields',
        component: () => import('./RouteSettingsCustomfields.vue'),
      },
      {
        path: 'email',
        component: () => import('./RouteSettingsEmail.vue'),
      },
      {
        path: 'export',
        component: () => import('./RouteSettingsExport.vue'),
      },
      {
        path: 'forms',
        component: () => import('./RouteSettingsForms.vue'),
      },
      {
        path: 'general',
        component: () => import('./RouteSettingsGeneral.vue'),
      },
      {
        path: 'import',
        component: () => import('./RouteSettingsImport.vue'),
      },
      {
        path: 'integrations',
        component: () => import('./RouteSettingsIntegrations.vue'),
        children: [
          {
            path: '',
            redirect() {
              return `/settings/integrations/${getLastChildPath('settings/integrations', 'all')}`;
            },
          },
          {
            path: ':integrationsTab(all|productivity|apps|accounting|connectors|partner|cloud|webhooks|teamwork|other)',
            component: () => import('./RouteSettingsIntegrationsView.vue'),
            props: true,
          },
        ],
      },
      {
        path: 'logo',
        component: () => import('./RouteSettingsLogo.vue'),
      },
      {
        path: 'referral',
        component: () => import('./RouteSettingsReferral.vue'),
        children: [
          {
            path: '',
            redirect() {
              return `/settings/referral/${getLastChildPath('settings/referral', 'about')}`;
            },
          },
          {
            path: ':referralTab(about|latest|banners|tips|terms)',
            component: () => import('./RouteSettingsReferralView.vue'),
            props: true,
          },
          {
            path: ':pathMatch(.*)*',
            redirect() {
              return '/settings/referral';
            },
          },
        ],
      },
      {
        path: 'sso',
        component: () => import('./RouteSettingsSSO.vue'),
      },
      {
        path: 'subscription',
        component: () => import('./RouteSettingsSubscription.vue'),
      },
      {
        path: 'tags',
        component: () => import('./RouteSettingsTags.vue'),
      },
      {
        path: 'templates',
        component: () => import('./RouteSettingsTemplates.vue'),
      },
      {
        path: 'webhooks',
        component: () => import('./RouteSettingsWebhooks.vue'),
      },
      {
        path: 'time',
        component: () => import('./RouteSettingsTime.vue'),
      },
      {
        path: 'notifications',
        component: () => import('./RouteSettingsTime.vue'),
        redirect: '/settings/time',
      },
      {
        path: 'invoicehistory',
        component: () => import('./RouteSettingsInvoiceHistory.vue'),
      },
      {
        path: ':pathMatch(.*)*',
        redirect: '/settings/general',
      },
    ],
  },
];
