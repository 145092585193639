/**
 * An adapter which transforms a `state` object produced by `useItemLoader`
 * to a corresponding `state` object which would be produced by `useListLoader`.
 * It's useful in situations where we should really use `useListLoader` but the API endpoint does not support pagination.
 *
 * @param {Object} state A `state` object produced by `useItemLoader`. `state.item.value` must be `undefined` or an Array.
 * @param {MaybeRef<Number>} count A `count` as expected by `useListLoader`.
 */
export function useItemToListLoader(state, _count = -1) {
  const { item, inSync, loaded, meta, error, retry } = state;
  const count = shallowRef(_count);
  return {
    items: computed(() => {
      if (item.value && count.value > 0) {
        return item.value.length <= count.value ? item.value : item.value.slice(0, count.value);
      }
      return [];
    }),
    hasMore: computed(() => {
      if (count.value < 0) {
        return true;
      }
      return item.value != null && count.value < item.value.length;
    }),
    itemInSync: () => inSync.value,
    inSync,
    loaded,
    meta,
    error: computed(() => (error.value?.response?.status === 404 ? undefined : error.value)),
    retry,
  };
}
