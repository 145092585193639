<script setup>
import { ChatDrawer } from '@/module/chat';
import { FormDrawer } from '@/module/form';
import { HelpCenterDrawer } from '@/module/helpCenter';
import { NotificationDrawer } from '@/module/notification';
import { ProjectJumpToDrawer } from '@/module/project';
import { SpacesDrawer } from '@/module/spaces';
import { TemplatesDrawer } from '@/module/template';
import { useAppShellSidebar } from '../sidebar/useAppShellSidebar';

const { activeDrawerPanel, isDrawerPanelPinned, activeDrawerPanelWidth } = useAppShellSidebar();
</script>

<template>
  <div
    class="z-[13] h-screen bg-surface-default"
    :class="{
      'fixed top-0 shadow-drawer': !isDrawerPanelPinned,
      'relative': isDrawerPanelPinned,
      'hidden': !activeDrawerPanel,
      'block': activeDrawerPanel,
    }"
    :style="{
      '--drawer-width': activeDrawerPanelWidth,
    }"
  >
    <div class="h-full w-[--drawer-width]">
      <ProjectJumpToDrawer />
      <NotificationDrawer />
      <HelpCenterDrawer />
      <FormDrawer />
      <TemplatesDrawer />
      <SpacesDrawer />
      <ChatDrawer />
    </div>
  </div>
</template>
