<script setup>
import { DateTime } from 'luxon';
import { useAppShellSidebar } from '@/appShell';
import { useI18n } from '@/util';
import { useSpacesIcons } from './useSpacesIcons';

defineProps({
  space: {
    type: Object,
    required: true,
  },
});

const { t, formatDate } = useI18n();
const { clearActiveDrawerPanelIfNotPinned } = useAppShellSidebar();
const { getSpaceIcon, getSpaceInitials } = useSpacesIcons();
</script>

<template>
  <VListItem
    :to="`/spaces/${space.code}`"
    v-bind="VListItemSidebarProjectsDrawer"
    @click="clearActiveDrawerPanelIfNotPinned"
  >
    <template #prepend>
      <div
        class="mr-2 flex size-8 shrink-0 items-center justify-center rounded-full bg-[--color] transition-transform group-hover:scale-105"
        :style="{ '--color': space.spaceColor }"
      >
        <LscIcon v-if="space.icon" class="text-icon-on-primary" :size="8" :icon="getSpaceIcon(space.icon)" />
        <span v-else class="text-body-3 text-on-primary">{{ getSpaceInitials(space.title) }}</span>
      </div>
    </template>
    <VListItemTitle class="text-body-1">{{ space.title }}</VListItemTitle>
    <VListItemSubtitle class="text-body-2">
      {{ t('Last updated {date}', { date: formatDate(DateTime.fromISO(space.updatedAt)) }) }}
    </VListItemSubtitle>
  </VListItem>
</template>
