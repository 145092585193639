export function createFormatList({ languageCode }) {
  /**
   * Returns a formatted list as a string in the locale's default style.
   * @param {Parameters<Intl.ListFormat['format']>[0]} list
   * @param {Intl.ListFormatOptions} [options] Optional object with options on how to format the list.
   * @returns {ReturnType<Intl.ListFormat['format']>} Formatted lists in the locale's default style.
   */
  return function formatList(list, options) {
    return new Intl.ListFormat(languageCode.value, options).format(list);
  };
}
