import { useItemLoader } from '../../base/useItemLoader';
import { useOptimisticUpdates } from '../../base/useOptimisticUpdates';
import { useRealTimeUpdates } from '../../base/useRealTimeUpdates';

function responseToItem({ data }) {
  const { workflow, included } = data;
  const { companies = {}, users = {}, stages = {} } = included;

  if (workflow.createdBy && users[workflow.createdBy]) {
    const createdBy = users[workflow.createdBy];
    workflow.createdBy = {
      ...createdBy,
      company: companies[createdBy.company.id],
    };
  }

  if (workflow.stages && stages) {
    workflow.stages = workflow.stages.map(({ id }) => stages[id]).sort((a, b) => a.displayOrder - b.displayOrder);
  }

  return workflow;
}

export function useWorkflowV3Loader({ workflowId: _workflowId, params }) {
  const workflowId = shallowRef(_workflowId);

  const { state, refresh, update } = useItemLoader({
    url: computed(() => Number.isInteger(workflowId.value) && `/projects/api/v3/workflows/${workflowId.value}.json`),
    params,
    responseToItem,
  });

  useOptimisticUpdates((event) => {
    if (['workflow', 'workflow-project'].includes(event.type) && workflowId.value === event.workflowId) {
      update((workflow) => {
        if (event.action === 'update') {
          return { ...workflow, ...event.workflow };
        }
        return workflow;
      }, event.promise);
    }
  });

  useRealTimeUpdates((event) => {
    if (['workflow', 'workflow-project'].includes(event.type) && workflowId.value === event.workflowId) {
      refresh();
    }

    if (event.type === 'stage' && event.affectedWorkflowIds.includes(workflowId.value)) {
      refresh();
    }
  });

  return state;
}
