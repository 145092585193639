import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = {
  width: "242",
  height: "121",
  viewBox: "0 0 242 121",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createStaticVNode("<path d=\"M31.7543 118.554L1 121H233C237.418 121 241 117.418 241 113V1H225.213C222.381 1 219.76 2.49703 218.322 4.93625L200.228 35.6182C198.919 37.8377 196.621 39.2909 194.055 39.5222L161.804 42.4278C158.935 42.6863 156.425 44.4683 155.236 47.0929L142.354 75.5136C141.089 78.3047 138.341 80.1279 135.277 80.2083L62.149 82.1262C59.4366 82.1974 56.9451 83.6385 55.531 85.9542L37.9478 114.749C36.612 116.936 34.3092 118.351 31.7543 118.554Z\" fill=\"url(#paint0_linear_12959_73344)\" fill-opacity=\"0.1\"></path><path d=\"M1 119.769H21.8978C30.4976 119.769 38.44 115.168 42.7179 107.708L50.8827 93.4686C55.1605 86.0083 63.1029 81.4071 71.7027 81.4071H124.392C134.316 81.4071 143.217 75.2983 146.786 66.0376L150.434 56.573C154.003 47.3123 162.904 41.2035 172.828 41.2035H183.383C192.383 41.2035 200.626 36.1684 204.736 28.1613L211.981 14.0422C216.09 6.03515 224.334 1 233.334 1H241\" stroke=\"#63C0F9\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"></path><defs><linearGradient id=\"paint0_linear_12959_73344\" x1=\"121\" y1=\"1\" x2=\"121\" y2=\"121\" gradientUnits=\"userSpaceOnUse\"><stop offset=\"0.491774\" stop-color=\"#63C0F9\"></stop><stop offset=\"0.822917\" stop-color=\"#63C0F9\" stop-opacity=\"0.666125\"></stop><stop offset=\"1\" stop-color=\"#63C0F9\" stop-opacity=\"0\"></stop></linearGradient></defs>", 3)
  ])))
}
export default { render: render }