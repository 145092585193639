import { useRoute } from '@/route';
import { usePendo } from './usePendo';

export function useUtmTracking() {
  const { trackPendoEvent } = usePendo();
  const route = useRoute();

  const UTM_PARAMS = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content', 'utm_ad', 'utm_ad_group'];
  const UTM_COMMON_METRICS = ['account_id', 'user_id', 'plan_name', 'page', 'user_role'];
  const UTM_EVENT_NAME = 'MARKETING_EVENT';

  const urlHasUtmParams = computed(() => UTM_PARAMS.some((e) => Boolean(route.query[e])));

  const utmDetails = computed(() =>
    UTM_PARAMS.reduce(
      (prev, key) =>
        route.query[key]
          ? {
              ...prev,
              [key]: route.query[key],
            }
          : prev,
      {},
    ),
  );

  function trackEventUtmDetected() {
    if (urlHasUtmParams.value) {
      trackPendoEvent({
        eventName: UTM_EVENT_NAME,
        commonMetrics: UTM_COMMON_METRICS,
        metadata: {
          ...utmDetails.value,
          event_action: `app_opened_from_${utmDetails?.value?.utm_source || 'unknown'}`,
        },
      });
    }
  }

  return {
    trackEventUtmDetected,
  };
}
