<script setup>
import { Interval } from 'luxon';
import { getToday, isSameDay, isSameMonth, useI18n } from '@/util';
import { useDatePicker } from './useDatePicker';

const { formatDate } = useI18n();
const {
  date,
  dates,
  hasDateProp,
  hasDatesProp,
  minDate,
  maxDate,
  enableWeekends,
  formattedVisibleMonth,
  rangeMode,
  dayHeadings,
  visibleMonth,
  visibleMonthInterval,
  selectDate,
  selectDates,
  toggleInputFocus,
  close,
  computedDisabledDayTooltipFunction,
} = useDatePicker();

/** @type {ShallowRef<DateTime|undefined>} */
const hoveredOverDay = shallowRef();

const dateRangeInterval = computed(() => {
  if (!hasDatesProp.value) {
    return undefined;
  }

  const [startDate, endDate] = dates.value;

  if (!hoveredOverDay.value) {
    if (startDate && endDate) {
      return Interval.fromDateTimes(startDate, endDate);
    }
    return undefined;
  }

  let start = startDate;
  let end = endDate;

  if (rangeMode.value === 'start' && !isSameDay(hoveredOverDay.value, end)) {
    start = hoveredOverDay.value;
    end = endDate;
  } else if (rangeMode.value === 'end' && !isSameDay(hoveredOverDay.value, start)) {
    end = hoveredOverDay.value;
    start = startDate;
  }

  if (start && end) {
    return Interval.fromDateTimes(start, end);
  }
  return undefined;
});

/**
 * Select a day in the calendar.
 * @param {DateTime} newDate
 */
function select(newDate) {
  if (hasDateProp.value) {
    selectDate(newDate);
    close();
  } else if (hasDatesProp.value) {
    selectDates(newDate);
    toggleInputFocus();
  }
}

function isDateDisabled(_date) {
  return (
    (minDate.value && _date < minDate.value) ||
    (maxDate.value && _date > maxDate.value) ||
    (!enableWeekends.value && _date.isWeekend)
  );
}

function isStartOfRange(_date) {
  if (!dateRangeInterval.value) {
    return false;
  }
  return isSameDay(_date, dateRangeInterval.value.start);
}

function isEndOfRange(_date) {
  if (!dateRangeInterval.value) {
    return false;
  }
  return isSameDay(_date, dateRangeInterval.value.end);
}

function isInRange(_date) {
  if (!dateRangeInterval.value) {
    return false;
  }
  return dateRangeInterval.value.contains(_date) && !isDateDisabled(_date);
}

function isHoveredOverDay(_date) {
  return hoveredOverDay.value && isSameDay(_date, hoveredOverDay.value);
}

function isDateSelected(_date) {
  if (hasDateProp.value) {
    return isSameDay(_date, date.value);
  }
  return isStartOfRange(_date) || isEndOfRange(_date) || dates.value.some((d) => isSameDay(_date, d));
}

function setHoveredOverDay(_date) {
  if (!isDateDisabled(_date)) {
    hoveredOverDay.value = _date;
  }
}

function clearHoveredOverDay() {
  hoveredOverDay.value = undefined;
}

const LscDatePickerDayVariantStyleConfig = tv({
  base: [
    'relative z-0 inline-flex h-8 w-full items-center justify-center',
    'text-button-1 font-medium text-default outline-0',
    'transition-[background,border-color]',
    'before:absolute before:inset-0 before:-z-10',
    'after:absolute after:-z-10 after:h-7 after:w-7 after:rounded-full after:bg-transparent',
    'after:ring-2 after:ring-transparent',
    'hover:after:bg-surface-hover focus-visible:after:bg-surface-hover',
  ],
  variants: {
    isOutsideCurrentMonth: {
      true: 'text-subtle',
    },
    isToday: {
      true: ['text-primary', 'after:border after:border-primary-default'],
    },
    isInRange: {
      true: [
        'font-semibold text-on-emphasis',
        'before:bg-[--lsds-c-datepicker-range-color-background-default]',
        'hover:after:bg-[--lsds-c-datepicker-range-color-background-hover]',
      ],
    },
    isSelected: {
      true: [
        'font-semibold',
        'text-on-primary',
        'after:bg-action-primary-default',
        'hover:after:bg-action-primary-default',
      ],
    },
    isDisabled: {
      true: ['cursor-not-allowed font-normal text-disabled hover:after:bg-transparent'],
    },
  },
  compoundVariants: [
    {
      isStartOfRange: true,
      isEndOfRange: false,
      class: [
        'before:left-auto before:w-1/2',
        'before:bg-[color:--lsds-c-datepicker-range-color-background-default]',
        'after:ring-[color:--lsds-c-datepicker-range-color-background-default]',
      ],
    },
    {
      isStartOfRange: false,
      isEndOfRange: true,
      class: [
        'before:right-auto before:w-1/2',
        'before:bg-[color:--lsds-c-datepicker-range-color-background-default]',
        'after:ring-[color:--lsds-c-datepicker-range-color-background-default]',
      ],
    },
    {
      isSelected: true,
      class: [
        'font-semibold',
        'text-on-primary',
        'after:bg-action-primary-default',
        'hover:after:bg-action-primary-default',
      ],
    },
  ],
});

const visibleDays = computed(() =>
  visibleMonthInterval.value.splitBy({ days: 1 }).map(({ start: day }) => ({
    day,
    formattedDay: formatDate(day, 'long'),
    classes: LscDatePickerDayVariantStyleConfig({
      isOutsideCurrentMonth: !isSameMonth(day, visibleMonth.value),
      isToday: isSameDay(day, getToday()),
      isStartOfRange: isStartOfRange(day),
      isEndOfRange: isEndOfRange(day),
      isInRange: isInRange(day),
      isSelected: isDateSelected(day) || isHoveredOverDay(day),
      isDisabled: isDateDisabled(day),
    }),
  })),
);
</script>

<template>
  <div
    class="relative grid grid-cols-7 place-items-center gap-y-1 px-3 pt-2 after:absolute after:top-10 after:h-px after:w-full after:border-b after:border-separator"
    role="grid"
    :ariaLabel="formattedVisibleMonth"
    @mouseleave="clearHoveredOverDay"
  >
    <div
      class="col-span-full grid grid-cols-subgrid text-body-2 font-semibold supports-[not(grid-template-columns:subgrid)]:contents"
      aria-hidden="true"
    >
      <div v-for="day in dayHeadings" :key="day" class="flex h-8 w-full items-center justify-center">
        {{ day }}
      </div>
    </div>
    <div class="col-span-full grid grid-cols-subgrid supports-[not(grid-template-columns:subgrid)]:contents">
      <button
        v-for="{ day, classes, formattedDay } in visibleDays"
        :key="day.ts"
        v-LsdTooltip="isDateDisabled(day) ? computedDisabledDayTooltipFunction(day) : undefined"
        :aria-selected="isDateSelected(day)"
        role="gridcell"
        :aria-label="formattedDay"
        :disabled="isDateDisabled(day)"
        type="button"
        :class="classes"
        @mouseover="setHoveredOverDay(day)"
        @click="select(day)"
      >
        {{ day.toFormat('d') }}
      </button>
    </div>
  </div>
</template>
