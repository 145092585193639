<script setup>
import { useI18n } from '@/util';

const { t } = useI18n();
</script>
<template>
  <div class="text-body-1">
    <p>
      {{
        t(
          'Set site-level user rates first so someone has a default rate for all projects. Later, you can update the project-level user rates to override the default whenever necessary.',
        )
      }}
    </p>
    <p class="pt-6">
      {{
        t(
          'Helpful hint: Add billable and cost rates as you add new team members, that way you’re set up to track profit from the beginning.',
        )
      }}
    </p>
  </div>
</template>
