import { PROOF_FEEDBACK_VERSION } from '../constants';

export const TOOL = Object.freeze({
  COMMENT: 'comment',
  SHAPE: 'shape',
  BRUSH: 'brush',
});
export const DEFAULT_TOOL = TOOL.COMMENT;

export const SHAPE = Object.freeze({
  ELLIPSE: 'ellipse',
  RECTANGLE: 'rectangle',
  ARROW: 'arrow',
  LINE: 'line',
});
export const DEFAULT_SHAPE = SHAPE.ELLIPSE;

export const COLOR = Object.freeze({
  RED: 'red',
  PINK: 'pink',
  ORANGE: 'orange',
  YELLOW: 'yellow',
  GREEN: 'green',
  BLUE: 'blue',
  PURPLE: 'purple',
  GREY: 'grey',
  BLACK: 'black',
  WHITE: 'white',
});
export const DEFAULT_COLOR = COLOR.BLACK;

export const DEFAULT_OPACITY = 100;

export const WEIGHT = Object.freeze({
  ONE: 1,
  TWO: 2,
  THREE: 3,
});
export const DEFAULT_WEIGHT = WEIGHT.ONE;

export function createProofFeedbackData(data, _version = PROOF_FEEDBACK_VERSION) {
  // Validate `version`
  const version = parseInt(_version, 10);
  if (version <= 0 || version > PROOF_FEEDBACK_VERSION) {
    throw new Error(`'_version' property is missing or invalid`);
  }

  // Version #1
  if (version === 1) {
    return {
      x: data.x || 0,
      y: data.y || 0,
      text: data.text || '',
      version,
    };
  }

  // Version #2
  if (version === 2) {
    const shape = Object.values(SHAPE).includes(data.config?.shape) ? data.config?.shape : DEFAULT_SHAPE;
    return {
      config: {
        tool: Object.values(TOOL).includes(data.config?.tool) ? data.config?.tool : DEFAULT_TOOL,
        shape,
        color: Object.values(COLOR).includes(data.config?.color) ? data.config?.color : DEFAULT_COLOR,
        opacity: !(typeof data.config?.opacity !== 'number' || data.config.opacity < 0 || data.config.opacity > 100)
          ? data.config?.opacity
          : DEFAULT_OPACITY,
        weight: Object.values(WEIGHT).includes(data.config?.weight) ? data.config?.weight : DEFAULT_WEIGHT,
      },
      attrs: data.attrs || {},
      text: data.text || '',
      version,
    };
  }

  return null;
}
