<script setup>
/*
  This file is a place holder - we don't have global forms yet but we will
  soon so please leave this for mow.
*/
import { useFormsV3Loader } from '@/api';
import FormDrawerFormsList from './FormDrawerFormsList.vue';
import FormDrawerProjectsBlankSlate from './FormDrawerProjectsBlankSlate.vue';

const step = 30;
const count = shallowRef(step);
const state = useFormsV3Loader({
  count,
  pageSize: step,
  params: {
    'orderBy': 'name',
    'onlyProjectsWithExplicitMembership': true,
    'include': 'projects,projects.permissions',
    'content.state': 'latest',
    'fields[projects]': 'id,name',
    'fields[projectPermissions]': 'addForms',
  },
});
</script>

<template>
  <FormDrawerFormsList :count="count" :step="step" :state="state">
    <template #blank>
      <FormDrawerProjectsBlankSlate />
    </template>
  </FormDrawerFormsList>
</template>
