const symbol = Symbol('useActiveProductTourId');

export function provideActiveProductTourId() {
  const activeProductTourId = shallowRef(null);
  provide(symbol, activeProductTourId);
  return activeProductTourId;
}

export function useActiveProductTourId() {
  return inject(symbol);
}
