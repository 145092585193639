import './VList.variants.css';

export const VListRounded = {
  class: 'VListRounded',
  display: 'compact',
  nav: true,
};

export const VListTaskColumnRounded = {
  class: 'VListTaskColumnRounded',
  display: 'compact',
  nav: false,
};

export const VListTaskBoardColumns = {
  class: 'VListTaskBoardColumns',
  density: 'compact',
};
