import { useListLoader } from '../base/useListLoader';

function responseToItems(response) {
  const { included: { projectCategories = {} } = {}, projects = [] } = response.data;

  return projects.map((project) => {
    const updatedProject = { ...project };

    const categoryId = updatedProject.categoryId || (updatedProject.category && updatedProject.category.id);
    if (categoryId && projectCategories[categoryId]) {
      updatedProject.category = projectCategories[categoryId];
    }

    return updatedProject;
  });
}

// Gets projects metadata from an axios response.
function responseToMeta({ data: { meta } }) {
  const { projects, user, page } = meta;
  return {
    projects,
    user,
    totalCount: page?.count,
  };
}

/**
 * Fetches a list of project samples from the Teamwork v3 API.
 * For more details, refer to the API documentation:
 * https://apidocs.teamwork.com/docs/teamwork/v3/projects/get-projects-api-v3-projects-teamwork-samples-json
 *
 * @param {Object} options - Configuration options for the API request.
 */
export function useProjectSamplesV3Loader({ params, count, pageSize } = {}) {
  const { state } = useListLoader({
    url: '/projects/api/v3/projects/teamwork/samples.json',
    params,
    count,
    pageSize,
    responseToItems,
    responseToMeta,
  });

  return state;
}
