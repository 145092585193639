import { useItemLoader } from '../base/useItemLoader';
import { useItemToListLoader } from '../base/useItemToListLoader';

/**
 * @typedef {Object} Rep
 * @property {number} id
 * @property {number} userId
 * @property {number} hubspotUserId
 * @property {string} avatarImagePath
 * @property {string} hubspotCalendarLink
 * @property {string} userFirstName
 * @property {string} userLastName
 * @property {string} role
 */

/**
 * @typedef {Object} RepLoaderResponse
 * @property {Rep[]} reps
 */

/**
 * @returns {RepLoaderResponse}
 */

function responseToItem(response) {
  const reps = response.data?.reps;
  return reps || [];
}

export function useRepsV3Loader({ count }) {
  const url = computed(() => (count.value > -1 ? `/projects/api/v3/reps.json` : undefined));
  const { state } = useItemLoader({
    url,
    responseToItem,
  });

  return useItemToListLoader(state, count);
}
