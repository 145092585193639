import { useListLoader } from '../base/useListLoader';

function responseToItems(response) {
  const { spaces = {} } = response.data.included || {};

  const list = response.data.categories || [];
  const newList = list.map((cat) => {
    const newCat = { ...cat, id: Number(cat.id) };
    if (cat.spaces?.length) {
      // Attach on the full spaces object details, if included
      const newSpaces = cat.spaces.map((space) => {
        if (spaces[space.id]) {
          return { ...space, ...spaces[space.id] };
        }
        return space;
      });
      newCat.spaces = newSpaces;
    }
    return newCat;
  });
  return newList;
}

function order(list1, list2) {
  return list1.position - list2.position;
}

/**
 * Loads a list of task lists from the Teamwork v3 API.
 * @param {Object} options
 */
export function useSpacesV1CategoriesLoader({ params, count, pageSize = 50 }) {
  const { state } = useListLoader({
    url: '/spaces/api/v1/categories/assigned.json',
    params,
    count,
    responseToItems,
    order,
    pageSize,
  });

  return state;
}
