import { DateTime } from 'luxon';
import { useCheckoutV1Loader, useCurrentAccount, usePermissions } from '@/api';
import { useCheckoutStandardUserDefaults } from './useCheckoutStandardUserDefaults';

const PERUSER_TWONE_2020 = 43;

export function useCheckoutData() {
  const { isOwnerAdmin } = usePermissions();
  const checkoutState = isOwnerAdmin.value ? useCheckoutV1Loader() : useCheckoutStandardUserDefaults();
  const { item: checkoutData } = checkoutState;
  const account = useCurrentAccount();

  const isCheckoutReady = computed(() => {
    if (checkoutData.value) {
      return true;
    }

    return false;
  });

  const isStripeInvoice = computed(() => {
    const projectsData = checkoutData.value?.installation.apps.projects || {};
    return projectsData.paymentMethod === 'stripe' && projectsData.paymentCollectionMethod === 'send_invoice';
  });

  const isNonStandardBillingFrequency = computed(() => {
    const projectsData = checkoutData.value?.installation.apps.projects || {};
    return projectsData.billingPeriod === 'quarterly' || projectsData.billingPeriod === 'biannually';
  });

  const isStripeInvoiceSubValid = computed(() => {
    const projectsData = checkoutData.value?.installation.apps.projects || {};
    return (
      projectsData.paymentCollectionMethod === 'send_invoice' &&
      projectsData.stripeSubscriptionId &&
      projectsData.stripeSubscriptionId !== ''
    );
  });

  const isSelfServiceDisabled = computed(() => checkoutData.value?.selfServiceDisabled);

  const canAccessCheckout = computed(() => {
    // Block Teamwork One
    if (account.value.pricePlanId === PERUSER_TWONE_2020) {
      return false;
    }

    // Block Stripe V2
    if (isStripeInvoice.value && (isNonStandardBillingFrequency.value || checkoutData.value.isMultiProduct)) {
      return false;
    }

    // Block Self Service Disabled
    if (isSelfServiceDisabled.value) {
      return false;
    }

    // Block standard users
    if (!isOwnerAdmin.value) {
      return false;
    }

    return true;
  });

  const nextPlanUp = computed(() => {
    const currentPlan = checkoutData.value?.plans?.findIndex(
      (p) => p.id === account.value.pricePlanId || p.code.toLowerCase() === account.value.pricePlanCode.toLowerCase(),
    );
    return checkoutData.value?.plans?.[currentPlan + 1];
  });

  const higherPlans = computed(() => {
    const currentPlanIndex = checkoutData.value?.plans?.findIndex(
      (p) => p.id === account.value.pricePlanId || p.code.toLowerCase() === account.value.pricePlanCode.toLowerCase(),
    );
    return checkoutData.value?.plans?.slice(currentPlanIndex + 1);
  });

  function getPlanPricePerUserPerPeriod(period, plan) {
    const price =
      {
        monthly: plan?.priceMonthly,
        annually: plan?.priceAnnually,
      }[period] / 100;
    return price;
  }

  function getMonthlyPlanPrice(plan, period) {
    const data = checkoutData.value?.installation.apps.projects || {};
    const monthlyPriceBeforeDiscount = getPlanPricePerUserPerPeriod(period, plan) * data.paidUsers;
    return monthlyPriceBeforeDiscount;
  }

  function getPlanPricePerBillingPeriod(plan, period) {
    const monthlyPriceBeforeDiscount = getMonthlyPlanPrice(plan, period);
    const isAnnualBillingPeriod = period === 'annually' || false;

    const price = isAnnualBillingPeriod ? monthlyPriceBeforeDiscount * 12 : monthlyPriceBeforeDiscount;
    return price % 1 === 0 ? price : price.toFixed(2);
  }

  const addonsMappedById = computed(() => {
    return checkoutData.value?.addons?.reduce(
      (addons, addon) => ({
        ...addons,
        [addon.id]: addon,
      }),
      {},
    );
  });

  function getAddons(newUsers) {
    if (!checkoutData.value || checkoutData.value?.addons?.length === 0) {
      return [];
    }
    function getAddonsByNumSeats(addon) {
      const addonInfo = addonsMappedById.value?.[addon.addonId];
      return {
        code: addonInfo?.code,
        quantity: addonInfo?.kind === 'matchSeats' && addon.quantity > 0 ? newUsers : addon.quantity,
      };
    }
    return checkoutData.value?.installation?.apps?.projects?.addons.map(getAddonsByNumSeats);
  }

  const currencySymbol = computed(() => checkoutData.value?.currency.symbol);
  const currencyCode = computed(() => checkoutData.value?.currency.code);
  const discountCode = computed(() => checkoutData.value?.discount);
  const vatNumber = computed(() => checkoutData.value?.installation.vatNumber);

  const paidUntil = computed(() =>
    checkoutData.value
      ? DateTime.fromISO(checkoutData.value.installation.apps.projects.paidUntil)
      : account.value?.paidUntilDateTime,
  );

  const paymentCollectionMethod = computed(
    () => checkoutData.value?.installation.apps.projects.paymentCollectionMethod,
  );
  const isCancellationScheduled = computed(() =>
    isOwnerAdmin.value ? checkoutData.value?.cancellationScheduled : false,
  );

  const isMultiProduct = computed(() => checkoutData.value?.isMultiProduct);

  return {
    checkoutData,
    nextPlanUp,
    getPlanPricePerUserPerPeriod,
    getMonthlyPlanPrice,
    getPlanPricePerBillingPeriod,
    currencySymbol,
    currencyCode,
    discountCode,
    vatNumber,
    checkoutState,
    paidUntil,
    getAddons,
    paymentCollectionMethod,
    isCancellationScheduled,
    canAccessCheckout,
    isCheckoutReady,
    isStripeInvoiceSubValid,
    higherPlans,
    isSelfServiceDisabled,
    isMultiProduct,
  };
}
