const symbol = Symbol('useFormHelpLearnMoreModalDialog');

function formHelpLearnMoreModalDialog() {
  const isVisible = shallowRef(false);

  function close() {
    isVisible.value = false;
  }

  function open() {
    isVisible.value = true;
  }

  return {
    isVisible,
    close,
    open,
  };
}

export function provideFormHelpLearnMoreModalDialog() {
  const state = formHelpLearnMoreModalDialog();
  provide(symbol, state);
  return state;
}

export function useFormHelpLearnMoreModalDialog() {
  return inject(symbol);
}
