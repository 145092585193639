import { Icon } from '@iconify/vue/dist/offline';
import LsiAliases from '@teamwork/lightspeed-icons/vuetify-aliases';
import { createVuetify } from 'vuetify';
import * as defaults from './defaults.js';
import { DEFAULT_THEME, main } from './themes.js';

// https://next.vuetifyjs.com/en/getting-started/installation/#usage
export const vuetifyPlugin = createVuetify({
  defaults,
  icons: {
    sets: {
      lsi: {
        component: (props) =>
          h(Icon, {
            icon: props.icon,
            mode: 'svg',
          }),
      },
    },
    defaultSet: 'lsi',
    aliases: LsiAliases,
  },
  theme: {
    defaultTheme: DEFAULT_THEME,
    themes: {
      main,
    },
  },
});
