export const LscAlertVariants = /** @type {const} */ ([
  'info',
  'info-subdued',
  'success',
  'success-subdued',
  'warning',
  'warning-subdued',
  'critical',
  'critical-subdued',
]);

export const LscAlertLayouts = /** @type {const} */ (['banner', 'alert']);
