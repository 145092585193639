import { useCohort, useCurrentAccount, useExperimentA18, useExperimentA30 } from '@/api';
import { useI18n } from '@/util';
import { useLegacyBridge } from '@/module/legacy';
import OnboardingTourDataBillAccurately from './OnboardingTourDataBillAccurately.vue';
import OnboardingTourDataBillingClientsForWork from './OnboardingTourDataBillingClientsForWork.vue';
import OnboardingTourDataBoardBoostAutomate from './OnboardingTourDataBoardBoostAutomate.vue';
import OnboardingTourDataBoardBoostBoardTriggers from './OnboardingTourDataBoardBoostBoardTriggers.vue';
import OnboardingTourDataBoardBoostBoardTriggersIdeas from './OnboardingTourDataBoardBoostBoardTriggersIdeas.vue';
import OnboardingTourDataBudgetAtAGranularLevel from './OnboardingTourDataBudgetAtAGranularLevel.vue';
import OnboardingTourDataClientChampionGatherData from './OnboardingTourDataClientChampionGatherData.vue';
import OnboardingTourDataClientChampionIntakeForms from './OnboardingTourDataClientChampionIntakeForms.vue';
import OnboardingTourDataClientChampionProvideInsight from './OnboardingTourDataClientChampionProvideInsight.vue';
import OnboardingTourDataClientChampionTasks from './OnboardingTourDataClientChampionTasks.vue';
import OnboardingTourDataCollaborateWithYourClients from './OnboardingTourDataCollaborateWithYourClients.vue';
import OnboardingTourDataCollaborateWithYourTeam from './OnboardingTourDataCollaborateWithYourTeam.vue';
import OnboardingTourDataCreateAndManageProofs from './OnboardingTourDataCreateAndManageProofs.vue';
import OnboardingTourDataCreateYourFirstInvoice from './OnboardingTourDataCreateYourFirstInvoice.vue';
import OnboardingTourDataDataGuruPlanned from './OnboardingTourDataDataGuruPlanned.vue';
import OnboardingTourDataDataGuruProgress from './OnboardingTourDataDataGuruProgress.vue';
import OnboardingTourDataDataGuruReportsCenter from './OnboardingTourDataDataGuruReportsCenter.vue';
import OnboardingTourDataDontMissAThing from './OnboardingTourDataDontMissAThing.vue';
import OnboardingTourDataGatherProjectRequirements from './OnboardingTourDataGatherProjectRequirements.vue';
import OnboardingTourDataHomeSweetHomeCustomise from './OnboardingTourDataHomeSweetHomeCustomise.vue';
import OnboardingTourDataHomeSweetHomeMyWork from './OnboardingTourDataHomeSweetHomeMyWork.vue';
import OnboardingTourDataHomeSweetHomeWhatWork from './OnboardingTourDataHomeSweetHomeWhatWork.vue';
import OnboardingTourDataImportYourExistingWork from './OnboardingTourDataImportYourExistingWork.vue';
import OnboardingTourDataIntegrateApps from './OnboardingTourDataIntegrateApps.vue';
import OnboardingTourDataLeaveCommentsOnWork from './OnboardingTourDataLeaveCommentsOnWork.vue';
import OnboardingTourDataManageProjectsWithEase from './OnboardingTourDataManageProjectsWithEase.vue';
import OnboardingTourDataManageTeamCapacity from './OnboardingTourDataManageTeamCapacity.vue';
import OnboardingTourDataManageTeamResources from './OnboardingTourDataManageTeamResources.vue';
import OnboardingTourDataManageYourTimeLogs from './OnboardingTourDataManageYourTimeLogs.vue';
import OnboardingTourDataMasterPlannerTimeCapacity from './OnboardingTourDataMasterPlannerTimeCapacity.vue';
import OnboardingTourDataMasterPlannerTimeEstimates from './OnboardingTourDataMasterPlannerTimeEstimates.vue';
import OnboardingTourDataMasterPlannerTimeWorkload from './OnboardingTourDataMasterPlannerTimeWorkload.vue';
import OnboardingTourDataMaximizeTeamCapacity from './OnboardingTourDataMaximizeTeamCapacity.vue';
import OnboardingTourDataMaximizeYourTime from './OnboardingTourDataMaximizeYourTime.vue';
import OnboardingTourDataMonitorWorkFromAClientLevel from './OnboardingTourDataMonitorWorkFromAClientLevel.vue';
import OnboardingTourDataMyTimesheets from './OnboardingTourDataMyTimesheets.vue';
import OnboardingTourDataMyTimesheetsNew from './OnboardingTourDataMyTimesheetsNew.vue';
import OnboardingTourDataMyWork from './OnboardingTourDataMyWork.vue';
import OnboardingTourDataNeverMissAPayment from './OnboardingTourDataNeverMissAPayment.vue';
import OnboardingTourDataPlanTeamsWorkload from './OnboardingTourDataPlanTeamsWorkload.vue';
import OnboardingTourDataProgressAtAGlance from './OnboardingTourDataProgressAtAGlance.vue';
import OnboardingTourDataProjectBoostCustomTemplate from './OnboardingTourDataProjectBoostCustomTemplate.vue';
import OnboardingTourDataProjectBoostLeverageTemplate from './OnboardingTourDataProjectBoostLeverageTemplate.vue';
import OnboardingTourDataProjectBoostTemplates from './OnboardingTourDataProjectBoostTemplates.vue';
import OnboardingTourDataReportOnWhatMatters from './OnboardingTourDataReportOnWhatMatters.vue';
import OnboardingTourDataResourceManagementIntro from './OnboardingTourDataResourceManagementIntro.vue';
import OnboardingTourDataResourceManagementProblem from './OnboardingTourDataResourceManagementProblem.vue';
import OnboardingTourDataResourceManagementProblemCongrats from './OnboardingTourDataResourceManagementProblemCongrats.vue';
import OnboardingTourDataResourceManagementScheduleIntro from './OnboardingTourDataResourceManagementScheduleIntro.vue';
import OnboardingTourDataResourceManagementScheduleWhat from './OnboardingTourDataResourceManagementScheduleWhat.vue';
import OnboardingTourDataResourceManagementScheduleWhy from './OnboardingTourDataResourceManagementScheduleWhy.vue';
import OnboardingTourDataRetainers from './OnboardingTourDataRetainers.vue';
import OnboardingTourDataSetBillableRates from './OnboardingTourDataSetBillableRates.vue';
import OnboardingTourDataSetHourlyRatesForYourTeam from './OnboardingTourDataSetHourlyRatesForYourTeam.vue';
import OnboardingTourDataStandardizeProjectCreation from './OnboardingTourDataStandardizeProjectCreation.vue';
import OnboardingTourDataStayOnTopOfYourWork from './OnboardingTourDataStayOnTopOfYourWork.vue';
import OnboardingTourDataStoreFilesInOnePlace from './OnboardingTourDataStoreFilesInOnePlace.vue';
import OnboardingTourDataStreamlineYourClientReviews from './OnboardingTourDataStreamlineYourClientReviews.vue';
import OnboardingTourDataTaskBoostExistingTemplates from './OnboardingTourDataTaskBoostExistingTemplates.vue';
import OnboardingTourDataTaskBoostHints from './OnboardingTourDataTaskBoostHints.vue';
import OnboardingTourDataTaskBoostManageTemplates from './OnboardingTourDataTaskBoostManageTemplates.vue';
import OnboardingTourDataTaskBoostTemplates from './OnboardingTourDataTaskBoostTemplates.vue';
import OnboardingTourDataTaskBoostUsingTemplates from './OnboardingTourDataTaskBoostUsingTemplates.vue';
import OnboardingTourDataTaskListBudgets from './OnboardingTourDataTaskListBudgets.vue';
import OnboardingTourDataTaskmasterHints from './OnboardingTourDataTaskmasterHints.vue';
import OnboardingTourDataTaskmasterProjects from './OnboardingTourDataTaskmasterProjects.vue';
import OnboardingTourDataTaskmasterTasklists from './OnboardingTourDataTaskmasterTasklists.vue';
import OnboardingTourDataTaskmasterTasks from './OnboardingTourDataTaskmasterTasks.vue';
import OnboardingTourDataTeamPlayerComments from './OnboardingTourDataTeamPlayerComments.vue';
import OnboardingTourDataTeamPlayerInvites from './OnboardingTourDataTeamPlayerInvites.vue';
import OnboardingTourDataTeamPlayerMessages from './OnboardingTourDataTeamPlayerMessages.vue';
import OnboardingTourDataTeamPlayerNotifications from './OnboardingTourDataTeamPlayerNotifications.vue';
import OnboardingTourDataTeamPlayerUserPermissions from './OnboardingTourDataTeamPlayerUserPermissions.vue';
import OnboardingTourDataTheAccountantBillableRates from './OnboardingTourDataTheAccountantBillableRates.vue';
import OnboardingTourDataTheAccountantBillAccurately from './OnboardingTourDataTheAccountantBillAccurately.vue';
import OnboardingTourDataTheAccountantProfitability from './OnboardingTourDataTheAccountantProfitability.vue';
import OnboardingTourDataTheAccountantTrackBillable from './OnboardingTourDataTheAccountantTrackBillable.vue';
import OnboardingTourDataTheAccountantUserRates from './OnboardingTourDataTheAccountantUserRates.vue';
import OnboardingTourDataTimeKeeperAllTime from './OnboardingTourDataTimeKeeperAllTime.vue';
import OnboardingTourDataTimeKeeperEstimateTime from './OnboardingTourDataTimeKeeperEstimateTime.vue';
import OnboardingTourDataTimeKeeperMyTimesheet from './OnboardingTourDataTimeKeeperMyTimesheet.vue';
import OnboardingTourDataTimeKeeperProjectTimeBudgets from './OnboardingTourDataTimeKeeperProjectTimeBudgets.vue';
import OnboardingTourDataTimeKeeperTimer from './OnboardingTourDataTimeKeeperTimer.vue';
import OnboardingTourDataTimeKeeperTimeTracking from './OnboardingTourDataTimeKeeperTimeTracking.vue';
import OnboardingTourDataTrackBillableMinutes from './OnboardingTourDataTrackBillableMinutes.vue';
import OnboardingTourDataTrackBillableTime from './OnboardingTourDataTrackBillableTime.vue';
import OnboardingTourDataTrackEveryBillableMinute from './OnboardingTourDataTrackEveryBillableMinute.vue';
import OnboardingTourDataTrackEveryBillableMinuteBillingClients from './OnboardingTourDataTrackEveryBillableMinuteBillingClients.vue';
import OnboardingTourDataTrackTeamPerformance from './OnboardingTourDataTrackTeamPerformance.vue';
import OnboardingTourDataTrackYourTeamsTime from './OnboardingTourDataTrackYourTeamsTime.vue';
import OnboardingTourDataTrackYourTime from './OnboardingTourDataTrackYourTime.vue';
import OnboardingTourDataWorkflowWizardArrange from './OnboardingTourDataWorkflowWizardArrange.vue';
import OnboardingTourDataWorkflowWizardBacklog from './OnboardingTourDataWorkflowWizardBacklog.vue';
import OnboardingTourDataWorkflowWizardConnecting from './OnboardingTourDataWorkflowWizardConnecting.vue';
import OnboardingTourDataWorkflowWizardHelpfulHints from './OnboardingTourDataWorkflowWizardHelpfulHints.vue';
import OnboardingTourDataWorkflowWizardNavigation from './OnboardingTourDataWorkflowWizardNavigation.vue';
import OnboardingTourDataWorkflowWizardTasks from './OnboardingTourDataWorkflowWizardTasks.vue';
import OnboardingTourDataWorkflowWizardUnderstandingBoards from './OnboardingTourDataWorkflowWizardUnderstandingBoards.vue';
import OnboardingTourDataWorkingWithClients from './OnboardingTourDataWorkingWithClients.vue';
import OnboardingTourDataWorkWithinBudget from './OnboardingTourDataWorkWithinBudget.vue';

export function useOnboardingTourData() {
  const { t } = useI18n();
  const account = useCurrentAccount();
  const { isPaid, isPaymentOverdue, isClientUser, isCollaborator, isSiteAdmin } = useCohort();
  const { legacyIframeEl } = useLegacyBridge();
  const { isExpA30Variation } = useExperimentA30();
  const { isExpA18Variation } = useExperimentA18();

  const isICPOnboarding = computed(
    () =>
      ((isPaid.value || isPaymentOverdue.value) && !isClientUser.value && !isCollaborator.value && isSiteAdmin.value) ||
      isExpA30Variation.value,
  );

  const workingWithClientsStepRoute = computed(() => {
    return account.value.useClientView ? '/clients' : '/companies';
  });

  const onboardingTourData = {
    'createProjectTour': {
      id: 1,
      name: 'create-project',
      description: t('Create a project'),
      type: 'addProject',
      repeatable: false,
    },
    'createTasksTour': {
      id: 2,
      name: 'create-tasks',
      description: t('Create tasks'),
      type: 'addTask',
      repeatable: false,
    },
    'trackingBillableTime': {
      id: 3,
      name: 'tracking-billable-time',
      description: t('Explore tracking billable time'),
      type: 'productTour',
      repeatable: true,
      steps: [
        {
          name: t('Track billable time'),
          description: OnboardingTourDataTrackBillableTime,
          image: {
            normal: 'https://cdn-projects.teamwork.com/tko/public/images/product-tours/track-billable-time.svg',
          },
          routeInProject: true,
          matchIncludes: true,
        },
        {
          name: t('Work within a budget'),
          description: OnboardingTourDataWorkWithinBudget,
          image: {
            normal: 'https://cdn-projects.teamwork.com/tko/public/images/product-tours/work-within-budget.svg',
          },
          routeInProject: !isICPOnboarding.value,
          stepRoute: isICPOnboarding.value ? '/budgets' : '/finance/budgets',
          matchIncludes: true,
        },
        {
          name: t('Set billable rates'),
          description: OnboardingTourDataSetBillableRates,
          image: {
            normal: 'https://cdn-projects.teamwork.com/tko/public/images/product-tours/set-billable-rates.svg ',
          },
          routeInProject: !isICPOnboarding.value,
          stepRoute: isICPOnboarding.value ? '/budgets' : '/finance/billing/open',
          matchIncludes: true,
        },
        {
          name: t('Track every billable minute'),
          description: OnboardingTourDataTrackEveryBillableMinute,
          image: {
            normal: 'https://cdn-projects.teamwork.com/tko/public/images/product-tours/track-every-billable-minute.svg',
          },
          routeInProject: false,
          stepRoute: '/time/all',
          matchIncludes: true,
        },
        ...(isICPOnboarding.value
          ? [
              {
                name: t('Manage your time logs'),
                description: OnboardingTourDataManageYourTimeLogs,
                image: {
                  normal: 'https://cdn-projects.teamwork.com/tko/public/images/product-tours/manage-your-time-logs.svg',
                },
                routeInProject: false,
                stepRoute: '/time/user',
                matchIncludes: true,
              },
              {
                name: t('Budget at a granular level'),
                description: OnboardingTourDataBudgetAtAGranularLevel,
                image: {
                  normal:
                    'https://cdn-projects.teamwork.com/tko/public/images/product-tours/budget-at-a-granular-level.svg',
                },
                routeInProject: true,
                stepRoute: '/finance/budgets',
                matchIncludes: true,
              },
            ]
          : []),
      ],
    },
    'managingTeamCapacityTour': {
      id: 4,
      name: 'manage-capacity',
      description: t('Explore managing team capacity'),
      type: 'productTour',
      repeatable: true,
      steps: [
        {
          name: t('Manage team capacity'),
          description: OnboardingTourDataManageTeamCapacity,
          routeInProject: true,
          matchIncludes: true,
          image: {
            normal: 'https://cdn-projects.teamwork.com/tko/public/images/product-tours/manage-team-capacity.svg',
          },
        },
        {
          name: t("Plan your team's workload"),
          description: OnboardingTourDataPlanTeamsWorkload,
          routeInProject: false,
          stepRoute: '/planning/workload',
          matchIncludes: true,
          image: {
            normal: 'https://cdn-projects.teamwork.com/tko/public/images/product-tours/plan-your-teams-workload.svg ',
          },
        },
        {
          name: t('Maximize team capacity'),
          description: OnboardingTourDataMaximizeTeamCapacity,
          routeInProject: false,
          stepRoute: '/reports/utilization',
          matchIncludes: true,
          image: {
            normal: 'https://cdn-projects.teamwork.com/tko/public/images/product-tours/maximise-team-capacity.svg',
          },
        },
        ...(isICPOnboarding.value
          ? [
              {
                name: t('Manage team resources'),
                description: OnboardingTourDataManageTeamResources,
                routeInProject: false,
                stepRoute: '/planning/schedule/projects',
                matchIncludes: true,
                image: {
                  normal:
                    'https://cdn-projects.teamwork.com/tko/public/images/product-tours/manage-team-resources.svg ',
                },
              },
              {
                name: t("Track your team's time"),
                description: OnboardingTourDataTrackYourTeamsTime,
                routeInProject: false,
                stepRoute: '/time/all',
                matchIncludes: true,
                image: {
                  normal: 'https://cdn-projects.teamwork.com/tko/public/images/product-tours/track-your-teams-time.svg',
                },
              },
            ]
          : []),
      ],
    },
    'reportingProjectHealthUtilization': {
      id: 5,
      name: 'reporting-project-health-utilization',
      description: t('Explore reporting'),
      type: 'productTour',
      repeatable: true,
      steps: [
        {
          name: t('Track team performance'),
          description: OnboardingTourDataTrackTeamPerformance,
          routeInProject: true,
          matchIncludes: true,
          image: {
            normal: 'https://cdn-projects.teamwork.com/tko/public/images/product-tours/track-team-performance.svg ',
          },
        },
        {
          name: t('Progress at a glance'),
          description: OnboardingTourDataProgressAtAGlance,
          routeInProject: false,
          stepRoute: '/reports/health',
          matchIncludes: true,
          image: {
            normal: 'https://cdn-projects.teamwork.com/tko/public/images/product-tours/progress-at-glance.svg',
          },
        },
        {
          name: t('Maximize team capacity'),
          description: OnboardingTourDataMaximizeTeamCapacity,
          routeInProject: false,
          stepRoute: '/reports/utilization',
          matchIncludes: true,
          image: {
            normal: 'https://cdn-projects.teamwork.com/tko/public/images/product-tours/maximise-team-capacity.svg',
          },
        },
        ...(isICPOnboarding.value
          ? [
              {
                name: t('Report on what matters'),
                description: OnboardingTourDataReportOnWhatMatters,
                routeInProject: false,
                stepRoute: '/reports/gallery',
                matchIncludes: true,
                image: {
                  normal:
                    'https://cdn-projects.teamwork.com/tko/public/images/product-tours/report-on-what-matters.svg',
                },
              },
            ]
          : []),
      ],
    },
    'managingProjectsTour': {
      id: 6,
      name: 'managing-projects',
      description: t('Explore managing projects'),
      type: 'productTour',
      repeatable: true,
      steps: [
        {
          name: t('Manage projects with ease'),
          description: OnboardingTourDataManageProjectsWithEase,
          routeInProject: true,
          matchIncludes: true,
          image: {
            normal: 'https://cdn-projects.teamwork.com/tko/public/images/product-tours/manage-projects-with-ease.svg ',
          },
        },
        {
          name: t('Progress at a glance'),
          description: OnboardingTourDataProgressAtAGlance,
          routeInProject: false,
          stepRoute: '/reports/health',
          matchIncludes: true,
          image: {
            normal: 'https://cdn-projects.teamwork.com/tko/public/images/product-tours/progress-at-glance.svg ',
          },
        },
        {
          name: t("Plan your team's workload"),
          description: OnboardingTourDataPlanTeamsWorkload,
          routeInProject: false,
          stepRoute: '/planning/workload',
          matchIncludes: true,
          image: {
            normal: 'https://cdn-projects.teamwork.com/tko/public/images/product-tours/allocate-your-resources.svg',
          },
        },
        {
          name: t('Maximize team capacity'),
          description: OnboardingTourDataMaximizeTeamCapacity,
          routeInProject: false,
          stepRoute: '/reports/utilization',
          matchIncludes: true,
          image: {
            normal: 'https://cdn-projects.teamwork.com/tko/public/images/product-tours/maximise-team-capacity.svg',
          },
        },
        {
          name: t('Standardize project creation'),
          description: OnboardingTourDataStandardizeProjectCreation,
          routeInProject: false,
          stepRoute: isICPOnboarding.value
            ? '/projects/templates/projects/teamwork'
            : '/projects/templates/projects/custom',
          matchIncludes: true,
          image: {
            normal: 'https://cdn-projects.teamwork.com/tko/public/images/product-tours/create-instant-projects.svg ',
          },
        },
      ],
    },
    'inviteTeamTour': {
      id: 7,
      name: 'invite-team',
      description: t('Invite your team'),
      type: 'addUser',
      repeatable: true,
    },
    'importData': {
      id: 8,
      name: 'import-data',
      description: t('Import your data'),
      type: 'import',
      repeatable: true,
      steps: [
        {
          name: t('Import your existing work'),
          description: OnboardingTourDataImportYourExistingWork,
          routeInProject: false,
          stepRoute: '/settings/import',
          matchIncludes: true,
          image: {
            normal: 'https://cdn-projects.teamwork.com/tko/public/images/product-tours/import-existing-work.svg',
          },
        },
      ],
    },
    'integrateApps': {
      id: 9,
      name: 'integrate-apps',
      description: t('Integrate with the apps you love'),
      type: 'integrate',
      repeatable: true,
      steps: [
        {
          name: t('Integrate the apps you love'),
          description: OnboardingTourDataIntegrateApps,
          routeInProject: false,
          stepRoute: '/settings/integrations',
          matchIncludes: true,
          image: {
            normal: 'https://cdn-projects.teamwork.com/tko/public/images/product-tours/integrate-apps-you-love.svg ',
          },
        },
      ],
    },
    'billingClientsForWork': {
      id: 10,
      name: 'billing-clients-for-work',
      description: t('Explore billing clients for work'),
      type: 'productTour',
      repeatable: true,
      steps: [
        {
          name: t('Never miss a payment'),
          description: OnboardingTourDataNeverMissAPayment,
          routeInProject: true,
          matchIncludes: true,
          image: {
            normal: 'https://cdn-projects.teamwork.com/tko/public/images/product-tours/never-miss-payment.svg ',
          },
        },
        ...(isICPOnboarding.value
          ? [
              {
                name: t('Set billable rates'),
                description: OnboardingTourDataSetBillableRates,
                image: {
                  normal: 'https://cdn-projects.teamwork.com/tko/public/images/product-tours/set-billable-rates.svg ',
                },
                routeInProject: false,
                stepRoute: '/budgets',
                matchIncludes: true,
              },
              {
                name: t('Retainers'),
                description: OnboardingTourDataRetainers,
                image: {
                  normal: 'https://cdn-projects.teamwork.com/tko/public/images/product-tours/retainers.svg',
                },
                routeInProject: false,
                stepRoute: '/budgets',
                matchIncludes: true,
              },
              {
                name: t('Task list budgets'),
                description: OnboardingTourDataTaskListBudgets,
                image: {
                  normal: 'https://cdn-projects.teamwork.com/tko/public/images/product-tours/task-list-budgets.svg',
                },
                routeInProject: true,
                stepRoute: '/finance/budgets',
                matchIncludes: true,
              },
            ]
          : []),
        {
          name: t('Track every billable minute'),
          description: OnboardingTourDataTrackEveryBillableMinuteBillingClients,
          image: {
            normal: 'https://cdn-projects.teamwork.com/tko/public/images/product-tours/track-every-billable-minute.svg',
          },
          routeInProject: false,
          stepRoute: '/time/all',
          matchIncludes: true,
        },
        {
          name: isICPOnboarding.value ? t('My timesheets') : t('Set billable rates'),
          description: isICPOnboarding.value ? OnboardingTourDataMyTimesheetsNew : OnboardingTourDataSetBillableRates,
          image: {
            normal: isICPOnboarding.value
              ? 'https://cdn-projects.teamwork.com/tko/public/images/product-tours/my-timesheet.svg'
              : 'https://cdn-projects.teamwork.com/tko/public/images/product-tours/set-billable-rates.svg ',
          },
          routeInProject: !isICPOnboarding.value,
          stepRoute: isICPOnboarding.value ? '/time/user' : '/finance/billing/open',
          matchIncludes: true,
        },
        {
          name: t('Bill accurately'),
          description: OnboardingTourDataBillAccurately,
          image: {
            normal: 'https://cdn-projects.teamwork.com/tko/public/images/product-tours/bill-accurately.svg',
          },
          routeInProject: true,
          stepRoute: '/finance/billing/open',
          matchIncludes: true,
        },
      ],
    },
    'workingWithClients': {
      id: 11,
      name: 'working-with-clients',
      description: t('Explore working with clients'),
      type: 'productTour',
      repeatable: true,
      steps: [
        {
          name: t('Collaborate with your clients'),
          description: OnboardingTourDataCollaborateWithYourClients,
          routeInProject: true,
          matchIncludes: true,
          image: {
            normal:
              'https://cdn-projects.teamwork.com/tko/public/images/product-tours/collaborate-with-your-clients.svg ',
          },
        },
        {
          name: t('Working with clients'),
          description: OnboardingTourDataWorkingWithClients,
          image: {
            normal: 'https://cdn-projects.teamwork.com/tko/public/images/product-tours/collaborate-with-clients.svg',
          },
          routeInProject: false,
          stepRoute: workingWithClientsStepRoute.value,
          matchIncludes: true,
        },
        {
          name: t('Gather project requirements'),
          description: OnboardingTourDataGatherProjectRequirements,
          image: {
            normal:
              'https://cdn-projects.teamwork.com/tko/public/images/product-tours/gather-project-requirements.svg ',
          },
          routeInProject: true,
          stepRoute: '/forms',
          matchIncludes: true,
        },
        {
          name: t('Maximize your time'),
          description: OnboardingTourDataMaximizeYourTime,
          routeInProject: true,
          stepRoute: '/tasks/table',
          matchIncludes: true,
          image: {
            normal: 'https://cdn-projects.teamwork.com/tko/public/images/product-tours/maximize-your-time.svg',
          },
        },
        ...(isICPOnboarding.value
          ? [
              {
                name: t('Retainers'),
                description: OnboardingTourDataRetainers,
                routeInProject: false,
                stepRoute: '/budgets',
                matchIncludes: false,
                image: {
                  normal: 'https://cdn-projects.teamwork.com/tko/public/images/product-tours/retainers.svg',
                },
              },
              {
                name: t('Monitor work from a client level'),
                description: OnboardingTourDataMonitorWorkFromAClientLevel,
                routeInProject: false,
                stepRoute: account.value.useClientView ? '/clients' : '/companies',
                matchIncludes: true,
                image: {
                  normal:
                    'https://cdn-projects.teamwork.com/tko/public/images/product-tours/monitor-work-from-client-level.svg',
                },
              },
              {
                name: t('Streamline your client reviews'),
                description: OnboardingTourDataStreamlineYourClientReviews,
                routeInProject: false,
                stepRoute: '/proofs',
                matchIncludes: true,
                image: {
                  normal:
                    'https://cdn-projects.teamwork.com/tko/public/images/product-tours/streamline-client-reviews.svg',
                },
              },
            ]
          : []),
      ],
    },
    'board-boost': {
      id: 12,
      name: 'board-boost',
      description: t('Learn about boards'),
      type: 'badgeTour',
      badgeId: 12,
      repeatable: true,
      steps: [
        {
          name: t('Automate your workflow'),
          description: OnboardingTourDataBoardBoostAutomate,
          routeInProject: true,
          matchIncludes: true,
          stepRoute: '/tasks/board',
          image: {
            normal: 'https://twa-prod.teamwork.com/tko/public/images/badge-tour/board-boost-automate.png',
          },
        },
        {
          name: t('Board automations'),
          description: OnboardingTourDataBoardBoostBoardTriggers,
          routeInProject: true,
          matchIncludes: true,
          stepRoute: '/tasks/board',
          image: {
            normal: 'https://twa-prod.teamwork.com/tko/public/images/badge-tour/board-boost-automations.png',
          },
        },
        {
          name: t('Ideas for automations'),
          description: OnboardingTourDataBoardBoostBoardTriggersIdeas,
          routeInProject: true,
          matchIncludes: true,
          stepRoute: '/tasks/board',
          image: {
            normal: 'https://twa-prod.teamwork.com/tko/public/images/badge-tour/board-boost-ideas.png',
          },
        },
      ],
    },
    'taskmaster': {
      id: 13,
      name: 'taskmaster',
      description: t('Explore tasks'),
      type: 'badgeTour',
      badgeId: 3,
      repeatable: true,
      steps: [
        {
          name: t('Projects'),
          description: OnboardingTourDataTaskmasterProjects,
          routeInProject: false,
          matchIncludes: true,
          stepRoute: '/projects/list',
          image: {
            normal: 'https://twa-prod.teamwork.com/tko/public/images/badge-tour/taskmaster-projects.png ',
          },
        },
        {
          name: t('Tasks'),
          description: OnboardingTourDataTaskmasterTasks,
          routeInProject: true,
          matchIncludes: true,
          stepRoute: '/tasks/list',
          image: {
            normal: 'https://twa-prod.teamwork.com/tko/public/images/badge-tour/taskmaster-tasks.png ',
          },
        },
        {
          name: t('Tasklists'),
          description: OnboardingTourDataTaskmasterTasklists,
          routeInProject: true,
          matchIncludes: true,
          stepRoute: '/tasks/list',
          image: {
            normal: 'https://twa-prod.teamwork.com/tko/public/images/badge-tour/taskmaster-tasklists.png',
          },
        },
        {
          name: t('Hints'),
          description: OnboardingTourDataTaskmasterHints,
          routeInProject: true,
          matchIncludes: true,
          stepRoute: '/tasks/list',
          image: {
            normal: 'https://twa-prod.teamwork.com/tko/public/images/badge-tour/taskmaster-hints.png',
          },
        },
      ],
    },
    'time-keeper': {
      id: 14,
      name: 'time-keeper',
      description: t('Time keeping'),
      type: 'badgeTour',
      badgeId: 5,
      repeatable: true,
      steps: [
        {
          name: t('Time tracking'),
          description: OnboardingTourDataTimeKeeperTimeTracking,
          routeInProject: false,
          matchIncludes: true,
          stepRoute: '/home/work',
          image: {
            normal: 'https://twa-prod.teamwork.com/tko/public/images/badge-tour/time-keeper-time-tracking.png',
          },
        },
        {
          name: t('Estimate time'),
          description: OnboardingTourDataTimeKeeperEstimateTime,
          routeInProject: false,
          matchIncludes: true,
          stepRoute: '/home/work',
          image: {
            normal: 'https://twa-prod.teamwork.com/tko/public/images/badge-tour/time-keeper-estimate-time.gif',
          },
        },
        {
          name: t('Project time budgets'),
          description: OnboardingTourDataTimeKeeperProjectTimeBudgets,
          routeInProject: true,
          matchIncludes: true,
          stepRoute: '/finance/budgets',
          image: {
            normal: 'https://twa-prod.teamwork.com/tko/public/images/badge-tour/time-keeper-project-time-budgets.png',
          },
        },
        {
          name: t('Timer'),
          description: OnboardingTourDataTimeKeeperTimer,
          matchIncludes: true,
          stepRoute: '/time/all',
          image: {
            normal: 'https://twa-prod.teamwork.com/tko/public/images/badge-tour/time-keeper-timer.gif',
          },
        },
        {
          name: t('All time'),
          description: OnboardingTourDataTimeKeeperAllTime,
          matchIncludes: true,
          stepRoute: '/time/all',
          image: {
            normal: 'https://twa-prod.teamwork.com/tko/public/images/badge-tour/time-keeper-all-time.png',
          },
        },
        {
          name: t('My timesheet'),
          description: OnboardingTourDataTimeKeeperMyTimesheet,
          matchIncludes: true,
          stepRoute: '/time/user',
          image: {
            normal: 'https://twa-prod.teamwork.com/tko/public/images/badge-tour/time-keeper-my-timesheet.png',
          },
        },
      ],
    },
    'home-sweet-home': {
      id: 15,
      name: 'home-sweet-home',
      description: t('Home sweet home'),
      type: 'badgeTour',
      badgeId: 6,
      repeatable: true,
      steps: [
        {
          name: t('What is My Work?'),
          description: OnboardingTourDataHomeSweetHomeMyWork,
          routeInProject: false,
          matchIncludes: true,
          stepRoute: '/home/work',
          image: {
            normal: 'https://twa-prod.teamwork.com/tko/public/images/badge-tour/home-sweet-home-my-work.png',
          },
        },
        {
          name: t('Deciding what to work on'),
          description: OnboardingTourDataHomeSweetHomeWhatWork,
          routeInProject: false,
          matchIncludes: true,
          stepRoute: '/home/work',
          image: {
            normal: 'https://twa-prod.teamwork.com/tko/public/images/badge-tour/home-sweet-home-what-work.png',
          },
        },
        {
          name: t('Customize My Work'),
          description: OnboardingTourDataHomeSweetHomeCustomise,
          routeInProject: false,
          matchIncludes: true,
          stepRoute: '/home/work',
          image: {
            normal: 'https://twa-prod.teamwork.com/tko/public/images/badge-tour/home-sweet-home-customise.png',
          },
        },
      ],
    },
    'master-planner': {
      id: 16,
      name: 'master-planner',
      description: t('Master Planner'),
      type: 'badgeTour',
      badgeId: 14,
      repeatable: true,
      steps: [
        {
          name: t('Time estimates'),
          description: OnboardingTourDataMasterPlannerTimeEstimates,
          matchIncludes: true,
          stepRoute: '/home/work',
          image: {
            normal: 'https://twa-prod.teamwork.com/tko/public/images/badge-tour/master-planner-time-estimates.png',
          },
        },
        {
          name: t("Plan your team's workload"),
          description: OnboardingTourDataMasterPlannerTimeWorkload,
          matchIncludes: true,
          stepRoute: '/planning/workload',
          image: {
            normal: 'https://twa-prod.teamwork.com/tko/public/images/badge-tour/master-planner-workload.png',
          },
        },
        {
          name: t('Manage team capacity'),
          description: OnboardingTourDataMasterPlannerTimeCapacity,
          matchIncludes: true,
          stepRoute: '/reports/utilization',
          image: {
            normal: 'https://twa-prod.teamwork.com/tko/public/images/badge-tour/master-planner-capacity.png',
          },
        },
      ],
    },
    'task-boost': {
      id: 17,
      name: 'task-boost',
      description: t('Task Boost'),
      type: 'badgeTour',
      badgeId: 11,
      repeatable: true,
      steps: [
        {
          name: t('Convert task lists into templates'),
          description: OnboardingTourDataTaskBoostTemplates,
          routeInProject: true,
          matchIncludes: true,
          stepRoute: '/tasks/list',
          image: {
            normal: 'https://twa-prod.teamwork.com/tko/public/images/badge-tour/task-boost-templates.png',
          },
        },
        {
          name: t('Manage task list templates'),
          description: OnboardingTourDataTaskBoostManageTemplates,
          matchIncludes: true,
          image: {
            normal: 'https://twa-prod.teamwork.com/tko/public/images/badge-tour/task-boost-manage-templates.gif',
          },
        },
        {
          name: t('Using task list templates'),
          description: OnboardingTourDataTaskBoostUsingTemplates,
          matchIncludes: true,
          image: {
            normal: 'https://twa-prod.teamwork.com/tko/public/images/badge-tour/task-boost-using-templates.gif',
          },
        },
        {
          name: t('Helpful hints'),
          description: OnboardingTourDataTaskBoostHints,
          matchIncludes: true,
          image: {
            normal: null,
          },
        },
        {
          name: t('Add tasks to existing task list from a template'),
          description: OnboardingTourDataTaskBoostExistingTemplates,
          matchIncludes: true,
          image: {
            normal: 'https://twa-prod.teamwork.com/tko/public/images/badge-tour/task-boost-exisiting-templates.gif',
          },
        },
      ],
    },
    'project-boost': {
      id: 18,
      name: 'project-boost',
      description: t('Project Boost'),
      type: 'badgeTour',
      badgeId: 10,
      repeatable: true,
      steps: [
        {
          name: t('Project Templates'),
          description: OnboardingTourDataProjectBoostTemplates,
          routeInProject: false,
          matchIncludes: true,
          stepRoute: '/projects/templates/projects/teamwork',
          image: {
            normal: 'https://twa-prod.teamwork.com/tko/public/images/badge-tour/project-boost-templates.png',
          },
        },
        {
          name: t('Leverage {teamwork} Templates', { teamwork: 'Teamwork.com' }),
          description: OnboardingTourDataProjectBoostLeverageTemplate,
          matchIncludes: true,
          image: {
            normal: 'https://twa-prod.teamwork.com/tko/public/images/badge-tour/project-boost-leverage-template.png',
          },
        },
        {
          name: t('Create a custom template'),
          description: OnboardingTourDataProjectBoostCustomTemplate,
          matchIncludes: true,
          image: {
            normal: 'https://twa-prod.teamwork.com/tko/public/images/badge-tour/project-boost-custom-template.png',
          },
        },
      ],
    },
    'data-guru': {
      id: 19,
      name: 'data-guru',
      description: t('Data Guru'),
      type: 'badgeTour',
      badgeId: 13,
      repeatable: true,
      steps: [
        {
          name: t('Reports center'),
          description: OnboardingTourDataDataGuruReportsCenter,
          matchIncludes: true,
          stepRoute: '/reports/gallery/',
          image: {
            normal: 'https://twa-prod.teamwork.com/tko/public/images/badge-tour/data-guru-reports-center.png',
          },
        },
        {
          name: t('Progress at a glance'),
          description: OnboardingTourDataDataGuruProgress,
          routeInProject: false,
          matchIncludes: true,
          stepRoute: '/reports/health',
          image: {
            normal: 'https://twa-prod.teamwork.com/tko/public/images/badge-tour/data-guru-progress.png',
          },
        },
        {
          name: t('Planned vs Actual report'),
          description: OnboardingTourDataDataGuruPlanned,
          routeInProject: false,
          matchIncludes: true,
          stepRoute: '/reports/plannedvsactual/tasks',
          image: {
            normal: 'https://twa-prod.teamwork.com/tko/public/images/badge-tour/data-guru-planned.png',
          },
        },
      ],
    },
    'workflow-wizard': {
      id: 20,
      name: 'workflow-wizard',
      description: t('Workload Wizard'),
      type: 'badgeTour',
      badgeId: 4,
      repeatable: true,
      steps: [
        {
          name: t('Understanding Boards'),
          description: OnboardingTourDataWorkflowWizardUnderstandingBoards,
          routeInProject: true,
          matchIncludes: true,
          stepRoute: '/tasks/board',
          image: {
            normal:
              'https://twa-prod.teamwork.com/tko/public/images/badge-tour/workflow-wizard-understanding-boards.png',
          },
        },
        {
          name: t('Navigating the view'),
          description: OnboardingTourDataWorkflowWizardNavigation,
          routeInProject: true,
          matchIncludes: true,
          stepRoute: '/tasks/board',
          image: {
            normal: 'https://twa-prod.teamwork.com/tko/public/images/badge-tour/workflow-wizard-navigating.png',
          },
        },
        {
          name: t('Tasks'),
          description: OnboardingTourDataWorkflowWizardTasks,
          routeInProject: true,
          matchIncludes: true,
          stepRoute: '/tasks/board',
          image: {
            normal: 'https://twa-prod.teamwork.com/tko/public/images/badge-tour/workflow-wizard-tasks.png',
          },
        },
        {
          name: t('Edit and arrange the board'),
          description: OnboardingTourDataWorkflowWizardArrange,
          routeInProject: true,
          matchIncludes: true,
          stepRoute: '/tasks/board',
          image: {
            normal: 'https://twa-prod.teamwork.com/tko/public/images/badge-tour/workflow-wizard-arrange.gif',
          },
        },
        {
          name: t('Backlogged tasks'),
          description: OnboardingTourDataWorkflowWizardBacklog,
          routeInProject: true,
          matchIncludes: true,
          stepRoute: '/tasks/board',
          image: {
            normal: 'https://twa-prod.teamwork.com/tko/public/images/badge-tour/workflow-wizard-backlog.gif',
          },
        },
        {
          name: t('Connecting Boards with My Work'),
          description: OnboardingTourDataWorkflowWizardConnecting,
          routeInProject: true,
          matchIncludes: true,
          stepRoute: '/tasks/board',
          image: {
            normal: 'https://twa-prod.teamwork.com/tko/public/images/badge-tour/workflow-wizard-connecting.png',
          },
        },
        {
          name: t('Helpful hints'),
          description: OnboardingTourDataWorkflowWizardHelpfulHints,
          routeInProject: true,
          matchIncludes: true,
          stepRoute: '/tasks/board',
          image: {
            normal: 'https://twa-prod.teamwork.com/tko/public/images/badge-tour/workflow-wizard-helpful-hints.png',
          },
        },
      ],
    },
    'team-player': {
      id: 21,
      name: 'team-player',
      description: t('Team Player'),
      type: 'badgeTour',
      badgeId: 7,
      repeatable: true,
      steps: [
        {
          name: t('Comments and mentions'),
          description: OnboardingTourDataTeamPlayerComments,
          routeInProject: true,
          matchIncludes: true,
          stepRoute: '/comments',
          image: {
            normal: 'https://twa-prod.teamwork.com/tko/public/images/badge-tour/team-player-comments.png',
          },
        },
        {
          name: t('Messages'),
          description: OnboardingTourDataTeamPlayerMessages,
          routeInProject: true,
          matchIncludes: true,
          stepRoute: '/messages',
          image: {
            normal: 'https://twa-prod.teamwork.com/tko/public/images/badge-tour/team-player-messages.png',
          },
        },
        {
          name: t('Notification settings'),
          description: OnboardingTourDataTeamPlayerNotifications,
          routeInProject: true,
          matchIncludes: true,
          stepRoute: '/settings/notifications',
          image: {
            normal: 'https://twa-prod.teamwork.com/tko/public/images/badge-tour/team-player-notifications.png',
          },
        },
        {
          name: t('Invite team mates'),
          description: OnboardingTourDataTeamPlayerInvites,
          routeInProject: false,
          matchIncludes: true,
          stepRoute: '/people/people',
          image: {
            normal: 'https://twa-prod.teamwork.com/tko/public/images/badge-tour/team-player-invites.png',
          },
        },
        {
          name: t('User permissions'),
          description: OnboardingTourDataTeamPlayerUserPermissions,
          image: {
            normal: 'https://twa-prod.teamwork.com/tko/public/images/badge-tour/team-player-user-permissions.png',
          },
        },
      ],
    },
    'client-champion': {
      id: 22,
      name: 'client-champion',
      description: t('Client Champion'),
      type: 'badgeTour',
      badgeId: 9,
      repeatable: true,
      steps: [
        {
          name: t('Intake forms'),
          description: OnboardingTourDataClientChampionIntakeForms,
          routeInProject: true,
          matchIncludes: true,
          stepRoute: '/forms',
          image: {
            normal: 'https://twa-prod.teamwork.com/tko/public/images/badge-tour/client-champion-intake-forms.png',
          },
        },
        {
          name: t('Notice when tasks are created'),
          description: OnboardingTourDataClientChampionTasks,
          routeInProject: true,
          matchIncludes: true,
          stepRoute: '/forms',
          image: {
            normal: 'https://twa-prod.teamwork.com/tko/public/images/badge-tour/client-champion-tasks.png',
          },
        },
        {
          name: t('Helpful hint: Gather data'),
          description: OnboardingTourDataClientChampionGatherData,
          image: {
            normal: 'https://twa-prod.teamwork.com/tko/public/images/badge-tour/client-champion-gather-data.png',
          },
        },
        {
          name: t('Helpful hint: Provide insight'),
          description: OnboardingTourDataClientChampionProvideInsight,
          image: {
            normal: 'https://twa-prod.teamwork.com/tko/public/images/badge-tour/client-champion-provide-insight.png',
          },
        },
      ],
    },
    'the-accountant': {
      id: 23,
      name: 'the-accountant',
      description: t('The Accountant'),
      type: 'badgeTour',
      badgeId: 8,
      repeatable: true,
      steps: [
        {
          name: t('User rates'),
          description: OnboardingTourDataTheAccountantUserRates,
          routeInProject: true,
          matchIncludes: true,
          stepRoute: '/finance/budgets',
          image: {
            normal: 'https://twa-prod.teamwork.com/tko/public/images/badge-tour/the-accountant-user-rates.png',
          },
        },
        {
          name: t('Why set site-level billable rates'),
          description: OnboardingTourDataTheAccountantBillableRates,
          routeInProject: false,
          matchIncludes: true,
          stepRoute: '/people/people',
          image: {
            normal: 'https://twa-prod.teamwork.com/tko/public/images/badge-tour/the-accountant-billable-rates.png',
          },
        },
        {
          name: t('Track every billable minute'),
          description: OnboardingTourDataTheAccountantTrackBillable,
          routeInProject: false,
          matchIncludes: true,
          stepRoute: '/time/all',
          image: {
            normal: 'https://twa-prod.teamwork.com/tko/public/images/badge-tour/the-accountant-track-billable.png',
          },
        },
        {
          name: t('Bill accurately'),
          description: OnboardingTourDataTheAccountantBillAccurately,
          routeInProject: true,
          matchIncludes: true,
          stepRoute: '/finance/billing/open',
          image: {
            normal: 'https://twa-prod.teamwork.com/tko/public/images/badge-tour/the-accountant-bill-accurately.gif',
          },
        },
        {
          name: t('Profitability report'),
          description: OnboardingTourDataTheAccountantProfitability,
          routeInProject: false,
          matchIncludes: true,
          stepRoute: '/reports/gallery',
          image: {
            normal: 'https://twa-prod.teamwork.com/tko/public/images/badge-tour/the-accountant-profitability.gif',
          },
        },
      ],
    },
    'joinYourFirstProject': {
      id: 24,
      name: 'join-your-first-project',
      description: t('Join your first project'),
      type: 'invitedWorkerBeeTour',
      repeatable: false,
      matchIncludes: true,
    },
    'stayOnTopOfYourWork': {
      id: 25,
      name: 'stay-on-top-of-your-work',
      description: t('Stay on top of your work'),
      type: 'invitedWorkerBeeTour',
      repeatable: true,
      steps: [
        {
          name: t('Stay on top of your work'),
          description: OnboardingTourDataStayOnTopOfYourWork,
          image: {
            normal: 'https://twa-prod.teamwork.com/tko/public/images/product-tours/stay-on-top-of-your-work.svg ',
          },
        },
        {
          name: t('My work'),
          description: OnboardingTourDataMyWork,
          routeInProject: false,
          stepRoute: '/home/work',
          matchIncludes: true,
          image: {
            normal: 'https://twa-prod.teamwork.com/tko/public/images/product-tours/my-work.svg ',
          },
        },
        {
          name: t("Don't miss a thing"),
          description: OnboardingTourDataDontMissAThing,
          routeInProject: false,
          stepRoute: '/home/work',
          matchIncludes: true,
          image: {
            normal: 'https://twa-prod.teamwork.com/tko/public/images/product-tours/dont-miss-a-thing.svg ',
          },
        },
      ],
    },
    'collaborateWithYourTeam': {
      id: 26,
      name: 'collaborate-with-your-team',
      description: t('Collaborate with your team'),
      type: 'invitedWorkerBeeTour',
      repeatable: true,
      steps: [
        {
          name: t('Collaborate with your team'),
          description: OnboardingTourDataCollaborateWithYourTeam,
          image: {
            normal:
              'https://cdn-projects.teamwork.com/tko/public/images/product-tours/collaborate-with-your-clients.svg ',
          },
        },
        {
          name: t('Leave comments on your work'),
          description: OnboardingTourDataLeaveCommentsOnWork,
          routeInProject: false,
          stepRoute: '/home/work',
          matchIncludes: true,
          image: {
            normal: 'https://twa-prod.teamwork.com/tko/public/images/product-tours/leave-comments-on-your-work.svg ',
          },
        },
        {
          name: t('Store your files in one safe place'),
          description: OnboardingTourDataStoreFilesInOnePlace,
          routeInProject: true,
          stepRoute: '/files',
          matchIncludes: true,
          image: {
            normal: 'https://twa-prod.teamwork.com/tko/public/images/product-tours/store-files-in-one-place.svg ',
          },
        },
        ...(!isExpA18Variation.value
          ? [
              {
                name: t('Create and manage proofs'),
                description: OnboardingTourDataCreateAndManageProofs,
                routeInProject: false,
                stepRoute: '/proofs',
                matchIncludes: true,
                image: {
                  normal: 'https://twa-prod.teamwork.com/tko/public/images/product-tours/create-and-manage-proofs.svg ',
                },
              },
            ]
          : []),
      ],
    },
    'trackYourTime': {
      id: 27,
      name: 'track-your-time',
      description: t('Track billable time'),
      type: 'invitedWorkerBeeTour',
      repeatable: true,
      steps: [
        {
          name: t('Track billable time'),
          description: OnboardingTourDataTrackYourTime,
          image: {
            normal: 'https://twa-prod.teamwork.com/tko/public/images/product-tours/track-your-time.svg',
          },
        },
        {
          name: t('Track every billable minute'),
          description: OnboardingTourDataTrackBillableMinutes,
          routeInProject: true,
          stepRoute: '/tasks/table',
          matchIncludes: true,
          image: {
            normal:
              'https://cdn-projects.teamwork.com/tko/public/images/product-tours/track-every-billable-minute.svg ',
          },
        },
        {
          name: t('My timesheets'),
          description: OnboardingTourDataMyTimesheets,
          routeInProject: false,
          stepRoute: '/time/user',
          matchIncludes: true,
          image: {
            normal: 'https://twa-prod.teamwork.com/tko/public/images/product-tours/my-timesheets.svg ',
          },
        },
      ],
    },
    // Experiment A41
    'connectWithSlack': {
      id: 28,
      name: 'connect-with-slack',
      type: 'connectWithSlack',
      description: t('Connect {teamwork} with {slack}', { teamwork: 'Teamwork.com', slack: 'Slack' }),
      repeatable: true,
    },
    // Experiment A18
    'billingClientsForWork2': {
      id: 29,
      name: 'billing-clients-for-work',
      description: t('Billing clients for work'),
      type: 'productTour',
      repeatable: true,
      steps: [
        {
          name: t('Billing clients for work'),
          description: OnboardingTourDataBillingClientsForWork,
          image: {
            normal: 'https://cdn-projects.teamwork.com/tko/public/images/product-tours/never-miss-payment.svg',
          },
          stepRoute: '/finance/billing/open',
          routeInProject: true,
          matchIncludes: true,
        },
        {
          name: t('Set hourly rates for your team'),
          description: OnboardingTourDataSetHourlyRatesForYourTeam,
          image: {
            normal: 'https://cdn-projects.teamwork.com/tko/public/images/product-tours/my-timesheet.svg',
          },
          stepRoute: '/finance/rates',
          routeInProject: true,
          matchIncludes: true,
        },
        {
          name: t('Create your first invoice'),
          description: OnboardingTourDataCreateYourFirstInvoice,
          image: {
            normal: 'https://cdn-projects.teamwork.com/tko/public/images/product-tours/bill-accurately.svg',
          },
          stepRoute: '/finance/billing/open',
          routeInProject: true,
          matchIncludes: true,
        },
      ],
    },
    // Experiment A24-20
    'resourceManagement': {
      id: 30,
      name: 'manage_resources',
      description: t('Resource management'),
      type: 'productTour',
      trackingVariant: 'ICP20+_ONBOARDING_EVENT',
      repeatable: false,
      takeOver: true,
      videoId: '3v5rio9l5q',
      takeOverTitle: t('See workloads in real time-before they become a problem'),
      takeOverContent: t(
        'Clients want it yesterday? Big job on the horizon? Account manager step out? Be ready for any resourcing challenge-short or long-term.',
      ),
      steps: [
        {
          name: t('Oops! David is over capacity'),
          description: OnboardingTourDataResourceManagementIntro,
          stepRoute: '/planning/workload',
          stepName: 'workload_intro',
          routeInProject: false,
          matchIncludes: true,
          primaryCta: t('Show me how'),
        },
        {
          name: t('Now, time to try it yourself'),
          description: OnboardingTourDataResourceManagementProblem,
          stepRoute: '/planning/workload',
          stepName: 'workload_problem',
          routeInProject: false,
          matchIncludes: true,
          bookADemoCta: true,
          primaryCta: t('I did it!!'),
          tip: {
            message: t('Drag and drop allocations from one day to another to redistribute your workload.'),
            title: t('Time to stop the burnout'),
            videoCode: 'xdcn8yc1q4',
            autoOpen: true,
          },
          initFunc: () => {
            try {
              // Workaround to expand during tip step.
              return legacyIframeEl.value.contentWindow.document
                .getElementsByClassName('workload_controls')?.[0]
                ?.click();
            } catch {
              return null;
            }
          },
        },
        {
          name: t("Congrats! You've optimized David's week like a pro."),
          description: OnboardingTourDataResourceManagementProblemCongrats,
          stepRoute: '/planning/workload',
          stepName: 'workload_what',
          routeInProject: false,
          matchIncludes: true,
          successStep: true,
          primaryCta: t('On to the Scheduler!'),
        },
      ],
    },
    'scheduler': {
      id: 31,
      name: 'scheduler',
      description: t('Resource scheduling'),
      type: 'productTour',
      trackingVariant: 'ICP20+_ONBOARDING_EVENT',
      repeatable: false,
      takeOver: true,
      videoId: 'yu3whe3enj',
      takeOverTitle: t('Forecasting without the guesswork'),
      takeOverContent: t(
        'Stay three steps ahead-check capacity, forecast resources, and hire with confidence. Make the right calls before things get messy.',
      ),
      steps: [
        {
          name: t('Do you have bandwidth to take on future work?'),
          description: OnboardingTourDataResourceManagementScheduleIntro,
          stepRoute: '/planning/schedule/projects',
          stepName: 'schedule_intro',
          params: { daysOverride: 30 },
          routeInProject: false,
          matchIncludes: true,
        },
        {
          name: t('How to schedule future work with {teamwork}?', { teamwork: 'Teamwork' }),
          description: OnboardingTourDataResourceManagementScheduleWhat,
          stepRoute: '/planning/schedule/projects',
          stepName: 'schedule_what',
          tip: {
            message: t(
              'View scheduled allocated work and expand projects and tentative projects to see detailed allocations.',
            ),
            title: t('Viewing scheduled work'),
            videoCode: 'wrgjnvjvmm',
          },
          routeInProject: false,
          matchIncludes: true,
        },
        {
          name: t('Why is accurate capacity forecasting important?'),
          description: OnboardingTourDataResourceManagementScheduleWhy,
          stepRoute: '/planning/schedule/projects',
          stepName: 'schedule_why',
          routeInProject: false,
          matchIncludes: true,
        },
      ],
    },
    'gboTour': {
      id: 32,
      name: 'goal-based-onboarding',
      description: t('Set up your first project'),
      type: 'gbo',
      repeatable: true,
    },
  };

  return { onboardingTourData };
}
