<script setup>
import { LscLinearChartDirections, LscLinearChartIs, LscLinearChartVariants } from '../../linearChart/constants';
import LscLinearChart from '../../linearChart/LscLinearChart.vue';

defineProps({
  /**
   * The HTML tag to use for the table chart cell.
   * @type {PropType<typeof LscLinearChartIs[number]>}
   */
  is: {
    type: String,
    default: 'div',
    validator: (value) => LscLinearChartIs.includes(value),
  },
  /**
   * The direction of the progress bar.
   * @type {PropType<typeof LscLinearChartDirections[number]>}
   */
  direction: {
    type: String,
    default: 'horizontal',
    validator: (type) => LscLinearChartDirections.includes(type),
  },
  /**
   * The variant of the progress bar. This will change the color of the progress bar and the background.
   * @type {PropType<typeof LscLinearChartVariants[number]>}
   */
  variant: {
    type: String,
    default: 'neutral',
    validator: (variant) => LscLinearChartVariants.includes(variant),
  },
  /**
   * Enable the pulse animation.
   */
  animation: {
    type: Boolean,
    default: false,
  },
  /**
   * The progress percentage of the progress bar.
   * @type {PropType<Number>}
   */
  percentage: {
    type: Number,
    default: 0,
  },
});

const emit = defineEmits(['click']);
</script>
<template>
  <LscLinearChart
    :is="is"
    :animation="animation"
    :percentage="percentage"
    :variant="variant"
    :direction="direction"
    @click="emit('click', $event)"
  >
    <slot />
  </LscLinearChart>
</template>
