import { useI18n } from '@/util';

export function useTaskRepeatHelpers() {
  const { t } = useI18n();

  function getTaskRepeatText(task) {
    const frequency = task?.sequence?.frequency;
    const duration = task?.sequence?.duration;

    const translations = {
      daily: t('This task repeats daily'),
      weekdays: t('This task repeats every weekday (Mon-Fri)'),
      selecteddays: t('This task repeats on selected days'),
      weekly: t('This task repeats weekly'),
      everyxdays: t('This task repeats every {n} days', { n: duration }),
      everyxdaysweekday: t('This task repeats every {n} days, only weekdays', { n: duration }),
      every2weeks: t('This task repeats every {n} weeks', { n: 2 }),
      every3weeks: t('This task repeats every {n} weeks', { n: 3 }),
      every4weeks: t('This task repeats every {n} weeks', { n: 4 }),
      every5weeks: t('This task repeats every {n} weeks', { n: 5 }),
      every6weeks: t('This task repeats every {n} weeks', { n: 6 }),
      monthly: t('This task repeats monthly'),
      every2months: t('This task repeats every {n} months', { n: 2 }),
      every3months: t('This task repeats every {n} months', { n: 3 }),
      every4months: t('This task repeats every {n} months', { n: 4 }),
      every6months: t('This task repeats every {n} months', { n: 6 }),
      yearly: t('This task repeats yearly'),
      default: t('This task does not repeat'),
    };
    return translations[frequency] || translations.default;
  }

  return { getTaskRepeatText };
}
