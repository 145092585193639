import { useFeatures } from '../features/useFeatures';
import { usePendo } from '../features/usePendo';
import { usePermissions } from '../permissions/usePermissions';

/**
 * Constants, computed properties and functions related to Experiment Desk Onboarding Flow rollout
 * https://app.launchdarkly.com/projects/default/flags/desk-onboarding-flow/targeting
 */
export function useExperimentDeskOnboardingFlow() {
  const { isDeskEnabled } = usePermissions();
  const { deskOnboardingFlowEnabled } = useFeatures();
  const { trackExperimentInPendo, trackPendoEvent } = usePendo();
  const { launchDarklyFlagKey, defaultValue } = deskOnboardingFlowEnabled;
  const ignoreInExperimentOnRuleMatch = true;
  const EXP_DESK_ONBOARDING_FLOW_COMMON_METRICS = ['plan_name', 'page', 'user_role', 'on_trial', 'is_icp', 'is_paid'];

  const deskOnboardingFlowExperimentAppLevelTargeting = computed(() => !isDeskEnabled?.value);

  const isExpDeskOnboardingFlowVariation = computed(
    () => deskOnboardingFlowExperimentAppLevelTargeting.value && deskOnboardingFlowEnabled.value,
  );

  function trackExperimentDeskOnboardingFlow() {
    trackExperimentInPendo({
      launchDarklyFlagKey,
      defaultValue,
      ignoreInExperimentOnRuleMatch,
      appLevelTargeting: deskOnboardingFlowExperimentAppLevelTargeting.value,
    });
  }

  function trackDeskOnboardingFlowEvent(meta = {}) {
    trackPendoEvent({
      eventName: 'DESK_UPSELL_TARGET_USER_EVENT',
      commonMetrics: EXP_DESK_ONBOARDING_FLOW_COMMON_METRICS,
      metadata: {
        ...meta,
      },
    });
  }

  return {
    trackExperimentDeskOnboardingFlow,
    isExpDeskOnboardingFlowVariation,
    trackDeskOnboardingFlowEvent,
  };
}
