<script setup>
const props = defineProps({
  /**
   * Whether the sheet is elevated.
   * @type {PropType<boolean>}
   */
  elevated: {
    type: Boolean,
    default: false,
  },
  /**
   * Whether the sheet has border & padding.
   * @type {PropType<boolean>}
   */
  inset: {
    type: Boolean,
    default: true,
  },
});

const cardVariantConfig = tv({
  base: '',
  variants: {
    inset: {
      true: 'rounded-md border bg-[--lsds-c-sheet-color-background-default] p-4 shadow-1',
      false: 'p-0',
    },
    elevated: {
      true: 'shadow-4',
    },
  },
  compoundVariants: [
    {
      elevated: true,
      inset: true,
      class: 'shadow-4',
    },
  ],
});

const classes = computed(() => cardVariantConfig({ elevated: props.elevated, inset: props.inset }));
</script>
<template>
  <div :class="classes"><slot /></div>
</template>
