<script setup lang="ts">
import { getInitials, useI18n } from '@/util';
import LscIcon from '../icon/LscIcon.vue';
import { LscAvatarProps } from './LscAvatar.types.js';

const props = defineProps(LscAvatarProps);

const emit = defineEmits(['remove']);

const { t } = useI18n();
const showFallback = shallowRef(!props.src);

const computedRemoveTooltip = computed(() => {
  if (!props.name) {
    return () => '';
  }
  if (props.removeTooltipFunction) {
    return () => props.removeTooltipFunction?.(props.name);
  }
  return () => t('Remove {name}', { name: props.name });
});

watchEffect(() => {
  showFallback.value = !props.src;
});

const avatarVariantStyleConfig = tv({
  base: [
    'group/LscAvatar flex items-center justify-center overflow-hidden rounded-full no-underline transition-all',
    'select-none bg-[--bg-color] leading-1 text-[color:--icon-color] !no-underline',
  ],
  slots: {
    icon: '',
    image: 'h-full w-full object-cover text-[transparent]',
    clearableButton:
      'pointer-events-none absolute inset-0 flex items-center justify-center bg-surface-dark bg-opacity-40 opacity-0 transition-opacity',
  },
  variants: {
    variant: {
      'default': '',
      'on-surface': '',
    },
    size: {
      xxs: {
        base: 'size-[--lsds-c-avatar-size-xxs] text-[length:--lsds-c-avatar-text-size-xxs]',
      },
      xs: {
        base: 'size-[--lsds-c-avatar-size-xs] text-[length:--lsds-c-avatar-text-size-xs]',
      },
      sm: {
        base: 'size-[--lsds-c-avatar-size-sm] text-[length:--lsds-c-avatar-text-size-sm]',
      },
      md: {
        base: 'size-[--lsds-c-avatar-size-md] text-[length:--lsds-c-avatar-text-size-md]',
      },
      lg: {
        base: 'size-[--lsds-c-avatar-size-lg] text-[length:--lsds-c-avatar-text-size-lg]',
      },
      xl: {
        base: 'size-[--lsds-c-avatar-size-xl] text-[length:--lsds-c-avatar-text-size-xl]',
      },
      xxl: {
        base: 'size-[--lsds-c-avatar-size-xxl] text-[length:--lsds-c-avatar-text-size-xxl]',
      },
    },
    clearable: {
      true: {
        base: 'relative',
        clearableButton: 'pointer-events-auto group-hover/LscAvatar:opacity-100',
      },
    },
    showFallback: {
      true: '',
      false: '',
    },
  },
  compoundVariants: [
    {
      variant: 'on-surface',
      showFallback: true,
      class: {
        base: 'border border-bold',
      },
    },
  ],
});

const type = computed(() => {
  if (showFallback.value) {
    if (props.icon || !props.name) {
      return 'icon';
    }
    return 'text';
  }
  return 'image';
});

const classes = computed(() =>
  avatarVariantStyleConfig({
    size: props.size,
    variant: props.variant,
    clearable: props.clearable,
    showFallback: showFallback.value,
  }),
);

const computedIcon = computed(() => {
  if (props.icon) {
    return props.icon;
  }
  // We will use the name to generate the initials if we can
  if (props.name) {
    return undefined;
  }
  // The default icon is the assignee icon
  return 'lsi-assignee';
});

const computedBgColor = computed(() => {
  if (props.bgColor) {
    return props.bgColor;
  }
  if (type.value === 'text') {
    return 'var(--lsds-a-color-decorative-2)';
  }
  return 'var(--lsds-a-color-surface-default)';
});

const computedIconColor = computed(() => {
  if (props.iconColor) {
    return props.iconColor;
  }
  if (type.value === 'text') {
    return 'var(--lsds-a-color-icon-default)';
  }
  return 'var(--lsds-a-color-icon-subtle)';
});

const tooltip = computed(() => {
  if (!props.showTooltip) {
    return undefined;
  }
  if (props.clearable) {
    return computedRemoveTooltip.value?.();
  }
  return props.name;
});

const computedAlt = computed(() => {
  if (props.alt) {
    return props.alt;
  }
  if (props.name) {
    return t('Avatar of {name}', { name: props.name });
  }
  return '';
});

const computedText = computed(() => {
  return props.name ? getInitials(props.name) : '';
});

// XL icon size is too large for the avatar
const iconSizeMap = {
  xxs: 'xs',
  xs: 'xs',
  sm: 'sm',
  md: 'md',
  lg: 'lg',
  xl: 32,
  xxl: 56,
} as const;

const iconSize = computed(() => iconSizeMap[props.size]);

function remove() {
  emit('remove');
}
</script>
<template>
  <div
    v-LsdTooltip="tooltip"
    :class="classes.base()"
    :style="{
      '--bg-color': computedBgColor,
      '--icon-color': computedIconColor,
    }"
  >
    <slot>
      <img v-if="!showFallback" :src="src" :alt="computedAlt" :class="classes.image()" @error="showFallback = true" />
      <template v-else>
        <LscIcon
          v-if="computedIcon"
          :size="iconSize"
          role="img"
          :title="computedAlt"
          :class="classes.icon()"
          :icon="computedIcon"
        />
        <span v-else>{{ computedText }}</span>
      </template>
      <button
        v-if="clearable"
        type="button"
        :class="classes.clearableButton()"
        :ariaLabel="t('Remove')"
        @click.stop="remove"
      >
        <LscIcon :size="iconSize" icon="lsi-close" class="text-on-dark" />
      </button>
    </slot>
  </div>
</template>
