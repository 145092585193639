<script setup>
import { useI18n } from '@/util';

const { t } = useI18n();
</script>
<template>
  <div class="text-body-1">
    <p>
      {{
        t('You can also add tasks from a template to an existing task list via the Table or List area of a project.')
      }}
    </p>
    <p class="mt-4">
      {{
        t(
          'Click the options button (three dots) to the right of the task list name. In the dropdown menu, click Advanced to expand the menu. Hover over Templates and select Add Tasks from Template.',
        )
      }}
    </p>
  </div>
</template>
