// Source: https://github.com/cure53/DOMPurify/blob/3.1.7/src/regexp.js
// eslint-disable-next-line no-control-regex
const ATTR_WHITESPACE = /[\u0000-\u0020\u00A0\u1680\u180E\u2000-\u2029\u205F\u3000]/g;
// eslint-disable-next-line no-useless-escape
const IS_ALLOWED_URI = /^(?:(?:(?:f|ht)tps?|mailto|tel|callto|sms|cid|xmpp):|[^a-z]|[a-z+.\-]+(?:[^a-z+.\-:]|$))/i;

/**
 * Sanitizes the given url.
 * @param {string} url The url to sanitize.
 * @returns The input `url`, if it is safe, otherwise an empty string.
 */
export function sanitizeUrl(url) {
  return typeof url === 'string' && IS_ALLOWED_URI.test(url.replaceAll(ATTR_WHITESPACE, '')) ? url : '';
}
