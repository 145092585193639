/* eslint-disable no-param-reassign */
import { DateTime } from 'luxon';

function getEntity(o, included) {
  if (!o || typeof o !== 'object') {
    return o;
  }

  const { id, type } = o;

  if (!id || !type) {
    return o;
  }

  return included?.[type]?.[id] || o;
}

export function hydrateProperty(o, p, included) {
  if (!o || typeof o !== 'object') {
    return;
  }

  if (!o[p] || typeof o[p] !== 'object') {
    return;
  }

  if (Array.isArray(o[p])) {
    o[p] = o[p].map((q) => getEntity(q, included));
    return;
  }

  o[p] = getEntity(o[p], included);
}

export function hydrateMeta(o, included) {
  if (o.createdAt) {
    o.createdAt = DateTime.fromISO(o.createdAt);
  }

  if (o.createdBy) {
    hydrateProperty(o, 'createdBy', included);
  }

  if (o.updatedAt) {
    o.updatedAt = DateTime.fromISO(o.updatedAt);
  }

  if (o.updatedBy) {
    hydrateProperty(o, 'updatedBy', included);
  }

  if (o.deletedAt) {
    o.deletedAt = DateTime.fromISO(o.deletedAt);
  }

  if (o.deletedBy) {
    hydrateProperty(o, 'deletedBy', included);
  }
}
