const symbol = Symbol('currentProject');

/**
 * Provides the current project state which `useCurrentProjectState` will return.
 */
export function provideCurrentProjectState(state) {
  provide(symbol, state);
}

/**
 * Returns the current project state provided by `provideCurrentProjectState`.
 */
export function useCurrentProjectState() {
  return inject(symbol);
}

/**
 * Returns the current project ref from `useCurrentProjectState`.
 */
export function useCurrentProject() {
  return useCurrentProjectState().item;
}
