<script setup>
import { useSpacesV1Loader } from '@/api';
import SpacesDrawerGetStartedBlankSlate from './SpacesDrawerGetStartedBlankSlate.vue';
import SpacesDrawerSpacesList from './SpacesDrawerSpacesList.vue';

const props = defineProps({
  userIsGettingStarted: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['update:userIsGettingStarted']);

const pageSize = 50;
const count = shallowRef(pageSize);
const state = useSpacesV1Loader({
  count,
  pageSize,
  params: computed(() => ({
    orderBy: 'updatedAt',
    orderMode: 'DESC',
  })),
});

watch(
  () => state.inSync.value && state.items.value.length === 0,
  (userIsGettingStarted) => {
    if (userIsGettingStarted !== props.userIsGettingStarted) {
      emit('update:userIsGettingStarted', userIsGettingStarted);
    }
  },
);
</script>

<template>
  <SpacesDrawerSpacesList v-model:count="count" :step="pageSize" :state="state">
    <template #blank>
      <SpacesDrawerGetStartedBlankSlate />
    </template>
  </SpacesDrawerSpacesList>
</template>
