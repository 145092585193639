<script setup>
import { useDarkMode } from '../../darkMode/useDarkMode';
import { useAppShellSidebar } from '../useAppShellSidebar';
import { useAppShellSidebarTracking } from '../useAppShellSidebarTracking';

const { isUserMenuThemeOptionsOpen } = useAppShellSidebar();
const { setDark, colorMode } = useDarkMode();
const { trackDarkModeThemeSelection } = useAppShellSidebarTracking();
function closeUserMenuThemeOptions() {
  isUserMenuThemeOptionsOpen.value = false;
}

function setTheme(theme) {
  setDark(theme);
  trackDarkModeThemeSelection(theme);
  closeUserMenuThemeOptions();
}
</script>

<template>
  <WidgetOptionsMenu v-model="isUserMenuThemeOptionsOpen" location="end" openOnHover openOnClick>
    <template #activator="activator">
      <slot name="activator" v-bind="activator" />
    </template>

    <WidgetOptionsMenuItem :active="colorMode === 'light'" @click="setTheme('light')">
      <template #prepend>
        <LscIcon icon="lsi-theme-light" size="sm" />
      </template>
      <VListItemTitle>Light</VListItemTitle>
    </WidgetOptionsMenuItem>

    <WidgetOptionsMenuItem :active="colorMode === 'dark'" @click="setTheme('dark')">
      <template #prepend>
        <LscIcon icon="lsi-theme-dark" size="sm" />
      </template>
      <VListItemTitle>Dark</VListItemTitle>
    </WidgetOptionsMenuItem>

    <WidgetOptionsMenuItem :active="colorMode === 'system'" @click="setTheme('system')">
      <template #prepend>
        <LscIcon icon="lsi-settings" size="sm" />
      </template>
      <VListItemTitle>System default</VListItemTitle>
    </WidgetOptionsMenuItem>
  </WidgetOptionsMenu>
</template>
