const symbol = Symbol('useDocumentTitle');

export function provideDocumentTitle() {
  const prefix = shallowRef([]);
  const main = shallowRef([]);
  const suffix = shallowRef([]);
  const full = shallowRef([]);

  const title = computed(
    () =>
      full.value.at(-1)?.value ||
      []
        .concat(
          prefix.value.map((s) => s.value),
          main.value.map((s) => s.value),
          suffix.value.map((s) => s.value),
        )
        .filter(Boolean)
        .join(' - '),
  );

  watch(title, (newTitle) => {
    document.title = newTitle;
  });

  provide(symbol, { prefix, main, suffix, full });
}

/**
 * Adds a segment to `document.title`.
 * Segments are separated by " - ".
 * The segment is removed automatically when the component is unmountd.
 *
 * @param {String|Ref<String>} title The title segment to add.
 * @param {'prefix'|'main'|'suffix'|'full'} location Where to add the new segment: "prefix", "main" (default), "suffix", "full".
 * @param {Boolean} append If false (default), then prepend the new segment, otherwise append it.
 */
export function useDocumentTitle(title, location = 'main', append = false) {
  const { prefix, main, suffix, full } = inject(symbol);

  let segments;

  if (location === 'prefix') {
    segments = prefix;
  }
  if (location === 'main') {
    segments = main;
  }
  if (location === 'suffix') {
    segments = suffix;
  }
  if (location === 'full') {
    segments = full;
  }

  // By creating a new computed here we can guarantee
  // that we'll remove the correct segment in `onUnmounted`.
  const segment = computed(() => unref(title));

  if (append) {
    segments.value = segments.value.concat([segment]);
  } else {
    segments.value = [segment].concat(segments.value);
  }

  onUnmounted(() => {
    // eslint-disable-next-line vue/no-ref-as-operand
    segments.value = segments.value.filter((s) => s !== segment);
  });
}
