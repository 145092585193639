import { delayedPromise, useI18n } from '@/util';
import { useAxios } from '../base/useAxios';
import { useOptimisticUpdates } from '../base/useOptimisticUpdates';
import { useRealTimeUpdates } from '../base/useRealTimeUpdates';

export function useNotificationsActions() {
  const api = useAxios();
  const { t } = useI18n();
  const { emit: emitOptimisticUpdate } = useOptimisticUpdates();
  const { emit: emitRealTimeUpdate, socketId } = useRealTimeUpdates();

  function markNotificationRead({ id, read }) {
    const promise = api
      .patch(
        `/projects/api/v3/notifications/${id}.json`,
        {
          notification: {
            read,
          },
        },
        {
          headers: {
            'Socket-ID': socketId,
          },
          errorMessage: t('Failed to update notification'),
        },
      )
      .then(() => {
        emitRealTimeUpdate({
          type: 'notification',
          action: 'update',
          notificationId: id,
          read,
        });
      });

    emitOptimisticUpdate({
      promise: delayedPromise(promise),
      type: 'notification',
      action: 'update',
      notification: { id, read },
    });

    return promise;
  }

  function markAllNotificationsAsRead() {
    const promise = api
      .post(
        '/projects/api/v3/notifications/read.json',
        {},
        {
          headers: {
            'Socket-ID': socketId,
          },
          errorMessage: t('Failed to update notification'),
        },
      )
      .then(() => {
        emitRealTimeUpdate({
          type: 'notification',
          action: 'markAllRead',
        });
      });

    emitOptimisticUpdate({
      promise: delayedPromise(promise),
      type: 'notification',
      action: 'update',
      notification: { read: true },
    });

    return promise;
  }

  return {
    markNotificationRead,
    markAllNotificationsAsRead,
  };
}
