<script setup>
import { useI18n } from '@/util';
import LscButton from '../../../components/action/button/LscButton.vue';
import LscEmptyState from '../../../components/infodisplay/empty/LscEmptyState.vue';
import LswAssigneePickerInvitePeoplePromptImage from './LswAssigneePickerInvitePeoplePromptImage.svg';

const emit = defineEmits(['invitePeople']);

const { t } = useI18n();

function handleInvitePeople() {
  emit('invitePeople');
}
</script>
<template>
  <LscEmptyState size="sm" :title="t('Collaborate with your team')">
    <template #image>
      <LswAssigneePickerInvitePeoplePromptImage />
    </template>
    <template #actions>
      <LscButton size="sm" variant="primary" @click="handleInvitePeople">
        {{ t('Invite people') }}
      </LscButton>
    </template>
  </LscEmptyState>
</template>
