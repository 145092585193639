<script setup>
import { useRouter } from 'vue-router';
import { useExperimentA2408, useExperimentA2413c, useExperimentA2419, useLaunchDarkly, usePricePlan } from '@/api';
import { useI18n } from '@/util';
import { useThemeColor } from '@/module/theme';
import { UserAddYourTeamDialog } from '@/module/user';
import { useAppShellSidebar } from './useAppShellSidebar';
import { useAppShellSidebarTracking } from './useAppShellSidebarTracking';

const { t } = useI18n();
const router = useRouter();
const { canUpgradeAccount, canTrialInviteUsers, isPaid } = usePricePlan();
const { isCustomTheme } = useThemeColor();
const { trackLaunchDarklyEvent } = useLaunchDarkly();
const { isSidebarExpanded } = useAppShellSidebar();
const { trackLeftNavigationClicked } = useAppShellSidebarTracking({ isSidebarExpanded });
const { isExpA2413cVariation, shouldShowInviteTeamSplitButton } = useExperimentA2413c();
const { isExpA2419Variation } = useExperimentA2419();
const { productSpecialistRep } = useExperimentA2408();

const isDialogOpen = shallowRef(false);
const dialogName = shallowRef('');

function handleUsersInvited(invitedUsers = []) {
  if (!invitedUsers.length) {
    return;
  }

  trackLaunchDarklyEvent({ eventName: 'trial-invite-users-via-sidenav-cta' });
}

function handleAddTeamClicked() {
  dialogName.value = 'UserAddYourTeamDialog';
  isDialogOpen.value = true;
  trackLeftNavigationClicked('add_your_team');
}

function bookACall() {
  trackLeftNavigationClicked('book_a_call');
  router.push({
    path: '/book-a-call',
    query: { bookingUrl: encodeURI(productSpecialistRep.value.hubspotCalendarLink) },
  });
}
</script>

<template>
  <ul
    v-if="
      (canTrialInviteUsers && !shouldShowInviteTeamSplitButton) || (canUpgradeAccount && !isPaid) || isExpA2419Variation
    "
    class="flex shrink-0 flex-col items-stretch divide-y border-y"
    :class="{
      'divide-[color:--sidebar-custom-theme-separator-border-color] border-[--sidebar-custom-theme-separator-border-color]':
        isCustomTheme,
      'divide-[color:--lsds-c-sidebar-drawer-upgrade-now-color-surface] border-[--lsds-c-sidebar-drawer-upgrade-now-color-surface]':
        !isCustomTheme,
      'mx-6': isExpA2413cVariation,
    }"
  >
    <li v-if="isExpA2419Variation">
      <button
        v-LsdTooltip:right="isSidebarExpanded ? undefined : t('Book a demo')"
        :class="[
          'flex h-12 w-full items-center gap-2 overflow-hidden py-2 text-left text-body-1 font-semibold',
          'bg-transparent',
          'text-[--lsds-c-sidebar-drawer-list-item-color-on-surface]',
          'hover:bg-[--lsds-c-sidebar-drawer-list-item-background-color-on-hover]',
          'hover:text-[--lsds-c-sidebar-drawer-list-item-color-on-hover]',
          'focus-visible:bg-[--lsds-c-sidebar-drawer-list-item-background-color-on-hover]',
          'focus-visible:text-[--lsds-c-sidebar-drawer-list-item-color-on-hover]',
          { 'px-0.5': isExpA2413cVariation, 'px-6': !isExpA2413cVariation },
        ]"
        type="button"
        :aria-label="t('Book a demo')"
        @click.stop="bookACall"
      >
        <span
          :class="isCustomTheme ? 'bg-surface-default' : 'bg-surface-warning-default'"
          class="flex size-8 shrink-0 items-center justify-center rounded-full"
        >
          <LscIcon class="text-icon-default" size="md" icon="lsi-calendar" />
        </span>
        <LscOverflowEllipsis :class="{ 'pointer-events-none opacity-0': !isSidebarExpanded }">
          {{ t('Book a demo') }}
        </LscOverflowEllipsis>
      </button>
    </li>
    <li v-else-if="canTrialInviteUsers && !shouldShowInviteTeamSplitButton">
      <button
        v-LsdTooltip:right="isSidebarExpanded ? undefined : t('Add your team')"
        :class="[
          'flex h-12 w-full items-center gap-2 overflow-hidden py-2 text-left text-body-1 font-semibold',
          'bg-transparent',
          'text-[--lsds-c-sidebar-drawer-list-item-color-on-surface]',
          'hover:bg-[--lsds-c-sidebar-drawer-list-item-background-color-on-hover]',
          'hover:text-[--lsds-c-sidebar-drawer-list-item-color-on-hover]',
          'focus-visible:bg-[--lsds-c-sidebar-drawer-list-item-background-color-on-hover]',
          'focus-visible:text-[--lsds-c-sidebar-drawer-list-item-color-on-hover]',
          { 'px-0.5': isExpA2413cVariation, 'px-6': !isExpA2413cVariation },
        ]"
        type="button"
        :aria-label="t('Add your team')"
        @click.stop="handleAddTeamClicked"
      >
        <span
          :class="isCustomTheme ? 'bg-surface-default' : 'bg-surface-warning-default'"
          class="flex size-8 shrink-0 items-center justify-center rounded-full"
        >
          <LscIcon class="text-icon-default" size="md" icon="lsi-add-user" />
        </span>
        <LscOverflowEllipsis :class="{ 'pointer-events-none opacity-0': !isSidebarExpanded }">
          {{ t('Add your team') }}
        </LscOverflowEllipsis>
      </button>
    </li>
    <li v-if="canUpgradeAccount && !isPaid">
      <RouterLink
        v-LsdTooltip:right="isSidebarExpanded ? undefined : t('Upgrade now')"
        :class="[
          'flex h-12 items-center gap-2 overflow-hidden py-2 text-body-1 font-semibold no-underline',
          'bg-transparent',
          'text-[--lsds-c-sidebar-drawer-list-item-color-on-surface]',
          'hover:bg-[--lsds-c-sidebar-drawer-list-item-background-color-on-hover]',
          'hover:text-[--lsds-c-sidebar-drawer-list-item-color-on-hover]',
          'focus-visible:bg-[--lsds-c-sidebar-drawer-list-item-background-color-on-hover]',
          'focus-visible:text-[--lsds-c-sidebar-drawer-list-item-color-on-hover]',
          { 'px-0.5': isExpA2413cVariation, 'px-6': !isExpA2413cVariation },
        ]"
        to="/redirect/checkout"
      >
        <span
          class="flex size-8 shrink-0 items-center justify-center rounded-full"
          :class="isCustomTheme ? 'bg-surface-default' : 'bg-surface-highlight'"
        >
          <LscIcon class="text-icon-default" size="md" icon="lsi-sidenav-upgrade" />
        </span>
        <LscOverflowEllipsis :class="{ 'pointer-events-none opacity-0': !isSidebarExpanded }">
          {{ t('Upgrade now') }}
        </LscOverflowEllipsis>
      </RouterLink>
    </li>
    <LscSlotSwitch :name="dialogName">
      <template #UserAddYourTeamDialog>
        <UserAddYourTeamDialog v-model="isDialogOpen" panelSource="add_your_team" @usersInvited="handleUsersInvited" />
      </template>
    </LscSlotSwitch>
  </ul>
</template>
