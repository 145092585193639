<script setup>
import { useI18n } from '@/util';

const { t } = useI18n();
</script>
<template>
  <div class="text-body-1">
    <p>
      {{
        t(
          'Setting a Project Time Budget gives you real time reporting, full control over the time spent on individual projects and ultimately revolutionizes how time is spent across your business.',
        )
      }}
    </p>
    <p class="pt-6">
      {{
        t(
          'This feature allows you to set a budget of time on any given project, and then use the time logged on that project to calculate the remaining budget over time, leading to a better understanding of efficiency, time utilization and how well a project is running overall.',
        )
      }}
    </p>
  </div>
</template>
