/**
 * Provides functions for tracking events related to custom fields.
 * Measurement plan: https://digitalcrew.teamwork.com/spaces/product-analytics/page/42141-custom-fields-phase-i
 */

import { usePendo } from '@/api';

const EVENT_NAME = 'CUSTOM_FIELDS_EVENT';

export function useCustomfieldTracking() {
  const {
    trackPendoEvent,
    EVENT_CATEGORIES: { ADVANCED_DISCOVERY, ACTIVATION, DISCOVERY },
  } = usePendo();

  function track(metadata) {
    trackPendoEvent({
      eventName: EVENT_NAME,
      metadata,
    });
  }

  function trackAddTaskCustomfieldModalViewed() {
    track({
      page_type: 'custom_fields',
      event_action: 'add_task_custom_field_modal_viewed',
      event_category: ADVANCED_DISCOVERY,
    });
  }

  function trackAddProjectCustomfieldModalViewed() {
    track({
      page_type: 'custom_fields',
      event_action: 'add_project_custom_field_modal_viewed',
      event_category: ADVANCED_DISCOVERY,
    });
  }

  function trackTaskCustomfieldAdded(customfieldType) {
    track({
      page_type: 'custom_fields',
      event_action: 'task_custom_field_added',
      event_category: ACTIVATION,
      customfieldType,
    });
  }

  function trackProjectCustomfieldAdded(customfieldType) {
    track({
      page_type: 'custom_fields',
      event_action: 'project_custom_field_added',
      event_category: ACTIVATION,
      customfieldType,
    });
  }

  function trackCustomfieldAddedViaCustomReport() {
    track({
      page_type: 'custom_report_modal',
      event_action: 'custom_field_added',
      event_category: ACTIVATION,
    });
  }

  function trackCustomfieldSettingsViewed() {
    track({
      page_type: 'custom_fields',
      event_action: 'custom_fields_viewed',
      event_category: DISCOVERY,
    });
  }

  return {
    trackAddTaskCustomfieldModalViewed,
    trackAddProjectCustomfieldModalViewed,
    trackTaskCustomfieldAdded,
    trackProjectCustomfieldAdded,
    trackCustomfieldAddedViaCustomReport,
    trackCustomfieldSettingsViewed,
  };
}
