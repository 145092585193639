import {
  ONBOARDING_EVENT_COMMON_METRICS as commonMetrics,
  GOAL_BASED_ONBOARDING_EVENT_NAME as eventName,
} from '@/module/onboarding';
import { useCohort } from '../cohort/useCohort';
import { useFeatures } from '../features/useFeatures';
import { usePendo } from '../features/usePendo';

/**
 * Constants, computed properties and functions related to Experiment A-23-50
 * https://app.launchdarkly.com/default/staging/features/projects-exp-a-23-50-goal-based-onboarding/targeting
 * https://digitalcrew.teamwork.com/spaces/growth/page/49712-a-23-50-goal-based-onboarding
 */
export function useExperimentA2350() {
  const { projectsGoalBasedOnboardingExperimentEnabled } = useFeatures();
  const { isICP, isOwnerAdmin, isSiteAdmin, isInFreeTrial } = useCohort();
  const { trackExperimentInPendo, trackPendoEvent } = usePendo();

  const { launchDarklyFlagKey, defaultValue } = projectsGoalBasedOnboardingExperimentEnabled;

  const ignoreInExperimentOnRuleMatch = true;

  const expA2350AppLevelTargeting = computed(
    () => isICP.value && isInFreeTrial.value && (isOwnerAdmin.value || isSiteAdmin.value),
  );
  const isExpA2350Variation = computed(
    () => projectsGoalBasedOnboardingExperimentEnabled.value && expA2350AppLevelTargeting.value,
  );
  const isExpA2350Control = computed(
    () => !projectsGoalBasedOnboardingExperimentEnabled.value && expA2350AppLevelTargeting.value,
  );

  function track(metadata) {
    trackPendoEvent({
      eventName,
      commonMetrics,
      metadata,
    });
  }

  function trackBillingTypeClicked(billingType) {
    if (!billingType) {
      return;
    }

    track({
      event_action: 'billing_type_clicked',
      billing_type_clicked: billingType,
      page_type: 'project_select_billing_type',
    });
  }

  function trackBudgetPeriodSelected(budgetPeriod) {
    track({
      event_action: 'budget_period_selected',
      budget_period_chosen: budgetPeriod,
      page_type: 'project_set_budget',
    });
  }

  function trackExperimentA2350() {
    trackExperimentInPendo({
      launchDarklyFlagKey,
      defaultValue,
      ignoreInExperimentOnRuleMatch,
      appLevelTargeting: expA2350AppLevelTargeting.value,
    });
  }

  function trackBudgetTypeSelected(budgetType) {
    track({
      event_action: 'budget_type_selected',
      budget_type_selected: budgetType,
      page_type: 'project_set_budget',
    });
  }

  function trackGoalClicked(contextProjectExists, goalType, entryPoint, projectId) {
    track({
      event_action: 'goal_clicked',
      context_project_exists: contextProjectExists,
      goal_type: goalType,
      entry_point: entryPoint,
      project_id: projectId,
    });
  }

  function trackAllGoalsCompleted(entryPoint, projectId) {
    track({
      event_action: 'all_goals_completed',
      entry_point: entryPoint,
      project_id: projectId,
    });
  }

  function trackDismissForProject(projectId) {
    track({
      event_action: 'dismiss_for_this_project_clicked',
      entry_point: 'project_tab',
      project_id: projectId,
    });
  }

  function trackDismissForAll(projectId) {
    track({
      event_action: 'dismiss_for_all_projects_clicked',
      entry_point: 'project_tab',
      project_id: projectId,
    });
  }

  function trackGoalBasedOnboardingViewed(projectId) {
    track({
      event_action: 'goal_based_onboarding_viewed',
      context_project_exists: true,
      entry_point: 'project_tab',
      project_id: projectId,
    });
  }

  function trackAddTasksWithAIClicked(projectId, tasklistId) {
    trackPendoEvent({
      eventName: 'AI_TASKS_EVENT',
      commonMetrics,
      metadata: {
        event_action: 'add_tasks_with_ai_clicked',
        entry_point: 'list_view_blank_state',
        project_id: projectId,
        task_list_id: tasklistId,
      },
    });
  }

  return {
    isExpA2350Variation,
    isExpA2350Control,
    trackExperimentA2350,
    trackBillingTypeClicked,
    trackBudgetPeriodSelected,
    trackBudgetTypeSelected,
    trackGoalClicked,
    trackAllGoalsCompleted,
    trackDismissForProject,
    trackDismissForAll,
    trackGoalBasedOnboardingViewed,
    trackAddTasksWithAIClicked,
  };
}
