import { useRecencyActions } from '@/api';

/**
 * This logs the recently viewed entity to the Teamwork API.
 * @param {ComputedRef<import('../api/recency/useRecencyActions.js').Recent>} recent
 */
export function useRecency(recent) {
  const { logRecent } = useRecencyActions();

  watch(
    recent,
    (newRecent, prevRecent) => {
      if (
        !newRecent ||
        (newRecent.entityType === prevRecent?.entityType && newRecent.entityId === prevRecent.entityId)
      ) {
        return;
      }
      logRecent({ actionType: 'viewed', ...newRecent });
    },
    { immediate: true },
  );
}
