const symbol = Symbol('useAppDrawer');

export const DRAWERS = {
  desk: 'DeskDrawer',
  taskDetails: 'TaskDetails',
};

export function provideAppDrawerState() {
  const openSidePanel = shallowRef();

  function setActiveDrawerPanel(drawer) {
    openSidePanel.value = drawer;
  }

  provide(symbol, {
    openSidePanel,
    setActiveDrawerPanel,
  });
}

export function useAppDrawer(_drawer) {
  const drawer = unref(_drawer);
  const { openSidePanel, setActiveDrawerPanel } = inject(symbol);

  function open() {
    if (!Object.values(DRAWERS).includes(drawer)) {
      throw new Error('Drawer not found. Add it!');
    }
    setActiveDrawerPanel(drawer);
  }

  function close() {
    setActiveDrawerPanel(undefined);
  }

  const isOpen = computed({
    get() {
      return openSidePanel.value === drawer;
    },
    set(value) {
      if (value) {
        open();
      } else {
        close();
      }
    },
  });

  return {
    isOpen,
    open,
    close,
  };
}
