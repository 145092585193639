import { customfieldTypeIcons, customfieldUnits } from '../constants';

export function createCustomfieldFormatter(t) {
  const groupTitle = t('Custom fields');

  return function map(customfield) {
    return {
      title: customfield.name,
      value: customfield.id,
      props: {
        groupTitle,
        unit: customfieldUnits.DEFAULT,
        standard: false,
        searchText: customfield.name.toLowerCase(),
        icon: customfieldTypeIcons[customfield.type],
      },
    };
  };
}
