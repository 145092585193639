import { getLastChildPath } from '../lastChildPath';

export const planningRoutes = [
  {
    path: '/planning',
    component: () => import('./RoutePlanning.vue'),
    meta: {
      sampleProjectsBannerVisible: true,
    },
    children: [
      {
        path: '',
        redirect() {
          return `/planning/${getLastChildPath('planning', 'workload')}`;
        },
      },
      {
        path: 'chart',
        component: () => import('./RoutePlanningChart.vue'),
      },
      {
        path: 'overview',
        component: () => import('./RoutePlanningOverview.vue'),
      },
      {
        path: 'portfolio',
        component: () => import('./RoutePlanningPortfolio.vue'),
        children: [
          {
            path: '',
            redirect() {
              return '/planning/portfolio/boards';
            },
          },
          {
            path: 'boards',
            component: () => import('./RoutePlanningPortfolioBoards.vue'),
          },
          {
            path: 'boards/:boardId',
            component: () => import('./RoutePlanningPortfolioBoard.vue'),
          },
        ],
      },
      {
        path: 'newscheduler',
        redirect() {
          return '/planning/schedule';
        },
      },
      {
        path: 'schedule/:schedulerType',
        props: true,
        component: () => import('./RoutePlanningSchedule.vue'),
      },
      {
        path: 'schedule',
        redirect() {
          return `/planning/schedule/${getLastChildPath('planning/schedule', 'projects')}`;
        },
      },
      {
        path: 'workload',
        component: () => import('./RoutePlanningWorkload.vue'),
        children: [
          {
            path: ':pathMatch(.*)*',
            redirect() {
              return '/planning/workload';
            },
          },
        ],
      },
      {
        path: ':pathMatch(.*)*',
        redirect() {
          return '/planning/workload';
        },
      },
    ],
  },
];
