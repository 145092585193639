/**
 * Returns a valid item loader state containing the specified `item` and `meta`.
 */
export function useNoopItemLoader(item = null, meta = null) {
  return {
    item: shallowRef(item),
    inSync: shallowRef(true),
    loaded: shallowRef(true),
    meta: shallowRef(meta),
    error: shallowRef(undefined),
    retry: () => undefined,
  };
}
