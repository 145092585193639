import { useI18n } from '@/util';

/**
 * @typedef {import("../../components/media/avatar/constants").LscAvatar} LscAvatar
 */

const types = /** @type {const} */ ({
  deskAgent: 'deskAgent',
  user: 'user',
  users: 'users',
  team: 'team',
  teams: 'teams',
  company: 'company',
  companies: 'companies',
  client: 'client',
  clients: 'clients',
});

const teamIconMap = /** @type {const} */ ({
  'check-circle': 'lsi-task-complete',
  'circle-notch': 'lsi-incomplete-task',
  'external-link': 'lsi-external-link',
  'file-download': 'lsi-spaces-avatar-cloud-arrow-down',
  'file-upload': 'lsi-upload-new-file',
  'landline-phone': 'lsi-phone',
  'mobile-phone': 'lsi-group',
  'puzzle-piece': 'lsi-feature',
  'activity': 'lsi-active',
  'archive': 'lsi-archived',
  'billing': 'lsi-billable',
  'bolt': 'lsi-active',
  'bug': 'lsi-spaces-avatar-bug',
  'bullhorn': 'lsi-announcement',
  'checklist': 'lsi-managing-planning',
  'clone': 'lsi-copy',
  'compress': 'lsi-collapse-all',
  'copy': 'lsi-file-document',
  'delete': 'lsi-delete',
  'edit': 'lsi-edit',
  'event': 'lsi-calendar',
  'expand': 'lsi-expand-all',
  'file': 'lsi-file',
  'filter': 'lsi-filter',
  'followers': 'lsi-following',
  'globe': 'lsi-website',
  'grid': 'lsi-grid',
  'lightbulb': 'lsi-tips',
  'list': 'lsi-bulleted-list',
  'merge': 'lsi-subtask',
  'observer': 'lsi-user-security',
  'owner': 'lsi-assignee',
  'pause': 'lsi-pause',
  'people': 'lsi-assignees',
  'phone': 'lsi-phone',
  'pin': 'lsi-pin',
  'play': 'lsi-play',
  'project': 'lsi-project',
  'quickview': 'lsi-file-preview',
  'reassign': 'lsi-reassign',
  'save': 'lsi-save',
  'search': 'lsi-search',
  'shield': 'lsi-security',
  'status': 'lsi-bugle',
  'stop': 'lsi-stop',
  'subtask': 'lsi-subtask',
  'tasks': 'lsi-task-complete',
  'timer': 'lsi-time',
  'users': 'lsi-group',
  'wrench': 'lsi-maintenance',
});

/**
 * Get the icon of the team mapped to our icon library
 * @param {{ logoIcon ?: string, icon?: string}} team
 * @returns {typeof teamIconMap[keyof typeof teamIconMap]|undefined}
 */
function getLsTeamIcon(team) {
  const icon = team?.logoIcon ?? team?.teamLogoIcon;
  return teamIconMap[icon];
}

/**
 * Get the HEX color of the team icon
 * @param {{ logoColor ?: string, teamLogoColor ?: string}} team
 * @returns {string}
 */
function getLsTeamColor(team) {
  return team.logoColor || team.teamLogoColor;
}

/**
 * Get the image url of the team
 * @param {{ logoUrl ?: string, teamLogoUrl ?: string}} team
 */
function getLsTeamImageUrl(team) {
  return team.teamLogo || team.logoUrl || team.teamLogoUrl;
}

/**
 * Get the avatar props for a Desk deskAgent
 * @param {{ avatarURL ?: string, firstName: string, lastName: string, id: number }} deskAgent
 * @returns {LscAvatar}
 */
function getDeskAgentAvatarProps(deskAgent) {
  if (!deskAgent || deskAgent.id === -1) {
    return {};
  }

  const { t } = useI18n();

  let name = '';
  if (deskAgent.firstName && deskAgent.lastName) {
    name = `${deskAgent.firstName} ${deskAgent.lastName}`;
  } else if (deskAgent['first-name'] && deskAgent['last-name']) {
    name = `${deskAgent['first-name']} ${deskAgent['last-name']}`;
  }

  const isArchived = deskAgent.state === 'archived';
  if (isArchived) {
    name += ` (${t('Agent archived')})`;
  }

  // Desk sets domain/desk/images/examples/noPhoto1.png avatarUrl for all user without the avatar
  const hasNoAvatar = deskAgent.avatarURL.includes('/desk/images/examples/noPhoto1.png');

  return {
    entityType: types.deskAgent,
    src: hasNoAvatar ? '' : deskAgent.avatarURL,
    id: deskAgent.id,
    name,
    class: { 'opacity-50': isArchived },
  };
}

/**
 * Get the avatar props for a user
 * @param {{ avatarUrl ?: string, firstName: string, lastName: string }} user
 * @returns {LscAvatar}
 */
function getUserAvatarProps(user) {
  if (!user) {
    return {};
  }

  let name = '';
  if (user.firstName && user.lastName) {
    name = `${user.firstName} ${user.lastName}`;
  } else if (user['first-name'] && user['last-name']) {
    name = `${user['first-name']} ${user['last-name']}`;
  }
  return {
    entityType: 'user',
    src: user.avatarUrl,
    id: user.id,
    name,
  };
}

/**
 * Return the avatar props for a company
 * @param {{ logoUrl ?: string, name: string }} company
 * @returns {LscAvatar}
 */
function getCompanyAvatarProps(company) {
  if (!company) {
    return {};
  }
  return {
    entityType: 'company',
    id: company.id,
    icon: 'lsi-company',
    name: company.name,
    src: company.logoUrl,
  };
}

/**
 * Return the avatar props for a team
 * @param {*} team
 * @returns {LscAvatar}
 */
function getTeamAvatarProps(team) {
  if (!team) {
    return {};
  }
  const teamImageUrl = getLsTeamImageUrl(team);
  return {
    entityType: 'team',
    icon: getLsTeamIcon(team),
    id: team.id,
    src: teamImageUrl,
    bgColor: teamImageUrl ? null : getLsTeamColor(team),
    iconColor: teamImageUrl ? null : 'var(--lsds-a-color-text-on-primary)',
    name: team.name,
  };
}
/**
 * Return the avatar props for the given entity based on its type
 * @param {{ [k in keyof typeof types]: object }} object
 * @returns {LscAvatar}
 */
export function getLsAvatarProps(object) {
  let avatarType;
  // eslint-disable-next-line no-restricted-syntax
  for (const key in object) {
    if (avatarType === undefined) {
      avatarType = key;
    } else {
      // eslint-disable-next-line no-console
      console.warn(
        `You must pass in an object with exactly one of the following keys: ${Object.keys(types).join(', ')}`,
      );
      return {};
    }
  }
  const entity = object[avatarType];

  switch (avatarType) {
    case types.deskAgent:
      return getDeskAgentAvatarProps(entity);
    case types.user:
    case types.users:
      return getUserAvatarProps(entity);
    case types.team:
    case types.teams:
      return getTeamAvatarProps(entity);
    case types.company:
    case types.companies:
    case types.client:
    case types.clients:
      return getCompanyAvatarProps(entity);
    default: {
      // eslint-disable-next-line no-console
      console.warn(
        `You must pass in an object with exactly one of the following keys: ${Object.keys(types).join(', ')}`,
      );
      return {};
    }
  }
}
