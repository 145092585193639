import { getLastChildPath } from '../lastChildPath';

export const companyRoutes = [
  {
    path: '/companies/:companyId',
    component: () => import('./RouteCompany.vue'),
    props: (route) => ({
      companyId: Number(route.params.companyId),
    }),
    children: [
      {
        path: '',
        redirect(to) {
          return `/companies/${to.params.companyId}/${getLastChildPath('company', 'profile')}`;
        },
      },
      {
        path: 'profile',
        component: () => import('./RouteCompanyProfile.vue'),
      },
      {
        path: 'people',
        component: () => import('./RouteCompanyPeople.vue'),
      },
      {
        path: 'teams',
        component: () => import('./RouteCompanyTeams.vue'),
      },
      {
        path: 'loggedtime',
        component: () => import('./RouteCompanyLoggedTime.vue'),
      },
      {
        path: 'projects',
        component: () => import('./RouteCompanyProjects.vue'),
      },
    ],
  },
  {
    path: '/companies',
    component: () => import('./RouteCompanies.vue'),
  },
];
