import { useCalendarSyncStatusV2Loader, usePermissions, useUserIntegrationsV2Loader } from '@/api';

const symbol = Symbol('useCalendarGoogleSyncStatus');

function CalendarGoogleSyncStatus() {
  const { isSiteAdmin } = usePermissions();
  const { item: integrations, loaded: integrationsLoaded } = useUserIntegrationsV2Loader();
  const { isSynced, isSyncInProgress, isSyncExpired, isSyncFailed, isSyncStalled, isLoading, hasSyncError } =
    useCalendarSyncStatusV2Loader();

  const integration = computed(() => integrations.value?.find(({ code }) => code === 'yoxel_google_calendar'));
  const isIntegrationActive = computed(() => integration.value?.active);
  const canEnableIntegration = computed(() => isSiteAdmin.value && integrationsLoaded.value && !!integration.value);
  const shouldShowCalendarAlert = computed(() => isSyncExpired.value || isSyncStalled.value || hasSyncError.value);
  const isSyncStatusLoading = computed(() => isLoading.value);

  return {
    // integration flag
    isIntegrationActive,
    canEnableIntegration,
    // calendar sync flags
    isSynced,
    isSyncInProgress,
    isSyncExpired,
    isSyncFailed,
    isSyncStalled,
    shouldShowCalendarAlert,
    isSyncStatusLoading,
  };
}

export function provideCalendarGoogleSyncStatus() {
  provide(symbol, CalendarGoogleSyncStatus());
}

export function useCalendarGoogleSyncStatus() {
  return inject(symbol);
}
