<script setup>
import { providePreferences } from '../preferences/usePreferences';
import ApiSetupEmbed6 from './ApiSetupEmbed6.vue';

providePreferences();
</script>

<template>
  <ApiSetupEmbed6>
    <slot />
  </ApiSetupEmbed6>
</template>
