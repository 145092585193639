<script setup>
import { useI18n } from '@/util';

const { t } = useI18n();
</script>
<template>
  <div class="text-body-1">
    <p>
      {{
        t(
          'Track how long it takes to complete projects and individual pieces of work so you can ensure that your work is being accurately billed for, while also helping you to manage your own time and plan your workload.',
        )
      }}
    </p>
    <p class="mt-4">
      {{ t('Over the next 2 minutes, discover how you can:') }}
    </p>
    <ul class="ml-4 list-disc">
      <!-- Track billable time -->
      <li>{{ t('Log time on your tasks') }}</li>
      <li>
        {{ t('Log time in bulk in one place') }}
      </li>
    </ul>
  </div>
</template>
