import { useI18n } from '@/util';
import { useAxios } from '../base/useAxios';

export function useFeedbackActions() {
  const api = useAxios();
  const { t } = useI18n();

  return {
    postFeedback: (formData) =>
      api.post('/feedback.json', formData, {
        errorMessage: t(
          'Sorry! There was a problem sending the email, please contact support team through another via.',
        ),
      }),
  };
}
