<script setup>
import { useI18n } from '@/util';
import LscAvatar from '../../../components/media/avatar/LscAvatar.vue';
import LscAvatarStack from '../../../components/media/avatar/LscAvatarStack.vue';
import { useAssigneePicker } from './useAssigneePicker';

const props = defineProps({
  avatars: {
    type: Array,
    default: () => [],
  },
  /**
   * The size of the avatar.
   * @default 'sm'
   * @type {PropType<LscAvatarSize>}
   */
  size: {
    type: String,
    default: 'sm',
  },
  /**
   * The maximum number of avatars to display.
   */
  max: {
    type: Number,
    default: 3,
    validator: (v) => v > 0,
  },
  dataTestIdPrefix: {
    type: String,
    default: undefined,
  },
  dataIdentifierPrefix: {
    type: String,
    default: undefined,
  },
  blankTooltip: {
    type: String,
    default: undefined,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  label: {
    type: String,
    default: undefined,
  },
});

const { t } = useI18n();
const { multiple } = useAssigneePicker();

const computedBlankTooltip = computed(() => {
  if (props.disabled) {
    return undefined;
  }
  if (props.blankTooltip) {
    return props.blankTooltip;
  }
  return multiple.value ? t('Assign to one or more people') : t('Assign to someone');
});
</script>

<template>
  <button
    type="button"
    :disabled="disabled"
    class="group/LscAssigneePickerActivator inline-flex items-center gap-2 rounded-full"
    :class="{
      'cursor-pointer': !disabled,
      'cursor-not-allowed': disabled,
    }"
    :data-test-id="dataTestIdPrefix ? `${dataTestIdPrefix}-assignee-icon` : undefined"
    :data-identifier="dataIdentifierPrefix ? `${dataIdentifierPrefix}-assignee-picker` : undefined"
  >
    <LscAvatarStack v-if="avatars.length" :size="size" :avatars="avatars" :max="max" />
    <template v-else>
      <LscAvatar
        v-LsdTooltip="computedBlankTooltip"
        :class="{ 'group-hover/LscAssigneePickerActivator:border-strong': label }"
        :variant="label ? 'on-surface' : undefined"
        :size="size"
      />
      <LscOverflowEllipsis v-if="label" class="text-subtle group-hover/LscAssigneePickerActivator:text-default">
        {{ label }}
      </LscOverflowEllipsis>
    </template>
  </button>
</template>
