import { DateTime } from 'luxon';
import { useItemLoader } from '../base/useItemLoader';
import { useOptimisticUpdates } from '../base/useOptimisticUpdates';
import { useRealTimeUpdates } from '../base/useRealTimeUpdates';

const keyMapping = new Map([
  ['companyid', 'companyId'],
  ['companyname', 'companyName'],
  ['datesignedup', 'dateSignedUp'],
  ['priceplanAllowSSL', 'pricePlanAllowSSL'],
  ['priceplanCanTurnOffTWBranding', 'pricePlanCanTurnOffTWBranding'],
  ['priceplanData', 'pricePlanData'],
  ['priceplanTrialData', 'pricePlanTrialData'],
  ['priceplanMaxUsers', 'pricePlanMaxUsers'],
  ['priceplanType', 'pricePlanType'],
  ['pricePlan', 'pricePlanCode'],
  ['companysizesId', 'companySizeId'],
]);

function responseToItem({ data: { account: rawAccount } }) {
  const account = {};
  Object.keys(rawAccount).forEach((key) => {
    const normalizedKey = keyMapping.get(key) || key.replace(/-./g, (match) => match.slice(1).toUpperCase());
    account[normalizedKey] = rawAccount[key];
  });

  account.accountHolderId = Number(account.accountHolderId);
  account.companyId = Number(account.companyId);
  account.id = Number(account.id);
  account.isPaid = account.isPaid === '1';
  account.memberOfOwnerCompany = account.memberOfOwnerCompany === 'YES';
  account.paidForUsers = Number(account.paidForUsers);
  account.paidUntilDateTime = account.paidUntilDateTime ? DateTime.fromISO(account.paidUntilDateTime) : undefined;
  account.pricePlanId = Number(account.pricePlanId);
  account.pricePlanMaxUsers = Number(account.pricePlanMaxUsers);
  account.shardNo = Number(account.shardNo);
  account.tasklistTemplateProjectId = Number(account.tasklistTemplateProjectId);
  account.installationTeamsCount = Number(account.installationTeamsCount);
  account.createdAt = DateTime.fromISO(account.createdAt);
  account.dateSignedUp = DateTime.fromISO(account.dateSignedUp);
  account.inGracePeriod = Boolean(account.inGracePeriod);
  account.installationDateFirstPayment = DateTime.fromISO(account.installationDateFirstPayment);
  account.companySizeId = Number(account.companySizeId);
  account.industryCategoryId = Number(account.industryCategoryId);
  account.sessionTimeoutMinutes = Number(account.sessionTimeoutMinutes ?? 0);

  const { currency, pricePlanData, pricePlanTrialData } = account;

  if (pricePlanData) {
    pricePlanData.id = Number(pricePlanData.id);
    pricePlanData.maxFinancialBudgets = Number(pricePlanData.maxFinancialBudgets);
    pricePlanData.maxUsers = Number(pricePlanData.maxUsers);
  }

  if (currency) {
    currency.id = Number(currency.id);
    currency.decimalPoints = Number(currency.decimalPoints);
  }

  if (pricePlanTrialData) {
    pricePlanTrialData.createdDate = DateTime.fromISO(pricePlanTrialData.createdDate);
    pricePlanTrialData.startDate = DateTime.fromISO(pricePlanTrialData.startDate);
    pricePlanTrialData.endDate = DateTime.fromISO(pricePlanTrialData.endDate);
    pricePlanTrialData.id = Number(pricePlanTrialData.id);
    pricePlanTrialData.maxUsers = Number(pricePlanTrialData.maxUsers);
    pricePlanTrialData.maxFinancialBudgets = Number(pricePlanTrialData.maxFinancialBudgets);

    pricePlanTrialData.remainingDays = pricePlanTrialData.endDate.diff(DateTime.now()).as('days');
  }

  return account;
}

/**
 * Loads the current account.
 */
export function useCurrentAccountV1Loader({ params } = {}) {
  const { state, refresh, update } = useItemLoader({
    url: '/account.json',
    params,
    responseToItem,
  });

  useOptimisticUpdates((event) => {
    if (event.type === 'account') {
      update((account) => {
        if (event.action === 'update') {
          return {
            ...account,
            ...event.account,
          };
        }
        return account;
      }, event.promise);
    }
  });

  useRealTimeUpdates((event) => {
    if (event.type === 'account') {
      refresh();
      return;
    }

    if (event.type === 'accountPreference') {
      refresh();
      // return;
    }
  });

  return state;
}
