<script setup>
import { provideFeatures } from '../features/useFeatures';
import ApiSetup4 from './ApiSetup4.vue';

provideFeatures();
</script>

<template>
  <ApiSetup4><slot /></ApiSetup4>
</template>
