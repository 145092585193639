import { DateTime } from 'luxon';
import { isValidId } from '@/util';
import { useListLoader } from '../../base/useListLoader';
import { useOptimisticUpdates } from '../../base/useOptimisticUpdates';
import { useRealTimeUpdates } from '../../base/useRealTimeUpdates';

function responseToItems({ data }) {
  const { workflows, included } = data;
  const { stages = {}, users = {}, companies = {}, ProjectPermissions = {}, projects = {} } = included;

  /* eslint-disable no-param-reassign */
  workflows.forEach((workflow) => {
    if (workflow.stages?.length && Object.values(stages).length) {
      workflow.stages = workflow.stages
        .map(({ id }) => stages[id])
        .filter(Boolean)
        .sort((a, b) => a.displayOrder - b.displayOrder);
    }
    if (workflow.companies?.length && Object.values(companies).length) {
      workflow.companies = workflow.companies.map((company) => companies[company.id]).filter(Boolean);
    }
    if (workflow.createdBy && users[workflow.createdBy]) {
      workflow.createdBy = users[workflow.createdBy];
    }
    if (workflow.projectSpecific && workflow.projectIds?.length) {
      workflow.project = projects[workflow.projectIds[0]];
    }
    workflow.projectPermissions = workflow.projectIds?.length
      ? workflow.projectIds.reduce((permissions, projectId) => {
          return {
            ...permissions,
            [projectId]: ProjectPermissions[projectId] || {},
          };
        }, {})
      : {};
  });
  /* eslint-enable no-param-reassign */

  return workflows;
}

function dateComparator(d1, d2, desc = false) {
  const a = DateTime.fromISO(d1);
  const b = DateTime.fromISO(d2);

  if (!a.isValid && !b.isValid) {
    return 0;
  }

  if (!a.isValid) {
    return 1;
  }

  if (!b.isValid) {
    return -1;
  }

  let comparison = 0;

  if (a < b) {
    comparison = -1;
  } else if (a > b) {
    comparison = 1;
  }

  return desc ? -comparison : comparison;
}

function orderByDateCreated({ createdAt: a }, { createdAt: b }, desc = false) {
  return dateComparator(a, b, desc);
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function orderByDescDateCreated(a, b) {
  return orderByDateCreated(a, b, true);
}

function orderByAscDateCreated(a, b) {
  return orderByDateCreated(a, b, false);
}

function orderByDefault(a, b) {
  return orderByAscDateCreated(a, b);
}

export function useWorkflowsV3Loader({
  /**
   * The project from which to load workflows.
   */
  projectId: _projectId,
  params = {},
  count,
  pageSize,
  cache = true,
}) {
  const projectId = shallowRef(_projectId);

  const url = computed(() => {
    if (isValidId(projectId.value)) {
      return `/projects/api/v3/projects/${projectId.value}/workflows.json`;
    }

    return '/projects/api/v3/workflows.json';
  });

  const order = computed(() => {
    const { orderBy: _orderBy, orderMode } = params.value || {};

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const desc = orderMode ? orderMode !== 'asc' : false;

    const orderBy = _orderBy ? _orderBy.toLowerCase() : '';

    // Note: API doesn't currently support adjusting sort order, this is just for optimistic updates
    switch (orderBy) {
      default:
        return orderByDefault;
    }
  });

  const { state, refresh, update } = useListLoader({
    url,
    params,
    responseToItems,
    responseToMeta: ({ data }) => data.meta,
    count,
    pageSize,
    order,
    cache,
  });

  useOptimisticUpdates((event) => {
    if (event.type !== 'workflow') {
      return;
    }
    update((workflows) => {
      if (event.action === 'delete') {
        return workflows.filter((workflow) => workflow.id !== event.workflow.id);
      }
      if (event.action === 'update') {
        return workflows.map((workflow) =>
          workflow.id === event.workflow.id ? { ...workflow, ...event.workflow } : workflow,
        );
      }
      if (event.action === 'create') {
        return workflows.concat(event.workflow);
      }

      return workflows;
    }, event.promise);
  });

  useRealTimeUpdates((event) => {
    if (['workflow', 'workflow-project'].includes(event.type)) {
      if (
        event.action === 'workflow-linked' ||
        event.action === 'workflow-unlinked' ||
        event.action === 'workflow-updated'
      ) {
        refresh();
      } else {
        refresh(event.workflowId);
      }
      return;
    }

    // To refresh sideloaded stages
    if (event.type === 'stage') {
      event.affectedWorkflowIds?.forEach((workflowId) => {
        refresh(workflowId);
      });
    }
  });

  return state;
}
