import { useItemLoader } from '../base/useItemLoader';

function responseToItem({ data: { info } }) {
  return info;
}

/**
 * Loads the launchpad information.
 */
export function useLaunchpadInfoV1Loader({ params } = {}) {
  const { state } = useItemLoader({
    url: '/launchpad/v1/info.json',
    params,
    responseToItem,
  });

  return state;
}
