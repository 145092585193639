/**
 * Returns a valid list loader state containing the specified `items` and `meta`.
 */
export function useNoopListLoader(items = [], meta = null) {
  return {
    items: shallowRef(items),
    hasMore: shallowRef(false),
    inSync: shallowRef(true),
    itemInSync: (item) => unref(items).includes(item),
    loaded: shallowRef(true),
    meta: shallowRef(meta),
    error: shallowRef(undefined),
    retry: () => undefined,
  };
}
