import { useBudgetsCountsV3Loader, useFeatures } from '@/api';
import { useBudgetCountsState } from '../budget/useBudgetCounts';

function normalizeBudgetLimit(value) {
  if (value === null || value === '' || value === -1) {
    return Infinity;
  }
  if (value === undefined || Number.isNaN(value)) {
    return 0;
  }
  return Number(value);
}

export function useBudgetsLimit({ type, isNewBudget }) {
  const {
    financialBudgetLimit,
    timeBudgetLimit,
    retainerBudgetsLimit,
    projectTimeBudgetsEnabled,
    projectFinancialBudgetsEnabled,
    retainerBudgetsEnabled,
    fixedFeeBudgetsLimit,
    fixedFeeBudgetsEnabled,
    projectsFixedFeeBudgetsEnabled,
  } = useFeatures();

  // Fallback to loader if state is not provided
  const { item: projectBudgetCounts } =
    useBudgetCountsState() ?? useBudgetsCountsV3Loader({ params: { skipCounts: false } });

  const activeAndUpcomingBudgetsCount = computed(() => {
    const upcoming = projectBudgetCounts.value[type]?.upcoming ?? 0;
    const active = projectBudgetCounts.value[type]?.active ?? 0;
    return active + upcoming;
  });

  const newActiveBudgetCount = computed(() => {
    if (!projectBudgetCounts.value) {
      return 0;
    }
    // if the user is editing an existing budget
    // do not count it
    return isNewBudget ? activeAndUpcomingBudgetsCount.value : activeAndUpcomingBudgetsCount.value - 1;
  });

  const isBudgetEnabled = computed(() => {
    const map = {
      financial: projectFinancialBudgetsEnabled.value,
      time: projectTimeBudgetsEnabled.value,
      retainer: retainerBudgetsEnabled.value,
      fixedFee: fixedFeeBudgetsEnabled.value || projectsFixedFeeBudgetsEnabled.value,
    };

    return map[type];
  });

  const budgetsLimit = computed(() => {
    const map = {
      financial: normalizeBudgetLimit(financialBudgetLimit.value),
      time: normalizeBudgetLimit(timeBudgetLimit.value),
      retainer: normalizeBudgetLimit(retainerBudgetsLimit.value),
      fixedFee: normalizeBudgetLimit(fixedFeeBudgetsLimit.value),
    };
    return map[type];
  });

  const isOverBudgetLimit = computed(() => newActiveBudgetCount.value >= budgetsLimit.value);

  return {
    featureLimit: budgetsLimit,
    isFeatureEnabled: isBudgetEnabled,
    isOverFeatureLimit: isOverBudgetLimit,
  };
}
