<script setup>
import { DateTime, Info } from 'luxon';
import { isBetweenDates, useI18n, useNowByMinute } from '@/util';
import { useDatePicker } from './useDatePicker';

const { languageCode, t } = useI18n();
const { hasDateProp, hasDatesProp, date, dates, clearable, minDate, maxDate, rangeMode, enableWeekends } =
  useDatePicker();

const now = useNowByMinute();
const today = computed(() => now.value.startOf('day'));

const normalizedMinDate = computed(() => {
  if (hasDateProp.value) {
    return minDate.value;
  }
  if (rangeMode.value === 'end') {
    return DateTime.max(...[dates.value[0], minDate.value].filter(Boolean));
  }
  return minDate.value;
});

const normalizedMaxDate = computed(() => {
  if (hasDateProp.value) {
    return maxDate.value;
  }
  if (rangeMode.value === 'start') {
    return DateTime.min(...[dates.value[1], maxDate.value].filter(Boolean));
  }
  return maxDate.value;
});

function getAllowedIncrementedDate(increment) {
  let dateToIncrement;
  if (hasDateProp.value) {
    dateToIncrement = date.value ?? today.value;
  } else if (hasDatesProp.value) {
    dateToIncrement =
      rangeMode.value === 'start' ? (dates.value?.[0] ?? today.value) : (dates.value?.[1] ?? today.value);
  }
  const newDate = dateToIncrement.plus({ day: increment });
  if (!enableWeekends.value && [6, 7].includes(newDate.weekday)) {
    return newDate.plus({ day: 8 - newDate.weekday });
  }
  return newDate;
}

function setActiveDate(selectedDate) {
  if (hasDateProp.value) {
    date.value = selectedDate;
  } else if (rangeMode.value === 'start') {
    dates.value = [selectedDate, dates.value?.[1]];
  } else {
    dates.value = [dates.value?.[0], selectedDate];
  }
}

/* Should hide the today button if skipWeekends is turned on and the current day is a weekend day */
const isTodayButtonVisible = computed(() => enableWeekends.value || !(enableWeekends.value && today.value.isWeekend));
const canSetDateToToday = computed(() =>
  isBetweenDates({
    dateTime: today.value,
    minDate: normalizedMinDate.value,
    maxDate: normalizedMaxDate.value,
  }),
);
function setDateToday() {
  setActiveDate(today.value);
}

const prospectiveDateInOneDay = computed(() => getAllowedIncrementedDate(1));
const canIncrementDateByOneDay = computed(() =>
  isBetweenDates({
    dateTime: prospectiveDateInOneDay.value,
    minDate: normalizedMinDate.value,
    maxDate: normalizedMaxDate.value,
  }),
);
function incrementDateByOneDay() {
  setActiveDate(prospectiveDateInOneDay.value);
}

const prospectiveDateInSevenDays = computed(() => getAllowedIncrementedDate(7));
const canIncrementDateBySevenDays = computed(() =>
  isBetweenDates({
    dateTime: prospectiveDateInSevenDays.value,
    minDate: normalizedMinDate.value,
    maxDate: normalizedMaxDate.value,
  }),
);
function incrementDateBySevenDays() {
  setActiveDate(prospectiveDateInSevenDays.value);
}

const mondayString = computed(() => Info.weekdays('short', { locale: languageCode.value }).at(0));
const shouldShowNextMondayShortcut = computed(() => [5, 6, 7].includes(today.value.weekday));
const prospectiveMonday = computed(() => today.value.plus({ day: 8 - today.value.weekday }));
const canSetDateToNextMonday = computed(() =>
  isBetweenDates({
    dateTime: prospectiveMonday.value,
    minDate: normalizedMinDate.value,
    maxDate: normalizedMaxDate.value,
  }),
);

function setDateToNextMonday() {
  setActiveDate(prospectiveMonday.value);
}

function setDateNone() {
  setActiveDate(null);
}
</script>

<template>
  <div class="flex justify-center divide-x divide-separator">
    <button
      v-if="isTodayButtonVisible"
      type="button"
      :disabled="!canSetDateToToday"
      class="px-3 text-body-2 font-semibold text-action-primary-default hover:text-action-primary-hover disabled:text-disabled"
      @click="setDateToday"
    >
      {{ t('Today') }}
    </button>
    <button
      type="button"
      :disabled="!canIncrementDateByOneDay"
      class="px-3 text-body-2 font-semibold text-action-primary-default hover:text-action-primary-hover disabled:text-disabled"
      @click="incrementDateByOneDay"
    >
      {{ `+${t('{n} day | {n} days', { n: 1 })}` }}
    </button>
    <button
      type="button"
      :disabled="!canIncrementDateBySevenDays"
      class="px-3 text-body-2 font-semibold text-action-primary-default hover:text-action-primary-hover disabled:text-disabled"
      @click="incrementDateBySevenDays"
    >
      {{ `+${t('{n} day | {n} days', { n: 7 })}` }}
    </button>
    <button
      v-if="shouldShowNextMondayShortcut"
      v-LsdTooltip="canSetDateToNextMonday ? t('Set date to the upcoming Monday') : t('Next Monday is not available')"
      :disabled="!canSetDateToNextMonday"
      type="button"
      class="px-3 text-body-2 font-semibold text-action-primary-default hover:text-action-primary-hover disabled:text-disabled"
      @click="setDateToNextMonday"
    >
      {{ mondayString }}
    </button>
    <button
      v-if="clearable"
      type="button"
      class="px-3 text-body-2 font-semibold text-action-primary-default hover:text-action-primary-hover"
      @click="setDateNone"
    >
      {{ t('None') }}
    </button>
  </div>
</template>
