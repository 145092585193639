<script setup>
import { DateTime } from 'luxon';
import LscMenu from '../../overlay/menu/LscMenu.vue';
import LscDatePickerCalendar from './LscDatePickerCalendar.vue';
import LscDatePickerCalendarControls from './LscDatePickerCalendarControls.vue';
import LscDatePickerRangeInputs from './LscDatePickerRangeInputs.vue';
import LscDatePickerShortcuts from './LscDatePickerShortcuts.vue';
import { DatePickerSymbol, provideDatePicker } from './useDatePicker';

const props = defineProps({
  /**
   * A function that returns a tooltip for a disabled day.
   * @type {PropType<(day: DateTime) => String | object>}
   */
  disabledDayTooltipFunction: {
    type: Function,
    default: undefined,
  },
  /**
   * Gives the ability to turn off the start date field.
   * @type {PropType<boolean>}
   */
  showStartDate: {
    type: Boolean,
    default: true,
  },
  /**
   * The minimum date that can be selected.
   * @type {PropType<DateTime|undefined>}
   */
  minDate: {
    type: Object,
    default: undefined,
    validator: (date) => date == null || (DateTime.isDateTime(date) && date.isValid),
    required: false,
  },
  /**
   * The maximum date that can be selected.
   * @type {PropType<DateTime|undefined>}
   */
  maxDate: {
    type: Object,
    default: undefined,
    validator: (date) => date == null || (DateTime.isDateTime(date) && date.isValid),
    required: false,
  },
  /**
   * If the date picker is clearable.
   * @type {PropType<boolean>}
   */
  clearable: {
    type: Boolean,
    default: true,
  },
  /**
   * Label for the start date field.
   * @default 'Start date'
   * @type {PropType<string>}
   */
  startDateLabel: {
    type: String,
    default: undefined,
  },
  /**
   * Label for the end date field
   * @default 'End date'
   * @type {PropType<string>}
   */
  endDateLabel: {
    type: String,
    default: undefined,
  },
  /**
   * If the shortcuts should be shown.
   * @type {PropType<boolean>}
   */
  showShortcuts: {
    type: Boolean,
    default: true,
  },
  /**
   * The prefix for the data test ids.
   * @type {PropType<string>}
   */
  dataTestIdPrefix: {
    type: String,
    default: undefined,
  },
  /**
   * Whether the menu should close on mouseleave.
   * This is only needed if the menu isn't opened by openOnHover, for that
   * already automatically closes the menu on mouseleave.
   * @type {PropType<boolean>}
   */
  closeOnMouseLeave: {
    type: Boolean,
    default: false,
  },
  /**
   * Whether weekend days should be enabled on the calendar.
   * @type {PropType<Boolean>}
   */
  enableWeekends: {
    type: Boolean,
    default: true,
  },
  /**
   * Prevent this menu closing
   * @type {PropType<boolean>}
   */
  preventParentMenuClose: {
    type: Boolean,
    default: false,
  },
});

/**
 * Controls the visibility of the menu.
 * @type {ModelRef<boolean>}
 */
const modelValue = defineModel({
  type: Boolean,
  default: false,
});
/**
 * Used to select a single date.
 * @type {ModelRef<DateTime|undefined>}
 */
const date = defineModel('date', {
  default: DatePickerSymbol,
  validator(value) {
    return value === DatePickerSymbol || value == null || (DateTime.isDateTime(value) && value.isValid);
  },
});
/**
 * Used to select a range of dates.
 * @type {ModelRef<[DateTime|undefined, DateTime|undefined]>}
 */
const dates = defineModel('dates', {
  default: DatePickerSymbol,
  validator(value) {
    return (
      value === DatePickerSymbol ||
      value == null ||
      (Array.isArray(value) &&
        value.length === 2 &&
        (value[0] == null || (DateTime.isDateTime(value[0]) && value[0].isValid)) &&
        (value[1] == null || (DateTime.isDateTime(value[1]) && value[1].isValid)))
    );
  },
});

/**
 * The mode of the range picker.
 * @type {ModelRef<'start'|'end'>}
 */
const rangeMode = defineModel('rangeMode', {
  type: String,
  default: 'start',
});

const { updateExternalDateRange, hasDatesProp } = provideDatePicker({
  clearable: computed(() => props.clearable),
  dataTestIdPrefix: computed(() => props.dataTestIdPrefix),
  date,
  dates,
  disabledDayTooltipFunction: computed(() => props.disabledDayTooltipFunction),
  enableWeekends: computed(() => props.enableWeekends),
  endDateLabel: computed(() => props.endDateLabel),
  maxDate: computed(() => props.maxDate),
  minDate: computed(() => props.minDate),
  modelValue,
  rangeMode,
  showShortcuts: computed(() => props.showShortcuts),
  showStartDate: computed(() => props.showStartDate),
  startDateLabel: computed(() => props.startDateLabel),
});

const monthMenuOpen = shallowRef(false);

function closePopover() {
  if (props.closeOnMouseLeave && !monthMenuOpen.value) {
    setTimeout(() => {
      modelValue.value = false;
    }, 400);
  }
}

// Update the model value when the menu closes
watch(modelValue, (isOpen) => {
  if (!isOpen) {
    updateExternalDateRange();
  }
});
</script>

<template>
  <LscMenu
    v-model="modelValue"
    :closeOnContentClick="false"
    :preventParentMenuClose="monthMenuOpen || preventParentMenuClose"
    @mouseleave="closePopover"
  >
    <template #activator="activator">
      <slot name="activator" v-bind="activator" />
    </template>
    <div
      class="w-[--lsds-c-popover-width-lg] rounded-md bg-default shadow-3"
      role="application"
      aria-label="date-picker"
    >
      <div
        class="flex flex-col items-stretch gap-2 overflow-hidden pt-3"
        :class="{
          'pb-3': !$slots.footer && !showShortcuts,
        }"
      >
        <LscDatePickerRangeInputs v-if="hasDatesProp" class="mx-4 mt-1" />
        <LscDatePickerCalendarControls v-model:monthMenuOpen="monthMenuOpen" class="mx-3" />
        <LscDatePickerCalendar />
        <div
          v-if="showShortcuts || $slots.footer"
          class="flex flex-col justify-center gap-2 border-t border-separator py-3"
        >
          <LscDatePickerShortcuts v-if="showShortcuts" />
          <div v-if="$slots.footer" class="mx-3 flex items-center justify-center">
            <slot name="footer" />
          </div>
        </div>
      </div>
    </div>
  </LscMenu>
</template>
