<script setup>
import { useI18n } from '@/util';

const { t } = useI18n();
</script>
<template>
  <div class="text-body-1">
    <p>
      {{ t('Log time on your tasks and projects in one place with My Timesheet — your personal timesheet.') }}
    </p>
    <p class="pt-6">
      {{
        t(
          'Tasks assigned to you, and which fall within the chosen week, are automatically visible. You can manually add any additional tasks and projects you need to track time against.',
        )
      }}
    </p>
  </div>
</template>
