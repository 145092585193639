<script setup>
import { useI18n } from '@/util';
import ApiSetupShared1 from './ApiSetupShared1.vue';
import ApiSetupShared2 from './ApiSetupShared2.vue';
import ApiSetupShared3 from './ApiSetupShared3.vue';
import ApiSetupShared4 from './ApiSetupShared4.vue';
import ApiSetupShared5 from './ApiSetupShared5.vue';

const { languageCode, timeZoneName, dateFormat, timeFormat, weekStartsOnSunday } = useI18n();
</script>

<template>
  <!-- The `key` recreates the child component when the basic localization data changes. -->
  <ApiSetupShared1 :key="`${languageCode}/${timeZoneName}/${dateFormat}/${timeFormat}/${weekStartsOnSunday}`">
    <ApiSetupShared2>
      <ApiSetupShared3>
        <ApiSetupShared4>
          <ApiSetupShared5>
            <slot />
          </ApiSetupShared5>
        </ApiSetupShared4>
      </ApiSetupShared3>
    </ApiSetupShared2>
  </ApiSetupShared1>
</template>
