<script setup>
import { useI18n } from '@/util';
import { useFilter } from './useFilter';

defineProps({
  ariaExpanded: {
    type: [Boolean, String],
    default: false,
  },
  ariaHasPopup: {
    type: [Boolean, String],
    default: false,
  },
  ariaOwns: {
    type: String,
    default: undefined,
  },
});

const emit = defineEmits(['click']);
const { t } = useI18n();

const filterTooltip = shallowRef(false);

const { count, clear, dataIdentifierPrefix } = useFilter();

const hasFiltersApplied = computed(() => count.value > 0);
</script>
<template>
  <div class="relative">
    <slot name="button" v-bind="{ ariaExpanded, ariaHasPopup, ariaOwns, hasFiltersApplied, count }">
      <button
        v-LsdTooltip="t('Filter')"
        type="button"
        :class="[
          'inline-flex h-8 items-center gap-1 rounded-full text-button-1',
          'border border-form-default px-2',
          'hover:border-form-hover aria-expanded:border-form-hover',
          'aria-pressed:border-form-active aria-pressed:bg-surface-emphasis-selected aria-pressed:pr-7',
        ]"
        :data-identifier="`${dataIdentifierPrefix}-menu-button`"
        :aria-expanded="ariaExpanded"
        :aria-haspopup="ariaHasPopup"
        :aria-owns="ariaOwns"
        :aria-label="t('Filter')"
        :aria-pressed="hasFiltersApplied"
        @click="emit('click', $event)"
      >
        <LscIcon
          icon="lsi-filter"
          :class="hasFiltersApplied ? 'text-icon-primary-default' : 'text-icon-subtle'"
          size="sm"
        />
        <div
          v-if="hasFiltersApplied"
          class="inline-flex size-5 items-center justify-center rounded-full bg-default text-body-1 font-medium text-primary"
        >
          {{ count }}
        </div>
        <template v-else>{{ t('Filter') }}</template>
      </button>
      <button
        v-if="hasFiltersApplied"
        v-LsdTooltip="t('Clear filters')"
        type="button"
        class="absolute right-0 top-0 flex size-8 items-center justify-center p-1"
        :data-identifier="`${dataIdentifierPrefix}-menu-clear-button`"
        @click="clear"
      >
        <LscIcon
          icon="lsi-close"
          class="text-icon-primary-default"
          size="sm"
          @mouseover="filterTooltip = false"
          @mouseleave="filterTooltip = true"
        />
      </button>
    </slot>
  </div>
</template>
