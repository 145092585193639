<script setup>
import { useI18n } from '@/util';

const { t } = useI18n();
</script>
<template>
  <div class="text-body-1">
    <p>
      {{
        t(
          'Log how long you and your team spend on each piece of work with Time Tracking and the {teamworkTimer} app.',
          {
            teamworkTimer: 'Teamwork Timer',
          },
        )
      }}
    </p>
    <p class="pt-6">
      {{ t('Start the stopwatch to time tasks as you work, or log time after the task has been completed.') }}
    </p>
  </div>
</template>
