import { isTextInput } from './isTextInput';

/**
 * Checks if the event was triggered on a text input element.
 *
 * @param {*} event The event to check.
 * @returns {boolean} True if the event was triggered on a text input element.
 */
export function isEventOnTextInput(event) {
  return event.composedPath().some(isTextInput);
}
