import { useFeatures } from '../features/useFeatures';
import { usePendo } from '../features/usePendo';
import { usePermissions } from '../permissions/usePermissions';

/**
 * Constants, computed properties and functions related to Experiment E21b
 * Deliver to Grow Experiment
 * https://app.launchdarkly.com/default/production/features/projects-exp-e-23-21b-feature-trials-grow/targeting
 */
export function useExperimentE21b() {
  const { isOwnerAdmin } = usePermissions();
  const { projectsFeatureTrialGrow } = useFeatures();
  const { trackExperimentInPendo, trackPendoEvent } = usePendo();

  const EXP_E21B_FLAG_KEY = 'projects-exp-e-23-21b-feature-trials-grow';

  const EXP_E21B_COMMON_METRICS = ['plan_name', 'page', 'user_role'];
  const EXP_E21B_EVENT_NAME = 'FEATURE_TRIAL_EVENT';

  const defaultFlagValue = false;
  const ignoreInExperimentOnRuleMatch = true;

  const isExp21bVariation = computed(() => projectsFeatureTrialGrow.value && isOwnerAdmin?.value);
  const isExp21bControl = computed(() => !isExp21bVariation.value);

  function trackExperimentE21b() {
    trackExperimentInPendo({
      launchDarklyFlagKey: EXP_E21B_FLAG_KEY,
      defaultValue: defaultFlagValue,
      ignoreInExperimentOnRuleMatch,
      appLevelTargeting: isOwnerAdmin.value,
    });
  }

  function trackEventE21b({ eventAction, meta = {} }) {
    trackPendoEvent({
      eventName: EXP_E21B_EVENT_NAME,
      commonMetrics: EXP_E21B_COMMON_METRICS,
      metadata: {
        event_action: eventAction,
        ...meta,
      },
    });
  }

  return {
    isExp21bVariation,
    isExp21bControl,
    EXP_E21B_COMMON_METRICS,
    EXP_E21B_EVENT_NAME,
    EXP_E21B_FLAG_KEY,
    trackExperimentE21b,
    trackEventE21b,
  };
}
