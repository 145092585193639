import { useI18n } from '@/util';
import { useAxios } from '../base/useAxios';
import { useOptimisticUpdates } from '../base/useOptimisticUpdates';
import { useRealTimeUpdates } from '../base/useRealTimeUpdates';

export function useSavedfilterActions() {
  const api = useAxios();
  const { t } = useI18n();
  const { socketId, emit: _emitRealTimeUpdate } = useRealTimeUpdates();
  const { emit: _emitOptimisticUpdate } = useOptimisticUpdates();

  function emitOptimisticUpdate(promise, action, savedfilter) {
    _emitOptimisticUpdate({ promise, type: 'savedfilter', action, savedfilter });
  }

  function emitRealTimeUpdate(action, savedfilter) {
    _emitRealTimeUpdate({
      type: 'savedfilter',
      action,
      savedfilterId: savedfilter.id,
    });
  }

  function normalizeDefaultFilter(filter) {
    return {
      color: filter.color || '',
      description: filter.description || '',
      displayOrder: filter.displayOrder,
      includesSort: filter.includesSort,
      isDefault: true,
      isTemporary: filter.isTemporary,
      parameters: filter.parameters,
      section: filter.section,
      title: filter.title,
    };
  }

  return {
    createSavedfilter(savedfilter, { updateDefaults } = {}) {
      const promise = api
        .post(
          '/projects/api/v3/me/savedfilters.json',
          {
            savedfilter,
            updateDefaults,
          },
          {
            headers: { 'Socket-ID': socketId },
            errorMessage: t('Failed to create the saved filter'),
          },
        )
        .then((response) => {
          emitRealTimeUpdate('new', response.data.savedfilter);
          return response.data.savedfilter;
        });

      emitOptimisticUpdate(promise, 'create', savedfilter);

      return promise;
    },

    updateSavedfilter(savedfilter, { updateDefaults = false } = {}) {
      const { id, ...updatedFilter } = savedfilter;

      const promise = api
        .patch(
          `/projects/api/v3/me/savedfilters/${id}.json`,
          {
            savedfilter: updatedFilter,
            updateDefaults,
          },
          {
            headers: { 'Socket-ID': socketId },
            errorMessage: t('Failed to update the saved filter'),
          },
        )
        .then((response) => {
          emitRealTimeUpdate('edited', response.data.savedfilter);
          return response.data.savedfilter;
        });
      emitOptimisticUpdate(promise, 'update', savedfilter);
      return promise;
    },

    setDefaultSavedfilter(savedfilter) {
      const promise = api.put(
        `/projects/api/v3/me/savedfilters/${savedfilter.id}.json`,
        {
          savedfilter: normalizeDefaultFilter(savedfilter),
          updateDefaults: true,
        },
        {
          errorMessage: t('Failed to set the default saved filter'),
        },
      );
      return promise;
    },

    clearDefaultSavedfilter(savedfilter) {
      const promise = api.post(
        `/projects/api/v3/me/savedfilters.json`,
        {
          savedfilter: normalizeDefaultFilter(savedfilter),
        },
        {
          errorMessage: t('Failed to clear the default saved filter'),
        },
      );
      return promise;
    },

    deleteSavedfilter(savedfilter) {
      const promise = api
        .delete(`/projects/api/v3/me/savedfilters/${savedfilter.id}.json`, {
          headers: { 'Socket-ID': socketId },
          errorMessage: t('Failed to delete the saved filter'),
        })
        .then(() => {
          emitRealTimeUpdate('deleted', savedfilter);
          return savedfilter;
        });

      emitOptimisticUpdate(promise, 'delete', savedfilter);

      return promise;
    },

    repositionSavedfilter(savedfilter, positionAfterFilterId) {
      const promise = api
        .put(
          `/projects/api/v3/me/savedfilters/${savedfilter.id}/reposition.json`,
          { positionAfter: positionAfterFilterId },
          {
            headers: { 'Socket-ID': socketId },
            errorMessage: t('Failed to move the saved filter'),
          },
        )
        .then(() => {
          emitRealTimeUpdate('reposition', savedfilter);
        });

      emitOptimisticUpdate(promise, 'update', { ...savedfilter, positionAfterFilterId });

      return promise;
    },

    shareSavedfilter(filterID, shareFilterData) {
      return api.post(`/sharefilter/${filterID}.json`, shareFilterData, {
        errorMessage: t('Failed to create the shared filter'),
      });
    },

    migrateOldListViewFilters() {
      return api.post(
        '/projects/api/v3/me/savedfilters/migrate/olv.json',
        {},
        {
          errorMessage: t('Failed to migrate the saved filters'),
        },
      );
    },
  };
}
