import { generateUuid } from '@/util';

const symbol = Symbol('useLsToasts');
const defaultMaxToasts = 5;
const toastTypes = /** @type {const} */ (['info', 'success', 'warning', 'critical']);

/**
 * @typedef {typeof toastTypes} ToastTypes
 * @typedef {ToastTypes[number]} ToastType
 *
 * @typedef {object} Toast
 * @property {string} id - A unique ID for the toast.
 * @property {ToastType} type - The type of toast.
 * @property {number} duration - The duration of the toast in ms.
 * @property {string} title - The title of the toast.
 * @property {string=} message  - The optional addition message of the toast.
 * @property {string=} icon - The icon of the toast. Will default to the type icon.
 * @property {() => void} action - The action a user can perform on the toast.
 * @property {string} actionText - The text of the action.
 * @property {() => void} onRemove - The callback when the toast is removed.
 *
 * @typedef {Toast|string} ToastParams
 */

function LsToast({ maxToasts = defaultMaxToasts } = {}) {
  /**
   * @type {Ref<Toast[]>}
   */
  // eslint-disable-next-line lightspeed/prefer-shallow-ref
  const toasts = ref([]);

  /**
   *
   * @param {Toast} toast
   * @param {() => void} removeCallback
   */
  function removeToast(toast) {
    toasts.value = toasts.value.filter(({ id }) => toast.id !== id);
    toast.onRemove?.();
  }

  /**
   * Creates a toast.
   * Please prefer using the alias functions: info, success, warning, critical.
   * @param {ToastParams} toastParams
   * @param {ToastType} [type] The default toast type which may be overriden by `toastParams.type`.
   * @returns {Toast}
   */
  function createToast(toastParams, type) {
    const toast = {
      title: '',
      type: type ?? 'info',
      duration: 5000,
    };

    if (typeof toastParams === 'string') {
      toast.title = toastParams;
    } else if (typeof toastParams === 'object' && toastParams !== null) {
      Object.assign(toast, toastParams);
    }

    toast.id = generateUuid();

    // Validate toast.title
    if (typeof toast.title !== 'string') {
      if (import.meta.env.DEV) {
        // eslint-disable-next-line no-console
        console.warn('toast.title must be a string');
      }
      toast.title = '';
    }

    // Validate toast.type
    if (!toastTypes.includes(toast.type)) {
      if (import.meta.env.DEV) {
        // eslint-disable-next-line no-console
        console.warn(`toast.type must be one of: ${toastTypes.join(', ')}`);
      }
      toast.type = 'info';
    }

    // Validate toast.action and toast.actionText
    if (import.meta.env.DEV) {
      if ((toast.action && !toast.actionText) || (!toast.action && toast.actionText)) {
        // eslint-disable-next-line no-console
        console.warn('toast.action and toast.actionText must be provided together');
      }
    }

    // Add remove toast to the end of the action
    if (typeof toast.action === 'function') {
      const { action } = toast;
      toast.action = () => {
        action.call(toast);
        removeToast(toast);
      };
    }

    // Add a timeoutId to the toast
    toast.timeoutId = setTimeout(() => {
      removeToast(toast);
    }, toast.duration);

    // keep the last 5 toasts
    toasts.value = [...toasts.value, toast].slice(-maxToasts);

    return toast;
  }

  /**
   * Creates an info toast
   * @param {ToastParams} toastParams
   */
  function info(toastParams) {
    return createToast(toastParams, 'info');
  }

  /**
   * Creates a success toast
   * @param {ToastParams} toastParams
   */
  function success(toastParams) {
    return createToast(toastParams, 'success');
  }

  /**
   * Creates a warning toast
   * @param {ToastParams} toastParams
   */
  function warning(toastParams) {
    return createToast(toastParams, 'warning');
  }

  /**
   * Creates a critical toast
   * @param {ToastParams} toastParams
   */
  function critical(toastParams) {
    return createToast(toastParams, 'critical');
  }

  /**
   * This is deprecated, please use toast.critical instead
   * @deprecated
   * @param {ToastParams} toastParams
   */
  function error(toastParams) {
    if (import.meta.env.DEV) {
      // eslint-disable-next-line no-console
      console.info('toast.error is deprecated, use toast.critical instead');
    }
    return createToast(toastParams, 'critical');
  }

  /**
   * Clears a toasts timeout
   * @param {Toast} toast
   */
  function pauseToast(toast) {
    clearTimeout(toast.timeoutId);
  }

  /**
   * Restart a toasts timeout
   * @param {Toast} toast
   */
  function resumeToast(toast) {
    // eslint-disable-next-line no-param-reassign
    toast.timeoutId = setTimeout(() => {
      removeToast(toast);
    }, toast.duration);
  }

  return {
    toasts,
    removeToast,
    pauseToast,
    resumeToast,
    createToast,
    info,
    success,
    warning,
    critical,
    error,
  };
}

export const lsToastPlugin = {
  install(app, options) {
    app.provide(symbol, LsToast(options));
  },
};

/**
 *
 * @returns {ReturnType<lsToastPlugin>}
 */
export function useLsToast() {
  const toast = inject(symbol);

  if (!toast) {
    throw new Error('No toast provider!');
  }

  return toast;
}
