<script setup>
import Draggable from 'vuedraggable';
import { useFeatures, useSavedfilterActions, useSavedfiltersV3Loader } from '@/api';
import { isValidId, useI18n } from '@/util';
import { useFilter } from './useFilter';

const props = defineProps({
  projectId: {
    type: Number,
    default: 0,
  },
  section: {
    type: String,
    default: undefined,
  },
});

const emit = defineEmits(['back', 'clear', 'edit', 'share']);

const { t } = useI18n();
const toast = useLsToast();

const { activeFilter } = useFilter();
const { deleteSavedfilter, setDefaultSavedfilter, repositionSavedfilter } = useSavedfilterActions();

// The addition of the share filters was done for this feature but needs QA testing before a full release.
const { sharingSavedFilterLinksEnabled } = useFeatures();
const pageSize = 50;
const count = shallowRef(pageSize);
const orderMode = shallowRef('desc');

const state = useSavedfiltersV3Loader({
  pageSize,
  count,
  params: computed(() => ({
    orderBy: 'displayOrder',
    orderMode: orderMode.value,
    include: 'projects,projectColumns,projectCategories,users,companies,tags,calendarEventTypes,tasklists,teams',
    sections: props.section,
    projectIds: props.projectId,
    includeMigrationStatus: true,
  })),
});

const { items: ungroupedSavedFilters, meta } = state;

// Note: with `orderBy=displayOrder` and `orderMode=asc` the API results are ordered based on:
//  - `projectSpecific` `false` then `true`
//  - (if above is equal) `displayOrder`
//  - (if above is equal) `id`
// This means that the non-project-specific filters group should come first (when using `orderMode=asc`).

const groupedSavedFilters = computed(() => {
  if (!isValidId(props.projectId)) {
    // No grouping necessary outside project context (keep structure consistent though)
    return [ungroupedSavedFilters.value, []];
  }

  return ungroupedSavedFilters.value.reduce(
    (result, savedFilter) => {
      const groupIndex = Number(
        orderMode.value === 'desc' ? !savedFilter.projectSpecific : savedFilter.projectSpecific,
      );
      // eslint-disable-next-line no-param-reassign
      result[groupIndex] = result[groupIndex] || [];
      result[groupIndex].push(savedFilter);
      return result;
    },
    [[], []],
  );
});

async function deleteFilter(filter) {
  if (filter.id === activeFilter.value?.id) {
    emit('clear');
  }
  await deleteSavedfilter(filter);
  toast.success(t('Filter deleted'));
}

async function selectFilter(filter) {
  activeFilter.value = filter;
  await setDefaultSavedfilter(filter);
  emit('back');
}

function afterReorder(e, groupIndex) {
  const list = groupedSavedFilters.value[groupIndex] || [];
  const movedSavedfilter = list[e.newIndex];
  let positionAfterFilterId = 0;
  if (orderMode.value === 'desc') {
    if (e.newIndex !== 0) {
      positionAfterFilterId = list[e.newIndex - 1].id;
    }
  } else if (e.newIndex !== list.length - 1) {
    positionAfterFilterId = list[e.newIndex + 1].id;
  }
  repositionSavedfilter(movedSavedfilter, positionAfterFilterId);
}

function getHeaderForGroup(groupIndex) {
  // No grouping outside project context
  if (!isValidId(props.projectId)) {
    return null;
  }
  const headers = [t('This project only'), t('All projects')];
  return headers[orderMode.value === 'desc' ? groupIndex : 1 - groupIndex];
}
</script>

<template>
  <div class="flex flex-col items-stretch gap-2">
    <slot name="prepend" :meta="meta" />
    <LswLoaderState :state="state" :blankTitle="t('No saved filters found')">
      <LscScrollMask class="max-h-96">
        <VList density="compact" class="flex h-full w-full flex-col gap-3 p-0">
          <div v-for="(savedFiltersGroup, index) in groupedSavedFilters" :key="index" class="">
            <template v-if="savedFiltersGroup.length > 0">
              <VListSubheader v-if="getHeaderForGroup(index)">
                {{ getHeaderForGroup(index) }}
              </VListSubheader>
              <Draggable
                class="flex flex-col gap-3"
                :list="savedFiltersGroup"
                :group="savedFiltersGroup"
                itemKey="id"
                @end="afterReorder($event, index)"
              >
                <template #item="{ element: filter }">
                  <VListItem
                    :key="filter.id"
                    class="group rounded-sm bg-surface-default !pl-0"
                    :active="filter.id === activeFilter?.id"
                    activeClass="text-primary"
                    @click="selectFilter(filter)"
                  >
                    <template #prepend>
                      <div class="inline-flex w-6 cursor-move justify-center">
                        <LscIcon
                          v-show="savedFiltersGroup.length > 1"
                          icon="lsi-drag"
                          class="hidden group-hover:flex"
                        />
                      </div>
                    </template>
                    <div class="flex items-center gap-1 text-body-1 font-semibold">
                      <LscOverflowEllipsis class="shrink">{{ filter.title }}</LscOverflowEllipsis>
                      <LscIcon
                        v-if="filter.description"
                        v-LsdTooltip="filter.description"
                        class="flex-none text-icon-subtle"
                        icon="lsi-tooltip"
                        size="sm"
                      />
                    </div>
                    <template #append>
                      <WidgetOptionsMenu location="bottom end" origin="auto" :closeOnContentClick="true">
                        <WidgetOptionsMenuItem :prepend="{ icon: 'lsi-edit' }" @click="emit('edit', filter)">
                          {{ t('Edit') }}
                        </WidgetOptionsMenuItem>
                        <WidgetOptionsMenuItem
                          v-if="sharingSavedFilterLinksEnabled"
                          :prepend="{ icon: 'lsi-share' }"
                          @click="emit('share', filter)"
                        >
                          {{ t('Share') }}
                        </WidgetOptionsMenuItem>
                        <WidgetOptionsMenuItem :prepend="{ icon: 'lsi-delete' }" critical @click="deleteFilter(filter)">
                          {{ t('Delete') }}
                        </WidgetOptionsMenuItem>
                      </WidgetOptionsMenu>
                    </template>
                  </VListItem>
                </template>
              </Draggable>
            </template>
          </div>
        </VList>
        <LswLoaderTrigger v-model:count="count" :state="state" :step="state.pageSize" />
      </LscScrollMask>
    </LswLoaderState>
    <div class="flex flex-wrap justify-between gap-2">
      <LscButton variant="tertiary" size="md" prependIcon="lsi-back" @click="emit('back')">
        {{ t('Back') }}
      </LscButton>
    </div>
  </div>
</template>
