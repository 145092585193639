<script setup>
import { refDebounced, syncRef } from '@vueuse/core';
import { useCategoriesV1Loader } from '@/api';
import { useI18n, useItemCache } from '@/util';
import { normalizeFilterIds } from './normalize';
import { useFilter, useFilterChips, useFilterClear, useFilterCount, useFilterNormalize } from './useFilter';

const props = defineProps({
  name: {
    type: String,
    required: true,
  },
  title: {
    type: String,
    required: true,
  },
  defaultValue: {
    type: String,
    default: '',
  },
  projectId: {
    type: Number,
    default: null,
  },
  type: {
    type: String,
    default: 'project',
  },
  loaderParams: {
    type: Object,
    default: () => ({}),
  },
});
const { t } = useI18n();
const { params, activeFilter, dataIdentifierPrefix } = useFilter();

const searchTerm = shallowRef('');
const debouncedSearchTerm = refDebounced(searchTerm, 300);
const pageSize = 10;
const count = shallowRef(-1);
const filterType = 'categories';

const state = useCategoriesV1Loader({
  projectId: computed(() => props.projectId),
  params: computed(() => ({
    ...props.loaderParams,
    searchTerm: debouncedSearchTerm.value,
  })),
  type: props.type,
  count,
});
const { items: categoryItems } = state;

const missingCategoriesIds = shallowRef([]);
/**
 * The endpoint does not support filtering by IDs, so we need to set the count and page to 100
 * in order to increase the chance of getting all missing items before opening the menu.
 */
const missingCategoriesState = useCategoriesV1Loader({
  projectId: computed(() => props.projectId),
  params: computed(() => ({
    ...props.loaderParams,
  })),
  type: props.type,
  count: computed(() => (missingCategoriesIds.value.length > 0 ? 100 : -1)),
  pageSize: 100,
});

const { computeAll, computeMissing } = useItemCache(
  computed(() => Object.values(activeFilter.value?.included?.[filterType] || {})),
  state.items,
  missingCategoriesState.items,
);

const categoryIds = computed({
  get() {
    return typeof params.value[props.name] === 'string'
      ? params.value[props.name].split(',').map(Number).filter(Boolean)
      : [];
  },
  set(value) {
    params.value = {
      ...params.value,
      [props.name]: value.join(','),
    };
  },
});

const categories = computeAll(categoryIds, (id) => ({ id, name: t('Unknown') }));

syncRef(computeMissing(categoryIds), missingCategoriesIds, { direction: 'ltr' });

useFilterNormalize(toRef(props, 'name'), (value) => normalizeFilterIds(value, props.defaultValue));

useFilterClear(toRef(props, 'name'), toRef(props, 'defaultValue'));

useFilterChips(
  computed(() =>
    categories.value.map((category) => ({
      // color, name, etc
      name: category.name,
      icon: category.color ? 'lsi-color' : null,
      iconColor: `#${category.color}`,
      type: 'category',
      delete: () => {
        categoryIds.value = categoryIds.value.filter((id) => id !== category.id);
      },
    })),
  ),
);

useFilterCount(computed(() => categoryIds.value.length));

function toggleCategory({ id }) {
  if (categoryIds.value.includes(id)) {
    categoryIds.value = categoryIds.value.filter((categoryId) => categoryId !== id);
  } else {
    categoryIds.value = [...categoryIds.value, id];
  }
}

function icon(id) {
  return categoryIds.value.includes(id) ? 'lsi-remove' : 'lsi-add';
}

function toggleMenu(opened) {
  if (!opened || count.value >= 0) {
    return;
  }
  count.value = pageSize;
}
</script>
<template>
  <LscMenu :closeOnContentClick="false" location="bottom left" offset="12" @update:modelValue="toggleMenu">
    <template #activator="activator">
      <LswFilterOptionButton
        v-bind="activator.props"
        :ariaExpanded="activator.isActive"
        :ariaPressed="categories.length > 0"
        icon="lsi-assignees"
        :data-identifier="`${dataIdentifierPrefix}-categories-button`"
      >
        {{ title }}
      </LswFilterOptionButton>
    </template>
    <LscSheet class="flex w-72 flex-col px-0">
      <LscSearchBar
        v-model="searchTerm"
        :clearable="true"
        :placeholder="t('Search categories')"
        autofocus
        class="mx-4"
      />
      <LswLoaderState :state="state" :blankTitle="t('No categories found')">
        <template #default>
          <VList density="compact" maxHeight="384">
            <VListItem
              v-for="category in categoryItems"
              :key="category.id"
              :active="categoryIds.includes(category.id)"
              :data-identifier="`${dataIdentifierPrefix}-categories-item`"
              @click="toggleCategory(category)"
            >
              <div class="flex items-center gap-2">
                <LscIcon v-if="category.color" :color="`#${category.color}`" icon="lsi-color" size="xs" />
                <LscOverflowEllipsis class="flex-1 text-body-1">{{ category.name }}</LscOverflowEllipsis>
                <LscIcon :icon="icon(category.id)" class="text-icon-subtle" size="sm" />
              </div>
            </VListItem>
            <LswLoaderTrigger v-model:count="count" :state="state" :step="pageSize" />
          </VList>
        </template>
        <!-- Return empty blank state as per design -->
        <template #blank>
          <div class="mb-2" />
        </template>
      </LswLoaderState>
    </LscSheet>
  </LscMenu>
</template>
