<script setup>
import { provideReps } from '@/module/reps';
import { provideCurrentProjectBudgetState } from './useCurrentProjectBudget';
import { useCurrentProjectBudgetLoader } from './useCurrentProjectBudgetLoader';

// Fetch the budget for the current project
provideCurrentProjectBudgetState(useCurrentProjectBudgetLoader());
provideReps();
</script>
<template>
  <slot />
</template>
