<script setup>
import { useLsSelection } from '../../../composables/selection/useLsSelection';

const props = defineProps({
  mandatory: {
    type: Boolean,
  },
  multiple: {
    type: Boolean,
  },
  limit: {
    type: Number,
    default: Infinity,
  },
  horizontal: {
    type: Boolean,
    default: false,
  },
});

const modelValue = defineModel({
  type: [String, Number, Array],
  default: null,
});

const slots = useSlots();

const slottedElements = computed(() => {
  if (slots.default().length === 1) {
    return slots.default()[0]?.children;
  }
  return slots.default();
});

const { isSelected, isDisabled, toggle } = useLsSelection({
  selection: modelValue,
  mandatory: computed(() => props.mandatory),
  multiple: computed(() => props.multiple),
  limit: computed(() => props.limit),
});
</script>

<template>
  <div :role="multiple ? 'group' : 'radiogroup'" class="flex gap-2" :class="horizontal ? 'flex-row' : 'flex-col'">
    <template v-for="slot of slottedElements" :key="slot.props?.value">
      <Component
        :is="slot"
        v-bind="slot.props"
        :role="multiple ? 'checkbox' : 'radio'"
        :disabled="slot.props?.disabled || isDisabled(slot.props?.value)"
        :selected="isSelected(slot.props?.value)"
        @click="toggle(slot.props?.value)"
      />
    </template>
  </div>
</template>
