import { useCurrentAccount } from '../account/useCurrentAccount';
import { useFeatures } from '../features/useFeatures';
import { usePendo } from '../features/usePendo';
import { usePermissions } from '../permissions/usePermissions';

/**
 * Constants, computed properties and functions related to Experiment 46
 * https://app.launchdarkly.com/default/staging/features/projects-exp-46-badge-rating-system/targeting
 * https://digitalcrew.teamwork.com/spaces/growth/page/38140-exp-46-introduce-a-badge-rating-system-for-users
 */
export function useExperiment46() {
  const { isOwnerAdmin } = usePermissions();
  const { exp46BadgeRatingSystemEnabled: exp46BadgeRatingSystemFlag } = useFeatures();
  const { trackExperimentInPendo } = usePendo();
  const account = useCurrentAccount();
  const EXP_46_FLAG_KEY = 'projects-exp-46-badge-rating-system';
  const EXP_46_COMMON_METRICS = [
    'account_id',
    'company_sector',
    'company_size',
    'company_user_role',
    'plan_name',
    'page',
  ];

  const GROW_PLAN_IDS = [48, 65, 69];
  const defaultFlagValue = false;
  const ignoreInExperimentOnRuleMatch = true;

  const exp46BadgeRatingSystemEnabled = computed(() => exp46BadgeRatingSystemFlag?.value ?? defaultFlagValue); // set default value to false in code

  const exp46AppLevelTargeting = computed(
    () => Boolean(GROW_PLAN_IDS.includes(account.value?.pricePlanId)) && isOwnerAdmin.value,
  );
  const isExp46Variation = computed(() => exp46BadgeRatingSystemEnabled.value && exp46AppLevelTargeting.value);

  const { trackPendoEvent } = usePendo();

  /** @param {Record<string, string | number>} otherExp46CommonMetrics  */
  function trackAppLaunchedEvent(otherExp46CommonMetrics) {
    trackPendoEvent({
      eventName: 'BADGE_RATING_EVENT',
      commonMetrics: EXP_46_COMMON_METRICS,
      metadata: {
        event_action: 'app_launched',
        ...otherExp46CommonMetrics,
      },
    });
  }

  function trackExperiment46() {
    if (exp46BadgeRatingSystemEnabled.value) {
      // condition placed to track only when the flag is explicitly enabled
      trackExperimentInPendo({
        launchDarklyFlagKey: EXP_46_FLAG_KEY,
        defaultValue: defaultFlagValue,
        ignoreInExperimentOnRuleMatch,
        appLevelTargeting: exp46AppLevelTargeting.value,
      });
    }
  }

  return {
    isExp46Variation,

    EXP_46_COMMON_METRICS,
    EXP_46_FLAG_KEY,
    trackAppLaunchedEvent,
    trackExperiment46,
  };
}
