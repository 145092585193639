<script setup>
import { useI18n } from '@/util';

const { t } = useI18n();
</script>
<template>
  <div class="text-body-1">
    <p>
      {{
        t(
          'Quickly and easily add supporting information to your tasks, messages, and to comments by using the drag and drop functionality for your file attachments, and store all your information in one place.',
        )
      }}
    </p>
    <p class="mt-4">
      {{
        t(
          'Files attached to any and all project items will be shown in the files area of the project so you always know where to find them.',
        )
      }}
    </p>
  </div>
</template>
