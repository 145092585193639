export const LscAvatarSizes = ['xxs', 'xs', 'sm', 'md', 'lg', 'xl', 'xxl'] as const;
export type LscAvatarSize = (typeof LscAvatarSizes)[number];

export const LscAvatarProps = {
  /** The alt text for the avatar. */
  alt: {
    type: String,
    default: undefined,
  },
  /** The background color of the avatar. */
  bgColor: {
    type: String,
    default: undefined,
  },
  /** Whether the avatar is clearable. */
  clearable: {
    type: Boolean,
    default: false,
  },
  /** The icon to display. */
  icon: {
    type: String as PropType<LscIconName>,
    default: undefined,
  },
  /** The color of the icon. */
  iconColor: {
    type: String,
    default: undefined,
  },
  /** The name of the user. */
  name: {
    type: String,
    default: undefined,
  },
  /** The function to generate the remove tooltip. */
  removeTooltipFunction: {
    type: Function as PropType<(name?: string) => string | undefined>,
    default: undefined,
  },
  /** Whether to show tooltips on the avatar. */
  showTooltip: {
    type: Boolean,
    default: true,
  },
  /** The size of the avatar. */
  size: {
    type: String as PropType<LscAvatarSize>,
    default: 'sm',
  },
  /** The source of the image. */
  src: {
    type: String,
    default: undefined,
  },
  /** The variant of the avatar. */
  variant: {
    type: String as PropType<'default' | 'on-surface'>,
    default: 'default',
  },
} as const;
