import { DateTime } from 'luxon';
import { useItemLoader } from '../base/useItemLoader';
import { useOptimisticUpdates } from '../base/useOptimisticUpdates';
import { useRealTimeUpdates } from '../base/useRealTimeUpdates';

function responseToItem({ data: { person: rawUser } }) {
  const user = {};

  Object.keys(rawUser).forEach((key) => {
    const normalizedKey = key.replace(/-./g, (match) => match.slice(1).toUpperCase());
    user[normalizedKey] = rawUser[key];
  });

  user.id = Number(user.id);
  user.companyId = Number(user.companyId);
  user.loginCount = Number(user.loginCount);
  user.inOwnerCompany = user.inOwnerCompany === '1';
  user.userInvited = user.userInvited === '1';
  user.lastChangedOn = DateTime.fromISO(user.lastChangedOn);
  user.lastLogin = DateTime.fromISO(user.lastLogin);
  user.companyRoleId = Number(user.companyRoleId);
  user.userInviteAcceptedDate = user.userInviteAcceptedDate ? DateTime.fromISO(user.userInviteAcceptedDate) : undefined;
  user.teamIds = user.teamIds.map(Number);

  const { permissions: rawPermissions } = user;

  if (rawPermissions) {
    const permissions = {};

    Object.keys(rawPermissions).forEach((key) => {
      const normalizedKey = key.replace(/-./g, (match) => match.slice(1).toUpperCase());
      permissions[normalizedKey] = rawPermissions[key];
    });

    user.permissions = permissions;
  }

  const { localization: rawLocalization } = user;

  if (rawLocalization) {
    const localization = {};

    Object.keys(rawLocalization).forEach((key) => {
      const normalizedKey = key.replace(/-./g, (match) => match.slice(1).toUpperCase());
      localization[normalizedKey] = rawLocalization[key];
    });

    localization.dateFormatId = Number(localization.dateFormatId);
    localization.languageCode = localization.languageCode.replace(
      /^(\w*)(.*)$/,
      (_, language, region) => language.toLowerCase() + region.toUpperCase(),
    );
    localization.timeFormatId = Number(localization.timeFormatId);
    localization.timezoneId = Number(localization.timezoneId);
    localization.timezoneUTCOffsetMins = Number(localization.timezoneUTCOffsetMins);

    user.localization = localization;
  }

  const { preferences: rawPreferences } = user;

  if (rawPreferences) {
    const preferences = {};

    Object.keys(rawPreferences).forEach((key) => {
      const normalizedKey = key.replace(/-./g, (match) => match.slice(1).toUpperCase());
      let value = rawPreferences[key];

      if (value === 'NO') {
        value = false;
      }
      if (value === 'YES') {
        value = true;
      }

      preferences[normalizedKey] = value;
    });

    user.preferences = preferences;
  }

  const { defaultFilters } = user;

  if (defaultFilters) {
    Object.keys(defaultFilters).forEach((key) => {
      const filter = defaultFilters[key];
      filter.id = Number(filter.id);
      filter.projectId = Number(filter.projectId);
      filter.userId = Number(filter.userId);
      filter.included = filter.fulldata;
    });
  }

  return user;
}

/**
 * Loads the current user.
 */
export function useCurrentUserV1Loader({ params } = {}) {
  const { state, refresh, update } = useItemLoader({
    url: '/me.json',
    params,
    responseToItem,
  });

  useOptimisticUpdates((event) => {
    if (event.type === 'person') {
      if (event.action === 'update') {
        update((person) => {
          return person?.id === event.person?.id ? { ...person, ...event.person } : person;
        }, event.promise);
      }
    }
  });

  useRealTimeUpdates((event) => {
    if (event.type === 'person' && state.item.value && state.item.value.id === event.userId) {
      refresh();
      return;
    }

    if (event.type === 'userPreference') {
      refresh();
      return;
    }

    // Refresh on time approvers list changes affecting the user's 'canApproveTime' permission
    if (
      event.type === 'timeApprovalsSettings' &&
      (event.userIdsAdded.includes(state.item.value.id) || event.userIdsRemoved.includes(state.item.value.id))
    ) {
      refresh();
      return;
    }

    // this event is triggered when creating a project or when a person is added or remove from one
    // this is needed to reload the users permissions to make sure the user sees the company time sheets after a project is added
    if (
      event.type === 'project' &&
      event.action === 'permissions' &&
      state.item.value &&
      state.item.value.id === event.userId
    ) {
      refresh();
      return;
    }

    // this event triggered when we mark a feature as read
    // we read unread feature count from user object
    if (event.type === 'announcement' && event.action === 'updated') {
      refresh();
      // return;
    }
  });

  return state;
}
