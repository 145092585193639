<script setup>
import { useI18n } from '@/util';

const { t } = useI18n();
</script>
<template>
  <div class="text-body-1">
    <p>
      {{
        t(
          'Log time spent on a project across multiple tasks and team members, including billable and non-billable hours for invoicing.',
        )
      }}
    </p>
    <p class="pt-6">
      {{
        t(
          'On project completion, your project invoice will calculate automatically based on users’ billable rates and logged time.',
        )
      }}
    </p>
  </div>
</template>
