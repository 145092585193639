import { DateTime } from 'luxon';
import { useListLoader } from '../base/useListLoader';
import { useOptimisticUpdates } from '../base/useOptimisticUpdates';
import { useRealTimeUpdates } from '../base/useRealTimeUpdates';

function responseToItems({ data }) {
  const forms = data.forms || [];

  const { projects = {}, projectPermissions = {} } = data.included || {};
  const { tasklists = {} } = data.included || {};

  for (let i = 0; i < forms.length; i += 1) {
    const form = forms[i];
    const projectId = form.hostObject?.type !== 'projects' ? 0 : form.hostObject.id;
    form.project = projects[projectId];
    form.updatedAt = DateTime.fromISO(form.updatedAt);

    if (form.project) {
      form.project.permissions = projectPermissions[projectId];
    }

    form.tasklistId = (form.destinationObject?.type === 'tasklists' && form.destinationObject?.id) || null;
    form.tasklist = tasklists[form.tasklistId];
  }

  return forms;
}

function orderByNameAsc(form1, form2) {
  return form1.content.name.localeCompare(form2.content.name);
}
function orderByNameDesc(form1, form2) {
  return form2.content.name.localeCompare(form1.content.name);
}
function orderByTasklistNameAsc(form1, form2) {
  return form1.tasklist?.name.localeCompare(form2.tasklist?.name);
}
function orderByTasklistNameDesc(form1, form2) {
  return form2.tasklist?.name.localeCompare(form1.tasklist?.name);
}
function orderByTotalSubmissionsAsc(form1, form2) {
  return form1.totalSubmissions - form2.totalSubmissions;
}
function orderByTotalSubmissionsDesc(form1, form2) {
  return form2.totalSubmissions - form1.totalSubmissions;
}
function orderByIsSharedAsc(form1, form2) {
  return form1.isShared - form2.isShared;
}
function orderByIsSharedDesc(form1, form2) {
  return form2.isShared - form1.isShared;
}
function orderByDateUpdatedAsc(form1, form2) {
  return form1.updatedAt - form2.updatedAt;
}
function orderByDateUpdatedDesc(form1, form2) {
  return form2.updatedAt - form1.updatedAt;
}

function responseToMeta({ data: { meta } }) {
  return { totalCount: meta.page?.count };
}

export function useFormsV3Loader({ params, count, pageSize }) {
  const url = `/projects/api/v3/forms.json`;

  const order = computed(() => {
    const { orderBy: _orderBy, orderMode } = params.value || {};
    const asc = orderMode ? orderMode === 'asc' : true;

    const orderBy = _orderBy ? _orderBy.toLowerCase() : '';

    switch (orderBy) {
      case 'totalsubmissions':
        return asc ? orderByTotalSubmissionsAsc : orderByTotalSubmissionsDesc;
      case 'dateupdated':
        return asc ? orderByDateUpdatedAsc : orderByDateUpdatedDesc;
      case 'tasklistname':
        return asc ? orderByTasklistNameAsc : orderByTasklistNameDesc;
      case 'isshared':
        return asc ? orderByIsSharedAsc : orderByIsSharedDesc;
      case 'name':
      default:
        return asc ? orderByNameAsc : orderByNameDesc;
    }
  });

  const { state, refresh, update } = useListLoader({
    url,
    params,
    count,
    order,
    responseToItems,
    responseToMeta,
    pageSize,
    type: 'form',
  });

  useRealTimeUpdates((event) => {
    if (event.type === 'form') {
      refresh();
    }
  });

  useOptimisticUpdates((event) => {
    if (event.type !== 'form') {
      return;
    }
    update((forms) => {
      const optimisticForm = { ...event.form };

      if (event.action === 'create') {
        return [...forms, optimisticForm];
      }
      if (event.action === 'update') {
        return forms.map((form) => (form.id === optimisticForm.id ? { ...form, ...optimisticForm } : form));
      }
      if (event.action === 'delete') {
        return forms.filter((form) => form.id !== optimisticForm.id);
      }
      return forms;
    }, event.promise);
  });

  return state;
}
