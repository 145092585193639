const symbol = Symbol('useDrawer');

/**
 * This doesnt do much, but it is still useful to
 * be explicit about what is being provided and injected.
 * */

/**
 *
 * @param {object} options
 * @param {() => void} options.close function to close the drawer
 */
function Drawer({ close } = {}) {
  return {
    close,
  };
}

/**
 *
 * @param {Parameters<Drawer>} options
 */
export function provideDrawer(options) {
  const drawer = Drawer(options);
  provide(symbol, drawer);
}

/**
 *
 * @returns {Drawer}
 */
export function useDrawer() {
  return inject(symbol);
}
