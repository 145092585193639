import { useListLoader } from '../base/useListLoader';
import { useRealTimeUpdates } from '../base/useRealTimeUpdates';
import { useCurrentUser } from '../user/useCurrentUser';

function responseToItems(response) {
  return response.data.levels;
}

/**
 * Loads a list of levels from the Teamwork v3 API.
 */
export function useBadgeLevelsV3Loader({ userId: _userId, params, count, pageSize = 50 }) {
  const user = useCurrentUser();
  const userId = computed(() => unref(_userId) ?? user.value.id);
  const url = computed(() => `/projects/api/v3/user/${userId.value}/badgelevels.json`);

  const { state, refresh } = useListLoader({
    url,
    params,
    count,
    responseToItems,
    pageSize,
  });

  useRealTimeUpdates((event) => {
    if (event.type === 'badge' && event.userId === userId.value) {
      refresh();
    }
  });

  return state;
}
