<script setup>
import { useI18n } from '@/util';

const { t } = useI18n();
</script>
<template>
  <div class="text-body-1">
    <p>
      {{
        t(
          'We spoke about time estimates before in relation to Time, but did you know that estimated time is also fundamental to planning?',
        )
      }}
    </p>
    <p class="pt-6">
      {{
        t(
          'By adding estimated time you can allot hours to your users on the project and begin tracking their capacity in our workload planner.',
        )
      }}
    </p>
  </div>
</template>
