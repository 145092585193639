import { useRoute } from './useRoute';

const useEmbeddedModeSymbol = Symbol('useEmbeddedMode');

function EmbeddedMode() {
  const currentRoute = useRoute();

  // To maintain backwards compatibility with the TWA interface
  // the `hideheadernavigation` param is also accepted.
  const embeddedModeQuery =
    currentRoute.query.embed === 'true' ||
    currentRoute.query.embed === '1' ||
    currentRoute.query.hideheadernavigation === 'true' ||
    currentRoute.query.hideheadernavigation === '1';

  const isEmbeddedModeEnabled = shallowRef(embeddedModeQuery);

  function enableEmbeddedMode() {
    isEmbeddedModeEnabled.value = true;
  }
  function disableEmbeddedMode() {
    isEmbeddedModeEnabled.value = false;
  }

  return {
    isEmbeddedModeEnabled,
    enableEmbeddedMode,
    disableEmbeddedMode,
  };
}

/**
 * @type {EmbeddedMode}
 */
export function useEmbeddedMode() {
  return inject(useEmbeddedModeSymbol);
}

/**
 * @type {EmbeddedMode}
 */
export function provideEmbeddedMode() {
  const embeddedMode = EmbeddedMode();
  provide(useEmbeddedModeSymbol, embeddedMode);
  return embeddedMode;
}
