<script setup>
import { useI18n } from '@/util';

const { t } = useI18n();
</script>
<template>
  <div class="text-body-1">
    <p>
      {{ t('Create invoices on your projects based on time logs and expenses, or create a fixed price invoice.') }}
    </p>
    <p class="pt-6">
      {{
        t(
          'Click on the “Set Rates” button to define the hourly rates for both yourself and your team members. This will give you the ability to generate invoices based on the time logs entered by your team, providing your clients with a transparent view of what they are being billed for.',
        )
      }}
    </p>
  </div>
</template>
