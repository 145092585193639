const symbol = Symbol('useLoaders');

function Loaders() {
  // The key is a ref containing a list of items.
  // The value is a ref containing the type of items.
  const itemsMap = new Map();

  return {
    /**
     * Registers the specified items and type.
     * @param {VueRef<string>} type The type name of `items`.
     * @param {VueRef<Array<any>>} items The `items`.
     */
    registerItems(type, items) {
      itemsMap.set(items, type);
      onScopeDispose(() => itemsMap.delete(items));
    },

    /**
     * Gets a registered item by `type` and `id`.
     * @param {string} type The type of the item.
     * @param {number|string} id The id of the item.
     * @returns {any|undefined} The requested item or `undefined`, if not found.
     */
    getItem(type, id) {
      for (const [{ value: items }, { value: itemsType }] of itemsMap) {
        if (type === itemsType) {
          for (const item of items) {
            if (id === item.id) {
              return item;
            }
          }
        }
      }
      return undefined;
    },
  };
}

/**
 * @param {import('vue').App} app
 * @type {Loaders}
 */
export function loadersPlugin(app) {
  app.provide(symbol, Loaders());
}

/**
 * @type {Loaders}
 */
export function useLoaders() {
  return inject(symbol);
}
