<script setup>
import { useI18n } from '@/util';

const { t } = useI18n();
</script>
<template>
  <div class="text-body-1">
    <p>
      {{
        t(
          'The Planned vs Actual report will show you if tasks and milestones are being completed on time, and if not, how long they are being delayed.',
        )
      }}
    </p>
    <p class="pt-6">
      {{
        t(
          'With a clear view of your planned delivery vs. actual progress, you can easily assess performance across your team, reprioritize work (if needed), and adjust timelines (if necessary), ensuring efficient and profitable project delivery.',
        )
      }}
    </p>
  </div>
</template>
