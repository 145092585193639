import { useCurrentAccount } from '../account/useCurrentAccount';
import { useCohort } from '../cohort/useCohort';
import { useFeatures } from '../features/useFeatures';
import { usePendo } from '../features/usePendo';

/**
 * Constants, computed properties and functions related to Experiment A-24-20
 * https://app.launchdarkly.com/projects/default/flags/projects-exp-a-24-20-differentiated-onboarding-for-icp-20-plus/targeting?env=production&env=staging&selected-env=production
 * https://digitalcrew.teamwork.com/spaces/growth/page/59525-a-24-20-differentiated-onboarding-for-icp-20
 */

export function useExperimentA2420() {
  const { isCompanySizeAboveTwenty, isPlanDeliver, isSiteOwner } = useCohort();
  const { differentiatedOnboardingForIcp20PlusEnabled } = useFeatures();
  const { trackExperimentInPendo, trackPendoEvent } = usePendo();
  const account = useCurrentAccount();

  const trackingVariantEventName = 'ICP20+_ONBOARDING_EVENT';
  const commonMetrics = ['account_id'];
  const resourceManagementCampaignId = 'website-vwo-210_control';

  const { launchDarklyFlagKey, defaultValue } = differentiatedOnboardingForIcp20PlusEnabled;

  const appLevelTargeting = computed(() => isCompanySizeAboveTwenty.value && !isPlanDeliver.value);

  const isResourceManagementCampaign = computed(() =>
    account.value?.webSignupVariant?.includes(resourceManagementCampaignId),
  );
  const isExpA2420Variation = computed(
    () =>
      (differentiatedOnboardingForIcp20PlusEnabled.value && appLevelTargeting.value) ||
      (isSiteOwner.value && isResourceManagementCampaign.value),
  );

  function trackExperimentA2420() {
    trackExperimentInPendo({
      launchDarklyFlagKey,
      defaultValue,
      appLevelTargeting,
      ignoreInExperimentOnRuleMatch: true,
    });
  }

  function trackEventA2420({ eventAction, meta = {} }) {
    trackPendoEvent({
      eventName: trackingVariantEventName,
      commonMetrics,
      metadata: {
        event_action: eventAction,
        ...meta,
      },
    });
  }

  return {
    isExpA2420Variation,
    trackExperimentA2420,
    trackEventA2420,
  };
}
