<script setup>
import { useI18n } from '@/util';

const { t } = useI18n();
</script>
<template>
  <div class="text-body-1">
    <p>
      {{
        t(
          "You don't need to remember to update key details as you move tasks through board columns -- {productName} can do that for you!",
          { productName: 'Teamwork' },
        )
      }}
    </p>
    <p class="pt-6">
      {{
        t(
          "Save time by automating steps in your workflow. Create automations within your board view to automatically update tasks' properties or to streamline communications by using automations to notify when the task cards are moved to a particular column.",
        )
      }}
    </p>
  </div>
</template>
