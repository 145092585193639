import { generateUuid } from '@/util';

const symbol = Symbol('useBreadcrumbs');

/**
 * @typedef {import('@/designsystem/components/navigation/breadcrumbs/constants').Breadcrumb} Breadcrumb
 */

/**
 * Makes sure that the breadcrumb has an id and a dataIdentifier
 * @param {string} dataIdentifierPrefix
 * @returns {(breadcrumb: Breadcrumb) => Breadcrumb}
 */
function normalizeBreadcrumb(dataIdentifierPrefix) {
  return (breadcrumb) => {
    const id = breadcrumb.id ?? generateUuid();
    const dataIdentifier = `${dataIdentifierPrefix}-${breadcrumb.dataIdentifier ?? id}`;
    return {
      ...breadcrumb,
      id,
      dataIdentifier,
    };
  };
}

export function provideBreadcrumbs({ dataIdentifierPrefix = 'breadcrumb', fixedBreadcrumbs = [] } = {}) {
  const fixedSegment = computed(() => fixedBreadcrumbs.map(normalizeBreadcrumb(dataIdentifierPrefix)));
  const segments = shallowRef([fixedSegment]);

  const breadcrumbs = computed(() =>
    segments.value
      .map((s) => s.value)
      .filter(Boolean)
      .flat(),
  );

  provide(symbol, {
    segments,
    dataIdentifierPrefix,
    breadcrumbs,
  });
}

export function useCurrentBreadcrumbs() {
  const { breadcrumbs } = inject(symbol);
  return breadcrumbs;
}

/**
 * Adds a segment to list of breadcrumbs
 * The segment is removed automatically when the component is unmounted.
 * @param {Array<Breadcrumb|ComputedRef<Breadcrumb>> | undefined} breadcrumbs The breadcrumbs to add.
 */
export function useBreadcrumbs(breadcrumbs = []) {
  const { segments, dataIdentifierPrefix } = inject(symbol);

  // By creating a new computed here we can guarantee
  // that we'll remove the correct segment in `onUnmounted`.
  const segment = computed(() => {
    const breadcrumbsSegment = unref(breadcrumbs) ?? [];
    return breadcrumbsSegment.map(normalizeBreadcrumb(dataIdentifierPrefix));
  });

  segments.value = segments.value.concat([segment]);
  onUnmounted(() => {
    // eslint-disable-next-line vue/no-ref-as-operand
    segments.value = segments.value.filter((s) => s !== segment);
  });
}
