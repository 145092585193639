export const publicRoutes = [
  {
    path: '/forms/:formId/:pageId?',
    component: () => import('./RoutePublicForm.vue'),
    props: (route) => ({
      formId: route.params.formId,
      pageId: route.params.pageId ? Number(route.params.pageId) : undefined,
      isInPreviewMode: route.query.preview === 'true',
    }),
  },
];
