<script setup>
import { useI18n } from '@/util';

const { t } = useI18n();
</script>
<template>
  <div class="text-body-1">
    <p>
      {{
        t(
          'Estimated time is an estimate for how long this task should take to complete. Adding time estimates to your tasks will activate key planning and reporting features within {productName} to help keep your projects on time and appropriately resourced (we’re looking at you Workload Planner and Planned vs Actual report).',
          { productName: 'Teamwork' },
        )
      }}
    </p>
    <p class="pt-6">
      {{ t("Estimated time doesn't equal actual time.") }}
    </p>
    <p>
      {{
        t(
          'Estimated time is a rough guide of how long we expect it to take to complete a task, but in actuality, tasks may take longer or shorter than expected.',
        )
      }}
    </p>
  </div>
</template>
