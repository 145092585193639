import { useItemLoader } from '../base/useItemLoader';
import { useRealTimeUpdates } from '../base/useRealTimeUpdates';

export function useImporterStatsV3Loader() {
  const { state, refresh } = useItemLoader({
    url: '/projects/api/v3/importer/stats.json',
  });

  useRealTimeUpdates((event) => {
    if (event.type === 'finished' && event.action === 'importer') {
      refresh();
    }
  });

  return state;
}
