<script setup>
import { useI18n } from '@/util';
import LscDatePickerCalendarMonthsMenu from './LscDatePickerCalendarMonthsMenu.vue';
import { useDatePicker } from './useDatePicker';

const { formattedVisibleMonth, navigateVisibleMonth, navigateToCurrentMonth } = useDatePicker();
const { t } = useI18n();

const monthMenuOpen = defineModel('monthMenuOpen', {
  type: Boolean,
  default: false,
});
</script>
<template>
  <div class="flex items-center justify-between gap-2">
    <LscIconButton
      icon="lsi-collapse"
      size="sm"
      :ariaLabel="t('Go to previous month')"
      @click="navigateVisibleMonth(-1)"
    />
    <LscDatePickerCalendarMonthsMenu
      location="bottom center"
      offset="8px"
      origin="auto"
      @afterEnter="monthMenuOpen = true"
      @afterLeave="monthMenuOpen = false"
    >
      <template #activator="activator">
        <LscButton
          variant="tertiary"
          :ariaExpanded="activator.isActive"
          :aria-label="t('Select month and year')"
          size="md"
          appendIcon="lsi-dropdown"
          v-bind="activator.props"
          @dblclick.prevent.stop="navigateToCurrentMonth"
        >
          {{ formattedVisibleMonth }}
        </LscButton>
      </template>
    </LscDatePickerCalendarMonthsMenu>
    <LscIconButton
      icon="lsi-open-panel"
      size="sm"
      :ariaLabel="t('Go to next month')"
      @click.stop="navigateVisibleMonth(1)"
    />
  </div>
</template>
