<script setup>
import { useI18n } from '@/util';

const { t } = useI18n();
</script>
<template>
  <div class="text-body-1">
    <p>
      {{
        t(
          'Within the board view, tasks are represented as cards (the white boxes) and you can see them organized into columns.',
        )
      }}
    </p>
    <p class="pt-6">
      {{
        t(
          'Best practice: Use columns to represent statuses in a workflow. For example, this "Doing" column is for the "in progress" stage of a workflow and any tasks in it are actively being worked on.',
        )
      }}
    </p>
  </div>
</template>
