import { useCurrentAccount } from '../account/useCurrentAccount';
import { useCohort } from '../cohort/useCohort';
import { useFeatures } from '../features/useFeatures';
import { usePendo } from '../features/usePendo';
import { usePermissions } from '../permissions/usePermissions';

/**
 * Constants, computed properties and functions related to Experiment A-24-19 Scale upgrade during trial
 * https://app.launchdarkly.com/projects/default/flags/projects-exp-a-24-19-scale-trial-upgrade/targeting?env=production&env=staging&selected-env=production
 * https://digitalcrew.teamwork.com/spaces/growth/page/59200-a-24-19-increase-discoverability-of-book-a-demo-rep-contacts-and-offer-scale-trial-to-icp-50
 */

export function useExperimentA2419() {
  const { scaleTrialUpgrade } = useFeatures();
  const { isSiteOwner } = usePermissions();
  const { trackExperimentInPendo, trackPendoEvent } = usePendo();
  const { isCompanySizeAboveFifty, isICP, isInFreeTrial } = useCohort();
  const account = useCurrentAccount();

  const GROW_PLAN_IDS = [22, 42, 48, 65, 69];

  const commonMetrics = ['account_id', 'company_size', 'company_user_role', 'company_sector', 'plan_id'];
  const eventName = 'SCALE_TRIAL_UPGRADE_EVENT';

  const { launchDarklyFlagKey, defaultValue } = scaleTrialUpgrade;
  const ignoreInExperimentOnRuleMatch = true;

  const isTargetPlan = computed(() => GROW_PLAN_IDS.includes(account.value.pricePlanId));

  const expA2419AppLevelTargeting = computed(
    () =>
      isTargetPlan.value && isSiteOwner?.value && isCompanySizeAboveFifty.value && isICP.value && isInFreeTrial.value,
  );

  const isExpA2419Variation = computed(() => expA2419AppLevelTargeting.value && scaleTrialUpgrade.value);

  function trackExperimentA2419() {
    trackExperimentInPendo({
      launchDarklyFlagKey,
      defaultValue,
      ignoreInExperimentOnRuleMatch,
      appLevelTargeting: expA2419AppLevelTargeting,
    });
  }

  function trackEventA2419({ eventAction, meta = {} }) {
    trackPendoEvent({
      eventName,
      commonMetrics,
      metadata: {
        event_action: eventAction,
        ...meta,
      },
    });
  }

  return {
    isExpA2419Variation,
    trackExperimentA2419,
    trackEventA2419,
  };
}
