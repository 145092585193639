import { useDebounceFn } from '@vueuse/core';
import { useBackOff } from '@/util';
import { useAxios } from '../base/useAxios';
import { useCurrentUser } from '../user/useCurrentUser';
import { normalizeNotification } from './normalizeNotification';

function orderDescByDate({ date: date1 }, { date: date2 }) {
  return date2 - date1;
}

/**
 * The notifications API has some properties that make it
 * incompatible with `useListLoader`. For this reason we
 * have implemented a new loader here.
 * It more or less has the same api as the list loaders.
 * For some more context see discussion here: https://github.com/Teamwork/frontend/pull/572#issuecomment-964206786
 * TODO Update it to exactly match the `useListLoader` API.
 *
 * @param {Object} options
 * @param {MaybeRef<Record>} options.params
 * @param {MaybeRef<number>} options.pageSize
 */
export function useNotificationsFetcher({ params: _params = {}, pageSize: _pageSize = 20 } = {}) {
  const axiosInstance = useAxios();
  const user = useCurrentUser();
  const backOff = useBackOff();
  const params = shallowRef(_params);
  const itemsMap = shallowRef(new Map());
  const pageSize = shallowRef(_pageSize);
  const cursor = shallowRef(undefined);
  const meta = shallowRef(undefined);
  const response = shallowRef(undefined);
  const error = shallowRef(undefined);
  const loading = shallowRef(false);
  const inSync = shallowRef(true);
  const initialized = computed(() => cursor.value !== undefined);
  const allLoaded = computed(() => cursor.value === null);
  const loadedCount = shallowRef(0);
  const totalCount = shallowRef(undefined);
  const url = computed(() => '/projects/api/v3/notifications.json');

  const items = computed(() => {
    return Array.from(itemsMap.value.values()).sort(orderDescByDate);
  });

  function responseToMeta({ data }) {
    return data?.meta;
  }

  function responseToItems({ data }) {
    return data?.notifications.map((notification) => normalizeNotification(notification, user.value?.id));
  }

  async function loadMore() {
    if (loading.value) {
      return;
    }

    if (backOff.active.value) {
      return;
    }

    if (cursor.value !== undefined) {
      if (cursor.value === null) {
        return;
      }
    }

    loading.value = true;
    try {
      response.value = await axiosInstance.get(url.value, {
        params: {
          ...params.value,
          cursor: cursor.value,
          limit: pageSize.value,
        },
      });

      error.value = undefined;

      backOff.reset();

      meta.value = responseToMeta(response.value);

      const loadedItems = responseToItems(response.value);
      loadedItems.forEach((item) => {
        return itemsMap.value.set(item.id, item);
      });

      triggerRef(itemsMap);

      cursor.value = meta.value.nextCursor || null;

      if (cursor.value !== null) {
        loadedCount.value += loadedItems.length;
        totalCount.value = Infinity;
      } else {
        loadedCount.value = itemsMap.value.size;
        totalCount.value = itemsMap.value.size;
      }

      loading.value = false;
    } catch (axiosError) {
      if (!axiosError?.isAxiosError) {
        throw axiosError;
      }

      response.value = undefined;
      error.value = axiosError;

      backOff.start();
    }
  }

  /**
   * clear loader - state is reset to empty values.
   * However unlike reset(), the loader remains initialized and
   * items cans still be added and removed.
   */
  function clear() {
    loadedCount.value = 0;
    totalCount.value = 0;
    response.value = undefined;
    error.value = undefined;
    meta.value = undefined;
    loading.value = false;
    cursor.value = null;
    itemsMap.value.clear();
    triggerRef(itemsMap);
    backOff.reset();
  }

  /**
   * uninitializes and resets loader back to initial state before first load.
   * items cant be added or removed from loader until it is initialized again.
   */
  function reset() {
    loadedCount.value = 0;
    totalCount.value = undefined;
    response.value = undefined;
    error.value = undefined;
    meta.value = undefined;
    loading.value = undefined;
    cursor.value = undefined;
    itemsMap.value.clear();
    triggerRef(itemsMap);
    backOff.reset();
  }

  function getItem(id) {
    return itemsMap.value.get(id);
  }

  function add(item) {
    itemsMap.value.set(item.id, item);

    totalCount.value += 1;
    loadedCount.value += 1;

    triggerRef(itemsMap);
    triggerRef(totalCount);
    triggerRef(loadedCount);
  }

  function addMultiple(_items) {
    _items.forEach((item) => {
      if (!item.id) {
        return;
      }

      itemsMap.value.set(item.id, item);

      totalCount.value += 1;
      loadedCount.value += 1;
    });

    triggerRef(itemsMap);
    triggerRef(totalCount);
    triggerRef(loadedCount);
  }

  // helper function to update single item
  // does not trigger itemMap ref
  function updateItem(item) {
    if (!itemsMap.value.get(item.id)) {
      return;
    }

    itemsMap.value.set(item.id, item);
  }

  function update(item) {
    updateItem(item);

    triggerRef(itemsMap);
  }

  function updateMultiple(_items) {
    _items.forEach((item) => {
      updateItem(item);
    });

    triggerRef(itemsMap);
  }

  function remove({ id }) {
    itemsMap.value.delete(id);

    totalCount.value -= 1;
    loadedCount.value -= 1;

    triggerRef(itemsMap);
    triggerRef(totalCount);
    triggerRef(loadedCount);
  }

  function removeMultiple(_items) {
    _items.forEach((item) => {
      if (!item.id) {
        return;
      }

      itemsMap.value.delete(item.id);

      totalCount.value -= 1;
      loadedCount.value -= 1;
    });

    triggerRef(itemsMap);
    triggerRef(totalCount);
    triggerRef(loadedCount);
  }

  /**
   * refresh the fetcher state in background (ie dont show loading state)
   * does nothing if loader not initialised
   *
   * @returns {Promise<void>}
   */
  async function refresh() {
    if (!initialized.value) {
      return;
    }
    try {
      response.value = await axiosInstance.get(url.value, {
        params: {
          ...params.value,
          limit: loadedCount.value,
        },
      });

      error.value = undefined;

      meta.value = responseToMeta(response.value);

      const loadedItems = responseToItems(response.value);
      loadedItems.forEach((item) => {
        return itemsMap.value.set(item.id, item);
      });

      triggerRef(itemsMap);
      cursor.value = meta.value.nextCursor || null;

      if (cursor.value !== null) {
        loadedCount.value += loadedItems.length;
        totalCount.value = Infinity;
      } else {
        loadedCount.value = itemsMap.value.size;
        totalCount.value = itemsMap.value.size;
      }
    } catch (axiosError) {
      if (!axiosError?.isAxiosError) {
        throw axiosError;
      }

      response.value = undefined;
      error.value = axiosError;
    }
  }

  // refresh debounced to 2 seconds
  const debouncedRefresh = useDebounceFn(refresh, 2000);

  watch(
    params,
    () => {
      if (!initialized.value) {
        return;
      }

      reset();
      loadMore();
    },
    { deep: true },
  );

  watch(backOff.active, (val) => {
    if (val) {
      return;
    }

    loading.value = false;

    loadMore();
  });

  return {
    add,
    addMultiple,
    update,
    updateMultiple,
    remove,
    removeMultiple,
    loadMore,
    reset,
    clear,
    refresh: debouncedRefresh,
    getItem,
    state: {
      pageSize,
      items,
      inSync,
      meta,
      totalCount,
      loading,
      allLoaded,
      response,
      initialized,
      error,
    },
  };
}
