<template>
  <div class="flex h-screen flex-col items-stretch">
    <div class="flex items-center">
      <slot name="header" />
    </div>
    <div class="min-h-0 flex-1">
      <slot name="content" />
    </div>
    <div v-if="$slots['footer']" class="flex w-full grow-0 flex-col items-stretch px-6">
      <slot name="footer" />
    </div>
  </div>
</template>
