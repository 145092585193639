<script setup>
import { useI18n } from '@/util';

const { t } = useI18n();
</script>
<template>
  <div class="text-body-1">
    <p>
      {{
        t(
          'A project is where all your work lives. Think of a project like a folder. You can have different projects for different pieces of work or even a project for each client.',
        )
      }}
    </p>
    <p class="pt-6">
      {{
        t(
          'You can create a project from scratch or from a project template and select which team members should have access to each project.',
        )
      }}
    </p>
  </div>
</template>
