import { useFeatures, useWorkflowsV3Loader } from '@/api';

/**
 * @returns {import('./useFeaturesLimit').FeatureLimitObject}
 */
export function useWorkflowsLimit() {
  const { workflowsLimit: featureLimit, workflowsEnabled: isFeatureEnabled } = useFeatures();
  const { meta, inSync, loaded } = useWorkflowsV3Loader({
    count: 0,
    params: { skipCounts: false, onlyGlobalWorkflows: true, includeTotalCount: true },
  });

  const totalCount = computed(() => {
    return meta.value?.totalCount || 0;
  });

  const loading = computed(() => !loaded.value || !inSync.value);

  const isOverLimit = computed(() => {
    if (loading.value) {
      return false;
    }

    if (featureLimit.value === -1) {
      return false;
    }

    if (totalCount.value >= featureLimit.value) {
      return true;
    }

    return false;
  });

  return {
    totalCount,
    featureLimit,
    isFeatureEnabled,
    isOverFeatureLimit: isOverLimit,
    loading,
  };
}
