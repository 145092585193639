<script setup lang="ts">
import { Icon } from '@iconify/vue/dist/offline';
import type { LscIconSize } from './LscIcon.types.js';

const props = defineProps({
  /** The icon to display */
  icon: {
    type: String as PropType<LscIconName>,
    required: true,
  },
  /** The size of the icon. This can be a predefined size or a number in pixels. */
  size: {
    type: [String, Number] as PropType<LscIconSize>,
    default: 'md',
    validator: (value: LscIconSize) => {
      if (typeof value === 'number' && value <= 0) {
        warn('The size must be a positive number.');
      }
      return true;
    },
  },
  /** The color of the icon. Please prefer using the predefined tailwind classes. */
  color: {
    type: String,
    default: undefined,
  },
});

const iconVariantStyleConfig = tv({
  variants: {
    size: {
      xs: 'size-[--lsds-a-icon-size-xs]',
      sm: 'size-[--lsds-a-icon-size-sm]',
      md: 'size-[--lsds-a-icon-size-md]',
      lg: 'size-[--lsds-a-icon-size-lg]',
      xl: 'size-[--lsds-a-icon-size-xl]',
    },
    isCustom: {
      true: 'size-[--custom-icon-size]',
    },
    hasColor: {
      true: '!text-[--custom-icon-color]',
    },
  },
});

const isCustom = computed(() => Number.isInteger(props.size));

const styles = computed(() => ({
  '--custom-icon-size': isCustom.value ? `${props.size}px` : undefined,
  '--custom-icon-color': props.color,
}));

const styleSize = computed(() => (typeof props.size === 'number' ? undefined : props.size));
const hasColor = computed(() => Boolean(props.color));

const classes = computed(() =>
  iconVariantStyleConfig({
    size: styleSize.value,
    isCustom: isCustom.value,
    hasColor: hasColor.value,
  }),
);
</script>

<template>
  <Icon mode="svg" height="none" :style="styles" :class="classes" :icon="icon" />
</template>
