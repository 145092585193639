import { useAxios } from '../base/useAxios';
import { useRealTimeUpdates } from '../base/useRealTimeUpdates';

export function useCompaniesActions() {
  const api = useAxios();
  const { socketId, emit: emitRealTimeUpdate } = useRealTimeUpdates();

  function config() {
    return {
      headers: {
        'Socket-ID': socketId,
      },
    };
  }

  return {
    createCompany(newCompany) {
      const promise = api
        .post('/projects/api/v3/companies.json', newCompany, config())
        .then(({ data: { company } }) => {
          emitRealTimeUpdate({
            type: 'company',
            action: 'new',
            companyId: company.id,
          });

          return company;
        });

      return promise;
    },
  };
}
