/* eslint-disable no-underscore-dangle */
import { publicShareToken } from '../../base/publicShareToken';

export function useDefaultParams(_params) {
  const params = computed(() => {
    const __params = unref(_params) || {};

    const include = __params.include || ['users'].join(',');

    return {
      ...__params,
      include,
      token: publicShareToken,
    };
  });

  return {
    params,
  };
}
