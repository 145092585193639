import { extendRef, useIntervalFn, useNow } from '@vueuse/core';
import { DateTime } from 'luxon';
import { useCurrentUser } from '../user/useCurrentUser';
import { useLaunchDarkly } from './useLaunchDarkly';
import { useTeamworkFeaturesState } from './useTeamworkFeatures';

export function useFeaturesUtil() {
  const user = useCurrentUser();

  const nowDate = useNow({ interval: 60 * 60 * 1000 });
  const now = computed(() => DateTime.fromJSDate(nowDate.value));
  const featureDateBuffer = 1000 * 60 * 5; // 5 minutes buffer to account for the possible inaccuracy of the client side clock

  const { items: twFeaturesList, inSync: twFeaturesInSync } = useTeamworkFeaturesState();
  const twFeaturesInitialized = shallowRef(false);
  const twFeatures = computed(() => {
    const features = new Map();
    twFeaturesList.value.forEach((feature) => {
      features.set(feature.key, feature);
    });
    return features;
  });
  const unwatch = watch(
    twFeaturesInSync,
    () => {
      if (twFeaturesInSync.value) {
        twFeaturesInitialized.value = true;
        queueMicrotask(() => unwatch());
      }
    },
    { immediate: true },
  );

  const { client: ldClient, ready: ldReady, initialized: ldInitialized } = useLaunchDarkly();
  const launchDarklyUpdateCount = shallowRef(0);
  let removeChangeListener;

  function triggerLaunchDarklyUpdate() {
    launchDarklyUpdateCount.value += 1;
  }

  watch([ldReady, ldInitialized], triggerLaunchDarklyUpdate);

  function addChangeListener() {
    const client = ldClient.value;
    if (client) {
      client.on('change', triggerLaunchDarklyUpdate);
      return () => client.off('change', triggerLaunchDarklyUpdate);
    }
    return undefined;
  }

  watch(
    ldClient,
    () => {
      removeChangeListener?.();
      removeChangeListener = addChangeListener();
    },
    { immediate: true },
  );

  // Make sure that the calls to the LaunchDarkly `track` function are recorded,
  // even if the app keeps running for more than 24 hours.
  // See https://docs.launchdarkly.com/home/experimentation/interpreting#understanding-conversions
  useIntervalFn(triggerLaunchDarklyUpdate, 24 * 60 * 60 * 1000);

  onScopeDispose(() => {
    removeChangeListener?.();
    removeChangeListener = undefined;
  });

  /**
   * @template {any} [T=boolean] flagType
   * @param {string} launchDarklyFlagKey
   * @param {T} defaultValue
   * @param {T} overrideValue
   * @returns {ComputedRef<T>} a `computed` which provides a feature flag value from LaunchDarkly.
   */
  function ldFlag(launchDarklyFlagKey, defaultValue, overrideValue) {
    const flag = computed(() => {
      if (unref(overrideValue) !== undefined) {
        return unref(overrideValue);
      }
      // Accessing `launchDarklyUpdateCount.value` retriggers the computed when the flag
      // value must be retrieved from LaunchDarkly again.
      if (ldClient.value && launchDarklyUpdateCount.value >= 0) {
        return ldClient.value.variation(launchDarklyFlagKey, unref(defaultValue));
      }
      return unref(defaultValue);
    });
    return extendRef(flag, { launchDarklyFlagKey, defaultValue });
  }

  /**
   * Returns a `computed` which indicates if a Teamwork feature is enabled.
   * @param {string} name
   * @param {boolean} overrideValue
   */
  function twEnabled(name, overrideValue) {
    return computed(() => {
      if (unref(overrideValue) !== undefined) {
        return unref(overrideValue);
      }
      const feature = twFeatures.value.get(name);
      const currentDateTime = Date.now();
      return Boolean(
        now.value != null && // trigger reactivity periodically
          feature &&
          (feature.startAt == null || currentDateTime + featureDateBuffer > feature.startAt) &&
          (feature.endAt == null || currentDateTime - featureDateBuffer < feature.endAt) &&
          (feature.scope !== 'ownercompany' || user.value?.inOwnerCompany) &&
          // feature.scope === 'nobody' means "disabled in beta, but potentially togglable"
          feature.scope !== 'nobody',
      );
    });
  }

  /**
   * Returns a `computed` which evaluates to the value of a Teamwork feature.
   * @param {string} name
   * @param {function} normalize
   * @param {*} overrideValue
   */
  function twValue(name, normalize, overrideValue, treatEmptyLimitAsUnlimited) {
    return computed(() => {
      if (unref(overrideValue) !== undefined) {
        return unref(overrideValue);
      }
      const feature = twFeatures.value.get(name);
      if (treatEmptyLimitAsUnlimited && feature?.value === '') {
        return -1;
      }
      return normalize(feature?.value);
    });
  }

  const initialized = computed(() => ldInitialized.value && twFeaturesInitialized.value);

  return {
    initialized,
    ldFlag,
    twEnabled,
    twValue,
  };
}
