import { usePendo } from '@/api';

/**
 * Provides functions for tracking Pendo events related to Jump To drawer located in the side nav.
 * Measurement plan: https://digitalcrew.teamwork.com/spaces/growth/page/56843-navigation-tracking
 */

export function useProjectJumpToDrawerTracking() {
  const { trackPendoEvent } = usePendo();

  const JUMP_TO_COMMON_METRICS = ['account_id'];

  const JUMP_TO_EVENT_NAME = 'NAVIGATION_EVENT';

  function track(metadata) {
    trackPendoEvent({
      eventName: JUMP_TO_EVENT_NAME,
      commonMetrics: JUMP_TO_COMMON_METRICS,
      metadata: {
        event_action: 'jumpto_event',
        ...metadata,
      },
    });
  }

  function trackJumpToDrawerFooterLinksClicked(actionType) {
    track({
      action_type: actionType,
    });
  }

  function trackJumpToDrawerSearchSumbmitted() {
    track({
      action_type: 'search_submitted',
    });
  }

  function trackJumpToDrawerTabClicked(tab) {
    track({
      action_type: 'tab_clicked',
      tab_item: tab,
    });
  }

  function trackJumpToDrawerProjectClicked(tab) {
    track({
      action_type: 'project_clicked',
      tab_item: tab,
    });
  }

  function trackJumpToDrawerPinning(pinDataIdentifier, isPanelPinned, activePanel) {
    if (pinDataIdentifier === 'side-nav-jump-to-panel-pin-icon') {
      track({
        action_type: isPanelPinned === activePanel ? 'pinned' : 'unpinned',
      });
    }
  }

  function trackJumpToDrawerClosed(closeDataIdentifier) {
    if (closeDataIdentifier === 'side-nav-jump-to-panel-close-icon') {
      track({
        action_type: 'closed',
      });
    }
  }

  function trackJumpToDrawerAddProjectClicked() {
    track({
      action_type: 'add_project_clicked',
    });
  }

  return {
    trackJumpToDrawerFooterLinksClicked,
    trackJumpToDrawerSearchSumbmitted,
    trackJumpToDrawerTabClicked,
    trackJumpToDrawerProjectClicked,
    trackJumpToDrawerPinning,
    trackJumpToDrawerClosed,
    trackJumpToDrawerAddProjectClicked,
  };
}
