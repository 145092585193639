<script setup>
import { useI18n } from '@/util';

const { t } = useI18n();
</script>
<template>
  <div class="text-body-1">
    <p>
      {{
        t(
          "Technically: You'll find My Work by clicking the Home button on your side nav. This is your work hub within {teamwork}. You'll see all work assigned to you and  you can decide what needs to get done first (or next).",
          { teamwork: 'Teamwork.com' },
        )
      }}
    </p>
    <p class="pt-6">
      {{
        t(
          "Paint me a picture: It's 9 AM. You've got your coffee, you're ready to start working. You're not sure what to start with - you open social media instead of figuring it out.",
        )
      }}
    </p>
    <p class="pt-6">
      {{ t("...just kidding, you confidently go to My Work because it'll show you what you should do first.") }}
    </p>
  </div>
</template>
