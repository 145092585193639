<script setup>
import useVuelidate from '@vuelidate/core';
import { useI18n, useValidators } from '@/util';
import OnboardingWizardCommonStep from '../common/OnboardingWizardCommonStep.vue';
import OnboardingWizardCommonPreview from '../common/preview/OnboardingWizardCommonPreview.vue';
import {
  PREVIEW_TAB_BOARD,
  PREVIEW_TAB_LIST,
  PREVIEW_TAB_TABLE,
  SAMPLE_USER_TASKS_DATA,
  STEP_BOARD_COLUMNS,
  STEP_SELECT_VIEW,
} from '../constants';

const props = defineProps({
  nextButtonText: {
    type: String,
    required: true,
  },
  state: {
    type: Object,
    required: true,
  },
});

/**
 * Triggers sending step result data back to main state and move to next step
 *
 * @param {boolean} hasChanged this step has changed data compared to the history. compare with `stateData` for the step
 * @param {object} stepData step result data
 * @param {object} [stateData] full state data - optional if you edited anything in the full state
 */
const emit = defineEmits(['nextStep']);

/** use stateData to check if there's old values for this state and if they have changed */
const { [STEP_BOARD_COLUMNS]: stateData, [STEP_SELECT_VIEW]: selectViewState } = props.state;

const { t } = useI18n();
const { required, requiredIf } = useValidators();

const inputEl = shallowRef(null);
const column1 = shallowRef(stateData?.columns?.at(0) ?? t('To do'));
const column2 = shallowRef(stateData?.columns?.at(1) ?? t('In progress'));
const column3 = shallowRef(stateData?.columns?.at(2) ?? t('Completed'));

const projectName = selectViewState?.name ?? '';
const tasksData = selectViewState?.tasksList ?? [];

const rules = {
  column1: {
    required,
  },
  column2: {
    requiredIf: requiredIf((value) => value !== ''),
  },
  column3: {
    requiredIf: requiredIf((value) => value !== ''),
  },
};
const v$ = useVuelidate(rules, { column1, column2, column3 }, { $autoDirty: false });

const columns = computed(() => [column1.value, column2.value, column3.value]);

const previewData = computed(() => {
  return {
    ...tasksData,
    boardColumns: SAMPLE_USER_TASKS_DATA.boardColumns.map((column, index) => ({
      ...column,
      name: columns.value[index],
    })),
  };
});

function nextStep() {
  v$.value.$touch();
  if (!v$.value.$error) {
    const numberOfColumnsIntroduced = columns.value.filter((column) => column !== '').length;
    emit('nextStep', columns.value.toString() !== stateData?.columns.toString(), {
      columns,
      columnsPreviewData: previewData.value,
      numberOfColumnsIntroduced,
    });
  }
}

onMounted(() => {
  if (column1.value === '') {
    inputEl.value.focus();
  }
});
</script>

<template>
  <OnboardingWizardCommonStep
    :title="t('Create board columns')"
    :description="t('Use columns to organize and visualize progress. You can change these later.')"
  >
    <div class="flex flex-col gap-2">
      <VTextField
        ref="inputEl"
        v-model="column1"
        :label="t('Board column')"
        :placeholder="t('To do')"
        :errorMessages="v$.column1.$errors.map((error) => error.$message)"
        :maxLength="255"
        :autoFocus="true"
        class="my-2"
        autocomplete="off"
        @blur="column1 = column1.trim()"
      />
      <VTextField
        v-model="column2"
        :label="t('Board column')"
        :placeholder="t('In progress')"
        :errorMessages="v$.column2.$errors.map((error) => error.$message)"
        :maxLength="255"
        :autoFocus="true"
        class="my-2"
        autocomplete="off"
        @blur="column2 = column2.trim()"
      />
      <VTextField
        v-model="column3"
        :label="t('Board column')"
        :placeholder="t('Completed')"
        :errorMessages="v$.column3.$errors.map((error) => error.$message)"
        :maxLength="255"
        :autoFocus="true"
        class="my-2"
        autocomplete="off"
        @blur="column3 = column3.trim()"
      />
    </div>

    <slot name="underFieldsButtons" :nextButtonText="nextButtonText" :nextStep="nextStep" />

    <template #right>
      <OnboardingWizardCommonPreview
        :projectName="projectName"
        :preselectedTab="PREVIEW_TAB_BOARD"
        :tasksData="previewData"
        :tabs="[PREVIEW_TAB_TABLE, PREVIEW_TAB_BOARD, PREVIEW_TAB_LIST]"
        :tabsClickable="false"
      />
    </template>
  </OnboardingWizardCommonStep>
</template>
