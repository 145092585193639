import { useItemLoader } from '../base/useItemLoader';

function responseToItem(response) {
  return response.data;
}

export function useHubspotOauthV2Loader({ active: _active = true } = {}) {
  const active = shallowRef(_active);

  const { state } = useItemLoader({
    responseToItem,
    url: computed(() => active.value && `/synthesis/api/v2/hubspot-workflow/oauth/authed.json`),
  });

  return state;
}
