function LsDialog(persistent: ComputedRef<boolean>) {
  const shouldCloseOnClickOutside = shallowRef(!persistent.value) as ShallowRef<boolean>;
  return {
    shouldCloseOnClickOutside,
    persistent,
  };
}

const symbol = Symbol('useLsDialog') as InjectionKey<ReturnType<typeof LsDialog>>;

export function provideLsDialog(persistent: ComputedRef<boolean>) {
  const lsDialog = LsDialog(persistent);
  provide(symbol, lsDialog);
  return lsDialog;
}

export function useLsDialog() {
  return inject(symbol) as ReturnType<typeof LsDialog>;
}
