<script setup>
import { useI18n } from '@/util';
import RouteSetupQuickView from './RouteSetupQuickView.vue';
import { provideBreadcrumbs } from './useBreadcrumbs';
import { provideRoute } from './useRoute';

const props = defineProps({
  dataIdentifierPrefix: {
    type: String,
    required: true,
  },
});

const { t } = useI18n();

provideBreadcrumbs({
  dataIdentifierPrefix: `${props.dataIdentifierPrefix}-breadcrumbs`,
  fixedBreadcrumbs: [
    {
      label: t('Home'),
      path: '/home',
      dataIdentifier: 'home',
    },
  ],
});
provideRoute();
</script>
<template>
  <RouteSetupQuickView>
    <slot />
  </RouteSetupQuickView>
</template>
