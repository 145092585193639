import { useListLoader } from '../base/useListLoader';
import { useOptimisticUpdates } from '../base/useOptimisticUpdates';
import { useRealTimeUpdates } from '../base/useRealTimeUpdates';

function responseToMeta({ data: { meta } }) {
  return { totalCount: meta.page?.count };
}

function responseToItems(response) {
  const { jobRoles = [], included } = response.data;
  const { users = {} } = included;
  return jobRoles.map((role) => ({
    ...role,
    users:
      role.users?.map((user) => ({
        ...users[user.id],
        entityType: 'user',
      })) ?? [],
  }));
}

function orderAscByName(left, right) {
  return left.name.localeCompare(right.name);
}

function orderDescByName(left, right) {
  return right.name.localeCompare(left.name);
}

export function useJobRolesV3Loader({ params: _params, count: _count, pageSize = 25 }) {
  const params = shallowRef(_params);
  const count = shallowRef(_count);

  const order = computed(() => {
    const { orderMode } = params.value ?? {};

    switch (orderMode) {
      case 'desc':
        return orderDescByName;
      case 'asc':
      default:
        return orderAscByName;
    }
  });

  const { state, refresh, update } = useListLoader({
    type: 'jobRole',
    url: '/projects/api/v3/jobroles.json',
    order,
    count,
    params,
    pageSize,
    responseToMeta,
    responseToItems,
  });

  function handleJobRoleRealTimeUpdate(event) {
    switch (event.action) {
      case 'added':
      case 'deleted':
        refresh();
        break;
      case 'updated':
        refresh();
        // refresh(event.jobRole.id);
        break;
      default:
    }
  }

  function handleJobRoleOptimisticUpdate(event) {
    switch (event.action) {
      case 'create':
        update((jobRoles) => [event.jobRole, ...jobRoles], event.promise);
        break;
      case 'update':
        update(
          (jobRoles) => jobRoles.map((role) => (role.id === event.jobRole.id ? { ...role, ...event.jobRole } : role)),
          event.promise,
        );
        break;
      case 'delete':
        update((jobRoles) => jobRoles.filter((role) => role.id !== event.jobRole.id), event.promise);
        break;
      default:
    }
  }

  useRealTimeUpdates((event) => {
    switch (event.type) {
      case 'jobRole':
        handleJobRoleRealTimeUpdate(event);
        break;
      default:
    }
  });

  useOptimisticUpdates((event) => {
    switch (event.type) {
      case 'jobRole':
        handleJobRoleOptimisticUpdate(event);
        break;
      default:
    }
  });

  return state;
}
