import { useCurrentAccount } from '@/api';

function setFavIcon(url = '/favicon.ico') {
  [...document.head.querySelectorAll('link[rel~=icon]')].forEach((el) => {
    // eslint-disable-next-line no-param-reassign
    el.href = url;
  });
}

export function useFavicon() {
  const account = useCurrentAccount();

  watch(
    account,
    (newAccount) => {
      setFavIcon(newAccount.favIcon);
    },
    { immediate: true },
  );
}
