<script setup>
import useVuelidate from '@vuelidate/core';
import { useCurrentUser } from '@/api';
import { useI18n, useValidators } from '@/util';
import OnboardingWizardCommonStep from '../common/OnboardingWizardCommonStep.vue';
import OnboardingWizardCommonPreview from '../common/preview/OnboardingWizardCommonPreview.vue';
import {
  PREVIEW_TAB_BOARD,
  PREVIEW_TAB_LIST,
  PREVIEW_TAB_PEOPLE,
  PREVIEW_TAB_TABLE,
  STEP_ADD_CLIENT,
  STEP_INVITE_PEOPLE,
  STEP_SELECT_VIEW,
} from '../constants';

const props = defineProps({
  nextButtonText: {
    type: String,
    required: true,
  },
  state: {
    type: Object,
    required: true,
  },
  showSkipButton: {
    type: Boolean,
    default: false,
  },
});

/**
 * Triggers sending step result data back to main state and move to next step
 *
 * @param {boolean} hasChanged this step has changed data compared to the history. compare with `stateData` for the step
 * @param {object} stepData step result data
 * @param {object} [stateData] full state data - optional if you edited anything in the full state
 */
const emit = defineEmits(['nextStep']);

/** use stateData to check if there's old values for this state and if they have changed */
const {
  [STEP_INVITE_PEOPLE]: stateData,
  [STEP_SELECT_VIEW]: selectViewState,
  [STEP_ADD_CLIENT]: addClientState,
} = props.state;

const { t } = useI18n();
const { email, requiredIf } = useValidators();
const currentUser = useCurrentUser();

const currentUserName = {
  name: t('{firstName} {lastName} (me)', {
    firstName: currentUser.value.firstName,
    lastName: currentUser.value.lastName,
  }),
};

const inputEl = shallowRef(null);

const projectName = selectViewState?.name ?? '';

const email1 = shallowRef(stateData?.emails?.at(0) ?? '');
const email2 = shallowRef(stateData?.emails?.at(1) ?? '');
const email3 = shallowRef(stateData?.emails?.at(2) ?? '');
const email4 = shallowRef(stateData?.emails?.at(3) ?? '');
const email5 = shallowRef(stateData?.emails?.at(4) ?? '');

const rules = {
  email1: {
    email,
    requiredIf: requiredIf((value) => value !== ''),
  },
  email2: {
    email,
    requiredIf: requiredIf((value) => value !== ''),
  },
  email3: {
    email,
    requiredIf: requiredIf((value) => value !== ''),
  },
  email4: {
    email,
    requiredIf: requiredIf((value) => value !== ''),
  },
  email5: {
    email,
    requiredIf: requiredIf((value) => value !== ''),
  },
};
const v$ = useVuelidate(rules, { email1, email2, email3, email4, email5 }, { $autoDirty: false });

const emails = computed(() => [email1.value, email2.value, email3.value, email4.value, email5.value]);

const emailsData = computed(() =>
  emails.value.filter(Boolean).length
    ? [currentUserName, ...emails.value.map((e) => ({ name: e })), ...[{}, {}, {}, {}]]
    : [currentUserName, {}, {}, {}, {}, {}, {}, {}, {}, {}],
);

function nextStep(skipped = false) {
  v$.value.$touch();
  if (!v$.value.$error) {
    const numberOfEmailsIntroduced = emails.value.filter((eml) => eml !== '').length;
    emit('nextStep', emails.value.toString() !== stateData?.emails.toString(), {
      emails,
      numberOfEmailsIntroduced,
      skipped,
    });
  }
}

function skipStep() {
  [email1, email2, email3, email4, email5].forEach((e) => {
    e.value = '';
  });
  nextStep(true);
}

onMounted(() => {
  if (email1.value === '') {
    inputEl.value.focus();
  }
});
</script>

<template>
  <OnboardingWizardCommonStep
    :title="t('Invite your team')"
    :description="t('Invite your teammates to your first project.')"
  >
    <div class="flex flex-col gap-2">
      <VTextField
        ref="inputEl"
        v-model="email1"
        :label="t('Add email')"
        :placeholder="t('e.g. billy@example.com')"
        :errorMessages="v$.email1.$errors.map((error) => error.$message)"
        :maxLength="255"
        :autoFocus="true"
        class="my-2"
        autocomplete="off"
        @blur="email1 = email1.trim()"
      />
      <VTextField
        v-model="email2"
        :label="t('Add email')"
        :placeholder="t('e.g. billy@example.com')"
        :errorMessages="v$.email2.$errors.map((error) => error.$message)"
        :maxLength="255"
        :autoFocus="true"
        class="my-2"
        autocomplete="off"
        @blur="email2 = email2.trim()"
      />
      <VTextField
        v-model="email3"
        :label="t('Add email')"
        :placeholder="t('e.g. billy@example.com')"
        :errorMessages="v$.email3.$errors.map((error) => error.$message)"
        :maxLength="255"
        :autoFocus="true"
        class="my-2"
        autocomplete="off"
        @blur="email3 = email3.trim()"
      />
      <VTextField
        v-model="email4"
        :label="t('Add email')"
        :placeholder="t('e.g. billy@example.com')"
        :errorMessages="v$.email4.$errors.map((error) => error.$message)"
        :maxLength="255"
        :autoFocus="true"
        class="my-2"
        autocomplete="off"
        @blur="email4 = email4.trim()"
      />
      <VTextField
        v-model="email5"
        :label="t('Add email')"
        :placeholder="t('e.g. billy@example.com')"
        :errorMessages="v$.email5.$errors.map((error) => error.$message)"
        :maxLength="255"
        :autoFocus="true"
        class="my-2"
        autocomplete="off"
        @blur="email5 = email5.trim()"
      />
    </div>

    <slot
      name="underFieldsButtons"
      :nextButtonText="nextButtonText"
      :nextStep="nextStep"
      :showSkipButton="showSkipButton"
      :skipButtonText="t('Skip')"
      :skipStep="skipStep"
    />

    <template #right>
      <OnboardingWizardCommonPreview
        :projectName="projectName"
        :clientCompanyName="addClientState?.name"
        :peopleData="emailsData"
        :preselectedTab="PREVIEW_TAB_PEOPLE"
        :tabs="[PREVIEW_TAB_TABLE, PREVIEW_TAB_BOARD, PREVIEW_TAB_LIST, PREVIEW_TAB_PEOPLE]"
        :tabsClickable="false"
      />
    </template>
  </OnboardingWizardCommonStep>
</template>
