/**
 * Improves the core Vue plugin support by making all subsequent plugins:
 * - run in a global effect scope, which is cleaned up when `app.unmount` is called.
 * - run within `app.runWithContext`, so that the plugins could use the `inject` function.
 * @param {import('vue').App<Element>} app
 */
export function enhancedPluginsPlugin(app) {
  const { unmount, use } = app;
  const scope = effectScope(true);

  // eslint-disable-next-line no-param-reassign
  app.use = (plugin, options) => {
    return use(() => {
      scope.run(() => {
        app.runWithContext(() => {
          if (typeof plugin === 'function') {
            plugin(app, options);
          } else if (plugin && typeof plugin.install === 'function') {
            plugin.install(app, options);
          } else {
            // eslint-disable-next-line no-console
            console.warn('Invalid Vue plugin', plugin);
          }
        });
      });
    });
  };

  // eslint-disable-next-line no-param-reassign
  app.unmount = () => {
    unmount();
    scope.stop();
  };
}
