import { useItemLoader } from '../base/useItemLoader';
import { useRealTimeUpdates } from '../base/useRealTimeUpdates';

function responseToItem(response) {
  return response.data.featureOrder;
}

export function useProjectFeatureOrderLoader({ projectId: _projectId } = {}) {
  const projectId = shallowRef(_projectId);
  const url = computed(() => projectId.value && `/projects/api/v3/projects/${projectId.value}/featureorder.json`);
  const { state, refresh } = useItemLoader({ url, responseToItem });

  useRealTimeUpdates((event) => {
    if (event.type === 'project') {
      refresh();
    }
  });

  return state;
}
