import { paragon } from '@useparagon/connect';
import { useCurrentProjectPermissions } from '@/route';
import { useCurrentAccount } from '../account/useCurrentAccount.js';
import { useFeatures } from '../features/useFeatures.js';
import { useIntegrationParagonAuthV1Loader } from './useIntegrationParagonAuthV1Loader.js';

const symbol = Symbol('useParagon');

function IntegrationParagonAuth() {
  // Do not load the Paragon auth until it's requested for the first time.
  const active = shallowRef(false);
  const account = useCurrentAccount();
  const {
    netSuiteIntegrationEnabled,
    projectsIntegrationsNetSuiteEnabled,
    salesforceIntegrationEnabled,
    projectsIntegrationsSalesforceEnabled,
  } = useFeatures();
  const { isSiteOrProjectAdmin } = useCurrentProjectPermissions();

  const shouldUserTryParagonAuthentication = computed(
    () =>
      ((netSuiteIntegrationEnabled.value && projectsIntegrationsNetSuiteEnabled.value) ||
        (salesforceIntegrationEnabled.value && projectsIntegrationsSalesforceEnabled.value)) &&
      isSiteOrProjectAdmin.value,
  );

  const paragonAuthState = useIntegrationParagonAuthV1Loader({
    active: computed(() => active.value && shouldUserTryParagonAuthentication.value),
  });

  const paragonUser = shallowRef(undefined);
  const isParagonAuthenticateLoading = shallowRef(false);

  watch(
    [paragonAuthState.item, paragonAuthState.inSync],
    async ([authData, inSync]) => {
      if (authData && inSync) {
        try {
          if (['eu', 'eu-west-1'].includes(account.value.awsRegion.toLowerCase())) {
            paragon.configureGlobal({
              host: 'eu.paragon.so',
            });
          }

          isParagonAuthenticateLoading.value = true;
          await paragon.authenticate(authData.paragonProjectID, authData.jwt);
          paragonUser.value = paragon.getUser();
          // Hack to stop extra sdk/me call using a cached token (remove when they implement the option to skip this)
          localStorage.removeItem('paragon-connect-user-state');
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error('Error authenticating user in Paragon: ', error.message);
        } finally {
          isParagonAuthenticateLoading.value = false;
        }
      }
    },
    { immediate: true },
  );

  return {
    active,
    isParagonAuthenticateLoading,
    paragonUser,
  };
}

export function provideIntegrationParagonAuth() {
  const p = IntegrationParagonAuth();
  provide(symbol, p);
  return p;
}

export function useIntegrationParagonAuth() {
  const { active, ...paragonState } = inject(symbol);
  active.value = true;
  return { ...paragonState };
}
