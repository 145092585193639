<script setup>
import { useCurrentUser, useExperimentA2301 } from '@/api';
import { capitalizeFirstLetter, useI18n } from '@/util';
import OnboardingWizardCommonStep from '../common/OnboardingWizardCommonStep.vue';
import OnboardingWizardCommonUserRatesRow from '../common/OnboardingWizardCommonUserRatesRow.vue';
import OnboardingWizardCommonPreview from '../common/preview/OnboardingWizardCommonPreview.vue';
import {
  BILLING_TYPE_RETAINER,
  BUDGET_TYPE_FINANCIAL,
  CURRENCY_OPTIONS,
  PREVIEW_TAB_BOARD,
  PREVIEW_TAB_FINANCE,
  PREVIEW_TAB_LIST,
  PREVIEW_TAB_PEOPLE,
  PREVIEW_TAB_TABLE,
  STEP_BILLING_TYPE,
  STEP_CLIENT_PROJECT,
  STEP_INVITE_PEOPLE,
  STEP_SELECT_VIEW,
  STEP_SET_BUDGET,
  STEP_SET_TEAM_RATES,
} from '../constants';
import { useOnboardingWizardUserRates } from './useOnboardingWizardUserRates';

const props = defineProps({
  state: {
    type: Object,
    required: true,
  },
  nextButtonText: {
    type: String,
    required: true,
  },
  showSkipButton: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['nextStep']);

const {
  [STEP_SET_TEAM_RATES]: stateData,
  [STEP_SELECT_VIEW]: selectViewState,
  [STEP_INVITE_PEOPLE]: invitePeopleStateData,
  [STEP_CLIENT_PROJECT]: clientProjectStateData,
  [STEP_BILLING_TYPE]: billingStateData,
  [STEP_SET_BUDGET]: budgetStateData,
} = props.state;

const currentUser = useCurrentUser();
const { t } = useI18n();
const { trackTooltipHovered } = useExperimentA2301();
const {
  currencyId,
  currencySymbol,
  getPreviewData,
  automaticCostRatesCalculationAlertVisible,
  automaticCostRatesCalculationEnabled,
  automaticCostRatesCalculationAlertDisplayed,
  showAutomaticCostRatesCalculationAlert,
  hasClearValidationStatus,
  defaultRate,
  defaultRateEl,
  showValidationError,
  handleDefaultRateInputBlurred,
  billableRates,
  costRates,
  v$,
} = useOnboardingWizardUserRates(props.state);

const billingType = billingStateData?.type;
const isBillingTypeRetainer = billingType === BILLING_TYPE_RETAINER;
const currencyNotSetInBudgetStep = budgetStateData?.skipped || !budgetStateData?.currencyId;

const people = computed(() => {
  return [currentUser.value.firstName, ...invitePeopleStateData.emails.filter(Boolean)].map((rawPersonDetail) => {
    const personFirstName = capitalizeFirstLetter(rawPersonDetail.split('@')[0].split('.')[0]);
    return {
      initial: personFirstName.at(0),
      firstName: personFirstName,
    };
  });
});

// Copied from TWA implementation:
// We are only taking the rates of the first user that has introduced both billable rate and cost rate
// to make the calculations reflected in the profitability report and multiplying by 10
const firstPersonIndexWithBothRatesSet = computed(() =>
  [...Array(people.value.length).keys()].findIndex((i) => billableRates.value[i] && costRates.value[i]),
);

const previewData = computed(() => {
  return getPreviewData(firstPersonIndexWithBothRatesSet.value);
});

const previewFinanceData = computed(() => {
  return {
    ...previewData.value,
    isBillingTypeRetainer,
    isBudgetTypeFinancial: budgetStateData?.type === BUDGET_TYPE_FINANCIAL,
    currencyId: currencyId.value,
    amount: budgetStateData?.amount,
    addUnspentHours: budgetStateData?.addUnspentHours,
    subtractOverspentHours: budgetStateData?.subtractOverspentHours,
    showProjectProfitabilityInformation: true,
  };
});

function nextStep() {
  v$.value.$touch();
  if (!hasClearValidationStatus.value || v$.value.$error) {
    showValidationError.value = true;
    return;
  }

  const newData = {
    defaultRate: defaultRate.value,
    ...(currencyNotSetInBudgetStep && {
      currencyId: currencyId.value,
    }),
    billableRates: billableRates.value,
    costRates: costRates.value,
    automaticCostRatesCalculationAlertDisplayed: automaticCostRatesCalculationAlertDisplayed.value,
    automaticCostRatesCalculationEnabled: automaticCostRatesCalculationEnabled.value,
  };
  const hasDataChanged = JSON.stringify(stateData) !== JSON.stringify(newData);

  emit('nextStep', hasDataChanged, newData);
}

function skipStep() {
  emit('nextStep', !stateData || !stateData?.skipped, { skipped: true });
}

function handleBillableRateUpdate(index, value) {
  billableRates.value[index] = value;
}

function handleCostRateUpdate(index, value) {
  costRates.value[index] = value;
}

watch(defaultRate, (newDefaultRate) => {
  showAutomaticCostRatesCalculationAlert();

  if (newDefaultRate === '') {
    billableRates.value.fill();
    costRates.value.fill();

    return;
  }

  billableRates.value.fill(Number.isInteger(newDefaultRate) ? newDefaultRate : Number(newDefaultRate.toFixed(2)));

  if (!automaticCostRatesCalculationEnabled.value) {
    return;
  }

  const newCostRate = newDefaultRate * 0.6;
  costRates.value.fill(Number.isInteger(newCostRate) ? newCostRate : Number(newCostRate.toFixed(2)));
});

onMounted(() => {
  if (!defaultRate.value) {
    defaultRateEl.value.focus();
  }
});
</script>
<template>
  <OnboardingWizardCommonStep
    :title="t('Set your team rates')"
    :description="
      t(
        'Adding billable and cost rates allows you to track profitability and progress against financial budgets. You can update these later.',
      )
    "
  >
    <div class="grid grid-cols-3 items-start gap-2">
      <div class="col-start-2 flex items-center gap-1">
        <label class="text-body-1 font-semibold" for="defaultRate">{{ t('Default rate') }}</label>
        <LscIcon
          v-LsdTooltip="
            t(
              '(Optional) Set the default hourly rate you charge your client for this project. This rate can be customized per user. It\'s visible to the site owner and the owner and client company admins.',
            )
          "
          class="cursor-help text-icon-default focus:outline-none"
          size="sm"
          icon="lsi-tooltip"
          @mouseenter="trackTooltipHovered('default_rate', billingType)"
        />
      </div>
      <label v-if="currencyNotSetInBudgetStep" class="text-body-1 font-semibold" for="currencyId">
        {{ t('Currency') }}
      </label>
      <VTextField
        id="defaultRate"
        ref="defaultRateEl"
        v-model.number="defaultRate"
        type="number"
        variant="outlined"
        min="1"
        max="9999999"
        class="col-start-2"
        :label="t('Add rate')"
        :placeholder="t('Not set')"
        :errorMessages="v$.defaultRate.$errors.map((error) => error.$message)"
        @blur="handleDefaultRateInputBlurred"
      >
        <template v-if="typeof defaultRate === 'number'" #prepend-inner>
          <div class="mr-2 text-subtle">
            {{ currencySymbol }}
          </div>
        </template>
      </VTextField>
      <VSelect
        v-if="currencyNotSetInBudgetStep"
        id="currencyId"
        v-model="currencyId"
        itemValue="id"
        itemTitle="code"
        :items="CURRENCY_OPTIONS"
        :menuProps="{ width: 'auto' }"
      />

      <div class="relative col-span-3">
        <div class="my-6 border-x border-b" />
        <Transition
          enterFromClass="opacity-0"
          enterToClass="opacity-100"
          enterActiveClass="transition-opacity duration-300"
          leaveFromClass="opacity-100"
          leaveToClass="opacity-0"
          leaveActiveClass="transition-opacity duration-300"
        >
          <LscAlert
            v-if="automaticCostRatesCalculationAlertVisible"
            class="z-10 mb-3 xl:absolute xl:left-full xl:top-12 xl:ml-4 xl:w-72"
            :message="
              t(
                'We have set the cost rate to 60% of the billable rate, you can adjust this rate to reflect your project costs',
              )
            "
          />
        </Transition>
      </div>

      <div class="text-body-1 font-semibold">{{ t('Individual rates') }}</div>
      <div class="flex items-center gap-1">
        <div class="text-body-1 font-semibold">{{ t('Billable rate') }}</div>
        <LscIcon
          v-LsdTooltip="
            t(
              'The hourly rate you charge your client for each user. This rate can be customised per project. It\'s visible to the site owner and owner and client company admins.',
            )
          "
          class="cursor-help text-icon-default focus:outline-none"
          size="sm"
          icon="lsi-tooltip"
          @mouseenter="trackTooltipHovered('billable_rate', billingType)"
        />
      </div>
      <div class="flex items-center gap-1">
        <div class="text-body-1 font-semibold">{{ t('Cost rate') }}</div>
        <LscIcon
          v-LsdTooltip="
            t(
              'The hourly cost to your company to employ this user. This is visible to the site owner and owner company admins.',
            )
          "
          class="cursor-help text-icon-default focus:outline-none"
          size="sm"
          icon="lsi-tooltip"
          @mouseenter="trackTooltipHovered('cost_rate', billingType)"
        />
      </div>
    </div>
    <div class="max-h-56 w-full overflow-auto pr-2">
      <OnboardingWizardCommonUserRatesRow
        v-for="(person, personIndex) of people"
        :key="personIndex"
        :personIndex="personIndex"
        :projectUser="person"
        :currencySymbol="currencySymbol"
        :billableRate="billableRates[personIndex]"
        :costRate="costRates[personIndex]"
        :showAutomaticCostRatesCalculationAlert="showAutomaticCostRatesCalculationAlert"
        :billingType="billingType"
        :showValidationError="showValidationError"
        @update:billableRate="handleBillableRateUpdate(personIndex, $event)"
        @update:costRate="handleCostRateUpdate(personIndex, $event)"
        @update:automaticCostRatesCalculationEnabled="automaticCostRatesCalculationEnabled = $event"
      />
    </div>
    <slot
      name="underFieldsButtons"
      :nextButtonText="nextButtonText"
      :nextStep="nextStep"
      :showSkipButton="showSkipButton"
      :skipButtonText="t('Skip')"
      :skipStep="skipStep"
    />
    <template #right>
      <OnboardingWizardCommonPreview
        :projectName="selectViewState.name"
        :clientCompanyName="clientProjectStateData.name"
        :preselectedTab="PREVIEW_TAB_FINANCE"
        :tabs="[PREVIEW_TAB_TABLE, PREVIEW_TAB_BOARD, PREVIEW_TAB_LIST, PREVIEW_TAB_PEOPLE, PREVIEW_TAB_FINANCE]"
        :tabsClickable="false"
        :financeData="previewFinanceData"
      />
    </template>
  </OnboardingWizardCommonStep>
</template>
