<script setup>
import { useCurrentUser, usePeopleV3Loader } from '@/api';
import { useI18n } from '@/util';

const props = defineProps({
  report: {
    type: Object,
    default: () => ({}),
  },
  /**
   * The size of the avatar.
   * @default 'sm'
   * @type {PropType<LscAvatarSize>}
   */
  size: {
    type: String,
    default: 'sm',
  },
  trackPendoConfig: {
    type: Object,
    default: () => ({}),
  },
  max: {
    type: Number,
    default: 5,
  },
});

const emit = defineEmits(['handleCustomReportTrack', 'update']);

const { t } = useI18n();
const user = useCurrentUser();

const currentReport = computed(() => props.report);
const previousUsers = shallowRef([...(currentReport.value.users || [])]);
const numOfUsersAdded = shallowRef(0);

const canEditViewers = computed(() => {
  return (
    user.value.administrator ||
    user.value.permissions.isAdminOnAProject ||
    !currentReport.value.createdByUser?.id ||
    user.value.id === currentReport.value.createdByUser?.id
  );
});

const { items: admins } = usePeopleV3Loader({
  count: Infinity,
  pageSize: 50,
  params: { adminsOnly: true },
});

const excludeUserIds = computed(() =>
  [currentReport.value.createdByUser?.id, user.value.id, ...admins.value.map((admin) => admin.id)].filter(Boolean),
);

const reportViewers = computed({
  get: () => currentReport.value.users?.filter((u) => !excludeUserIds.value.includes(u.id)) || [],
  set: (newUsers) => {
    currentReport.value.users = newUsers;
    emit('update', newUsers);
  },
});
watchEffect(() => {
  currentReport.value.users = props.report.users;
});

function handleCustomReportTrack(pageType, eventAction, eventCategory, ...args) {
  emit('handleCustomReportTrack', pageType, eventAction, eventCategory, ...args);
}

watch(
  () => currentReport.value.users,
  (newUsers, oldUsers) => {
    if (newUsers?.length > oldUsers?.length) {
      numOfUsersAdded.value += newUsers.length - oldUsers.length;
    } else if (newUsers?.length < oldUsers?.length) {
      numOfUsersAdded.value = 0;
    }
    previousUsers.value = [...(newUsers || [])];
  },
  { deep: true, immediate: true },
);

function update() {
  if (numOfUsersAdded.value > 0 && props.trackPendoConfig.usersAdded) {
    handleCustomReportTrack(...props.trackPendoConfig.usersAdded, numOfUsersAdded.value);
    numOfUsersAdded.value = 0;
  }

  emit('update');
}
</script>

<template>
  <div class="flex items-center gap-1 text-subtle">
    <LswAssigneePicker
      v-model:assignees="reportViewers"
      :avatarSize="size"
      :showAnyone="false"
      :excludeUserIds="excludeUserIds"
      :editable="canEditViewers"
      includeProjectTeams
      includeSubteams
      includeCompanyTeams
      @afterLeave="update"
    >
      <template #activator="activator">
        <LscButton
          v-bind="activator.props"
          variant="plain-primary"
          @click="trackPendoConfig.addUsersViewed && handleCustomReportTrack(...trackPendoConfig.addUsersViewed)"
        >
          <LswAssigneePickerActivator
            :avatars="activator.avatars"
            :label="canEditViewers && t('Add user or team')"
            :blankTooltip="canEditViewers ? t('Add user or team') : t('You don\'t have rights to add user or team')"
            :size="size"
            :max="max"
          />
          <LscIconButton
            v-if="reportViewers.length && canEditViewers"
            v-LsdTooltip="t('Add user or team')"
            :ariaLabel="t('Add user or team')"
            icon="lsi-add"
            :size="size"
          />
        </LscButton>
      </template>
    </LswAssigneePicker>
    <span class="ml-auto">{{ t('Can view') }}</span>
  </div>
</template>
