<script setup>
import { useI18n } from '@/util';

const { t } = useI18n();
</script>
<template>
  <div class="text-body-1">
    <p>
      {{
        t(
          'Columns only show tasks that have been added to it. Before you add tasks to a column on the view, they are considered backlogged.',
        )
      }}
    </p>
    <p class="pt-6">
      {{ t('Access these tasks by selecting "Show backlog" in the top left corner.') }}
    </p>
  </div>
</template>
