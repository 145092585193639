export const TimelogDialogOpenSources = /** @type {const} */ ([
  'quick_add_menu',
  'timesheet_sheet_view_header',
  'timesheet_calendar_view_header',
  'timesheet_calendar',
  'timesheet_task_planner',
  'calendar',
  'command_center',
  'project_invoice',
  'project_budget',
  'project_time',
]);
