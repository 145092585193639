<script setup>
import { useI18n } from '@/util';

const { t } = useI18n();
</script>
<template>
  <div class="text-body-1">
    <p>
      {{ t('You can track your time in My work, on the Time page or directly on tasks. But... why should you?') }}
    </p>
    <p class="pt-6">
      {{ t('Why do you need to track time?') }}
    </p>
    <ul class="my-4 ml-4 list-disc">
      <li>{{ t('The time you spend on a task or project is used to bill clients.') }}</li>
      <li>{{ t('Your time logs show where time is being spent. (Better project planning anyone?)') }}</li>
      <li>{{ t('To better understand your workload and plan your upcoming work.') }}</li>
    </ul>
    <p class="pt-6">
      {{ t('There’s more, but we’re running out of room. Let’s see how we can track our time.') }}
    </p>
    <p class="pt-6">
      {{ t('Trust us, it’s easy.') }}
    </p>
  </div>
</template>
