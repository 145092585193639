export function formatKeyboardShortcut(shortcut) {
  const modifierMap = {
    Ctrl: '\u2303',
    Shift: '\u21E7',
    Alt: '\u2325',
    Cmd: '\u2318',
    Win: '\u229E',
    Up: '\u2191',
    Down: '\u2193',
    Enter: '\u23CE',
  };

  return shortcut.replace(/\b(Ctrl|Shift|Alt|Cmd|Win|Up|Down|Enter)\b/g, (match) => modifierMap[match]);
}
