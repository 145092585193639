<script setup>
import { useEnabledApps, usePermissions } from '@/api';
import { useI18n } from '@/util';
import { useLegacyBridge } from '@/module/legacy';
import { useAppShellSidebar } from './useAppShellSidebar';

const { t } = useI18n();
const { isSidebarExpanded } = useAppShellSidebar();
const { isOwnerAdmin } = usePermissions();
const { showLegacyModal } = useLegacyBridge();
const { isChatAvailable, isCrmAvailable, isDeskAvailable, isSpacesAvailable } = useEnabledApps();

const otherAppsItems = computed(() =>
  [
    {
      appCode: 'chat',
      icon: 'lsi-app-chat',
      exact: true,
      text: 'Teamwork Chat',
      permission: isChatAvailable,
    },
    {
      appCode: 'spaces',
      icon: 'lsi-app-spaces',
      exact: false,
      text: 'Teamwork Spaces',
      permission: isSpacesAvailable,
    },
    {
      appCode: 'desk',
      icon: 'lsi-app-desk',
      exact: false,
      text: 'Teamwork Desk',
      permission: isDeskAvailable,
    },
    {
      appCode: 'crm',
      icon: 'lsi-app-crm',
      exact: false,
      text: 'Teamwork CRM',
      permission: isCrmAvailable,
    },
  ].filter((app) => app.permission.value),
);

function showManageAppsModal() {
  showLegacyModal({
    modalName: 'manageApps',
  });
}
</script>

<template>
  <WidgetOptionsMenu location="top">
    <template #activator="activator">
      <slot name="activator" v-bind="activator" />
    </template>

    <WidgetOptionsMenuItem
      v-for="item in otherAppsItems"
      :key="item.name"
      v-LsdTooltip="isSidebarExpanded ? undefined : item.text"
      :href="`/${item.appCode}`"
      tabindex="0"
      target="_blank"
      link
      class="focus-visible:bg-selected"
      density="compact"
    >
      <template #prepend>
        <LscIcon :icon="item.icon" />
      </template>
      <VListItemTitle>{{ item.text }}</VListItemTitle>
    </WidgetOptionsMenuItem>
    <template v-if="isOwnerAdmin">
      <VDivider v-if="otherAppsItems.length" class="my-2" />
      <VListItem link density="compact" tabindex="0" class="focus-visible:bg-selected" @click="showManageAppsModal">
        <template #prepend>
          <LscIcon icon="lsi-settings" />
        </template>
        <VListItemTitle>{{ t('Manage apps') }}</VListItemTitle>
      </VListItem>
    </template>
  </WidgetOptionsMenu>
</template>
