import { useRouter } from 'vue-router';
import { useRoute } from './useRoute';

const symbol = Symbol('useTheaterMode');

// The supported values of `currentRoute.meta.theaterMode`.
const MODE = {
  SUPPORTED: 'supported',
  ALWAYS: 'always',
  NEVER: 'never',
};

function TheaterMode() {
  const router = useRouter();
  const currentRoute = useRoute();

  const canToggleTheaterMode = computed(() => ![MODE.NEVER, MODE.ALWAYS].includes(currentRoute.meta.theaterMode));

  /**
   * Readable/writable computed ref that honors
   * the type constrains defined by `currentRoute.meta.theaterMode`.
   *
   * @see {@link https://teamwork.dev/platform/theater-mode Theater mode}
   * */
  const isTheaterModeEnabled = computed({
    get: () => {
      if (currentRoute.meta.theaterMode === MODE.NEVER) {
        return false;
      }

      if (currentRoute.meta.theaterMode === MODE.ALWAYS) {
        return true;
      }

      return ['true', '1'].includes(currentRoute.query.theater);
    },
    set: (newValue) => {
      if (typeof newValue !== 'boolean') {
        // eslint-disable-next-line no-console
        console.warn('useTheaterMode: isTheaterModeEnabled must be a boolean');
        return;
      }

      if (!canToggleTheaterMode.value) {
        // eslint-disable-next-line no-console
        console.warn(`useTheaterMode: isTheaterModeEnabled cannot be set on the current route.`);
        return;
      }

      // the theater mode param is persisted across routes so we cannot undefine it.
      router.replace({ query: { ...currentRoute.query, theater: newValue } });
    },
  });

  return {
    isTheaterModeEnabled,
    canToggleTheaterMode,
  };
}

/**
 * @type {TheaterMode}
 */
export function provideTheaterMode() {
  const theaterMode = TheaterMode();
  provide(symbol, theaterMode);
  return theaterMode;
}

/**
 * @type {TheaterMode}
 */
export function useTheaterMode() {
  return inject(symbol);
}
