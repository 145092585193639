<script setup>
import { useExperimentA41 } from '@/api';
import { useI18n } from '@/util';
import OnboardingWizardCommonChoice from '../common/OnboardingWizardCommonChoice.vue';
import OnboardingWizardCommonStep from '../common/OnboardingWizardCommonStep.vue';
import { STEP_ADD_INTEGRATIONS } from '../constants';
import { useOnboardingWizardTracking } from '../useOnboardingWizardTracking';
import { useOnboardingWizardIntegrations } from './useOnboardingWizardIntegrations';

const props = defineProps({
  nextButtonText: {
    type: String,
    required: true,
  },
  state: {
    type: Object,
    required: true,
  },
  showSkipButton: {
    type: Boolean,
    default: false,
  },
});

/**
 * Triggers sending step result data back to main state and move to next step
 *
 * @param {boolean} hasChanged this step has changed data compared to the history. compare with `stateData` for the step
 * @param {object} stepData step result data
 * @param {object} [stateData] full state data - optional if you edited anything in the full state
 */
const emit = defineEmits(['nextStep']);

/** use stateData to check if there's old values for this state and if they have changed */
const { [STEP_ADD_INTEGRATIONS]: stateData } = props.state;

const { t } = useI18n();
const { trackIntegrationClick } = useExperimentA41();
const { availableIntegrations } = useOnboardingWizardIntegrations();
const { previousPage } = useOnboardingWizardTracking();

const selectedIntegrations = shallowRef(stateData?.selectedIntegrations || []);

function hasChanged() {
  return selectedIntegrations.value.toString() !== stateData?.selectedIntegrations.toString();
}

function nextStep(skipped = false) {
  emit('nextStep', hasChanged(), {
    selectedIntegrations,
    skipped,
  });
}

function skipStep() {
  selectedIntegrations.value = [];
  nextStep(true);
}
</script>
<template>
  <OnboardingWizardCommonStep
    :title="t('Integrate {teamwork} with the tools you love', { teamwork: 'Teamwork.com' })"
    :description="
      t('Select the tools you\'d like to connect with {teamwork} and easily sync them once in your account.', {
        teamwork: 'Teamwork.com',
      })
    "
  >
    <LscChoiceList v-model="selectedIntegrations" :multiple="true" :horizontal="true" class="flex-wrap gap-4">
      <OnboardingWizardCommonChoice
        v-for="integration in availableIntegrations"
        :key="integration.id"
        :label="integration.label"
        :value="integration.id"
        hasCheck
        class="max-w-64 basis-1/4"
        @click.once="trackIntegrationClick(integration.id, previousPage)"
      >
        <template #icon>
          <div class="m-0 flex size-12 shrink-0 items-center justify-center p-0">
            <component :is="integration.icon" class="w-full" />
          </div>
        </template>
      </OnboardingWizardCommonChoice>
    </LscChoiceList>

    <slot
      name="underFieldsButtons"
      :nextButtonText="nextButtonText"
      :nextStep="nextStep"
      :showSkipButton="showSkipButton"
      :skipButtonText="t('Skip')"
      :skipStep="skipStep"
    />
  </OnboardingWizardCommonStep>
</template>
