<script setup>
import { unrefElement } from '@vueuse/core';
import { getVNodeTextContent } from '@/util';
import { useOverflowEllipsis } from './useOverflowEllipsis';

defineProps({
  /**
   * The HTML tag to use for the text.
   * Must return a single DOM node.
   * @type {PropType<string>}
   */
  is: {
    type: String,
    default: 'span',
  },
  /**
   * The location of the tooltip.
   * @type {PropType<'top'|'right'|'bottom'|'left'>}
   */
  location: {
    type: String,
    default: 'top',
  },
});

const textRef = shallowRef();
const textElement = computed(() => unrefElement(textRef));
const isOverflowing = useOverflowEllipsis(textElement);
const slots = useSlots();

const tooltip = computed(() => {
  if (!isOverflowing.value) {
    return '';
  }
  return getVNodeTextContent(slots.default?.({}));
});
</script>

<template>
  <Component :is="is" ref="textRef" v-LsdTooltip:[location]="tooltip" class="truncate">
    <slot />
  </Component>
</template>
