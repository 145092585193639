<script setup>
import { useI18n } from '@/util';

const { t } = useI18n();
</script>
<template>
  <div class="text-body-1">
    <p>
      {{
        t(
          'Starting your day by opening Home > My Work and checking if there are any "Late" tasks. If there are - start working on those, or update task dates if they don\'t need to be worked on today.',
        )
      }}
    </p>
    <p class="pt-6">
      {{
        t(
          'Next, start working on tasks due "Today". At the end of your day, check on what\'s "Upcoming" to make sure there aren\'t any surprises.',
        )
      }}
    </p>
    <p class="pt-6">
      {{ `${t('Reminder')}: ${t('My work only shows tasks assigned to you.')}` }}
    </p>
  </div>
</template>
