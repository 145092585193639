<script setup>
import { useI18n } from '@/util';

const { t } = useI18n();
</script>
<template>
  <div class="text-body-1">
    <p>
      {{
        t(
          'On the All Time page you can see all of your logged time and the entry details. You can log more time using the +Log time button or edit an existing entry by hovering over the entry and clicking the pencil icon.',
        )
      }}
    </p>
  </div>
</template>
