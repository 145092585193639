const symbol = Symbol('tourDataIdentifier');

export function tourDataIdentifierPlugin(app) {
  const tourDataIdentifier = shallowRef('');
  const tourFeatureName = shallowRef('');

  app.provide(symbol, { tourDataIdentifier, tourFeatureName });
}

/**
 * Controls the visibility of the tour button in the app shell.
 * This data identifier can be used to trigger a Pendo guide.
 * @param {MaybeRef<String>} dataIdentifier
 */
export function useTourDataIdentifier(_tourData) {
  const { tourDataIdentifier, tourFeatureName } = inject(symbol);
  const dataIdentifier = toRef(_tourData?.dataIdentifier);
  const featureName = toRef(_tourData?.featureName);

  onMounted(() => {
    if (dataIdentifier.value) {
      tourDataIdentifier.value = dataIdentifier.value ?? '';
    }
    if (featureName.value) {
      tourFeatureName.value = featureName.value ?? '';
    }
  });

  watch(dataIdentifier, (value) => {
    tourDataIdentifier.value = value ?? '';
  });

  watch(featureName, (value) => {
    tourFeatureName.value = value ?? '';
  });

  onUnmounted(() => {
    if (dataIdentifier.value) {
      tourDataIdentifier.value = '';
    }
    if (featureName.value) {
      tourFeatureName.value = '';
    }
  });

  return {
    tourDataIdentifier,
    tourFeatureName,
  };
}
