import { useCurrentUser, useSavedfilterActions } from '@/api';
import { deepEqual, useLocalStorage } from '@/util';
import { apiParamsToFilterParams, filterParamsToApiParams } from './filterUtil';

// Params formats:
// - Saved Filters format:
//   - Example: `{ customFields: '[{"fieldId":5,"operator":"eq","value":"hello"}]' }`
//   - Used within `@/module/filter`.
// - API format:
//   - Example: `{ 'customField[5][eq]': 'hello' }`
//   - Used outside `@/module/filter`.

// Params storage:
// - `apiParams`
//    - API format
//    - immutable
//    - the single source of truth outside `@/module/filter`
//    - synchronized bidrectionally with `activeFilter.parameters`
// - `activeFilter`
//    - Saved Filters format
//    - mutable
//    - the single source of truth within `@/module/filter`
//    - synchronized bidrectionally with `apiParams`
//    - when `activeFilter` is replaced, the params are normalized
//    - initialized from the default saved filter or `localStorage`
//    - saved to `localStorage` on change
// - `localFilterParams`
//    - Saved Filters format
//    - provides persistence for `activeFilter` based on `localStorage`
// - Saved Filters API
//    - Saved Filters format
//    - provides persistence for `activeFilter` based on the Saved Filters API

// Params configuration:
// - Child filter components define how each param should be handled using:
//   - `useFilterNormalize` for param normalization
//   - `useFilterClear` for param default values
//   - `useFilterChips` for chip items
//   - `useFilterCount` for the filter count

// Params normalization:
// - filter item components register normalization functions using `useFilterNormalize`
// - normalization functions are called on assignment to `activeFilter.value`, that is:
//   - when initializing the filter from the default saved filter or `localStorage`
//   - when `apiParams` change
//   - when a saved filter is created or selected
//   - when the filter is cleared
// - filter params which have a normalization function are normalized and others are removed

const symbol = Symbol('useFilter');
const privateSymbol = Symbol('useFilter/private');

/**
 * @callback NormalizeFunction
 * @param {any} value The value of the GET parameter.
 * @param {string} key The name of the GET parameter.
 * @param {Record<string, any>} params All GET parameters.
 */

export function provideFilter({ apiParams, section, projectId, dataIdentifierPrefix }) {
  const { clearDefaultSavedfilter } = useSavedfilterActions();
  const user = useCurrentUser();
  const localFilterParams = useLocalStorage(
    computed(() => `teamwork/useFilter/${section.value}/${projectId.value ?? 0}`),
    computed(() => apiParamsToFilterParams(apiParams.value)),
    { mergeDefaults: true },
  );

  let apiParamsLastValue;

  const chipList = shallowRef([]);
  const counts = shallowRef([]);
  /** @type {NormalizeFunction[]} */
  const normalizeFunctions = [];
  const defaultValues = [];

  // eslint-disable-next-line lightspeed/prefer-shallow-ref
  const activeFilterPrivate = ref(createFilter());
  const activeFilter = computed({
    get: () => activeFilterPrivate.value,
    set: (activeFilterNew) => {
      // Create a deep copy to avoid unexpected side effects because `activeFilter.value` is a mutable object.
      const activeFilterNormalized = JSON.parse(JSON.stringify(activeFilterNew));

      // Normalize the filter params.
      const newParams = {};
      Object.keys(activeFilterNormalized.parameters).forEach((paramName) => {
        const normalizeFunction = normalizeFunctions.find(({ name }) =>
          typeof name.value === 'string' ? name.value === paramName : name.value.test?.(paramName),
        )?.normalize;
        if (normalizeFunction) {
          const value = activeFilterNormalized.parameters[paramName];
          const newValue = normalizeFunction(value, paramName, activeFilterNormalized.parameters);
          newParams[paramName] = newValue;
        }
      });
      activeFilterNormalized.parameters = newParams;

      activeFilterPrivate.value = activeFilterNormalized;
    },
  });

  // Synchronize `activeFilter.parameters` with `localFilterParams` and `apiParams`.
  watch(
    () => activeFilter.value.parameters,
    () => {
      localFilterParams.value = activeFilter.value.parameters;
      apiParamsLastValue = filterParamsToApiParams(activeFilter.value.parameters);
      // eslint-disable-next-line no-param-reassign
      apiParams.value = apiParamsLastValue;
    },
    { deep: true },
  );

  // Synchronize `apiParams` with `activeFilter.parameters`.
  watch(apiParams, () => {
    if (!deepEqual(apiParams.value, apiParamsLastValue)) {
      clear(apiParamsToFilterParams(apiParams.value));
    }
  });

  onMounted(() => {
    const savedDefaultFilter = user.value.defaultFilters?.[section.value];
    if (savedDefaultFilter?.title && savedDefaultFilter?.projectId === projectId.value) {
      // Initialize `activeFilter` from the saved filter.
      activeFilter.value = savedDefaultFilter;
    } else {
      // Initialize `activeFilter` from `localStorage`.
      activeFilter.value = createFilter({ parameters: localFilterParams.value });
    }
  });

  /**
   * Creates a new filter object.
   * @param {Object} [properties] Overrides for the default filter properties.
   * @returns {Object} A new filter object.
   */
  function createFilter(properties) {
    return {
      id: undefined,
      title: '',
      description: '',
      color: '',
      isTemporary: true,
      isProjectSpecific: false,
      includesSort: false,
      displayOrder: 0,
      section: section.value,
      parameters: {},
      ...properties,
    };
  }

  /**
   * Clears `activeFilter`.
   * @param {Object} filterParams Allows overriding the default filter params when clearing the filter.
   */
  function clear(filterParams = {}) {
    const parameters = {};

    // Set the default values provided by the filter components.
    Object.keys(activeFilter.value.parameters).forEach((key) => {
      const item = defaultValues.find(({ name }) =>
        typeof name.value === 'string' ? name.value === key : name.value.test?.(key),
      );
      if (item) {
        parameters[key] = unref(item.defaultValue);
      }
    });

    // Set the default values provided as the function argument.
    Object.keys(filterParams).forEach((key) => {
      parameters[key] = filterParams[key];
    });

    // Set a placeholder to avoid an API error.
    if (Object.keys(parameters).length === 0) {
      parameters.searchTerm = '';
    }

    activeFilter.value = createFilter({ parameters });
    clearDefaultSavedfilter(activeFilter.value);
  }

  provide(symbol, {
    // Providing `params` for backwards compatibility.
    // TODO Remove `params` and update all components to use `activeFilter.parameters` instead.
    params: computed({
      get: () => activeFilter.value.parameters,
      set: (parameters = {}) => {
        activeFilter.value.parameters = parameters;
      },
    }),
    activeFilter,
    dataIdentifierPrefix: computed(() => `${dataIdentifierPrefix.value}-filter`),
    count: computed(() => counts.value.reduce((total, { value }) => total + value, 0)),
    chips: computed(() => chipList.value.flatMap((c) => c.value)),
    clear,
  });
  provide(privateSymbol, { chipList, counts, normalizeFunctions, defaultValues });
}

/**
 * @type {Filter}
 */
export function useFilter() {
  return inject(symbol);
}

export function useFilterCount(count) {
  const { counts } = inject(privateSymbol);
  const countRef = computed(() => unref(count));

  counts.value = counts.value.concat(countRef);

  onUnmounted(() => {
    // eslint-disable-next-line vue/no-ref-as-operand
    counts.value = counts.value.filter((c) => c !== countRef);
  });
}

export function useFilterChips(chips) {
  const { chipList } = inject(privateSymbol);
  const chipsRef = computed(() => unref(chips));

  chipList.value = chipList.value.concat(chipsRef);

  onUnmounted(() => {
    // eslint-disable-next-line vue/no-ref-as-operand
    chipList.value = chipList.value.filter((c) => c !== chipsRef);
  });
}

/**
 * Registers a function to normalize params.
 * @param {MaybeRef<String|RegExpConstructor>} name Matches the name of a GET parameter.
 * @param {NormalizeFunction} normalize Returns a normalized value of a GET parameter.
 */
export function useFilterNormalize(name, normalize) {
  const { normalizeFunctions } = inject(privateSymbol);
  const nameRef = computed(() => unref(name));

  if (typeof nameRef.value !== 'string' && !(nameRef.value instanceof RegExp)) {
    // eslint-disable-next-line no-console
    console.warn(`useFilterNormalize: invalid filter param: ${nameRef.value}`);
  }

  const item = { name: nameRef, normalize };

  normalizeFunctions.push(item);

  onUnmounted(() => {
    const index = normalizeFunctions.indexOf(item);
    normalizeFunctions.splice(index, 1);
  });
}

/**
 * Registers a default value for a param.
 * @param {MaybeRef<String|RegExpConstructor>} name Matches the name of a GET parameter.
 * @param {MaybeRef<any>} defaultValue A default value for the GET parameter.
 */
export function useFilterClear(name, defaultValue) {
  const { defaultValues } = inject(privateSymbol);
  const nameRef = computed(() => unref(name));

  if (typeof nameRef.value !== 'string' && !(nameRef.value instanceof RegExp)) {
    // eslint-disable-next-line no-console
    console.warn(`useFilterClear: invalid filter param: ${nameRef.value}`);
  }

  const item = { name: nameRef, defaultValue };

  defaultValues.push(item);

  onUnmounted(() => {
    const index = defaultValues.indexOf(item);
    defaultValues.splice(index, 1);
  });
}
