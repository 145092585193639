<script setup>
import { useI18n } from '@/util';

const { t } = useI18n();
</script>
<template>
  <div class="text-body-1">
    <p>
      <LscReplace
        :text="
          t(
            '{comments} are for discussions, updates, or questions related to a particular task of piece of work. Essentially, if you\'re talking about work, it should probably be a comment in {teamwork}.',
          )
        "
      >
        <template #teamwork>Teamwork</template>
        <template #comments>
          <strong>{{ t('Comments') }}</strong>
        </template>
      </LscReplace>
    </p>
    <p class="pt-6">
      <LscReplace
        :text="
          t(
            '{mention} people or groups in comments so that they get a notification. You\'ll normally use this type of comment because (in most cases) comments are part of conversations or are required reading.',
          )
        "
      >
        <template #mention>
          <strong>@{{ t('mention') }}</strong>
        </template>
      </LscReplace>
    </p>
  </div>
</template>
