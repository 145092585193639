<script setup>
const SuccessDrawerPanel = defineAsyncComponent(() => import('./SuccessDrawerPanel.vue'));
</script>

<template>
  <LscDrawer scrim>
    <template #activator="activator"><slot name="activator" v-bind="activator" /></template>
    <template #default="{ close }"><SuccessDrawerPanel @close="close" /></template>
  </LscDrawer>
</template>
