<script setup>
import { LscMessageVariants } from './constants';

const props = defineProps({
  /**
   * The variant of the message.
   * @type {PropType<typeof LscMessageVariants[number]>}
   */
  variant: {
    type: String,
    default: 'hint',
    validator: (value) => LscMessageVariants.includes(value),
  },
  /**
   * If you want to attach this message to a specific element, you can provide an ID.
   * On the input element, you can then use `aria-errormessage` to link the message to the input.
   */
  id: {
    type: String,
    default: '',
  },
  message: {
    type: String,
    default: undefined,
  },
  /**
   * The icon to prepend to the message.
   * @type {PropType<LscIconName|undefined>}
   */
  prependIcon: {
    type: String,
    default: undefined,
  },
});

const LscMessageVariantStyleConfig = tv({
  base: 'inline-flex min-h-0 items-center gap-1 text-body-2',
  variants: {
    variant: {
      hint: 'text-subtle',
      warning: 'text-warning',
      success: 'text-success',
      critical: 'text-critical',
    },
  },
});

const variantIconMap = {
  hint: '',
  success: '',
  warning: 'lsi-alert',
  critical: 'lsi-alert',
};

const icon = computed(() => props.prependIcon ?? variantIconMap[props.variant] ?? '');

const classes = computed(() => LscMessageVariantStyleConfig(props));
</script>

<template>
  <div :id="id" role="status" :class="classes">
    <slot name="prepend">
      <LscIcon :icon="icon" size="xs" class="shrink-0" />
    </slot>
    <slot>{{ message }}</slot>
  </div>
</template>
