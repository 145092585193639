import { useItemLoader } from '../base/useItemLoader';

function responseToItem({ data: { account } }) {
  // Handle some inconsistencies between the v1 and v3 endpoints.
  // TODO Remove it once we migrate fully to the v3 endpoint.
  /* eslint-disable no-param-reassign */
  if (account.pricePlanData) {
    account.isPaid ??= account.pricePlanData.isPaid;
    account.pricePlanCode ??= account.pricePlanData.code;
    account.pricePlanData.id ??= account.pricePlanId;
  }
  /* eslint-enable no-param-reassign */
  return account;
}

/**
 * Loads the current account.
 *
 * Please note that this endpoint is not fully ported yet and should not be widely used.
 * The reason for its current inclusion and usage is to support the `/shared` routes
 * within the app
 */
export function useCurrentAccountV3Loader({ params } = {}) {
  const { state } = useItemLoader({
    url: '/projects/api/v3/account.json',
    params,
    responseToItem,
  });

  return state;
}
