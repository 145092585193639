<script setup>
import { useI18n } from '@/util';

const { t } = useI18n();
</script>
<template>
  <div class="text-body-1">
    <p>{{ t('Work your way by connecting the apps your team loves with {teamwork}.', { teamwork: 'Teamwork ' }) }}</p>
    <p class="pt-6">
      {{
        t(
          'Choose from a variety of {teamwork} built and third-party integrations available from your {teamwork} account.',
          { teamwork: 'Teamwork.com' },
        )
      }}
    </p>
  </div>
</template>
