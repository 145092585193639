<script setup>
import { useRealTimeUpdates } from '../base/useRealTimeUpdates';
import { provideTWIMSocket } from '../base/useTWIMSocket';
import { provideLaunchDarkly } from '../features/useLaunchDarkly';
import ApiSetupEmbed3 from './ApiSetupEmbed3.vue';

provideLaunchDarkly();
useRealTimeUpdates().emitFromSocket(provideTWIMSocket());
</script>

<template>
  <ApiSetupEmbed3><slot /></ApiSetupEmbed3>
</template>
