<script setup>
import { Duration } from 'luxon';
import { useI18n } from '@/util';
import TimeTimelogAddOrEditDialog from '../timelog/addOrEdit/TimeTimelogAddOrEditDialog.vue';
import TimeTimerAddOrEditDialog from './addOrEdit/TimeTimerAddOrEditDialog.vue';
import { useTimeTimerTracking } from './useTimeTimerTracking';

const props = defineProps({
  timer: {
    type: Object,
    required: true,
  },
  timeToDisplay: {
    type: String,
    default: null,
  },
  isBeingDragged: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['pauseTimer', 'resumeTimer']);

const { t } = useI18n();
const { trackTimerWidgetAction } = useTimeTimerTracking();

const isActive = computed(() => props.timer?.running ?? false);

const taskName = computed(() => {
  return props.timer.task?.name || '';
});

const projectName = computed(() => {
  return props.timer.project?.name || '';
});

const taskPath = computed(() => (props.timer.task ? `/tasks/${props.timer.taskId}` : ''));
const projectPath = computed(() => (props.timer.project ? `/projects/${props.timer.projectId}/tasks` : ''));

const durationInMinutes = computed(() => {
  if (!props.timer?.duration) {
    return null;
  }

  const { minutes } = Duration.fromObject({ seconds: props.timer?.duration }).shiftTo('minutes').toObject();

  return Math.ceil(minutes);
});

const showTimeTimelogAddOrEditDialog = shallowRef(false);
const showTimeTimerEditDialog = shallowRef(false);

function openTimelogModal() {
  if (props.isBeingDragged) {
    return;
  }
  if (props.timer.running) {
    emit('pauseTimer', props.timer);
  }
  trackTimerWidgetAction('timer_stop_clicked');

  showTimeTimelogAddOrEditDialog.value = true;
}

function resumeOrPauseTimerClick() {
  if (isActive.value) {
    trackTimerWidgetAction('timer_pause_clicked');
    emit('pauseTimer', props.timer);
  } else {
    trackTimerWidgetAction('timer_resume_clicked');
    emit('resumeTimer', props.timer);
  }
}

function editTimer() {
  showTimeTimerEditDialog.value = true;
  trackTimerWidgetAction('timer_edit_clicked');
}
</script>

<template>
  <div v-if="timer" v-bind="$attrs" class="group flex items-center gap-2">
    <div class="flex items-center gap-2">
      <button
        v-LsdTooltip="t('Log time')"
        type="button"
        data-identifier="task-details-start-timer-log-button"
        class="flex size-7 items-center justify-center rounded-full text-icon-on-primary"
        :class="{
          'bg-surface-critical-default': isActive,
          'bg-surface-dark': !isActive,
        }"
        @click="openTimelogModal"
      >
        <LscIcon icon="lsi-timer-stop" size="sm" />
      </button>

      <button
        v-LsdTooltip="isActive ? t('Pause timer') : t('Resume timer')"
        type="button"
        data-identifier="task-details-start-timer-log-button"
        class="flex size-7 items-center justify-center rounded-full text-icon-on-primary"
        :class="{
          'bg-surface-dark': isActive,
          'bg-surface-default': !isActive,
        }"
        @click="resumeOrPauseTimerClick"
      >
        <LscIcon
          :class="isActive ? 'text-icon-on-primary' : 'text-icon-primary-default'"
          :icon="isActive ? 'lsi-timer-pause' : 'lsi-timer-play'"
          size="sm"
        />
      </button>
    </div>

    <div class="ml-3 flex w-40 grow cursor-pointer flex-col items-stretch gap-1" role="button" tabindex="-1">
      <p v-if="projectName" class="text-body-2">
        <RouterLink :to="projectPath" class="text-subtle no-underline hover:underline">
          <LscOverflowEllipsis class="block max-w-full">{{ projectName }}</LscOverflowEllipsis>
        </RouterLink>
      </p>
      <p v-if="taskName" class="text-body-2">
        <RouterLink :to="taskPath" class="text-default no-underline hover:underline">
          <LscOverflowEllipsis class="block max-w-full">{{ taskName }}</LscOverflowEllipsis>
        </RouterLink>
      </p>

      <p class="cursor-default text-body-1 font-bold !leading-none">
        {{ timeToDisplay }}
      </p>
    </div>

    <LscIconButton
      v-LsdTooltip="t('Edit')"
      icon="lsi-edit"
      :ariaLabel="t('Edit')"
      size="sm"
      variant="plain-secondary"
      class="invisible group-hover:visible"
      @click="editTimer"
    />
  </div>
  <TimeTimelogAddOrEditDialog v-model="showTimeTimelogAddOrEditDialog" :timer="timer" :duration="durationInMinutes" />
  <TimeTimerAddOrEditDialog v-model="showTimeTimerEditDialog" :timer="timer" :project="timer.project" />
</template>
