import { DateTime } from 'luxon';
import { useI18n } from '@/util';
import { useAxios } from '../base/useAxios';
import { useOptimisticUpdates } from '../base/useOptimisticUpdates';
import { useRealTimeUpdates } from '../base/useRealTimeUpdates';
import { useCurrentUser } from '../user/useCurrentUser';

const newTimerId = (() => {
  let id = 0;
  return () => {
    id -= 1;
    return id;
  };
})();

function toApiTimer(timer) {
  const timerPayload = {};

  if (Object.hasOwn(timer, 'description')) {
    timerPayload.description = timer.description;
  }

  if (Object.hasOwn(timer, 'isBillable')) {
    timerPayload.isBillable = timer.isBillable;
  }

  if (Object.hasOwn(timer, 'isRunning')) {
    timerPayload.isRunning = timer.isRunning;
  }

  if (Object.hasOwn(timer, 'projectId')) {
    timerPayload.projectId = timer.projectId;
  }

  if (Object.hasOwn(timer, 'taskId')) {
    timerPayload.taskId = timer.taskId;
  }

  return { timer: timerPayload };
}

export function useTimerActions() {
  const api = useAxios();
  const { t } = useI18n();
  const currentUser = useCurrentUser();
  const { emit: _emitOptimisticUpdate } = useOptimisticUpdates();
  const { emit: _emitRealTimeUpdate } = useRealTimeUpdates();

  function emitRealTimeUpdate(action) {
    _emitRealTimeUpdate({
      type: 'timer',
      action,
      userId: currentUser.value.id,
    });
  }

  function emitOptimisticUpdate(promise, action, timer) {
    _emitOptimisticUpdate({
      promise,
      type: 'timer',
      action,
      timer,
    });
  }

  function createTimer(timer) {
    const promise = api
      .post('/projects/api/v3/me/timers.json', { timer }, { errorMessage: t('There was a problem creating the timer') })
      .then((response) => {
        emitRealTimeUpdate('added');
        return response.data.timer;
      });

    emitOptimisticUpdate(promise, 'create', { ...timer, id: newTimerId() });
    return promise;
  }

  function deleteTimer(timer) {
    const promise = api
      .delete(`/projects/api/v3/me/timers/${timer.id}.json`, {
        errorMessage: t('There was a problem deleting the timer'),
      })
      .then(() => {
        emitRealTimeUpdate('deleted');
      });
    emitOptimisticUpdate(promise, 'delete', timer);
    return promise;
  }

  function resumeTimer(timer) {
    const updatedTimer = {
      ...timer,
      running: true,
      lastStartedAt: DateTime.now(),
    };
    const promise = api
      .put(
        `/projects/api/v3/me/timers/${timer.id}/resume.json`,
        {},
        {
          errorMessage: t('There was a problem resuming the timer'),
        },
      )
      .then((response) => {
        emitRealTimeUpdate('edited');
        return response.data.timer;
      });
    emitOptimisticUpdate(promise, 'update', updatedTimer);
    return promise;
  }

  function pauseTimer(timer) {
    const diffInSeconds = Math.floor(DateTime.now().diff(timer.lastStartedAt).as('seconds'));
    const updatedTimer = {
      ...timer,
      running: false,
      duration: timer.duration + diffInSeconds,
    };
    const promise = api
      .put(
        `/projects/api/v3/me/timers/${timer.id}/pause.json`,
        {},
        {
          errorMessage: t('There was a problem pausing the timer'),
        },
      )
      .then((response) => {
        emitRealTimeUpdate('edited');
        return response.data.timer;
      });
    emitOptimisticUpdate(promise, 'update', updatedTimer);
    return promise;
  }

  function updateTimer(timer) {
    const promise = api
      .put(`/projects/api/v3/me/timers/${timer.id}.json`, toApiTimer(timer), {
        errorMessage: t('There was a problem updating the timer'),
      })
      .then((response) => {
        emitRealTimeUpdate('edited');
        return response.data.timer;
      });
    emitOptimisticUpdate(promise, 'update', timer);
    return promise;
  }

  return {
    createTimer,
    deleteTimer,
    resumeTimer,
    pauseTimer,
    updateTimer,
  };
}
