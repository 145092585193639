<script setup>
import { useI18n } from '@/util';

const { t } = useI18n();
</script>
<template>
  <div class="text-body-1">
    <p>
      {{
        t(
          'Automate your repetitive processes and improve team productivity by minimizing tedious and time-consuming tasks.',
        )
      }}
    </p>
    <p class="pt-6">
      {{
        t(
          'Send a notification if a task is behind schedule, assign to additional teammates if the priority changes, move tasks between lists if a specific tag is added, and more!',
        )
      }}
    </p>
  </div>
</template>
