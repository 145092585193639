<script setup lang="ts">
import { useDraggable, useElementBounding } from '@vueuse/core';
import { useI18n } from '@/util';

const props = defineProps({
  /** The container element within which the resizer can move. This should be a reference to a DOM element. */
  container: {
    type: Object as PropType<HTMLElement>,
    default: document.body,
  },
});

const emit = defineEmits<{
  dragStart: [];
  dragEnd: [];
}>();

/** The relative position of the resizer to its container. */
const position = defineModel<number>('position', { default: 0 });
/** A Boolean indicating whether the resizer is currently being dragged. */
const dragging = defineModel<boolean>('dragging', { default: false });

const resizeHandle = shallowRef(null);
const resizeHandleBtn = shallowRef(null);

const { t } = useI18n();

const { width: handleWidth } = useElementBounding(resizeHandle);
const { width: handleBtnWidth } = useElementBounding(resizeHandleBtn);
const { left: containerLeft, right: containerRight } = useElementBounding(computed(() => props.container));

const padding = computed(() => (handleWidth.value - handleBtnWidth.value) / 2);

const { isDragging } = useDraggable(resizeHandle, {
  onStart: () => emit('dragStart'),
  onEnd: () => emit('dragEnd'),
  onMove({ x: pos }) {
    if (!props.container) {
      return;
    }

    // When dragging, offset the handle padding and container and update with drag position.
    // When dragging reaches right bounds, offset the handle padding and container and fix to the right side.
    // When dragging reaches left bounds, offset the handle padding and container and fix to the left side.
    if (pos > containerRight.value - (padding.value + handleBtnWidth.value)) {
      position.value = containerRight.value - containerLeft.value - handleBtnWidth.value;
    } else if (pos < containerLeft.value - padding.value) {
      position.value = handleBtnWidth.value * -1;
    } else {
      position.value = pos - containerLeft.value + padding.value;
    }
  },
});

watch(isDragging, (value) => {
  dragging.value = value;
});
</script>
<template>
  <div
    ref="resizeHandle"
    class="absolute -left-4 z-50 h-full cursor-ew-resize px-4 opacity-0 transition-opacity hover:opacity-100"
    :class="{ 'opacity-100': isDragging }"
  >
    <button
      ref="resizeHandleBtn"
      type="button"
      :aria-label="t('Drag to resize')"
      :class="{
        'bg-[--lsds-a-color-border-primary-default]': isDragging,
      }"
      class="h-full w-1 cursor-ew-resize bg-[--lsds-a-color-border-bold] transition-colors"
    />
  </div>
</template>
