<script setup>
import { useI18n } from '@/util';

defineProps({
  featureTrialsActivated: {
    type: Array,
    default: () => [],
  },
  featureText: {
    type: String,
    default: '',
  },
  bannerMessage: {
    type: String,
    default: '',
  },
  days: {
    type: Number,
    default: 0,
  },
});

const emit = defineEmits(['featureClick']);

const { t } = useI18n();
</script>
<template>
  <LscReplace :text="featureText">
    <template #days>
      <span>{{ days }} {{ t('day | days', { n: days }) }}</span>
    </template>
    <template #featureTrials>
      <span v-for="(featureTrial, index) in featureTrialsActivated" :key="featureTrial.id">
        <span v-if="featureTrial.path">
          <RouterLink :to="featureTrial.path" @click="emit('featureClick', featureTrial)">
            {{ featureTrial.title }}
          </RouterLink>
        </span>
        <span v-else>{{ featureTrial.title }}</span>
        <span v-if="index < featureTrialsActivated.length - 2">,&nbsp;</span>
        <span v-else-if="index === featureTrialsActivated.length - 2">&nbsp;{{ t('and') }}&nbsp;</span>
      </span>
    </template>
    <template #bannerMessage>
      {{ bannerMessage }}
    </template>
  </LscReplace>
</template>
