/* eslint-disable no-param-reassign */
import { DateTime } from 'luxon';
import { useListLoader } from '../base/useListLoader';
import { useRealTimeUpdates } from '../base/useRealTimeUpdates';

function responseToItems(response) {
  const featuresList = response.data.features || [];
  featuresList.forEach((feature) => {
    feature.id = feature.key;
    feature.startAt = feature.startAt ? DateTime.fromISO(feature.startAt) : null;
    feature.endAt = feature.endAt ? DateTime.fromISO(feature.endAt) : null;
  });
  return featuresList;
}

/**
 * Loads a list of budgets from the Teamwork v3 API.
 */
export function useTeamworkFeaturesV3Loader({ params, count, pageSize = 100 }) {
  const { state, refresh } = useListLoader({
    url: '/projects/api/v3/features.json',
    params,
    count,
    responseToItems,
    pageSize,
  });

  useRealTimeUpdates((event) => {
    if (event.type === 'account' && event.action === 'edited') {
      refresh();
    }
  });

  return state;
}
