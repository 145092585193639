<script setup>
import { useI18n } from '@/util';
import ApiSetup1 from './ApiSetup1.vue';

const { languageCode, timeZoneName, dateFormat, timeFormat, weekStartsOnSunday } = useI18n();
</script>

<template>
  <!-- The `key` recreates the child component when the basic localization data changes. -->
  <ApiSetup1 :key="`${languageCode}/${timeZoneName}/${dateFormat}/${timeFormat}/${weekStartsOnSunday}`">
    <slot />
  </ApiSetup1>
</template>
