<script setup>
import { usePendo } from '@/api';
import { useI18n } from '@/util';

defineProps({
  /**
   * The data identifier of the button.
   */
  dataIdentifier: {
    type: String,
    required: true,
  },
  /**
   * The size of the button.
   * @type {PropType<'sm' | 'md' >}
   */
  size: {
    type: String,
    default: 'md',
    validator: (value) => ['sm', 'md'].includes(value),
  },
  /**
   * The name of the feature for the tour.
   */
  featureName: {
    type: String,
    required: true,
  },
});

const { t } = useI18n();
const { trackPendoEvent } = usePendo();

function trackContextualGuideBadgeClicked(dataIdentifier) {
  const commonMetrics = ['account_id', 'company_size', 'company_user_role', 'company_sector', 'plan_id'];
  trackPendoEvent({
    eventName: 'CONTEXTUAL_ONBOARDING_GUIDE_EVENT',
    commonMetrics,
    metadata: {
      event_action: 'guide_badge_clicked',
      badge_type: dataIdentifier,
    },
  });
}
</script>
<template>
  <LscButton
    variant="plain-primary"
    :size="size"
    :data-identifier="dataIdentifier"
    prependIcon="lsi-play"
    @click="trackContextualGuideBadgeClicked(dataIdentifier)"
  >
    {{ t('Take a tour of {featureName}', { featureName }) }}
  </LscButton>
</template>
