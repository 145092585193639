<script setup>
import { useI18n } from '@/util';

const { t } = useI18n();
</script>
<template>
  <div class="text-body-1">
    <p>
      {{
        t(
          'The Resource scheduler enables you to input the resources you need for any given project and assign these tasks to team members based on their skill sets and workloads.',
        )
      }}
    </p>
    <p class="pt-6">
      {{
        t(
          'Track how resources are being used, and the amount of time needed for each task throughout the project. You can even set up automations to save time on recurring tasks.',
        )
      }}
    </p>
  </div>
</template>
