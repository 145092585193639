<script setup lang="ts">
import { useI18n } from '@/util';
import {
  type LscButtonSize,
  LscButtonSizes,
  type LscButtonType,
  LscButtonTypes,
  type LscButtonVariant,
  LscButtonVariants,
} from './LscButton.types.js';

const props = defineProps({
  /** Used to set the button's active state for toggle buttons.*/
  ariaPressed: {
    type: [Boolean, String] as PropType<Booleanish | 'mixed'>,
    default: false,
  },
  /** Used to set the button's active state for popovers and dropdown menus.*/
  ariaExpanded: {
    type: [Boolean, String] as PropType<Booleanish>,
    default: false,
  },
  /** Used to define the buttons popup role for popovers and dropdown menus. */
  ariaHasPopup: {
    type: [Boolean, String] as PropType<Booleanish | 'menu' | 'listbox' | 'tree' | 'grid' | 'dialog'>,
    default: false,
  },
  /** This icon will be rendered after the button's content. */
  appendIcon: {
    type: String as PropType<LscIconName>,
    default: undefined,
  },
  /** Disables the button.*/
  disabled: {
    type: Boolean,
    default: false,
  },
  /** Shows a loading indicator inside the button. This also disables the button. */
  loading: {
    type: Boolean,
    default: false,
  },
  /** This icon will be rendered before the button's content.*/
  prependIcon: {
    type: String as PropType<LscIconName | undefined>,
    default: undefined,
  },
  /**
   * The size of the button.
   */
  size: {
    type: String as PropType<LscButtonSize>,
    default: 'lg',
    validator: (value: LscButtonSize) => LscButtonSizes.includes(value),
  },
  /** The HTML type of the button. */
  type: {
    type: String as PropType<LscButtonType>,
    default: 'button',
    validator: (value: LscButtonType) => LscButtonTypes.includes(value),
  },
  /** The variant of the button. */
  variant: {
    type: String as PropType<LscButtonVariant>,
    default: 'secondary',
    validator: (value: LscButtonVariant) => LscButtonVariants.includes(value),
  },
});

const { t } = useI18n();

const iconSizes = {
  sm: 'xs',
  md: 'sm',
  lg: 'md',
} as const;

const iconSize = computed(() => iconSizes[props.size]);

const buttonVariantStyleConfig = tv({
  base: [
    'group/button relative inline-flex min-w-0 content-center items-center whitespace-nowrap rounded-full font-medium outline-none',
    'focus-visible:ring-[length:--lsds-c-button-focus-ring-width]',
  ],
  slots: {
    icon: 'shrink-0',
    loadingIcon: 'shrink-0 animate-spin rounded-full border-2 border-current border-t-transparent',
  },
  variants: {
    size: {
      sm: {
        base: 'text-button-2',
        loadingIcon: 'size-[--lsds-a-icon-size-xs]',
      },
      md: {
        base: 'text-button-1',
        loadingIcon: 'size-[--lsds-a-icon-size-sm]',
      },
      lg: {
        base: 'text-button-1',
        loadingIcon: 'size-[--lsds-a-icon-size-md]',
      },
    },
    variant: {
      'primary': {
        base: [
          'bg-[--lsds-c-button-color-bg-primary-default]',
          'text-[color:--lsds-c-button-color-text-primary-default]',
          'ring-[color:--lsds-c-button-color-focus-ring-primary]',
          'hover:bg-[--lsds-c-button-color-bg-primary-hover]',
          'aria-expanded:bg-[--lsds-c-button-color-bg-primary-active]',
          'aria-pressed:bg-[--lsds-c-button-color-bg-primary-pressed]',
          'active:bg-[--lsds-c-button-color-bg-primary-active]',
          'aria-disabled:bg-[--lsds-c-button-color-bg-primary-disabled]',
          'aria-disabled:text-[color:--lsds-c-button-color-text-primary-disabled]',
        ],
      },
      'secondary': {
        base: [
          'bg-[--lsds-c-button-color-bg-secondary-default]',
          'text-[color:--lsds-c-button-color-text-secondary-default]',
          'border border-[color:--lsds-c-button-color-border-secondary-default]',
          'ring-[color:--lsds-c-button-color-focus-ring-secondary]',
          'hover:bg-[--lsds-c-button-color-bg-secondary-hover]',
          'hover:border-[color:--lsds-c-button-color-border-secondary-hover]',
          'aria-expanded:bg-[--lsds-c-button-color-bg-secondary-active]',
          'aria-expanded:border-[color:--lsds-c-button-color-border-secondary-active]',
          'aria-pressed:bg-[--lsds-c-button-color-bg-secondary-pressed]',
          'aria-pressed:text-[color:--lsds-c-button-color-text-secondary-pressed]',
          'active:bg-[--lsds-c-button-color-bg-secondary-active]',
          'active:border-[color:--lsds-c-button-color-border-secondary-active]',
          'focus-visible:border-[color:--lsds-c-button-color-border-secondary-focus]',
          'aria-disabled:bg-[--lsds-c-button-color-bg-secondary-disabled]',
          'aria-disabled:border-[color:--lsds-c-button-color-bg-secondary-disabled]',
          'aria-disabled:text-[color:--lsds-c-button-color-text-secondary-disabled]',
        ],
      },
      'tertiary': {
        base: [
          'bg-[--lsds-c-button-color-bg-tertiary-default]',
          'text-[color:--lsds-c-button-color-text-tertiary-default]',
          'ring-[color:--lsds-c-button-color-focus-ring-tertiary]',
          'hover:bg-[--lsds-c-button-color-bg-tertiary-hover]',
          'aria-expanded:bg-[--lsds-c-button-color-bg-tertiary-active]',
          'aria-pressed:bg-[--lsds-c-button-color-bg-tertiary-pressed]',
          'aria-pressed:text-[color:--lsds-c-button-color-text-tertiary-pressed]',
          'active:bg-[--lsds-c-button-color-bg-tertiary-active]',
          'aria-disabled:bg-[--lsds-c-button-color-bg-tertiary-disabled]',
          'aria-disabled:text-[color:--lsds-c-button-color-text-tertiary-disabled]',
        ],
      },
      'critical': {
        base: [
          'bg-[color:--lsds-c-button-color-bg-critical-default]',
          'text-[color:--lsds-c-button-color-text-critical-default]',
          'ring-[color:--lsds-c-button-color-focus-ring-critical]',
          'hover:bg-[--lsds-c-button-color-bg-critical-hover]',
          'aria-expanded:bg-[--lsds-c-button-color-bg-critical-active]',
          'active:bg-[--lsds-c-button-color-bg-critical-active]',
          'aria-disabled:bg-[--lsds-c-button-color-bg-critical-disabled]',
          'aria-disabled:text-[color:--lsds-c-button-color-text-critical-disabled]',
        ],
      },
      'critical-secondary': {
        base: [
          'bg-[--lsds-c-button-color-bg-critical-secondary-default]',
          'text-[color:--lsds-c-button-color-text-critical-secondary-default]',
          'border border-[color:--lsds-c-button-color-border-critical-secondary-default]',
          'ring-[color:--lsds-c-button-color-focus-ring-critical-secondary]',
          'hover:bg-[--lsds-c-button-color-bg-critical-secondary-hover]',
          'hover:border-[color:--lsds-c-button-color-border-critical-secondary-hover]',
          'aria-expanded:bg-[--lsds-c-button-color-bg-critical-secondary-active]',
          'aria-expanded:border-[color:--lsds-c-button-color-border-critical-secondary-active]',
          'active:bg-[--lsds-c-button-color-bg-critical-secondary-active]',
          'active:border-[color:--lsds-c-button-color-border-critical-secondary-active]',
          'focus-visible:border-[color:--lsds-c-button-color-border-critical-secondary-focus]',
          'aria-disabled:bg-[--lsds-c-button-color-bg-critical-secondary-disabled]',
          'aria-disabled:border-[color:--lsds-c-button-color-bg-critical-secondary-disabled]',
          'aria-disabled:text-[color:--lsds-c-button-color-text-critical-secondary-disabled]',
        ],
      },
      'plain-primary': {
        base: [
          'text-[color:--lsds-c-button-color-text-plain-primary-default]',
          'hover:text-[color:--lsds-c-button-color-text-plain-primary-hover]',
          'aria-expanded:text-[color:--lsds-c-button-color-text-plain-primary-active]',
          'active:text-[color:--lsds-c-button-color-text-plain-primary-active]',
          'ring-[color:--lsds-c-button-color-focus-ring-plain-primary]',
          'aria-disabled:text-[color:--lsds-c-button-color-text-plain-primary-disabled]',
        ],
      },
      'plain-secondary': {
        base: [
          'text-[color:--lsds-c-button-color-text-plain-secondary-default]',
          'ring-[color:--lsds-c-button-color-focus-ring-plain-secondary]',
          'hover:text-[color:--lsds-c-button-color-text-plain-secondary-hover]',
          'aria-expanded:text-[color:--lsds-c-button-color-text-plain-secondary-active]',
          'active:text-[color:--lsds-c-button-color-text-plain-secondary-active]',
          'focus-visible:text-[color:--lsds-c-button-color-text-plain-secondary-focus]',
          'aria-disabled:text-[color:--lsds-c-button-color-text-plain-secondary-disabled]',
        ],
      },
    },
  },
  compoundVariants: [
    {
      variant: ['primary', 'secondary', 'tertiary', 'critical', 'critical-secondary'],
      size: 'sm',
      class: {
        base: [
          'h-[--lsds-c-button-size-sm]',
          'gap-[--lsds-c-button-spacing-gap-sm]',
          'px-[--lsds-c-button-spacing-outer-sm]',
        ],
      },
    },
    {
      variant: ['primary', 'secondary', 'tertiary', 'critical', 'critical-secondary'],
      size: 'md',
      class: {
        base: [
          'h-[--lsds-c-button-size-md]',
          'gap-[--lsds-c-button-spacing-gap-md]',
          'px-[--lsds-c-button-spacing-outer-md]',
        ],
      },
    },
    {
      variant: ['primary', 'secondary', 'tertiary', 'critical', 'critical-secondary'],
      size: 'lg',
      class: {
        base: [
          'h-[--lsds-c-button-size-lg]',
          'gap-[--lsds-c-button-spacing-gap-lg]',
          'px-[--lsds-c-button-spacing-outer-lg]',
        ],
      },
    },
    {
      variant: ['primary', 'secondary', 'critical', 'critical-secondary'],
      class: {
        icon: 'group/button:disabled:invisible',
      },
    },
    {
      variant: ['plain-primary', 'plain-secondary'],
      class: {
        base: 'gap-1 ring-offset-4',
      },
    },
  ],
});

const classes = computed(() =>
  buttonVariantStyleConfig({
    size: props.size,
    variant: props.variant,
  }),
);
</script>

<template>
  <button
    :class="classes.base()"
    :type="type"
    :aria-pressed="ariaPressed"
    :aria-disabled="disabled"
    :aria-expanded="ariaExpanded"
    :aria-haspopup="ariaHasPopup || ariaExpanded"
    :disabled="disabled || loading"
  >
    <div :class="{ invisible: loading }" class="contents">
      <!-- @slot Before the button text -->
      <slot name="prepend">
        <LscIcon v-if="prependIcon" :icon="prependIcon" :class="classes.icon()" :size="iconSize" />
      </slot>
      <!-- @slot Button text content. Must not have any interactable elements inside this slot -->
      <slot />
      <!-- @slot After the button text -->
      <slot name="append">
        <LscIcon v-if="appendIcon" :icon="appendIcon" :class="classes.icon()" :size="iconSize" />
      </slot>
    </div>

    <div v-if="loading" class="absolute inset-0 flex items-center justify-center">
      <span :class="classes.loadingIcon()" role="status" :aria-label="t('Loading')">
        <span class="sr-only">{{ t('Loading') }}</span>
      </span>
    </div>
  </button>
</template>
