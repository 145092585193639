import { useAxios } from '../base/useAxios';

/**
 * @typedef {Object} Recent
 * @property {'project' | 'task' | 'tasklist' | 'message' | 'form' | 'file' | 'notebook' | 'milestone' | 'link' | 'billingInvoice' | 'customReport'} entityType
 * @property {'viewed'} actionType
 * @property {number|undefined} projectId
 * @property {number} entityId
 * @property {string|undefined} description
 * @property {string|undefined} descriptionLink
 */

export function useRecencyActions() {
  const api = useAxios();

  /**
   * Logs a recency item to the Teamwork API.
   * @param {Recent} recent
   */
  async function logRecent(recent) {
    const promise = await api.post('/projects/api/v3/recency.json', { recent });
    return promise;
  }

  return {
    logRecent,
  };
}
