<script setup>
import { useI18n } from '@/util';
import OnboardingWizardCommonStep from '../common/OnboardingWizardCommonStep.vue';
import OnboardingWizardCommonPreview from '../common/preview/OnboardingWizardCommonPreview.vue';
import {
  PREVIEW_TAB_BOARD,
  PREVIEW_TAB_LIST,
  PREVIEW_TAB_TABLE,
  SAMPLE_USER_TASKS_DATA,
  STEP_ADD_CLIENT,
  STEP_ADD_TASKS,
  STEP_BOARD_COLUMNS,
  STEP_PROJECT_NAME,
  STEP_SELECT_VIEW,
} from '../constants';
import { useOnboardingWizardTracking } from '../useOnboardingWizardTracking';

const props = defineProps({
  nextButtonText: {
    type: String,
    required: true,
  },
  state: {
    type: Object,
    required: true,
  },
});

/**
 * Triggers sending step result data back to main state and move to next step
 *
 * @param {boolean} hasChanged this step has changed data compared to the history. compare with `stateData` for the step
 * @param {object} stepData step result data
 * @param {object} [stateData] full state data - optional if you edited anything in the full state
 */
const emit = defineEmits(['nextStep']);

/** use stateData to check if there's old values for this state and if they have changed */
const {
  [STEP_SELECT_VIEW]: stateData,
  [STEP_PROJECT_NAME]: projectNameState,
  [STEP_ADD_TASKS]: addTasksState,
  [STEP_BOARD_COLUMNS]: boardColumnsState,
  [STEP_ADD_CLIENT]: addClientState,
} = props.state;

const { t } = useI18n();
const { trackOnboardingCommonPreviewClicked } = useOnboardingWizardTracking();

const selectedView = shallowRef(
  projectNameState?.selectedView ?? addTasksState?.selectedView ?? props.state?.selectedView ?? PREVIEW_TAB_TABLE,
);

const selectedTemplate = projectNameState?.selectedTemplate ?? {};
const projectName = (projectNameState?.name || addTasksState?.name) ?? '';
const tasksDataFromTemplate = !!projectNameState?.selectedTemplate.name;
const boardColumnsFromState = boardColumnsState?.columns;
const boardColumnsFromTasksDataTemplate = SAMPLE_USER_TASKS_DATA.boardColumns.map((column) => column.name);

const tasksData = computed(() => {
  const columns = boardColumnsFromState ?? boardColumnsFromTasksDataTemplate;

  if (selectedTemplate.content) {
    return selectedTemplate.content;
  }

  const combinedTasks = SAMPLE_USER_TASKS_DATA.tasklists.map((tasklist) => ({
    ...tasklist,
    tasks: tasklist.tasks.map((task, taskIndex) => ({
      ...task,
      name: addTasksState.tasksList[taskIndex] ?? '',
    })),
  }));

  const columnsDisplayed = columns.map((columnName, index) => ({
    ...SAMPLE_USER_TASKS_DATA.boardColumns[index],
    name: columnName,
  }));

  return {
    boardColumns: columnsDisplayed,
    tasklists: combinedTasks,
  };
});

function nextStep() {
  emit('nextStep', selectedView.value !== stateData?.view, {
    name: projectName,
    view: selectedView?.value,
    tasksList: tasksData.value,
  });
}

watch(selectedView, (newValue) => {
  if (newValue) {
    trackOnboardingCommonPreviewClicked(STEP_SELECT_VIEW, newValue, 'main');
  }
});
</script>

<template>
  <OnboardingWizardCommonStep
    :title="t('Select view')"
    :description="t('What works better for you? You can change this later')"
  >
    <LscChoiceList v-model="selectedView" mandatory :multiple="false">
      <LscChoice :value="PREVIEW_TAB_TABLE" hasCheck :label="t('Table')" icon="lsi-table" />
      <LscChoice :value="PREVIEW_TAB_BOARD" hasCheck :label="t('Board')" icon="lsi-board-column" />
      <LscChoice :value="PREVIEW_TAB_LIST" hasCheck :label="t('List')" icon="lsi-bulleted-list" />
    </LscChoiceList>

    <slot name="underFieldsButtons" :nextButtonText="nextButtonText" :nextStep="nextStep" />

    <template #right>
      <OnboardingWizardCommonPreview
        :projectName="projectName"
        :clientCompanyName="addClientState?.name"
        :preselectedTab="selectedView"
        :tasksData="tasksData"
        :tasksDataFromTemplate="tasksDataFromTemplate"
        :tabs="[PREVIEW_TAB_TABLE, PREVIEW_TAB_BOARD, PREVIEW_TAB_LIST]"
        :stepId="STEP_SELECT_VIEW"
      />
    </template>
  </OnboardingWizardCommonStep>
</template>
