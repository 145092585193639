const targets = new WeakMap();

const observer = new ResizeObserver((entries) => {
  for (const entry of entries) {
    const target = targets.get(entry.target);
    if (target) {
      target.value = entry.target.offsetWidth < entry.target.scrollWidth;
    }
  }
});

export function useOverflowEllipsis(ref) {
  const isOverflowing = shallowRef(false);

  function unregisterElement(element) {
    targets.delete(element);
    if (element) {
      observer.unobserve(element);
    }
  }

  function registerElement(element) {
    targets.set(element, isOverflowing);
    observer.observe(element);
  }

  watch(
    ref,
    (newElement, oldElement) => {
      if (oldElement) {
        unregisterElement(oldElement);
      }
      if (newElement) {
        registerElement(newElement);
      }
    },
    { immediate: true },
  );

  onUnmounted(() => {
    unregisterElement(ref.value);
  });

  return isOverflowing;
}
