export const themeMap = new Map([
  ['#0B0F1F', 'default'],
  ['#64697D', 'neutral'],
  ['#9D6942', 'brown'],
  ['#CD9100', 'yellow'],
  ['#D14711', 'orange'],
  ['#E12D42', 'red'],
  ['#00884E', 'green'],
  ['#00838F', 'teal'],
  ['#0574E3', 'blue'],
  ['#4461D7', 'indigo'],
  ['#602BE7', 'violet'],
  ['#DD0792', 'pink'],
]);
