import { useExperimentA49, usePendo } from '@/api';
import { useAppShellSidebar } from '@/appShell';
import { useRoute } from '@/route';
import { useI18n } from '@/util';
import {
  HELP_CENTER_COMMON_METRICS,
  HELP_CENTER_PANEL,
  HELP_CENTER_PANEL_WIDE,
  STATE_DEFAULT,
  STATE_KNOWLEDGE_BASE_ARTICLE,
  STATE_SEARCH_RESULTS,
} from './constants';
import { getRecommendedArticleDataForPath, openToStateStorage, validState } from './helpers';

const symbol = Symbol('useHelpCenter');

function HelpCenterService() {
  const route = useRoute();
  const { t } = useI18n();
  const { trackPendoEvent } = usePendo();
  const { activeDrawerPanel, setActiveDrawerPanel } = useAppShellSidebar();
  const { isExpA49Variation } = useExperimentA49();

  const statesHistory = ref([{ id: STATE_DEFAULT, data: null }]);
  const popRecentlyCalled = shallowRef(false);
  const currentState = computed(() => statesHistory.value.at(-1));
  const previousState = computed(() => statesHistory.value.at(-2));

  const searchTerm = shallowRef('');
  const isHelpCenterContactFilledOut = shallowRef(false);

  /**
   * reset the state, only use in HelpCenterDrawer when opened
   */
  function resetState() {
    const defaultState = [{ id: STATE_DEFAULT, data: null }];
    isHelpCenterContactFilledOut.value = false;
    searchTerm.value = '';
    statesHistory.value = defaultState;
    openToStateStorage.value = defaultState;
  }

  /**
   * Set a new state to the top of the stack for help center
   * @param {string} state name of state (use constants)
   * @param {*} data any data the state requires
   */
  function pushState(state, data = null) {
    statesHistory.value.push({ id: state, data });
  }

  function popState() {
    if (isHelpCenterContactFilledOut.value) {
      if (
        // eslint-disable-next-line no-alert
        confirm(t('You have unsubmitted feedback. Are you sure you want to close the window?'))
      ) {
        isHelpCenterContactFilledOut.value = false;
      } else {
        return;
      }
    }

    if (!previousState.value) {
      return;
    }

    popRecentlyCalled.value = true;

    const lastSearchResultState = statesHistory.value
      .slice(0, -1)
      .findLast((state) => state.id === STATE_SEARCH_RESULTS);

    if (lastSearchResultState) {
      searchTerm.value = lastSearchResultState.data.searchTerm;
    } else {
      searchTerm.value = '';
    }

    statesHistory.value.pop();
  }

  watch(
    openToStateStorage,
    ({ state: _state, data }) => {
      const state = validState(_state);
      if (currentState.value.id !== state) {
        pushState(state, data);
        setActiveDrawerPanel(
          currentState.value === STATE_KNOWLEDGE_BASE_ARTICLE ? HELP_CENTER_PANEL_WIDE : HELP_CENTER_PANEL,
        );
      }
    },
    { immediate: true },
  );

  // Unblocks search by setting popRecentlyCalled to false after state change and tick (to make sure searchTerm watch can still get valid popRecentlyCalled value)
  watch(
    statesHistory,
    async () => {
      await nextTick();
      popRecentlyCalled.value = false;
      if ([HELP_CENTER_PANEL, HELP_CENTER_PANEL_WIDE].includes(activeDrawerPanel.value)) {
        if (currentState.value.id === STATE_KNOWLEDGE_BASE_ARTICLE) {
          setActiveDrawerPanel(HELP_CENTER_PANEL_WIDE);
        } else {
          setActiveDrawerPanel(HELP_CENTER_PANEL);
        }
      }
    },
    { deep: true },
  );

  watch(searchTerm, (newTerm, oldTerm) => {
    // Don't make any action in case user cleared the input in state different from STATE_SEARCH_RESULTS
    if (!newTerm && oldTerm && currentState.value.id !== STATE_SEARCH_RESULTS) {
      return;
    }

    // Don't make any action in case searchTerm was changed by popState
    if (popRecentlyCalled.value) {
      return;
    }

    if (!newTerm?.trim()?.length) {
      popState();

      return;
    }

    if (currentState.value.id === STATE_SEARCH_RESULTS) {
      currentState.value.data.searchTerm = newTerm;
    } else {
      pushState(STATE_SEARCH_RESULTS, { searchTerm: newTerm });
    }

    trackPendoEvent({
      eventName: 'HELP_EVENT',
      commonMetrics: HELP_CENTER_COMMON_METRICS,
      metadata: {
        event_action: 'search_submitted',
        event_label: newTerm,
      },
    });
  });

  if (isExpA49Variation.value) {
    watch(
      activeDrawerPanel,
      (newVal, oldVal) => {
        if (
          [HELP_CENTER_PANEL, HELP_CENTER_PANEL_WIDE].includes(newVal) &&
          ![HELP_CENTER_PANEL, HELP_CENTER_PANEL_WIDE].includes(oldVal)
        ) {
          // Remove trailing slash from the current path
          const currentPath = route.path.slice(-1) === '/' ? route.path.slice(0, -1) : route.path;
          const data = getRecommendedArticleDataForPath(currentPath);
          if (!data) {
            // no route found, do nothing
            return;
          }

          pushState(STATE_KNOWLEDGE_BASE_ARTICLE, data);
        }
      },

      { immediate: true },
    );
  }

  return {
    HELP_CENTER_PANEL,
    HELP_CENTER_COMMON_METRICS,
    currentState,
    previousState,
    searchTerm,
    isHelpCenterContactFilledOut,
    isExpA49Variation,
    pushState,
    popState,
    resetState,
  };
}

export function provideHelpCenter() {
  const helpCenterService = HelpCenterService();
  provide(symbol, helpCenterService);
  return helpCenterService;
}

/**
 * @type {HelpCenterService}
 */
export function useHelpCenter() {
  return inject(symbol);
}
