import * as TOKENS from '@teamwork/lightspeed-design-tokens';

export const DEFAULT_THEME = 'main';

/**
 * @type {import('vuetify').ThemeDefinition}
 */
export const main = {
  dark: false,
  colors: {
    'background': TOKENS.LsdsAColorBackgroundDefault,
    // Intentionally not using the surface color here because it's not correct
    'surface': TOKENS.LsdsAColorBackgroundDefault,
    'primary': TOKENS.LsdsAColorActionPrimaryDefault,
    'secondary': TOKENS.LsdsAColorActionSecondaryDefault,
    'tertiary': TOKENS.LsdsAColorActionTertiaryDefault,
    'error': TOKENS.LsdsAColorActionCriticalDefault,
    'info': TOKENS.LsdsAColorSurfaceInfoDefault,
    'accent': TOKENS.LsdsAColorSurfaceAccent,
    'on-background': TOKENS.LsdsAColorTextDefault,
    'on-surface': TOKENS.LsdsAColorTextDefault,
    'on-primary': TOKENS.LsdsAColorTextOnPrimary,
    'on-secondary': TOKENS.LsdsAColorTextOnSecondary,
    'on-success': TOKENS.LsdsAColorTextOnSuccess,
    'on-warning': TOKENS.LsdsAColorTextOnWarning,
    'on-error': TOKENS.LsdsAColorTextOnCritical,
    'on-info': TOKENS.LsdsAColorTextOnInfo,
  },
};

// This is what we generated from the design system tokens before
// export const VUETIFYLSDSTHEME = {
// secondary: '#edeff5',
// error: '#e12d42',
// decorative1: '#bba1ff',
// decorative2: '#94c7fb',
// decorative3: '#ffa785',
// decorative4: '#ffa0dd',
// decorative5: '#97e8f0',
// decorative6: '#ffdc87',
// decorative7: '#95e1c1',
// decorative8: '#ed7987',
// textSecondary: '#64697d',
// textAccent: '#e557b3',
// onPrimary: '#ffffff',
// onSecondary: '#0b0f1f',
// onError: '#ffffff',
// onCritical: '#ffffff',
// onInfo: '#0b0f1f',
// onSuccess: '#0b0f1f',
// onWarning: '#0b0f1f',
// onAccent: '#0b0f1f',
// onTertiary: '#c5cae0',
// textPrimary: '#4461d7',
// textError: '#b42435',
// textCrticial: '#b42435',
// textWarning: '#ac5515',
// textSuccess: '#318160',
// textInfo: '#4592df',
// textTertiary: '#0b0f1f',
// background: '#ffffff',
// surface: '#ffffff',
// warning: '#ffc63c',
// success: '#4ecd97',
// info: '#4da2f8',
// accent: '#ff61c7',
// surfaceHighlight: '#ff61c7',
// info: '#4da2f8',
// success: '#4ecd97',
// warning: '#ffc63c',
// };
