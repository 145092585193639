import { useI18n } from '@/util';

const symbol = Symbol('useCalendarGoogleSyncAuthPopup');

const GOOGLE_CAL_SYNC_AUTH_POPOVER_MAX_WIDTH = 482;
const GOOGLE_CAL_SYNC_AUTH_POPOVER_MAX_HEIGHT = 528;

export function CalendarGoogleSyncAuthPopup() {
  const { t } = useI18n();

  // Calculating popup dimensions and position
  const width = Math.min(window.screen.width, GOOGLE_CAL_SYNC_AUTH_POPOVER_MAX_WIDTH);
  const height = Math.min(window.screen.height, GOOGLE_CAL_SYNC_AUTH_POPOVER_MAX_HEIGHT);
  const top = (window.innerHeight - height) / 2;
  const left = (window.innerWidth - width) / 2;

  let googleSyncAuthPopup;

  function openGoogleSyncAuthPopup(reconnecting = false) {
    googleSyncAuthPopup?.close(); // Close already opened popup

    const action = reconnecting ? 'reconnect' : 'start';
    const dTime = new Date().getTime(); // Passing dt to the url to prevent caching

    googleSyncAuthPopup = window.open(
      `/synthesis/api/v2/yoxelcalendarsync/oauth/${action}?dt=${dTime}`,
      '_blank',
      `width=${width}, height=${height}, top=${top}, left=${left}`,
    );
    googleSyncAuthPopup.document.title = t('Sign in - {google} Accounts', { google: 'Google' });
  }

  return { openGoogleSyncAuthPopup };
}

export function provideCalendarGoogleSyncAuthPopup() {
  provide(symbol, CalendarGoogleSyncAuthPopup());
}

export function useCalendarGoogleSyncAuthPopup() {
  return inject(symbol);
}
