<script setup>
import { useExperimentA2419, usePendo, usePreferences } from '@/api';
import { usePlanTrial } from './usePlanTrial';

const PlanTrialUpgradeDialogPanel = defineAsyncComponent(() => import('./PlanTrialUpgradeDialogPanel.vue'));

const { isExpA2419Variation } = useExperimentA2419();
const { trackPendoEvent } = usePendo();
const { scaleTrialUpgradeModalDismissed } = usePreferences();
const { isTrialActive } = usePlanTrial();

const isUpgradeDialogOpen = shallowRef(
  isExpA2419Variation.value && !scaleTrialUpgradeModalDismissed.value && !isTrialActive.value,
);

watch(
  isUpgradeDialogOpen,
  (isOpen) => {
    if (isOpen) {
      trackPendoEvent({
        eventName: 'SCALE_TRIAL_UPGRADE_EVENT',
        metadata: {
          event_action: 'scale_trial_upgrade_modal_displayed',
        },
      });
    }
  },
  { immediate: true },
);
</script>
<template>
  <LscDialog v-bind="$attrs" v-model="isUpgradeDialogOpen">
    <template #activator="activator"><slot name="activator" v-bind="activator" /></template>
    <template #default="{ close }">
      <PlanTrialUpgradeDialogPanel @close="close" />
    </template>
  </LscDialog>
</template>
