<script setup>
// Displays a luxon DateTime object as a user-friendly string.

import { DateTime } from 'luxon';
import { DateFormatOptions, useI18n } from '@/util';

const props = defineProps({
  /**
   * The date to display as a Luxon DateTime object.
   * @type {PropType<DateTime|undefined>}
   */
  dateTime: {
    type: Object,
    default: undefined,
    validator: (value) => value === undefined || DateTime.isDateTime(value),
  },
  /**
   * @type {PropType<DateFormats>}
   */
  format: {
    validator: (value) => DateFormatOptions.includes(value),
    default: 'mini',
  },
  /**
   * Whether to display a tooltip with the full date.
   * @type {PropType<boolean>}
   */
  noTooltip: {
    type: Boolean,
    default: false,
  },
});

const { formatDate } = useI18n();

const dateTimeCustom = computed(() => formatDate(props.dateTime, props.format));
const dateISO = computed(() => props.dateTime?.toISO());
const dateTooltip = computed(() => (props.noTooltip ? undefined : formatDate(props.dateTime, 'long')));
</script>

<template>
  <time v-if="dateTimeCustom" v-LsdTooltip="dateTooltip" :datetime="dateISO">
    {{ dateTimeCustom }}
  </time>
</template>
