<script setup>
import { useI18n } from '@/util';

const { t } = useI18n();
</script>
<template>
  <div class="text-body-1">
    <p>
      {{
        t(
          'The Profitability Report keeps track of profitability across users, projects, and clients. See which projects are bringing in the most profit and exactly how much your team members are contributing to the bottom line.',
        )
      }}
    </p>
    <p class="pt-6">
      {{
        t(
          'Take the guesswork out of profitability! Now you can evaluate your return on investment and choose future work based on past profitability. Easily share this report for financial transparency so everyone is aligned.',
        )
      }}
    </p>
  </div>
</template>
