import { useDocumentVisibility, useWebNotification } from '@vueuse/core';
import { delayedPromise, getEntitySomeoneText, useI18n } from '@/util';
import { useCurrentAccount } from '../account/useCurrentAccount';
import { useOptimisticUpdates } from '../base/useOptimisticUpdates';
import { useRealTimeUpdates } from '../base/useRealTimeUpdates';
import { usePreferences } from '../preferences/usePreferences';
import { useCurrentUser } from '../user/useCurrentUser';
import { normalizeNotification } from './normalizeNotification';
import { useNotificationsFetcher } from './useNotificationsFetcher';
import { useNotificationsUnreadCount } from './useNotificationsUnreadCount';

/**
 * TODO: Migrate this to use useListLoader as it already supports cursor based pagination
 */

const symbol = Symbol('useNotifications');

function NotificationsService() {
  const { areNotificationsUnmuted, notificationDrawerActiveTab, useDesktopNotifications } = usePreferences();
  const notificationUnreadCount = useNotificationsUnreadCount();
  const user = useCurrentUser();
  const account = useCurrentAccount();
  const { t } = useI18n();
  const toast = useLsToast();
  const visibility = useDocumentVisibility();
  const { isSupported, show: showWebNotification, onClick } = useWebNotification();

  const allNotifications = useNotificationsFetcher({
    params: {
      unreadOnly: false,
    },
  });

  const readNotifications = useNotificationsFetcher({
    params: {
      readOnly: true,
    },
  });

  const unreadNotifications = useNotificationsFetcher({
    params: {
      unreadOnly: true,
    },
  });

  function shouldShowDesktopNotification(rawEvent) {
    // Only show desktop notification if the user has enabled it and the app is not visible
    if (visibility.value === 'visible' || !useDesktopNotifications.value) {
      return false;
    }

    const { notifiedUserIdList } = rawEvent.eventInfo;
    // Only show desktop notification if the user has been explictly chosen to be
    // notified by this event
    if (!notifiedUserIdList) {
      return false;
    }

    let recipientIds = [];

    if (typeof notifiedUserIdList === 'string') {
      recipientIds = [...notifiedUserIdList.split(',').map((id) => parseInt(id, 10))];
    }

    if (typeof notifiedUserIdList === 'number') {
      recipientIds = [notifiedUserIdList];
    }

    return recipientIds.includes(user.value.id);
  }

  function shouldAddNewNotificationFromRealTimeUpdate(rawEvent) {
    if (!rawEvent?.eventInfo?.popup) {
      return false;
    }

    // Only handle notification not originating from this user
    if (parseInt(rawEvent.eventInfo.userId, 10) === user.value.id) {
      return false;
    }

    return true;
  }

  function findNotification(twimEventId) {
    return (
      unreadNotifications.getItem(twimEventId) ||
      readNotifications.getItem(twimEventId) ||
      allNotifications.getItem(twimEventId)
    );
  }

  function toggleNotificationMute() {
    areNotificationsUnmuted.value = !areNotificationsUnmuted.value;
  }

  function addWebNotification(notification) {
    if (!isSupported.value) {
      return;
    }

    showWebNotification({
      title: getEntitySomeoneText(notification.entity)(t, notification.user),
      icon: account.value.logo,
      body: notification.description || '',
      tag: `tw-${account.value.id}-${user.value.id}`,
    });

    onClick(() => {
      window.open(`/app/${notification.entity.link}`, '_blank');
    });
  }

  function addNewNotification(rawEvent) {
    const { eventInfo } = rawEvent;
    const notification = normalizeNotification(eventInfo, user.value.id, false);

    if (!notification.id) {
      return;
    }

    unreadNotifications.add(notification);
    allNotifications.add(notification);

    if (shouldShowDesktopNotification(rawEvent)) {
      addWebNotification(notification);
    }
  }

  function setNotificationAsRead(notification) {
    const updated = { ...notification, read: true };

    unreadNotifications.remove(notification);
    readNotifications.add(updated);
    allNotifications.update(updated);
  }

  function setNotificationAsUnread(notification) {
    const updated = { ...notification, read: false };

    readNotifications.remove(notification);
    unreadNotifications.add(updated);
    allNotifications.update(updated);
  }

  function setAllNotificationsAsRead() {
    const readItems = unreadNotifications.state.items.value.map((i) => ({
      ...i,
      read: true,
    }));

    unreadNotifications.clear();
    readNotifications.addMultiple(readItems);
    allNotifications.updateMultiple(readItems);
  }

  function optimisticSetNotificationAsRead(notification, promise) {
    setNotificationAsRead(notification);

    function rollback() {
      setNotificationAsUnread(notification);
      toast.critical(t('Failed to mark notification as read'));
    }

    promise.catch(rollback);
  }

  function optimisticSetNotificationAsUnread(notification, promise) {
    setNotificationAsUnread(notification);

    function rollback() {
      setNotificationAsRead(notification);
      toast.critical(t('Failed to mark notification as unread'));
    }

    promise.catch(rollback);
  }

  function optimisticSetAllNotificationsAsRead(promise) {
    setAllNotificationsAsRead();

    function rollback() {
      unreadNotifications.reset();
      readNotifications.reset();
      allNotifications.reset();

      toast.critical(t('Failed to mark all notifications as read'));
    }

    promise.catch(rollback);
  }

  useOptimisticUpdates((event) => {
    if (!(event.type === 'notification' && event.action === 'update')) {
      return;
    }

    if (event.notification.id) {
      const notification = findNotification(event.notification.id);

      if (event.notification.read) {
        optimisticSetNotificationAsRead(notification, event.promise);
      } else {
        optimisticSetNotificationAsUnread(notification, event.promise);
      }
    } else {
      optimisticSetAllNotificationsAsRead(event.promise);
    }
  });

  useRealTimeUpdates(async (event, rawEvent) => {
    if (!account.value?.realTimeNotifications) {
      return;
    }

    if (!rawEvent) {
      return;
    }

    const { eventInfo } = rawEvent;

    if (event.detail === 'notification-updated-read') {
      const alreadyMarkedAsRead = !!readNotifications.getItem(eventInfo.twimEventId);

      if (alreadyMarkedAsRead) {
        return;
      }

      const notification = findNotification(eventInfo.twimEventId);

      if (notification) {
        setNotificationAsRead(notification);
        return;
      }

      await delayedPromise(null, 2000);

      readNotifications.refresh();

      return;
    }

    if (event.detail === 'notification-updated-unread') {
      const alreadyMarkedAsUnread = !!unreadNotifications.getItem(eventInfo.twimEventId);
      if (alreadyMarkedAsUnread) {
        return;
      }

      const notification = findNotification(eventInfo.twimEventId);

      if (notification) {
        setNotificationAsUnread(notification);
        return;
      }

      await delayedPromise(null, 2000);

      unreadNotifications.refresh();

      return;
    }

    if (event.detail === 'notification-mark-all-read') {
      if (unreadNotifications.state.items.value.length > 0) {
        setAllNotificationsAsRead();
      }

      return;
    }

    if (shouldAddNewNotificationFromRealTimeUpdate(rawEvent)) {
      addNewNotification(rawEvent);
    }
  });

  return {
    areNotificationsUnmuted,
    notificationDrawerActiveTab,
    notificationUnreadCount,
    toggleNotificationMute,

    allNotifications,
    readNotifications,
    unreadNotifications,
  };
}

export function provideNotifications() {
  provide(symbol, NotificationsService());
}

/**
 * @type {NotificationsService}
 */
export function useNotifications() {
  return inject(symbol);
}
