<script setup>
import { useI18n } from '@/util';

const { t } = useI18n();
</script>
<template>
  <div class="text-body-1">
    <p>{{ t('Fire up new, pre-populated projects in seconds.') }}</p>
    <p class="pt-6">
      {{
        t('Use Templates for repeatable projects to cut down on manual work, save time, and scale your best practices.')
      }}
    </p>
  </div>
</template>
