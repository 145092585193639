import { useEventListener } from '@vueuse/core';

/**
 * Allows exchange of messages between tabs, windows, frames and iframes on the same origin.
 *
 * Uses `BroadcastChannel` if available and falls back to `localStorage`.
 */
export function useBroadcast(name, listener) {
  const key = `teamwork/useBroadcast/${name}`;

  // `BroadcastChannel` is supported only since Safari 15.4
  // We can remove the fallback to `localStorage` once we stop supporting the older Safari versions.
  if ('BroadcastChannel' in window) {
    const channel = new BroadcastChannel(key);

    if (typeof listener === 'function') {
      channel.addEventListener('message', (event) => {
        try {
          listener(event.data);
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error('useBroadcast (BroadcastChannel): Failed to handle a message', error);
        }
      });
      channel.addEventListener('messageerror', (event) => {
        // eslint-disable-next-line no-console
        console.error('useBroadcast (BroadcastChannel): Failed to parse a message', event);
      });
    }

    onScopeDispose(() => {
      channel.close();
    });

    return {
      broadcast(message) {
        try {
          channel.postMessage(message);
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error('useBroadcast (BroadcastChannel): Failed to broadcast a message', error);
        }
      },
    };
  }

  if (typeof listener === 'function') {
    useEventListener('storage', (event) => {
      if (event.storageArea === localStorage && event.key === key && typeof event.newValue === 'string') {
        try {
          listener(JSON.parse(event.newValue));
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error('useBroadcast (localStorage): Failed to handle a message', error);
        }
      }
    });
  }

  return {
    broadcast(message) {
      try {
        localStorage.setItem(key, JSON.stringify(message));
        localStorage.removeItem(key);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('useBroadcast (localStorage): Failed to broadcast a message', error);
      }
    },
  };
}
