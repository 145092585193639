const symbol = Symbol('useEmbeddedChat');

function loadStateFromCache() {
  let cache = localStorage.getItem('chat-vue');
  if (!cache) {
    return { isEnabled: false };
  }

  cache = JSON.parse(cache);
  return { isEnabled: cache.general.user.settings.embeddedChat === 'enabled' };
}

const embeddedChat = {
  // eslint-disable-next-line lightspeed/prefer-shallow-ref
  state: reactive(loadStateFromCache()),
};

export function provideEmbeddedChat() {
  provide(symbol, embeddedChat);
  return embeddedChat;
}

export function defineEmbeddedChatMethods(methods) {
  Object.assign(embeddedChat, methods);
}

export function updateEmbeddedChatState(state) {
  Object.assign(embeddedChat.state, state);
}

export function useEmbeddedChat() {
  return inject(symbol);
}
