export const PROOF_FEEDBACK_TYPE = Object.freeze({
  ANNOTATION: 'annotation',
  COMMENT: 'comment',
});

export const PROOF_FEEDBACK_VERSION = 2;

export const PROOF_STATE = Object.freeze({
  ACTIVE: 'active',
  ARCHIVED: 'archived',
  DELETED: 'deleted',
});

export const PROOF_STATUS = Object.freeze({
  APPROVED: 'approved',
  REJECTED: 'rejected',
  PENDING: 'pending',
});

export const PROOF_OBJECT_TYPE = Object.freeze({
  FILE: 'file',
  LINK: 'link',
});
