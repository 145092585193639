<script setup>
import LscMenu from '../../../components/overlay/menu/LscMenu.vue';
import LswTagPickerMenu from './LswTagPickerMenu.vue';
import { provideLswTagPicker } from './useLswTagPicker';

/**
 * @typedef {import('./useLswTagPicker').Tag} Tag
 */

const props = defineProps({
  /**
   * If provided, only global and project tags from the specified project will be shown.
   * @type {PropType<number>}
   */
  projectId: {
    type: Number,
    default: undefined,
  },
  /**
   * Whether to show the global and project-specific tags from all the projects (if "projectId" is not provided).
   * @type {PropType<Boolean>}
   */
  includeProjectTags: {
    type: Boolean,
    default: false,
  },
  /**
   * Whether the tag picker should close when a tag is selected.
   * @type {PropType<Boolean>}
   */
  closeOnSelect: {
    type: Boolean,
    default: false,
  },
});

/**
 * Whether the tag picker is open.
 * @type {PropType<Boolean>}
 */
const modelValue = defineModel({
  type: Boolean,
  default: false,
});

/**
 * The selected tags.
 * @type {PropType<Tag[]>}
 */
const selectedTags = defineModel('tags', {
  type: Array,
  default: () => [],
});

provideLswTagPicker({
  selectedTags,
  projectId: computed(() => props.projectId),
  includeProjectTags: computed(() => props.includeProjectTags),
});

function handleCloseOnSelect() {
  if (!props.closeOnSelect) {
    return;
  }
  modelValue.value = false;
}
</script>

<template>
  <LscMenu v-model="modelValue" :closeOnContentClick="false" offset="8">
    <template #activator="activator">
      <slot name="activator" v-bind="activator" />
    </template>
    <LswTagPickerMenu @close="handleCloseOnSelect">
      <template v-if="$slots.footer" #footer>
        <slot name="footer" />
      </template>
    </LswTagPickerMenu>
  </LscMenu>
</template>
