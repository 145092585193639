<script setup>
import { useI18n } from '@/util';

const { t } = useI18n();
</script>
<template>
  <div class="text-body-1">
    <p>{{ t('Set your project budget so that you can stay on track as you start logging your work and time.') }}</p>
    <p class="pt-6">{{ t('Track project progress with an hourly or fixed fee budget.') }}</p>
    <p class="pt-6">
      {{ t('Click on "Add a budget" within your project dashboard to set your project budget.') }}
    </p>
  </div>
</template>
