/** @type {InjectionKey<ReturnType<typeof import('@/api/user/useCurrentUserV1Loader').useCurrentUserV1Loader>>} */
const symbol = Symbol('useCurrentUser');

export function provideCurrentUserState(currentUserState) {
  provide(symbol, currentUserState);
  return currentUserState;
}

/** @type {() => ReturnType<typeof import('@/api/user/useCurrentUserV1Loader').useCurrentUserV1Loader>} */
export function useCurrentUserState() {
  return inject(symbol);
}

export function useCurrentUser() {
  return useCurrentUserState().item;
}
