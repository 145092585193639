<script setup>
// This isnt automatically imported by Vite, so we need to import it manually
import { VMenu } from 'vuetify/components';
import { provideKeyboardShortcut } from '@/util';
import { blockScrollStrategy } from './blockScrollStrategy';
import { LscMenuScrollStrategies } from './constants';
import { repositionScrollStrategy } from './repositionScrollStrategy';

defineOptions({
  inheritAttrs: false,
});

const props = defineProps({
  /**
   * Whether the menu should open on hover.
   * @type {PropType<boolean>}
   */
  openOnHover: {
    type: Boolean,
    default: false,
  },
  /**
   * Whether this menu should be prevented from closing.
   * @todo This needs to have a better name.
   * @type {PropType<boolean>}
   */
  preventParentMenuClose: {
    type: Boolean,
    default: false,
  },
  /**
   * An optional function which allows additional filtering of the click-outside events.
   * It gets a click event as the only parameter and if it returns `false`,
   * then the menu will remain open on click outside.
   */
  clickOutsideFilter: {
    type: Function,
    default: undefined,
  },
  /**
   * The scroll strategy to use. The default value is determined based on the `openOnHover` prop.
   * @type {PropType<typeof LscMenuScrollStrategies[number]>}
   */
  scrollStrategy: {
    type: String,
    default: undefined,
    validator: (value) => LscMenuScrollStrategies.includes(value),
  },
});

const emit = defineEmits(['afterEnter', 'afterLeave']);
const attrs = useAttrs();
const modelValue = defineModel({
  type: Boolean,
  default: false,
});

provideKeyboardShortcut(computed(() => modelValue.value && !props.openOnHover));

const scrollStrategy = computed(() => {
  if (props.scrollStrategy === 'reposition') {
    return repositionScrollStrategy;
  }
  if (props.scrollStrategy === 'block') {
    return blockScrollStrategy;
  }
  return props.openOnHover ? repositionScrollStrategy : blockScrollStrategy;
});

const menuRef = shallowRef();

// Handle click outside.
let clickOutsideEvent = null;
const modelValueFiltered = computed({
  get() {
    return modelValue.value;
  },
  set(value) {
    // Do not close the menu when a toast was clicked.
    if (clickOutsideEvent?.target.closest('.LscToast')) {
      return;
    }
    if (props.preventParentMenuClose && !clickOutsideEvent && value === false) {
      return;
    }
    if (
      clickOutsideEvent &&
      value === false &&
      typeof props.clickOutsideFilter === 'function' &&
      props.clickOutsideFilter(clickOutsideEvent) === false
    ) {
      return;
    }
    modelValue.value = value;
  },
});

function handleClickOutside(event) {
  clickOutsideEvent = event;
  nextTick(() => {
    clickOutsideEvent = null;
  });
}

function updateLocation() {
  menuRef.value?.updateLocation();
}

defineExpose({
  updateLocation,
  contentEl: computed(() => menuRef.value?.contentEl),
});
</script>

<template>
  <!-- eslint-disable-next-line lightspeed/no-restricted-component -->
  <VMenu
    ref="menuRef"
    v-model="modelValueFiltered"
    v-bind="attrs"
    :retainFocus="false"
    :openOnFocus="false"
    :openOnHover="openOnHover"
    :scrollStrategy="scrollStrategy"
    @click:outside="handleClickOutside"
    @afterEnter="emit('afterEnter', $event)"
    @afterLeave="emit('afterLeave', $event)"
  >
    <template #activator="activator">
      <slot name="activator" v-bind="activator" />
    </template>
    <slot name="default" />
  </VMenu>
</template>
