<script setup>
import { useI18n } from '@/util';

const { t } = useI18n();
</script>
<template>
  <div class="text-body-1">
    <p>
      {{
        t(
          'Set a billable rate for users on this project. As they log their time, this will be tracked against your budget.',
        )
      }}
    </p>
    <p class="pt-6">
      {{
        t(
          "On project completion, your project invoice will calculate automatically based on users' billable rates and logged time.",
        )
      }}
    </p>
  </div>
</template>
