<script setup>
const props = defineProps({
  openSource: {
    type: String,
    default: undefined,
  },
});

const emit = defineEmits(['deskMessageSent', 'subscriptionUpdated', 'cardDeclined']);

const AccountSubscriptionUpdateDialogPanel = defineAsyncComponent(
  () => import('./AccountSubscriptionUpdateDialogPanel.vue'),
);
</script>

<template>
  <LscDialog>
    <template #activator="activator"><slot name="activator" v-bind="activator" /></template>
    <template #default="{ close }">
      <AccountSubscriptionUpdateDialogPanel
        v-bind="props"
        @subscriptionUpdated="emit('subscriptionUpdated', $event)"
        @cardDeclined="emit('cardDeclined', $event)"
        @close="close"
        @deskMessageSent="emit('deskMessageSent', $event)"
      />
    </template>
  </LscDialog>
</template>
