import { exponentialBackOff } from './exponentialBackOff';

/**
 * Manages retry delays for processes that can fail and be retried.
 */
export function useBackOff({
  /**
   * A function which determines how long to wait in milliseconds before retrying.
   * It takes a 0-based retry attempt number and returns the required delay.
   */
  retryDelay = exponentialBackOff(),
  /**
   * The maximum number of retries. When it is reached, the back-off will remain `active` until `reset` is called.
   */
  maxRetries = Infinity,
} = {}) {
  let handle;
  const active = shallowRef(false);
  const retryAttempt = shallowRef(-1);

  function start() {
    clearTimeout(handle);
    active.value = true;
    retryAttempt.value += 1;

    if (retryAttempt.value < unref(maxRetries)) {
      handle = setTimeout(() => {
        active.value = false;
      }, retryDelay(retryAttempt.value));
    }
  }

  function reset() {
    clearTimeout(handle);
    active.value = false;
    retryAttempt.value = -1;
  }

  onUnmounted(reset);

  return {
    /**
     * Increments retryAttempt and starts the back-off.
     */
    start,
    /**
     * Stops the back-off and resets retryAttempt.
     */
    reset,
    /**
     * Indicates if a back-off is currently active.
     */
    active,
    /**
     * The 0-based retry attempt number. It is -1 when not retrying.
     */
    retryAttempt,
  };
}
