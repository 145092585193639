<script setup>
import * as Sentry from '@sentry/vue';
import { useEventListener } from '@vueuse/core';
import { useVersion } from '@/api';
import { useI18n } from '@/util';

const { t } = useI18n();
const { version, latestVersion } = useVersion();

const moduleLoadingFailed = shallowRef(false);

function reloadApp() {
  window.location.reload();
}

// Handle errors when loading app modules.
// We keep the error handling generic because app modules may fail to load for a variety of reasons,
// including connection problems on the customer end.
//
// Docs: https://vitejs.dev/guide/build#load-error-handling
//
// Note also that we're suspecting that some of the module loading issues might be related to new app version deployments.
// We still need to properly investigate this possibility, so we're going to temporarily log these issues to Sentry,
// as that might help us reveal some patterns and understand the scale of the problem.
//
// Related vite issue: https://github.com/vitejs/vite/issues/11804
useEventListener('vite:preloadError', (preloadErrorEvent) => {
  const message = `Got vite:preloadError - the app needs to be refreshed (Current version: ${version}, Latest version: ${latestVersion.value})`;

  // eslint-disable-next-line no-console
  console.error(message);

  Sentry.captureMessage(message, { level: 'warning', extra: { preloadErrorEvent } });

  moduleLoadingFailed.value = true;
});
</script>

<template>
  <LscAlert
    v-if="moduleLoadingFailed"
    variant="critical"
    layout="banner"
    :title="t('Loading failed')"
    :message="
      t('There was an issue loading a part of the app, which could affect some features. Please refresh the app.')
    "
  >
    <template #action>
      <LscAlertButton @click="reloadApp">
        {{ t('Refresh now') }}
      </LscAlertButton>
    </template>
  </LscAlert>
</template>
