<script setup>
import { useI18n } from '@/util';

const { t } = useI18n();
</script>
<template>
  <div class="text-body-1">
    <p>
      {{
        t(
          'When you add a new task list, you can select a template from the dropdown and view the tasks within that task list by hovering over the "Preview task list" option.',
        )
      }}
    </p>
  </div>
</template>
