<template>
  <span
    :class="[
      'size-[--lsds-c-button-size-xs] items-center justify-center rounded-full',
      'hidden group-hover/LscTableHeaderCell:inline-flex',
      'group-aria-[sort=ascending]/LscTableHeaderCell:inline-flex',
      'group-aria-[sort=descending]/LscTableHeaderCell:inline-flex',
    ]"
  >
    <LscIcon
      size="xs"
      icon="lsi-sort-ascending"
      class="hidden group-aria-[sort=ascending]/LscTableHeaderCell:inline-block"
    />
    <LscIcon
      size="xs"
      icon="lsi-sort-descending"
      class="hidden group-aria-[sort=descending]/LscTableHeaderCell:inline-block group-aria-[sort=none]/LscTableHeaderCell:inline-block"
    />
  </span>
</template>
