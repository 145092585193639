export const HELP_CENTER_PANEL = 'help-center';
export const HELP_CENTER_PANEL_WIDE = 'help-center-wide';
export const STATE_DEFAULT = 'default';
export const STATE_SEARCH_RESULTS = 'searchResults';
export const STATE_WEBINARS = 'webinars';
export const STATE_KNOWLEDGE_BASE_CATEGORIES = 'knowledgeBaseCategories';
export const STATE_KNOWLEDGE_BASE_SUBCATEGORIES_AND_ARTICLES = 'knowledgeBaseSubcategoriesAndArticles';
export const STATE_KNOWLEDGE_BASE_ARTICLE = 'knowledgeBaseArticle';
export const STATE_WHATS_NEW = 'whatsNew';
export const STATE_CONTACT_SUPPORT = 'contactSupport';
export const STATE_KEYBOARD_SHORTCUTS = 'keyboardShortcuts';
export const HELP_CENTER_COMMON_METRICS = ['plan_name', 'user_role', 'page'];
export const MAX_UPLOAD_SIZE = 2 * 1024 * 1024;
export const VALID_STATES = [
  STATE_DEFAULT,
  STATE_CONTACT_SUPPORT,
  STATE_WEBINARS,
  STATE_WHATS_NEW,
  STATE_KEYBOARD_SHORTCUTS,
  STATE_SEARCH_RESULTS,
  STATE_KNOWLEDGE_BASE_ARTICLE,
];
export const HELP_CENTER_LOCAL_STORAGE_KEY = 'teamwork/useHelpCenter/openToState';

// Static check - fast, no sub-routes or dynamic URLs param(s) supported
export const RECOMMENDED_ARTICLES_STATIC_MAPPING = {
  '/planning/workload': 'https://support.teamwork.com/projects/workload/workload-planner-overview',
  '/clients': 'https:/support.teamwork.com/projects/clients/clients-view',
  '/reports/health': 'https://support.teamwork.com/projects/reports/using-project-health-reports',
  '/home/work': 'https://support.teamwork.com/projects/home/using-the-my-work-table-view',
  '/projects/list': 'https://support.teamwork.com/projects/getting-started/adding-a-project',
  '/planning/schedule/projects': 'https://support.teamwork.com/projects/schedule/resource-scheduling-overview',
  '/planning/schedule/people': 'https://support.teamwork.com/projects/schedule/resource-scheduling-overview',
  '/planning/overview': 'https://support.teamwork.com/projects/planning/using-the-overview-area',
  '/planning/portfolio/boards': 'https://support.teamwork.com/projects/portfolio/create-portfolio-boards',
  '/budgets': 'https://support.teamwork.com/projects/billing/user-rates',
  '/reports/gallery': 'https://support.teamwork.com/projects/reports/creating-a-custom-report',
  '/reports/utilization': 'https://support.teamwork.com/projects/reports/using-utilization-reports',
  '/reports/time': 'https://support.teamwork.com/projects/reports/using-time-reports',
  '/reports/plannedvsactual/tasks': 'https://support.teamwork.com/projects/reports/planned-vs-actual-tasks',
  '/reports/plannedvsactual/milestones':
    'https://support.teamwork.com/projects/reports/using-planned-vs-actual-reports',
  // '/reports/tasktime': '',     <-- New feature, no help docs yet
  // '/reports/projectstime': '', <-- New feature, no help docs yet
  // '/reports/usertime': '',     <-- New feature, no help docs yet
  '/time/user': 'https://support.teamwork.com/projects/time-tracking/my-timesheet',
  '/time/company': 'https://support.teamwork.com/projects/time-tracking/company-timesheet',
  '/time/all': 'https://support.teamwork.com/projects/using-teamwork/all-time',
  '/proofs': 'https://support.teamwork.com/projects/proofing/create-a-proof',
  '/people/people': 'https://support.teamwork.com/projects/getting-started/inviting-people-to-your-teamwork-site',
  '/people/teams': 'https://support.teamwork.com/projects/teams/adding-a-team',
  '/people/statuses': 'https://support.teamwork.com/projects/using-teamwork/status-updates',
};

// Regex check - slower, sub-routes and dynamic URLs param(s) supported
export const RECOMMENDED_ARTICLES_REGEX_MAPPING = [
  [/^\/clients\/\d+\/overview$/, 'https://support.teamwork.com/projects/clients/clients-overview'],
  [
    /^\/projects\/templates\/projects/,
    'https://support.teamwork.com/projects/project-templates/creating-project-templates',
  ],

  [/^\/projects\/\d+\/tasks\/board$/, 'https://support.teamwork.com/projects/board-view/using-board-view-for-tasks'],
  [/^\/projects\/\d+\/tasks\/table$/, 'https://support.teamwork.com/projects/table-view/adding-tasks-in-table-view'],
  [/^\/projects\/\d+\/tasks\/list$/, 'https://support.teamwork.com/projects/tasks/tasks-list-view'],
  [/^\/projects\/\d+\/overview\/activity$/, 'https://support.teamwork.com/projects/dashboard/viewing-project-activity'],
  [
    /^\/projects\/\d+\/overview\/summary$/,
    'https://support.teamwork.com/projects/dashboard/customizing-your-project-summary-settings',
  ],
  [/^\/projects\/\d+\/files$/, 'https://support.teamwork.com/projects/files/uploading-files'],
  [/^\/projects\/\d+\/time$/, 'https://support.teamwork.com/projects/project-sections/logging-time-in-the-time-tab'],
  [/^\/projects\/\d+\/forms$/, 'https://support.teamwork.com/projects/forms/creating-forms-on-your-projects'],
  [/^\/projects\/\d+\/finance\/budgets$/, 'https://support.teamwork.com/projects/project-budgets/retainer-budgets'],
  [/^\/projects\/\d+\/finance\/rates$/, 'https://support.teamwork.com/projects/billing/user-rates'],
  [/^\/projects\/\d+\/finance\/billing/, 'https://support.teamwork.com/projects/billing/adding-an-invoice'],
  [/^\/projects\/\d+\/milestones/, 'https://support.teamwork.com/projects/getting-started/milestones-overview'],
  [/^\/projects\/\d+\/messages/, 'https://support.teamwork.com/projects/messages/adding-a-message'],
  [/^\/projects\/\d+\/tasks\/gantt$/, 'https://support.teamwork.com/projects/glossary/gantt-explained'],
  [/^\/projects\/\d+\/comments$/, 'https://support.teamwork.com/projects/getting-started/comments-overview'],
  [/^\/projects\/\d+\/notebooks/, 'https://support.teamwork.com/projects/notebooks/creating-a-notebook'],
  [/^\/projects\/\d+\/links/, 'https://support.teamwork.com/projects/project-sections/adding-a-link'],
  [/^\/projects\/\d+\/people/, 'https://support.teamwork.com/projects/getting-started/adding-people-to-a-project'],
  [/^\/projects\/\d+\/settings/, 'https://support.teamwork.com/projects/project-sections/project-general-settings'],
  [/^\/projects\/\d+\/proofs$/, 'https://support.teamwork.com/projects/proofing/create-a-proof'],
];
