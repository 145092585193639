<script setup>
import { useExperimentG07 } from '@/api';
import { useI18n } from '@/util';
import OnboardingWizardCommonChoice from '../common/OnboardingWizardCommonChoice.vue';
import OnboardingWizardCommonStep from '../common/OnboardingWizardCommonStep.vue';
import { STEP_SIGNUP_SURVEY } from '../constants';
import { useOnboardingWizardTracking } from '../useOnboardingWizardTracking';

const props = defineProps({
  nextButtonText: {
    type: String,
    required: true,
  },
  state: {
    type: Object,
    required: true,
  },
});

/**
 * Triggers sending step result data back to main state and move to next step
 *
 * @param {boolean} hasChanged this step has changed data compared to the history. compare with `stateData` for the step
 * @param {object} stepData step result data
 * @param {object} [stateData] full state data - optional if you edited anything in the full state
 */
const emit = defineEmits(['nextStep']);

/** use stateData to check if there's old values for this state and if they have changed */
const { [STEP_SIGNUP_SURVEY]: stateData } = props.state;

const { t } = useI18n();
const { trackExpG07SignupSourceSubmitted } = useExperimentG07();
const { previousPage } = useOnboardingWizardTracking();

const sources = [
  {
    id: 0,
    icon: 'lsi-share',
    description: t('Social Media'),
    signupType: 'social_media',
  },
  {
    id: 1,
    icon: 'lsi-group',
    description: t('Friend / Colleague'),
    signupType: 'friend_colleague',
  },
  {
    id: 2,
    icon: 'lsi-search',
    description: t('Search Engine'),
    signupType: 'search_engine',
  },
  {
    id: 3,
    icon: 'lsi-file-document',
    description: t('Blog / Online article'),
    signupType: 'online_article',
  },
  {
    id: 4,
    icon: 'lsi-teams-bullhorn',
    description: t('Podcast / Radio'),
    signupType: 'podcast',
  },
  {
    id: 5,
    icon: 'lsi-profile-site-owner',
    description: t('Software site'),
    signupType: 'review_site',
  },
  {
    id: 6,
    icon: 'lsi-business',
    description: t('Consultant'),
    signupType: 'consultant',
  },
  {
    id: 7,
    icon: 'lsi-webinars',
    description: t('Video / Webinar'),
    signupType: 'youtube',
  },
  {
    id: 8,
    icon: 'lsi-email',
    description: t('Industry e-mail / Publication'),
    signupType: 'industry_publication',
  },
  {
    id: 9,
    icon: 'lsi-date',
    description: t('Event'),
    signupType: 'event',
  },
  {
    id: 10,
    icon: 'lsi-undo',
    description: t('Used in previous workplace'),
    signupType: 'used_previously',
  },
  {
    id: 11,
    icon: 'lsi-website',
    description: t('Other'),
    signupType: 'other',
  },
];

const selectedItem = shallowRef(stateData?.selectedItem ?? undefined);
const userInput = shallowRef('');

function selectSurveyItem() {
  if (selectedItem.value !== 11) {
    userInput.value = '';
  }
}

function hasChanged() {
  return !stateData || stateData?.selectedItem !== selectedItem.value;
}

function nextStep() {
  const selectedItemType = sources.find((source) => source.id === selectedItem.value)?.signupType;
  const signupType =
    userInput.value?.length > 0
      ? [selectedItemType, userInput.value?.replace(/[^A-Z0-9]+/gi, '_')].join(' | ')
      : selectedItemType;
  emit('nextStep', hasChanged(), {
    selectedItem: selectedItem.value,
    selectedItemType,
    userInput: userInput.value.trim(),
  });
  trackExpG07SignupSourceSubmitted(previousPage.value, signupType);
}
</script>
<template>
  <OnboardingWizardCommonStep
    :title="t('How did you hear about {teamwork}?', { teamwork: 'Teamwork.com' })"
    :description="t('Please select only one option from the options below')"
  >
    <LscChoiceList v-model="selectedItem" :multiple="false" :horizontal="true" class="flex-wrap gap-4">
      <OnboardingWizardCommonChoice
        v-for="source in sources"
        :key="source.id"
        :label="source.description"
        :icon="source.icon"
        :value="source.id"
        hasCheck
        class="max-w-64 basis-1/4"
        @click="selectSurveyItem"
      />
    </LscChoiceList>

    <VTextField
      v-if="selectedItem === 11"
      v-model="userInput"
      :autofocus="true"
      :label="t('Other')"
      :placeholder="t('e.g. Referral, advertisement, etc.')"
      prependInnerIcon="lsi-website"
      class="mt-6"
      @blur="userInput = userInput.trim()"
    />
    <slot name="underFieldsButtons" :nextButtonText="nextButtonText" :nextStep="nextStep" />
  </OnboardingWizardCommonStep>
</template>
