import { createTV } from 'tailwind-variants';

export const tv = createTV({
  twMergeConfig: {
    classGroups: {
      'font-size': [
        {
          text: [
            'body-1',
            'body-2',
            'body-3',
            'button-1',
            'button-2',
            'h1',
            'h2',
            'h3',
            'h4',
            'h5',
            'h6',
            'label',
            'overline',
            'subtitle-1',
            'subtitle-2',
          ],
        },
      ],
    },
  },
});
