<script setup>
import { useI18n } from '@/util';
import FormDrawerEmptyFormsAll from './FormDrawerEmptyFormsAll.svg';

const props = defineProps({
  searchTerm: {
    type: String,
    default: '',
  },
});

const { t } = useI18n();

const title = computed(() => {
  if (!props.searchTerm) {
    return t('No forms yet');
  }
  return t('No matching forms');
});

const message = computed(() => {
  if (!props.searchTerm) {
    return t('All your forms will be shown here for fast access. You can add new forms in your projects');
  }
  return t('Please try again with a different term');
});
</script>
<template>
  <LscEmptyState size="sm" class="grow" :title="title" :message="message">
    <template v-if="!searchTerm" #image>
      <FormDrawerEmptyFormsAll />
    </template>
  </LscEmptyState>
</template>
