<script setup>
import { useI18n } from '@/util';

const { t } = useI18n();
</script>
<template>
  <div class="text-body-1">
    <p>
      {{
        t(
          "When you have a number of projects on your plate it's easy to lose track of how things are going. The reports available to you will depend on your subscription plan.",
        )
      }}
    </p>
  </div>
</template>
