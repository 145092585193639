// Returns a `computed` which provides the number of items which are currently being loaded.
export function useLoadingCount({
  // The `count` for `useListLoader`.
  count,
  // The `state` from `useListLoader`.
  state: { items, hasMore },
  // The max value to return.
  maxLoadingCount = 3,
}) {
  return computed(() => {
    const target = count.value - items.value.length;
    const min = 0;
    const max = hasMore.value ? unref(maxLoadingCount) : 0;
    return Math.max(min, Math.min(max, target));
  });
}
