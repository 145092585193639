<script setup>
import useVuelidate from '@vuelidate/core';
import { useCurrentAccount, useExperimentA62 } from '@/api';
import { capitalizeFirstLetter, useI18n, useValidators } from '@/util';
import OnboardingWizardCommonStep from '../common/OnboardingWizardCommonStep.vue';
import OnboardingWizardCommonPreview from '../common/preview/OnboardingWizardCommonPreview.vue';
import { PREVIEW_TAB_BOARD, PREVIEW_TAB_LIST, PREVIEW_TAB_TABLE, STEP_ADD_CLIENT } from '../constants';

const props = defineProps({
  nextButtonText: {
    type: String,
    required: true,
  },
  state: {
    type: Object,
    required: true,
  },
});

/**
 * Triggers sending step result data back to main state and move to next step
 *
 * @param {boolean} hasChanged this step has changed data compared to the history. compare with `stateData` for the step
 * @param {object} stepData step result data
 * @param {object} [stateData] full state data - optional if you edited anything in the full state
 */
const emit = defineEmits(['nextStep']);

/** use stateData to check if there's old values for this state and if they have changed */
const { [STEP_ADD_CLIENT]: stateData } = props.state;

const { t } = useI18n();
const { requiredIf, helpers } = useValidators();
const { trackAddClientStepEvents } = useExperimentA62();
const account = useCurrentAccount();

const clientName = shallowRef(stateData?.name ?? '');
const clientProject = shallowRef(stateData?.clientProject !== undefined ? Number(stateData.clientProject) : 1);
const clientProjectPendoValue = computed(() => (clientProject.value === 1 ? 'client' : 'internal'));
const inputEl = shallowRef(null);

const rules = {
  clientName: {
    required: helpers.withMessage(
      t("Please enter your client's company name"),
      requiredIf(() => clientProject.value === 1),
    ),
  },
};
const v$ = useVuelidate(rules, { clientName }, { $autoDirty: false });

function nextStep() {
  v$.value.$touch();
  if (!v$.value.$error) {
    emit('nextStep', clientName.value !== stateData?.name, {
      name: clientName.value,
      clientProject: Boolean(clientProject.value),
    });
  }
}

watch(clientName, (newValue) => {
  clientName.value = capitalizeFirstLetter(newValue);
});

watch(clientProject, async (newValue, oldValue) => {
  trackAddClientStepEvents('project_type_selected', clientProjectPendoValue.value);

  if (!newValue) {
    clientName.value = account.value.companyName;
  }
  if (!oldValue && newValue) {
    clientName.value = '';
    await nextTick();
    inputEl.value.focus();
  }
});

onMounted(() => {
  if (!clientName.value && clientProject.value === 1) {
    inputEl.value.focus();
  }
});
</script>

<template>
  <OnboardingWizardCommonStep
    :title="t('What type of project are you working on?')"
    :description="t('Your first project will be set up under the company you select here.')"
  >
    <LscChoiceList v-model="clientProject" :multiple="false" mandatory horizontal class="mb-4">
      <LscChoice hasCheck icon="lsi-assignees" :value="1" :label="t('Client')" />
      <LscChoice hasCheck icon="lsi-project" :value="0" :label="t('Internal')" />
    </LscChoiceList>

    <label v-if="clientProject === 1">{{ t("Add your client's company name") }}</label>
    <label v-else>{{ t('Your first project will be set up under your company:') }}</label>
    <VTextField
      ref="inputEl"
      v-model="clientName"
      :label="t('Client name')"
      :placeholder="t('e.g. {company}', { company: 'Acme Inc' })"
      :errorMessages="v$.clientName.$errors.map((error) => error.$message)"
      :maxLength="255"
      :autoFocus="true"
      :disabled="clientProject === 0"
      class="my-2"
      @blur="clientName = clientName.trim()"
    />

    <slot name="underFieldsButtons" :nextButtonText="nextButtonText" :nextStep="nextStep" />
    <template #right>
      <OnboardingWizardCommonPreview
        :clientCompanyName="clientName"
        :preselectedTab="PREVIEW_TAB_TABLE"
        :tabs="[PREVIEW_TAB_TABLE, PREVIEW_TAB_BOARD, PREVIEW_TAB_LIST]"
        :tabsClickable="false"
      />
    </template>
  </OnboardingWizardCommonStep>
</template>
