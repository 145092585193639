import { useItemLoader } from '../base/useItemLoader';
import { useCheckoutUtils } from './useCheckoutUtils';

function responseToItem({ data }) {
  return data.ref;
}

/**
 * Loads the checkout reference.
 */
export function useCheckoutRefV1Loader({ params } = {}) {
  const { subscriptionUrl } = useCheckoutUtils();
  const { state } = useItemLoader({
    url: subscriptionUrl,
    params,
    responseToItem,
  });

  return state;
}
