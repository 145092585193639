<script setup>
import { useI18n } from '@/util';
import { useReportCustomBuilder } from '../useReportCustomBuilder.js';

const props = defineProps({
  column: {
    type: Object,
    required: true,
  },
  usedInPopover: {
    type: Boolean,
    default: false,
  },
  allowDrag: {
    type: Boolean,
    default: false,
  },
  hasMaxNumberColumns: {
    type: Boolean,
    default: false,
  },
  canEdit: {
    type: Boolean,
    default: false,
  },
  enabled: {
    type: Boolean,
    default: false,
  },
  showToggle: {
    type: Boolean,
    default: true,
  },
});

const emit = defineEmits(['toggleColumn', 'edit']);
const { t } = useI18n();
const { currentReport, clearCharts } = useReportCustomBuilder();
const disableButton = computed(() => props.hasMaxNumberColumns && !props.enabled);

const usedInChart = computed(() => {
  const { column } = props;
  const { charts } = currentReport.value;

  if (clearCharts.value || !column || !charts?.length) {
    return false;
  }

  const targetName = column.isCustomField ? String(column.id) : column.rawName;

  return charts.some((chart) => chart.series?.some((series) => series.name === targetName));
});

function toggleColumn() {
  if (disableButton.value || !props.showToggle) {
    return;
  }
  emit('toggleColumn', props.column);
}
</script>
<template>
  <div
    v-LsdTooltip="hasMaxNumberColumns ? t('You\'ve reached the maximum number of 20 columns') : undefined"
    class="group/column flex h-10 w-full shrink-0 items-center rounded-md p-2 text-body-1"
    :class="[{ 'hover:cursor-pointer hover:bg-hover': showToggle, 'border border-default': !usedInPopover }]"
    :draggable="allowDrag"
    @click.stop.prevent="toggleColumn"
  >
    <LscIcon v-if="allowDrag" icon="lsi-drag" size="sm" />
    <LscIcon v-if="column.icon" :icon="column.icon" class="mx-2 text-icon-subtle" size="sm" />
    <LscOverflowEllipsis>{{ column.name }}</LscOverflowEllipsis>
    <span class="ml-auto" />
    <LscIconButton
      v-if="canEdit"
      v-LsdTooltip="t('Edit custom field')"
      :ariaLabel="t('Edit custom field')"
      icon="lsi-edit"
      size="sm"
      class="invisible mr-1 !text-icon-subtle group-hover/column:visible"
      @click.stop.prevent="emit('edit', column.id)"
    />

    <LscIcon
      v-if="usedInChart"
      v-LsdTooltip="t('Visualised in a chart. Removing it will also remove it from chart(s)')"
      icon="lsi-insights"
      size="sm"
      class="text-icon-subtle"
    />
    <LscSwitch
      v-if="showToggle"
      v-LsdTooltip="enabled ? t('Remove column') : t('Add column')"
      :modelValue="enabled"
      :ariaLabel="enabled ? t('Remove column') : t('Add column')"
      :disabled="disableButton"
      size="md"
      class="ml-1"
      @update:modelValue="toggleColumn"
    />
    <LscIconButton
      v-else
      v-LsdTooltip="t('Remove column')"
      :ariaLabel="t('Remove column')"
      icon="lsi-close"
      size="sm"
      @click.stop.prevent="emit('toggleColumn', props.column)"
    />
  </div>
</template>
