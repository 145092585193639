import { useItemLoader } from '../base/useItemLoader';
import { useItemToListLoader } from '../base/useItemToListLoader';
import { useRealTimeUpdates } from '../base/useRealTimeUpdates';

function responseToItem(response) {
  const { productOutcomes } = response.data;
  return productOutcomes || [];
}

export function useUserProductOutcomesV3Loader({ params, userId: _userId, count }) {
  const userId = shallowRef(_userId);

  const url = computed(
    () => Number.isInteger(userId.value) && `/projects/api/v3/users/${userId.value}/productoutcomes.json`,
  );

  const { state, refresh } = useItemLoader({
    url,
    params,
    responseToItem,
  });

  useRealTimeUpdates((event) => {
    if (event.type === 'userproductoutcome') {
      refresh();
    }
  });

  return useItemToListLoader(state, count);
}
