<script setup>
import { InvoiceMoveItemsDialogOpenSources } from './constants';

defineProps({
  projectId: {
    type: Number,
    default: null,
  },
  timelogs: {
    type: Array,
    default: undefined,
  },
  expenses: {
    type: Array,
    default: undefined,
  },
  /**
   * @type {PropType<typeof InvoiceMoveItemsDialogOpenSources[number]>}
   */
  openSource: {
    type: String,
    default: undefined,
    validator: (value) => InvoiceMoveItemsDialogOpenSources.includes(value),
  },
});

const emit = defineEmits(['close', 'save']);

const modelValue = defineModel({ type: Boolean, default: false });

const FinanceInvoiceMoveExpenseOrTimelogDialogPanel = defineAsyncComponent(
  () => import('./FinanceInvoiceMoveExpenseOrTimelogDialogPanel.vue'),
);
</script>

<template>
  <LscDialog v-model="modelValue">
    <template #activator="activator">
      <slot name="activator" v-bind="activator" />
    </template>
    <template #default="{ close }">
      <FinanceInvoiceMoveExpenseOrTimelogDialogPanel
        :closing="!modelValue"
        :projectId="projectId"
        :timelogs="timelogs"
        :expenses="expenses"
        :openSource="openSource"
        @close="
          close();
          emit('close');
        "
        @save="emit('save')"
      />
    </template>
  </LscDialog>
</template>
