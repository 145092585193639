import { DateTime } from 'luxon';
import { normalizeLockdown } from '../lockdown/normalizeLockdown';

/**
 * Normalizes tasklist defaults
 * @param {*} newTaskDefaults
 */
export function normalizeTasklistDefaults({
  newTaskDefaults,
  users = {},
  teams = {},
  companies = {},
  customfieldTasks = {},
  customfields = {},
  columns = {},
  lockdowns = {},
  tags = {},
  workflowStages = [],
}) {
  const defaultTask = { ...newTaskDefaults };

  // set up assignees
  defaultTask.defaultAssignees = newTaskDefaults.assignees.map((assignee) => {
    if (assignee.type === 'users') {
      return { ...assignee, entityType: 'user' };
    }
    if (assignee.type === 'teams') {
      return { ...assignee, entityType: 'team' };
    }
    if (assignee.type === 'companies') {
      return { ...assignee, entityType: 'company' };
    }
    return assignee;
  });

  // Followers are not included in the Tasklist API and are fetched at a component level
  defaultTask.defaultFollowers = {
    changeFollowers: newTaskDefaults.changeFollowers,
    commentFollowers: newTaskDefaults.commentFollowers,
  };

  // setup task default dates
  if (newTaskDefaults.startDate && newTaskDefaults.startDateOffset >= 0) {
    defaultTask.defaultStartDate = DateTime.now().plus({ days: newTaskDefaults.startDateOffset }).startOf('day');
  }

  if (newTaskDefaults.dueDate && newTaskDefaults.dueDateOffset >= 0) {
    defaultTask.defaultDueDate = DateTime.now().plus({ days: newTaskDefaults.dueDateOffset }).endOf('day');
  }

  defaultTask.tagIds ??= [];
  defaultTask.tags = defaultTask.tagIds?.map((id) => tags[id]) || [];

  // board column
  if (newTaskDefaults.column) {
    defaultTask.column = columns[newTaskDefaults.column.id];
  }

  defaultTask.customfieldTasks = Object.values(customfieldTasks);
  // Populate custom field with its corresponding custom field task values
  defaultTask.customfields = defaultTask.customfieldTasks.map((customfieldTask) => {
    return {
      ...customfields[customfieldTask.customfieldId],
      value: customfieldTask.value,
      customfieldId: customfieldTask.customfieldId,
      urlTextToDisplay: customfieldTask.urlTextToDisplay,
    };
  });

  if (newTaskDefaults.lockdown) {
    const lockdown = normalizeLockdown({ lockdown: lockdowns[newTaskDefaults.lockdown.id], users, teams, companies });
    defaultTask.lockdown = lockdown.grantAccessTo ?? {};
  }

  // Privacy workaround
  defaultTask.isPrivate = newTaskDefaults?.isPrivate === 1;
  defaultTask.lockdownId = newTaskDefaults?.lockdown?.id || null;

  // Workflows
  defaultTask.workflowStages =
    newTaskDefaults?.workflowStages?.map((wf) => ({
      ...wf,
      stage: workflowStages[wf.stageId],
    })) || [];

  return defaultTask;
}
