/**
 * Constants, computed properties and functions related to Experiment A-23-46
 * https://app.launchdarkly.com/default/production/features/projects-lightspeed-list-view/targeting
 *  - re-using old flag for this that's already in place
 * https://digitalcrew.teamwork.com/spaces/growth/page/48947-a-23-46-lightspeed-tasks-list-view-for-trials-rerun
 */

import { useFeatures } from '../features/useFeatures';
import { usePendo } from '../features/usePendo';

export function useExperimentA46() {
  const { lightspeedListViewEnabled } = useFeatures();
  const { trackExperimentInPendo } = usePendo();
  const ignoreInExperimentOnRuleMatch = true;
  const { launchDarklyFlagKey, defaultValue } = lightspeedListViewEnabled;

  function trackExperimentA46() {
    trackExperimentInPendo({
      launchDarklyFlagKey,
      defaultValue,
      ignoreInExperimentOnRuleMatch,
    });
  }
  const isExpA46Variation = computed(() => lightspeedListViewEnabled.value);

  return {
    isExpA46Variation,
    trackExperimentA46,
  };
}
