<script setup>
import { useFeatures, useNotifications, useNotificationsActions } from '@/api';
import { AppShellDrawerPanel, AppShellDrawerTitle, useAppShellSidebar } from '@/appShell';
import { LsaNotificationsMuted } from '@/designsystem';
import { useI18n } from '@/util';
import NotificationDrawerRead from './NotificationDrawerRead.vue';
import NotificationDrawerUnread from './NotificationDrawerUnread.vue';

const hasNotificationsBeenInitialized = shallowRef(false);

const { t } = useI18n();
const { activeDrawerPanel } = useAppShellSidebar();
const { shouldReduceElasticsearchLoad } = useFeatures();
const { markAllNotificationsAsRead } = useNotificationsActions();

const { notificationUnreadCount, areNotificationsUnmuted, notificationDrawerActiveTab, toggleNotificationMute } =
  useNotifications();

watch(
  activeDrawerPanel,
  (val) => {
    if (val === 'notifications' && !hasNotificationsBeenInitialized.value) {
      hasNotificationsBeenInitialized.value = true;
    }
  },
  { immediate: true },
);
</script>

<template>
  <AppShellDrawerPanel
    v-if="hasNotificationsBeenInitialized"
    v-show="activeDrawerPanel === 'notifications'"
    data-identifier="side-nav-notifications-panel"
  >
    <template #header>
      <AppShellDrawerTitle :title="t('Notifications')">
        <template #prepend-icons="{ buttonSize }">
          <LscIconButton
            v-if="notificationUnreadCount && !shouldReduceElasticsearchLoad"
            v-LsdTooltip="t('Mark all as read')"
            :size="buttonSize"
            :ariaLabel="t('Mark all as read')"
            icon="lsi-mark-all-read"
            data-identifier="side-nav-notifications-options-mark-all-as-read"
            @click="markAllNotificationsAsRead"
          />

          <LscIconButton
            v-LsdTooltip="areNotificationsUnmuted ? t('Mute notifications') : t('Unmute notifications')"
            :size="buttonSize"
            :ariaLabel="areNotificationsUnmuted ? t('Mute notifications') : t('Unmute notifications')"
            :icon="areNotificationsUnmuted ? 'lsi-notifications' : 'lsi-mute-notification'"
            data-identifier="side-nav-notifications-options-mark-all-as-read"
            @click="toggleNotificationMute"
          />
        </template>
      </AppShellDrawerTitle>
    </template>

    <template #content>
      <div class="m-0 flex h-full flex-col overflow-hidden p-0">
        <template v-if="areNotificationsUnmuted">
          <div class="grow-0 px-6">
            <VTabs v-bind="VTabsUnderline" v-model="notificationDrawerActiveTab">
              <VTab value="unread" data-identifier="side-nav-tab-unread" tabindex="0">{{ t('Unread') }}</VTab>
              <VTab v-if="!shouldReduceElasticsearchLoad" value="read" data-identifier="side-nav-tab-read" tabindex="0">
                {{ t('Read') }}
              </VTab>
            </VTabs>
          </div>

          <KeepAlive>
            <NotificationDrawerUnread v-if="notificationDrawerActiveTab === 'unread'" />
            <NotificationDrawerRead
              v-else-if="notificationDrawerActiveTab === 'read' && !shouldReduceElasticsearchLoad"
            />
          </KeepAlive>
        </template>

        <template v-else>
          <LscEmptyState class="h-full" :title="t('Notifications muted')" :message="t('You have muted notifications')">
            <template #image>
              <LsaNotificationsMuted />
            </template>

            <template #actions>
              <LscButton variant="primary" @click="toggleNotificationMute">{{ t('Unmute') }}</LscButton>
            </template>
          </LscEmptyState>
        </template>
      </div>
    </template>
  </AppShellDrawerPanel>
</template>
