<script setup>
import { useI18n } from '@/util';

const { t } = useI18n();
</script>
<template>
  <div class="text-body-1">
    <p>
      {{ t('Take the manual labour out of gathering the information you need for projects by using Intake Forms.') }}
    </p>
    <p class="pt-6">
      {{
        t(
          'Send a form link to your customers to fill out, and once the form is completed, a task is created with all the information you need.',
        )
      }}
    </p>
  </div>
</template>
