import { DateTime } from 'luxon';

export function normalizeMilestoneStatus(milestone) {
  const today = DateTime.now();
  if (milestone.deadline?.hasSame(today, 'day') && milestone.status !== 'completed') {
    return 'today';
  }
  return milestone.status;
}

export function getOptimisticStatus(milestone) {
  if (milestone.status === 'incomplete') {
    const today = DateTime.now();
    /* eslint-disable no-param-reassign */
    if (milestone.deadline?.hasSame(DateTime.now(), 'day')) {
      milestone.status = 'today';
    } else if (milestone.deadline < today) {
      milestone.status = 'late';
    } else {
      milestone.status = 'upcoming';
    }
    /* eslint-enable no-param-reassign */
  }
  return milestone;
}
