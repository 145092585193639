<script setup>
import { LscProgressBarSizes } from './constants';

const props = defineProps({
  /**
   * The percentage to show.
   * @type {PropType<Number>}
   */
  percentage: {
    type: Number,
    default: 0,
    validator: (v) => v >= 0 && v <= 100 && Number.isInteger(v),
  },

  /**
   * The color of the progress bar.
   * @type {PropType<String>}
   */
  valueColor: {
    type: String,
    default: 'var(--lsds-a-color-action-primary-default)',
  },
  /**
   * The size of the progress bar.
   * @type {PropType<typeof LscProgressBarSizes[number]>}
   */
  size: {
    type: String,
    default: 'lg',
    validator: (v) => LscProgressBarSizes.includes(v),
  },
});

const id = useId();

const progressBarVariantStyleConfigs = tv({
  base: 'inline-flex items-center justify-between gap-2 text-body-2',
  slots: {
    barWrapper: 'grow rounded-full bg-surface-default',
    bar: 'h-full w-[--lsc-progress-bar-width] rounded-full bg-[color:--lsc-progress-bar-value-color] transition',
    slots: 'h-0 align-baseline leading-0',
  },
  variants: {
    size: {
      sm: { bar: 'h-1' },
      md: { bar: 'h-2' },
      lg: { bar: 'h-3' },
    },
  },
});

const slots = useSlots();
const classes = computed(() =>
  progressBarVariantStyleConfigs({
    size: props.size,
  }),
);

const prependId = computed(() => (slots.prepend ? `${id}-prepend` : undefined));
const appendId = computed(() => (slots.append ? `${id}-append` : undefined));
const labelledBy = computed(() => [prependId.value, appendId.value].filter(Boolean).join(' '));
const progressPercentage = computed(() => `${props.percentage}%`);

const barVariables = computed(() => ({
  '--lsc-progress-bar-width': progressPercentage.value,
  '--lsc-progress-bar-value-color': props.valueColor,
}));
</script>
<template>
  <div
    :class="classes.base()"
    role="progressbar"
    :aria-valuemin="0"
    :aria-valuemax="100"
    :aria-valuenow="percentage"
    :aria-labelledBy="labelledBy"
  >
    <span v-if="$slots.prepend" :id="prependId" :class="classes.slots()">
      <slot name="prepend" />
    </span>
    <div :class="classes.barWrapper()">
      <div :class="classes.bar()" :style="barVariables" />
    </div>
    <span v-if="$slots.append" :id="appendId" :class="classes.slots()">
      <slot name="append" />
    </span>
  </div>
</template>
