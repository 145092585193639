<script setup>
import { useI18n } from '@/util';

const { t } = useI18n();
</script>
<template>
  <div class="text-body-1">
    <p>
      {{ t('Default settings: Everyone on the project is notified of the message.') }}
    </p>
    <p class="pt-6">
      {{ t('You can opt out to send the message to specific people instead by clicking “Edit” on the right.') }}
    </p>
  </div>
</template>
