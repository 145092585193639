<script setup>
import { useI18n } from '@/util';

const { t } = useI18n();
</script>
<template>
  <div class="text-body-1">
    <p>
      {{
        t(
          'Time tracking helps you see how long individual pieces of work are taking, helping you manage your own time and plan work for your team.',
        )
      }}
    </p>
    <p class="pt-6">
      {{
        t(
          '{teamworks} time tracking features collect real time data so you can plan ahead and keep an eye on what’s happening in real-time.',
          { teamworks: "Teamwork's" },
        )
      }}
    </p>
  </div>
</template>
