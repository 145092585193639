import * as TOKENS from '@teamwork/lightspeed-design-tokens';
import { hasBadContrast } from 'color2k';

/**
 * This returns the most accessible text color for a given background color.
 * @param {string} backgroundColor - This must be a HEX color. CSS variables are not supported.
 * @returns {string} - A HEX color.
 */
export function getAccessibleTextColor(backgroundColor = TOKENS.LsdsAColorBackgroundDefault) {
  return hasBadContrast(TOKENS.LsdsAColorTextDefault, 'aa', backgroundColor)
    ? TOKENS.LsdsAColorTextOnPrimary
    : TOKENS.LsdsAColorTextDefault;
}
