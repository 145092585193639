import { useItemLoader } from '../base/useItemLoader';
import { useItemToListLoader } from '../base/useItemToListLoader';

/**
 * This loader is used to load the special tags, This currently is only used for hubspot.
 *
 * The special tags include the hubspot intallation ID for linking deals and blog posts via the hubspot integration.
 */
function responseToItem({ data: { tags } }) {
  for (const tag of tags) {
    tag.id = Number(tag.id);
  }
  return tags;
}

export function useSpecialTagsV1Loader({ count }) {
  const { state } = useItemLoader({
    url: computed(() => (count.value > 0 ? '/tags/special.json' : undefined)),
    responseToItem,
  });

  return useItemToListLoader(state, count);
}
