// eslint-disable-next-line no-restricted-imports
import { useRoute as useRouteOriginal, useRouter } from 'vue-router';

const useRouteSymbol = Symbol('useRoute');
const useQuickViewRouteSymbol = Symbol('useQuickViewRoute');

/**
 * Provides a reactive route object for the main route only.
 */
export function provideRouteMinimal() {
  provide(useRouteSymbol, useRouteOriginal());
}

/**
 * Provides reactive route objects for the main and quick view routes.
 */
export function provideRoute() {
  const router = useRouter();
  const quickViewBackgroundPath = shallowRef(window.history.state?.quickViewBackgroundPath);

  router.afterEach((to, from) => {
    // When navigating from one quick view route to another,
    // we need to ensure that the background route is set.
    if (
      from.meta.isQuickViewRoute &&
      to.meta.isQuickViewRoute &&
      quickViewBackgroundPath.value && // the previous background route path
      window.history.state &&
      !window.history.state.quickViewBackgroundPath // the current background route path
    ) {
      window.history.state.quickViewBackgroundPath = quickViewBackgroundPath.value;
    }

    quickViewBackgroundPath.value = window.history.state?.quickViewBackgroundPath;
  });

  // Due to our quickview routing pattern, the main route may be derived from:
  // - `router.currentRoute`, when there's no quickview
  // - `quickViewBackgroundPath`, when there's a quickview
  //
  // We abstract away this implementation detail below,
  // so in order to get the correct main route anywhere in the app,
  // we simply need to use `useRoute` from `@/route`,
  // instead of `useRoute` or `useRouter().currentRoute` from 'vue-router'.
  //
  // The logic here is taken from the vue-router implementation of `useRoute`.
  // https://github.com/vuejs/router/blob/v4.1.6/packages/router/src/router.ts#L1231-L1239
  const route = {};
  const quickViewRoute = {};

  const backgroundRoute = computed(() =>
    quickViewBackgroundPath.value ? router.resolve(quickViewBackgroundPath.value) : null,
  );

  const quickViewActive = computed(() =>
    Boolean(router.currentRoute.value.meta.isQuickViewRoute && backgroundRoute.value),
  );

  for (const key of Object.keys(router.currentRoute.value)) {
    route[key] = computed(() => (quickViewActive.value ? backgroundRoute.value[key] : router.currentRoute.value[key]));
    quickViewRoute[key] = computed(() => (quickViewActive.value ? router.currentRoute.value[key] : undefined));
  }

  // We need to use `reactive` here for consistency with `vue-router`.
  // eslint-disable-next-line lightspeed/prefer-shallow-ref
  provide(useRouteSymbol, reactive(route));
  // eslint-disable-next-line lightspeed/prefer-shallow-ref
  provide(useQuickViewRouteSymbol, reactive(quickViewRoute));
}

/**
 * Returns a reactive object representing the main route.
 * @returns {import('vue-router').RouteLocationNormalizedLoaded}
 */
export function useRoute() {
  return inject(useRouteSymbol);
}

/**
 * Returns a reactive object representing the quick view route.
 * @returns {import('vue-router').RouteLocationNormalizedLoaded}
 */
export function useQuickViewRoute() {
  return inject(useQuickViewRouteSymbol);
}
