import { DateTime } from 'luxon';
import { useListLoader } from '../base/useListLoader';
import { useOptimisticUpdates } from '../base/useOptimisticUpdates';
import { useRealTimeUpdates } from '../base/useRealTimeUpdates';

function responseToItems(response) {
  const teams = response.data.teams || [];
  const users = response.data.users || {};

  /* eslint-disable no-param-reassign */
  teams.forEach((team) => {
    team.id = Number(team.id);
    team.dateCreated = team.dateCreated ? DateTime.fromISO(team.dateCreated) : null;
    team.members = team.members.map((member) => users[Number(member.id)] || member);
    team.members.forEach((member) => {
      member.id = Number(member.id);
      member.entityType = 'user'; // for assignee pickers
    });
    if (team.company?.id) {
      team.company.id = Number(team.company.id);
    } else {
      // company can be {} which is dumb, use null instead
      team.company = null;
    }
    if (team.parentTeam?.id) {
      team.parentTeam.id = Number(team.parentTeam.id);
    } else {
      // parentTeam can be {} which is dumb, use null instead
      team.parentTeam = null;
    }
    team.numSubteams = Number(team.numSubteams);
    team.projectId = Number(team.projectId);
  });
  /* eslint-enable no-param-reassign */

  return teams;
}

function responseToMeta({ headers }) {
  return { totalCount: Number(headers['x-records']) };
}

/**
 * Loads teams from Teamwork v1 endpoint.
 */
export function useTeamsV1Loader({
  // Whether to  get teams for task comments or tasks only
  teamsForComments: _teamsForComments = false,
  projectId: _projectId,
  taskId: _taskId,
  tasklistId: _tasklistId,
  milestoneId: _milestoneId,
  commentId: _commentId,
  fileVersionId: _fileVersionId,
  params,
  pageSize = 20,
  count,
} = {}) {
  const teamsForComments = shallowRef(_teamsForComments);
  const projectId = shallowRef(_projectId);
  const taskId = shallowRef(_taskId);
  const tasklistId = shallowRef(_tasklistId);
  const milestoneId = shallowRef(_milestoneId);
  const commentId = shallowRef(_commentId);
  const fileVersionId = shallowRef(_fileVersionId);

  const url = computed(() => {
    if (teamsForComments.value) {
      if (commentId.value) {
        return `/comments/${commentId.value}/teams.json`;
      }
      if (fileVersionId.value) {
        return `/files/${fileVersionId.value}/comments/teams.json`;
      }
      if (milestoneId.value) {
        return `/milestones/${milestoneId.value}/comments/teams.json`;
      }
      return `/tasks/${taskId.value}/comments/teams.json`;
    }

    // `taskId` must be checked before `tasklistId` and `projectId`
    // As we may have multiple props passed
    if (taskId.value) {
      return `/tasks/${taskId.value}/teams.json`;
    }

    // `tasklistId` must be checked before `projectId`
    if (tasklistId.value) {
      return `/tasklists/${tasklistId.value}/teams.json`;
    }

    if (projectId.value) {
      return `/projects/${projectId.value}/teams.json`;
    }

    return '/teams.json';
  });

  const { state, refresh, update } = useListLoader({
    url,
    params,
    count,
    pageSize,
    responseToItems,
    responseToMeta,
  });

  useOptimisticUpdates((event) => {
    if (event.type !== 'team' || event?.team?.id === undefined) {
      return;
    }

    update((teams) => {
      if (event.action === 'update') {
        return teams.map((team) => (team.id === event.team.id ? { ...team, ...event.team } : team));
      }

      if (event.action === 'delete') {
        return teams.filter((team) => team.id !== event.team.id);
      }

      return teams;
    }, event.promise);
  });

  useRealTimeUpdates((event) => {
    switch (event.type) {
      case 'person':
      case 'team':
        refresh();
        break;
      default:
        break;
    }
  });

  return state;
}
