const symbol = Symbol('LswTagPicker');

/**
 * @typedef {object} Tag
 * @property {number} id - The ID of the tag.
 * @property {string} name - The name of the tag.
 * @property {string} color - The color of the tag in HEX format.
 * @property {number} projectId - The ID of the project that the tag belongs to. '0' if the tag is not associated with any project.
 * @property {object} project - The project that the tag belongs to.
 */

/**
 * @param {object} params
 * @param {ShallowRef<Tag[]>} options.selectedTags
 * @param {ComputedRef<number?>} options.projectId
 * @param {ComputedRef<boolean>} options.includeProjectTags
 */
function TagPicker({ selectedTags: _selectedTags, projectId: _projectId, includeProjectTags: _includeProjectTags }) {
  const selectedTags = shallowRef(_selectedTags);
  const projectId = shallowRef(_projectId);
  const includeProjectTags = shallowRef(_includeProjectTags);

  return {
    selectedTags,
    projectId,
    includeProjectTags,
  };
}

export function provideLswTagPicker(params) {
  const tagPicker = TagPicker(params);
  provide(symbol, tagPicker);
  return tagPicker;
}

/**
 * @returns {ReturnType<typeof TagPicker>}
 */
export function useLswTagPicker() {
  const lswTagPicker = inject(symbol);
  if (!lswTagPicker) {
    throw new Error('useLswTagPicker must be called within LswTagPicker');
  }
  return lswTagPicker;
}
