<script setup>
import PreviewAvatar0 from './assets/preview-avatar0.svg';
import PreviewAvatar1 from './assets/preview-avatar1.svg';
import PreviewAvatar2 from './assets/preview-avatar2.svg';
import PreviewAvatar3 from './assets/preview-avatar3.svg';
import PreviewAvatar4 from './assets/preview-avatar4.svg';
import PreviewAvatar5 from './assets/preview-avatar5.svg';
import PreviewAvatar6 from './assets/preview-avatar6.svg';
import PreviewAvatar7 from './assets/preview-avatar7.svg';

const props = defineProps({
  id: {
    type: [String, Number],
    default: 0,
  },
});

const reducedId = String(props.id % 7) || 0;
</script>

<template>
  <LscSlotSwitch :name="reducedId">
    <template #0><PreviewAvatar0 /></template>
    <template #1><PreviewAvatar1 /></template>
    <template #2><PreviewAvatar2 /></template>
    <template #3><PreviewAvatar3 /></template>
    <template #4><PreviewAvatar4 /></template>
    <template #5><PreviewAvatar5 /></template>
    <template #6><PreviewAvatar6 /></template>
    <template #7><PreviewAvatar7 /></template>
  </LscSlotSwitch>
</template>
