import { useFeatures } from '@/api';
import { useLocalStorage } from '@/util';

const symbol = Symbol('useDarkMode');

export function provideDarkMode() {
  const { darkModeEnabled } = useFeatures();
  const colorMode = useLocalStorage('teamwork/useDarkMode/colorMode', 'light');

  // Check if user prefers dark mode at the system level
  const prefersDark = shallowRef(window.matchMedia('(prefers-color-scheme: dark)').matches);

  // Listen for changes in system preference
  // Can be refactored to use a Vue watcher 🤷‍♂️
  window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', (e) => {
    prefersDark.value = e.matches;
  });

  function toggleDark() {
    if (colorMode.value === 'system') {
      // If in system mode, switch to explicit dark/light
      colorMode.value = prefersDark.value ? 'light' : 'dark';
    } else {
      // Our custom toggle between light and dark
      colorMode.value = colorMode.value === 'dark' ? 'light' : 'dark';
    }
  }

  // Set the color mode to a specific theme:
  // 'light', 'dark', or 'system'
  function setDark(value) {
    if (['light', 'dark', 'system'].includes(value)) {
      colorMode.value = value;
    } else if (typeof value === 'boolean') {
      // Handle legacy boolean values for backward compatibility
      colorMode.value = value ? 'dark' : 'light';
    }
  }

  // Watch for changes in darkModeEnabled LD flag
  // and force light mode if it gets disabled
  watch(
    darkModeEnabled,
    (enabled) => {
      if (enabled === false) {
        // Forcefully set to light mode
        colorMode.value = 'light';
      }
    },
    { immediate: true },
  );

  // Compute the effective dark mode state
  // based on colorMode and system preference
  const isDark = computed(() => {
    if (!darkModeEnabled.value) {
      return false;
    }

    switch (colorMode.value) {
      case 'dark':
        return true;
      case 'light':
        return false;
      case 'system':
        return prefersDark.value;
      default:
        return false;
    }
  });

  // Watch to add dark mode class to the html element
  watch(
    isDark,
    (enabled) => {
      document.documentElement.classList.toggle('dark', enabled);
    },
    { immediate: true },
  );

  provide(symbol, {
    toggleDark,
    setDark,
    isDark,
    colorMode,
  });
}

export function useDarkMode() {
  return inject(symbol);
}
