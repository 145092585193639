<script setup>
import { DateTime } from 'luxon';
import { TimelogDialogOpenSources } from './constants';

defineProps({
  duration: {
    type: Number,
    default: null,
  },
  project: {
    type: Object,
    default: null,
  },
  task: {
    type: Object,
    default: null,
  },
  timelog: {
    type: Object,
    default: null,
  },
  timer: {
    type: Object,
    default: null,
  },
  openFromTWA: {
    type: Boolean,
    default: false,
  },
  /**
   * @type {PropType<typeof TimelogDialogOpenSources[number]>}
   */
  openSource: {
    type: String,
    default: undefined,
    validator: (value) => TimelogDialogOpenSources.includes(value),
  },
  /**
   * @type {PropType<DateTime>}
   */
  date: {
    type: Object,
    default: undefined,
    validator: (value) => value === undefined || DateTime.isDateTime(value),
  },
  eventId: {
    type: String,
    default: undefined,
  },
});

const emit = defineEmits(['save', 'close', 'delete', 'change', 'submit']);
const modelValue = defineModel({ type: Boolean, default: false });
const bulkActive = shallowRef(false);
const hidePanel = shallowRef(false);

function refreshPanel() {
  hidePanel.value = true;
  setTimeout(() => {
    bulkActive.value = true;
    hidePanel.value = false;
  }, 200);
}

watch(modelValue, (value) => {
  if (!value) {
    bulkActive.value = false;
  }
});

const TimeTimelogAddOrEditDialogPanel = defineAsyncComponent(() => import('./TimeTimelogAddOrEditDialogPanel.vue'));
</script>

<template>
  <LscDialog v-model="modelValue" :retainFocus="false">
    <template #activator="activator">
      <slot name="activator" v-bind="activator" />
    </template>
    <template #default="{ close }">
      <TimeTimelogAddOrEditDialogPanel
        v-if="!hidePanel"
        :closing="!modelValue"
        :duration="duration"
        :project="project"
        :task="task"
        :timelog="timelog"
        :timer="timer"
        :openFromTWA="openFromTWA"
        :openSource="openSource"
        :eventId="eventId"
        :date="date"
        :bulkActive="bulkActive"
        @close="
          close();
          emit('close');
        "
        @addAnother="refreshPanel"
        @change="emit('change', $event)"
        @save="emit('save')"
        @delete="emit('delete')"
        @submit="emit('submit')"
      />
    </template>
  </LscDialog>
</template>
