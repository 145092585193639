<script setup>
import { useI18n } from '@/util';

const { t } = useI18n();
</script>
<template>
  <div class="text-body-1">
    <p>
      {{
        t(
          "A project without a task list is like a project manager without a project. You can't have one without the other!",
        )
      }}
    </p>
    <p class="pt-6">
      {{
        t(
          'A task list ensures that everything is organized and all the related tasks are grouped together and by linking a task list to a milestone you can quickly see how progress is being made.',
        )
      }}
    </p>
    <p class="pt-6">
      {{ t('Task lists can be created within a project, and can be found in the "Task Lists" panel.') }}
    </p>
  </div>
</template>
