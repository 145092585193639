<script setup>
import { useI18n } from '@/util';

const { t } = useI18n();
</script>
<template>
  <div class="text-body-1">
    <p>
      {{
        t(
          'Easily log billable time and expenses for your projects, to allow for transparent project progression for clients, and improved profitability.',
        )
      }}
    </p>
    <p class="pt-6">
      {{
        t(
          'You can log as much time and expenses into a project as you wish, and export your invoices to your accounting system.',
        )
      }}
    </p>
  </div>
</template>
