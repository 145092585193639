<script setup>
import { refDebounced, syncRef } from '@vueuse/core';
import { useProjectsV3Loader } from '@/api';
import { useI18n, useItemCache } from '@/util';
import { normalizeFilterIds } from './normalize';
import { useFilter, useFilterChips, useFilterClear, useFilterCount, useFilterNormalize } from './useFilter';

const props = defineProps({
  name: {
    type: String,
    required: true,
  },
  title: {
    type: String,
    required: true,
  },
  loaderParams: {
    type: Object,
    default: () => ({}),
  },
  defaultValue: {
    type: String,
    default: '',
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  multiple: {
    type: Boolean,
    default: true,
  },
});

const { t } = useI18n();
const { params, activeFilter, dataIdentifierPrefix } = useFilter();

const searchTerm = shallowRef('');
const debouncedSearchTerm = refDebounced(searchTerm, 300);
const filterType = 'projects';
const pageSize = 20;
const count = shallowRef(-1);

const baseParams = computed(() => ({
  'fields[projects]': 'id,name',
  'include': 'projects',
  ...props.loaderParams,
}));

const state = useProjectsV3Loader({
  params: computed(() => ({
    ...baseParams.value,
    searchTerm: debouncedSearchTerm.value,
  })),
  count,
  pageSize,
});

const { items } = state;

const missingProjectIds = shallowRef([]);
const missingProjectsState = useProjectsV3Loader({
  params: computed(() => ({
    ...baseParams.value,
    projectIds: missingProjectIds.value.join(','),
  })),
  count: computed(() => missingProjectIds.value.length || -1),
  pageSize: 50,
});

const { computeAll, computeMissing } = useItemCache(
  computed(() => Object.values(activeFilter.value?.included?.[filterType] || {})),
  items,
  missingProjectsState.items,
);

const projectIds = computed({
  get() {
    return typeof params.value[props.name] === 'string'
      ? params.value[props.name].split(',').map(Number).filter(Boolean)
      : [];
  },
  set(value) {
    params.value = {
      ...params.value,
      [props.name]: value.join(','),
    };
  },
});

const projects = computeAll(projectIds, (id) => ({ id, name: t('Unknown') }));

syncRef(computeMissing(projectIds), missingProjectIds, { direction: 'ltr' });

useFilterNormalize(toRef(props, 'name'), (value) => normalizeFilterIds(value, props.defaultValue));

useFilterClear(toRef(props, 'name'), toRef(props, 'defaultValue'));

useFilterChips(
  computed(() =>
    projects.value.map((project) => ({
      name: project.name,
      icon: 'lsi-project',
      type: 'project',
      delete: () => {
        projectIds.value = projectIds.value.filter((id) => id !== project.id);
      },
    })),
  ),
);

useFilterCount(computed(() => projectIds.value.length));

function toggleProject({ id }) {
  if (projectIds.value.includes(id)) {
    projectIds.value = projectIds.value.filter((projectId) => projectId !== id);
  } else if (props.multiple) {
    projectIds.value = [...projectIds.value, id];
  } else {
    projectIds.value = [id];
  }
}

function icon(id) {
  return projectIds.value.includes(id) ? 'lsi-remove' : 'lsi-add';
}

function toggleMenu(opened) {
  if (!opened || count.value >= 0) {
    return;
  }
  count.value = pageSize;
}
</script>
<template>
  <LscMenu :closeOnContentClick="false" location="bottom left" offset="12" @update:modelValue="toggleMenu">
    <template #activator="activator">
      <LswFilterOptionButton
        v-bind="activator.props"
        :ariaExpanded="activator.isActive"
        :ariaPressed="projectIds.length > 0"
        icon="lsi-project"
        :data-identifier="`${dataIdentifierPrefix}-project-picker-button`"
        :disabled="disabled"
      >
        {{ title }}
      </LswFilterOptionButton>
    </template>
    <LscSheet class="flex w-72 flex-col px-0">
      <LscSearchBar v-model="searchTerm" :clearable="true" :placeholder="t('Search projects')" autofocus class="mx-4" />
      <LswLoaderState :state="state" :blankTitle="t('No projects found')">
        <template #default>
          <VList density="compact" maxHeight="384">
            <VListItem
              v-for="project in items"
              :key="project.id"
              :active="projectIds.includes(project.id)"
              class="my-1 rounded-sm"
              :data-identifier="`${dataIdentifierPrefix}-project-picker-item`"
              @click="toggleProject(project)"
            >
              <div class="flex items-center gap-2">
                <LscOverflowEllipsis class="max-w-full flex-1 text-body-1">
                  {{ project.name }}
                </LscOverflowEllipsis>
                <LscIcon v-if="multiple" :icon="icon(project.id)" class="flex shrink-0 text-icon-subtle" size="sm" />
              </div>
            </VListItem>
            <LswLoaderTrigger v-model:count="count" :state="state" :step="pageSize" />
          </VList>
        </template>
      </LswLoaderState>
    </LscSheet>
  </LscMenu>
</template>
