import { useCurrentAccount } from '../account/useCurrentAccount';
import { usePricePlan } from '../account/usePricePlan';
import { useSpecialTagsV1Loader } from '../integrations/useSpecialTagsV1Loader';
import { usePermissions } from '../permissions/usePermissions';
import { useHubspotOauthV2Loader } from './useHubspotOauthV2Loader';

const HubspotAuthInfoSymbol = Symbol('HubspotAuthInfo');

function Hubspot({ active: _active = true } = {}) {
  const active = shallowRef(_active);
  const account = useCurrentAccount();
  const { isCollaborator, isClientUser } = usePermissions();
  const { isInFreeTrial } = usePricePlan();
  const { item: authed } = useHubspotOauthV2Loader({ active });
  const isHubspotV1Authed = computed(() => account.value.integrations.hubSpot.enabled);
  const isHubspotV2Authed = computed(() => authed.value?.isAuthed && authed.value?.hasRequiredScopes);
  const isHubspotAuthed = computed(() => isHubspotV1Authed.value || isHubspotV2Authed.value);
  const canModifyHubspotLink = computed(() =>
    Boolean(
      isHubspotAuthed.value &&
        !isCollaborator.value &&
        !isClientUser.value &&
        (account.value.pricePlanData.features.hubspot || isInFreeTrial.value),
    ),
  );

  const { items: specialTags } = useSpecialTagsV1Loader({ count: computed(() => (active.value ? Infinity : -1)) });
  const specialTag = computed(
    () => specialTags.value?.find((tag) => tag.name.toLowerCase() === 'hubspot') ?? { id: 0 },
  );

  return {
    isHubspotAuthed,
    isHubspotV1Authed,
    isHubspotV2Authed,
    canModifyHubspotLink,
    specialTag,
  };
}

export function provideHubspot() {
  const active = shallowRef(false);
  const state = Hubspot({ active });
  provide(HubspotAuthInfoSymbol, { state, active });
}

export function useHubSpot() {
  const { active, state } = inject(HubspotAuthInfoSymbol);
  active.value = true;
  return state;
}

export const hubspotObjectTypeIds = {
  CONTACT: 1,
  COMPANY: 2,
  DEAL: 3,
  TICKET: 4,
};
