import { useCurrentAccount } from '../account/useCurrentAccount';
import { usePendo } from '../features/usePendo';

/**
 * Constants, computed properties and functions related to generic web experiment tracking
 */
export function useWebExperimentTracking() {
  const account = useCurrentAccount();
  const { trackPendoEvent } = usePendo();

  const webSignupVariantArray = account.value?.webSignupVariant?.split(',');
  function parseWebExperiment(experimentString) {
    const expRegEx = /website-(.*)_([a-z]*[0-9]*)/gm;
    const values = expRegEx.exec(experimentString);
    if (values) {
      const experimentID = values?.[1];
      const variation = values?.[2];
      return {
        experiment_id: `website-${experimentID}`,
        experiment_variation_value: variation,
        experiment_variation_name: variation,
      };
    }
    return null;
  }

  const parsedExperiments = webSignupVariantArray.map(parseWebExperiment).filter(Boolean);

  function trackWebExperiments() {
    if (!account.value?.webSignupVariant) {
      return;
    }
    parsedExperiments.forEach((experiment) => {
      trackPendoEvent({
        eventName: 'WEBSITE_EXPERIMENT',
        metadata: {
          experiment_id: experiment.experiment_id,
          experiment_variation_value: experiment.experiment_variation_value,
          experiment_variation_name: experiment.experiment_variation_value,
        },
      });
    });
  }

  return {
    trackWebExperiments,
  };
}
