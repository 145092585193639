<script setup>
import { useExperiment46, usePendo } from '@/api';
import { useBadge } from './useBadge';

defineProps({
  progress: {
    type: Number,
    required: true,
  },
});
const { EXP_46_COMMON_METRICS } = useExperiment46();
const { getOtherExp46CommonMetrics } = useBadge();

const { trackPendoEvent } = usePendo();

const pendoEventWasSent = ref(false);

function levelProgressBarHovered() {
  if (pendoEventWasSent.value) {
    return;
  }
  trackPendoEvent({
    eventName: 'NAVIGATION_EVENT',
    commonMetrics: EXP_46_COMMON_METRICS,
    metadata: {
      event_action: 'profile_progress_bar_hovered',
      ...getOtherExp46CommonMetrics(),
    },
  });
  pendoEventWasSent.value = true;
}
</script>

<template>
  <VProgressLinear
    class="mt-2"
    :modelValue="progress"
    color="success"
    height="4"
    rounded
    @mouseover="levelProgressBarHovered"
  />
</template>
