<script setup>
import { useAppShellSidebar } from '@/appShell';
import { useI18n } from '@/util';
import NotificationDrawerListItem from './NotificationDrawerListItem.vue';

const props = defineProps({
  state: {
    type: Object,
    default: () => ({}),
  },
  step: {
    type: Number,
    default: 20,
  },
});

const emit = defineEmits(['toggleRead']);

const count = defineModel('count', { type: Number, required: true });

const { t } = useI18n();

const { activeDrawerPanel } = useAppShellSidebar();

const scrollContainer = shallowRef(null);

watch(
  activeDrawerPanel,
  async (newVal) => {
    if (newVal === 'notifications') {
      await nextTick();
      scrollContainer.value.scrollTop = 0;
    }
  },
  { immediate: true },
);

const notifications = computed(() => props.state.items.value);

// `<LswLoaderState>` cannot be used until `useNotificationsFetcher` is replaced by
// proper, standards-conforming `useNotificationsLoader` and `useNotificationActions` composables.
const uiState = computed(() => {
  const totalCount = unref(props.state.totalCount);
  const error = unref(props.state.error);
  if (error) {
    return 'error';
  }
  if (notifications.value.length > 0) {
    return 'default';
  }
  if (totalCount === 0) {
    return 'blank';
  }
  return 'loading';
});
</script>

<template>
  <div ref="scrollContainer" class="h-full overflow-y-auto overflow-x-hidden px-4 py-2">
    <LscSlotSwitch :name="uiState">
      <template #default>
        <div class="flex flex-col gap-1">
          <!-- TODO: Fix this once the data is normalised -->
          <!-- <TransitionGroup
          tag="div"
          class="flex flex-col gap-1"
          enterActiveClass="transition-all duration-500 ease-[cubic-bezier(0,0.34,0.03,1)]"
          leaveActiveClass="transition-all duration-300 ease-[cubic-bezier(0,0.34,0.03,1)] absolute"
          moveClass="transition-all duration-500 ease-[cubic-bezier(0,0.34,0.03,1)]"
          enterFromClass="opacity-0 -translate-y-full"
          enterToClass="opacity-100 translate-y-0"
          leaveFromClass="opacity-100 translate-x-0"
          leaveToClass="opacity-0 -translate-x-full"
        > -->
          <NotificationDrawerListItem
            v-for="notification in notifications"
            :key="notification.id"
            v-memo="[notification.read]"
            :notification="notification"
            @toggleRead="emit('toggleRead', notification.id)"
          />
          <LswLoaderTrigger v-model:count="count" :step="step" :state="state" />
          <!-- </TransitionGroup> -->
        </div>
      </template>
      <template #loading>
        <LscLoadingProgress :message="t('Loading notifications')" />
      </template>
      <template #blank>
        <slot name="blank" />
      </template>
      <template #error>
        <LscEmptyState variant="error" class="h-full" :title="t('Notifications failed to load')" />
      </template>
    </LscSlotSwitch>
  </div>
</template>
