<script setup>
import { useI18n } from '@/util';

const { t } = useI18n();
</script>
<template>
  <div class="text-body-1">
    <p>
      {{
        t(
          'Once a form is submitted it creates a task in the designated project and it will land in the project’s “inbox” task list.',
        )
      }}
    </p>
    <p class="pt-6">
      {{
        t(
          'Fun (and incredibly valuable fact!): You can create a new task list that holds all incoming requests.  If you would benefit from creating a designated task list for organizational (and tracking) purposes, we highly recommend doing so.',
        )
      }}
    </p>
  </div>
</template>
