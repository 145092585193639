<script setup>
import { usePendo } from '@/api';
import { useAppShellSidebar } from '@/appShell';
import { useQuickView, useRoute } from '@/route';
import {
  getEntityDescriptionText,
  getEntitySecondaryText,
  getEntitySomeoneText,
  useI18n,
  useKeyboardShortcut,
} from '@/util';
import { EntityBadge } from '@/module/entity';
import { useLegacyBridge } from '@/module/legacy';

const props = defineProps({
  notification: {
    type: Object,
    required: true,
  },
});

const emit = defineEmits(['toggleRead']);
const SUPPORTED_QUICKVIEW_ENTITIES = [
  'file',
  'message',
  'milestone',
  'link',
  'notebook',
  'task',
  'taskList',
  'comment',
];

const { t } = useI18n();
const { openQuickView, isQuickViewLocked } = useQuickView();

const { clearActiveDrawerPanelIfNotPinned } = useAppShellSidebar();
const { closeAllLegacyQuickViews, showLegacyQuickView, shouldUseLightspeedTaskDetails } = useLegacyBridge();
const { trackPendoEvent } = usePendo();
const route = useRoute();

const itemEl = shallowRef(null);

const title = computed(() =>
  getEntitySomeoneText(props.notification.entity)(t, props.notification.user || t('Someone'), props.notification.extra),
);
const description = computed(
  () => getEntityDescriptionText(props.notification.entity)(t) ?? props.notification.description,
);
const secondaryText = computed(() => {
  const { entity, extra, action, project } = props.notification;
  const defaultText = extra?.description && action !== 'reacted' ? `${extra.description} - ${project}` : project;
  return getEntitySecondaryText(entity)(t) ?? defaultText;
});

const itemObject = computed(() => {
  const itemLink = props.notification.entity.link || '';
  // file, proofrole, notebook, link, message, task, comment
  let itemType = props.notification.entity.group;
  let itemId = props.notification.entity.id;
  let commentId;

  if (['proofrole', 'prooffeedback'].includes(itemType)) {
    itemType = 'proof';
  }

  if (itemType === 'comment') {
    // we switch places here just to make it easy on the openQuickView call
    // TODO: think about a better way to handle this
    itemId = props.notification.extra?.info?.objectId;
    commentId = props.notification.entity.id;

    // Please note that a comment can be for a task, file, milestone, or notebook
    if (itemLink.includes('/files')) {
      itemType = 'file';
    } else if (itemLink.includes('/milestones')) {
      itemType = 'milestone';
    } else if (itemLink.includes('/notebooks')) {
      itemType = 'notebook';
    } else if (itemLink.includes('/tasks')) {
      itemType = 'task';
    }
  }

  return {
    itemType: itemType.replace('tasklist', 'taskList'),
    itemId: Number(itemId),
    commentId: Number.isNaN(commentId) ? undefined : Number(commentId),
  };
});

const itemHasQuickView = computed(() => SUPPORTED_QUICKVIEW_ENTITIES.includes(itemObject.value.itemType));
const itemHasLink = computed(() => Boolean(props.notification.entity.link));

function triggerNotificationQuickView(markAsRead = true) {
  const { itemType, itemId, commentId } = itemObject.value;

  if (itemHasQuickView.value) {
    closeAllLegacyQuickViews();

    if (itemType === 'task') {
      if (shouldUseLightspeedTaskDetails.value) {
        openQuickView(`/tasks/${itemId}`, commentId > 0 ? { query: { c: commentId } } : {});
      } else {
        showLegacyQuickView({
          name: itemType,
          params: {
            id: itemId,
            projectId: props.notification.projectId,
            [`${itemType}Id`]: itemId,
          },
        });
      }
    }

    trackPendoEvent({
      eventName: 'NOTIFICATION_QUICK_VIEW',
      commonMetrics: ['plan_name', 'user_role'],
      metadata: {
        itemType,
      },
    });

    if (markAsRead && !props.notification.read) {
      // This timeout is to slow down the removal of the list item from the notification panel.
      // This gives the quick view a moment to open before removing the notification from the list
      // Design has requested this change
      setTimeout(() => {
        emit('toggleRead', props.notification.id);
      }, 1500);
    }
  } else {
    // eslint-disable-next-line no-console
    console.warn('Unsupported notification type', props.notification);
  }
}

function clickItem(event) {
  if (!itemHasLink.value) {
    return;
  }
  const commandKeyPressed = event.metaKey || event.ctrlKey;

  if (commandKeyPressed || itemObject.value.itemType === 'proof') {
    window.open(`/app${props.notification.entity.link}`, '_blank');
  } else if (!commandKeyPressed) {
    triggerNotificationQuickView();
  }

  if (shouldUseLightspeedTaskDetails.value) {
    closeAllLegacyQuickViews();

    // If full task details is active, we need to close the notification drawer
    if (isQuickViewLocked.value) {
      clearActiveDrawerPanelIfNotPinned();
    }
  }
}

watch(
  () => route.path,
  () => {
    if (shouldUseLightspeedTaskDetails.value) {
      clearActiveDrawerPanelIfNotPinned();
    }
  },
);

useKeyboardShortcut(
  'V',
  () => {
    triggerNotificationQuickView(false);
  },
  {
    activeOnHoverElement: itemEl,
  },
);
</script>

<template>
  <!-- event="" -->
  <Component
    :is="itemHasLink ? 'RouterLink' : 'div'"
    ref="itemEl"
    data-identifier="notification-list-item"
    class="flex w-full select-none items-center gap-4 rounded-xl px-2 py-3 text-left hover:bg-surface-hover"
    :to="notification.entity?.link"
    :target="itemObject.itemType === 'proof' ? '_blank' : undefined"
    persistDrawer
    @click.prevent="clickItem"
  >
    <EntityBadge :group="notification.entity.group" :action="notification.entity.action">
      <LscAvatar
        v-if="notification.entity.group === 'timereminder'"
        size="lg"
        bgColor="var(--time-reminders-avatar-color-background)"
        iconColor="var(--lsds-a-color-icon-default)"
        icon="lsi-time"
      />
      <LscAvatar v-else size="lg" :name="notification.user" :src="notification.avatar" />
    </EntityBadge>
    <div class="flex flex-1 flex-col items-stretch gap-1 overflow-hidden">
      <div class="flex w-full items-start gap-2 text-body-2 text-subtle">
        <LscOverflowEllipsis class="flex-1">
          {{ title }}
        </LscOverflowEllipsis>
        <LscDateTime :dateTime="notification.date" class="flex-none" />
        <LscIconButton
          v-LsdTooltip="notification.read ? t('Mark as unread') : t('Mark as read')"
          size="sm"
          class="flex-none"
          variant="plain-secondary"
          :ariaPressed="notification.read"
          :ariaLabel="notification.read ? t('Mark as unread') : t('Mark as read')"
          :icon="notification.read ? 'lsi-mark-unread' : 'lsi-mark-read'"
          @click.prevent.stop="emit('toggleRead')"
        />
      </div>

      <LscOverflowEllipsis class="font-medium">
        {{ description || t('View here') }}
      </LscOverflowEllipsis>

      <LscOverflowEllipsis :title="secondaryText" class="truncate text-body-2 text-subtle">
        {{ secondaryText }}
      </LscOverflowEllipsis>
    </div>
  </Component>
</template>
