<script setup>
import { useResizeObserver, useScroll } from '@vueuse/core';

const props = defineProps({
  shouldDisableMasking: {
    type: Boolean,
    default: false,
  },
  horizontal: {
    type: Boolean,
    default: false,
  },
});

const mask = shallowRef('');
const scrollableElement = shallowRef(null);

/**
 * @param {HTMLElement} el
 * @returns {string|null}
 */
function getMaskGradient(el) {
  if (props.shouldDisableMasking) {
    return null;
  }

  const margin = 4;
  const isScrollable = props.horizontal ? el.scrollWidth > el.clientWidth : el.scrollHeight > el.clientHeight;

  const endNotVisible = (() => {
    if (!isScrollable) {
      return false;
    }

    const distanceToEnd = Math.abs(
      props.horizontal
        ? el.scrollWidth - el.clientWidth - el.scrollLeft
        : el.scrollHeight - el.clientHeight - el.scrollTop,
    );

    return distanceToEnd >= margin;
  })();

  const startNotVisible = (() => {
    if (!isScrollable) {
      return false;
    }

    return (props.horizontal ? el.scrollLeft : el.scrollTop) > margin;
  })();

  function getGradient(angle, bothSides = false) {
    return `linear-gradient(${angle}, transparent 0%, black min(10%, 64px)${bothSides ? ', black min(calc(100% - 10%), calc(100% - 64px)), transparent 100%' : ''})`;
  }

  if (startNotVisible && endNotVisible) {
    return props.horizontal ? getGradient('to right', true) : getGradient('to bottom', true);
  }

  if (startNotVisible) {
    return props.horizontal ? getGradient('to right', false) : getGradient('to bottom', false);
  }

  if (endNotVisible) {
    return props.horizontal ? getGradient('to left', false) : getGradient('to top', false);
  }

  return null;
}

function getMaskStyles(el) {
  const gradient = getMaskGradient(el);
  if (!gradient) {
    return null;
  }

  return {
    'mask-image': gradient,
  };
}

function handler() {
  const el = scrollableElement.value;
  if (el) {
    mask.value = getMaskStyles(el);
  }
}

onMounted(handler);
useResizeObserver(scrollableElement, handler);
useScroll(scrollableElement, { onScroll: handler });
</script>

<template>
  <div
    ref="scrollableElement"
    :style="{
      ...mask,
    }"
    class="relative"
    :class="[horizontal ? 'overflow-x-auto' : 'overflow-y-auto']"
    tabindex="0"
  >
    <slot />
  </div>
</template>
