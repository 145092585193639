<script setup>
import { providePermissions } from '../permissions/usePermissions';
import ApiSetup5 from './ApiSetup5.vue';

providePermissions();
</script>

<template>
  <ApiSetup5>
    <slot />
  </ApiSetup5>
</template>
