import { Info, Interval } from 'luxon';
import { getToday, isSameDay, useI18n } from '@/util';

export const DatePickerSymbol = Symbol('DatePicker');
export const DatePickerInternalSymbol = Symbol('DatePickerInternal');

/**
 * @param {Object} options
 * @param {ComputedRef<Boolean>} options.clearable
 * @param {ComputedRef<String|undefined>} options.dataTestIdPrefix
 * @param {ComputedRef<DateTime|null>} options.date
 * @param {ComputedRef<[DateTime|null, DateTime|null]>} options.dates
 * @param {ComputedRef<(day: DateTime) => String | object>} options.disabledDayTooltipFunction
 * @param {ComputedRef<Boolean>} options.enableWeekends
 * @param {ComputedRef<String|undefined>} options.endDateLabel
 * @param {ComputedRef<DateTime|undefined>} options.maxDate
 * @param {ComputedRef<DateTime|undefined>} options.minDate
 * @param {ComputedRef<Boolean>} options.modelValue
 * @param {ComputedRef<'start'|'end'>} options.rangeMode
 * @param {ComputedRef<String|undefined>} options.startDateLabel
 * @param {ComputedRef<Boolean>} options.showShortcuts
 */
function DatePicker({
  clearable: _clearable,
  dataTestIdPrefix: _dataTestIdPrefix,
  date: _date,
  dates: _dates,
  disabledDayTooltipFunction,
  enableWeekends: _enableWeekends,
  endDateLabel: _endDateLabel,
  maxDate: _maxDate,
  minDate: _minDate,
  modelValue: _modelValue,
  rangeMode,
  showShortcuts: _showShortcuts,
  showStartDate: _showStartDate,
  startDateLabel: _startDateLabel,
}) {
  const { t, weekStartsOnSunday, languageCode } = useI18n();

  const modelValue = shallowRef(_modelValue);
  const date = shallowRef(_date);

  // This is a copy of the original prop, so that we can modify it without affecting the original until were ready.
  const dates = shallowRef(_dates.value);

  const clearable = shallowRef(_clearable);
  const minDate = shallowRef(_minDate);
  const maxDate = shallowRef(_maxDate);
  const enableWeekends = shallowRef(_enableWeekends);
  const showShortcuts = shallowRef(_showShortcuts);
  const showStartDate = shallowRef(_showStartDate);
  const startDateLabel = shallowRef(_startDateLabel);
  const endDateLabel = shallowRef(_endDateLabel);
  const dataTestIdPrefix = shallowRef(_dataTestIdPrefix);

  const startTextField = shallowRef();
  const endTextField = shallowRef();

  const hasDateProp = computed(() => date.value !== DatePickerSymbol);
  const hasDatesProp = computed(() => dates.value !== DatePickerSymbol);

  const visibleMonth = shallowRef();

  const formattedVisibleMonth = computed(() => visibleMonth.value?.toLocaleString({ year: 'numeric', month: 'long' }));

  const startDateVisibilityOverride = shallowRef(false);

  const shouldShowStartDateField = computed(() => {
    if (showStartDate.value || startDateVisibilityOverride.value) {
      return true;
    }
    return dates.value?.[0] != null;
  });

  async function showStartDateField() {
    startDateVisibilityOverride.value = true;
    await nextTick();
    startTextField.value?.focus();
  }

  /**
   * The 6 weeks that are visible in the calendar.
   */
  const visibleMonthInterval = computed(() => {
    const start = visibleMonth.value.startOf('month').startOf('week', { useLocaleWeeks: true });
    const end = start.plus({ day: 42 });
    return Interval.fromDateTimes(start, end);
  });

  const dayHeadings = computed(() => {
    // Gets the first letter of each day, in the user's language, for use in the datepicker
    const days = Info.weekdays('narrow', { locale: languageCode.value });
    if (weekStartsOnSunday.value) {
      // Move last day 'Sunday' to first position
      const sunday = days.pop();
      days.unshift(sunday);
    }
    return days;
  });

  /**
   * Returns a function to compute the tooltip for a disabled day.
   * @type {ComputedRef<(day: DateTime) => String | object>}
   */
  const computedDisabledDayTooltipFunction = computed(() => {
    if (disabledDayTooltipFunction.value) {
      return disabledDayTooltipFunction.value;
    }
    return () => t('Date unavailable');
  });

  /**
   * Navigate relative to the current month.
   * @param {Number} diff
   */
  function navigateVisibleMonth(diff) {
    visibleMonth.value = visibleMonth.value.plus({ month: diff }).startOf('month');
  }

  /**
   * Change the visible month to the current month.
   */
  function navigateToCurrentMonth() {
    visibleMonth.value = getToday().startOf('month');
  }

  /**
   * Only update the visibleMonth if the new month is not already visible, even partially
   * @param {DateTime} newMonthYear
   */
  function maybeNavigateVisibleMonth(newMonthYear) {
    if (!newMonthYear?.isValid) {
      return;
    }

    if (!visibleMonthInterval.value.contains(newMonthYear)) {
      visibleMonth.value = newMonthYear.startOf('month');
    }
  }

  // Update the month and year when the date changes.
  watch(date, () => {
    maybeNavigateVisibleMonth(date.value);
  });

  watch(dates, () => {
    if (rangeMode.value === 'start' && dates.value[0]) {
      maybeNavigateVisibleMonth(dates.value[0]);
    } else if (dates.value[1]) {
      maybeNavigateVisibleMonth(dates.value[1]);
    }
  });

  // Update the internal dates when the external dates change.
  watch(_dates, () => {
    dates.value = _dates.value;
  });

  function close() {
    modelValue.value = false;
  }

  /**
   * Select a single date in the calendar.
   * @param {DateTime} newDate
   */
  function selectDate(newDate) {
    if (!hasDateProp.value) {
      return;
    }
    date.value = newDate;
  }

  /**
   * Select a range in the calendar.
   * @param {DateTime} newDate
   */
  function selectDates(newDate, rangeModeOverride) {
    if (!hasDatesProp.value) {
      return;
    }

    const effectiveRangeMode = rangeModeOverride ?? rangeMode.value;
    let [startDate, endDate] = dates.value;

    if (effectiveRangeMode === 'start') {
      startDate = newDate;
    } else {
      endDate = newDate;
    }

    // Fix the conflicting date, if any.
    if (startDate && endDate && startDate > endDate) {
      if (effectiveRangeMode === 'start') {
        endDate = startDate;
      } else {
        startDate = endDate;
      }
    }

    dates.value = [startDate, endDate];
  }

  function toggleInputFocus() {
    if (!hasDatesProp.value) {
      return;
    }
    if (rangeMode.value === 'start') {
      endTextField.value?.focus();
    } else if (shouldShowStartDateField.value && rangeMode.value === 'end') {
      startTextField.value?.focus();
    }
  }

  /**
   * This updates the date range outside of the component when the dialog is closed.
   */
  function updateExternalDateRange() {
    if (
      hasDatesProp.value &&
      (!isSameDay(_dates.value?.[0], dates.value[0]) || !isSameDay(_dates.value?.[1], dates.value[1]))
    ) {
      // eslint-disable-next-line no-param-reassign
      _dates.value = dates.value;
    }
  }

  /**
   * Keeps the focus on the selected input - start / end date - when an input is clicked off of.
   * This must be done so that the user knows which input is active when they're making a selection.
   */
  function keepFocus() {
    requestAnimationFrame(() => {
      // prevent focusing while closing date popover
      if (!modelValue.value) {
        return;
      }
      if (startTextField.value?.$el.matches(':focus-within') || endTextField.value?.$el.matches(':focus-within')) {
        return;
      }
      if (rangeMode.value === 'start' && shouldShowStartDateField.value) {
        startTextField.value?.focus();
      } else if (rangeMode.value === 'end') {
        endTextField.value?.focus();
      }
    });
  }

  /* eslint-disable no-console */
  function validateDatePicker() {
    if (!hasDateProp.value && !hasDatesProp.value) {
      console.warn('LscDatePicker requires either the date or dates prop to be set.');
    }
    if (hasDateProp.value && hasDatesProp.value) {
      console.warn('LscDatePicker must not have both the date and dates prop set.');
    }
  }
  /* eslint-enable no-console */

  // Warn if the date or dates prop is not set.
  watchEffect(validateDatePicker);

  // Set the initial month and year to the first date in the range, or today if no date is set.
  function initMonthYear() {
    let today = getToday();
    if (minDate.value && today < minDate.value) {
      today = minDate.value;
    } else if (maxDate.value && today > maxDate.value) {
      today = maxDate.value;
    }

    let initialMonth = today;
    if (hasDateProp.value && date.value != null) {
      initialMonth = date.value;
    } else if (hasDatesProp.value && dates.value.some((d) => d != null)) {
      initialMonth = dates.value[0] ?? dates.value[1];
    }
    visibleMonth.value = initialMonth.startOf('month');
  }

  initMonthYear();

  return {
    // External
    modelValue,
    date,
    dates,
    clearable,
    minDate,
    maxDate,
    enableWeekends,
    showShortcuts,
    dataTestIdPrefix,
    rangeMode,
    // Internal
    hasDateProp,
    hasDatesProp,
    // Month and year info
    visibleMonth,
    formattedVisibleMonth,
    visibleMonthInterval,
    dayHeadings,
    navigateVisibleMonth,
    navigateToCurrentMonth,
    computedDisabledDayTooltipFunction,
    // Menu
    close,
    // Range
    shouldShowStartDateField,
    showStartDateField,
    startDateLabel,
    endDateLabel,
    startTextField,
    endTextField,
    keepFocus,
    toggleInputFocus,
    // Date selection
    selectDate,
    selectDates,
    // Update
    updateExternalDateRange,
  };
}

/**
 * @param {Parameters<DatePicker>} params
 * @returns
 */
export function provideDatePicker(...params) {
  const datePicker = DatePicker(...params);
  provide(DatePickerInternalSymbol, datePicker);
  return datePicker;
}

/**
 * @returns {ReturnType<DatePicker>}
 */
export function useDatePicker() {
  const datePicker = inject(DatePickerInternalSymbol);
  if (!datePicker) {
    throw new Error('No date picker data provided');
  }
  return datePicker;
}
