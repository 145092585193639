<script setup>
import { useI18n } from '@/util';
import { useLsDialog } from './useLsDialog.js';

defineProps({
  infoTooltip: {
    type: [String, Object],
    default: null,
  },
  dataIdentifierPrefix: {
    type: String,
    default: undefined,
  },
});
const emit = defineEmits(['close']);

const { persistent } = useLsDialog();

const { t } = useI18n();
</script>

<template>
  <div class="flex items-center justify-between gap-2">
    <slot name="prependHeaderLeft" />
    <h4
      id="lscdialog-title"
      class="text-h4 font-semibold"
      :data-identifier="dataIdentifierPrefix ? `${dataIdentifierPrefix}-title` : undefined"
    >
      <slot name="title" />
    </h4>
    <slot name="appendHeaderLeft">
      <LscIcon v-if="infoTooltip" v-LsdTooltip="infoTooltip" class="text-icon-subtle" icon="lsi-tooltip" size="sm" />
    </slot>
    <div class="flex-1" />
    <slot name="prependHeaderRight" />
    <LscIconButton
      v-if="!persistent"
      v-LsdTooltip="t('Close')"
      class="shrink-0"
      :data-identifier="dataIdentifierPrefix ? `${dataIdentifierPrefix}-close-button` : undefined"
      size="md"
      variant="primary"
      icon="lsi-close"
      :ariaLabel="t('Close')"
      @click="emit('close')"
    />
    <slot name="appendHeaderRight" />
  </div>
</template>
