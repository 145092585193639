/** @type {InjectionKey<import('./useCurrentAccountV1Loader').useCurrentAccountV1Loader>} */
const symbol = Symbol('useCurrentAccount');

export function provideCurrentAccountState(currentAccountState) {
  provide(symbol, currentAccountState);
}

/** @type {() => ReturnType<import('./useCurrentAccountV1Loader').useCurrentAccountV1Loader>} */
export function useCurrentAccountState() {
  return inject(symbol);
}

export function useCurrentAccount() {
  return useCurrentAccountState().item;
}
