/**
 * An object containing translation variants.
 * @typedef {Object} Variants
 * @property {string} [zero]
 * @property {string} [one]
 * @property {string} [two]
 * @property {string} [few]
 * @property {string} [many]
 * @property {string} other
 * @see https://www.unicode.org/cldr/cldr-aux/charts/29/supplemental/language_plural_rules.html
 */

/**
 * A translation for a single key which can be represented by a single string or an object of variants.
 * @typedef {string|Variants} Translation
 */

/**
 * Takes variants and params, and returns one of the variants based on the params.
 * @callback ResolveVariant
 * @param {Variants} variants An object containing variants.
 * @param {Object} params An object containing translation parameters.
 * @returns {string} A translation variant.
 */

/**
 * A translation descriptor containing a translation and optional extra data.
 * @typedef {Object} TranslationDescriptor
 * @property {Translation} translation A message translation.
 * @property {string} [notes] Notes for human translators.
 */

/**
 * An object mapping translation keys to translations or translation descriptors.
 * @typedef {{ [key: string]: Translation | TranslationDescriptor }} Messages
 */

/**
 * An object representing a single locale.
 * @typedef {Object} Locale
 * @property {string} languageCode The language code.
 * @property {function} translate A function for translating messages to the locale.
 */

const defaultLanguageCode = window.navigator.language.slice(0, 2);

/**
 * Normalizes the specified translation.
 * @param {Translation} translation The translation to normalize.
 * @param {boolean} [stringOnly=false] If true, accept only string translations - no variants.
 * @returns {Translation|undefined} The normalized translation, or `undefined` if an invalid translation was provided.
 */
function normalizeTranslation(translation, stringOnly = false) {
  if (typeof translation === 'string') {
    if (translation) {
      return translation.trim();
    }
  }
  if (stringOnly) {
    return undefined;
  }
  if (typeof translation === 'object') {
    if (translation && typeof translation.other === 'string' && translation.other) {
      return {
        zero: ((typeof translation.zero === 'string' && translation.zero) || translation.other).trim(),
        one: ((typeof translation.one === 'string' && translation.one) || translation.other).trim(),
        two: ((typeof translation.two === 'string' && translation.two) || translation.other).trim(),
        few: ((typeof translation.few === 'string' && translation.few) || translation.other).trim(),
        many: ((typeof translation.many === 'string' && translation.many) || translation.other).trim(),
        other: translation.other.trim(),
      };
    }
  }
  return undefined;
}

/**
 * The default implementation of a function which returns an appropriate variant to use
 * based on the specified variants and params.
 * @type {ResolveVariant}
 * @see https://www.unicode.org/cldr/cldr-aux/charts/29/supplemental/language_plural_rules.html#en
 */
function defaultResolveVariant(variants, params) {
  return Math.abs(params.n) === 1 ? variants.one : variants.other;
}

/**
 * Creates Locale objects.
 * @param {Object} [config]
 * @param {string} [config.languageCode]
 * @param {Messages} [config.messages]
 * @param {ResolveVariant} [config.resolveVariant]
 * @returns {Locale} A new locale object.
 */
export function createLocale(config) {
  const languageCode = config?.languageCode ?? 'en';
  const messages = config?.messages ?? {};
  const resolveVariant = config?.resolveVariant ?? defaultResolveVariant;
  const cache = new Map();

  function prepareTarget(message) {
    // English messages come in one of the 3 formats listed below.
    // The `|` characters separate message variants.
    // "=0", "=1" and "other" are names of the variants.
    //
    // - "other" - for example "Tasks"
    // - "=1 | other" - for example "{n} task | {n} tasks"
    // - "=0 | =1 | other" - for example "No tasks | {n} task | {n} tasks"
    //
    // The "=0" and "=1" variants are handled in a special way for improved user experience.
    // To make them work, we must store them under a separate translation key. We maintain translation keys for
    // the following messages or partial messages.
    //
    // - "=0" holds a string translation for the case where `params.n === 0`
    // - "=1" holds a string translation for the case where `params.n === 1`
    // - "other" if it contains `{n}`, it holds a Variants translation, otherwise it holds a string translation.
    //
    // See https://www.unicode.org/cldr/cldr-aux/charts/29/supplemental/language_plural_rules.html
    const variants = message.split('|', 3).map((variant) => variant.trim());
    const key = variants.pop();
    const key1 = variants.length > 0 ? variants.pop().replaceAll('{n}', '1') : undefined;
    const key0 = variants.length > 0 ? variants.pop().replaceAll('{n}', '0') : undefined;

    const translation = normalizeTranslation(Object.hasOwn(messages, key) && messages[key]);

    if (translation) {
      return {
        translation,
        resolveVariant,
        variant0: normalizeTranslation(Object.hasOwn(messages, key0) && messages[key0], true),
        variant1: normalizeTranslation(Object.hasOwn(messages, key1) && messages[key1], true),
      };
    }

    if (import.meta.env.DEV && languageCode !== defaultLanguageCode) {
      // eslint-disable-next-line no-console
      console.trace(`useI18n: missing or invalid "${languageCode}" translation for "${message}"`);
    }

    return undefined;
  }

  function prepareDefault(message) {
    const variants = message.split('|', 3).map((variant) => variant.trim());
    const variant0 = variants.length === 3 ? variants.shift() : undefined;
    const variant1 = variants.length === 2 ? variants[0] : undefined;

    if (variants.length === 2) {
      return {
        translation: { one: variants[0], other: variants[1] },
        resolveVariant: defaultResolveVariant,
        variant0,
        variant1,
      };
    }

    return { translation: variants[0] };
  }

  function translate(message, params, formatN) {
    let cacheItem = cache.get(message);

    if (!cacheItem) {
      cacheItem = prepareTarget(message) || prepareDefault(message);
      cache.set(message, cacheItem);
    }

    if (
      import.meta.env.MODE === 'development' &&
      (cacheItem.variant0 != null || cacheItem.variant1 != null || typeof cacheItem.translation !== 'string') &&
      (params == null || !Number.isFinite(params.n))
    ) {
      // eslint-disable-next-line no-console
      console.warn('useI18n#t: The param `n` must be a finite number to pick the correct variant.', {
        message,
        params,
        cacheItem,
      });
    }

    let translation;

    if (params != null) {
      if (params.n === 0 && cacheItem.variant0) {
        translation = cacheItem.variant0;
      } else if (params.n === 1 && cacheItem.variant1) {
        translation = cacheItem.variant1;
      } else if (typeof cacheItem.translation === 'string') {
        translation = cacheItem.translation;
      } else if (Number.isFinite(params.n)) {
        translation = cacheItem.resolveVariant(cacheItem.translation, params);
      } else {
        translation = cacheItem.translation.other;
      }
    } else if (typeof cacheItem.translation === 'string') {
      translation = cacheItem.translation;
    } else {
      translation = cacheItem.translation.other;
    }

    if (params != null) {
      // eslint-disable-next-line no-restricted-syntax
      for (const key in params) {
        if (Object.hasOwn(params, key)) {
          translation = translation.replaceAll(
            `{${key}}`,
            key === 'n' && Number.isFinite(params.n) && typeof formatN === 'function' ? formatN(params.n) : params[key],
          );
        }
      }
    }

    return translation;
  }

  return { languageCode, translate };
}

export { defaultLanguageCode };

export const defaultLocale = createLocale();

export async function loadLocale(languageCode, localeLoaders) {
  const { messages, resolveVariant } = await localeLoaders.get(languageCode)();
  return createLocale({ languageCode, messages, resolveVariant });
}

export function createT({ locale, formatNumber }) {
  /**
   * Translates the given message to the language specified in the user's localization preferences.
   * The `message` may contain any number of placeholders like `{someName}`, which are replaced by the matching `params`.
   * The `message` may contain up to 3 variants separated by `|` to support pluralization.
   * Examples:
   * - "Some tasks"
   * - "{n} task | {n} tasks"
   * - "No tasks | {n} task | {n tasks}"
   * The appropriate variant is selected based on the value of `params.n`.
   * The translations come from `./src/locale/` and can be populated automatically using `pnpm i18n`.
   * @param {string} message The message to translate.
   * @param {Object} [params] An optional object containing translation parameters.
   * @param {Function} [formatN] An optional function for formatting the `n` parameter.
   * @returns {string} A translated message.
   * @see https://teamwork.dev/platform/internationalization#message-translation for more details.
   */
  return function t(message, params, formatN) {
    return locale.value.translate(message, params, formatN ?? formatNumber);
  };
}
