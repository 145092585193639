import { useItemLoader } from '../base/useItemLoader';
import { useRealTimeUpdates } from '../base/useRealTimeUpdates';
import { useCheckoutRefV1Loader } from './useCheckoutRefV1Loader';
import { useCheckoutUtils } from './useCheckoutUtils';

function responseToItem({ data }) {
  return data;
}

export function useCheckoutV1Loader() {
  const { item: ref, inSync: refInSync } = useCheckoutRefV1Loader();
  const { checkoutBaseUrl } = useCheckoutUtils();
  const { state, refresh } = useItemLoader({
    url: computed(() => refInSync.value && `${checkoutBaseUrl.value}checkout.json?ref=${encodeURI(ref.value)}`),
    responseToItem,
  });

  useRealTimeUpdates((event) => {
    if (event.type === 'account' && event.action === 'edited') {
      refresh();
    }
  });

  return state;
}
