import { useIntervalFn } from '@vueuse/core';
import { DateTime } from 'luxon';
import semverGt from 'semver/functions/gt';
import semverMajor from 'semver/functions/major';
import semverValid from 'semver/functions/valid';
import { useLocalStorage } from '@/util';

const symbol = Symbol('useVersion');

// The version number from `./lightspeed/package.json` is inserted into
// the `data-teamwork-version` attribute on the `<html>` element at build time.
// This line must be the only place where we touch this attribute.
const version = document.documentElement.getAttribute('data-teamwork-version') || '';

function Version() {
  // We store `latestVersion` using `useLocalStorage` to keep it in sync in all browser tabs.
  const latestVersion = useLocalStorage('teamwork/useVersion/latestVersion', version);

  // `useVersion` runs right after the app has started, so we know for sure that the current version
  // is the latest version right now. We assign it here to keep other browser tabs in sync and to avoid
  // any risk of infinite redirects in case the latest version in `localStorage` is higher than the
  // version available on the server for any reason.
  latestVersion.value = version;

  // Check for the new version every 30 minutes.
  useIntervalFn(
    async () => {
      const response = await fetch('/app');
      if (response.ok) {
        const html = await response.text();
        const versionMatch = html.match(/data-teamwork-version="(\d+\.\d+\.\d+)"/);
        if (versionMatch) {
          const loadedVersion = versionMatch[1];
          latestVersion.value = loadedVersion;
        }
      }
    },
    1000 * 60 * 30,
  );

  const hasNewVersion = computed(() =>
    Boolean(semverValid(latestVersion.value) && semverValid(version) && semverGt(latestVersion.value, version)),
  );

  const hasNewMajorVersion = computed(() =>
    Boolean(
      semverValid(latestVersion.value) &&
        semverValid(version) &&
        semverMajor(latestVersion.value) > semverMajor(version),
    ),
  );

  const willUpgradeAt = shallowRef();
  const now = shallowRef(DateTime.now());

  const willUpgradeInSeconds = computed(() => {
    if (willUpgradeAt.value === undefined) {
      return undefined;
    }
    return Math.round((willUpgradeAt.value - now.value) / 1000);
  });

  // Reload the app now.
  function upgradeNow() {
    window.location.reload();
  }

  // Reload in 1 minute and retry every 5 minutes.
  function upgrade() {
    if (willUpgradeAt.value !== undefined) {
      return;
    }
    now.value = DateTime.now();
    willUpgradeAt.value = now.value.plus({ minute: 1 });
    setInterval(() => {
      now.value = DateTime.now();
      if (now.value >= willUpgradeAt.value) {
        upgradeNow();
        // If `window.onbeforeunload` returns `false`,
        // the browser displays the "Confirm Close" dialog
        // and all script execution is paused.
        // If the user cancels the reload, we will retry in 5 minutes.
        now.value = DateTime.now();
        willUpgradeAt.value = now.value.plus({ minute: 5 });
      }
    }, 1000);
  }

  // When a new major version is detected, reload as soon as possible.
  // This is for emergencies.
  watch(hasNewMajorVersion, () => {
    if (hasNewMajorVersion.value) {
      upgrade();
    }
  });

  // When a new version is detected, wait a day and reload after midnight.
  // We wait 5 days to minimize the forced reloads,
  // as most users should reload manually in the meantime.
  // We spread the reloads within 3 hours after midnight of the local time
  // in order to minimize user and infrastructure impact.
  watch(hasNewVersion, () => {
    if (hasNewVersion.value) {
      const upgradeAt = DateTime.now()
        .endOf('day')
        .plus({ day: 5, second: Math.round(Math.random() * 3 * 60 * 60) });
      const timeFromNow = upgradeAt.diffNow().rescale();
      setTimeout(upgrade, timeFromNow.toMillis());
      // eslint-disable-next-line no-console
      console.info(
        `New version detected (${latestVersion.value}). The app reload will be scheduled at ${upgradeAt.toString()} - in ${timeFromNow.toHuman()} === ${timeFromNow.toMillis()} milliseconds.`,
      );
    }
  });

  return {
    // The current version number.
    version,
    // The latest available version number.
    latestVersion: readonly(latestVersion),
    // Indicates if a new version is available.
    hasNewVersion,
    // Indicates if a new major version is available.
    hasNewMajorVersion,
    // A ref containing:
    // - a number indicating in how many seconds the app will be reloaded, if a new version is available
    // - `undefined`, if no new version is available
    willUpgradeInSeconds,
    // Triggers the app upgrade soon.
    upgrade,
    // Triggers the app upgrade now.
    upgradeNow,
  };
}

// Export the current version as a const, so that it could be used during initialization of the Vue app.
export const appVersion = version;

export const versionPlugin = {
  install(app) {
    app.provide(symbol, Version());
  },
};

export function useVersion() {
  return inject(symbol);
}
