import { useFeatures, useFormsV3Loader } from '@/api';

/**
 *
 * @returns {import('./useFeaturesLimit').FeatureLimitObject}
 */
export function useFormsLimit() {
  const { intakeFormsLimit: featureLimit, intakeFormsEnabled: isFeatureEnabled } = useFeatures();

  const { inSync, loaded, meta } = useFormsV3Loader({ count: 0, params: { skipCounts: false } });

  const loading = computed(() => !loaded.value || !inSync.value);

  const isOverFeatureLimit = computed(() => {
    if (loading.value) {
      return undefined;
    }

    if (featureLimit.value === -1) {
      return false;
    }

    if (meta.value?.totalCount >= featureLimit.value) {
      return true;
    }

    return undefined;
  });

  return {
    featureLimit,
    isFeatureEnabled,
    isOverFeatureLimit,
    loading,
  };
}
