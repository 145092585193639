<script setup>
import { usePermissions } from '@/api';
import { useI18n } from '@/util';
import { useLegacyBridge } from '@/module/legacy';

const { t } = useI18n();
const { canAddProjects } = usePermissions();
const { showLegacyModal } = useLegacyBridge();

function openTemplateProjectModal() {
  showLegacyModal({
    modalName: 'addOrEditProjectWizard',
    params: {
      type: 'projects-template',
    },
  });
}
</script>
<template>
  <LscEmptyState
    size="sm"
    class="my-8"
    :title="t('No templates yet')"
    :message="t('Template projects are a great way to get super organized')"
  >
    <template v-if="canAddProjects" #actions>
      <LscButton variant="primary" size="md" prependIcon="lsi-add" @click.stop="openTemplateProjectModal">
        {{ t('Add project template') }}
      </LscButton>
    </template>
  </LscEmptyState>
</template>
