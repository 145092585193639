import { isValidId } from '@/util';
import { useCompaniesV3Loader } from '../companies/useCompaniesV3Loader';
import { useLockdownV3Loader } from '../lockdown/useLockdownV3Loader';
import { usePeopleV3Loader } from '../people/usePeopleV3Loader';
import { useTeamsV1Loader } from '../teams/useTeamsV1Loader';

// TODO Get all assignee data from a single API endpoint.
// Ideally the backend API would give us a suitable list of assignees but until that is supported,
// we use a composite loader to load users followed by teams from separate API endpoints.
// Ideally the backend API would filter the assignees by a lockdownId but until that is supported,
// we load the lockdown object and perform the filtering on the client side.
export function useAssigneesCompositeLoader({
  count: _count = -1,
  loadPeople: _loadPeople = true,
  loadTeams: _loadTeams = true,
  loadCompanies: _loadCompanies = false,
  lockdownId: _lockdownId = undefined,
  lockdownUserIds: _lockdownUserIds = [],
  lockdownTeamIds: _lockdownTeamIds = [],
  lockdownCompanyIds: _lockdownCompanyIds = [],
  skipAdminsLockdown: _skipAdminsLockdown = true,
  forComments: _forComments = false,
  projectId: _projectId,
  taskId: _taskId,
  milestoneId: _milestoneId,
  peopleParams: _peopleParams = {},
  teamsParams: _teamsParams = {},
  companiesParams: _companiesParams = {},
}) {
  const count = shallowRef(_count);
  const loadPeople = shallowRef(_loadPeople);
  const loadTeams = shallowRef(_loadTeams);
  const loadCompanies = shallowRef(_loadCompanies);
  const lockdownId = shallowRef(_lockdownId);
  const projectId = shallowRef(_projectId);
  const taskId = shallowRef(_taskId);
  const milestoneId = shallowRef(_milestoneId);
  const forComments = shallowRef(_forComments);
  const peopleParams = shallowRef(_peopleParams);
  const teamsParams = shallowRef(_teamsParams);
  const companiesParams = shallowRef(_companiesParams);
  const skipAdminsLockdown = shallowRef(_skipAdminsLockdown);

  // We do client-side filtering, so we might need to request more items from the server,
  // so that we get the right number of items after filtering.
  const adjustedCount = shallowRef(count.value);

  const lockdownState = useLockdownV3Loader({
    lockdownId: computed(() => (count.value >= 0 ? lockdownId.value : undefined)),
    params: {
      include: 'users.companies,companies,teams',
    },
  });

  const lockdownUserIds = computed(() => {
    if (isValidId(lockdownId.value)) {
      return lockdownState.item.value?.grantAccessTo?.userIds ?? [];
    }

    return toValue(_lockdownUserIds);
  });

  const lockdownTeamIds = computed(() => {
    if (isValidId(lockdownId.value)) {
      return lockdownState.item.value?.grantAccessTo?.teamIds ?? [];
    }

    return toValue(_lockdownTeamIds);
  });

  const lockdownCompanyIds = computed(() => {
    if (isValidId(lockdownId.value)) {
      return lockdownState.item.value?.grantAccessTo?.companyIds ?? [];
    }

    return toValue(_lockdownCompanyIds);
  });

  function isUserAuthorized(person) {
    // If the user is an admin, they are authorized if "skipAdminsLockdown" is true.
    if (person.isAdmin && skipAdminsLockdown.value) {
      return true;
    }

    // If there are no lockdowns, all users are authorized.
    if (
      lockdownUserIds.value.length === 0 &&
      lockdownTeamIds.value.length === 0 &&
      lockdownCompanyIds.value.length === 0
    ) {
      return true;
    }

    // TODO Normalize the IDs in the loaders and remove the normalization from here.
    const normalizedLockdownUserIds = lockdownUserIds.value.map(Number);
    const normalizedLockdownTeamIds = lockdownTeamIds.value.map(Number);
    const normalizedLockdownCompanyIds = lockdownCompanyIds.value.map(Number);

    // If the user is in the lockdown, they are authorized.
    // TODO Normalize the IDs in the loaders and remove the normalization from here.
    if (
      normalizedLockdownUserIds.includes(Number(person.id)) ||
      person.teams?.some((team) => normalizedLockdownTeamIds.includes(Number(team.id))) ||
      normalizedLockdownCompanyIds.includes(Number(person.companyId))
    ) {
      return true;
    }

    // Otherwise the user is not authorized.
    return false;
  }

  const peopleCount = computed(() => {
    if (!loadPeople.value) {
      return -1;
    }
    return adjustedCount.value;
  });

  const peopleState = usePeopleV3Loader({
    projectId,
    params: peopleParams,
    count: peopleCount,
  });

  const peopleItems = computed(() =>
    peopleState.items.value
      .filter(isUserAuthorized)
      .map((person) => ({ id: `person-${person.id}`, type: 'person', person })),
  );

  function isTeamAuthorized(team) {
    // Filter teams by lockdown (if applicable)
    if (lockdownTeamIds.value.length) {
      // TODO IDs must be normalized in loaders and then `Number(...)` must be removed.
      return lockdownTeamIds.value.includes(Number(team.id));
    }

    return true;
  }

  const teamsCount = computed(() => {
    if (!loadTeams.value) {
      return -1;
    }

    if (!loadPeople.value) {
      return adjustedCount.value;
    }

    return peopleState.hasMore.value ? -1 : adjustedCount.value - peopleState.items.value.length;
  });

  const teamsState = useTeamsV1Loader({
    taskId,
    milestoneId,
    projectId,
    teamsForComments: forComments,
    params: teamsParams,
    count: teamsCount,
  });

  const teamsItems = computed(() =>
    teamsState.items.value.filter(isTeamAuthorized).map((team) => ({ id: `team-${team.id}`, type: 'team', team })),
  );

  function isCompanyAuthorized(company) {
    // Filter companies by lockdown (if applicable)
    if (lockdownCompanyIds.value.length) {
      // TODO IDs must be normalized in loaders and then `Number(...)` must be removed.
      return lockdownCompanyIds.value.includes(Number(company.id));
    }

    return true;
  }

  const companiesCount = computed(() => {
    if (!loadCompanies.value) {
      return -1;
    }
    if (!loadPeople.value && !loadTeams.value) {
      return adjustedCount.value;
    }
    if ((loadPeople.value && peopleState.hasMore.value) || (loadTeams.value && teamsState.hasMore.value)) {
      return -1;
    }
    return adjustedCount.value - peopleState.items.value.length - teamsState.items.value.length;
  });

  const companiesState = useCompaniesV3Loader({
    projectId,
    params: companiesParams,
    count: companiesCount,
  });

  const companiesItems = computed(() =>
    companiesState.items.value
      .filter(isCompanyAuthorized)
      .map((company) => ({ id: `company-${company.id}`, type: 'company', company })),
  );

  const items = computed(() => peopleItems.value.concat(teamsItems.value).concat(companiesItems.value));

  const hasMore = computed(() =>
    Boolean(
      (loadPeople.value && peopleState.hasMore.value) ||
        (loadTeams.value && teamsState.hasMore.value) ||
        (loadCompanies.value && companiesState.hasMore.value),
    ),
  );

  const inSync = computed(
    () =>
      lockdownState.inSync.value && peopleState.inSync.value && teamsState.inSync.value && companiesState.inSync.value,
  );

  const error = computed(() => {
    // We should not need to check for the cache errors,
    // see https://digitalcrew.teamwork.com/app/tasks/22184779
    if (lockdownState.error.value) {
      return lockdownState.error.value;
    }
    if (peopleState.error.value) {
      return peopleState.error.value;
    }
    if (teamsState.error.value) {
      return teamsState.error.value;
    }
    if (companiesState.error.value) {
      return companiesState.error.value;
    }
    return undefined;
  });

  const loaded = computed(() => {
    if (Number.isInteger(lockdownId.value) && !lockdownState.loaded.value) {
      return false;
    }
    if (items.value.length === 0) {
      if (loadPeople.value && peopleState.hasMore.value) {
        return false;
      }
      if (loadTeams.value && teamsState.hasMore.value) {
        return false;
      }
      if (loadCompanies.value && companiesState.hasMore.value) {
        return false;
      }
    }
    return true;
  });

  watch(count, (newCount, oldCount) => {
    if (newCount < oldCount || adjustedCount.value < newCount) {
      adjustedCount.value = newCount;
    }
  });

  watch(
    () =>
      items.value.length < count.value && inSync.value && hasMore.value ? adjustedCount.value + 1 : adjustedCount.value,
    (newAdjustedCount) => {
      adjustedCount.value = newAdjustedCount;
    },
  );

  return {
    items,
    hasMore,
    inSync,
    itemInSync: (item) =>
      peopleState.itemInSync(item) || teamsState.itemInSync(item) || companiesState.itemInSync(item),
    loaded,
    meta: computed(() => undefined),
    error,
    retry: () => {
      lockdownState.retry();
      peopleState.retry();
      teamsState.retry();
      companiesState.retry();
    },
  };
}
