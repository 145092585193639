import { useListLoader } from '../base/useListLoader';
import { useRealTimeUpdates } from '../base/useRealTimeUpdates';

function responseToItems(response) {
  const {
    notifications = {},
    companies = {},
    projects = {},
    tasklistBudgets = {},
    teams = {},
    users = {},
  } = response.data.included;

  const tasklists = response.data.tasklists || [];
  const tasklistTaskStats = response.data.included?.tasklistTaskStats || [];

  /* eslint-disable no-param-reassign */
  tasklists.forEach((tasklist) => {
    tasklist.id = Number(tasklist.id);
    tasklist.projectId = Number(tasklist.projectId);
    tasklist.tasklistTaskStats = tasklistTaskStats[tasklist.id] || {};

    if (projects[tasklist.projectId]) {
      tasklist.project = projects[tasklist.projectId];
    }

    if (tasklist.tasklistBudget && tasklistBudgets[tasklist.tasklistBudget.id]) {
      const tasklistBudget = tasklistBudgets[tasklist.tasklistBudget.id];

      tasklistBudget.type = tasklistBudget.type.toLowerCase();
      tasklistBudget.notifications?.forEach((_notification, index) => {
        if (notifications[_notification.id]) {
          const notification = notifications[_notification.id];
          notification.notificationMedium = notification.notificationMedium.toLowerCase();
          notification.users = notification.users.map((user) => {
            return users[user.id] ?? user;
          });
          notification.teams = notification.teams.map((_team) => {
            const team = teams[_team.id] ?? _team;
            team.teamId = team.id;
            return team;
          });
          notification.companies = notification.companies.map((_company) => {
            const company = companies[_company.id] ?? _company;
            company.companyId = company.id;
            return company;
          });
          tasklistBudget.notifications[index] = notification;
        }
      });
      tasklist.tasklistBudget = tasklistBudget;
    }
  });
  /* eslint-enable no-param-reassign */
  return tasklists;
}

/**
 * Loads a list of task lists from the Teamwork v3 API.
 * @param {Object} options
 */
export function useTemplateTasklistsV3Loader({ params, count, pageSize = 200 }) {
  const url = computed(() => {
    return '/projects/api/v3/tasklists/templates.json';
  });

  const { state, refresh } = useListLoader({
    url,
    params,
    count,
    responseToItems,
    pageSize,
  });

  useRealTimeUpdates((event) => {
    // If many tasks changed, reload all task lists.
    // The event name is misleading, as task modifications are not limited to a single project.
    if (event.type === 'projectTasks') {
      refresh();
      return;
    }

    // If filtering by projectId, it must match event.projectId.
    // if (projectId.value && projectId.value !== event.projectId) {
    //   return;
    // }

    if (event.action === 'reposition') {
      refresh();
      return;
    }

    // Only events of these types can affect task list properties.
    if (event.type !== 'task' && event.type !== 'tasklist' && event.type !== 'tasklistTasks') {
      return;
    }

    // Only specific task actions can affect a task list.
    if (
      event.type === 'task' &&
      event.action !== 'reopened' &&
      event.action !== 'completed' &&
      event.action !== 'new' &&
      event.action !== 'deleted'
    ) {
      return;
    }

    refresh(event.taskListId);
  });

  return state;
}
