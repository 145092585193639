<script setup>
import { useRealTimeUpdates } from '../base/useRealTimeUpdates';
import { provideTWIMSocket } from '../base/useTWIMSocket';
import { provideLaunchDarkly } from '../features/useLaunchDarkly';

provideLaunchDarkly();
useRealTimeUpdates().emitFromSocket(provideTWIMSocket());
</script>
<template>
  <slot />
</template>
