/**
 * Checks if the specified node is any type of text input element.
 * @param {*} node A DOM node to test.
 * @returns {boolean} True if the node is a text input element.
 */
export function isTextInput(node) {
  return (
    Boolean(node) &&
    node.nodeType === Node.ELEMENT_NODE &&
    node.matches(
      'input, textarea, [contenteditable=""], [contenteditable=true], .CodeMirror, .ql-editor, .mce-content-body',
    )
  );
}
