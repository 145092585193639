<script setup>
import { useI18n } from '@/util';

const { t } = useI18n();
</script>
<template>
  <div class="text-body-1">
    <p>
      {{
        t(
          'Messages are for larger-scale communications about a project as whole. They’re normally meant for a bigger audience and are about the entire project.',
        )
      }}
    </p>
  </div>
</template>
