import {
  ONBOARDING_EVENT_COMMON_METRICS as commonMetrics,
  ONBOARDING_EVENT_NAME as eventName,
} from '@/module/onboarding';
import { useCohort } from '../cohort/useCohort';
import { useFeatures } from '../features/useFeatures';
import { usePendo } from '../features/usePendo';

/**
 * Constants, computed properties and functions related to Experiment A-23-01
 * https://app.launchdarkly.com/default/staging/features/projects-exp-a-23-01-finance-onboarding-v2/targeting
 * https://digitalcrew.teamwork.com/spaces/growth/page/41226-a-23-01-finance-onboarding-wizard-v2
 */

export function useExperimentA2301() {
  const { isICP, isCompanySizeAboveTen } = useCohort();
  const { projectsFinanceOnboardingV2Enabled } = useFeatures();
  const { trackExperimentInPendo, trackPendoEvent } = usePendo();

  const { launchDarklyFlagKey, defaultValue } = projectsFinanceOnboardingV2Enabled;

  const appLevelTargeting = computed(() => isICP.value && isCompanySizeAboveTen.value);
  const isExpA2301Variation = computed(() => projectsFinanceOnboardingV2Enabled.value && appLevelTargeting.value);

  function trackExperimentA2301() {
    trackExperimentInPendo({
      launchDarklyFlagKey,
      defaultValue,
      ignoreInExperimentOnRuleMatch: true,
      appLevelTargeting,
    });
  }

  function trackExperimentA2301Variation() {
    if (!isExpA2301Variation.value) {
      return;
    }

    trackPendoEvent({
      eventName: 'A-23-01_TEST_GROUP',
      commonMetrics,
      metadata: { event_action: 'entered_test_group_a-23-01' },
    });
  }

  function track(metadata) {
    trackPendoEvent({
      eventName,
      commonMetrics,
      metadata,
    });
  }

  function trackProjectTypeSelected(projectType) {
    track({
      event_action: 'project_type_selected',
      project_type: projectType,
      page_type: 'project_client_project',
    });
  }

  function trackBillingTypeClicked(billingType) {
    if (!billingType) {
      return;
    }

    track({
      event_action: 'billing_type_clicked',
      billing_type_clicked: billingType,
      page_type: 'project_select_billing_type',
    });
  }

  function trackIDontKnowYet() {
    track({
      event_action: 'i_dont_know_yet_clicked',
      page_type: 'project_select_billing_type',
    });
  }

  function trackBudgetTypeSelected(budgetType) {
    track({
      event_action: 'budget_type_selected',
      budget_type_selected: budgetType,
      page_type: 'project_set_budget',
    });
  }

  function trackBudgetPeriodSelected(budgetPeriod) {
    track({
      event_action: 'budget_period_selected',
      budget_period_chosen: budgetPeriod,
      page_type: 'project_set_budget',
    });
  }

  function trackTooltipHovered(tooltip, billingType) {
    track({
      event_action: 'tooltip_hovered',
      tooltip,
      page_type: 'project_set_team_rates',
      billing_type: billingType,
    });
  }

  function trackDefaultRateEntered(billingType) {
    const metadata = { event_action: 'default_rate_entered', page_type: 'project_set_team_rates' };
    if (billingType) {
      metadata.billing_type = billingType;
    }
    track(metadata);
  }

  function trackBillableRateEntered(billingType) {
    const metadata = {
      event_action: 'billable_rate_entered',
      page_type: 'project_set_team_rates',
    };

    if (billingType) {
      metadata.billing_type = billingType;
    }
    track(metadata);
  }

  function trackCostRateEntered(billingType) {
    const metadata = {
      event_action: 'cost_rate_entered',
      page_type: 'project_set_team_rates',
    };

    if (billingType) {
      metadata.billing_type = billingType;
    }
    track(metadata);
  }

  return {
    isExpA2301Variation,
    trackExperimentA2301,
    trackExperimentA2301Variation,
    trackProjectTypeSelected,
    trackBillingTypeClicked,
    trackIDontKnowYet,
    trackBudgetTypeSelected,
    trackBudgetPeriodSelected,
    trackTooltipHovered,
    trackDefaultRateEntered,
    trackBillableRateEntered,
    trackCostRateEntered,
  };
}
