<script setup>
import { useAppShellSidebar } from '@/appShell';

defineProps({
  navItem: {
    type: String,
    required: true,
  },
  tasklists: {
    type: Array,
    required: true,
  },
});
const { clearActiveDrawerPanelIfNotPinned } = useAppShellSidebar();
</script>
<template>
  <VListItem
    v-for="tasklist in tasklists"
    :key="tasklist.id"
    :to="`/tasklists/${tasklist.id}/${navItem.id}`"
    link
    density="compact"
    v-bind="VListItemSidebarProjectsDrawer"
    @click="clearActiveDrawerPanelIfNotPinned"
  >
    <template #prepend>
      <div class="flex size-6 items-center justify-center">
        <template v-if="tasklist.icon">{{ tasklist.icon }}</template>
        <LscIcon v-else size="sm" :icon="navItem.icon" class="text-icon-subtle" />
      </div>
    </template>
    <VListItemTitle :title="tasklist.name" class="ml-2 text-body-1">{{ tasklist.name }}</VListItemTitle>
  </VListItem>
</template>
