<script setup>
import { useI18n } from '@/util';
import SpacesDrawerSpaceVListItem from './SpacesDrawerSpaceVListItem.vue';

const props = defineProps({
  state: {
    type: Object,
    required: true,
  },
  step: {
    type: Number,
    default: 10,
  },
});

const count = defineModel('count', { type: Number, required: true });

const { t } = useI18n();

const spaces = computed(() => unref(props.state.items));
</script>

<template>
  <LswLoaderState :state="state">
    <template #default>
      <VList density="compact" class="m-0 !px-4 !pt-4">
        <SpacesDrawerSpaceVListItem v-for="space in spaces" :key="space.id" :space="space" />
        <LscLoadingProgress v-if="!state.inSync.value" />
        <LswLoaderTrigger v-model:count="count" :step="step" :state="state" />
      </VList>
    </template>
    <template #loading>
      <LscLoadingProgress :message="t('Loading spaces')" />
    </template>
    <template #blank>
      <slot name="blank" />
    </template>
  </LswLoaderState>
</template>
