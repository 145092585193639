<script setup>
import { refDebounced } from '@vueuse/core';
import { DateTime } from 'luxon';
import { useCustomfieldsV3Loader } from '@/api';
import { useI18n } from '@/util';
import { customfieldTypeIcons } from '@/module/customfield';
import FilterCustomFieldMenu from './customField/FilterCustomFieldMenu.vue';
import { getDaysDiff, toCustomFieldsArray, toCustomFieldsString } from './filterUtil';
import { useFilter, useFilterChips, useFilterClear, useFilterCount, useFilterNormalize } from './useFilter';

const props = defineProps({
  projectId: {
    type: Number,
    default: 0,
  },
  entities: {
    type: String,
    required: true,
  },
});

const { t, formatDate } = useI18n();
const { activeFilter, chips, count: filtersCount } = useFilter();

const customFieldMenuOpen = shallowRef(false);
const count = shallowRef(-1);
const searchTerm = shallowRef('');
const debouncedSearchTerm = refDebounced(searchTerm, 300);

const state = useCustomfieldsV3Loader({
  projectId: computed(() => props.projectId),
  params: computed(() => ({
    projectId: props.projectId,
    entities: props.entities,
    onlySiteLevel: false,
    searchTerm: debouncedSearchTerm.value,
  })),
  count,
});

const { items: loadedCustomFields, loaded } = state;

const noCustomFields = computed(() => loadedCustomFields.value.length === 0 && debouncedSearchTerm.value === '');

const filterCustomFields = computed({
  get() {
    return toCustomFieldsArray(activeFilter.value.parameters.customFields);
  },
  set(newCustomFields) {
    activeFilter.value.parameters.customFields = toCustomFieldsString(newCustomFields);
  },
});

function parseOperator({ type, operator, value }) {
  if (type === 'text-short') {
    if (operator === 'like') {
      if (value.startsWith('%') && value.endsWith('%')) {
        return t('contains');
      }
      if (value.endsWith('%')) {
        return t('starts with');
      }
      if (value.startsWith('%')) {
        return t('ends with');
      }
    }
    if (operator === 'eq') {
      return t('matches');
    }
    if (operator === 'not-like') {
      return t('does not contain');
    }
  } else if (type === 'number-integer') {
    if (operator === 'eq') {
      return '=';
    }
    if (operator === 'lt') {
      return '<';
    }
    if (operator === 'gt') {
      return '>';
    }
  } else if (type === 'url') {
    if (operator === 'like') {
      return t('contains');
    }
    if (operator === 'not-like') {
      return t('does not contain');
    }
  } else if (type === 'dropdown' || type === 'status') {
    if (operator === 'eq') {
      return '=';
    }
    if (operator === 'any') {
      return t('any of');
    }
    if (operator === 'not') {
      return t('none of');
    }
  } else if (type === 'checkbox') {
    if (value) {
      return t('is checked');
    }
    return t('is not checked');
  } else if (type === 'date') {
    if (operator === 'today') {
      return t('Today');
    }
    if (operator === 'yesterday') {
      return t('Yesterday');
    }
  }
  return null;
}

// A range can only be applied to dates and numbers
function parseRangeOperator({ name, operator, value }) {
  let [fromValue, toValue] = value || [];

  switch (operator) {
    case 'thisweek':
      return `${name}: ${t('This week')}`;
    case 'lastweek':
      return `${name}: ${t('Last week')}`;
    case 'thismonth':
      return `${name}: ${t('This month')}`;
    case 'lastmonth':
      return `${name}: ${t('Last month')}`;
    case 'last3months':
      return `${name}: ${t('Last 3 months')}`;
    case 'last6months':
      return `${name}: ${t('Last 6 months')}`;
    case 'withinprev':
      return `${name}: ${t('Within previous 1 day | Within previous {n} days', {
        n: getDaysDiff(fromValue, toValue),
      })}`;
    case 'custom':
      fromValue = formatDate(DateTime.fromISO(fromValue), 'short');
      toValue = formatDate(DateTime.fromISO(toValue), 'short');
      return t('{customFieldName}: from {from} to {to}', { customFieldName: name, from: fromValue, to: toValue });
    default:
      return t('{customFieldName}: from {from} to {to}', { customFieldName: name, from: fromValue, to: toValue });
  }
}

function parseCustomFieldForChip(customField) {
  const { name, type, value } = customField;
  const isArray = Array.isArray(value);
  if (isArray) {
    return parseRangeOperator(customField);
  }

  if (type === 'date') {
    return `${name}: ${parseOperator(customField)}`;
  }

  return `${name} ${parseOperator(customField)} ${typeof value === 'string' ? value.replaceAll('%', '') : ''}`;
}

function deleteCustomFieldFilter(fieldId) {
  filterCustomFields.value = filterCustomFields.value.filter(({ fieldId: id }) => id !== fieldId);
}

useFilterChips(
  computed(() => {
    // If the search term is present, return the chips as they are
    // This is to prevent the chips showing applied custom fields name `undefined`
    // If they are not in the search results
    // TODO We should use `useItemCache` as in the [docs](https://teamwork.dev/platform/filters) and other filters, for example `FilterProjectPicker`.
    if (debouncedSearchTerm.value) {
      return chips.value?.filter(({ id }) => filterCustomFields.value.some(({ id: fieldId }) => fieldId === id));
    }

    return filterCustomFields.value
      .map((appliedCustomField) => {
        // Custom field may take some time to load especially when the user has a lot of custom fields
        // And the applied custom field may not be available in the first page of the list
        const customField = loadedCustomFields.value.find(({ id }) => id === appliedCustomField.fieldId);
        if (!customField) {
          return null;
        }
        const chip = { id: customField.id, name: customField.name, type: customField.type, ...appliedCustomField };
        return {
          id: chip.id,
          name: parseCustomFieldForChip(chip),
          icon: customfieldTypeIcons[chip.type],
          delete: () => deleteCustomFieldFilter(chip.fieldId),
        };
      })
      .filter(Boolean);
  }),
);

useFilterCount(computed(() => filterCustomFields.value.length));
useFilterNormalize('customFields', toCustomFieldsString);
useFilterClear('customFields', toCustomFieldsString([]));

// Make sure the custom field menu is closed when the filter count changes
// This is to prevent the menu from staying open when a quick filter is applied or user clicks clear all
watch(filtersCount, () => {
  customFieldMenuOpen.value = false;
});

function toggleMenu(opened) {
  searchTerm.value = '';
  if (!opened || count.value >= 0) {
    return;
  }
  count.value = Infinity;
}
</script>

<template>
  <FilterCustomFieldMenu
    v-model:searchTerm="searchTerm"
    :closeOnContentClick="false"
    location="bottom left"
    :projectId="projectId"
    :customFields="loadedCustomFields"
    :noCustomFields="noCustomFields"
    :searchLoading="!loaded"
    @update:modelValue="toggleMenu"
  >
    <template #activator="activator">
      <LscButton
        variant="plain-secondary"
        v-bind="activator.props"
        class="my-1 w-fit justify-start"
        prependIcon="lsi-add"
      >
        {{ t('Add custom field filter') }}
      </LscButton>
    </template>
  </FilterCustomFieldMenu>
</template>
