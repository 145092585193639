<script setup>
defineProps({
  timer: {
    type: Object,
    default: null,
  },
  project: {
    type: Object,
    default: null,
  },
});

const TimeTimerAddOrEditDialogPanel = defineAsyncComponent(() => import('./TimeTimerAddOrEditDialogPanel.vue'));
</script>

<template>
  <LscDialog>
    <template #activator="activator"><slot name="activator" v-bind="activator" /></template>
    <template #default="{ close }">
      <TimeTimerAddOrEditDialogPanel :project="project" :timer="timer" @close="close" />
    </template>
  </LscDialog>
</template>
