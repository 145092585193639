import { DateTime } from 'luxon';
import { useItemLoader } from '../base/useItemLoader';
import { useRealTimeUpdates } from '../base/useRealTimeUpdates';

function responseToItem({ data: { user } }) {
  /* eslint-disable no-param-reassign */
  user.createdAt = DateTime.fromISO(user.createdAt);
  user.updatedAt = DateTime.fromISO(user.updatedAt);
  /* eslint-enable no-param-reassign */
  return user;
}

export function useDeskCurrentUserLoader({ params, active: _active = true } = {}) {
  const active = shallowRef(_active);

  const { state, refresh } = useItemLoader({
    url: computed(() => active.value && '/desk/api/v2/me.json'),
    params,
    responseToItem,
  });

  useRealTimeUpdates((event) => {
    if (event.type === 'deskAccount' && event.action === 'enabled') {
      refresh();
    }
  });

  return state;
}
