const symbol = Symbol('useBudgetCounts');

export function provideBudgetCountsState(budgetCountsState) {
  provide(symbol, budgetCountsState);
  return budgetCountsState;
}

export function useBudgetCountsState() {
  return inject(symbol);
}

export function useBudgetCounts() {
  return useBudgetCountsState().item;
}
