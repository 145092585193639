<script setup>
import { useKnowledgeBaseCategoriesLoader, usePendo } from '@/api';
import { useI18n } from '@/util';
import { useHelpCenter } from '../..';
import { STATE_KNOWLEDGE_BASE_SUBCATEGORIES_AND_ARTICLES } from '../../constants';
import HelpCenterDrawerMenuLarge from '../HelpCenterDrawerMenuLarge.vue';
import HelpCenterDrawerMenuMedium from '../HelpCenterDrawerMenuMedium.vue';

const ICONS_MAPPING = {
  463: 'lsi-resources',
  465: 'lsi-dashboard',
  475: 'lsi-working-projects',
  467: 'lsi-integrations',
  1146: 'lsi-tips',
  486: 'lsi-settings',
  1141: 'lsi-planning-managing',
  1145: 'lsi-subscription',
  3399: 'lsi-agency',
};

const DEFAULT_ICON = 'lsi-project';

const { trackPendoEvent } = usePendo();
const { pushState, HELP_CENTER_COMMON_METRICS } = useHelpCenter();
const { t } = useI18n();
const state = useKnowledgeBaseCategoriesLoader({ count: Infinity });
const { items: categories, inSync: categoriesInSync } = state;

const displayedCategories = shallowRef([]);

const formattedCategories = computed(() =>
  displayedCategories.value
    .filter((category) => category.displayOnDocHomepage)
    .map((category) => {
      return {
        iconCss: 'bg-[--help-center-contact-icon-color]',
        icon: ICONS_MAPPING[category.id] ?? DEFAULT_ICON,
        ...category,
      };
    }),
);

function handleCategoryClicked(slug, name) {
  trackPendoEvent({
    eventName: 'HELP_EVENT',
    commonMetrics: HELP_CENTER_COMMON_METRICS,
    metadata: {
      event_action: 'knowledge_base_category_clicked',
      event_label: name.toLowerCase(),
    },
  });

  pushState(STATE_KNOWLEDGE_BASE_SUBCATEGORIES_AND_ARTICLES, {
    categorySlug: slug,
    categoryName: name,
    breadcrumb: `Knowledge Base / ${name}`,
  });
}

watch(categoriesInSync, () => {
  if (categoriesInSync.value) {
    displayedCategories.value = categories.value;
  }
});
</script>
<template>
  <div class="m-0 h-full max-h-[inherit] overflow-y-auto p-0">
    <div class="mx-6 flex flex-col gap-6 pb-6">
      <LswLoaderState :state="state">
        <HelpCenterDrawerMenuLarge
          iconSize="sm"
          :items="formattedCategories"
          :title="t('Knowledge Base')"
          @itemClicked="handleCategoryClicked($event.slug, $event.title)"
        />
        <HelpCenterDrawerMenuMedium
          :items="[
            {
              title: t('Open in our support website'),
              icon: 'lsi-website',
              hoverAppendIcon: 'lsi-external-link',
              href: 'https://support.teamwork.com/projects',
            },
          ]"
        />
      </LswLoaderState>
    </div>
  </div>
</template>
