import { usePendo } from '@/api';
import { useRoute } from '@/route';

const EVENT_NAME = 'TIMER_EVENT';
const COMMON_METRICS = ['plan_name', 'user_role', 'page', 'on_trial', 'is_icp', 'is_paid'];

/**
 * Provides functions for tracking events related to timer
 * Measurement plan: https://digitalcrew.teamwork.com/spaces/product-analytics/page/53529-timer-phase-i
 */
export function useTimeTimerTracking() {
  const { trackPendoEvent: trackEvent, EVENT_CATEGORIES } = usePendo();
  const { DISCOVERY, ADVANCED_DISCOVERY, ACTIVATION, STANDARD_INTERACTION } = EVENT_CATEGORIES;
  const route = useRoute();

  function track(data) {
    trackEvent({
      eventName: EVENT_NAME,
      commonMetrics: COMMON_METRICS,
      metadata: {
        open_source: route.path,
        ...data,
      },
    });
  }

  /**
   * Track when the user starts a timer
   * @param {string=} source - The source of the event
   */
  function trackTimerStartClicked(source) {
    track({
      event_action: 'timer_start_clicked',
      event_category: DISCOVERY,
      ...(source && { open_source: source }),
    });
  }

  /**
   * Track the timer action performed in the My Timesheet row
   * @param {('timer_start_clicked' | 'timer_resume_clicked' | 'timer_pause_clicked')} action - The action that was performed
   * @param {('main_row' | 'main_cta')} openSource - The source of the event
   * @param {('sheet_view' | 'calendar_view')} activeView - The active view of the timesheet
   */
  function trackTimerMyTimesheetAction(action, openSource, activeView) {
    track({
      page_type: 'my_timesheet',
      event_action: action,
      event_category: ACTIVATION,
      active_view: activeView,
      open_source: openSource,
    });
  }

  /**
   * Track the Timer dialog displayed
   */
  function trackTimerDialogDisplayed() {
    track({
      event_action: 'timer_modal_displayed',
      event_category: DISCOVERY,
    });
  }

  /**
   * Track the action performed in the Timer dialog when it is closed
   * @param {('close_clicked' | 'X')} action - The close action that was performed
   */
  function trackTimerDialogClosed(action) {
    track({
      event_action: 'timer_modal_closed',
      event_category: STANDARD_INTERACTION,
      page_type: 'timer_modal',
      close_type: action,
    });
  }

  /**
   * Track when the user clicks the delete button in the Timer dialog
   */
  function trackTimerDialogDeleteClicked() {
    track({
      event_action: 'timer_delete_clicked',
      event_category: ADVANCED_DISCOVERY,
      page_type: 'edit_timer_modal',
    });
  }

  /**
   * Track when the user clicks the start timer button in the Timer dialog
   */
  function trackTimerDialogStartTimerClicked() {
    track({
      event_action: 'timer_modal_start_clicked',
      event_category: ADVANCED_DISCOVERY,
      page_type: 'timer_modal',
    });
  }

  /**
   * Track when the user clicks the update timer button in the Timer dialog
   */
  function trackTimerDialogUpdateTimerClicked() {
    track({
      event_action: 'timer_updated',
      event_category: ACTIVATION,
      page_type: 'edit_timer_modal',
    });
  }

  /**
   * Track the error displayed in the Timer dialog
   */
  function trackTimerDialogErrorDisplayed() {
    track({
      event_action: 'timer_modal_error_displayed',
      event_category: ADVANCED_DISCOVERY,
      page_type: 'timer_modal',
    });
  }

  /**
   * Track when the timer starts in the Timer dialog
   * @param {boolean} hasTask - Whether a task is selected
   * @param {boolean} hasDescription - Whether a description is added
   * @param {boolean} isBillable - Whether the time is billable
   */
  function trackTimerDialogTimerStarted(hasTask, hasDescription, isBillable) {
    track({
      event_action: 'timer_started',
      event_category: ACTIVATION,
      page_type: 'timer_modal',
      task_selected: hasTask,
      description_added: hasDescription,
      billable_time: isBillable,
    });
  }

  /**
   * Track the action performed in the Timer widget
   * @param {('timer_stop_clicked' | 'timer_pause_clicked' | 'timer_resume_clicked' | 'timer_edit_clicked')} action - The action that was performed
   */
  function trackTimerWidgetAction(action) {
    track({
      event_category: ADVANCED_DISCOVERY,
      page_type: 'timer_widget',
      event_action: action,
    });
  }

  /**
   * Track when the timer widget is moved
   */
  function trackTimerWidgetMoved() {
    track({
      event_action: 'timer_widget_moved',
      event_category: STANDARD_INTERACTION,
      page_type: 'timer_widget',
    });
  }

  /**
   * Track when the time log modal is displayed from the timer widget
   */
  function trackTimerTimeLogModalDisplayed() {
    track({
      event_action: 'time_log_modal_displayed',
      event_category: ADVANCED_DISCOVERY,
      page_type: 'time_log_modal',
      event_source: 'timer_stopped',
    });
  }

  /**
   * Track when the cancel button is clicked in the time log modal that is displayed from the timer widget
   */
  function trackTimerTimeLogModalCancelClicked() {
    track({
      event_action: 'cancel_clicked',
      event_category: STANDARD_INTERACTION,
      page_type: 'time_log_modal',
      event_source: 'timer_stopped',
    });
  }

  /**
   * Track when the time is logged in the time log modal that is displayed from the timer widget
   */
  function trackTimerTimeLogModalTimeLogged() {
    track({
      event_action: 'time_logged',
      event_category: ACTIVATION,
      page_type: 'time_log_modal',
      event_source: 'timer_stopped',
    });
  }

  return {
    trackTimerStartClicked,
    trackTimerMyTimesheetAction,
    trackTimerDialogDisplayed,
    trackTimerDialogClosed,
    trackTimerDialogDeleteClicked,
    trackTimerDialogStartTimerClicked,
    trackTimerDialogUpdateTimerClicked,
    trackTimerDialogErrorDisplayed,
    trackTimerDialogTimerStarted,
    trackTimerWidgetAction,
    trackTimerWidgetMoved,
    trackTimerTimeLogModalDisplayed,
    trackTimerTimeLogModalCancelClicked,
    trackTimerTimeLogModalTimeLogged,
  };
}
