import { DateTime } from 'luxon';
import { useListLoader } from '../base/useListLoader';
import { useOptimisticUpdates } from '../base/useOptimisticUpdates';
import { useRealTimeUpdates } from '../base/useRealTimeUpdates';

function responseToItems(response) {
  const { notifications = {}, companies = {}, projects = {}, teams = {}, users = {} } = response.data.included;

  const projectBudgets = response.data.budgets || [];

  /* eslint-disable no-param-reassign */
  projectBudgets.forEach((budget) => {
    if (budget.isRetainer) {
      budget.budgetCategory = 'retainer';
    } else if (budget.budgetCategory) {
      budget.budgetCategory = budget.budgetCategory.toLowerCase();
    } else {
      budget.budgetCategory = 'standard';
    }
    budget.repeatUnit = budget.repeatUnit.toLowerCase();
    budget.status = budget.status.toLowerCase();
    budget.timelogType = budget.timelogType.toLowerCase();
    budget.type = budget.type.toLowerCase();
    budget.startDateTime = budget.startDateTime ? DateTime.fromISO(budget.startDateTime) : null;
    budget.endDateTime = budget.endDateTime ? DateTime.fromISO(budget.endDateTime) : null;
    budget.completedAt = budget.completedAt ? DateTime.fromISO(budget.completedAt) : null;
    budget.endDate = budget.endDate ? DateTime.fromISO(budget.endDate) : null;
    budget.startDate = budget.startDate ? DateTime.fromISO(budget.startDate) : null;
    budget.originatorStartDateTime = budget.originatorStartDateTime
      ? DateTime.fromISO(budget.originatorStartDateTime)
      : null;
    budget.originatorStartDate = budget.originatorStartDate ? DateTime.fromISO(budget.originatorStartDate) : null;
    budget.finalizerEndDate = budget.finalizerEndDate ? DateTime.fromISO(budget.finalizerEndDate) : null;

    if (projects[budget.projectId]) {
      budget.project = projects[budget.projectId];
    }

    budget.notifications.forEach((_notification, index) => {
      if (notifications[_notification.id]) {
        const notification = notifications[_notification.id];
        notification.notificationMedium = notification.notificationMedium.toLowerCase();
        notification.users = notification.users.map((_user) => {
          const user = users[_user.id] ?? _user;
          user.entityType = 'user';
          return user;
        });
        notification.teams = notification.teams.map((_team) => {
          const team = teams[_team.id] ?? _team;
          team.teamId = team.id;
          team.entityType = 'team';
          return team;
        });
        notification.companies = notification.companies.map((_company) => {
          const company = companies[_company.id] ?? _company;
          company.companyId = company.id;
          company.entityType = 'company';
          return company;
        });
        budget.notifications[index] = notification;
      }
    });
    /* eslint-enable no-param-reassign */
  });

  return projectBudgets;
}

// Gets project budgets metadata from an axios response.
function responseToMeta({ data: { meta } }) {
  return { totalCount: meta.page?.count };
}

/**
 * Loads a list of budgets from the Teamwork v3 API.
 */
export function useProjectBudgetsV3Loader({ params, count, pageSize = 50 }) {
  const { state, update, refresh } = useListLoader({
    url: '/projects/api/v3/projects/budgets.json',
    params,
    count,
    responseToItems,
    responseToMeta,
    pageSize,
  });

  useOptimisticUpdates((event) => {
    if (event.type !== 'budget') {
      return;
    }
    update((budgets) => {
      if (event.action === 'create') {
        return budgets.concat(event.budget);
      }
      if (event.action === 'update') {
        return budgets.map((budget) => (budget.id === event.budget.id ? { ...budget, ...event.budget } : budget));
      }
      if (event.action === 'delete') {
        return budgets.filter((budget) => budget.id !== event.budget.id);
      }
      return budgets;
    }, event.promise);
  });

  useRealTimeUpdates((event) => {
    // budget
    if (event.type === 'budget') {
      refresh(event.budgetId);
      return;
    }

    // budget expense or tasklist budget
    if (event.type === 'budgetExpense' || event.type === 'tasklistBudget') {
      refresh(event.projectBudgetId);
      return;
    }

    // timelog or project rate change
    if ((event.type === 'time' && event.timelogId) || (event.type === 'project' && event.action === 'rate-set')) {
      const budget = state.items.value.find((_budget) => event.projectId === _budget.projectId);
      if (budget) {
        refresh(budget.id);
      }
      return;
    }

    // installation rate
    if (event.type === 'installation' && event.action === 'rate-set') {
      refresh();
    }
  });

  return state;
}
