<script setup lang="ts">
import { useI18n } from '@/util';
import {
  type LscIconButtonSize,
  LscIconButtonSizes,
  type LscIconButtonType,
  LscIconButtonTypes,
  type LscIconButtonVariant,
  LscIconButtonVariants,
} from './LscIconButton.types';

const props = defineProps({
  /** Used to set the button's active state for toggle buttons.*/
  ariaPressed: {
    type: [Boolean, String] as PropType<Booleanish | 'mixed'>,
    default: false,
  },
  /** Used to set the button's active state for popovers and dropdown menus.*/
  ariaExpanded: {
    type: [Boolean, String] as PropType<Booleanish>,
    default: false,
  },
  /** Used to define the buttons popup role for popovers and dropdown menus. */
  ariaHasPopup: {
    type: [Boolean, String] as PropType<Booleanish | 'menu' | 'listbox' | 'tree' | 'grid' | 'dialog'>,
    default: false,
  },
  /** An accessible label for the control. */
  ariaLabel: {
    type: String,
    required: true,
  },
  /** Disables the button.*/
  disabled: {
    type: Boolean,
    default: false,
  },
  /** This icon will be rendered in the button */
  icon: {
    type: String as PropType<LscIconName>,
    default: undefined,
  },
  /** Shows a loading indicator inside the button. This also disables the button. */
  loading: {
    type: Boolean,
    default: false,
  },
  /**
   * The size of the button.
   */
  size: {
    type: String as PropType<LscIconButtonSize>,
    default: 'lg',
    validator: (value: LscIconButtonSize) => LscIconButtonSizes.includes(value),
  },
  /** The HTML type of the button. */
  type: {
    type: String as PropType<LscIconButtonType>,
    default: 'button',
    validator: (value: LscIconButtonType) => LscIconButtonTypes.includes(value),
  },
  /** The variant of the button. */
  variant: {
    type: String as PropType<LscIconButtonVariant>,
    default: 'primary',
    validator: (value: LscIconButtonVariant) => LscIconButtonVariants.includes(value),
  },
});
const { t } = useI18n();

const iconSizeMap = {
  xs: 'xs',
  sm: 'sm',
  md: 'md',
  lg: 'md',
} as const;

const iconSize = computed(() => iconSizeMap[props.size]);

const iconButtonVariantStyleConfig = tv({
  base: [
    'relative inline-flex items-center justify-center rounded-full font-semibold outline-0',
    'focus-visible:ring-2',
  ],
  slots: {
    icon: '',
    loadingIcon: 'inline-block animate-spin rounded-full border-2 border-current border-t-transparent',
  },
  variants: {
    size: {
      xs: {
        loadingIcon: 'size-[--lsds-a-icon-size-xs]',
      },
      sm: {
        loadingIcon: 'size-[--lsds-a-icon-size-sm]',
      },
      md: {
        loadingIcon: 'size-[--lsds-a-icon-size-md]',
      },
      lg: {
        loadingIcon: 'size-[--lsds-a-icon-size-md]',
      },
    },
    variant: {
      'primary': {
        base: [
          'bg-[--lsds-c-button-color-bg-tertiary-default]',
          'text-[color:--lsds-c-button-color-icon-tertiary-default]',
          'ring-[color:--lsds-c-button-color-focus-ring-tertiary]',

          'hover:bg-[--lsds-c-button-color-bg-tertiary-hover]',
          'focus-visible:bg-[--lsds-c-button-color-bg-tertiary-hover]',

          // active/expanded button state
          'active:bg-[--lsds-c-button-color-bg-tertiary-active]',
          'aria-expanded:bg-[--lsds-c-button-color-bg-tertiary-active]',

          // pressed/toggle button state
          'aria-pressed:bg-[--lsds-c-button-color-bg-tertiary-pressed]',
          'aria-pressed:text-[color:--lsds-c-button-color-icon-tertiary-pressed]',
          'aria-disabled:bg-[--lsds-c-button-color-bg-tertiary-disabled]',
          'aria-disabled:text-[color:--lsds-c-button-color-icon-tertiary-disabled]',
        ],
      },
      'secondary': {
        base: [
          'bg-[--lsds-c-button-color-bg-secondary-default]',
          'text-[color:--lsds-c-button-color-icon-secondary-default]',
          'border border-[color:--lsds-c-button-color-border-secondary-default]',
          'ring-[color:--lsds-c-button-color-focus-ring-secondary]',

          'hover:bg-[--lsds-c-button-color-bg-secondary-hover]',
          'hover:border-[color:--lsds-c-button-color-border-secondary-hover]',

          // active/expanded button state
          'active:bg-[--lsds-c-button-color-bg-secondary-active]',
          'active:text-[color:--lsds-c-button-color-icon-secondary-active]',
          'active:border-[color:--lsds-c-button-color-border-secondary-active]',
          'aria-expanded:bg-[--lsds-c-button-color-bg-secondary-active]',
          'aria-expanded:text-[color:--lsds-c-button-color-icon-secondary-active]',
          'aria-expanded:border-[color:--lsds-c-button-color-border-secondary-active]',

          // pressed/toggle button state
          'aria-pressed:bg-[--lsds-c-button-color-bg-secondary-pressed]',
          'aria-pressed:text-[color:--lsds-c-button-color-icon-secondary-pressed]',
          'aria-pressed:border-[color:--lsds-c-button-color-border-secondary-pressed]',

          'focus-visible:border-[color:--lsds-c-button-color-border-secondary-focus]',

          // disabled
          'aria-disabled:bg-transparent',
          'aria-disabled:border-[color:--lsds-a-color-icon-disabled]',
          'aria-disabled:text-[color:--lsds-a-color-icon-disabled]',
        ],
      },
      'plain-secondary': {
        base: [
          'text-[color:--lsds-c-button-color-icon-plain-secondary-default]',
          'ring-[color:--lsds-c-button-color-focus-ring-plain-secondary]',
          'hover:text-[color:--lsds-c-button-color-icon-plain-secondary-hover]',
          'aria-expanded:text-[color:--lsds-c-button-color-icon-plain-secondary-active]',
          'active:text-[color:--lsds-c-button-color-icon-plain-secondary-active]',
          'focus-visible:text-[color:--lsds-c-button-color-icon-plain-secondary-focus]',
          'aria-disabled:text-[color:--lsds-c-button-color-icon-plain-secondary-disabled]',
        ],
      },
    },
  },
  compoundVariants: [
    {
      variant: ['primary', 'secondary'],
      size: 'xs',
      class: {
        base: 'size-[--lsds-c-button-size-xs]',
      },
    },
    {
      variant: ['primary', 'secondary'],
      size: 'sm',
      class: {
        base: 'size-[--lsds-c-button-size-sm]',
      },
    },
    {
      variant: ['primary', 'secondary'],
      size: 'md',
      class: {
        base: 'size-[--lsds-c-button-size-md]',
      },
    },
    {
      variant: ['primary', 'secondary'],
      size: 'lg',
      class: {
        base: 'size-[--lsds-c-button-size-lg]',
      },
    },
  ],
});

const classes = computed(() =>
  iconButtonVariantStyleConfig({
    size: props.size,
    variant: props.variant,
  }),
);
</script>

<template>
  <button
    :class="classes.base()"
    :type="type"
    :aria-disabled="disabled"
    :aria-label="ariaLabel"
    :aria-pressed="ariaPressed"
    :aria-expanded="ariaExpanded"
    :aria-haspopup="ariaHasPopup || ariaExpanded"
    :disabled="disabled || loading"
  >
    <div :class="{ invisible: loading }" class="contents">
      <LscIcon :icon="icon" :class="classes.icon()" :size="iconSize" />
    </div>
    <div v-if="loading" class="absolute inset-0 flex items-center justify-center">
      <span :class="classes.loadingIcon()" role="status" :aria-label="t('Loading')">
        <span class="sr-only">{{ t('Loading') }}</span>
      </span>
    </div>
  </button>
</template>
