import { unrefElement } from '@vueuse/core';

const targets = new WeakMap();

const observer = new ResizeObserver((entries) => {
  for (const entry of entries) {
    const target = targets.get(entry.target);
    if (target) {
      target.width.value = entry.contentRect.width;
      target.height.value = entry.contentRect.height;
    }
  }
});

/**
 * A more performant version of useElementSize from vueuse that uses a single observer for all elements.
 * @param {HTMLElement} ref
 * @returns {{width: Ref<number>, height: Ref<number>}}
 */
export function useElementSize(ref) {
  const width = shallowRef(0);
  const height = shallowRef(0);

  /**
   *
   * @param {Ref<HTMLElement>} elementRef
   */
  function unregisterElement(elementRef) {
    const element = unrefElement(elementRef);
    if (!element) {
      return;
    }
    targets.delete(element);
    observer.unobserve(element);
    width.value = 0;
    height.value = 0;
  }

  /**
   *
   * @param {Ref<HTMLElement>} elementRef
   */
  function registerElement(elementRef) {
    const element = unrefElement(elementRef);
    if (!element) {
      return;
    }
    targets.set(element, { width, height });
    observer.observe(element);
  }

  watch(
    ref,
    (newElement, oldElement) => {
      if (oldElement) {
        unregisterElement(oldElement);
      }
      if (newElement) {
        registerElement(newElement);
      }
    },
    { immediate: true },
  );

  onUnmounted(() => {
    if (ref.value) {
      unregisterElement(ref.value);
    }
  });

  return {
    width,
    height,
  };
}
