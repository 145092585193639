<script setup>
import { useExperimentA57, usePendo } from '@/api';
import { useI18n } from '@/util';

const props = defineProps({
  plan: {
    type: String,
    required: true,
  },
  unlimited: {
    type: Boolean,
    default: false,
  },
  tooltip: {
    type: String,
    required: false,
    default: '',
  },
});

const { isExpA57Variation, EXP_A57_COMMON_METRICS: commonMetrics } = useExperimentA57();
const { trackPendoEvent } = usePendo();
const { t } = useI18n();

const planName = `${props.plan?.charAt(0).toUpperCase()}${props.plan?.slice(1)}`;

const label = computed(() => {
  if (props.unlimited) {
    return t('Unlimited on {planName}', { planName });
  }
  return planName;
});

const tooltipString = computed(() => {
  if (props.tooltip?.length > 0) {
    return props.tooltip;
  }

  if (props.unlimited) {
    return t('This feature is limited on the {deliver} plan, but unlimited on {planName}', {
      deliver: 'Deliver',
      planName,
    });
  }
  return t('This feature is only available on the {planName} plan and above.', { planName });
});

// eslint-disable-next-line camelcase
function trackEvent(event_action) {
  trackPendoEvent({
    eventName: 'GROW_PROMO_EVENT',
    commonMetrics,
    metadata: {
      // eslint-disable-next-line camelcase
      event_action,
    },
  });
}
</script>
<template>
  <span
    v-if="isExpA57Variation"
    v-LsdTooltip="tooltipString"
    class="inline-flex cursor-pointer flex-row items-center gap-1 rounded-md bg-decorative-2 px-1.5 py-1 text-body-3 font-semibold uppercase leading-none"
    @mouseover="trackEvent('feature_badge_moused_over')"
    @click="trackEvent('feature_badge_clicked')"
  >
    <LscIcon size="xs" icon="lsi-spaces-avatar-growth" />
    {{ label }}
  </span>
</template>
