<script setup>
import RouteQuickViewDrawer from './RouteQuickViewDrawer.vue';
import { provideBreadcrumbs } from './useBreadcrumbs';
import { useQuickView } from './useQuickView';
import { useQuickViewRoute } from './useRoute';

const quickViewRoute = useQuickViewRoute();
const { closeQuickView } = useQuickView();

const isQuickViewResizable = computed(() =>
  Boolean(quickViewRoute.meta?.isQuickViewResizable === undefined || quickViewRoute.meta?.isQuickViewResizable),
);

provideBreadcrumbs({
  dataIdentifierPrefix: `quick-view-breadcrumbs`,
});
</script>

<template>
  <RouteQuickViewDrawer :resizable="isQuickViewResizable" @close="closeQuickView">
    <RouterView :route="quickViewRoute" :isQuickView="true" />
  </RouteQuickViewDrawer>
</template>
