<script setup>
import { useCurrentAccount } from '@/api';
import { useI18n } from '@/util';
import {
  Filter,
  FilterCategoryPicker,
  FilterCheckbox,
  FilterCompanyPicker,
  FilterCustomFields,
  FilterPeoplePicker,
  FilterProjectPicker,
  FilterStatusTypes,
  FilterTagPicker,
  FilterTasklistPicker,
  FilterTeamPicker,
} from '@/module/filter';
import { useReportCustomBuilderFilters } from '../useReportCustomBuilderFilters';

const { t } = useI18n();
const account = useCurrentAccount();
const { filterParams } = useReportCustomBuilderFilters();
const clientOrCompanyText = computed(() => (account.value?.useClientView ? t('Client') : t('Company')));
</script>

<template>
  <Filter
    v-model="filterParams"
    dataIdentifierPrefix="report-builder-filter-task"
    section="reportBuilderFilter"
    :allowSaveFilters="false"
    class="flex flex-col"
  >
    <template #menuFilters>
      <FilterPeoplePicker :title="t('Assignee')" name="userIds" />
      <FilterTagPicker v-if="account.tagsEnabled" :title="t('Tags')" name="tagIds" />
      <FilterTeamPicker
        :title="t('Teams')"
        name="teamIds"
        :loaderParams="{
          includeCompanyTeams: true,
          includeSubteams: true,
          includeProjectTeams: true,
        }"
      />
      <FilterCompanyPicker :title="clientOrCompanyText" name="companyIds" />
      <FilterTasklistPicker :title="t('Task lists')" name="tasklists" :loaderParams="{ showCompleted: true }" />
      <FilterProjectPicker :title="t('Project')" name="projectIds" />
      <FilterStatusTypes :title="t('Task status')" name="status" />
      <FilterCategoryPicker :title="t('Category')" name="categoryIds" />
      <FilterCustomFields :title="t('Custom field')" name="customFieldFilters" entities="task" />
    </template>
    <template #menuOptions>
      <FilterCheckbox
        name="includeCompletedTasks"
        :label="t('Include completed tasks')"
        data-identifier="report-builder-filter-task--completed-tasks-checkbox"
      />
      <FilterCheckbox
        name="includeArchivedProjects"
        :label="t('Include data from archived projects')"
        data-identifier="report-builder-filter-task--archived-projects-checkbox"
      />
    </template>
  </Filter>
</template>
