import { DateTime } from 'luxon';

/**
 * Returns a DateTime object for the start of the current day
 * @returns {DateTime}
 */
export function getToday() {
  return DateTime.now().startOf('day');
}

/**
 * Check if a date is between two other dates which are optional
 * @param {object} options
 * @param {DateTime} options.dateTime
 * @param {DateTime=} options.minDate
 * @param {DateTime=} options.maxDate
 * @returns {boolean}
 */
export function isBetweenDates({ dateTime, minDate, maxDate }) {
  if (minDate && dateTime < minDate) {
    return false;
  }
  if (maxDate && dateTime > maxDate) {
    return false;
  }
  return true;
}

/**
 * Returns true if the two dates have the same day
 * @param {DateTime=} date1
 * @param {DateTime=} date2
 * @returns {boolean}
 */
export function isSameDay(date1, date2) {
  return (date1 && date2 && date1.hasSame(date2, 'day')) || (date1 == null && date2 == null);
}

/**
 * Returns true if the two dates have the same month
 * @param {DateTime=} date1
 * @param {DateTime=} date2
 * @returns {boolean}
 */
export function isSameMonth(date1, date2) {
  return (date1 && date2 && date1.hasSame(date2, 'month')) || (date1 == null && date2 == null);
}
