import { useItemLoader } from '../base/useItemLoader';

function responseToItem(response) {
  return response.data;
}

export function useIntegrationsHubspotV1Loader() {
  const { state } = useItemLoader({
    url: '/synthesis/api/v2/hubspot-workflow/oauth/authed.json',
    responseToItem,
  });

  return state;
}
