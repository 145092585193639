<script setup>
import { usePendo, useProductUpdatesV1Loader } from '@/api';
import { useI18n } from '@/util';
import { ProductUpdatesItem } from '@/module/productUpdates';
import { useHelpCenter } from '../..';

const { t } = useI18n();
const count = 25;
const state = useProductUpdatesV1Loader({ count });
const { items: updates } = state;
const { trackPendoEvent } = usePendo();
const { HELP_CENTER_COMMON_METRICS } = useHelpCenter();

function handleUpdateClicked(updateTitle) {
  trackPendoEvent({
    eventName: 'HELP_EVENT',
    commonMetrics: HELP_CENTER_COMMON_METRICS,
    metadata: {
      event_action: 'what_new_item_clicked',
      event_label: updateTitle.toLowerCase(),
    },
  });
}
</script>

<template>
  <div class="m-0 h-full max-h-[inherit] overflow-y-auto p-0">
    <div class="mx-6 pb-6">
      <p class="mb-5 text-subtitle-1 font-semibold text-default">{{ t("What's new?") }}</p>
      <LswLoaderState :state="state">
        <LscSheet v-for="update in updates" v-bind="VCardRounded" :key="update.id" class="mb-6 !p-2">
          <ProductUpdatesItem :update="update" @click="handleUpdateClicked(update.title)" />
        </LscSheet>
      </LswLoaderState>
    </div>
  </div>
</template>
