/**
 * Gets a state object returned by a loaded and returns a promise which is fulfilled when loading completes.
 *
 * When data is loaded and the state is in sync with the server,
 * the returned promise is resolved with an object:
 * - `{ item, meta }` for item loaders
 * - `{ items, meta }` for list loaders
 * Note that `item`, `items`, and `meta` are direct object references and not Vue refs.
 *
 * When the loader reports a non-cache error,
 * the returned promise is rejected with that error.
 */
export function loaderStatePromise(state) {
  return new Promise((resolve, reject) => {
    function isSuccess() {
      return state.inSync.value && state.loaded.value;
    }

    function isError() {
      return state.error.value;
    }

    function reportSuccess() {
      resolve(
        state.items
          ? {
              items: state.items.value,
              meta: state.meta.value,
            }
          : {
              item: state.item.value,
              meta: state.meta.value,
            },
      );
    }

    function reportError() {
      reject(state.error.value);
    }

    if (isSuccess()) {
      reportSuccess();
    } else {
      const unwatch = watch([state.inSync, state.error, state.loaded], () => {
        if (isSuccess()) {
          unwatch();
          reportSuccess();
        } else if (isError()) {
          unwatch();
          reportError();
        }
      });
    }
  });
}
