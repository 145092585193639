import { useItemLoader } from '../base/useItemLoader';
import { useItemToListLoader } from '../base/useItemToListLoader';

const keyMapping = {
  DisplayOnDocHomepage: 'displayOnDocHomepage',
  OldURL: 'oldUrl',
  name: 'title',
};

/**
 * Every category returns
 * @typedef {Object} KnowledgeBaseCategory a category
 * @property {number} id
 * @property {number} parentId
 * @property {number} numArticles how many articles in this category
 * @property {number} displayOrder integer order for displaying in a list
 * @property {string} name category title
 * @property {string} slug category slug
 * @property {boolean} displayOnDocHomepage if category is shown on the front page
 * @property {KnowledgeBaseCategory[]} subCategories array of nested categories
 */

function isObject(obj) {
  const type = typeof obj;
  return type === 'function' || (type === 'object' && !!obj);
}

function responseToItem({ data }) {
  function updateKeyName(result, keysMap) {
    if (result === null) {
      return null;
    }
    if (Array.isArray(result)) {
      return result.map((item) => updateKeyName(item, keysMap));
    }
    if (typeof result === 'object') {
      return Object.keys(result).reduce((obj, key) => {
        const propKey = updateKeyName(key, keysMap);
        const propValue = isObject(result[key]) ? updateKeyName(result[key], keysMap) : result[key];
        // eslint-disable-next-line no-param-reassign
        obj[propKey] = propValue;
        return obj;
      }, {});
    }
    if (typeof result === 'string') {
      return keysMap[result] || result;
    }
    return result;
  }

  const updatedKeysInData = updateKeyName(data, keyMapping);
  return updatedKeysInData;
}

/**
 * An array of categories fetched from Teamwork's Knowledge Base site
 * @returns {KnowledgeBaseCategories[]} array of available categories
 */
export function useKnowledgeBaseCategoriesLoader({ count }) {
  const { state } = useItemLoader({
    url: 'https://support.teamwork.com/projects/categories',
    responseToItem,
  });

  return useItemToListLoader(state, count);
}
