<script setup lang="ts">
import type { PropType } from 'vue';
import { useI18n } from '@/util';
import type { LscIconName } from '../../media/icon/LscIcon.types';
import type { LscDialogCardSize } from './LscDialog.types';
import LscDialog from './LscDialog.vue';
import LscDialogCard from './LscDialogCard.vue';

defineProps({
  /** The title to display in the dialog. */
  title: {
    type: String,
    default: undefined,
  },
  /** The message to display in the dialog. */
  message: {
    type: String,
    default: null,
  },
  /** The icon to display on the left side of the dialog. */
  icon: {
    type: String as PropType<LscIconName>,
    default: undefined,
  },
  /** Whether the dialog is critical. */
  critical: {
    type: Boolean,
    default: false,
  },
  /** The text to display on the confirm button. */
  confirmButtonText: {
    type: String,
    default: null,
  },
  /** The text to display on the cancel button. */
  cancelButtonText: {
    type: String,
    default: undefined,
  },
  /**
   * The data identifier prefix.
   */
  dataIdentifierPrefix: {
    type: String,
    default: '',
  },
  /** The size of the dialog */
  size: {
    type: String as PropType<LscDialogCardSize>,
    default: 'sm',
  },
  /** Should the user be able to click outside */
  shouldCloseOnClickOutside: {
    type: Boolean,
    default: true,
  },
});

const emit = defineEmits(['cancel', 'confirm']);

const modelValue = defineModel({
  type: Boolean,
  default: false,
});

// Focus the cancel button when the confirmation dialog opens up.
const cancelButton = shallowRef();
watch(cancelButton, () => {
  cancelButton.value?.$el?.focus();
});

// Focus the previously active element when the confirmation dialog closes.
let oldActiveElement: HTMLElement | undefined;
watch(modelValue, () => {
  if (modelValue.value) {
    oldActiveElement = document.activeElement as HTMLElement;
  } else {
    // We need some delay here to avoid Vuetify's `retainFocus` behavior.
    requestAnimationFrame(() => {
      if (!modelValue.value && oldActiveElement) {
        oldActiveElement.focus();
        oldActiveElement = undefined;
      }
    });
  }
});

const { t } = useI18n();

function cancel(close: () => void) {
  emit('cancel');
  close();
}

function confirm(close: () => void) {
  emit('confirm');
  close();
}
</script>

<template>
  <LscDialog v-model="modelValue" :aria-label="title">
    <template #activator="activator">
      <slot name="activator" v-bind="activator" />
    </template>
    <template #default="{ close }">
      <LscDialogCard
        :shouldCloseOnClickOutside="shouldCloseOnClickOutside"
        :size="size"
        :title="title"
        @close="cancel(close)"
      >
        <template #prependHeaderLeft>
          <slot name="prependHeaderLeft">
            <LscIcon v-if="icon" :icon="icon" size="lg" />
          </slot>
        </template>
        <div class="text-body-1">
          <slot>{{ message ?? t('Please confirm?') }}</slot>
        </div>
        <template #actions>
          <LscButton
            ref="cancelButton"
            variant="tertiary"
            :data-identifier="dataIdentifierPrefix ? `${dataIdentifierPrefix}-cancel-button` : undefined"
            @click="cancel(close)"
          >
            {{ cancelButtonText ?? t('Cancel') }}
          </LscButton>
          <div class="flex-1" />
          <LscButton
            :variant="critical ? 'critical' : 'primary'"
            :data-identifier="dataIdentifierPrefix ? `${dataIdentifierPrefix}-confirm-button` : undefined"
            @click="confirm(close)"
          >
            {{ confirmButtonText ?? t('OK') }}
          </LscButton>
        </template>
      </LscDialogCard>
    </template>
  </LscDialog>
</template>
