export const ExpenseDialogOpenSources = /** @type {const} */ ([
  'project_invoice',
  'project_expenses',
  'project_budget',
  'project_billing',
]);

export const DATA_IDENTIFIER_PREFIX = 'expense-modal';
export const AI_MAX_FILE_SIZE = 20 * 1024 * 1024;
export const SUPPORTED_FILE_EXTENSIONS = ['jpeg', 'jpg', 'png', 'webp'];
