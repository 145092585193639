import { useCurrentAccount } from '../account/useCurrentAccount';
import { usePricePlan } from '../account/usePricePlan';
import { useAxios } from '../base/useAxios';
import { useRealTimeUpdates } from '../base/useRealTimeUpdates';
import { useCurrentUser } from '../user/useCurrentUser';
import { useCheckoutUtils } from './useCheckoutUtils';

export function useCheckoutActions() {
  const api = useAxios();
  const { emit: emitRealTimeUpdate, socketId } = useRealTimeUpdates();
  const user = useCurrentUser();
  const account = useCurrentAccount();
  const { paidUserCount } = usePricePlan();
  const { checkoutBaseUrl, subscriptionUrl } = useCheckoutUtils();

  function config() {
    return {
      headers: {
        'Socket-ID': socketId,
      },
    };
  }

  async function getSubscriptionRef() {
    const {
      data: { ref },
    } = await api.get(subscriptionUrl);

    return ref;
  }

  async function goToUpgrade(entryPoint = '') {
    const subscriptionRef = await getSubscriptionRef();
    window.location = `${checkoutBaseUrl.value}${entryPoint ? `?entryPoint=${entryPoint}` : ''}#ref=${subscriptionRef}`;
  }

  async function goToPlanPage(planId, period, entrypoint) {
    const subscriptionRef = await getSubscriptionRef();
    window.location = `${checkoutBaseUrl.value}plan?planId=${planId}&billingPeriod=${period}&skipPlans=true${
      entrypoint ? `&entryPoint=${entrypoint}` : ''
    }#ref=${subscriptionRef}`;
  }

  async function goToUpdatePaymentDetailsPage(entrypoint) {
    const subscriptionRef = await getSubscriptionRef();
    window.location = `${checkoutBaseUrl.value}billing?entryPoint=${entrypoint}#ref=${subscriptionRef}`;
  }

  /**
   * Updates a subscription.
   * @param {object} payload
   */
  async function updateSubscription(payload) {
    const subscriptionRef = await getSubscriptionRef();
    const url = `${checkoutBaseUrl.value}subscription.json?ref=${subscriptionRef}`;
    const promise = api.post(url, payload, config()).finally(() => {
      // given account can be updated even on error (eg: Stripe card declined error sets account to trial),
      // we emit a real time update to trigger a refetch of account.json
      emitRealTimeUpdate({
        type: 'account',
        action: 'edited',
        id: account.value.id,
      });
    });
    return promise;
  }

  /**
   * Allows the customer to inquire with Sales about requesting a demo for the Scale price plan
   */
  async function requestSalesDemo() {
    const subscriptionRef = await getSubscriptionRef();
    const userData = {
      productCode: 'projects',
      phone: user.value.phoneNumberOffice ?? user.value.phoneNumberMobile,
      email: user.value.emailAddress,
      message: 'I would like to request a demo for the Scale price plan',
    };
    const requestSalesDemoUrl = `${checkoutBaseUrl.value}enterprisecontactrequest.json?ref=${subscriptionRef}`;
    const promise = api.post(requestSalesDemoUrl, userData);
    return promise;
  }

  /**
   * POST a subscription price calculation to Checkout.
   * @param {object} payload
   */
  async function calculateSubscriptionCost(payload) {
    const subscriptionRef = await getSubscriptionRef();

    const calculateSubscriptionCostUrl = `${checkoutBaseUrl.value}subscription/cost.json?ref=${subscriptionRef}`;
    const promise = api.post(calculateSubscriptionCostUrl, payload);
    return promise;
  }

  /**
   * 2023 Cancellation Flow, fetches and activates a free month discount code, one time use.
   */
  async function redeemFreeMonth() {
    const subscriptionRef = await getSubscriptionRef();
    const discountUrl = `${checkoutBaseUrl.value}cancellationdiscount.json?ref=${subscriptionRef}`;
    const { data: resp } = await api.get(discountUrl);
    const req = {
      productCode: 'projects',
      discountCode: resp.code,
      billingPeriod: account.value.billingPeriod === 'annually' ? 'monthly' : '',
      newTeamSize: paidUserCount.value,
    };
    const prom = updateSubscription(req);
    return prom;
  }

  /**
   * POST a subscription cancel to Checkout.
   * @param {object} payload
   */
  async function cancelSubscription(payload) {
    const subscriptionRef = await getSubscriptionRef();

    const cancelSubscriptionUrl = `${checkoutBaseUrl.value}subscription/cancel.json?ref=${subscriptionRef}`;
    const promise = api.post(cancelSubscriptionUrl, payload);
    return promise;
  }

  /**
   * PUT a subscription cancel abort to Checkout.
   */
  async function abortCancelSubscription() {
    const subscriptionRef = await getSubscriptionRef();

    const cancelSubscriptionUrl = `${checkoutBaseUrl.value}subscription/cancel/abort.json?ref=${subscriptionRef}`;
    const promise = api.put(cancelSubscriptionUrl);
    return promise;
  }

  /**
   * POST a check for a discount code to Checkout.
   */
  async function checkDiscountCode(discountCode) {
    const subscriptionRef = await getSubscriptionRef();

    const checkDiscountCodeUrl = `${checkoutBaseUrl.value}discountcodecheck.json?ref=${subscriptionRef}`;
    const promise = api.post(checkDiscountCodeUrl, {
      discountCode,
      productCode: 'projects',
    });
    return promise;
  }

  return {
    updateSubscription,
    cancelSubscription,
    calculateSubscriptionCost,
    goToUpgrade,
    requestSalesDemo,
    redeemFreeMonth,
    goToPlanPage,
    abortCancelSubscription,
    goToUpdatePaymentDetailsPage,
    checkDiscountCode,
  };
}
