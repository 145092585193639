<script setup>
const props = defineProps({
  config: {
    type: Object,
    required: true,
  },
  columnConfig: {
    type: Object,
    default: () => {},
  },
  mode: {
    type: String,
    default: 'total',
  },
});

const manualSelectedOption = shallowRef(null);

const selectedOption = computed(() => {
  if (manualSelectedOption.value) {
    return manualSelectedOption.value;
  }
  if (props.mode === 'total') {
    return props.config.options.find((option) => option.label.toLowerCase() === 'total') || props.config.options[0];
  }
  if (props.mode === 'average') {
    return props.config.options.find((option) => option.label.toLowerCase() === 'average') || props.config.options[0];
  }
  return props.config.options.find((option) => option.default === true) || props.config.options[0];
});

function updateSelectedOption(newOption) {
  manualSelectedOption.value = newOption;
}

const alignmentClass = computed(() => {
  return props.columnConfig?.align === 'right' ? 'justify-end' : 'justify-start';
});

watch(
  () => props.config,
  () => {
    manualSelectedOption.value = null;
  },
);
</script>

<template>
  <div class="flex flex-col">
    <span class="text-body-1">{{ selectedOption?.value }}</span>
    <WidgetOptionsMenu v-if="config.options.length > 1" location="top">
      <template #activator="activator">
        <LscButton
          variant="plain-secondary"
          v-bind="activator.props"
          appendIcon="lsi-dropdown"
          size="sm"
          class="flex py-1"
          :class="alignmentClass"
        >
          {{ selectedOption?.label }}
        </LscButton>
      </template>
      <WidgetOptionsMenuItem
        v-for="item in config.options"
        :key="item.key"
        class="truncate text-body-2"
        :text="item.label"
        :active="selectedOption?.key === item.key"
        @click="updateSelectedOption(item)"
      />
    </WidgetOptionsMenu>
    <span v-else>
      <span class="text-body-2 text-subtle">{{ selectedOption?.label }}</span>
    </span>
  </div>
</template>
