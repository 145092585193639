<script setup>
import { useI18n } from '@/util';
import FormDrawerEmptyFormsGlobal from './FormDrawerEmptyFormsGlobal.svg';

const { t } = useI18n();
</script>
<template>
  <LscEmptyState size="sm" class="grow" :title="t('No global forms')" :message="t('You have no global forms yet')">
    <template #image>
      <FormDrawerEmptyFormsGlobal />
    </template>
    <template #actions>
      <!-- <LscButton v-if="isOwnerAdmin" variant="primary" @click.stop="addForm">
        {{ t('Add a form') }}
      </LscButton> -->
    </template>
  </LscEmptyState>
</template>
