import { DateTime, Info } from 'luxon';

export const defaultTimeZoneName = Intl.DateTimeFormat().resolvedOptions().timeZone;

// These are the predefined Teamwork date formats.
const dateFormats = new Map([
  [1, 'dd/MM/yyyy'], // 17/06/2022
  [2, 'MM/dd/yyyy'], // 06/17/2022
  [3, 'dd.MM.yyyy'], // 17.06.2022
  [4, 'yyyy-MM-dd'], // 2022-06-17
  [5, 'yyyy.MM.dd'], // 2022.06.17
  [6, 'MM.dd.yyyy'], // 06.17.2022
]);
export const defaultDateFormatId = shallowRef(4);

// These are the predefined Teamwork time formats.
const timeFormats = new Map([
  [1, 'h:mma'], // 7:45AM
  [2, 'HH:mm'], // 07:45
]);
export const defaultTimeFormatId = shallowRef(1);

// These are the predefined Teamwork time formats
// mapped to the corresponding Luxon hour cycle.
// More info: https://github.com/moment/luxon/issues/726#issuecomment-964944768
const hourCycles = new Map([
  [1, 'h12'],
  [2, 'h23'],
]);

function getFormatTimeConfigShort(hourCycle) {
  return { hour: 'numeric', hourCycle };
}

function getFormatTimeConfigMedium(hourCycle) {
  return { ...DateTime.TIME_SIMPLE, hourCycle };
}

function getFormatTimeConfigLong(hourCycle) {
  return { ...DateTime.TIME_WITH_SECONDS, hourCycle };
}

export function createDateFormat({ dateFormatId = defaultDateFormatId } = {}) {
  return computed(() => dateFormats.get(dateFormatId.value));
}

export function createTimeFormat({ timeFormatId = defaultTimeFormatId } = {}) {
  return computed(() => timeFormats.get(timeFormatId.value));
}

export function createHourCycle({ timeFormatId = defaultTimeFormatId } = {}) {
  return computed(() => hourCycles.get(timeFormatId.value));
}

export const DateFormatOptions = /** @type {const} */ (['mini', 'short', 'medium', 'long', 'teamwork']);

export function createFormatDate({ t, dateFormat, now }) {
  /**
   * Formats the specified Luxon DateTime object as a date string.
   * @param {DateTime} dateTime
   * @param {typeof DateFormats[number]} format
   * @returns {string}
   */
  return function formatDate(dateTime, format = 'short') {
    if (!dateTime || !dateTime.isValid) {
      return '';
    }
    if (format === 'mini') {
      const nowValue = now?.value ?? DateTime.now();
      const sixDaysFromNow = nowValue.plus({ days: 6 });
      const isDueInNext7Days = dateTime >= nowValue && dateTime <= sixDaysFromNow;
      if (nowValue.hasSame(dateTime, 'day')) {
        return t('Today');
      }
      if (nowValue.plus({ day: 1 }).hasSame(dateTime, 'day')) {
        return t('Tomorrow');
      }
      if (nowValue.minus({ day: 1 }).hasSame(dateTime, 'day')) {
        return t('Yesterday');
      }
      return dateTime.toLocaleString({
        month: 'short',
        day: 'numeric',
        year: nowValue.hasSame(dateTime, 'year') ? undefined : 'numeric',
        weekday: isDueInNext7Days ? 'short' : undefined,
      });
    }
    if (format === 'short') {
      return dateTime.toLocaleString(DateTime.DATE_MED);
    }
    if (format === 'medium') {
      return dateTime.toLocaleString(DateTime.DATE_FULL);
    }
    if (format === 'long') {
      return dateTime.toLocaleString(DateTime.DATE_HUGE);
    }
    return dateTime.toFormat(dateFormat.value);
  };
}

export const TimeFormatOptions = /** @type {const} */ (['mini', 'short', 'medium', 'long']);

export function createFormatTime({ t, now, hourCycle }) {
  /**
   * Formats the specified Luxon DateTime object as a time string.
   * @param {'mini'|'short'|'medium'|'long'} format
   * @returns {string}
   */
  return function formatTime(dateTime, format = 'medium') {
    if (!dateTime || !dateTime.isValid) {
      return '';
    }

    if (format === 'mini') {
      const nowValue = now?.value ?? DateTime.now();
      if (Math.abs(nowValue.diff(dateTime).as('minutes')) < 1) {
        return t('Now');
      }
      if (Math.abs(nowValue.diff(dateTime).as('hour')) <= 1) {
        return dateTime.toRelative({ base: nowValue, style: 'short' });
      }
    }
    if (format === 'short') {
      return dateTime.toLocaleString(getFormatTimeConfigShort(hourCycle.value));
    }
    if (format === 'long') {
      return dateTime.toLocaleString(getFormatTimeConfigLong(hourCycle.value));
    }
    // if (format === 'medium')
    return dateTime.toLocaleString(getFormatTimeConfigMedium(hourCycle.value));
  };
}

export function createToTimeParts({ hourCycle }) {
  /**
   * @param {DateTime} dateTime
   * @param {'short'|'medium'|'long'} format
   * @returns {Intl.DateTimeFormatPart[]}
   */
  return function toTimeParts(dateTime, format = 'medium') {
    if (!dateTime || !dateTime.isValid) {
      return [];
    }
    if (format === 'short') {
      return dateTime.toLocaleParts(getFormatTimeConfigShort(hourCycle.value));
    }
    if (format === 'long') {
      return dateTime.toLocaleParts(getFormatTimeConfigLong(hourCycle.value));
    }
    return dateTime.toLocaleParts(getFormatTimeConfigMedium(hourCycle.value));
  };
}

export const DateRangeFormatOptions = /** @type {const} */ (['mini', 'short', 'medium', 'long']);

export function createFormatDateRange({ dateFormat, now }) {
  /**
   * Formats a range of dates ie: 17 - 23 Jun | 17 Dec 2002 - 23 Jun | June
   * @param {Interval} interval
   * @param {typeof DateRangeFormats[number]} format
   * @returns {string}
   */
  return function formatDateRange(interval, format = 'short') {
    if (!interval || !interval.isValid) {
      return '';
    }
    const nowValue = now ? now.value : DateTime.now();
    if (format === 'mini') {
      const isCurrentYear = nowValue.hasSame(interval.start, 'year') && nowValue.hasSame(interval.end, 'year');
      return interval.toLocaleString({ month: 'short', day: 'numeric', year: isCurrentYear ? undefined : 'numeric' });
    }
    if (format === 'short') {
      return interval.toLocaleString(DateTime.DATE_MED);
    }
    if (format === 'medium') {
      return interval.toLocaleString(DateTime.DATE_FULL);
    }
    if (format === 'long') {
      return interval.toLocaleString(DateTime.DATE_HUGE);
    }

    return `${interval.start.toFormat(dateFormat.value)} - ${interval.end.toFormat(dateFormat.value)}`;
  };
}

export function createFormatDateRelative({ t, now }) {
  /**
   * Returns a string representation of `dateTime` relative to now with the minimum
   * resolution of 1 day. Returns 'Today', 'Tomorrow', 'Yesterday' when appropriate.
   * @param {DateTime} dataTime
   * @returns {string}
   */
  return function formatDateRelative(dateTime) {
    if (!dateTime || !dateTime.isValid) {
      return '';
    }

    const date = dateTime.startOf('day');
    const base = (now?.value ?? DateTime.now()).startOf('day');

    if (date.hasSame(base, 'day')) {
      return t('Today');
    }
    if (date.hasSame(base.plus({ day: 1 }), 'day')) {
      return t('Tomorrow');
    }
    if (date.hasSame(base.minus({ day: 1 }), 'day')) {
      return t('Yesterday');
    }

    return date.toRelative({ base });
  };
}

export const DateTimeFormatOptions = /** @type {const} */ (['mini', 'short', 'medium', 'long']);

export function createFormatDateTime({ hourCycle, formatDate, formatTime, now }) {
  /**
   * Formats the specified Luxon DateTime object as a date and time string. The teamwork format is not supported.
   * @param {DateTime} dateTime
   * @param {DateFormats} format
   * @returns {string}
   */
  return function formatDateTime(dateTime, format = 'short') {
    if (!dateTime || !dateTime.isValid) {
      return '';
    }
    if (format === 'mini') {
      const nowValue = now?.value ?? DateTime.now();
      if (nowValue.hasSame(dateTime, 'day')) {
        return formatTime(dateTime, 'mini');
      }
      return formatDate(dateTime, 'mini');
    }
    if (format === 'long') {
      return dateTime.toLocaleString({
        ...DateTime.DATE_HUGE,
        ...DateTime.TIME_SIMPLE,
        hourCycle: hourCycle.value,
      });
    }
    if (format === 'medium') {
      return dateTime.toLocaleString({
        ...DateTime.DATE_FULL,
        ...DateTime.TIME_SIMPLE,
        hourCycle: hourCycle.value,
      });
    }
    // if (format === 'short')
    return dateTime.toLocaleString({
      ...DateTime.DATE_MED,
      ...DateTime.TIME_SIMPLE,
      hourCycle: hourCycle.value,
    });
  };
}

/**
 * Returns a DateTime object based on input
 * @param {string} input
 * @returns {DateTime}
 */
export function parseDate(input) {
  const monthNames = Info.months().map((month) => month.toLocaleLowerCase());

  function parseDay(value) {
    // We remove non-digits using `.replace(/\D/g, '')` to be able to parse strings like "1st", "4th", etc.
    const number = Number(value.replace(/\D/g, ''));
    return Number.isInteger(number) ? number : undefined;
  }

  function parseMonth(value) {
    const lowerCaseInput = value.toLocaleLowerCase();
    const index = monthNames.findIndex((monthName) => monthName.startsWith(lowerCaseInput));

    if (index >= 0) {
      return index + 1;
    }

    const number = Number(value);
    return Number.isInteger(number) ? number : undefined;
  }

  function parseYear(value) {
    let number = Number(value);

    if (value.length === 2) {
      number += 2000;
    } else if (value.length !== 4) {
      number = undefined;
    }

    return Number.isInteger(number) ? number : undefined;
  }

  const parts = input.split(/[-+./\s]+/).filter(Boolean);
  let partsOrder = DateTime.now()
    .toLocaleParts()
    .map(({ type }) => type);

  if (parts.length === 1) {
    partsOrder = partsOrder.filter((type) => type === 'day');
  } else if (parts.length === 2) {
    partsOrder = partsOrder.filter((type) => type === 'day' || type === 'month');
  } else if (parts.length === 3) {
    partsOrder = partsOrder.filter((type) => type === 'day' || type === 'month' || type === 'year');
  } else {
    return DateTime.invalid('Parsing failed');
  }

  const dayIndex = partsOrder.indexOf('day');
  const monthIndex = partsOrder.indexOf('month');
  const yearIndex = partsOrder.indexOf('year');

  const day = dayIndex >= 0 ? parseDay(parts[dayIndex]) : undefined;
  const month = monthIndex >= 0 ? parseMonth(parts[monthIndex]) : undefined;
  const year = yearIndex >= 0 ? parseYear(parts[yearIndex]) : undefined;

  if (
    (dayIndex >= 0 && day === undefined) ||
    (monthIndex >= 0 && month === undefined) ||
    (yearIndex >= 0 && year === undefined)
  ) {
    return DateTime.invalid('Parsing failed');
  }

  return DateTime.fromObject({ day, month, year });
}
