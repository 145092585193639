import { useI18n } from '@/util';
import { useAxios } from '../base/useAxios';

export function useTemplatesActions() {
  const api = useAxios();
  const { t } = useI18n();

  return {
    /**
     * Save welcome email template
     * @param {string} template
     */
    updateWelcomeEmailTemplate(template) {
      return api.put(
        '/templates/welcomeemail.json',
        { welcomeemail: template },
        { errorMessage: t('Failed to update the email template') },
      );
    },

    /**
     * Create a new project from sample template, but provide a full payload for other options.
     * @param {object} payload
     * @param {number} payload.templateId - template id is always required
     * @param {number} [payload.installationId]
     * @param {number} [payload.sourceInstallationId] - should be the same as installationId
     * @param {string} [payload.projectName] - override the template project name
     * @param {string} [payload.cloneProjectName] - should be the same as projectName
     * @param {string} [payload.cloneprojectAction=copy] - should be 'copy'
     * @param {object} [payload.owner] - project owner
     * @param {number} [payload.owner.id] - required if owner is present
     * @param {number} [payload.sampleProjectUserAId] - Dummy Data experiment A27 related
     * @param {number} [payload.sampleProjectUserBId] - Dummy Data experiment A27 related
     * @param {number} [payload.companyId] - Dummy Data experiment A27 related - assign to client company
     * @param {string} [payload.startPage] - project task list view default
     * @param {string} [payload.activeView]
     * @param {string} [payload.newCompany] - only if project is created for a new company
     */
    createProjectFromSampleTemplate(payload) {
      return api.post('/projects/sample.json', payload, { errorMessage: t('Failed to create a project') });
    },
    /**
     * Create a new project from sample template with extra details
     * @param {object} template
     */
    createProjectFromSampleTemplateWithDetails(template) {
      return api.post('/projects/sample.json', template, { errorMessage: t('Failed to create a project') });
    },
  };
}
