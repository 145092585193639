export function orderAscByAssignedTo(task1, task2) {
  return (
    task1?.assignees[0]?.firstName?.localeCompare(task2?.assignees[0]?.firstName) ||
    task1?.assignees[0]?.lastName?.localeCompare(task2?.assignees[0]?.lastName)
  );
}
export function orderDescByAssignedTo(task1, task2) {
  return (
    task2?.assignees[0]?.firstName?.localeCompare(task1?.assignees[0]?.firstName) ||
    task2?.assignees[0]?.lastName?.localeCompare(task1?.assignees[0]?.lastName)
  );
}

export function orderAscByManual(task1, task2) {
  return (
    task1.project.name.localeCompare(task2.project.name) ||
    task1.taskList.displayOrder - task2.taskList.displayOrder ||
    task1.parentTaskId - task2.parentTaskId ||
    task1.displayOrder - task2.displayOrder
  );
}
export function orderDescByManual(task1, task2) {
  return (
    task2.project.name.localeCompare(task1.project.name) ||
    task1.taskList.displayOrder - task2.taskList.displayOrder ||
    task1.parentTaskId - task2.parentTaskId ||
    task1.displayOrder - task2.displayOrder
  );
}

export function orderAscByName(task1, task2) {
  return task1.name.localeCompare(task2.name);
}
export function orderDescByName(task1, task2) {
  return task2.name.localeCompare(task1.name);
}

export function orderAscByDueDate({ dueDate: date1 }, { dueDate: date2 }) {
  // For consistency with the server, tasks without a dueDate are ordered last.
  if (date1 && date1.isValid) {
    if (date2 && date2.isValid) {
      return date1.valueOf() - date2.valueOf();
    }
    return -1;
  }
  if (date2 && date2.isValid) {
    return 1;
  }
  return 0;
}
export function orderDescByDueDate({ dueDate: date1 }, { dueDate: date2 }) {
  // For consistency with the server, tasks without a dueDate are ordered last.
  if (date1 && date1.isValid) {
    if (date2 && date2.isValid) {
      return date2.valueOf() - date1.valueOf();
    }
    return -1;
  }
  if (date2 && date2.isValid) {
    return 1;
  }
  return 0;
}

export function orderAscByStartDate({ startDate: date1 }, { startDate: date2 }) {
  // For consistency with the server, tasks without a startDate are ordered last.
  if (date1 && date1.isValid) {
    if (date2 && date2.isValid) {
      return date1.valueOf() - date2.valueOf();
    }
    return -1;
  }
  if (date2 && date2.isValid) {
    return 1;
  }
  return 0;
}
export function orderDescByStartDate({ startDate: date1 }, { startDate: date2 }) {
  // For consistency with the server, tasks without a startDate are ordered first.
  if (date2 && date2.isValid) {
    if (date1 && date1.isValid) {
      return date2.valueOf() - date1.valueOf();
    }
    return -1;
  }
  if (date1 && date1.isValid) {
    return 1;
  }
  return 0;
}

export function orderAscByCreatedDate(task1, task2) {
  return task1.createdAt.valueOf() - task2.createdAt.valueOf();
}
export function orderDescByCreatedDate(task1, task2) {
  return task2.createdAt.valueOf() - task1.createdAt.valueOf();
}

export function orderAscByPriority({ priority: priority1 }, { priority: priority2 }) {
  // For consistency with the server, tasks without priority are ordered last.
  if (priority1 === priority2) {
    return 0;
  }
  if (priority1 === 'low') {
    return -1;
  }
  if (priority2 === 'low') {
    return 1;
  }
  if (priority1 === 'medium') {
    return -1;
  }
  if (priority2 === 'medium') {
    return 1;
  }
  if (priority1 === 'high') {
    return -1;
  }
  if (priority2 === 'high') {
    return 1;
  }
  return 0; // should never get here
}
export function orderDescByPriority({ priority: priority1 }, { priority: priority2 }) {
  // For consistency with the server, tasks without priority are ordered last.
  if (priority1 === priority2) {
    return 0;
  }
  if (priority1 === 'high') {
    return -1;
  }
  if (priority2 === 'high') {
    return 1;
  }
  if (priority1 === 'medium') {
    return -1;
  }
  if (priority2 === 'medium') {
    return 1;
  }
  if (priority1 === 'low') {
    return -1;
  }
  if (priority2 === 'low') {
    return 1;
  }
  return 0; // should never get here
}

export function orderAscByTaskListName(task1, task2) {
  return task1.taskList.name.localeCompare(task2.taskList.name);
}
export function orderDescByTaskListName(task1, task2) {
  return task2.taskList.name.localeCompare(task1.taskList.name);
}

export function orderAscByTasklistDisplayOrder(task1, task2) {
  return task1.tasklist.displayOrder - task2.tasklist.displayOrder;
}

export function orderAscByProject(task1, task2) {
  return task1.project.name.localeCompare(task2.project.name) || task1.taskList.id - task2.taskList.id;
}
export function orderDescByProject(task1, task2) {
  return task2.project.name.localeCompare(task1.project.name) || task2.taskList.id - task1.taskList.id;
}

export function orderAscByCreatedBy(task1, task2) {
  return (
    task1.createdBy.firstName.localeCompare(task2.createdBy.firstName) ||
    task1.createdBy.lastName.localeCompare(task2.createdBy.lastName)
  );
}
export function orderDescByCreatedBy(task1, task2) {
  return (
    task2.createdBy.firstName.localeCompare(task1.createdBy.firstName) ||
    task2.createdBy.lastName.localeCompare(task1.createdBy.lastName)
  );
}

export function orderAscByCompletedOn(task1, task2) {
  if (task1.completedOn && task1.completedOn.isValid) {
    if (task2.completedOn && task2.completedOn.isValid) {
      // recently completed subtask should be at top
      return task2.completedOn.valueOf() - task1.completedOn.valueOf();
    }
    return -1;
  }
  if (task2.completedOn && task2.completedOn.isValid) {
    return 1;
  }
  return orderAscByName(task1, task2);
}
export function orderDescByCompletedOn(task1, task2) {
  if (task2.completedOn && task2.completedOn.isValid) {
    if (task1.completedOn && task1.completedOn.isValid) {
      // recently completed subtask should be at bottom
      return task1.completedOn.valueOf() - task2.completedOn.valueOf();
    }
    return -1;
  }
  if (task1.completedOn && task1.completedOn.isValid) {
    return 1;
  }
  return orderAscByName(task1, task2);
}

export function orderAscByEstimate(task1, task2) {
  return task1.estimateMinutes - task2.estimateMinutes;
}
export function orderDescByEstimate(task1, task2) {
  return task2.estimateMinutes - task1.estimateMinutes;
}

export function orderAscDateUpdated(task1, task2) {
  return task1.updatedAt.valueOf() - task2.updatedAt.valueOf();
}
export function orderDescDateUpdated(task1, task2) {
  return task2.updatedAt.valueOf() - task1.updatedAt.valueOf();
}
