<script setup>
import { provideFeatures } from '../features/useFeatures';
import ApiSetupEmbed4 from './ApiSetupEmbed4.vue';

provideFeatures();
</script>

<template>
  <ApiSetupEmbed4><slot /></ApiSetupEmbed4>
</template>
