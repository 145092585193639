<script setup>
import { LsaEmojiDislike, LsaEmojiFrown, LsaEmojiHeart, LsaEmojiJoy, LsaEmojiLike } from '@/designsystem';
import { ENTITY_ICON_MAP } from './entity';

const props = defineProps({
  group: {
    type: String,
    default: '',
  },

  action: {
    type: String,
    default: '',
  },

  size: {
    type: [String, Number],
    default: 32,
  },
});

const reactions = {
  liked: LsaEmojiLike,
  disliked: LsaEmojiDislike,
  loved: LsaEmojiHeart,
  laughed: LsaEmojiJoy,
  frowned: LsaEmojiFrown,
};

const reaction = computed(() => reactions[props.action]);
const icon = computed(() => ENTITY_ICON_MAP[props.group]);
</script>

<template>
  <Component :is="reaction" v-if="reaction" :width="size" :height="size" />
  <LscIcon v-else-if="icon" color="#0b0e1f" :icon="icon" :size="size" />
</template>
