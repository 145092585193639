// We use an old version of `marked` (2.1.3) to guarantee backward compatibility with twa
// and the old content in the database. Ideally we'd upgrade `marked` to the latest version soon.
// The version we use does not have the `module` property in package.json, so we have to explicitly
// import the ESM file which is necessary for storybook to work.
import marked from 'marked/lib/marked.esm';
import { sanitize } from '../string/sanitize';

export function markdownToHtml(markdownText) {
  let htmlOutputString = markdownText;
  htmlOutputString = htmlOutputString.replace(/<code>/g, '\n~~~\n').replace(/<\/code>/g, '\n~~~\n');
  htmlOutputString = htmlOutputString.replace(/\[code\]/g, '\n~~~\n').replace(/\[\/code\]/g, '\n~~~\n');

  // handle block quotes differently
  htmlOutputString = htmlOutputString
    .replace(/<blockquote>/g, '[blockquote]')
    .replace(/<\/blockquote>/g, '[/blockquote]');

  // handle strike, del and s tags
  htmlOutputString = htmlOutputString.replace(/<s>/g, '~~').replace(/<\/s>/g, '~~');
  htmlOutputString = htmlOutputString.replace(/<del>/g, '~~').replace(/<\/del>/g, '~~');
  htmlOutputString = htmlOutputString.replace(/<strike>/g, '~~').replace(/<\/strike>/g, '~~');

  htmlOutputString = marked(htmlOutputString, {
    // Turn new lines into <br> tags.
    breaks: true,
    // Treat html in markdown as text.
    // This is done for compatibility with earlier Teamwork versions only.
    // For security we rely on DOMPurify.
    sanitize: true,
    // Prevent the warning generated by usage of the deprecated `sanitize` option.
    // See https://marked.js.org/using_advanced#options
    silent: true,
  });

  // diacritic fix for @mentions
  htmlOutputString = htmlOutputString.replace(
    /(^|\s)(@[a-zA-Z0-9À-ž_-]+)/gi,
    '$1<span class="atwho-inserted">$2</span>',
  );

  htmlOutputString = htmlOutputString
    .replace(/\[blockquote\]/g, '<blockquote>')
    .replace(/\[\/blockquote\]/g, '</blockquote>');

  // remove any dangerous HTML
  htmlOutputString = sanitize(htmlOutputString);

  return htmlOutputString;
}
