import { useItemLoader } from '../base/useItemLoader';
import { useItemToListLoader } from '../base/useItemToListLoader';

/**
 * Every article returns
 * @typedef {Object} KnowledgeBaseListArticle a related article
 * @property {number} id
 * @property {number} CategoryID category id (not currently available)
 * @property {number} popularity popularity index, bigger is "better"
 * @property {string} title article title
 * @property {string} description article description (not currently available)
 * @property {string} slug article slug
 * @property {string} status publish status of article
 * @property {string} categorySlug slug for containing category
 * @property {string} canonicalURL URL link to original article on support site
 * @property {string} createdAt article creation date as ISO string
 * @property {string} updatedAt article last updated date as ISO string
 */

function responseToItem(response) {
  return response.data.articles;
}

/**
 * Get a list of articles in a given category
 * @param {string} categorySlug category slug
 * @returns {KnowledgeBaseListArticle[]} array of available articles
 */
export function useKnowledgeBaseCategoryArticlesBySlugLoader({ count, categorySlug: _categorySlug }) {
  const categorySlug = shallowRef(_categorySlug);

  const { state } = useItemLoader({
    url: computed(() => `https://support.teamwork.com/projects/${categorySlug.value}?order=popularity`),
    responseToItem,
  });

  return useItemToListLoader(state, count);
}
