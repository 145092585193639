<script setup>
import { useCurrentAccount } from '@/api';
import { useI18n } from '@/util';
import {
  Filter,
  FilterCategoryPicker,
  FilterCheckbox,
  FilterCompanyPicker,
  FilterCustomFields,
  FilterPeoplePicker,
  FilterProjectPicker,
  FilterStatusTypes,
  FilterTagPicker,
} from '@/module/filter';
import { useReportCustomBuilderFilters } from '../useReportCustomBuilderFilters';

const { t } = useI18n();
const account = useCurrentAccount();
const { filterParams } = useReportCustomBuilderFilters();
const clientOrCompanyText = computed(() => (account.value?.useClientView ? t('Client') : t('Company')));
</script>

<template>
  <Filter
    v-model="filterParams"
    dataIdentifierPrefix="report-builder-filter-project"
    section="reportBuilderFilter"
    :allowSaveFilters="false"
    class="flex flex-col"
  >
    <template #menuFilters>
      <FilterProjectPicker :title="t('Project')" name="projectIds" />
      <FilterTagPicker v-if="account.tagsEnabled" :title="t('Tag')" name="tagIds" />
      <FilterPeoplePicker
        :title="t('Owner')"
        name="userIds"
        :loaderParams="{ includeClients: false, includeCollaborators: false }"
      />
      <FilterCompanyPicker :title="clientOrCompanyText" name="companyIds" />
      <FilterCategoryPicker :title="t('Category')" name="categoryIds" />
      <FilterStatusTypes :title="t('Project status')" name="status" :status="['all', 'late']" />
      <FilterCustomFields :title="t('Custom field')" entities="project" />
    </template>
    <template #menuOptions>
      <FilterCheckbox
        name="includeCompletedProjects"
        :label="t('Include completed projects')"
        :defaultValue="filterParams.includeCompletedProjects"
        data-identifier="report-builder-filter-project--completed-projects-checkbox"
      />
      <FilterCheckbox
        name="onlyStarredProjects"
        :label="t('Show starred projects only')"
        data-identifier="report-builder-filter-project--starred-projects-checkbox"
      />
      <FilterCheckbox
        name="includeArchivedProjects"
        :label="t('Include data from archived projects')"
        data-identifier="report-builder-filter-project--archived-projects-checkbox"
      />
      <FilterCheckbox
        name="projectsWithActivityOnly"
        :label="t('Show projects with activity only')"
        data-identifier="report-builder-filter-project--activity-only-checkbox"
      />
    </template>
  </Filter>
</template>
