import axios from 'axios';
import { Settings } from 'luxon';
import { useI18n } from '@/util';
import { publicShareToken } from './publicShareToken';
import { useVersion } from './useVersion';

const symbol = Symbol('useAxios');

// Indicates if we have just been redirected from Launchpad.
// `document.referrer` does not meet our needs because it stays the same after reloading the app.
const redirectedFromLaunchpad = (() => {
  const url = new URL(window.location.href);
  const result = url.searchParams.get('redirectedFromLaunchpad') === 'true';
  url.searchParams.delete('redirectedFromLaunchpad');
  window.history.replaceState(window.history.state, null, url);
  return result;
})();

/**
 * @param {import('axios').AxiosInstance} customAxios
 */
function Axios(customAxios) {
  const got401 = shallowRef(false);

  if (customAxios) {
    return { axios: customAxios, got401, report401: redirectToLaunchpad };
  }

  const { version } = useVersion();
  const toast = useLsToast();
  const { t } = useI18n();
  const defaultAxios = axios.create({
    headers: {
      // Asks the server to respond in JSON format.
      'Accept': 'application/json',
      // Prevents the browser from showing the native login modal on 401 errors.
      'X-Requested-With': 'XMLHttpRequest',
      // Increases the rate limit from 300 to 1000 on v3 endpoints.
      // Tells the server the version number of the client.
      'twProjectsVer': version,
      // The Teams feature must be explicitly requested, as it is off by default.
      // It's been done this way to keep the Teamwork HTTP API backwards compatible.
      // As a lof of endpoints can return or accept teams,
      // it is easiest to just add this header to all requests.
      // It would be nice to eventually enable teams by default in the Teamwork HTTP API.
      'Teams-Enabled': 'true',
      // Used for API usage analytics.
      'Sent-By': 'lightspeed',
      // Used for API usage analytics.
      'Triggered-By': 'user',
    },
    adapter: 'fetch',
  });

  defaultAxios.interceptors.request.use(
    (config) => {
      // Used for API usage analytics.
      // eslint-disable-next-line no-param-reassign
      config.headers['Referer-Path'] = window.location.pathname;

      if (Settings.defaultZone.isValid) {
        // The "Time-Zone" header must be added in an interceptor
        // because the required value might change over time.
        // eslint-disable-next-line no-param-reassign
        config.headers['Time-Zone'] = Settings.defaultZone.name;
      }
      return config;
    },
    null,
    { synchronous: true },
  );

  defaultAxios.interceptors.response.use(
    null,
    (error) => {
      // Handle `errorMessage` to show a toast message as needed.
      if (error.config) {
        let { errorMessage } = error.config;
        if (typeof errorMessage === 'function') {
          errorMessage = errorMessage(error);
        }
        if (errorMessage === true || (errorMessage === undefined && error.config.method !== 'get')) {
          toast.createToast(t('Request failed'), 'critical');
        } else if (errorMessage) {
          toast.createToast(errorMessage, 'critical');
        }
      }

      // Handle authentication errors.
      if (
        error.response?.status === 401 &&
        !error.response?.request?.url.startsWith(`${window.location.origin}/desk/api/`) &&
        !error.response?.request?.url.startsWith(`${window.location.origin}/synthesis/api/`)
      ) {
        redirectToLaunchpad();
      }

      throw error;
    },
    { synchronous: true },
  );

  function redirectToLaunchpad() {
    if (got401.value) {
      // eslint-disable-next-line no-console
      console.warn('Got a 401 error - preventing duplicate redirect');
      return;
    }

    got401.value = true;

    if (window.location.pathname.startsWith('/app/shared/') && publicShareToken) {
      // eslint-disable-next-line no-console
      console.warn('Got a 401 error - not redirecting to launchpad on /app/shared/');
    } else if (window.location.pathname.startsWith('/app/embed/')) {
      // eslint-disable-next-line no-console
      console.warn('Got a 401 error - not redirecting to launchpad on /app/embed/');
    } else if (window.location.pathname.startsWith('/app/public/')) {
      // eslint-disable-next-line no-console
      console.warn('Got a 401 error - not redirecting to launchpad on /app/public/');
    } else if (import.meta.env.DEV && !window.location.hostname.endsWith('dev.stg.teamworkops.com')) {
      // eslint-disable-next-line no-console
      console.warn('Got a 401 error - not redirecting to launchpad on localhost');
    } else if (performance.now() < 10000 && redirectedFromLaunchpad) {
      // eslint-disable-next-line no-console
      console.warn('Got a 401 error - redirecting to the logout page to avoid a redirection loop');
      // If Launchpad redirected the user to Lightspeed and we get a 401 within 10 seconds after loading the app,
      // then there's a high chance that there's something seriously wrong with the authentication.
      // Redirecting back to the login page would likely result in an infinite redirection loop between
      // Launchpad and Lightspeed. We avoid this problem by redirecting to the logout page instead.
      window.location = '/launchpad/logout/projects?r=authFail';
    } else {
      // eslint-disable-next-line no-console
      console.warn('Got a 401 error - redirecting to the login page');
      const url = new URL(window.location.href);
      url.searchParams.set('redirectedFromLaunchpad', 'true');
      window.location = `/launchpad/login/projects?continue=${encodeURIComponent(url.toString())}`;
    }
  }

  return { axios: defaultAxios, got401, report401: redirectToLaunchpad };
}

export const axiosPlugin = {
  install(app, customInstance) {
    app.provide(symbol, Axios(customInstance));
  },
};

/** @returns {import('axios').AxiosInstance} */
export function useAxios() {
  return inject(symbol).axios;
}

export function useAxiosAuthError() {
  const { got401, report401 } = inject(symbol);
  return {
    /** @type {ShallowRef<boolean>} */
    got401,
    /**
     * A function for reporting 401 errors which occurred in twa. It must not be used for any other purpose.
     * @type {function}
     * @see https://github.com/Teamwork/teamwork-web-app
     */
    report401,
  };
}
