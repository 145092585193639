<script setup>
import { getAccessibleTextColor, getInitials } from '@/util';

const props = defineProps({
  project: {
    type: Object,
    required: true,
  },
  size: {
    type: String,
    default: 'md',
    validator: (value) => ['md', 'lg'].includes(value),
  },
  preview: {
    type: Object,
    default: null,
  },
});

const logoIcon = computed(() => {
  return props.preview?.logoIcon || props.project.logoIcon;
});

const bgColorsPalette = ['#94c7fb', '#bba1ff', '#97e8f0', '#ffa785', '#95e1c1', '#ffa0dd'];

function getIconBackgroundColor(project) {
  if (project.logoColor) {
    return project.logoColor;
  }
  return project?.category?.color || bgColorsPalette[project.id % bgColorsPalette.length];
}

const style = computed(() => {
  const bgColor = getIconBackgroundColor(props.project);
  const textColor = getAccessibleTextColor(bgColor);

  return {
    '--bg-color': bgColor,
    '--text-color': textColor,
  };
});
</script>

<template>
  <div
    class="flex items-center justify-center rounded-sm bg-[--bg-color] text-[--text-color]"
    :class="{
      'size-6': size === 'md',
      'size-8': size === 'lg',
    }"
    :style="style"
  >
    <template v-if="logoIcon">
      <LscIcon v-if="logoIcon.startsWith('lsi-')" :icon="logoIcon" :size="size === 'md' ? 16 : 20" />
      <template v-else>
        {{ logoIcon }}
      </template>
    </template>
    <template v-else>
      {{ getInitials(project.name, 1) }}
    </template>
  </div>
</template>
