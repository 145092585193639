import { calendarRoutes } from './calendar';
import { cancellationRoutes } from './cancellation';
import { clientsRoutes } from './clients';
import { companyRoutes } from './company';
import { everythingRoutes } from './everything';
import { homeRoutes } from './home';
import { miscRoutes } from './misc';
import { onboardingRoutes } from './onboarding';
import { peopleRoutes } from './people';
import { personRoutes } from './person';
import { planningRoutes } from './planning';
import { playgroundRoutes } from './playground';
import { projectsRoutes } from './projects';
import { proofsRoutes } from './proofs';
import { redirectRoutes } from './redirect';
import { reportsRoutes } from './reports';
import { searchRoutes } from './search';
import { settingsRoutes } from './settings';
import { spacesRoutes } from './spaces';
import { teamRoutes } from './team';
import { timeRoutes } from './time';

export const routes = [
  {
    path: '',
    redirect: '/home/',
  },
  ...calendarRoutes,
  ...cancellationRoutes,
  ...clientsRoutes,
  ...companyRoutes,
  ...everythingRoutes,
  ...homeRoutes,
  ...miscRoutes,
  ...onboardingRoutes,
  ...peopleRoutes,
  ...personRoutes,
  ...planningRoutes,
  ...playgroundRoutes,
  ...projectsRoutes,
  ...proofsRoutes,
  ...redirectRoutes,
  ...reportsRoutes,
  ...searchRoutes,
  ...settingsRoutes,
  ...spacesRoutes,
  ...teamRoutes,
  ...timeRoutes,
  {
    path: '/:path(.*)*',
    redirect() {
      return '/home';
    },
  },
];
