<script setup>
import { useTheaterMode } from '@/route';
import { formatKeyboardShortcut, useI18n } from '@/util';

const { t } = useI18n();
const { isTheaterModeEnabled } = useTheaterMode();
</script>

<template>
  <LscToolbar class="w-20 cursor-pointer">
    <LscToolbarControl
      :ariaLabel="t('Exit full-screen')"
      prependIcon="lsi-full-screen-exit"
      @click="isTheaterModeEnabled = false"
    >
      {{ t('Exit full-screen') }}
    </LscToolbarControl>
    <div
      class="rounded-md bg-[color:--lsds-c-toolbar-input-color-background] px-2 py-1 text-center text-body-1 text-on-dark outline-none disabled:cursor-not-allowed"
    >
      {{ formatKeyboardShortcut('Shift + T') }}
    </div>
  </LscToolbar>
</template>
