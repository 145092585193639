import { useI18n } from '@/util';

const symbol = Symbol('useManageSubscription');

function ManageSubscriptionService() {
  const { t } = useI18n();

  const steps = [
    {
      id: 'selectPlan',
      label: t('Select plan'),
    },
    {
      id: 'confirmation',
      label: t('Confirmation'),
    },
  ];

  const currentStep = shallowRef(steps[0]);
  const showDemoBooked = shallowRef(false);
  const selectedPricingPlanData = shallowRef({});

  function resetState() {
    currentStep.value = steps[0];
    showDemoBooked.value = false;
    selectedPricingPlanData.value = {};
  }

  return {
    steps,
    currentStep,
    showDemoBooked,
    selectedPricingPlanData,
    resetState,
  };
}

export function provideManageSubscription() {
  const manageSubscriptionService = ManageSubscriptionService();
  provide(symbol, manageSubscriptionService);
  return manageSubscriptionService;
}

/**
 * @type {ManageSubscriptionService}
 */
export function useManageSubscription() {
  return inject(symbol);
}
