import { usePricePlan } from '../account/usePricePlan';
import { useCohort } from '../cohort/useCohort';
import { useFeatures } from '../features/useFeatures';
import { useLaunchDarkly } from '../features/useLaunchDarkly';

/**
 * Constants, computed properties and functions related to experiment A-24-13c - Grouped & personalised side nav
 * https://digitalcrew.teamwork.com/spaces/growth/page/57429-a-24-13-grouped-and-personalised-side-nav
 * https://app.launchdarkly.com/projects/default/flags/projects-exp-a-24-13-grouped-and-personalised-side-nav/targeting?env=production
 */
export function useExperimentA2413c() {
  const { isPersonaWorkerBee, isPersonaPM, isPersonaOps, isPersonaOwner } = useCohort();
  const { projectsGroupedAndPersonalisedSideNavEnabled } = useFeatures();
  const { isInFreeTrial, isPaid, canTrialInviteUsers } = usePricePlan();
  const { trackLaunchDarklyEvent } = useLaunchDarkly();

  const DEFAULT_GROUP_ITEMS_KEYS = ['home', 'search', 'welcome', 'jumpTo'];
  const PLAN_REPORT_GROUP_ITEMS_KEYS = ['planning', 'reports'];

  const { launchDarklyFlagKey, defaultValue } = projectsGroupedAndPersonalisedSideNavEnabled;

  const appLevelTargeting = computed(
    () =>
      (isPaid.value && isPersonaWorkerBee.value) ||
      (isInFreeTrial.value &&
        (isPersonaWorkerBee.value || isPersonaPM.value || isPersonaOwner.value || isPersonaOps.value)),
  );
  const isExpA2413cVariation = computed(
    () => projectsGroupedAndPersonalisedSideNavEnabled.value && appLevelTargeting.value,
  );

  const shouldShowInviteTeamSplitButton = computed(
    () => isExpA2413cVariation.value && isInFreeTrial.value && canTrialInviteUsers.value,
  );

  const personalisedSidebarPinnedItems = computed(() => {
    // Not all personas are mutually exclusive. We might have a situation where the user is both PM and Owner persona.
    // In that case, we would like the Owner persona personalisation to take priority.
    if (isPersonaOwner.value) {
      return {
        home: { docked: true },
        search: { docked: true },
        jumpTo: { docked: true },
        welcome: { docked: false },
        clients: { docked: true },
        projects: { docked: true },
        templates: { docked: false },
        planning: { docked: true },
        budgets: { docked: false },
        reports: { docked: true },
        time: { docked: false },
        proofs: { docked: false },
        forms: { docked: false },
        everything: { docked: false },
        calendar: { docked: false },
        people: { docked: false },
      };
    }

    return {
      home: { docked: true },
      search: { docked: true },
      jumpTo: { docked: true },
      welcome: { docked: false },
      clients: { docked: isPersonaPM.value || isPersonaOps.value },
      projects: { docked: true },
      templates: { docked: false },
      planning: { docked: isPersonaPM.value || isPersonaOps.value },
      budgets: { docked: false },
      reports: { docked: isPersonaPM.value || isPersonaOps.value },
      time: { docked: isPersonaWorkerBee.value || isPersonaPM.value || isPersonaOps.value },
      proofs: { docked: false },
      forms: { docked: false },
      everything: { docked: false },
      calendar: { docked: false },
      people: { docked: false },
    };
  });

  function trackExperimentA2413c(trackExperimentInPendoFn) {
    // Pendo can't be directly imported by this composable, as we need to use the composable in usePreferences
    trackExperimentInPendoFn?.({
      launchDarklyFlagKey,
      defaultValue,
      ignoreInExperimentOnRuleMatch: true,
      appLevelTargeting,
    });
  }

  function trackTrialInviteUsersViaSidenavCta(invitedUsers = []) {
    if (!invitedUsers.length) {
      return;
    }

    trackLaunchDarklyEvent({ eventName: 'trial-invite-users-via-sidenav-cta' });
  }

  return {
    DEFAULT_GROUP_ITEMS_KEYS,
    PLAN_REPORT_GROUP_ITEMS_KEYS,
    isExpA2413cVariation,
    shouldShowInviteTeamSplitButton,
    personalisedSidebarPinnedItems,
    trackExperimentA2413c,
    trackTrialInviteUsersViaSidenavCta,
  };
}
