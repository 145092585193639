<script setup>
defineProps({
  data: {
    type: Array,
    default: () => [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}],
  },
});

function getAvatarBackgroundColorByIndex(index) {
  switch (index % 6) {
    case 1:
      return 'var(--lsds-a-color-decorative-1)';
    case 2:
      return 'var(--lsds-a-color-decorative-2)';
    case 3:
      return 'var(--lsds-a-color-decorative-6)';
    case 4:
      return 'var(--lsds-a-color-decorative-3)';
    case 5:
      return 'var(--lsds-a-color-decorative-5)';
    default:
      return 'var(--lsds-a-color-decorative-7)';
  }
}
</script>
<template>
  <div>
    <div v-for="(person, personIndex) in data" :key="personIndex" class="flex h-10 items-center gap-2 border border-b">
      <template v-if="person.name">
        <div
          class="flex size-6 shrink-0 items-center justify-center rounded-full text-body-2"
          :style="{ backgroundColor: getAvatarBackgroundColorByIndex(personIndex) }"
        >
          {{ person.name.at(0).toUpperCase() }}
        </div>
        <LscOverflowEllipsis class="text-body-1 text-default">{{ person.name }}</LscOverflowEllipsis>
      </template>
      <template v-else>
        <div class="size-6 rounded-full bg-action-secondary-default" />
        <LscSkeleton
          :animation="false"
          :class="['h-[--lsds-a-typography-body-1-regular-font-size]', personIndex % 2 ? 'w-60' : 'w-52']"
        />
      </template>
    </div>
  </div>
</template>
