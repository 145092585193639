import { useListLoader } from '../base/useListLoader';

function responseToItems(response) {
  const { pages = {}, spaces = {} } = response.data.included || {};

  const list = response.data.starred || [];

  list.forEach((starredItem) => {
    if (starredItem.type === 'spaces' && spaces[starredItem.id]) {
      Object.assign(starredItem, spaces[starredItem.id]);
    }
    if (starredItem.type === 'pages' && pages[starredItem.id]) {
      Object.assign(starredItem, pages[starredItem.id]);
      // We need to link the space for list rendering
      if (starredItem.space?.id && spaces[starredItem.space.id]) {
        Object.assign(starredItem.space, spaces[starredItem.space.id]);
      }
    }
  });
  return list;
}

/**
 * Loads a list of starred spaces and pages from the Teamwork API.
 * @param {Object} options
 */
export function useSpacesStarredV1Loader({ params, count, pageSize = 50 }) {
  const { state } = useListLoader({
    url: '/spaces/api/v1/starred.json',
    params,
    count,
    responseToItems,
    pageSize,
  });

  return state;
}
