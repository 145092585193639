/**
 * Shallow-watches `refToWatch`, waits until it changes and resolves the returned promise
 * if the new value matches `expected`. It remains active until the promise is resolved.
 * @param refToWatch A reference to watch.
 * @param expected Determines if the returned promise should be resolved after `refToWatch` changed.
 *   - if undefined, the returned promise will be resolved.
 *   - if a function, it is called with the new value as parameter. If it returns a truthy value
 *     the promise will be resolved.
 *   - if it is strict equal to the new value of `refToWatch`, the promise will be resolved.
 * @returns A promise.
 */
export function until(refToWatch, expected) {
  return new Promise((resolve) => {
    const unwatch = watch(refToWatch, (value) => {
      if (
        typeof expected === 'undefined' ||
        (typeof expected === 'function' && expected(value)) ||
        expected === value
      ) {
        unwatch();
        resolve();
      }
    });
  });
}
