<script setup>
import { useKnowledgeBaseSearchResultLoader, usePendo } from '@/api';
import { useI18n } from '@/util';
import { useHelpCenter } from '../..';
import { STATE_KNOWLEDGE_BASE_ARTICLE } from '../../constants';
import HelpCenterDrawerEmptySearch from '../HelpCenterDrawerEmptySearch.svg';
import HelpCenterDrawerMenuMedium from '../HelpCenterDrawerMenuMedium.vue';

const { t } = useI18n();
const { searchTerm, pushState, HELP_CENTER_COMMON_METRICS } = useHelpCenter();
const params = computed(() => ({
  searchTerm,
}));
const { trackPendoEvent } = usePendo();
const state = useKnowledgeBaseSearchResultLoader({ params });
const { items: results } = state;

function articleClicked(article) {
  trackPendoEvent({
    eventName: 'HELP_EVENT',
    commonMetrics: HELP_CENTER_COMMON_METRICS,
    metadata: {
      event_action: 'search_result_clicked',
      event_label: article.title,
    },
  });
  pushState(STATE_KNOWLEDGE_BASE_ARTICLE, article);
}
</script>

<template>
  <div class="m-0 h-full max-h-[inherit] overflow-y-auto p-0">
    <div class="h-full overflow-x-hidden px-6 pb-6">
      <LswLoaderState :state="state">
        <template #default>
          <HelpCenterDrawerMenuMedium :items="results" @itemClicked="articleClicked" />
        </template>
        <template #blank>
          <LscEmptyState
            size="sm"
            class="h-full"
            :title="t('No articles found matching your search')"
            :message="t('Try again with a different search term')"
          >
            <template #image>
              <HelpCenterDrawerEmptySearch />
            </template>
          </LscEmptyState>
        </template>
      </LswLoaderState>
    </div>
  </div>
</template>
