/**
 * Transform assignees to the format expected by the Teamwork API
 * @param {Object[]} assignees - The assignees
 * @returns a comma separated string of assignees
 */
export function toAssigneeString(assignees = []) {
  return assignees
    .map(({ id, type, entityType }) => {
      if (type === 'team' || entityType === 'team') {
        return `t${id}`;
      }
      if (type === 'company' || entityType === 'company') {
        return `c${id}`;
      }
      return `${id}`;
    })
    .filter(Boolean)
    .join(',');
}
