import { useDebounceFn, useEventListener, useThrottleFn } from '@vueuse/core';
import { useBroadcast, useDevtools } from '@/util';

const symbol = Symbol('useRealTimeUpdates');

/**
 * Basic normalization of the events coming over the WebSocket connection.
 */
function normalizeRawEvent(rawEvent) {
  const eventInfo = rawEvent.eventInfo || {};
  const extraInfo = eventInfo.extraInfo || {};
  let data = extraInfo.data || {};

  if (typeof data === 'string') {
    try {
      data = JSON.parse(data);
    } catch {
      // eslint-disable-next-line no-console
      console.warn(`Failed to parse twimEvent.eventInfo.extraInfo.data: ${data}`);
      data = {};
    }
  }

  return {
    ...rawEvent,
    eventInfo: {
      ...eventInfo,
      extraInfo: {
        ...extraInfo,
        data: {
          ...data,
        },
      },
    },
  };
}

function toId(value) {
  if (typeof value === 'number') {
    return value;
  }
  if (typeof value === 'string' && value) {
    return Number(value);
  }
  return null;
}

function toIds(s) {
  if (typeof s === 'string') {
    return s
      .split(',')
      .map((id) => toId(id.trim()))
      .filter((id) => id !== null);
  }

  return [];
}

// `categoryType` must be normalized.
function toCategoryType(categoryType) {
  switch (categoryType) {
    case 'projects':
      return 'project';
    case 'files':
      return 'file';
    case 'notebooks':
      return 'notebook';
    case 'messages':
      return 'message';
    case 'links':
      return 'link';
    default:
      return categoryType;
  }
}

// `fileId` must be obtained from a link.
// See https://digitalcrew.teamwork.com/#/tasks/19198342
function toFileId(link) {
  if (typeof link !== 'string') {
    return null;
  }
  const match = /^files\/(\d+)/.exec(link);
  if (!match) {
    return null;
  }
  return Number(match[1]);
}

function normalizeProofEventAction({ actionType }) {
  if (['new', 'add', 'added', 'create', 'created'].includes(actionType)) {
    return 'create';
  }

  if (['update', 'updated'].includes(actionType)) {
    return 'update';
  }

  if (['delete', 'deleted'].includes(actionType)) {
    return 'delete';
  }

  return actionType;
}

// We should really create a spec for the events,
// which would ideally define the normalized properties below.
// Then we could get rid of the `normalize` function.
function normalize({ eventInfo: event }) {
  const { extraInfo } = event;

  switch (event.itemType) {
    case 'task':
      return {
        type: 'task',
        action: event.actionType,
        detail: event.event,
        taskId: toId(event.itemId),
        parentTaskId: toId(extraInfo.parentTaskId),
        oldParentTaskId: toId(extraInfo.data.oldParentTaskId),
        tasklistId: toId(extraInfo.taskListId),
        oldTasklistId: toId(extraInfo.data.oldTaskListId),
        projectId: toId(event.projectId),
        oldProjectId: toId(extraInfo.data.oldProjectId),
        lockdownId: toId(event.lockdownId),
        milestoneId: toId(extraInfo.data.milestoneId || extraInfo.data.newMilestoneId),
        oldMilestoneId: toId(extraInfo.data.oldMilestoneId),
        affectedWorkflowIds: toIds(extraInfo.data.affectedWorkflowIds),
        affectedStageIds: toIds(extraInfo.data.affectedStageIds),
        hasEstimatedTime: extraInfo.data.hasEstimatedTime,
        hasDependents: extraInfo.data.hasDependents,
      };
    case 'tasklist':
    case 'taskList':
      return {
        type: 'tasklist',
        action: event.actionType,
        detail: event.event,
        projectId: toId(event.projectId || extraInfo.data.destProjectId),
        oldProjectId: toId(extraInfo.data.sourceProjectId),
        tasklistId: toId(event.itemId),
      };
    case 'tasklisttasks':
    case 'tasklistTasks':
    case 'taskListTasks':
      return {
        type: 'tasklistTasks',
        action: event.actionType,
        detail: event.event,
        projectId: toId(event.projectId),
        tasklistId: toId(event.itemId),
        affectedTaskIds: toIds(event.extraInfo.data.affectedTaskIds),
        affectedTaskListIds: toIds(event.extraInfo.data.affectedTaskListIds),
        affectedProjectIds: toIds(event.extraInfo.data.affectedProjectIds),
      };
    case 'projecttasks':
    case 'projectTasks':
      return {
        type: 'projectTasks',
        action: event.actionType,
        detail: event.event,
        projectId: toId(event.projectId),
        affectedTaskIds: toIds(event.extraInfo.data.affectedTaskIds),
        affectedTaskListIds: toIds(event.extraInfo.data.affectedTaskListIds),
        affectedProjectIds: toIds(event.extraInfo.data.affectedProjectIds),
        affectedWorkflowIds: toIds(event.extraInfo.data.affectedWorkflowIds),
        affectedStageIds: toIds(event.extraInfo.data.affectedStageIds),
      };
    case 'comment':
    case 'file_comment':
    case 'task_comment': {
      const hasFile = extraInfo.objectType === 'file';
      const hasTask = extraInfo.objectType === 'task';
      return {
        type: 'comment',
        action: event.actionType,
        detail: event.event,
        projectId: toId(event.projectId),
        fileId: toFileId(hasFile && (event.extraLink || event.itemLink)),
        fileVersionId: toId(hasFile && extraInfo.objectId),
        tasklistId: toId(hasTask && extraInfo.taskListId),
        parentTaskId: toId(hasTask && extraInfo.parentTaskId),
        taskId: toId(hasTask && extraInfo.objectId),
        commentId: toId(event.itemId),
      };
    }
    case 'milestone':
      return {
        type: 'milestone',
        action: event.actionType,
        detail: event.event,
        projectId: toId(event.projectId),
        milestoneId: toId(event.itemId),
      };
    case 'unavailable-time':
      return {
        type: 'unavailable-time',
        action: event.actionType,
        detail: event.event,
        eventId: toId(event.itemId),
        // The IDs of those users whom the unavailable time is assigned to.
        unavailableTimesUserIds: event.unavailableTime.attendingUserIds || event.unavailableTime['attending-user-ids'],
      };
    case 'event':
      return {
        type: 'unavailable-time',
        action: event.actionType,
        detail: event.event,
        eventId: toId(event.itemId),
        // The IDs of those users whom the unavailable time is assigned to.
        unavailableTimesUserIds: extraInfo.data.unavailableTimesUserIds,
      };
    case 'calendar':
      return {
        type: 'calendar',
        action: event.actionType,
        detail: event.event,
        calendarId: toId(event.itemId),
      };
    case 'calendarevent':
    case 'calendarEvent': {
      const eventId = toId(event.itemId);

      return {
        type: 'calendarEvent',
        action: event.actionType,
        detail: event.event,
        eventId: Number.isNaN(eventId) ? event.itemId : eventId,
        recurringEventId: toId(extraInfo.data.recurringEventId),
        hasTimeblock: Boolean(extraInfo.data.Timeblock),
        timelogId: toId(extraInfo.data.TimelogID),
      };
    }
    case 'time': {
      const hasTask = extraInfo.objectType === 'task';
      return {
        type: 'time',
        action: event.actionType,
        projectId: toId(event.projectId),
        // `userId` is the ID of the user to whom the time log belongs, and not necessarily the user who created the time log.
        userId: toId(extraInfo.data.userId),
        // oldTaskListId, oldParentTaskId and oldTaskId are populated when a time log is detached from a task.
        tasklistId: toId(hasTask && (extraInfo.taskListId || extraInfo.data.oldTaskListId)),
        parentTaskId: toId(hasTask && (extraInfo.parentTaskId || extraInfo.data.oldParentTaskId)),
        taskId: toId(hasTask && (extraInfo.objectId || extraInfo.data.oldTaskId)),
        timelogId: toId(event.itemId),
        invoiceId: extraInfo.objectType === 'invoice' ? toId(extraInfo.objectId) : null,
      };
    }
    case 'usertimer': {
      return {
        type: 'timer',
        action: event.actionType,
        userId: toId(extraInfo.data.userId),
      };
    }
    case 'file':
      return {
        type: 'file',
        action: event.actionType,
        detail: event.event,
        projectId: toId(event.projectId),
        fileId: toFileId(event.itemLink),
        fileVersionId: toId(event.itemId),
        affectedTaskIds: toIds(extraInfo.data.affectedTaskIds),
      };
    case 'project':
      // eslint-disable-next-line no-case-declarations
      let userId = extraInfo.data?.userId;
      // When adding or removing a person from a project
      if (extraInfo?.objectType === 'user' && extraInfo?.objectId != null) {
        userId = extraInfo?.objectId;
      }

      return {
        type: 'project',
        action: event.actionType,
        detail: event.event,
        projectId: toId(event.projectId),
        // When `action === 'rate-set' and the user rate was updated for a single user,
        // then `userId` is the ID of the user whose rate was updated.
        userId: toId(userId),
        categoryChanged: Boolean(extraInfo.data.categoryChanged),
      };
    case 'budget':
      return {
        type: 'budget',
        action: event.actionType,
        detail: event.event,
        projectId: toId(event.projectId),
        budgetId: toId(event.itemId),
      };
    case 'tasklistBudget':
      return {
        type: 'tasklistBudget',
        action: event.actionType,
        detail: event.event,
        tasklistId: toId(event.tasklistId),
        projectBudgetId: toId(event.projectBudgetId),
        tasklistBudgetId: toId(event.itemId),
      };
    case 'budgetExpense':
      return {
        type: 'budgetExpense',
        action: event.actionType,
        detail: event.event,
        projectBudgetId: toId(event.projectBudgetId),
        budgetExpenseId: toId(event.itemId),
      };
    case 'expense':
    case 'billingExpense':
    case 'projectExpense':
      return {
        type: 'expense',
        action: event.actionType,
        projectId: toId(event.projectId),
        expenseId: toId(event.itemId),
        invoiceId: extraInfo.objectType === 'invoice' ? toId(extraInfo.objectId) : null,
      };
    case 'category':
      return {
        type: 'category',
        action: event.actionType,
        detail: event.event,
        projectId: toId(event.projectId),
        categoryId: toId(event.itemId),
        categoryType: toCategoryType(
          // "catgoryType" is needed for now as there's a typo in the server-sent event
          extraInfo.data.categoryType || extraInfo.data.catgoryType,
        ),
      };
    case 'customreport':
    case 'customReport':
      return {
        type: 'customReport',
        action: event.actionType,
        detail: event.event,
        reportId: toId(event.itemId),
      };
    case 'person':
      return {
        type: 'person',
        action: event.actionType,
        detail: event.event,
        userId: toId(event.itemId),
      };
    case 'installation':
      return {
        type: 'installation',
        action: event.actionType,
        detail: event.event,
        installationId: toId(event.installationId),
      };
    case 'reminder': {
      const hasTask = extraInfo.objectType === 'task';
      return {
        type: 'reminder',
        action: event.actionType,
        detail: event.event,
        projectId: toId(event.projectId),
        tasklistId: toId(hasTask && extraInfo.taskListId),
        taskId: toId(hasTask && extraInfo.objectId),
        parentTaskId: toId(hasTask && extraInfo.parentTaskId),
        reminderId: toId(event.itemId),
      };
    }
    case 'timereminder': {
      return {
        type: 'timereminder',
        action: event.actionType,
      };
    }
    case 'custom_type':
      return {
        type: 'customfield',
        action: event.actionType,
        detail: event.event,
        projectId: toId(event.projectId),
        customfieldId: toId(event.itemId),
      };
    case 'account':
      return {
        type: 'account',
        action: event.actionType,
        detail: event.event,
        accountId: toId(event.itemId),
      };
    case 'clockin':
      return {
        type: 'person',
        detail: event.event,
        action: event.actionType,
        clockinId: toId(event.itemId),
        userId: toId(extraInfo.data.userId),
      };
    case 'notification':
      return {
        type: 'notification',
        notificationId: toId(event.notificationId),
        detail: event.event,
        action: event.actionType,
      };
    case 'announcement':
      return {
        type: 'announcement',
        announcementId: toId(event.itemId),
        detail: event.event,
        action: event.actionType,
      };
    case 'inboxItem':
      return {
        type: 'inboxItem',
        action: event.event,
        entity: event.entity,
        inboxItemId: toId(event.id),
      };
    case 'jobRole':
      return {
        type: 'jobRole',
        action: event.actionType,
        detail: event.event,
        jobRoleId: toId(event.itemId),
      };
    case 'person-jobrole':
      return {
        type: 'personJobRole',
        action: event.actionType,
        detail: event.event,
        jobRoleId: toId(event.itemId),
      };
    case 'timesheetrow': {
      return {
        type: 'timesheetRow',
        action: event.actionType,
        taskId: extraInfo.objectType === 'tasks' ? toId(extraInfo.objectId) : null,
        projectId: extraInfo.objectType === 'projects' ? toId(extraInfo.objectId) : null,
        userId: toId(extraInfo.data.userId),
      };
    }
    case 'timeapprovalssettings':
      return {
        type: 'timeApprovalsSettings',
        action: event.actionType,
        // Users who have been added to the approvers list.
        userIdsAdded: extraInfo.data?.userIdsAdded ?? [],
        // Users who have been removed from the approvers list.
        userIdsRemoved: extraInfo.data?.userIdsRemoved ?? [],
      };
    case 'timeapproval':
      return {
        type: 'timeApproval',
        action: event.actionType,
        timeApprovalId: toId(event.itemId),
        userId: toId(extraInfo.data?.userId),
        status: extraInfo.data?.status,
      };
    case 'form':
      return {
        type: 'form',
        action: event.actionType,
        detail: event.event,
        formId: toId(event.formId),
        projectId: toId(event.projectId),
      };
    case 'message':
      return {
        type: 'message',
        action: event.actionType,
        detail: event.event,
        messageId: toId(event.itemId),
        projectId: toId(event.projectId),
        oldProjectId: toId(extraInfo.data.oldProjectId),
      };
    case 'finished':
      return {
        type: 'finished',
        action: event.actionType,
        detail: event.event,
        description: event.description,
      };
    case 'proof':
      return {
        type: 'proof',
        detail: event.event,
        action: normalizeProofEventAction(event),
        proofId: toId(event.itemId),
      };
    case 'proofobject':
    case 'proofObject':
      return {
        type: 'proofObject',
        detail: event.event,
        action: normalizeProofEventAction(event),
        proofObjectId: toId(event.itemId),
        proofId: toId(event.extraInfo.data.proofId),
      };
    case 'prooffeedback':
    case 'proofFeedback':
      return {
        type: 'proofFeedback',
        detail: event.event,
        action: normalizeProofEventAction(event),
        proofFeedbackId: toId(event.itemId),
        proofId: toId(event.extraInfo.objectId),
      };
    case 'company':
      return {
        type: 'company',
        detail: event.event,
        action: event.actionType,
        companyId: toId(event.itemId),
      };
    case 'allocation':
      return {
        type: 'allocation',
        detail: event.event,
        action: event.actionType,
        allocationId: toId(event.itemId),
        projectId: toId(event.projectId),
        userId: toId(event.userId),
      };
    case 'team':
      return {
        type: 'team',
        action: event.actionType,
        teamId: toId(event.itemId),
      };
    case 'savedfilter-migration':
      return {
        type: 'savedFilterMigration',
        action: event.actionType,
        userId: toId(event.userId),
      };
    case 'statusupdate':
      return {
        type: 'statusupdate',
        action: event.actionType,
        userId: toId(event.userId),
      };
    case 'stage':
      return {
        type: 'stage',
        action: event.actionType,
        stageId: toId(event.itemId),
        affectedStageIds: toIds(extraInfo.data.affectedStageIds),
        affectedWorkflowIds: toIds(extraInfo.data.affectedWorkflowIds),
      };
    case 'tag':
      return {
        type: 'tag',
        action: event.actionType,
        tagId: toId(event.itemId),
      };
    case 'workflow':
    case 'workflow-project':
      return {
        type: 'workflow',
        action: event.actionType,
        workflowId: toId(event.itemId),
        projectId: toId(event.projectId),
      };
    case 'installationpref':
      return {
        type: 'accountPreference',
        action: event.actionType,
        accountId: toId(event.itemId),
      };
    case 'userpref':
      return {
        type: 'userPreference',
        action: event.actionType,
        userId: toId(event.itemId),
      };
    case 'automation':
      return {
        type: 'automation',
        // Supported values are:
        // - new
        // - edited
        // - deleted
        // - integration-enabled (note: no backend event yet)
        // - integration-disabled (note: no backend event yet)
        action: event.actionType,
        automationId: event.itemId,
        stageId: toId(event.extraInfo.data.stageId),
        status: event.extraInfo.data.status,
      };
    // This is a placeholder for the "integration" events, which the backend does not support yet.
    // Just like all the other events here, it serves as the source of truth for the local real-time update events.
    case 'integration':
      return {
        type: 'integration',
        // Supported values are:
        // - hubspot-enabled
        // - hubspot-disabled
        // - paragon-enabled
        // - paragon-disabled
        action: event.actionType,
      };
    case 'billinginvoice':
      return {
        type: 'invoice',
        action: event.actionType,
        projectId: toId(event.projectId),
        invoiceId: toId(event.itemId),
      };
    case 'placeholder-person':
      return {
        type: 'placeholderPerson',
        action: event.actionType,
        userId: toId(event.itemId),
        projectId: toId(event.projectId),
        jobRoleIds: toIds(event.extraInfo.data.jobRoleIds),
      };
    case 'quote':
      return {
        type: 'quote',
        action: event.actionType,
        quoteId: toId(event.itemId),
        companyId: toId(event.extraInfo.data.companyId),
      };
    case 'skill':
      return {
        type: 'skill',
        action: event.actionType,
        detail: event.event,
        skillId: toId(event.itemId),
      };
    case 'userskill':
      return {
        type: 'userSkill',
        action: event.actionType,
        detail: event.event,
        skillId: toId(event.itemId),
      };
    default:
      if (import.meta.env.DEV) {
        // eslint-disable-next-line no-console
        console.warn('Unhandled event', event, '(dev mode)');
      }
      return {
        // Using `undefined`, so that no client code could depend on the non-normalized `event.type`
        // which is important because `event.type` is not always the same as `rawEvent.itemType`.
        type: undefined,
        action: event.actionType,
        detail: event.event,
      };
  }
}

function RealTimeUpdates() {
  const timelineLayerId = 'real-time';
  const devtools = useDevtools();
  // Setup devtools timeline layer
  devtools?.addTimelineLayer?.({
    id: timelineLayerId,
    label: 'Real-time updates',
    color: 0xb5ead4,
  });
  const handlingEventFromSocket = shallowRef(false);
  const sockets = shallowRef([]);
  // `crypto.randomUUID` is supported only since Safari 15.4
  // We can remove the fallback to `Math.random` once we stop supporting the older Safari versions.
  const socketId = crypto?.randomUUID?.() || `${Date.now()}-${Math.random()}`;
  let listeners = [];
  let emitting = 0;
  let pendingEvents = [];

  /**
   * Registers `listener` to be called on real-time updates events.
   * Must be called within a component.
   * Unregisters the listener automatically when the component unmounts.
   */
  function on(listener) {
    // copy on write, if currently emitting
    if (emitting > 0) {
      listeners = listeners.slice();
    }
    listeners.push(listener);

    onUnmounted(() => {
      // copy on write, if currently emitting
      if (emitting > 0) {
        listeners = listeners.slice();
      }

      const index = listeners.indexOf(listener);

      if (index > -1) {
        listeners[index] = listeners[listeners.length - 1];
        listeners.pop();
      }
    });
  }

  /**
   * Emits the specified, normalized real-time updates event.
   *
   * @param event A normalized real-time updates event.
   * @param rawEvent An optional raw real-time updates events received from the server.
   *   `rawEvent` is only intended for use by Notifications. View this thread for context:
   *   https://github.com/Teamwork/frontend/pull/587#issuecomment-967238253
   */
  function emitNow(event, rawEvent) {
    try {
      emitting += 1;
      if (rawEvent) {
        handlingEventFromSocket.value = true;
      }
      // thanks to "copy on write" above, fixedListeners will not change while iterating
      const fixedListeners = listeners;
      for (let i = 0; i < fixedListeners.length; i += 1) {
        try {
          fixedListeners[i](event, rawEvent);
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error('useRealTimeUpdates: Error in an event listener', error);
        }
      }
      // Log to devtools
      devtools?.addTimelineEvent?.({
        layerId: timelineLayerId,
        event: {
          time: devtools.now(),
          data: { ...event, rawEvent },
          title: event.action,
          subtitle: event.type,
        },
      });
    } finally {
      if (rawEvent) {
        handlingEventFromSocket.value = false;
      }
      emitting -= 1;
    }
  }

  function emitPendingEvents() {
    if (pendingEvents.length > 0 && document.visibilityState === 'visible') {
      const events = pendingEvents;
      pendingEvents = [];
      // The format of the server events is inconsistent and hard to work with, so we normalize the raw events.
      events.forEach(({ event, rawEvent }) => emitNow(event ?? normalize(rawEvent), rawEvent));
    }
  }

  // We use throttling to ensure that we deliver the events to loaders at most once every 5 seconds.
  // We avoid unnecessary delays by processing the events at the start and end of the 5 second interval.
  //
  // We use debouncing to batch the events arriving within 1 second because they often result from the same action.
  // We avoid excessive delays by limiting the wait time to 5 seconds.
  const emitPendingEventsLater = useDebounceFn(useThrottleFn(emitPendingEvents, 5000, true, true), 1000, {
    maxWait: 5000,
  });

  /**
   * Handles `eventNotice` events from the notification server or `useBroadcast`.
   */
  function handleEventNotice(rawEvent) {
    // eslint-disable-next-line no-param-reassign
    rawEvent = normalizeRawEvent(rawEvent);

    // In most cases server-side data modification can be completed fast. It works as follows:
    //
    // 1. Client sends a request (includes a Socked-ID header).
    // 2. Server receives the request.
    // 3. Server updates data.
    // 4. Server sends an event (isAsync === false; includes the value of the Socket-ID header).
    // 5. Server sends a response.
    // 6. Client receives the response.
    // 7. Client emits a local event based on the response.
    // 8. Client handles the local event.
    // 9. Client ignores the server event.
    //
    // In rare cases server-side data modification is known to need much time. It works as follows:
    //
    // 1. Client sends a request (includes a Socked-ID header).
    // 2. Server receives the request.
    // 3. Server spawns a thread or schedules an action to update data asynchronously.
    // 4. Server sends a response.
    // 5. Client receives the response.
    // 6. Server updates the data.
    // 7. Server sends an event (isAsync === true; includes the value of the Socket-ID header).
    // 8. Client handles the server event.
    //
    // We use local events for API requests sent from the same browser tab because we have the information
    // necessary to emit such events, and they provide greater reliability and reduced latency
    // which are critical for user experience, as this guarantees that users will see their own changes with
    // minimum delay.

    // Determines if this event was emitted as a result of an API request sent from the same browser tab.
    const isForLocalAction = rawEvent.eventInfo.socketId != null && rawEvent.eventInfo.socketId === socketId;

    // Determines if this event was emitted asynchronously, some time after the API request completed.
    const isAsync = rawEvent.eventInfo.extraInfo.data.threadedLoopback || false;

    // Ignore the server events for which we emit equivalent local events.
    if (isForLocalAction && !isAsync) {
      return;
    }

    pendingEvents.push({ rawEvent });
    emitPendingEventsLater();
  }

  /**
   * Handles local events from `useBroadcast`.
   */
  function handleLocalEvent(event) {
    pendingEvents.push({ event });
    emitPendingEventsLater();
  }

  const { broadcast: broadcastLocalEvent } = useBroadcast('useRealTimeUpdates/localEvent', handleLocalEvent);

  /**
   * Emits the specified, normalized real-time updates event.
   *
   * @param event A normalized real-time updates event.
   */
  function emit(event) {
    // We emit the local event immediately here to ensure that there's no flicker
    // when both optimistic and real-time updates are used in an action.
    emitNow(event);
    broadcastLocalEvent(event);
  }

  const { broadcast: broadcastEventNotice } = useBroadcast('useRealTimeUpdates/eventNotice', handleEventNotice);

  function handleEventNoticeFromSocket(eventNotice) {
    handleEventNotice(eventNotice);
    broadcastEventNotice(eventNotice);
  }

  /**
   * Forwards events from the specified TWIM socket.
   */
  function emitFromSocket(socket) {
    watch(socket, () => socket.value && socket.value.on('eventNotice', handleEventNoticeFromSocket), {
      immediate: true,
    });

    sockets.value = [...sockets.value, socket];

    onUnmounted(() => {
      if (socket.value) {
        socket.value.off('eventNotice', handleEventNoticeFromSocket);
      }
      sockets.value = sockets.value.filter((el) => el !== socket);
    });
  }

  useEventListener(document, 'visibilitychange', emitPendingEvents, false);

  return {
    emit,
    emitFromSocket,
    on,
    socketId,
    handlingEventFromSocket,
  };
}

/**
 * Provides a new real-time updates instance which can later be obtained using `useRealTimeUpdates`.
 * @param {import('vue').App} app
 */
export function realTimeUpdatesPlugin(app) {
  app.provide(symbol, RealTimeUpdates());
}

/**
 * Returns the real-time updates instance provided by `realTimeUpdatesPlugin`.
 * If `listener` is specified, it is registered to listen for real-time updates events.
 * @param {function=} listener
 * @type {RealTimeUpdates}
 */
export function useRealTimeUpdates(listener) {
  const realTimeUpdates = inject(symbol);
  if (listener) {
    realTimeUpdates.on(listener);
  }
  return realTimeUpdates;
}
