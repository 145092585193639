<script setup>
import { useDebounceFn } from '@vueuse/core';
import { AppShellDrawerPanel, AppShellDrawerTitle, useAppShellSidebar } from '@/appShell';
import { useI18n } from '@/util';
import {
  HELP_CENTER_PANEL,
  HELP_CENTER_PANEL_WIDE,
  STATE_CONTACT_SUPPORT,
  STATE_DEFAULT,
  STATE_KEYBOARD_SHORTCUTS,
  STATE_KNOWLEDGE_BASE_ARTICLE,
  STATE_KNOWLEDGE_BASE_CATEGORIES,
  STATE_KNOWLEDGE_BASE_SUBCATEGORIES_AND_ARTICLES,
  STATE_SEARCH_RESULTS,
  STATE_WEBINARS,
  STATE_WHATS_NEW,
} from '../constants';
import { useHelpCenter } from '../useHelpCenter';
import HelpCenterDrawerStateArticle from './state/HelpCenterDrawerStateArticle.vue';
import HelpCenterDrawerStateContactSupport from './state/HelpCenterDrawerStateContactSupport.vue';
import HelpCenterDrawerStateDefault from './state/HelpCenterDrawerStateDefault.vue';
import HelpCenterDrawerStateKeyboardShortcut from './state/HelpCenterDrawerStateKeyboardShortcut.vue';
import HelpCenterDrawerStateKnowledgeBaseCategories from './state/HelpCenterDrawerStateKnowledgeBaseCategories.vue';
import HelpCenterDrawerStateSearchResults from './state/HelpCenterDrawerStateSearchResults.vue';
import HelpCenterDrawerStateSubcategoriesAndArticles from './state/HelpCenterDrawerStateSubcategoriesAndArticles.vue';
import HelpCenterDrawerStateWebinars from './state/HelpCenterDrawerStateWebinars.vue';
import HelpCenterDrawerStateWhatsNew from './state/HelpCenterDrawerStateWhatsNew.vue';

const { activeDrawerPanel } = useAppShellSidebar();

const { searchTerm, popState, resetState, currentState, previousState } = useHelpCenter();
const { t } = useI18n();

const helpCenterPanelIsOpen = computed(() =>
  [HELP_CENTER_PANEL, HELP_CENTER_PANEL_WIDE].includes(activeDrawerPanel.value),
);

const onSearch = useDebounceFn((val) => {
  searchTerm.value = val;
}, 300);

watch(activeDrawerPanel, (openPanel) => {
  if ([HELP_CENTER_PANEL, HELP_CENTER_PANEL_WIDE].includes(openPanel)) {
    return;
  }

  resetState();
});
</script>

<template>
  <AppShellDrawerPanel v-if="helpCenterPanelIsOpen" data-identifier="side-nav-help-center-panel">
    <template #header>
      <AppShellDrawerTitle :title="t('Help Center')">
        <template #prepend-title>
          <LscIconButton
            v-if="!!previousState"
            v-LsdTooltip="t('Go back')"
            icon="lsi-back"
            class="mr-2"
            data-identifier="side-nav-help-center-back"
            :ariaLabel="t('Go back')"
            size="sm"
            @click="popState"
          />
        </template>
      </AppShellDrawerTitle>
    </template>

    <template #content>
      <div class="m-0 flex h-full flex-col overflow-hidden p-0">
        <VTextField
          class="z-10 mx-6 mb-6 grow-0"
          :modelValue="searchTerm"
          :placeholder="t('Search knowledge base')"
          v-bind="VTextFieldPanelSearch"
          clearable
          autofocus
          @update:modelValue="onSearch"
        />

        <Transition
          enterFromClass="opacity-0"
          enterToClass="opacity-100"
          enterActiveClass="transition-opacity duration-200 ease-in-out"
          leaveFromClass="opacity-100"
          leaveToClass="opacity-0"
          leaveActiveClass="transition-opacity duration-200 ease-in-out"
          mode="out-in"
        >
          <HelpCenterDrawerStateDefault v-if="currentState.id === STATE_DEFAULT" />
          <HelpCenterDrawerStateWebinars v-else-if="currentState.id === STATE_WEBINARS" />
          <HelpCenterDrawerStateWhatsNew v-else-if="currentState.id === STATE_WHATS_NEW" />
          <HelpCenterDrawerStateSearchResults v-else-if="currentState.id === STATE_SEARCH_RESULTS" />
          <HelpCenterDrawerStateContactSupport
            v-else-if="currentState.id === STATE_CONTACT_SUPPORT"
            v-bind="currentState.data"
          />
          <HelpCenterDrawerStateKeyboardShortcut v-else-if="currentState.id === STATE_KEYBOARD_SHORTCUTS" />
          <HelpCenterDrawerStateKnowledgeBaseCategories
            v-else-if="currentState.id === STATE_KNOWLEDGE_BASE_CATEGORIES"
          />
          <HelpCenterDrawerStateSubcategoriesAndArticles
            v-else-if="currentState.id === STATE_KNOWLEDGE_BASE_SUBCATEGORIES_AND_ARTICLES"
            v-bind="currentState.data"
            :key="currentState.data.breadcrumb"
          />
          <HelpCenterDrawerStateArticle
            v-else-if="currentState.id === STATE_KNOWLEDGE_BASE_ARTICLE"
            v-bind="currentState.data"
            :key="currentState.data.id"
          />
        </Transition>
      </div>
    </template>
  </AppShellDrawerPanel>
</template>
