// TODO Avoid global state!
const highlightedTask = shallowRef(undefined);

export function useHighlightedTask() {
  function setHighlightedTask(task) {
    if (!task) {
      highlightedTask.value = undefined;
    } else if (highlightedTask.value?.id !== task?.id) {
      highlightedTask.value = task;
    }
  }

  return {
    highlightedTask,
    setHighlightedTask,
  };
}
