<script setup>
import { useI18n } from '@/util';

const { t } = useI18n();
</script>
<template>
  <div class="text-body-1">
    <p>
      {{
        t(
          'My Timesheet lets you see and track time for all your tasks (for a week) at a glance. If you want to add time at the end of the day or week, this might be your ideal view.',
        )
      }}
    </p>
  </div>
</template>
