<script setup>
import { useI18n } from '@/util';

const { t } = useI18n();
</script>
<template>
  <div class="text-body-1">
    <p>
      {{
        t('You get requests (too many requests, some would say) from your colleagues and clients, what should you do?')
      }}
    </p>
    <p class="pt-6">
      {{
        t(
          'Create intake forms for projects to gather information from both internal and external requesters. Each submitted form turns into a task you can work with.',
        )
      }}
    </p>
    <p class="pt-6">
      {{ t('What we’re saying is: you can manage incoming requests easily and efficiently.') }}
    </p>
  </div>
</template>
