const symbol = Symbol('useLoadersInSync');

/**
 * Registers a new `inSync` ref within `useListLoader` and `useItemLoader`.
 */
export function useLoadersInSyncSource(inSync) {
  const inSyncRefs = inject(symbol, null);

  if (inSyncRefs) {
    inSyncRefs.value.add(inSync);
    triggerRef(inSyncRefs);

    onScopeDispose(() => {
      inSyncRefs.value.delete(inSync);
      triggerRef(inSyncRefs);
    });
  }
}

/**
 * Determines if all loaders in all descendant components are in sync - `inSync.value === true`.
 * @returns {ComputedRef<boolean>}
 */
export function useLoadersInSync() {
  const inSyncRefs = shallowRef(new Set());

  provide(symbol, inSyncRefs);
  const inSync = computed(() => {
    for (const inSyncRef of inSyncRefs.value) {
      if (!inSyncRef.value) {
        return false;
      }
    }
    return true;
  });

  useLoadersInSyncSource(inSync);

  return inSync;
}
