<script setup>
import { useI18n } from '@/util';

const { t } = useI18n();
</script>
<template>
  <div class="text-body-1">
    <p>
      {{
        t(
          'Not sure how to get started with a project? Use {teamworks} pre-defined templates as a launchpad to quickly spin up new projects, or simply to get a feel for how you might build out your own custom templates.',
          { teamworks: "Teamwork's" },
        )
      }}
    </p>
  </div>
</template>
