<script setup>
const props = defineProps({
  title: {
    type: String,
    default: undefined,
  },
  subtitle: {
    type: String,
    default: '',
  },
  formType: {
    type: String,
    default: 'sdrCall',
  },
  entryPoint: {
    type: String,
    default: '',
  },
  formSubmittedAction: {
    type: Function,
    default: undefined,
  },
});

const ContactFormDialogPanel = defineAsyncComponent(() => import('./ContactFormDialogPanel.vue'));
</script>

<template>
  <LscDialog>
    <template #default="{ close }">
      <ContactFormDialogPanel v-bind="props" @close="close" />
    </template>
  </LscDialog>
</template>
