<script setup>
import { useRealTimeUpdates } from '../base/useRealTimeUpdates';
import { provideTWIMSocket } from '../base/useTWIMSocket';
import { provideEmbeddedChat } from '../chat/useEmbeddedChat';
import { provideLaunchDarkly } from '../features/useLaunchDarkly';
import { provideTotango } from '../features/useTotango';
import { provideNotificationsUnreadCount } from '../notifications/useNotificationsUnreadCount';
import ApiSetup3 from './ApiSetup3.vue';

provideLaunchDarkly();
provideTotango();
useRealTimeUpdates().emitFromSocket(provideTWIMSocket());
provideNotificationsUnreadCount();
provideEmbeddedChat();
</script>

<template>
  <ApiSetup3><slot /></ApiSetup3>
</template>
