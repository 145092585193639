<script setup>
defineProps({
  videoId: {
    type: String,
    required: true,
  },
  borderless: {
    type: Boolean,
    default: false,
  },
  size: {
    type: String,
    default: 'sm',
    validator: (value) => ['sm', 'md', 'lg'].includes(value),
  },
});

const OnboardingWistiaVideoDialogPanel = defineAsyncComponent(() => import('./OnboardingWistiaVideoDialogPanel.vue'));
</script>

<template>
  <LscDialog>
    <template #activator="activator"><slot name="activator" v-bind="activator" /></template>
    <template #default="{ close }">
      <OnboardingWistiaVideoDialogPanel :videoId="videoId" :borderless="borderless" :size="size" @close="close" />
    </template>
  </LscDialog>
</template>
