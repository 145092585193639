import { useItemLoader } from '../base/useItemLoader';
import { useItemToListLoader } from '../base/useItemToListLoader';
import { useRealTimeUpdates } from '../base/useRealTimeUpdates';

function responseToItem(response) {
  const { productOutcomes } = response.data;
  return productOutcomes || [];
}

export function useProductOutcomesV3Loader({ params, count }) {
  const url = `/projects/api/v3/productoutcomes.json`;
  const { state, refresh } = useItemLoader({
    url,
    params,
    responseToItem,
  });

  useRealTimeUpdates((event) => {
    if (event.type === 'productoutcome') {
      refresh();
    }
  });

  return useItemToListLoader(state, count);
}
