/**
 * A basic function for comparing two parameters.
 *
 * - uses strict equality
 * - supports only JSON types
 * - compares Objects and Arrays recursively
 *
 * @param {*} item1 The first item to compare.
 * @param {*} item2 The second item to compare.
 * @returns {boolean} `true` if the items are equal, otherwise `false`.
 */
export function deepEqual(item1, item2) {
  if (item1 === item2) {
    return true;
  }

  if (typeof item1 === 'object' && typeof item2 === 'object' && item1 && item2) {
    if (Array.isArray(item1)) {
      if (!Array.isArray(item2)) {
        return false;
      }
      if (item1.length !== item2.length) {
        return false;
      }
      for (let i = 0; i < item1.length; i++) {
        if (!deepEqual(item1[i], item2[i])) {
          return false;
        }
      }
      return true;
    }
    if (Array.isArray(item2)) {
      return false;
    }

    let item1KeyCount = 0;
    let item2KeyCount = 0;

    // eslint-disable-next-line no-restricted-syntax
    for (const key in item1) {
      if (Object.hasOwn(item1, key)) {
        item1KeyCount++;
      }
    }

    // eslint-disable-next-line no-restricted-syntax
    for (const key in item2) {
      if (Object.hasOwn(item2, key)) {
        item2KeyCount++;
        if (!Object.hasOwn(item1, key)) {
          return false;
        }
        if (!deepEqual(item1[key], item2[key])) {
          return false;
        }
      }
    }

    if (item1KeyCount !== item2KeyCount) {
      return false;
    }

    return true;
  }

  return false;
}
