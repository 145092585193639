const symbol = Symbol('useBranding');

export function provideBrandingState(brandingState) {
  provide(symbol, brandingState);
  return brandingState;
}

export function useBrandingState() {
  return inject(symbol);
}

export function useBranding() {
  return useBrandingState().item;
}
