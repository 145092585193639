<script setup>
import { useI18n } from '@/util';

const { t } = useI18n();
</script>
<template>
  <div class="text-body-1">
    <p>
      {{
        t(
          'Custom Reports open the door to a magnitude of additional reports based on Projects, Tasks, Milestones, and Users. You have complete flexibility to pick and choose specific criteria, date ranges, filters, user permissions, and more.',
        )
      }}
    </p>
    <p class="pt-6">
      {{
        t(
          'Oh! And if you need to revisit a report later on and grab the most up-to-date version of the data, a simple refresh will do just the trick.',
        )
      }}
    </p>
  </div>
</template>
