<script setup>
import { providePermissions } from '../permissions/usePermissions';
import ApiSetupEmbed5 from './ApiSetupEmbed5.vue';

providePermissions();
</script>

<template>
  <ApiSetupEmbed5>
    <slot />
  </ApiSetupEmbed5>
</template>
