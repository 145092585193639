const symbol = Symbol('LscColorPicker');

export function provideLscColorPicker({ showIcon: _showIcon } = {}) {
  /**
   * Indicates if the color picker button should use the icon.
   * @type {boolean}
   */
  const showIcon = _showIcon;

  provide(symbol, { showIcon });
}

const defaultInjectValue = {
  showIcon: false,
};

export function useLscColorPicker() {
  return inject(symbol, defaultInjectValue);
}
