import { useEventListener } from '@vueuse/core';
import { useCurrentAccountState } from '@/api';

const symbol = Symbol('useHarvest');

export function Harvest() {
  const { item: account, inSync: accountInSync } = useCurrentAccountState();

  const isHarvestReady = shallowRef(false);
  const activeTimerTaskId = shallowRef(null);
  const isHarvestEnabledForAccount = computed(
    () => accountInSync.value && Boolean(account.value?.integrations?.harvest?.enabled),
  );

  // Insert Harvest script only if it's not already
  function initHarvest() {
    /* eslint-disable no-underscore-dangle */
    if (!window._harvestPlatformConfig) {
      window._harvestPlatformConfig = {
        applicationName: account.value.name,
        permalink: `${account.value.URL}tasks/%ITEM_ID%`,
        skipStyling: true,
        skipJquery: true,
      };

      const e = document.createElement('script');
      e.type = 'text/javascript';
      e.async = true;
      e.src = '//platform.harvestapp.com/assets/platform.js?x=2';
      document.body.appendChild(e);
    } else {
      isHarvestReady.value = true;
    }
    /* eslint-enable no-underscore-dangle */
  }

  // load script only if Harvest integration is enabled
  const unwatch = watch(
    isHarvestEnabledForAccount,
    (shouldInitHarvest) => {
      if (shouldInitHarvest) {
        initHarvest();
        setTimeout(() => unwatch());
      }
    },
    { immediate: true },
  );

  function setHarvestReady() {
    isHarvestReady.value = true;
  }

  function getHarvestMessage(message) {
    if (message.origin?.includes('//platform.harvestapp.com')) {
      const { type, value } = message.data;
      if (type === 'timer:started') {
        const { external_reference } = value; // eslint-disable-line camelcase
        activeTimerTaskId.value = Number(external_reference.id); // eslint-disable-line camelcase
      }
      if (type === 'timer:stopped') {
        activeTimerTaskId.value = null;
      }
    }
  }

  useEventListener(document.body, 'harvest-event:ready', setHarvestReady);
  useEventListener(window, 'message', getHarvestMessage);

  /**
   * Add harvest timer button after script loaded
   * Ref: https://github.com/harvesthq/platform/blob/master/button.md#adding-buttons-after-loaded
   * @type {HTMLElement} btnElement
   */
  function openHarvestModal(btnElement) {
    const harvestRoot = document.querySelector('#harvest-messaging');
    if (harvestRoot && btnElement) {
      const originalShowModal = HTMLDialogElement.prototype.showModal;
      HTMLDialogElement.prototype.showModal = function showModal() {
        if (this.open) {
          this.close();
        }
        return originalShowModal.call(this);
      };

      const event = new CustomEvent('harvest-event:timers:add', {
        detail: { element: btnElement },
      });
      harvestRoot.dispatchEvent(event);
      btnElement.click();

      setTimeout(() => {
        HTMLDialogElement.prototype.showModal = originalShowModal;
      }, 1000);
    }
  }

  return {
    isHarvestReady,
    activeTimerTaskId,
    isHarvestEnabledForAccount,
    openHarvestModal,
  };
}

/**
 * @returns {Harvest}
 */
export function provideHarvest() {
  const harvest = Harvest();
  provide(symbol, harvest);
}

/**
 * @returns {Harvest}
 */
export function useHarvest() {
  return inject(symbol);
}
