<script setup>
import { useI18n } from '@/util';

const { t } = useI18n();
</script>
<template>
  <div class="text-body-1">
    <p>
      {{
        t(
          "Tasks you see here are the same ones you see on the Table or List views, they're just visualized as cards. Cards include valuable information such as name, date, and assignees.",
        )
      }}
    </p>
    <p class="pt-6">
      {{ t('Tip: Customize the information shown on cards by editing column settings.') }}
    </p>
  </div>
</template>
