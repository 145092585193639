<script setup>
import { useI18n } from '@/util';

const { t } = useI18n();
</script>
<template>
  <div class="text-body-1">
    <p>
      {{ t('Leave comments, have conversations, and approve changes on tasks and within documents.') }}
    </p>
    <p class="mt-4">
      {{
        t(
          "Comments are for discussions, updates, or questions related to a particular task or piece of work. Essentially, if you're talking about work, it should probably be a comment in {teamwork}.",
          { teamwork: 'Teamwork.com' },
        )
      }}
    </p>
    <p class="mt-4">
      {{ t('You can post a comment by clicking on a task and typing your comment in the relevant section.') }}
    </p>
  </div>
</template>
