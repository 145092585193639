<script setup>
import { useAxiosAuthError } from '@/api';
import { useI18n } from '@/util';

const { t } = useI18n();
const { got401 } = useAxiosAuthError();
</script>

<template>
  <div class="flex h-dvh w-dvw items-center justify-center">
    <LscEmptyState size="lg" :title="got401 ? t('Redirecting to login') : t('Loading failed')" variant="error" />
  </div>
</template>
