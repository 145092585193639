const options = { passive: true, capture: true };

/**
 * Intersects 2 rectangles.
 */
function intersect(r1, r2) {
  const r3 = {
    left: Math.max(r1.left, r2.left),
    top: Math.max(r1.top, r2.top),
    right: Math.min(r1.right, r2.right),
    bottom: Math.min(r1.bottom, r2.bottom),
  };
  r3.width = r3.right - r3.left;
  r3.height = r3.bottom - r3.top;
  return r3;
}

function isVisible(element) {
  if (!element) {
    return false;
  }

  let rect = intersect(element.getBoundingClientRect(), {
    left: 0,
    top: 0,
    right: document.documentElement.clientWidth,
    bottom: document.documentElement.clientHeight,
    width: document.documentElement.clientWidth,
    height: document.documentElement.clientHeight,
  });

  do {
    if (getComputedStyle(element).overflow !== 'visible') {
      rect = intersect(rect, element.getBoundingClientRect());
    }
    if (rect.width <= 0 || rect.height <= 0) {
      return false;
    }
    // eslint-disable-next-line no-cond-assign, no-param-reassign
  } while ((element = element.parentElement));

  return true;
}

export function repositionScrollStrategy({ updateLocation, targetEl, isActive }) {
  let slow = false;
  let raf = -1;

  function update(event) {
    raf = requestAnimationFrame(() => {
      const start = performance.now();

      if (isVisible(targetEl.value)) {
        updateLocation.value?.(event);
      } else {
        // eslint-disable-next-line no-param-reassign
        isActive.value = false;
      }

      const time = performance.now() - start;
      slow = time / (1000 / 60) > 2;
    });
  }

  function listener(event) {
    if (!targetEl.value || !event.target.contains(targetEl.value)) {
      return;
    }

    cancelAnimationFrame(raf);
    if (slow) {
      // If the position calculation is slow,
      // defer updates until scrolling is finished.
      // Browsers usually fire one scroll event per frame so
      // we just wait until we've got two frames without an event
      raf = requestAnimationFrame(() => {
        raf = requestAnimationFrame(() => {
          update(event);
        });
      });
    } else {
      update(event);
    }
  }

  window.addEventListener('scroll', listener, options);
  onScopeDispose(() => {
    window.removeEventListener('scroll', listener, options);
    cancelAnimationFrame(raf);
  });
}
