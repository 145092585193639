import { useListLoader } from '../base/useListLoader';
import { useRealTimeUpdates } from '../base/useRealTimeUpdates';
import { getCustomfieldSlot } from './getCustomfieldSlot';

function responseToItems({ data: { customReportBuilder } }) {
  return customReportBuilder.map((reportBuilder) => ({
    ...reportBuilder,
    customfields: reportBuilder.customfields?.map((customfield) => ({
      ...customfield,
      slot: getCustomfieldSlot(customfield),
    })),
    id: reportBuilder.baseType,
  }));
}

/**
 * Loads custom report builder info from Teamwork v3 endpoints.
 */
export function useCustomReportsBuilderV3Loader({ params, count, pageSize }) {
  const { state, refresh } = useListLoader({
    url: '/projects/api/v3/reporting/custom/builder.json',
    params,
    count,
    pageSize,
    responseToItems,
  });

  useRealTimeUpdates((event) => {
    if (event.type === 'customfield') {
      refresh();
    }
  });

  return state;
}
