<script setup>
import { LscColorPickerColumns } from './constants';
import { useDefaultColorPickerColors } from './useDefaultColorPickerColors';
import { provideLscColorPicker } from './useLscColorPicker';

/**
 * @typedef {import('./useDefaultColorPickerColors').ColorObject} ColorObject
 */

const props = defineProps({
  /**
   * The colors to display in the picker.
   * Each color is an object with a `color` property (hex string)
   * and an optional `label` property (string).
   * @type {PropType<ColorObject[]>}
   */
  colors: {
    type: Array,
    default: () => [],
  },
  /**
   * If true, the user must select a color & cannot clear the selection.
   */
  required: {
    type: Boolean,
    default: false,
  },
  /**
   * How many columns to display the colors in.
   * @type {PropType<typeof LscColorPickerColumns[number]>}
   */
  cols: {
    type: Number,
    default: 4,
    validator: (value) => LscColorPickerColumns.includes(value),
  },
});

provideLscColorPicker({
  showIcon: true,
});

/**
 * The color to display as selected.
 * @type {ModelRef<ColorObject['color']>}
 */
const modelValue = defineModel({
  type: String,
  default: undefined,
});
const { defaultColors, emptyColor } = useDefaultColorPickerColors();

/**
 * @type {ComputedRef<ColorObject[]>}
 */
const localColorObjects = computed(() => {
  const colors = props.colors.length ? props.colors : defaultColors;
  if (!props.required) {
    return [...colors, emptyColor];
  }
  return colors;
});
</script>
<template>
  <div
    class="grid justify-items-center gap-3"
    :class="{ 'grid-cols-4': cols === 4, 'grid-cols-6': cols === 6, 'grid-cols-8': cols === 8 }"
  >
    <LscColorPickerButton
      v-for="colorObject of localColorObjects"
      :key="colorObject.color"
      v-LsdTooltip="colorObject.label"
      :color="colorObject.color"
      :ariaPressed="colorObject.color === modelValue"
      :ariaLabel="colorObject.label"
      @click="modelValue = colorObject.color"
    />
  </div>
</template>
