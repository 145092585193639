import { getLastChildPath } from '../lastChildPath';

export const teamRoutes = [
  {
    path: '/teams/:teamId',
    component: () => import('./RouteTeam.vue'),
    props: (route) => ({
      teamId: Number(route.params.teamId),
    }),
    children: [
      {
        path: '',
        redirect(to) {
          return `/teams/${to.params.teamId}/${getLastChildPath('team', 'members')}`;
        },
      },
      {
        path: 'members',
        component: () => import('./RouteTeamMembers.vue'),
      },
      {
        path: 'statuses',
        component: () => import('./RouteTeamStatuses.vue'),
      },
      {
        path: 'subteams',
        component: () => import('./RouteTeamSubteams.vue'),
      },
      {
        path: 'projects',
        component: () => import('./RouteTeamProjects.vue'),
      },
    ],
  },
];
