import { PROOF_OBJECT_TYPE } from '../constants';
import { formatEmbeddableLink } from './formatEmbeddableLink';
import { hydrateMeta } from './hydrate';

export function normalizeProofObject(proofObject, included) {
  // eslint-disable-next-line no-underscore-dangle
  const _proofObject = { ...proofObject };

  hydrateMeta(_proofObject, included);

  // Normalize embeddable links
  if (_proofObject.type === PROOF_OBJECT_TYPE.LINK) {
    _proofObject.URL = formatEmbeddableLink(_proofObject.URL);
  }

  return _proofObject;
}
