import { DateTime } from 'luxon';
import { useListLoader } from '../base/useListLoader';
import { useOptimisticUpdates } from '../base/useOptimisticUpdates';
import { useRealTimeUpdates } from '../base/useRealTimeUpdates';
import { useCurrentUser } from '../user/useCurrentUser';

function getUpdatedByData(userRate, users) {
  return users?.[userRate?.updatedBy?.id];
}

function getUpdatedAtData(userRate) {
  return userRate?.updatedAt ? DateTime.fromISO(userRate.updatedAt) : undefined;
}

function responseToItems(response) {
  const { users = {}, costRates = {} } = response.data.included || {};
  const userRates = response.data.userRates || [];

  return userRates.map((userRate) => {
    const userRateData = {
      ...userRate,
      id: userRate.user.id,
      user: users?.[userRate.user.id],
      updatedBy: getUpdatedByData(userRate, users),
      updatedAt: getUpdatedAtData(userRate),
    };

    const costRate = costRates?.[userRate.costRate?.id] ?? undefined;
    return {
      ...userRateData,
      costRate: costRates
        ? {
            ...costRate,
            updatedBy: getUpdatedByData(costRate, users),
            updatedAt: getUpdatedAtData(costRate),
          }
        : null,
    };
  });
}

function responseToMeta(response) {
  return response.data.meta;
}

export function useUserRatesV3Loader({ pageSize, count, projectId: _projectId, params = {} } = {}) {
  const projectId = shallowRef(_projectId);
  const currentUser = useCurrentUser();

  const url = computed(() =>
    projectId.value
      ? `/projects/api/v3/rates/projects/${projectId.value}/users.json`
      : '/projects/api/v3/rates/installation/users.json',
  );

  const { state, refresh, update } = useListLoader({
    url,
    responseToItems,
    responseToMeta,
    pageSize,
    params,
    count,
    type: 'userRates',
  });

  useRealTimeUpdates((event) => {
    if (event.type === 'userRate') {
      refresh(event.projectId);
    }
    if (event.type === 'project' && event.action === 'permissions') {
      refresh();
    }
  });

  useOptimisticUpdates((event) => {
    if (!['userCost', 'userRate'].includes(event.type)) {
      return;
    }
    update((userRates) => {
      if (event.type === 'userRate') {
        return userRates.map((userRate) =>
          event.userIds.includes(userRate.id) ? { ...userRate, effectiveRate: event.userRate } : userRate,
        );
      }
      if (event.type === 'userCost') {
        const newCostRate = {
          cost: event.userCost,
          updatedAt: DateTime.now(),
          updatedBy: currentUser.value,
        };
        return userRates.map((userRate) =>
          event.userIds.includes(userRate.id) ? { ...userRate, costRate: newCostRate } : userRate,
        );
      }

      return userRates;
    }, event.promise);
  });

  return state;
}
