<script setup>
import { useI18n } from '@/util';

const { t } = useI18n();
</script>
<template>
  <div class="text-body-1">
    <p>
      {{ t('Use the Workload Planner to manage and understand your team’s capacity.') }}
    </p>
    <p class="pt-6">
      {{
        t(
          'Drill down to the project or task level to see who is under or over worked, and redistribute tasks accordingly.',
        )
      }}
    </p>
  </div>
</template>
