<script setup>
import { useI18n } from '@/util';

const { t } = useI18n();
</script>
<template>
  <div class="text-body-1">
    <p>
      {{
        t(
          'You can get pretty granular with permissions (we know you value that as much as we do!), but you can always keep things as the default for now.',
        )
      }}
    </p>
  </div>
</template>
