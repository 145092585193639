export const LscLabelSizes = /** @type {const} */ (['sm', 'lg']);
export const LscLabelVariants = /** @type {const} */ (['flag', 'emphasis', 'highlight']);
export const LscLabelColors = /** @type {const} */ ([
  'yellow',
  'orange',
  'red',
  'teal',
  'blue',
  'purple',
  'green',
  'grey',
]);
