import { useI18n } from '@/util';
import { useAxios } from '../base/useAxios';
import { useOptimisticUpdates } from '../base/useOptimisticUpdates';
import { useRealTimeUpdates } from '../base/useRealTimeUpdates';

/**
 * @typedef {import('../../designsystem/widgets/picker/tag/useLswTagPicker').Tag} Tag
 */

export function useTagActions() {
  const { t } = useI18n();
  const api = useAxios();
  const { emit: emitRealTimeUpdate, socketId } = useRealTimeUpdates();
  const { emit: emitOptimisticUpdate } = useOptimisticUpdates();

  return {
    /**
     * @param {Omit<Tag, 'id'>} tag
     */
    createTag(tag) {
      const promise = api
        .post(
          '/tags.json',
          { tag },
          {
            errorMessage(error) {
              if (error.response?.status === 409) {
                return t('A tag with this name already exists');
              }
              return t('Failed to create the tag');
            },
            headers: { 'Socket-ID': socketId },
          },
        )
        .then((response) => {
          const projectId = Number(response.data.projectId) || 0;
          const createdTag = {
            id: Number(response.data.id),
            name: response.data.name,
            color: response.data.color,
            projectId,
            project: { id: projectId, type: 'projects' },
          };
          emitRealTimeUpdate({
            type: 'tag',
            action: 'new',
            tagId: createdTag.id,
          });

          return createdTag;
        });
      return promise;
    },
    /**
     *
     * @param {Tag} tag
     */
    updateTag(tag) {
      const promise = api
        .put(
          `/tags/${tag.id}.json`,
          { tag },
          {
            errorMessage: t('Failed to update the tag'),
            headers: { 'Socket-ID': socketId },
          },
        )
        .then(() => {
          emitRealTimeUpdate({
            type: 'tag',
            action: 'edited',
            tagId: tag.id,
          });
          return tag;
        });

      emitOptimisticUpdate({
        type: 'tag',
        action: 'update',
        tag,
        promise,
      });

      return promise;
    },
  };
}
