/**
 * return the format for Teamwork lockdown api's when setting an entity's privacy
 * @param {Object} -  an object with companies, teams, users and everyone properties
 * @returns comma separated string of access ids or 0 if everyone is true
 */
export function grantAccessTo(
  { companies = [], teams = [], users = [], everyone = true } = { companies: [], teams: [], users: [], everyone: true },
) {
  return everyone
    ? '0'
    : [
        ...companies.map(({ id }) => `c${id}`),
        ...teams.map(({ id }) => `t${id}`),
        ...users.map(({ id }) => String(id)),
      ].join(',');
}
