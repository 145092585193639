<script setup>
import { generateUuid, useI18n } from '@/util';
import { useLscAccordion } from './useLscAccordion';

const props = defineProps({
  name: {
    type: String,
    required: true,
  },
  title: {
    type: String,
    default: undefined,
  },
  /**
   * Optional icon to be displayed before the title
   * @type {PropType<LscIconName>}
   */
  icon: {
    type: String,
    default: '',
  },
  dataIdentifierPrefix: {
    type: String,
    default: '',
  },
});

const modelValue = defineModel({
  type: Boolean,
  default: false,
});

const { t } = useI18n();
const { isExpanded, toggle } = useLscAccordion();

const uuid = generateUuid();
const controlId = `lsc-accordion-item-${props.name}-control-${uuid}`;
const groupId = `lsc-accordion-item-${props.name}-group-${uuid}`;

const expanded = computed(() => isExpanded(props.name));

// Required over overflow-x-visible due to browser quirk @ https://stackoverflow.com/a/17020278
// Using overflow-y-clip would break the transition
const isFinishedExpanding = shallowRef(false);

function transitionEnd() {
  if (expanded.value) {
    isFinishedExpanding.value = true;
  }
}

watch(expanded, (isExpanding) => {
  if (!isExpanding) {
    isFinishedExpanding.value = false;
  }
});

watch(
  modelValue,
  (value) => {
    if (value !== expanded.value) {
      toggle(props.name);
    }
  },
  { immediate: true },
);

watch(expanded, (value) => {
  modelValue.value = value;
});
</script>

<template>
  <div class="border-b border-b-separator py-2">
    <div role="heading" aria-level="3">
      <button
        :id="controlId"
        :aria-controls="groupId"
        :aria-label="expanded ? t('Collapse group') : t('Expand group')"
        :data-identifier="dataIdentifierPrefix ? `${dataIdentifierPrefix}-${name}` : undefined"
        type="button"
        class="flex w-full cursor-pointer items-center gap-3 rounded-sm outline-1 outline-offset-2 outline-focus-primary"
        @click="toggle(name)"
      >
        <slot name="prepend">
          <LscIcon v-if="icon" :icon="icon" size="sm" />
        </slot>

        <slot name="title">
          <h3 class="select-none text-body-1 font-semibold">{{ title }}</h3>
        </slot>

        <slot name="append" />

        <div class="ml-auto flex items-center gap-3">
          <slot name="action" />
          <LscIcon
            icon="lsi-dropdown"
            size="md"
            class="transition-transform"
            :class="{ 'rotate-180 transform': expanded }"
          />
        </div>
      </button>
    </div>

    <div
      :id="groupId"
      :aria-labelledby="controlId"
      :aria-expanded="expanded"
      :class="expanded ? 'mt-3 grid-rows-[1fr]' : 'grid-rows-[0fr]'"
      class="grid transition-all duration-400 ease-in"
      @transitionend="transitionEnd"
    >
      <div class="grid grid-cols-1 gap-2" :class="{ 'overflow-y-hidden': !isFinishedExpanding }">
        <slot />
      </div>
    </div>
  </div>
</template>
