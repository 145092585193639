<script setup>
import { useI18n } from '@/util';

const { t } = useI18n();
</script>
<template>
  <div class="text-body-1">
    <p>
      {{
        t(
          "Add fields to gather all data you'll need to understand or complete a request (without having to follow up with the responder for more).",
        )
      }}
    </p>
  </div>
</template>
