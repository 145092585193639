<script setup>
import { useI18n } from '@/util';

const { t } = useI18n();
</script>
<template>
  <div class="text-body-1">
    <p>
      {{
        t(
          'My Timesheets is ideal if you prefer to complete your work first and then log your time in bulk afterwards, so you can stay focused on the task at hand.',
        )
      }}
    </p>
    <p class="mt-4">
      {{
        t(
          'Tasks assigned to you, and which fall within the chosen week, are automatically visible. You can manually add any additional tasks and projects you need to track time against by clicking on "Add row".',
        )
      }}
    </p>
  </div>
</template>
