<script setup>
defineProps({
  proofData: {
    type: Object,
    default: undefined,
  },
  /**
   * Disables the project picker and sets its value to this
   */
  forceProject: {
    type: Object,
    default: undefined,
  },
  /**
   * Disables the task picker and sets its value to this
   */
  forceTask: {
    type: Object,
    default: undefined,
  },
  userCacheSeed: {
    type: Array,
    default: () => [],
  },
});

const emit = defineEmits(['edit', 'create']);

const modelValue = defineModel({
  type: Boolean,
  default: false,
});

const ProofAddOrEditDialogPanel = defineAsyncComponent(() => import('./ProofAddOrEditDialogPanel.vue'));
</script>

<template>
  <LscDialog v-model="modelValue" data-identifier="proofs-add-edit-modal">
    <template v-if="$slots.activator" #activator="activator">
      <slot name="activator" v-bind="activator" />
    </template>
    <template #default="{ close }">
      <ProofAddOrEditDialogPanel
        v-bind="{ proofData, forceProject, forceTask, userCacheSeed }"
        @close="close"
        @edit="emit('edit')"
        @create="emit('create')"
      />
    </template>
  </LscDialog>
</template>
