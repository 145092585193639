export const miscRoutes = [
  {
    path: '/task-templates',
    component: () => import('./RouteTaskTemplates.vue'),
  },
  {
    path: '/task-templates/:taskTemplateId',
    component: () => import('./RouteTaskTemplate.vue'),
    props: (route) => ({
      taskTemplateId: Number(route.params.taskTemplateId),
    }),
  },
  {
    path: '/json-to-project',
    component: () => import('./RouteJsonToProject.vue'),
  },
  {
    path: '/legacytogrow',
    component: () => import('./RouteLegacyToGrow.vue'),
  },
  {
    path: '/cancel',
    component: () => import('./RouteCancel.vue'),
    props: {
      name: 'cancellation',
      mode: 'cancel',
    },
  },
  {
    path: '/downgrade',
    component: () => import('./RouteCancel.vue'),
    props: {
      name: 'cancellation',
      mode: 'downgrade',
    },
  },
  {
    path: '/trialstarted/:feature',
    component: () => import('./RouteTrialStarted.vue'),
    props: (route) => ({
      pageName: 'Trial Started',
      feature: route.params.feature,
      enableTrial: !!route.query.enableTrial,
    }),
  },
  {
    path: '/migrate',
    component: () => import('./RouteMigrate.vue'),
  },
  {
    path: '/emailgateway/:planId/:discount/:campaign',
    component: () => import('./RouteEmailGateway.vue'),
    props: (route) => ({
      campaign: route.params.campaign,
      planId: Number(route.params.planId),
      discount: route.params.discount,
    }),
  },
  {
    path: '/lastproject/:projectPath*',
    component: () => import('./RouteLastProject.vue'),
    props: (to) => ({
      projectPath: to.params.projectPath ? `/${to.params.projectPath.join('/')}` : '',
    }),
    meta: {
      ignoreDialogQueryParam: true,
    },
  },
  {
    path: '/sdr/call',
    component: () => import('./RouteSDRCall.vue'),
  },
  {
    path: '/welcome',
    component: () => import('./RouteWelcome.vue'),
    children: [
      {
        path: '',
        redirect: '/welcome/the-fundamentals',
      },
      {
        path: 'badges',
        component: () => import('./RouteWelcomeBadges.vue'),
      },
      {
        path: 'the-fundamentals',
        component: () => import('./RouteWelcomeLegacy.vue'),
      },
      {
        path: 'set-up-teamwork',
        component: () => import('./RouteWelcomeLegacy.vue'),
      },
      {
        path: 'educate',
        component: () => import('./RouteWelcomeLegacy.vue'),
      },
      {
        path: 'integrations',
        component: () => import('./RouteWelcomeLegacy.vue'),
      },
      {
        path: 'get-in-touch',
        component: () => import('./RouteWelcomeLegacy.vue'),
      },
    ],
  },
  {
    path: '/budgets',
    component: () => import('./RouteBudgets.vue'),
  },
  {
    path: '/integrations/projects/:projectId(\\d+)/summary',
    component: () => import('./RouteIntegrationsProjectSummary.vue'),
  },
  {
    path: '/integrations/tasks/:taskId(\\d+)/summary',
    component: () => import('./RouteIntegrationsTaskSummary.vue'),
  },
  {
    path: '/import/:pathMatch(.*)*',
    component: () => import('./RouteImportApp.vue'),
  },
  {
    path: '/book-a-call',
    component: () => import('./RouteBookAHubspotCall.vue'),
    props: (route) => ({
      bookingUrl: route.query.bookingUrl,
      source: route.query.source,
    }),
  },
];
