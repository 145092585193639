<script setup>
import { useCurrentUser, useFeatures, useProjectActions } from '@/api';
import { useAppShellSidebar } from '@/appShell';
import { useCurrentProject } from '@/route';
import { useI18n, useLocalStorage } from '@/util';
import ProjectIcon from '../../display/ProjectIcon.vue';
import ProjectJumpToDrawerProjectsListProjectNav from './projectNav/ProjectJumpToDrawerProjectsListProjectNav.vue';
import { useProjectJumpToDrawerTracking } from './useProjectJumpToDrawerTracking';

defineProps({
  projects: {
    type: Array,
    required: true,
  },
  tab: {
    type: String,
    default: 'all',
  },
});

const { projectNavSubmenuEnabled } = useFeatures();
const { t } = useI18n();
const { clearActiveDrawerPanelIfNotPinned } = useAppShellSidebar();
const { toggleStarProject } = useProjectActions();
const { trackJumpToDrawerProjectClicked } = useProjectJumpToDrawerTracking();
const currentProject = useCurrentProject();
const openProjects = useLocalStorage('teamwork/sidenav/openProjects', []);

const user = useCurrentUser();

function toggleOpenProject(project) {
  const pos = openProjects.value.indexOf(project.id);
  if (pos < 0) {
    openProjects.value.push(project.id);
  } else {
    openProjects.value.splice(pos, 1);
  }
}

function handleProjectClicked(tab) {
  clearActiveDrawerPanelIfNotPinned();
  trackJumpToDrawerProjectClicked(tab);
}
</script>

<template>
  <template v-for="project in projects" :key="project.id">
    <VListItem
      :to="`/projects/${project.id}`"
      :active="currentProject?.id === project.id"
      v-bind="VListItemSidebarProjectsDrawer"
      tabindex="0"
      class="group"
      @click="handleProjectClicked(tab)"
    >
      <template #prepend>
        <template v-if="projectNavSubmenuEnabled">
          <button
            type="button"
            size="sm"
            class="mr-3 hidden h-[24px] w-[24px] items-center justify-center hover:rounded-full hover:bg-[#fff] group-hover:flex"
            @click.stop.prevent="toggleOpenProject(project)"
          >
            <LscIcon
              size="md"
              icon="lsi-collapse"
              class="text-[color:--lsds-c-sidebar-drawer-icon-color-on-surface] transition-transform duration-300"
              :class="{
                '-rotate-90': openProjects.indexOf(project.id) > -1,
                '-rotate-180': openProjects.indexOf(project.id) < 0,
              }"
            />
          </button>
          <ProjectIcon :project="project" class="mr-3 group-hover:hidden" />
        </template>
        <!-- Star/Unstar -->
        <button
          v-if="!projectNavSubmenuEnabled"
          v-LsdTooltip="project.isStarred ? t('Remove from Starred') : t('Add to Starred')"
          class="mr-2 flex items-center justify-center"
          type="button"
          :aria-label="project.isStarred ? t('Remove from Starred') : t('Add to Starred')"
          @click.stop.prevent="toggleStarProject(project)"
        >
          <LscIcon
            size="md"
            :class="project.isStarred ? 'text-[color:--project-starred-color]' : 'text-icon-subtle'"
            :icon="project.isStarred ? 'lsi-favorite' : 'lsi-favorite-unselected'"
          />
        </button>
      </template>
      <template v-if="projectNavSubmenuEnabled" #append>
        <!-- Star/Unstar -->
        <button
          v-LsdTooltip="project.isStarred ? t('Remove from Starred') : t('Add to Starred')"
          class="mr-2 hidden items-center justify-center group-hover:flex"
          type="button"
          :aria-label="project.isStarred ? t('Remove from Starred') : t('Add to Starred')"
          @click.stop.prevent="() => toggleStarProject(project)"
        >
          <LscIcon
            size="md"
            :class="project.isStarred ? 'text-[color:--project-starred-color]' : 'text-icon-subtle'"
            :icon="project.isStarred ? 'lsi-favorite' : 'lsi-favorite-unselected'"
          />
        </button>
      </template>
      <VListItemTitle :title="project.name" class="text-body-1">{{ project.name }}</VListItemTitle>
      <VListItemSubtitle
        v-if="project.company?.name && user.companyId !== project.companyId"
        :title="project.company?.name"
        class="text-body-2"
      >
        {{ project.company.name }}
      </VListItemSubtitle>
    </VListItem>
    <template v-if="projectNavSubmenuEnabled && openProjects.indexOf(project.id) > -1">
      <ProjectJumpToDrawerProjectsListProjectNav :projectId="project.id" />
    </template>
  </template>
</template>
