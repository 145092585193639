<script setup>
import { useExperiment46, usePendo } from '@/api';
import { useBadge } from './useBadge';

defineProps({
  code: {
    type: String,
    required: true,
  },
});

const { EXP_46_COMMON_METRICS } = useExperiment46();
const { getOtherExp46CommonMetrics } = useBadge();

const { trackPendoEvent } = usePendo();

const pendoEventWasSent = ref(false);

function badgeLevelUserHovered() {
  if (pendoEventWasSent.value) {
    return;
  }
  trackPendoEvent({
    eventName: 'NAVIGATION_EVENT',
    commonMetrics: EXP_46_COMMON_METRICS,
    metadata: {
      event_action: 'profile_badge_hovered',
      ...getOtherExp46CommonMetrics(),
    },
  });
  pendoEventWasSent.value = true;
}
function getImageUrl(name) {
  return new URL(`./assets/levels/${name}.svg`, import.meta.url).href;
}
</script>

<template>
  <img :src="getImageUrl(code)" @mouseover="badgeLevelUserHovered" />
</template>
