<script setup>
import { useI18n } from '@/util';

const { t } = useI18n();
</script>
<template>
  <div class="text-body-1">
    <p>
      {{
        t(
          'If your team works on projects with similar objectives and that follow the same (or similar) steps, then project templates are for you.',
        )
      }}
    </p>
    <p class="pt-6">
      {{
        t(
          'You’ll launch new projects from the template (everything from the template will be carried over) and you can make changes from there.',
        )
      }}
    </p>
  </div>
</template>
